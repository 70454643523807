import { atom } from "recoil";

import {
  ShippingDeliveryType,
  ShippingItem,
  ShippingListItem,
  TransactionStatementData,
} from "../../../types/fulfillment/shipping";
import {
  subtractDate,
  transformUTCDateToLocalDateTime,
} from "../../../utils/common/date";

import { GET_SHIPPER_LIST_REQ } from "../../../api-interfaces/boful-api/shipping";

/** Confirm */
const SHIPPING_LIST = atom<ShippingListItem[]>({
  key: "fulfillment/shipping/atoms/SHIPPING_LIST",
  default: [],
});

/** Picking */
const SCANNED_PICKING_INVOICE = atom<{
  shippingId: number;
  invoiceNo: string;
  deliveryType: ShippingDeliveryType | undefined;
  items: ShippingItem[];
  teamId: number;
  companyName: string;
  teamName: string;
}>({
  key: "fulfillment/shipping/atoms/SCANNED_PICKING_INVOICE",
  default: {
    shippingId: 0,
    invoiceNo: "",
    deliveryType: undefined,
    items: [],
    teamId: 0,
    companyName: "",
    teamName: "",
  },
});

const IS_NO_NEED_TO_PACKING = atom<boolean | undefined>({
  key: "fulfillment/shipping/atoms/IS_NO_NEED_TO_PACKING",
  default: undefined,
});

/** 거래 명세서 데이터 */
const TRANSACTION_STATEMENT_DATA = atom<TransactionStatementData[]>({
  key: "fulfillment/shipping/atoms/TRANSACTION_STATEMENT_DATA",
  default: [],
});

/** 출고신청/취소 국내 search query params */
const SEARCH_QUERY_PARAMS_SHIPPING_ALL_DOMESTIC = atom<
  Omit<GET_SHIPPER_LIST_REQ, "deliveryArea">
>({
  key: "fulfillment/shipping/atoms/SEARCH_QUERY_PARAMS_SHIPPING_ALL_DOMESTIC",
  default: {
    page: 0,
    perPage: 10,
    tabStatus: "all",
    dateKind: "createdAt",
    dateFrom:
      transformUTCDateToLocalDateTime({
        utcDateTime: subtractDate({
          date: new Date(),
          value: 6,
          unit: "day",
        }),
        timeZone: "Asia/Seoul",
        when: "start",
      })?.toISOString() ?? "",
    dateTo:
      transformUTCDateToLocalDateTime({
        utcDateTime: new Date(),
        timeZone: "Asia/Seoul",
        when: "end",
      })?.toISOString() ?? "",
  },
});

/** 출고신청/취소 해외 search query params */
const SEARCH_QUERY_PARAMS_SHIPPING_ALL_OVERSEAS = atom<
  Omit<GET_SHIPPER_LIST_REQ, "deliveryArea">
>({
  key: "fulfillment/shipping/atoms/SEARCH_QUERY_PARAMS_SHIPPING_ALL_OVERSEAS",
  default: {
    page: 0,
    perPage: 10,
    tabStatus: "all",
    dateKind: "createdAt",
    dateFrom:
      transformUTCDateToLocalDateTime({
        utcDateTime: subtractDate({
          date: new Date(),
          value: 6,
          unit: "day",
        }),
        timeZone: "Asia/Seoul",
        when: "start",
      })?.toISOString() ?? "",
    dateTo:
      transformUTCDateToLocalDateTime({
        utcDateTime: new Date(),
        timeZone: "Asia/Seoul",
        when: "end",
      })?.toISOString() ?? "",
  },
});

/** 출고신청/취소 국내 search query params */
const SEARCH_QUERY_PARAMS_SHIPPING_IN_PROGRESS_DOMESTIC = atom<
  Omit<GET_SHIPPER_LIST_REQ, "deliveryArea">
>({
  key: "fulfillment/shipping/atoms/SEARCH_QUERY_PARAMS_SHIPPING_IN_PROGRESS_DOMESTIC",
  default: {
    page: 0,
    perPage: 10,
    tabStatus: "pending",
    dateKind: "createdAt",
    dateFrom:
      transformUTCDateToLocalDateTime({
        utcDateTime: subtractDate({
          date: new Date(),
          value: 6,
          unit: "day",
        }),
        timeZone: "Asia/Seoul",
        when: "start",
      })?.toISOString() ?? "",
    dateTo:
      transformUTCDateToLocalDateTime({
        utcDateTime: new Date(),
        timeZone: "Asia/Seoul",
        when: "end",
      })?.toISOString() ?? "",
  },
});

/** 출고신청/취소 해외 search query params */
const SEARCH_QUERY_PARAMS_SHIPPING_IN_PROGRESS_OVERSEAS = atom<
  Omit<GET_SHIPPER_LIST_REQ, "deliveryArea">
>({
  key: "fulfillment/shipping/atoms/SEARCH_QUERY_PARAMS_SHIPPING_IN_PROGRESS_OVERSEAS",
  default: {
    page: 0,
    perPage: 10,
    tabStatus: "pending",
    dateKind: "createdAt",
    dateFrom:
      transformUTCDateToLocalDateTime({
        utcDateTime: subtractDate({
          date: new Date(),
          value: 6,
          unit: "day",
        }),
        timeZone: "Asia/Seoul",
        when: "start",
      })?.toISOString() ?? "",
    dateTo:
      transformUTCDateToLocalDateTime({
        utcDateTime: new Date(),
        timeZone: "Asia/Seoul",
        when: "end",
      })?.toISOString() ?? "",
  },
});

/** 출고마감내역 국내 search query params */
const SEARCH_QUERY_PARAMS_SHIPPING_COMPLETE_DOMESTIC = atom<
  Omit<GET_SHIPPER_LIST_REQ, "deliveryArea">
>({
  key: "fulfillment/shipping/atoms/SEARCH_QUERY_PARAMS_SHIPPING_COMPLETE",
  default: {
    page: 0,
    perPage: 10,
    tabStatus: "close",
    dateKind: "endedPackingAt",
    dateFrom:
      transformUTCDateToLocalDateTime({
        utcDateTime: subtractDate({
          date: new Date(),
          value: 27,
          unit: "day",
        }),
        timeZone: "Asia/Seoul",
        when: "start",
      })?.toISOString() ?? "",
    dateTo:
      transformUTCDateToLocalDateTime({
        utcDateTime: new Date(),
        timeZone: "Asia/Seoul",
        when: "end",
      })?.toISOString() ?? "",
  },
});

/** 출고마감내역 해외 search query params */
const SEARCH_QUERY_PARAMS_SHIPPING_COMPLETE_OVERSEAS = atom<
  Omit<GET_SHIPPER_LIST_REQ, "deliveryArea">
>({
  key: "fulfillment/shipping/atoms/SEARCH_QUERY_PARAMS_SHIPPING_COMPLETE_OVERSEAS",
  default: {
    page: 0,
    perPage: 10,
    tabStatus: "close",
    dateKind: "endedPackingAt",
    dateFrom:
      transformUTCDateToLocalDateTime({
        utcDateTime: subtractDate({
          date: new Date(),
          value: 27,
          unit: "day",
        }),
        timeZone: "Asia/Seoul",
        when: "start",
      })?.toISOString() ?? "",
    dateTo:
      transformUTCDateToLocalDateTime({
        utcDateTime: new Date(),
        timeZone: "Asia/Seoul",
        when: "end",
      })?.toISOString() ?? "",
  },
});

export default {
  SHIPPING_LIST,

  SCANNED_PICKING_INVOICE,
  IS_NO_NEED_TO_PACKING,

  TRANSACTION_STATEMENT_DATA,

  SEARCH_QUERY_PARAMS_SHIPPING_ALL_DOMESTIC,
  SEARCH_QUERY_PARAMS_SHIPPING_ALL_OVERSEAS,
  SEARCH_QUERY_PARAMS_SHIPPING_IN_PROGRESS_DOMESTIC,
  SEARCH_QUERY_PARAMS_SHIPPING_IN_PROGRESS_OVERSEAS,
  SEARCH_QUERY_PARAMS_SHIPPING_COMPLETE_DOMESTIC,
  SEARCH_QUERY_PARAMS_SHIPPING_COMPLETE_OVERSEAS,
};
