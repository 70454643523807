import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const modalBody = styled.div`
  > .sku-info {
    padding: 16px;
    margin-bottom: 24px;
    text-align: left;
    border-radius: 8px;
    background-color: ${COLOR.primaryBlue_10};
    ${setFontStyle("SubHead3")};
    color: ${COLOR.grayScale_700};
  }

  > .paging {
    margin-bottom: 24px;
  }
`;

export default {
  modalBody,
};
