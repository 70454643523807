import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilValue } from "recoil";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import useUploadFile from "@sellernote/_shared/src/hooks/common/useUploadFile";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import { FileDomainType } from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import { getActorKindByUserAuthority } from "@sellernote/_shared/src/utils/fulfillment/file";

import useCameraOrGallery from "hooks/common/useCameraOrGallery";
import { usePubSub } from "hooks/common/usePubSub";

import useImageSliderModal from "../useImageSliderModal";
import Styled from "./index.styles";

export default function usePicturePanelForConfirm({
  label,
  domainType,
  receivingId,
}: {
  label: string;
  domainType: FileDomainType;
  receivingId: number;
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const invoiceNo = useRef("");

  const currentManagerAuthority = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  )?.authority;
  const pictureList = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.PICTURE_DICT
  )[domainType];

  const hasPicture = pictureList?.length;

  const [uploadFile, LoadingToUpload, FailureModal] = useUploadFile();

  const { publish, subscribe } = usePubSub();

  const {
    showImageSliderModal,
    PictureImageSliderModal,
    ResultHandlerOfImageSliderModal,
  } = useImageSliderModal({ receivingId, domainType });

  const [TriggerForCameraOrGallery] = useCameraOrGallery({
    triggerComponent: <Styled.takePictureOption>촬영</Styled.takePictureOption>,
    onFileLoaded: (fileInfoList) => {
      const file = fileInfoList[0];

      uploadFile({
        data: {
          fileName: file.fileName,
          fileBase64String: file.base64,
          name:
            domainType === "invoice"
              ? `${invoiceNo.current}_${file.fileName}`
              : file.fileName,
          domain: domainType,
          targetId: receivingId,
          mimeType: file.fileMimeType,
          // 로그인한 상태이기 때문에 반드시 존재
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          actorKind: getActorKindByUserAuthority(currentManagerAuthority!),
        },
        onUploadSuccess: showImageSliderModal,
      });
    },
  });

  useEffect(() => {
    subscribe(
      "useScanInvoicePanelForConfirm.handleScanResult",
      ({ scannedInvoiceNo }) => {
        invoiceNo.current = scannedInvoiceNo;
      }
    );
  }, [subscribe]);

  const showPicturePanel = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handlePicturePanelClose = useCallback(
    () =>
      domainType === "invoice"
        ? () => {
            publish("usePicturePanelForConfirm.handlePicturePanelClose");
            setIsModalVisible(false);
          }
        : () => setIsModalVisible(false),
    [domainType, publish]
  );

  const PicturePanel = useMemo(() => {
    if (!isModalVisible) return null;

    return (
      <>
        <Modal
          active
          uiType="contentWithCustomBody"
          title={label}
          body={
            <Styled.takePictureOptionContainer>
              <Styled.takePictureOption
                disabled={!hasPicture}
                onClick={() => {
                  if (hasPicture) showImageSliderModal();
                }}
              >
                사진보기
              </Styled.takePictureOption>

              {TriggerForCameraOrGallery}
            </Styled.takePictureOptionContainer>
          }
          onClose={handlePicturePanelClose()}
        />

        {PictureImageSliderModal}
      </>
    );
  }, [
    isModalVisible,
    label,
    hasPicture,
    TriggerForCameraOrGallery,
    handlePicturePanelClose,
    PictureImageSliderModal,
    showImageSliderModal,
  ]);

  const ResultHandlerOfPicturePanel = useMemo(() => {
    return (
      <>
        {LoadingToUpload}

        {FailureModal}

        {ResultHandlerOfImageSliderModal}
      </>
    );
  }, [LoadingToUpload, FailureModal, ResultHandlerOfImageSliderModal]);

  return {
    showPicturePanel,
    PicturePanel,
    ResultHandlerOfPicturePanel,
    PictureImageSliderModal,
  };
}
