import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { ShippingListItem } from "@sellernote/_shared/src/types/fulfillment/shipping";

import useCancelingMessageModal from "./useCancelingMessageModal";

/**
 * 취소 송장 여부 확인 후 페이지 이동
 */
export default function useCheckCancelingInvoice() {
  const currentUser = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );

  const history = useHistory();

  const { MessageModal, modalOpenHandler } = useCancelingMessageModal();

  const checkInvoice = useCallback(
    (invoiceData: ShippingListItem) => {
      const scannedInvoiceNo = invoiceData.invoiceNo;

      if (invoiceData.shippingStatus !== "cancel") {
        modalOpenHandler.openNoCancelingInvoiceNoMessage(scannedInvoiceNo);
        return;
      }

      // 피킹 시작 전, 피킹 시작 후 최초 상품 스캔 전 제외(재입고할 내역이 없는 취소 건의 경우)
      if (invoiceData.pickerId === undefined || !invoiceData.isStartScanning) {
        modalOpenHandler.openEmptyRestockingList(scannedInvoiceNo);
        return;
      }

      // 파트타이머의 경우 취소시점 담당자가 본인인 경우에만
      if (currentUser?.authority === "partTimer") {
        if (
          !(
            currentUser?.id === invoiceData.pickerId ||
            currentUser?.id === invoiceData.packerId
          )
        ) {
          modalOpenHandler.openNoMyCustomerMessage(scannedInvoiceNo);
          return;
        }
      }

      history.push({
        pathname: `/canceling/detail/${scannedInvoiceNo}`,
        state: { from: "scanning" },
      });
    },
    [history, currentUser?.authority, currentUser?.id, modalOpenHandler]
  );

  return { MessageModal, checkInvoice };
}
