import { Dispatch, SetStateAction } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";

import SelectManagementDate from "../SelectManagementDate";
import SelectSkuInput from "../SelectSkuInput";
import { SkuToSelect } from "../useSelectSkuModal";
import useInputForSku from "./useInputForSku";

export default function InputForSku({
  tempSku,
  setTempSku,
  setSelectedSku,
  onSelectSkuModalClose,
}: {
  tempSku: SkuToSelect | undefined;
  setTempSku: Dispatch<SetStateAction<SkuToSelect | undefined>>;
  setSelectedSku: Dispatch<SetStateAction<SkuToSelect | undefined>>;
  onSelectSkuModalClose: () => void;
}) {
  const {
    searchTerm,
    setSearchTerm,
    searchedSkuList,
    handleSkuSelect,
    handleTempSkuReset,
    handleTempSearchTermRestore,

    handleSelectSkuModalConfirm,

    isValid,
  } = useInputForSku({
    tempSku,
    setTempSku,
    setSelectedSku,
    onSelectSkuModalClose,
  });

  return (
    <>
      <SelectSkuInput
        type="input"
        selectedSku={tempSku}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        optionList={searchedSkuList}
        onSkuSelect={handleSkuSelect}
        onReset={handleTempSkuReset}
        onTempSearchTermRestore={handleTempSearchTermRestore}
      />

      {tempSku?.managementKind && (
        <SelectManagementDate
          tempSku={tempSku}
          setTempSku={setTempSku}
          managementKind={tempSku.managementKind}
        />
      )}

      <Button
        theme="primary"
        size="normal"
        label="확인"
        disabled={!isValid}
        handleClick={handleSelectSkuModalConfirm}
      />
    </>
  );
}
