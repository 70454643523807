import { useMemo } from "react";
import { useRecoilValue } from "recoil";

import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { SKUInfoForCancelingRestocking } from "@sellernote/_shared/src/types/fulfillment/canceling";
import { getWorkerNameByAuthority } from "@sellernote/_shared/src/utils/fulfillment/common";

export interface ReportingListItem {
  id: number;
  locationBarcode: string;
  skuId: number;
  skuBarcode: string | undefined;
  currentQty: number;
  quantity: number;
  workerName: string;
}

/**
 * 취소 문제보고 리스트 생성
 */
export default function useReportCancelingProblem({
  SKUInfoForScanning,
}: {
  SKUInfoForScanning: SKUInfoForCancelingRestocking[];
}) {
  const currentUser = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );

  const locationListOfWarehouse = useRecoilValue(
    FULFILLMENT_COMMON_ATOMS.LOCATION_LIST_OF_WAREHOUSE
  );

  const reportingList = useMemo((): ReportingListItem[] => {
    const filteredList = SKUInfoForScanning.filter(
      (v) => v.currentQty !== v.quantity
    );

    return filteredList.map((v) => ({
      id: v.id,
      locationBarcode:
        locationListOfWarehouse.find((location) => location.id === v.locationId)
          ?.barCode ?? "",
      skuId: v.skuId,
      skuBarcode: v.skuBarcode,
      currentQty: v.currentQty,
      quantity: v.quantity,
      workerName: getWorkerNameByAuthority(currentUser),
    }));
  }, [SKUInfoForScanning, currentUser, locationListOfWarehouse]);

  return { reportingList };
}
