import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { INVENTORY_MANAGEMENT_KIND_MAP } from "@sellernote/_shared/src/constants/fulfillment/inventory";
import { ManagementKind } from "@sellernote/_shared/src/types/fulfillment/inventory";
import { SKU } from "@sellernote/_shared/src/types/fulfillment/sku";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

export default function SkuDetailModal({
  active,
  skuDetail,
  managementKind,
  managementDate,
  onClose,
}: {
  active: boolean;
  skuDetail: SKU | undefined;
  managementKind: ManagementKind | undefined;
  managementDate: string | undefined;
  onClose: () => void;
}) {
  const hasManagementInfo = managementKind && managementDate;

  return (
    <Modal
      active={active}
      uiType="contentWithCustomBody"
      title={`SKU ID: ${getFormattedSingleSkuId(skuDetail?.id)}`}
      body={
        <>
          <ListItem
            type="withContentAlignRight"
            label="회사명"
            value={skuDetail?.team?.company}
          />
          <ListItem
            type="withContentAlignRight"
            label="상품명"
            value={skuDetail?.itemName}
          />
          <ListItem
            type="withContentAlignRight"
            label="판매자상품코드"
            value={skuDetail?.productCode}
          />
          <ListItem
            type="withContentAlignRight"
            label="옵션관리코드"
            value={skuDetail?.managementCode}
          />
          <ListItem
            type="withContentAlignRight"
            label="상품바코드"
            value={skuDetail?.barCode}
          />
          {hasManagementInfo && (
            <ListItem
              type="withContentAlignRight"
              label={INVENTORY_MANAGEMENT_KIND_MAP[managementKind]}
              value={toFormattedDate(managementDate, "YYYY-MM-DD")}
            />
          )}
        </>
      }
      onClose={onClose}
    />
  );
}
