import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import {
  REMOVE_PARTIAL_WAREHOUSING_REQ,
  REMOVE_PARTIAL_WAREHOUSING_REQ_PATH_PARAMS,
} from "@sellernote/_shared/src/api-interfaces/boful-api/returning";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";

import { returningActions } from "modules/returning";

export default function useRemovePartialWarehousing({
  returningId,
}: {
  returningId: number;
}) {
  const dispatch = useDispatch();

  const {
    mutate: removePartialWarehousing,
    ResponseHandler: ResponseHandlerOfRemovePartialWarehousing,
  } = RETURNING_QUERY.useRemovePartialWarehousing();

  const {
    mutate: addPartialWarehousing,
    ResponseHandler: ResponseHandlerOfAddPartialWarehousing,
  } = RETURNING_QUERY.useAddPartialWarehousing({
    type: "AfterRemove",
    returningId,
  });

  const removePartialWarehousingFromItem = useCallback(
    ({
        returningId,
        itemId,
        placingId,
        maxCount,
        isLastRemaining,
        postSuccessCallback,
      }: REMOVE_PARTIAL_WAREHOUSING_REQ_PATH_PARAMS &
        REMOVE_PARTIAL_WAREHOUSING_REQ & {
          maxCount: number;
          isLastRemaining: boolean;
          postSuccessCallback: () => void;
        }) =>
      () => {
        const onSuccessAfterRemoving = () => {
          dispatch(
            returningActions.GET_DETAIL({
              id: returningId,
              _postSuccessCallback: postSuccessCallback,
            })
          );
        };

        removePartialWarehousing(
          {
            pathParams: { returningId, itemId },
            placingId,
          },
          {
            onSuccess: isLastRemaining
              ? () => {
                  // 마지막으로 남은 분할입고를 삭제하는 경우, 삭제후 일반입고(기본형)를 생성한다.
                  addPartialWarehousing(
                    { itemId, quantity: maxCount, isInit: true },
                    { onSuccess: onSuccessAfterRemoving }
                  );
                }
              : onSuccessAfterRemoving,
          }
        );
      },
    [addPartialWarehousing, dispatch, removePartialWarehousing]
  );

  const ResponseHandlerOfRemovingPartialWarehousing = useMemo(() => {
    return (
      <>
        {ResponseHandlerOfRemovePartialWarehousing}

        {ResponseHandlerOfAddPartialWarehousing}
      </>
    );
  }, [
    ResponseHandlerOfRemovePartialWarehousing,
    ResponseHandlerOfAddPartialWarehousing,
  ]);

  return {
    removePartialWarehousingFromItem,
    ResponseHandlerOfRemovingPartialWarehousing,
  };
}
