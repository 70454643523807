import { useCallback } from "react";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { MultiSelectInfo } from "@sellernote/_shared/src/hooks/common/useMultiSelect";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";
import TextButton from "@sellernote/_sds-v1/src/components/button/TextButton";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import { Form } from "./useUnverifiedItemModal/useUnverifiedItemForm";

export default function UnverifiedList({
  list,
  multiSelect,
  openModalAndUpdate,
}: {
  list?: ReceivingItem[];
  multiSelect: MultiSelectInfo;
  openModalAndUpdate: (formInfo: Form) => void;
}) {
  const {
    selectAll,
    unSelectAll,
    isAllSelected,
    selectionDict,
    select,
    unSelect,
  } = multiSelect;

  const handleCheck = useCallback(
    (id: number, isChecked: boolean) => {
      if (isChecked) {
        unSelect(id);
      } else {
        select(id);
      }
    },
    [select, unSelect]
  );

  const tableList = list?.map((item, i) => {
    const isChecked = !!selectionDict[item.id];

    const order = i + 1;

    const productName = `불일치${order}`;

    const hasAttachment = !!item.attachment?.length;

    return {
      rowKey: item.id,
      checkbox: isChecked ? (
        <Icon
          type="checkStrokeBoxChecked"
          size={1.5}
          color={COLOR.primaryBlue}
          onClick={() => handleCheck(item.id, isChecked)}
        />
      ) : (
        <Icon
          type="checkStrokeBox"
          size={1.5}
          color={COLOR.grayScale_700}
          onClick={() => handleCheck(item.id, isChecked)}
        />
      ),
      product: (
        <>
          {productName} /<br />
          {replaceEmptyToDash(item.skuBarCode)}
        </>
      ),
      quantity: replaceEmptyToDash(toThousandUnitFormat(item.actualQty), true),
      // attachment 유무로 입력/수정 구분. 입력도 이전에 스캔만 한 정보에 추가 입력을 하는 것이므로 과정은 수정과 동일.
      detail: (
        <TextButton
          theme={hasAttachment ? "gray" : "default"}
          size="14px"
          label={hasAttachment ? "수정" : "입력"}
          icon="arrowRight"
          handleClick={() =>
            openModalAndUpdate({
              order,
              itemId: item.id,
              tempId: undefined,
              quantity: item.actualQty,
              memo: item.memo || "",
              attachments: item.attachment,
            })
          }
        />
      ),
    };
  });

  return (
    <>
      <Table
        columnInfo={{
          checkbox: {
            label: isAllSelected ? (
              <Icon
                type="checkStrokeBoxChecked"
                size={1.5}
                color={COLOR.primaryBlue}
                onClick={() => unSelectAll()}
              />
            ) : (
              <Icon
                type="checkStrokeBox"
                size={1.5}
                color={COLOR.grayScale_700}
                onClick={() => selectAll()}
              />
            ),
            fixedWidth: 40,
          },
          product: {
            label: "상품/상품 바코드",
            fixedWidth: 118,
          },
          quantity: {
            label: "수량(PCS)",
            fixedWidth: 84,
          },
          detail: {
            label: "상세",
            fixedWidth: 100,
          },
        }}
        dataList={tableList}
        labelForNoData="불일치 상품을 추가해주세요."
      />
    </>
  );
}
