import { useCallback } from "react";
import { useRecoilValue } from "recoil";

import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";

/**
 * getWorkerById 함수를 반환
 */
export default function useGetWorkerByIdFactory() {
  const workerList = useRecoilValue(FULFILLMENT_COMMON_ATOMS.WORKER_LIST);

  const getWorkerById = useCallback(
    (workerId?: number) => {
      if (!workerId || !workerList?.length) return;

      return workerList.find((v) => v.id === workerId);
    },
    [workerList]
  );

  return getWorkerById;
}
