import styled from "styled-components";

const completeConsolidation = styled.div`
  > .complete-button {
    margin-bottom: 24px;
  }
`;

export default {
  completeConsolidation,
};
