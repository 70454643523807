import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

const container = styled.div`
  display: flex;
  flex-direction: column;
  .list-item-with-more-result {
    .value {
      color: ${COLOR.pointWarning};
    }
  }
  .list-item {
    .label::before {
      content: " • ";
    }
  }
`;

export default {
  container,
};
