import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import {
  ParcelCompanyType,
  ShipmentParcelOrderType,
  ShipmentStatusType,
  TruckCompanyType,
} from "@sellernote/_shared/src/types/fulfillment/shipping";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import InputSelect from "@sellernote/_sds-v1/src/components/input/InputSelect";

import Layout from "containers/Layout";

import Styled from "./index.styles";

type ParcelCompanyOptionList = Extract<ParcelCompanyType, "all" | "cj">;
type TruckCompanyOptionList = Extract<TruckCompanyType, "all" | "gogoVan">;
type TitleOptionList = "택배" | "화물차량";
type SelectTypeOptionList = "택배사" | "운송사";

const parcelCompanyList = new Map<
  ParcelCompanyOptionList,
  InputSelectOption<ParcelCompanyOptionList>
>([
  ["all", { label: "전체", value: "all" }],
  ["cj", { label: "대한통운(기본)", value: "cj" }],
]);

const truckCompanyList = new Map<
  TruckCompanyOptionList,
  InputSelectOption<TruckCompanyOptionList>
>([
  ["all", { label: "전체", value: "all" }],
  ["gogoVan", { label: "고고밴", value: "gogoVan" }],
]);

function getSelectOptionList<Title extends TitleOptionList>(
  title: Title
): Title extends "택배"
  ? InputSelectOption<ParcelCompanyOptionList>[]
  : InputSelectOption<TruckCompanyOptionList>[];
function getSelectOptionList(title: any): any {
  return title === "택배"
    ? [...parcelCompanyList.values()]
    : [...truckCompanyList.values()];
}

function getInitialSelectedCompany<Title extends TitleOptionList>(
  title: Title,
  company: Title extends "택배"
    ? ParcelCompanyOptionList
    : TruckCompanyOptionList
): Title extends "택배"
  ? InputSelectOption<ParcelCompanyOptionList>
  : InputSelectOption<TruckCompanyOptionList>;
function getInitialSelectedCompany(title: any, company: any): any {
  return title === "택배"
    ? parcelCompanyList.get(company)
    : truckCompanyList.get(company);
}

export default function ShippingShipmentClosingLayout({
  title,
  selectType,
  children,
}: {
  title: TitleOptionList;
  selectType: SelectTypeOptionList;
  children: React.ReactNode;
}) {
  const history = useHistory();
  const location = useLocation();
  const { company, status, order } = useParams<{
    company: ParcelCompanyOptionList | TruckCompanyOptionList;
    status?: ShipmentStatusType;
    order?: ShipmentParcelOrderType;
  }>();

  const shipmentPath = location.pathname.split("/");
  const DELIVERY_TYPE_INDEX_IN_PATH = 4;
  const SHIPMENT_STATUS_INDEX_IN_PATH = 6;

  const [selectedCompany, setSelectedCompany] = useState(
    getInitialSelectedCompany(title, company)
  );

  const selectOptionList = getSelectOptionList(title);

  useEffect(() => {
    setSelectedCompany(getInitialSelectedCompany(title, company));
  }, [company, location, title]);

  const handleParcelCompanySelect = useCallback(
    (
      v: InputSelectOption<ParcelCompanyOptionList | TruckCompanyOptionList>
    ) => {
      setSelectedCompany(v);

      history.push(
        `/shipping/shipment/closing/${title === "택배" ? "parcel" : "truck"}/${
          v.value
        }${status ? `/${status}` : ""}${order ? `/${order}` : ""}`
      );
    },
    [history, order, status, title]
  );

  return (
    <Layout
      navigator={{
        title: `출하(${title})`,
      }}
    >
      <Styled.container>
        {/* 피그마 기준 - truck 상세 화면은 헤더 부분이 없음 */}
        {shipmentPath[DELIVERY_TYPE_INDEX_IN_PATH] === "truck" &&
        shipmentPath[SHIPMENT_STATUS_INDEX_IN_PATH] ? (
          <Styled.date>
            {toFormattedDate(new Date(), "YYYY년 MM월 DD일")}
          </Styled.date>
        ) : (
          <Styled.parcelHeaderContainer>
            <Styled.date>
              {toFormattedDate(new Date(), "YYYY년 MM월 DD일")}
            </Styled.date>
            <InputSelect<ParcelCompanyOptionList | TruckCompanyOptionList>
              uiType="outline"
              label={`${selectType} 선택`}
              optionList={selectOptionList}
              selectedOption={selectedCompany}
              handleSelect={handleParcelCompanySelect}
            />
          </Styled.parcelHeaderContainer>
        )}

        {children}
      </Styled.container>
    </Layout>
  );
}
