import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useRecoilValue } from "recoil";

import COMMON_QUERY from "@sellernote/_shared/src/queries/fulfillment/COMMON_QUERY";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { BofulLocation } from "@sellernote/_shared/src/types/fulfillment/common";

import { InventoryToMove } from "../split/useInventoryMovement";

/**
 * all: 기존 위치 선택(전체 위치)
 * movement: 이동할 위치 선택(빈 위치 + 해당 skuId가 있는 위치 + 관리일자를 설정한 경우 해당 관리일자가 있는 위치)
 * consolidation: 병합할 위치 선택(빈 위치)
 */
export type ModalTypeInfo =
  | { type: "all" | "consolidation" }
  | {
      type: "movement";
      skuId: number;
      existingLocationBarcode: string | undefined;
      managementDate: string | undefined;
      listOfInventoryToMove: InventoryToMove[];
    };

export type LocationType = "scan" | "input";

const label = new Map<ModalTypeInfo["type"], string>([
  ["all", "기존 위치"],
  ["movement", "이동할 위치"],
  ["consolidation", "이동할 위치"],
]);

export default function useSelectLocationModal({
  setSelectedLocation,
  onClose,
  ...modalTypeInfo
}: {
  setSelectedLocation: Dispatch<SetStateAction<BofulLocation | undefined>>;
  onClose: () => void;
} & ModalTypeInfo) {
  const [locationType, setLocationType] = useState<LocationType>("scan");
  const [tempLocation, setTempLocation] = useState<BofulLocation>();

  const currentUser = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );
  const locationList = useRecoilValue(
    FULFILLMENT_COMMON_ATOMS.LOCATION_LIST_OF_WAREHOUSE
  );

  const {
    data: availableLocationList,
    ResponseHandler: ResponseHandlerOfGettingLocationListOfWarehouse,
  } = COMMON_QUERY.useGetLocationListOfWarehouse({
    enabled:
      modalTypeInfo.type === "movement" ||
      modalTypeInfo.type === "consolidation",
    warehouseId: currentUser?.warehouseId ?? 1,
    isAvailable: true,
    ...(modalTypeInfo.type === "movement" &&
      modalTypeInfo.skuId && {
        skuId: modalTypeInfo.skuId,
      }),
    ...(modalTypeInfo.type === "movement" &&
      modalTypeInfo.managementDate && {
        managementDate: modalTypeInfo.managementDate,
      }),

    // 마운트됨으로써 새로운 쿼리가 실행되는 것을 방지(재고 이동/분할의 경우 이동할 위치를 선택할 때마다 key값이 변경되기 때문)
    refetchOnMount: false,
  });

  const locationListByType = useMemo(() => {
    if (modalTypeInfo.type === "all") {
      return locationList;
    }

    if (modalTypeInfo.type === "movement") {
      // 기존 위치로 등록 되었거나, 이동할 위치에 이미 등록된 위치를 제외한 위치만 필터링
      return availableLocationList?.filter(
        (location) =>
          !(
            location.barCode === modalTypeInfo.existingLocationBarcode ||
            modalTypeInfo.listOfInventoryToMove.some(
              (item) => item.location?.barCode === location.barCode
            )
          )
      );
    }

    if (modalTypeInfo.type === "consolidation") {
      return availableLocationList;
    }
  }, [modalTypeInfo, locationList, availableLocationList]);

  const handleLocationTypeChange = useCallback(
    (v: LocationType) => {
      setLocationType(v);
      setTempLocation(undefined);
    },
    [setTempLocation]
  );

  const handleTempLocationReset = useCallback(() => {
    setTempLocation(undefined);
  }, [setTempLocation]);

  const handleSelectLocationModalClose = useCallback(() => {
    setLocationType("scan");
    setTempLocation(undefined);
    onClose();
  }, [onClose]);

  const handleSelectLocationModalConfirm = useCallback(() => {
    setSelectedLocation(tempLocation);
    handleSelectLocationModalClose();
  }, [handleSelectLocationModalClose, setSelectedLocation, tempLocation]);

  return {
    label: label.get(modalTypeInfo.type),

    locationType,
    handleLocationTypeChange,

    tempLocation,
    setTempLocation,
    handleTempLocationReset,

    handleSelectLocationModalConfirm,
    handleSelectLocationModalClose,

    locationListByType,
    ResponseHandlerOfGettingLocationListOfWarehouse,
  };
}
