import { useMemo, useState } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { ReturningCondition } from "@sellernote/_shared/src/types/fulfillment/fulfillment";

import Styled from "./index.styles";

export interface ReturningGuideModal {
  condition: ReturningCondition;
  requests: string | undefined;
}

const returningConditionMap = new Map<
  ReturningCondition,
  { label: string; description: string }
>([
  [
    "notOpenNotUsed",
    {
      label: "소비자가 상품포장을 개봉하지 않았고, 상품사용 흔적이 없습니다.",
      description:
        "센터 담당자가 육안으로 확인 시, 상품포장이 개봉되지 않았고 상품을 사용한 흔적이 없다고 판단하는 경우 정상입고 처리합니다.",
    },
  ],
  [
    "openNotUsed",
    {
      label: "소비자가 상품포장을 개봉했으나, 상품사용 흔적이 없습니다.",
      description:
        "센터 담당자가 육안으로 확인 시, 상품포장은 개봉되었으나 상품을 사용한 흔적이 없다고 판단하는 경우 정상입고 처리합니다.",
    },
  ],
  [
    "openAndUsed",
    {
      label: "소비자가 상품포장을 개봉했고, 상품사용 흔적이 있습니다.",
      description:
        "센터 담당자가 육안으로 확인 시, 상품포장이 개봉되었고 상품을 사용한 흔적이 있더라도 정상입고 처리합니다.",
    },
  ],
]);

export default function useReturningGuide() {
  const [returningGuideModal, setReturningGuideModal] =
    useState<ReturningGuideModal>();

  const ReturningGuideModal = useMemo(
    () => (
      <Modal
        active={!!returningGuideModal}
        uiType="contentWithCustomBody"
        title="상품별 반품가이드"
        body={
          <Styled.returningGuideModalBody>
            <div className="sku-returning-condition">
              <strong className="label">
                {
                  returningConditionMap.get(
                    returningGuideModal?.condition ?? "notOpenNotUsed"
                  )?.label
                }
              </strong>
              <div className="description">
                {
                  returningConditionMap.get(
                    returningGuideModal?.condition ?? "notOpenNotUsed"
                  )?.description
                }
              </div>
            </div>

            <div className="returning-requests">
              <div className="label">[고객 전체요청사항]</div>
              <div className="description">{returningGuideModal?.requests}</div>
            </div>

            <Button
              label="확인"
              theme="primary"
              size="normal"
              handleClick={() => setReturningGuideModal(undefined)}
            />
          </Styled.returningGuideModalBody>
        }
        onClose={() => setReturningGuideModal(undefined)}
      />
    ),
    [returningGuideModal]
  );

  return {
    ReturningGuideModal,
    setReturningGuideModal,
  };
}
