import React from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";

import Styled from "./index.styles";

export interface WithButtonProps {
  type: "withButton";
  value: React.ReactNode;
  button: {
    label: string;
    handleClick: () => void;
    disabled?: boolean;
  };
}
interface WithImageProps {
  type: "withImage";
  imageList: string[];
}

interface WithContentAlignProps {
  type: "withContentAlignRight" | "withContentAlignLeft";
  value?: React.ReactNode;
}

export default function ListItem({
  className,
  label,
  ...propsByType
}: {
  label: React.ReactNode;
  className?: string;
} & (WithButtonProps | WithImageProps | WithContentAlignProps)) {
  return (
    <Styled.container className={`${className ? className : ""} list-item`}>
      {propsByType.type === "withContentAlignRight" && (
        <Styled.withContentAlignRightItem>
          <div className="label">{label}</div>

          <div className="value">{propsByType.value}</div>
        </Styled.withContentAlignRightItem>
      )}

      {propsByType.type === "withImage" && (
        <Styled.withImage>
          <div className="label">{label}</div>

          <ul className="image-container">
            {propsByType.imageList.map((imgUrl, i) =>
              imgUrl ? (
                <li key={`list-item-image-list-${i}`}>
                  <img src={imgUrl} />
                </li>
              ) : (
                <li key={`list-item-image-list-${i}`} className="no-image">
                  <span className="no-image-desc">이미지 없음</span>
                </li>
              )
            )}
          </ul>
        </Styled.withImage>
      )}

      {propsByType.type === "withButton" && (
        <Styled.withButton>
          <div className="label">{label}</div>

          <div className="value">
            {propsByType.value}

            <Button
              size="small"
              theme="secondary"
              label={propsByType.button.label}
              handleClick={propsByType.button.handleClick}
              disabled={propsByType.button.disabled}
            />
          </div>
        </Styled.withButton>
      )}
    </Styled.container>
  );
}
