import styled, { css } from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

import { Props } from "./index";

// eslint-disable-next-line @typescript-eslint/ban-types
type ContainerProps = Pick<Props, "theme" | "size" | "disabled"> & {};

const container = styled.div`
  padding: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  ${(props: ContainerProps) => {
    return css`
      em {
        font-size: ${props.size};
        line-height: ${props.size};
        color: ${COLOR.primaryBlue};
        font-weight: bold;
        margin-right: 3px;
      }

      .icon svg path {
        fill: ${COLOR.primaryBlue};
      }
    `;
  }}

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &:hover {
    opacity: 0.6;
  }

  &:active {
    color: ${COLOR.secondaryDeepBlue};
  }

  ${(props: ContainerProps) => {
    switch (props.theme) {
      case "default":
        return css`
          em {
            color: ${COLOR.primaryBlue};
          }

          &:active {
            color: ${COLOR.secondaryDeepBlue};
          }
        `;

      case "danger":
        return css`
          em {
            color: ${COLOR.pointWarning};
          }

          .icon svg path {
            fill: ${COLOR.pointWarning};
          }

          &:active {
            color: ${COLOR.pointWarning_60};
          }
        `;

      case "gray":
        return css`
          em {
            color: ${COLOR.grayScale_500};
          }

          .icon svg path {
            fill: ${COLOR.grayScale_500};
          }

          &:active {
            color: ${COLOR.grayScale_600};
          }
        `;
    }
  }}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      em {
        color: ${COLOR.grayScale_400};
      }

      .icon svg path {
        fill: ${COLOR.grayScale_400};
      }
    `}
`;

export default {
  container,
};
