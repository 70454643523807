import selectors from "./selectors";
import slice, { saga } from "./slice";

const returningReducer = slice.reducer;
const returningActions = slice.actions;
const returningSaga = saga;
const returningSelectors = selectors;

export {
  returningReducer,
  returningActions,
  returningSaga,
  returningSelectors,
};
