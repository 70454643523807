import React from "react";
import SignaturePad from "react-signature-canvas";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { ShippingShipmentListItem } from "@sellernote/_shared/src/types/fulfillment/shipping";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import TextButton from "@sellernote/_sds-v1/src/components/button/TextButton";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

import useConsigneeSignatureModal from "hooks/shipping/useConsigneeSignatureModal";
import { truckCompany } from "hooks/shipping/useShipmentClosingTruckDetail";

import Styled from "./index.styles";

export default function ConsigneeSignatureModal({
  data,
  handleModalClose,
}: {
  data?: ShippingShipmentListItem;
  handleModalClose: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const {
    isCompleted,
    signatureCanvas,
    signatureImageURL,
    setSignatureImageURL,
    handleScanStart,
    handleSignatureReset,
    handleShipmentComplete,
    handleConsigneeSignatureModalClose,

    ScanModal,
    ScanMessageModal,

    LoadingToUpload,
    UploadSignatureFailureModal,

    ResponseHandlerOfGettingShipmentTruckPackings,
    ResponseHandlerOfCompletingShipmentTruck,
  } = useConsigneeSignatureModal({
    data,
    handleModalClose,
  });

  return (
    <>
      <Styled.container>
        <Modal
          uiType="contentWithCustomBody"
          title="인수자 서명"
          active={true}
          body={
            <>
              <div className="signature-modal-header">
                <ListItem
                  type="withContentAlignRight"
                  label="일자"
                  value={toFormattedDate(new Date(), "YYYY년 MM월 DD일")}
                />
                <ListItem
                  type="withContentAlignRight"
                  label="출고요청번호"
                  value={data?.id}
                />
                <ListItem
                  type="withContentAlignRight"
                  label="고객사명"
                  value={`${data?.team?.company}(${data?.team?.id})`}
                />
              </div>

              <div className="signature-modal-body">
                <ListItem
                  className="custom-value"
                  type="withContentAlignRight"
                  label="총 출하 건"
                  value={
                    <>
                      <span className="total">{data?.trucking?.boxQty}</span>
                      <Button
                        theme="secondary"
                        size="small"
                        label="스캔시작"
                        handleClick={handleScanStart}
                      />
                    </>
                  }
                />
                <ListItem
                  type="withContentAlignRight"
                  label="운송사명"
                  value={truckCompany.get(data?.truckCompany ?? "")}
                />
                <ListItem
                  type="withContentAlignRight"
                  label="목적지"
                  value={data?.customerAddress}
                />
                <ListItem
                  type="withContentAlignRight"
                  label="기사님 성함"
                  value={data?.trucking?.driverName}
                />
                <ListItem
                  type="withContentAlignRight"
                  label="차량번호"
                  value={data?.trucking?.truckNo}
                />

                <ListItem
                  className="custom-value"
                  type="withContentAlignRight"
                  label="인수자 서명"
                  value={
                    <TextButton
                      theme="withIcon"
                      label="재서명"
                      size="14px"
                      icon="reset"
                      //  스캔이 풀카운트인 경우에만 서명 영역이 활성화 된다
                      disabled={!isCompleted}
                      handleClick={handleSignatureReset}
                    />
                  }
                />

                <Styled.signatureContainer disabled={!isCompleted}>
                  <SignaturePad
                    // 스캔이 풀카운트인 경우에만 서명 영역이 활성화 된다
                    ref={signatureCanvas}
                    canvasProps={{ className: "signatureCanvas" }}
                    onBegin={() =>
                      setSignatureImageURL(
                        signatureCanvas.current
                          ?.getTrimmedCanvas()
                          .toDataURL() ?? ""
                      )
                    }
                  />
                </Styled.signatureContainer>

                <Button
                  className="complete-button"
                  theme="primary"
                  label="출하마감"
                  size="normal"
                  // 서명까지 해야 마감 버튼이 활성화된다
                  disabled={!signatureImageURL}
                  handleClick={handleShipmentComplete}
                />
              </div>
            </>
          }
          onClose={handleConsigneeSignatureModalClose}
        />
      </Styled.container>

      {ScanModal}
      {ScanMessageModal}

      {LoadingToUpload}
      {UploadSignatureFailureModal}

      {ResponseHandlerOfGettingShipmentTruckPackings}
      {ResponseHandlerOfCompletingShipmentTruck}
    </>
  );
}
