import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { ManagementKind } from "@sellernote/_shared/src/types/fulfillment/inventory";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import {
  useCounter,
  UseCounterData,
  UseCounterDataValue,
  UseCounterReturn,
} from "@sellernote/_shared/src/utils/common/hook";
import { getNumberParam } from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { checkForNormalItemAsInspection } from "@sellernote/_shared/src/utils/fulfillment/inspection";
import { getHasMultiLocationInspection } from "@sellernote/_shared/src/utils/fulfillment/receiving";

type CounterType = "single" | "multi";

export interface InspectionCounterSKU {
  counterKey: string;
  itemId: number;
  skuId: number;
  skuBarcode: string | undefined; // 상품 바코드
  itemName: string;
  inspectorId?: number;
  inspectingId: string;
  isCompleteInspecting: boolean;
  counterType: CounterType;
  managementKind?: ManagementKind;
  managementDate?: string;
}

type CounterForInspection = UseCounterReturn<InspectionCounterSKU>;
export type CounterDataForInspection = UseCounterData<InspectionCounterSKU>;

export interface SKUCountingForInspection {
  skuInProgress: InspectionCounterSKU | undefined;
  setSkuInProgress: (val?: InspectionCounterSKU) => void;
  totalCount: string;
  counter: CounterForInspection;
  reset: (items: ReceivingItem[]) => void;
}

/**
 * 검수 카운팅용(분할검수용) 키를 생성.
 * - 분할검수를 가능하기 위해 skuId와 inspectingId를 조합
 * - (일반검수와는 달리)관리일자를 사용하는 경우라도 `inspectingId`이 고유하므로 굳이 key에 itemId를 반영하지 않음
 */
export function getCounterKeyForMultiLocationInspection({
  skuId,
  inspectingId,
}: {
  skuId: number;
  inspectingId: string;
}) {
  return `S${skuId}-${inspectingId}`;
}

/**
 * 입고 카운팅용(일반검수용) 키를 생성.
 * - key에 itemId을 조합하는 이유는 관리일자를 사용하는 경우도 커버하기 위함.
 */
export function getCounterKeyForSingleLocationInspection({
  skuId,
  itemId,
}: {
  skuId: number;
  itemId: number;
}) {
  return `S${skuId}-${itemId}`;
}

export function getCounterKeyFromScanResultByInspectingIdInProgress({
  counterData,
  scanResult,
  inspectingIdInProgress,
}: {
  counterData: SKUCountingForInspection;
  scanResult: string;
  inspectingIdInProgress: string | undefined;
}) {
  return Object.values(counterData.counter.counterInfo).find(
    (counter) =>
      (getFormattedSingleSkuId(counter.skuId) === scanResult ||
        counter.skuBarcode === scanResult) &&
      counter.inspectingId === inspectingIdInProgress
  )?.counterKey;
}

export function getSingleLocationCounterKeyFromScanResult({
  counterData,
  scanResult,
  itemIdInprogress,
}: {
  counterData: SKUCountingForInspection;
  scanResult: string;
  itemIdInprogress?: number;
}) {
  return Object.values(counterData.counter.counterInfo).find((counter) => {
    const isSingleLocationItem = counter.counterType === "single";
    const isScannedItem =
      getFormattedSingleSkuId(counter.skuId) === scanResult ||
      counter.skuBarcode === scanResult;
    const isInprogressItem = itemIdInprogress === counter.itemId;

    // 라디오 버튼을 선택한 상태에는 선택된 상품만 스캔할 수 있다.
    return itemIdInprogress
      ? isSingleLocationItem && isScannedItem && isInprogressItem
      : isSingleLocationItem && isScannedItem;
  })?.counterKey;
}

export const checkIsMultiLocationItem = ({
  counterData,
  scanResult,
}: {
  counterData: SKUCountingForInspection;
  scanResult: string;
}) => {
  return Object.values(counterData.counter.counterInfo).some(
    (counter) =>
      (getFormattedSingleSkuId(counter.skuId) === scanResult ||
        counter.skuBarcode === scanResult) &&
      counter.counterType === "multi"
  );
};

export function getIncompleteSingleLocationCounterInfoListFromScannedSkuBarcode({
  counterData,
  scanResult,
}: {
  counterData: SKUCountingForInspection;
  scanResult: string;
}) {
  return Object.values(counterData.counter.counterInfo).filter(
    (counter) =>
      counter.skuBarcode === scanResult &&
      !counter.isCompleteInspecting &&
      counter.counterType === "single"
  );
}

export function getIncompleteSingleLocationCounterKeyFromScannedSkuBarcode({
  counterData,
  scanResult,
}: {
  counterData: SKUCountingForInspection;
  scanResult: string;
}) {
  return Object.values(counterData.counter.counterInfo).find(
    (counter) =>
      counter.skuBarcode === scanResult &&
      !counter.isCompleteInspecting &&
      counter.counterType === "single"
  )?.counterKey;
}

export default function useSKUCountingForInspection({
  items,
  setRowInfoToHighlight,
  unverifiedCount = 0,
  setGroupedItemIdInProgress,
}: {
  items: ReceivingItem[] | undefined;
  setRowInfoToHighlight?: ({ rowKey }: { rowKey: number }) => void;
  unverifiedCount?: number;
  setGroupedItemIdInProgress?: (itemId: number) => void;
}): SKUCountingForInspection {
  const setCanNotLeavePage = useSetRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );

  const { search } = useLocation();

  /**
   * inputting이란, 검수 작업 중(검수수량이 확정되기 전)에 비정상 상품 페이지로 이동한 뒤 검수 페이지로 되돌아왔을 때 작업 중인 정보를 되살리기 위한 데이터이다.
   */
  const inputtingSkuId = getNumberParam(search, "skuId");
  const inputtingItemId = getNumberParam(search, "itemId");
  const inputtingInspectionCount = getNumberParam(search, "inspectionCount");

  const counter = useCounter<InspectionCounterSKU>();

  const [skuInProgress, setSkuInProgress] = useState<InspectionCounterSKU>();

  const setScanInprogressState = useCallback(
    (counterInfo?: UseCounterDataValue<InspectionCounterSKU>) => {
      if (!counterInfo) return;

      setSkuInProgress(counterInfo);

      setRowInfoToHighlight?.({ rowKey: counterInfo.itemId });
      setGroupedItemIdInProgress?.(counterInfo.itemId);
    },
    [setGroupedItemIdInProgress, setRowInfoToHighlight]
  );

  const initCounter = useCallback(
    ({
      counter,
      receivingItemList,
      needReset,
    }: {
      counter: CounterForInspection;
      receivingItemList: ReceivingItem[] | undefined;
      needReset?: boolean; // 이전 local에서 카운팅했던 값을 무시할때 사용
    }) => {
      if (!receivingItemList) return;
      const newInfo: CounterDataForInspection = {};

      receivingItemList.filter(checkForNormalItemAsInspection).forEach((v) => {
        const hasMultiLocationInspection = getHasMultiLocationInspection(
          v.quantity,
          v.inspectItems
        );

        const isInputtingItem = inputtingItemId === v.id;

        v.inspectItems.forEach((ii) => {
          if (!v.skuId || !ii.inspectingId) return;

          // 일반검수와 분할검수의 바코드 형식을 다르게 함
          const counterKey = hasMultiLocationInspection
            ? getCounterKeyForMultiLocationInspection({
                skuId: v.sku.id,
                inspectingId: ii.inspectingId,
              })
            : getCounterKeyForSingleLocationInspection({
                skuId: v.sku.id,
                itemId: v.id,
              });

          const previousCountInfo =
            !needReset && counter.counterInfo
              ? counter.counterInfo[counterKey]
              : null;

          newInfo[counterKey] = {
            counterKey,
            itemId: v.id,
            skuId: v.skuId,
            skuBarcode: v.sku.barCode,
            itemName: v.sku.itemName,
            inspectorId: ii.inspectorId,
            inspectingId: ii.inspectingId,
            isCompleteInspecting: ii.isCompleteInspecting,
            counterType: hasMultiLocationInspection ? "multi" : "single",
            max: ii.quantity,
            current:
              isInputtingItem && !hasMultiLocationInspection
                ? // 분할검수가 진행된 경우에는 이미 확정된 수량이 있기 때문에 inputtingInspectionCount를 사용하지 않음
                  inputtingInspectionCount ||
                  previousCountInfo?.current ||
                  ii.actualQty
                : previousCountInfo?.current || ii.actualQty,
            managementKind: v.sku.managementKind,
            managementDate: v.managementDate,
          };
        });
      });

      counter.initCounterInfo(newInfo);

      const prevInputtingCounterInfo =
        newInfo[
          getCounterKeyForSingleLocationInspection({
            skuId: inputtingSkuId,
            itemId: inputtingItemId,
          })
        ];
      setScanInprogressState(prevInputtingCounterInfo);
    },
    [
      inputtingInspectionCount,
      inputtingItemId,
      inputtingSkuId,
      setScanInprogressState,
    ]
  );

  useEffect(() => {
    initCounter({ counter, receivingItemList: items });
    // counter를 넣을 수 없음
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, initCounter]);

  useEffect(() => {
    if (skuInProgress) {
      setCanNotLeavePage(true);
    } else {
      setCanNotLeavePage(false);
    }
  }, [skuInProgress, setCanNotLeavePage]);

  useEffect(() => {
    if (skuInProgress) {
      const target = counter.counterInfo[skuInProgress.counterKey];
      setSkuInProgress(target);
    } else {
      setSkuInProgress(undefined);
    }
    // counter를 넣을 수 없음
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skuInProgress]);

  const getTotalCount = useCallback(
    (counter: CounterDataForInspection, list: ReceivingItem[] | undefined) => {
      if (!list) return "";

      let sum = 0;
      let total = 0;

      Object.values(counter).forEach((v) => {
        sum += v.current || 0;
      });

      list.forEach((v) => {
        total += v.quantity || 0;
      });

      return `${sum + unverifiedCount} / ${total}`;
    },
    [unverifiedCount]
  );

  const totalCount = useMemo(
    () => getTotalCount(counter.counterInfo, items),
    [items, counter.counterInfo, getTotalCount]
  );

  const reset = useCallback(
    (items: ReceivingItem[]) => {
      setSkuInProgress(undefined);

      initCounter({
        counter,
        receivingItemList: items,
        needReset: true,
      });
    },
    [counter, initCounter]
  );

  return {
    skuInProgress,
    setSkuInProgress,
    counter,
    totalCount,
    reset,
  };
}
