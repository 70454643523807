import styled from "styled-components";

const basicRowOfManagementDateContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 4px;
  text-align: left;
`;

export default {
  basicRowOfManagementDateContainer,
};
