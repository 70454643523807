import { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import SHIPPING_QUERY from "@sellernote/_shared/src/queries/fulfillment/SHIPPING_QUERY";
import { FULFILLMENT_SHIPPING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/shipping";
import ConfirmWrapper from "@sellernote/_sds-v1/src/components/ConfirmWrapper";

export default function useIssueAllShippingList() {
  const shippingList = useRecoilValue(FULFILLMENT_SHIPPING_ATOMS.SHIPPING_LIST);
  const hasShippingList = shippingList.length > 0;

  const {
    mutate: issueShippingInvoice,
    ResponseHandler: ResponseHandlerOfIssuingAllShippingInvoice,
  } = SHIPPING_QUERY.useIssueShippingInvoice();

  const handleAllListIssue = useCallback(() => {
    const isNotIssuedShippingIdList = shippingList
      .filter((v) => !v.isIssue)
      .map((v) => v.id);

    // * 빈 배열이면 요청 X
    if (isNotIssuedShippingIdList.length > 0) {
      issueShippingInvoice({ ids: isNotIssuedShippingIdList });
    }
  }, [issueShippingInvoice, shippingList]);

  const IssueAllShippingList = useMemo(
    () => (
      <ConfirmWrapper
        confirmMessageTitle="전체출력을 진행하시겠습니까?"
        confirmModalUiType="titleOnly"
        disabled={!hasShippingList}
      >
        <Button
          label="전체출력"
          size="small"
          theme="secondary"
          handleClick={handleAllListIssue}
          disabled={!hasShippingList}
        />
      </ConfirmWrapper>
    ),
    [handleAllListIssue, hasShippingList]
  );

  return { IssueAllShippingList, ResponseHandlerOfIssuingAllShippingInvoice };
}
