import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { ScanType } from "@sellernote/_shared/src/types/fulfillment/scan";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";

import Styled from "../index.styles";
import useInvoiceDirectInput from "./useInvoiceDirectInput";

export default function InvoiceDirectInput({
  onScanButtonSelect,
}: {
  onScanButtonSelect: (type: ScanType | undefined) => void;
}) {
  const {
    isActiveInvoiceDirectInputButton,

    isVisibleInvoiceDirectInputModal,

    invoiceNo,
    setInvoiceNo,

    handleDirectInputModalOpen,
    handleDirectInputModalClose,
    handleDirectInputModalConfirm,

    PickingInvoiceMessageModal,
    ResponseHandlerOfPickingInvoice,
  } = useInvoiceDirectInput({ onScanButtonSelect });

  return (
    <>
      <Button
        className="scan-button"
        theme={"secondary"}
        size="small"
        label="송장 직접입력"
        handleClick={handleDirectInputModalOpen}
        disabled={!isActiveInvoiceDirectInputButton}
      />

      <Modal
        uiType="contentWithCustomBody"
        active={isVisibleInvoiceDirectInputModal}
        title="송장 직접입력"
        body={
          <Styled.invoiceDirectInputModalBody>
            <InputText
              label="송장번호"
              placeholder="입력해주세요."
              borderType="outline"
              valueType="string"
              value={invoiceNo}
              setValue={setInvoiceNo}
            />

            <Button
              theme="primary"
              size="normal"
              label="확인"
              handleClick={handleDirectInputModalConfirm}
              disabled={!invoiceNo}
            />
          </Styled.invoiceDirectInputModalBody>
        }
        onClose={handleDirectInputModalClose}
      />

      {ResponseHandlerOfPickingInvoice}
      {PickingInvoiceMessageModal}
    </>
  );
}
