import { useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import CANCELING_QUERY from "@sellernote/_shared/src/queries/fulfillment/CANCELING_QUERY";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { getStatusWhenCanceled } from "@sellernote/_shared/src/utils/fulfillment/canceling";
import {
  getTeamLabelForBofulWorker,
  getWorkerNameById,
} from "@sellernote/_shared/src/utils/fulfillment/common";

/**
 * 취소 상세 정보 조회
 */
export default function useCancelingDetail() {
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<{ from?: string }>();

  const currentUser = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );
  const workerList = useRecoilValue(FULFILLMENT_COMMON_ATOMS.WORKER_LIST);

  const {
    data: cancelingDetail,
    ResponseHandler: ResponseHandlerOfGettingCancelingDetail,
  } = CANCELING_QUERY.useGetCancelingDetail(
    // 피킹 화면에서 이동, 송장 스캔 이동한 경우에는 송장번호로 조회
    state?.from === "picking" || state?.from === "scanning"
      ? {
          invoiceNo: id,
        }
      : {
          id: Number(id),
        }
  );

  const [packingRestocker, setPackingRestocker] = useState("");

  const currentUserIsNotManagerInPicking =
    currentUser?.authority === "manager" &&
    cancelingDetail?.shipping.wmsStatus === "picking" &&
    cancelingDetail.managerIdWhenCanceled !== currentUser?.id;

  // 피킹 취소의 경우 자신이 재입고 담당자로 이미 존재하므로, 다른 경우에만 단순 조회
  const isRestockingInvoice =
    cancelingDetail?.restockerId !== null &&
    cancelingDetail?.restockerId !== currentUser?.id;

  const cancelingDetailSummary = useMemo(
    () => ({
      cancelingId: cancelingDetail?.id ?? 0,
      canceller:
        cancelingDetail?.canceller === "warehouseMaster" ? "관리자" : "고객",
      customer: getTeamLabelForBofulWorker(cancelingDetail?.shipping.team),
      shippingId: cancelingDetail?.shipping.id ?? 0,
      invoiceNo: cancelingDetail?.shipping.invoiceNo ?? "",
      statusWhenCanceled: getStatusWhenCanceled(
        cancelingDetail?.shipping.wmsStatus
      ),
      workerWhenCanceled: getWorkerNameById(
        workerList,
        cancelingDetail?.managerIdWhenCanceled
      ),
      restocker: getWorkerNameById(workerList, cancelingDetail?.restockerId),
    }),
    [cancelingDetail, workerList]
  );

  return {
    packingRestocker,
    setPackingRestocker,
    cancelingDetailSummary,
    currentUserIsNotManagerInPicking,
    isRestockingInvoice,

    ResponseHandlerOfGettingCancelingDetail,
  };
}
