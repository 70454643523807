import React from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { SKUInfoForCancelingRestocking } from "@sellernote/_shared/src/types/fulfillment/canceling";
import { ScanType } from "@sellernote/_shared/src/types/fulfillment/scan";

import {
  CompletedList,
  WorkingLocation,
} from "hooks/canceling/useRestockCanceling";
import useScanCancelingSKU from "hooks/canceling/useScanCancelingSKU";

export default function ScanSKUButton({
  selectedScanButtonType,
  handleScanButtonClick,
  workingLocation,
  setWorkingLocation,
  setRowInfoToHighlight,
  SKUInfoForScanning,
  setSKUInfoForScanning,
  completedList,
  isFirstSKUScan,
}: {
  selectedScanButtonType: ScanType | undefined;
  handleScanButtonClick: (type: ScanType | undefined) => void;
  workingLocation: WorkingLocation | undefined;
  setWorkingLocation: React.Dispatch<
    React.SetStateAction<WorkingLocation | undefined>
  >;
  setRowInfoToHighlight: React.Dispatch<
    React.SetStateAction<TableRowInfoToHighlight | undefined>
  >;
  SKUInfoForScanning: SKUInfoForCancelingRestocking[];
  setSKUInfoForScanning: React.Dispatch<
    React.SetStateAction<SKUInfoForCancelingRestocking[]>
  >;
  completedList: React.MutableRefObject<CompletedList>;
  isFirstSKUScan: React.MutableRefObject<boolean>;
}) {
  const { ScanSKUMessageModal, modalOpenHandler } = useScanCancelingSKU({
    selectedScanButtonType,
    handleScanButtonClick,
    workingLocation,
    setWorkingLocation,
    setRowInfoToHighlight,
    SKUInfoForScanning,
    setSKUInfoForScanning,
    completedList,
    isFirstSKUScan,
  });

  return (
    <>
      <Button
        className="scan-sku"
        theme={selectedScanButtonType === "sku" ? "scan" : "tertiary"}
        size="small"
        label="상품 스캔"
        handleClick={() =>
          // 위치를 먼저 스캔해주세요.
          !workingLocation
            ? modalOpenHandler.openLocationScanFirstMessage()
            : handleScanButtonClick("sku")
        }
      />

      {ScanSKUMessageModal}
    </>
  );
}
