import React, { useCallback, useMemo, useState } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { SHIPPING_DELIVERY_TYPE } from "@sellernote/_shared/src/constants/fulfillment/shipping";
import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import SHIPPING_QUERY from "@sellernote/_shared/src/queries/fulfillment/SHIPPING_QUERY";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import {
  ShippingListItem,
  UnSetRowInfo,
} from "@sellernote/_shared/src/types/fulfillment/shipping";
import { IssueOptionList } from "@sellernote/_shared/src/types/fulfillment/shipping";
import {
  isBeforeToday,
  toFormattedDate,
} from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { getTeamLabelForBofulWorker } from "@sellernote/_shared/src/utils/fulfillment/common";
import {
  getNumberOfSKUType,
  getSumOfSKUQuantity,
} from "@sellernote/_shared/src/utils/fulfillment/fulfillment";

import { FilterForIsMine } from "pages/shipping/confirm";

export type ShippingTableItem = {
  customerCompany: React.ReactNode;
  numberOfSKUType: string;
  sumOfSKUQuantity: string;
  deliveryType: string;
  issue: React.ReactNode;
  isIssue: string;
} & Pick<ShippingListItem, "id" | "invoiceNo" | "dueDate">;

const PAGE_UNIT = 10;

export default function useShippingList({
  filterForIsMine,
  filterForIsIssue,
  currentPage,
}: {
  filterForIsMine: FilterForIsMine;
  filterForIsIssue: IssueOptionList;
  currentPage: number;
}) {
  const [unSetRowInfo, setUnSetRowInfo] = useState<UnSetRowInfo>();

  const {
    data: shippingList,
    ResponseHandler: ResponseHandlerOfGettingShippingList,
  } = SHIPPING_QUERY.useGetPDAShippingList({
    params: {
      page: currentPage,
      perPage: PAGE_UNIT,
      isMyTask: filterForIsMine === "mine",
      filter: filterForIsMine === "mine" ? filterForIsIssue : "all",
    },
  });

  const {
    mutate: issueShippingInvoice,
    ResponseHandler: ResponseHandlerOfIssuingShippingInvoice,
  } = SHIPPING_QUERY.useIssueShippingInvoice();

  const {
    mutate: moveToMyTask,
    ResponseHandler: ResponseHandlerOfMovingToMyTask,
  } = SHIPPING_QUERY.useMoveToMyTask({
    setUnSetRowInfo,
  });

  const handleItemIssue = useCallback(
    (id: number) => issueShippingInvoice({ ids: [id] }),
    [issueShippingInvoice]
  );

  const handleUnSetRowClick = useCallback(
    (rowInfo: ShippingListItem) => () => {
      const unSetRowInfo: UnSetRowInfo = {
        id: rowInfo.id,
        companyName: rowInfo.team?.company ?? "-",
        teamName: rowInfo.team?.name ?? "-",
        sumOfSKUQuantity: toThousandUnitFormat(
          getSumOfSKUQuantity({ items: rowInfo.items })
        ),
      };

      setUnSetRowInfo(unSetRowInfo);
    },
    []
  );

  const handleUnsetTaskMoveToMyTask = useCallback(
    (id: number) => moveToMyTask({ ids: [id] }),
    [moveToMyTask]
  );

  const shippingTableList =
    useMemo((): TableDataListItem<ShippingTableItem>[] => {
      const rawList = shippingList?.list;

      if (!rawList) {
        return [];
      }

      return rawList.map<TableDataListItem<ShippingTableItem>>((v) => ({
        rowKey: v.id,

        rowFontColor: isBeforeToday(v.dueDate) ? COLOR.pointWarning : undefined,

        handleRowClick:
          filterForIsMine === "unSet" ? handleUnSetRowClick(v) : undefined,

        customerCompany: getTeamLabelForBofulWorker(v.team),

        id: v.id,

        numberOfSKUType: toThousandUnitFormat(
          getNumberOfSKUType({ items: v.items })
        ),

        sumOfSKUQuantity: toThousandUnitFormat(
          getSumOfSKUQuantity({ items: v.items })
        ),

        deliveryType: SHIPPING_DELIVERY_TYPE[v.deliveryType],

        invoiceNo: v.invoiceNo,

        dueDate: toFormattedDate(v.dueDate, `M월 D일`),

        issue: (
          <Button
            theme="tertiary"
            size="small"
            label="출력"
            handleClick={(e) => {
              e.stopPropagation();
              handleItemIssue(v.id);
            }}
            disabled={filterForIsMine === "unSet"}
          />
        ),

        isIssue: v.isIssue ? "○" : "✕",
      }));
    }, [filterForIsMine, handleItemIssue, handleUnSetRowClick, shippingList]);

  const MoveToMyTaskModal = useMemo(
    () => (
      <Modal
        uiType="content"
        active={unSetRowInfo !== undefined}
        title="해당 건을 My의뢰로 이동시키겠습니까?"
        body={
          <>
            <div>
              {unSetRowInfo?.id} <br />
              {`${unSetRowInfo?.companyName ?? "-"} (${
                unSetRowInfo?.teamName ?? "-"
              }) / ${toThousandUnitFormat(unSetRowInfo?.sumOfSKUQuantity)} PCS`}
            </div>
          </>
        }
        actionPositive={{
          label: "예",
          handleClick: () => handleUnsetTaskMoveToMyTask(unSetRowInfo?.id ?? 0),
        }}
        actionNegative={{
          label: "아니오",
          handleClick: () => setUnSetRowInfo(undefined),
        }}
      />
    ),
    [handleUnsetTaskMoveToMyTask, unSetRowInfo]
  );

  return {
    shippingTotal: shippingList?.total ?? 0,
    shippingAnotherTotal: shippingList?.anotherTotal ?? 0,
    shippingTableList,

    MoveToMyTaskModal,

    ResponseHandlerOfGettingShippingList,
    ResponseHandlerOfIssuingShippingInvoice,
    ResponseHandlerOfMovingToMyTask,
  };
}
