import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  RESET_PLACER_AND_WAREHOUSING_OF_ITEM_REQ,
  RESET_PLACER_AND_WAREHOUSING_OF_ITEM_REQ_PATH_PARAMS,
} from "@sellernote/_shared/src/api-interfaces/boful-api/returning";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";

import { returningActions } from "modules/returning";

export default function useRemoveWarehousingPersonInCharge() {
  const dispatch = useDispatch();

  const {
    mutate: resetPlacerAndWarehousingOfItem,
    ResponseHandler: ResponseHandlerOfRemovingPersonInCharge,
  } = RETURNING_QUERY.useResetPlacerAndWarehousingOfItem();

  const removePersonInCharge = useCallback(
    ({
        returningId,
        itemId,
        placingId,
        resetAfterRemoveInProgressPersonInCharge,
      }: RESET_PLACER_AND_WAREHOUSING_OF_ITEM_REQ &
        RESET_PLACER_AND_WAREHOUSING_OF_ITEM_REQ_PATH_PARAMS & {
          resetAfterRemoveInProgressPersonInCharge: () => void;
        }) =>
      () => {
        if (!returningId) return;

        resetPlacerAndWarehousingOfItem(
          {
            pathParams: { returningId, itemId },
            placingId,
          },
          {
            onSuccess: () => {
              dispatch(
                returningActions.GET_DETAIL({
                  id: returningId,
                  _postSuccessCallback:
                    resetAfterRemoveInProgressPersonInCharge,
                })
              );
            },
          }
        );
      },
    [dispatch, resetPlacerAndWarehousingOfItem]
  );

  return { removePersonInCharge, ResponseHandlerOfRemovingPersonInCharge };
}
