import { useEffect, useState } from "react";

import { InventoryDetailBySKUId } from "@sellernote/_shared/src/api-interfaces/boful-api/inventory";
import { BofulLocation } from "@sellernote/_shared/src/types/fulfillment/common";

import { SkuToSelect } from "../SelectSkuModal/useSelectSkuModal";

export interface InventoryToConsolidate {
  locationId: number | undefined;
  availableQty: number;
  consolidationQty: number;
}

export default function useInventoryConsolidation() {
  const [inventoryDetailOfSelectedSku, setInventoryDetailOfSelectedSku] =
    useState<InventoryDetailBySKUId[]>();
  const [listOfInventoryToConsolidate, setListOfInventoryToConsolidate] =
    useState<InventoryToConsolidate[]>([
      { locationId: undefined, availableQty: 0, consolidationQty: 0 },
    ]);
  const [locationToConsolidate, setLocationToConsolidate] = useState<
    BofulLocation | undefined
  >();
  const [selectedSku, setSelectedSku] = useState<SkuToSelect>();

  useEffect(() => {
    if (inventoryDetailOfSelectedSku) {
      const hasManagementInfo = Boolean(
        selectedSku?.managementKind && selectedSku?.managementDate
      );

      setListOfInventoryToConsolidate(
        inventoryDetailOfSelectedSku.reduce<InventoryToConsolidate[]>(
          (acc, { managementDate, locationId, availableQty }) => {
            if (hasManagementInfo) {
              if (managementDate === selectedSku?.managementDate) {
                acc.push({
                  locationId,
                  availableQty,
                  consolidationQty: 0,
                });
                return acc;
              }

              return acc;
            }

            acc.push({
              locationId,
              availableQty,
              consolidationQty: 0,
            });
            return acc;
          },
          []
        )
      );

      setLocationToConsolidate(undefined);
    }
  }, [
    inventoryDetailOfSelectedSku,
    selectedSku?.managementDate,
    selectedSku?.managementKind,
  ]);

  return {
    inventoryDetailOfSelectedSku,
    setInventoryDetailOfSelectedSku,

    listOfInventoryToConsolidate,
    setListOfInventoryToConsolidate,

    locationToConsolidate,
    setLocationToConsolidate,

    selectedSku,
    setSelectedSku,
  };
}
