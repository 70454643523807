import { useCallback, useMemo, useState } from "react";

export type KeyType = string | number;

export type SelectionDict = { [K in KeyType]: boolean };

export interface MultiSelectInfo {
  initSelectionDict: (keyList: KeyType[]) => void;
  selectionDict: SelectionDict;
  select: (key: KeyType) => void;
  unSelect: (key: KeyType) => void;
  selectAll: () => void;
  unSelectAll: () => void;
  selectedCount: number;
  isAllSelected: boolean;
  resetSelectionDict: () => void;
  selectedIdList: number[];
  selectAllByParam: (keyList: KeyType[]) => void;
}

export default function useMultiSelect(): MultiSelectInfo {
  const [selectionDict, setSelectionDict] = useState<SelectionDict>({});

  const initSelectionDict = useCallback((keyList: KeyType[]) => {
    const dict: SelectionDict = {};
    keyList.forEach((v) => {
      dict[v] = false;
    });

    setSelectionDict(dict);
  }, []);

  const select = useCallback((key: KeyType) => {
    setSelectionDict((dict) => {
      const newDict = { ...dict };
      newDict[key] = true;
      return newDict;
    });
  }, []);

  const unSelect = useCallback((key: KeyType) => {
    setSelectionDict((dict) => {
      const newDict = { ...dict };
      newDict[key] = false;
      return newDict;
    });
  }, []);

  const selectAll = useCallback(() => {
    setSelectionDict((dict) => {
      const newDict = { ...dict };

      Object.keys(dict).forEach((key) => {
        newDict[key] = true;
      });

      return newDict;
    });
  }, []);

  const unSelectAll = useCallback(() => {
    setSelectionDict((dict) => {
      const newDict = { ...dict };

      Object.keys(dict).forEach((key) => {
        newDict[key] = false;
      });

      return newDict;
    });
  }, []);

  const resetSelectionDict = useCallback(() => {
    setSelectionDict({});
  }, []);

  const [selectedCount, isAllSelected] = useMemo(() => {
    let selectedCount = 0;
    let isAllSelected = true;

    Object.keys(selectionDict).forEach((key) => {
      if (selectionDict[key] === true) {
        selectedCount++;
      } else {
        isAllSelected = false;
      }
    });

    if (!selectedCount) isAllSelected = false;

    return [selectedCount, isAllSelected];
  }, [selectionDict]);

  // TODO: 현재까지는 key에 list의 id 값을 넣어서 사용하고 있기 떄문에 number[]로 반환
  const selectedIdList = useMemo(
    () =>
      Object.entries(selectionDict).reduce<number[]>(
        (selectedIdList, [key, isChecked]) => {
          if (isChecked) {
            selectedIdList.push(Number(key));
          }

          return selectedIdList;
        },
        []
      ),
    [selectionDict]
  );

  const selectAllByParam = useCallback((keyList: KeyType[]) => {
    const dict: SelectionDict = {};

    keyList.forEach((v) => {
      dict[v] = true;
    });

    setSelectionDict(dict);
  }, []);

  return {
    initSelectionDict,
    selectionDict,
    select,
    unSelect,
    selectAll,
    unSelectAll,
    selectedCount,
    isAllSelected,
    resetSelectionDict,
    selectedIdList,
    selectAllByParam,
  };
}
