import { useMemo } from "react";

import { ManagementDateForm } from "..";
import Styled from "./index.styles";

export default function TotalCount({
  goalCount,
  managementDateFormList,
}: {
  goalCount: number;
  managementDateFormList: ManagementDateForm[];
}) {
  const currentCount = useMemo(() => {
    return managementDateFormList.reduce((a, c) => {
      return a + (c.quantity ?? 0);
    }, 0);
  }, [managementDateFormList]);

  const isValidCount = !!goalCount && currentCount === goalCount;

  return (
    <Styled.totalCount isValidCount={isValidCount}>
      <span className="current-count">{currentCount}</span> / {goalCount} PCS
    </Styled.totalCount>
  );
}
