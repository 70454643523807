import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReactSignatureCanvas from "react-signature-canvas";

import useUploadFile from "@sellernote/_shared/src/hooks/common/useUploadFile";
import SHIPPING_QUERY from "@sellernote/_shared/src/queries/fulfillment/SHIPPING_QUERY";
import {
  ShipmentStatusType,
  ShippingShipmentListItem,
} from "@sellernote/_shared/src/types/fulfillment/shipping";

import useScanShipmentInvoice from "./useScanShipmentInvoice";

export default function useConsigneeSignatureModal({
  data,
  handleModalClose,
}: {
  data?: ShippingShipmentListItem;
  handleModalClose: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [visibleScanModal, setVisibleScanModal] = useState(false);

  const signatureCanvas = useRef<ReactSignatureCanvas>(null);
  const [signatureImageURL, setSignatureImageURL] = useState<string>("");

  const { status } = useParams<{ status: ShipmentStatusType }>();

  const {
    isCompleted,
    ScanModal,
    ScanMessageModal,
    ResponseHandlerOfGettingShipmentTruckPackings,
  } = useScanShipmentInvoice({
    shippingId: data?.id ?? 0,
    visibleScanModal,
    setVisibleScanModal,
  });

  // 기본적으로 서명을 막기, 첫 진입 시점(data가 바뀌었을 때)에는 서명 Off
  useEffect(() => {
    signatureCanvas.current?.off();
  }, [data]);

  // 스캔이 모두 완료된 경우 서명을 On시킨다.
  useEffect(() => {
    if (isCompleted) signatureCanvas.current?.on();
  }, [isCompleted]);

  // 리셋 시에는 캔버스를 지우고, URL도 지워서 마감버튼을 비활성화시킨다.
  const handleSignatureReset = useCallback(() => {
    signatureCanvas.current?.clear();
    setSignatureImageURL("");
  }, []);

  const handleScanStart = useCallback(() => {
    setVisibleScanModal(true);
  }, []);

  const handleConsigneeSignatureModalClose = useCallback(() => {
    handleModalClose(false);
  }, [handleModalClose]);

  const {
    mutate: completeShipmentTruck,
    ResponseHandler: ResponseHandlerOfCompletingShipmentTruck,
  } = SHIPPING_QUERY.useCompleteShipmentTruck({
    shippingId: data?.id ?? 0,
    handleConsigneeSignatureModalClose,
    status,
  });

  const [uploadFile, LoadingToUpload, FailureModal] = useUploadFile();
  const handleShipmentComplete = useCallback(() => {
    uploadFile({
      data: {
        fileName: `signature(${data?.id}).png`,
        fileBase64String: signatureImageURL,
        name: `signature(${data?.id}).png`,
        targetId: data?.id ?? 0,
        actorKind: "consignee",
        mimeType: "image/png",
        domain: "signature",
      },
      onUploadSuccess: completeShipmentTruck,
    });
  }, [completeShipmentTruck, data?.id, signatureImageURL, uploadFile]);

  return {
    isCompleted,
    signatureCanvas,
    signatureImageURL,
    setSignatureImageURL,
    handleScanStart,
    handleSignatureReset,
    handleShipmentComplete,
    handleConsigneeSignatureModalClose,

    ScanModal,
    ScanMessageModal,

    LoadingToUpload,
    UploadSignatureFailureModal: FailureModal,

    ResponseHandlerOfGettingShipmentTruckPackings,
    ResponseHandlerOfCompletingShipmentTruck,
  };
}
