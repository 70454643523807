import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

const inputForCounting = styled.div`
  > .input-text {
    margin-bottom: 40px;

    .available-qty {
      color: ${COLOR.primaryBlue};
    }
  }
`;

export default {
  inputForCounting,
};
