import { useMemo } from "react";

import { TempPhotoItem } from "hooks/common/useSelectPhotoList";

import { Form } from ".";

export default function useValid({
  form,
  prevForm,
  tempPhotoList,
  isChangedPhoto,
}: {
  form: Form;
  prevForm: Form;
  tempPhotoList: TempPhotoItem[];
  isChangedPhoto: boolean;
}) {
  const isValidMaxQuantity = (form.quantity ?? 0) < Number.MAX_SAFE_INTEGER;

  const isValidQuantity = form.quantity && form.quantity > 0;

  const isValidMemo = form.memo ? form.memo.length <= 200 : true;

  // 등록 시, 수량과 업로드 할 사진이 있는지 확인. 비고는 선택입력이나 입력 시에는 200자 이하.
  const isValidForm =
    isValidQuantity &&
    isValidMaxQuantity &&
    !!tempPhotoList.length &&
    isValidMemo;

  const isChangedForm = useMemo(() => {
    const isChangedQuantity =
      (prevForm?.quantity ?? 0) !== Number(form.quantity ?? 0);

    const isChangedMemo = prevForm?.memo !== form.memo;

    return isChangedMemo || isChangedQuantity || isChangedPhoto;
  }, [
    form.memo,
    form.quantity,
    isChangedPhoto,
    prevForm?.memo,
    prevForm?.quantity,
  ]);

  // 수정 시, 수량, 메모가 변경되었는지 확인.
  // 업로드 할 사진이 있는지 확인, 삭제했다면 남아있는 사진이나 업로드 할 사진이 하나라도 있는지 확인.
  const isValidUpdateForm = useMemo(() => {
    return (
      isChangedForm && isValidMaxQuantity && isValidQuantity && isValidMemo
    );
  }, [isChangedForm, isValidMaxQuantity, isValidMemo, isValidQuantity]);

  const quantityErrorMessage = form.quantity
    ? ((form.quantity ?? 0) < 1 && "양의 정수만 입력 가능합니다") ||
      (!isValidMaxQuantity && "입력하신 수량은 등록 불가능합니다.")
    : undefined;

  const memoErrorMessage =
    form.memo.length > 200 ? "200자 이하로 입력해주세요." : undefined;

  return {
    quantityErrorMessage,
    memoErrorMessage,
    isValidUpdateForm,
    isValidForm,
    isChangedForm,
  };
}
