import React from "react";
import { useLocation } from "react-router-dom";

import {
  TableColumnInfo,
  TableDataListItem,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { TabFilterItem } from "@sellernote/_shared/src/headlessComponents/useTabFilter";
import { ShippingDeliveryType } from "@sellernote/_shared/src/types/fulfillment/shipping";
import {
  ShipmentDelayFilterType,
  ShipmentStatusType,
  ShipmentTodayAndOrderFilterType,
} from "@sellernote/_shared/src/types/fulfillment/shipping";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";
import Paging from "@sellernote/_sds-v1/src/components/Paging";
import TabFilter from "@sellernote/_sds-v1/src/components/TabFilter";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import { ShipmentParcelDetailTableItem } from "hooks/shipping/useShipmentClosingParcelDetail";
import { ShipmentTruckDetailTableItem } from "hooks/shipping/useShipmentClosingTruckDetail";

import Styled from "./index.styles";

type DetailOptionList =
  | "당일 출하 예정 건"
  | "현재 마감 건"
  | "현재 미처리 건"
  | "출하 준비완료"
  | "출하 마감";

const detailTitle = new Map<string, DetailOptionList>([
  ["expected", "당일 출하 예정 건"],
  ["completed", "현재 마감 건"],
  ["unprocessed", "현재 미처리 건"],
  ["ready", "출하 준비완료"],
  ["done", "출하 마감"],
]);

export type ConditionalFilterType<Status> = Status extends "expected"
  ? ShipmentTodayAndOrderFilterType
  : ShipmentDelayFilterType;

function getColumnInfo<T extends ShippingDeliveryType>(
  deliveryType: T
): T extends "parcel"
  ? TableColumnInfo<ShipmentParcelDetailTableItem>
  : TableColumnInfo<ShipmentTruckDetailTableItem>;
function getColumnInfo(deliveryType: any): any {
  return deliveryType === "parcel"
    ? {
        dueDate: {
          label: "발송희망일자",
          fixedWidth: 100,
        },
        id: {
          label: "출고요청번호",
          fixedWidth: 100,
        },
        customerCompany: {
          label: "고객사",
          fixedWidth: 120,
        },
        parcelCompany: {
          label: "운송사",
          fixedWidth: 100,
        },
        invoiceNo: {
          label: "운송장번호",
          fixedWidth: 120,
        },
        isDone: {
          label: "출하여부",
        },
        status: {
          label: "상태",
          fixedWidth: 100,
        },
      }
    : {
        dueDate: {
          label: "발송희망일자",
          fixedWidth: 100,
        },
        dueTime: {
          label: "도착희망시각",
          fixedWidth: 100,
        },
        id: {
          label: "출고요청번호",
          fixedWidth: 100,
        },
        customerCompany: {
          label: "고객사",
          fixedWidth: 120,
        },
        customerAddress: {
          label: "목적지",
          fixedWidth: 120,
        },
        boxQty: {
          label: "박스 수",
        },
        isTruckAppointed: {
          label: "배차여부",
        },
        isDone: {
          label: "출하여부",
        },
        status: {
          label: "상태",
          fixedWidth: 100,
        },
      };
}

export default function ShippingShipmentClosingDetail<
  T extends ShippingDeliveryType,
  Status extends ShipmentStatusType
>({
  deliveryType,
  titleInfo,
  dataList,
  filter,
  paging,
}: {
  deliveryType: ShippingDeliveryType;
  titleInfo: {
    label: ShipmentStatusType;
    value: number;
  };
  dataList: T extends "parcel"
    ? TableDataListItem<ShipmentParcelDetailTableItem>[]
    : TableDataListItem<ShipmentTruckDetailTableItem>[];
  filter: {
    selectedFilter: ConditionalFilterType<Status>;
    setFilter: (filter: ConditionalFilterType<Status>) => void;
    filterList: TabFilterItem<ConditionalFilterType<Status>>[];
  };
  paging: {
    pageSize: number;
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  };
}) {
  const location = useLocation();

  const shipmentPath = location.pathname.split("/");
  const DELIVERY_TYPE_INDEX_IN_PATH = 4;
  const SHIPMENT_STATUS_INDEX_IN_PATH = 6;

  const tableColumnInfo = getColumnInfo(deliveryType);

  return (
    <Styled.container>
      {!(
        shipmentPath[DELIVERY_TYPE_INDEX_IN_PATH] === "truck" &&
        shipmentPath[SHIPMENT_STATUS_INDEX_IN_PATH]
      ) && (
        <ListItem
          type="withContentAlignRight"
          label={detailTitle.get(titleInfo.label)}
          value={titleInfo.value}
        />
      )}

      {filter.filterList.length > 0 && (
        <TabFilter<ConditionalFilterType<Status>>
          filterList={filter.filterList}
          selectedFilter={filter.selectedFilter}
          setFilter={filter.setFilter}
        />
      )}

      <Paging
        pageSize={paging.pageSize}
        currentPage={paging.currentPage}
        currentPageData={
          <Table<ShipmentParcelDetailTableItem | ShipmentTruckDetailTableItem>
            columnInfo={tableColumnInfo}
            dataList={dataList}
          />
        }
        handleClickPage={paging.setCurrentPage}
        isZeroBasedPage
      />
    </Styled.container>
  );
}
