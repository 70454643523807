import { useCallback } from "react";
import { useState } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { ReceivingWorker } from "@sellernote/_shared/src/types/fulfillment/receiving";

import CandidateSelector from "./CandidateSelector";

function AddPersonInCharge({
  isManager,
  workerList,
}: {
  isManager: boolean;
  workerList?: ReceivingWorker[];
}) {
  const [isVisibleSelector, setIsVisibleSelector] = useState(false);

  const handleAddPersonOpen = useCallback(() => {
    setIsVisibleSelector(true);
  }, []);

  const handleAddPersonClose = useCallback(() => {
    setIsVisibleSelector(false);
  }, []);

  return (
    <>
      <Button
        label={`담당자 추가(${workerList?.length ?? 0})`}
        size="small"
        theme="secondary"
        handleClick={handleAddPersonOpen}
        disabled={!isManager}
      />

      {isVisibleSelector && (
        <CandidateSelector
          deActivate={handleAddPersonClose}
          existedWorkerList={workerList}
        />
      )}
    </>
  );
}

export default AddPersonInCharge;
