import { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";

import CANCELING_QUERY from "@sellernote/_shared/src/queries/fulfillment/CANCELING_QUERY";
import { FULFILLMENT_CANCELING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/canceling";
import { SKUInfoForCancelingRestocking } from "@sellernote/_shared/src/types/fulfillment/canceling";

import { ProblemList } from "pages/canceling/detail/:id/RestockInvoice/ReportProblemButton/ReportProblemModal";

/**
 * 취소 문제보고 완료
 */
export default function useCompleteReportingCancelingProblem({
  SKUInfoForScanning,
  problemList,
  moveToCancelingListPage,
  handleReportProblemModalClose,
}: {
  SKUInfoForScanning: SKUInfoForCancelingRestocking[];
  problemList: ProblemList;
  moveToCancelingListPage: () => void;
  handleReportProblemModalClose: () => void;
}) {
  const { cancelingId } = useRecoilValue(
    FULFILLMENT_CANCELING_ATOMS.CANCELING_DETAIL
  );

  const {
    mutate: reportCancelingProblem,
    ResponseHandler: ResponseHandlerOfReportingCancelingProblem,
    ResponseHandlerOfRestockingCancelingInvoice,
  } = CANCELING_QUERY.useReportCancelingProblem({
    cancelingId,
    moveToCancelingListPage,
    handleReportProblemModalClose,
    restockItems: SKUInfoForScanning.map((v) => ({
      itemId: v.id,
      restockQty: v.currentQty,
    })),
  });

  const isDisabledCompleteButton = useMemo(() => {
    const problemListValues = Object.values(problemList);
    const selectedProblemKindList = problemListValues.filter(
      (v) => v.problemKind?.value !== undefined
    );

    if (selectedProblemKindList.length === 0) {
      return true;
    } else {
      const isAllSelectedProblem =
        selectedProblemKindList.length === problemListValues.length;

      // directInput이 있는 경우 모두 값이 들어있어야 활성화
      let isAllCompletedDirectInput = true;
      const directInputList = selectedProblemKindList.filter(
        (v) => v.problemKind?.value === "directInput"
      );
      const hasDirectInputList = directInputList.length > 0;
      if (hasDirectInputList) {
        isAllCompletedDirectInput = directInputList.every(
          (v) => Number(v.problemDirectInput?.length) > 0
        );
      }
      return !(isAllSelectedProblem && isAllCompletedDirectInput);
    }
  }, [problemList]);

  const handleReportProblemConfirm = useCallback(() => {
    const problemItems = Object.entries(problemList).map(([itemId, v]) => ({
      itemId: Number(itemId),
      problemKind: v.problemKind?.value ?? "none",
      problemDirectInput: v.problemDirectInput,
    }));

    reportCancelingProblem({
      problemItems,
    });
  }, [problemList, reportCancelingProblem]);

  return {
    handleReportProblemConfirm,
    isDisabledCompleteButton,
    ResponseHandlerOfReportingCancelingProblem,
    ResponseHandlerOfRestockingCancelingInvoice,
  };
}
