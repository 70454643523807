import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.section`
  > .actions {
    margin-top: 32px;

    > .button {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
`;

const info = styled.section`
  > .kind {
    ${setFontStyle("SubHead2", "Bold")};
    color: ${COLOR.grayScale_800};

    margin-bottom: 8px;
  }

  > .summary {
    border-bottom: 1px solid ${COLOR.grayScale_200};
  }

  > .detail {
    .total-packing-unit {
      ${setFontStyle("Body7", "Bold")};
      color: ${COLOR.grayScale_800};
      margin-right: 8px;
    }
  }
`;

export default {
  container,
  info,
};
