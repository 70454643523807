import SvgIconWrapper from "./SvgIconWrapper";

export default function CheckBoxCircleSelectedFilledIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_917_7743)">
          <path
            d="M2 20C2 10.0578 10.0578 2 20 2C29.9422 2 38 10.0578 38 20C38 29.9422 29.9422 38 20 38C10.0578 38 2 29.9422 2 20ZM28.1422 16.8922C28.9086 16.1258 28.9086 14.8742 28.1422 14.1078C27.3758 13.3414 26.1242 13.3414 25.3578 14.1078L17.75 21.7156L14.6422 18.6078C13.8758 17.8414 12.6242 17.8414 11.8578 18.6078C11.0914 19.3742 11.0914 20.6258 11.8578 21.3922L16.3578 25.8922C17.1242 26.6586 18.3758 26.6586 19.1422 25.8922L28.1422 16.8922Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_917_7743">
            <rect
              width="36"
              height="36"
              fill="white"
              transform="translate(2 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}
