import React, { ReactNode, useCallback, useMemo } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { INVENTORY_MANAGEMENT_KIND_MAP } from "@sellernote/_shared/src/constants/fulfillment/inventory";
import {
  TableDataListItem,
  TableRowInfoToHighlight,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import {
  checkUsesManagementDate,
  convertItemListToMapBySKU,
  getLabelCombinedWithSKUIdAndManagementDate,
  getManagementDateLabel,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { checkForNormalItemAsInspection } from "@sellernote/_shared/src/utils/fulfillment/inspection";
import { getStyledSKUCount } from "@sellernote/_shared/src/utils/fulfillment/sku";
import TableForCounting from "@sellernote/_sds-v1/src/components/table/TableForCounting";

import { ConfirmModal } from "hooks/common/useConfirmModal";
import useCountForScanning from "hooks/common/useCountForScanning";
import useGetWorkerByIdFactory from "hooks/common/useGetWorkerByIdFactory";
import useCompleteInspectionByForce from "hooks/returning/useCompleteInspectionByForce";
import useRemoveInspectionPersonInCharge from "hooks/returning/useRemoveInspectionPersonInCharge";
import useResetInspectionOfItem from "hooks/returning/useResetInspectionOfItem";
import {
  getCounterKeyForInspection,
  SKUCountingForInspection,
} from "hooks/returning/useSKUCountingForInspection";
import useSKUImageInfoModal from "hooks/sku/useSKUImageInfoModal";
import { authSelectors } from "modules/auth";
import { returningSelectors } from "modules/returning";
import { ReceivingInspectionDetailTableItem } from "pages/receiving/inspection/:id/SKUList";
import { useAppSelector } from "store";
import { getCurrentSKUCount } from "./utils";

import CompleteByForce from "components/CompleteByForce";
import CountForScanning from "components/CountForScanning";
import PersonInCharge from "components/PersonInCharge";
import ProblemInput from "components/ProblemInput";
import ResetCounting from "components/ResetCounting";
import SKUInfo from "components/SKUInfo";

import { ReturningGuideModal } from "../useReturningGuide";
import Styled from "./index.styles";

type ReturningInspectionDetailTableItem = Omit<
  ReceivingInspectionDetailTableItem,
  "selectMultiLocation"
> & {
  returningGuide: ReactNode;
  problem: ReactNode;
};

function SKUList({
  returningId,
  returningItems,
  rowInfoToHighlight,
  setRowInfoToHighlight,
  skuCounting,
  resetAfterRemoveInProgressPersonInCharge,
  resetCountByCounterKey,
  setConfirmModal,
  returningRequests,
  setReturningGuideModal,
  groupedItemIdInProgress,
  setGroupedItemIdInProgress,
}: {
  returningId: number;
  returningItems: ReceivingItem[] | undefined;
  rowInfoToHighlight: TableRowInfoToHighlight | undefined;
  setRowInfoToHighlight: (val: TableRowInfoToHighlight | undefined) => void;
  skuCounting: SKUCountingForInspection;
  resetAfterRemoveInProgressPersonInCharge: (counterKey: string) => () => void;
  resetCountByCounterKey: (counterKey: string) => void;
  setConfirmModal: React.Dispatch<
    React.SetStateAction<ConfirmModal | undefined>
  >;
  returningRequests: string | undefined;
  setReturningGuideModal: React.Dispatch<
    React.SetStateAction<ReturningGuideModal | undefined>
  >;
  groupedItemIdInProgress: number;
  setGroupedItemIdInProgress: React.Dispatch<React.SetStateAction<number>>;
}) {
  const { currentUser, currentUserIsMainManager } = useAppSelector((state) => {
    return {
      currentUser: authSelectors.getCurrentUser(),
      currentUserIsMainManager:
        returningSelectors.getCurrentUserIsReturningManager(state),
    };
  });

  const currentUserId = currentUser?.id;

  const {
    CountForScanningModal,
    handleDirectInputModalOpen,
    setCountForScanning,
  } = useCountForScanning();

  const {
    fetchImageInfoBySKUId,
    SKUImageInfoModal,
    ResponseHandlerOfGettingSKUInfo,
    ResponseHandlerOfGettingFileURLList,
  } = useSKUImageInfoModal();

  const getWorkerById = useGetWorkerByIdFactory();

  const { removePersonInCharge, ResponseHandlerOfRemovingPersonInCharge } =
    useRemoveInspectionPersonInCharge();

  const {
    resetInspectionOfItem,
    ResponseHandlerOfResetInspectionOfItemExceptInspector,
  } = useResetInspectionOfItem();

  const {
    completeInspectionByForce,
    ResponseHandlerOfCompletingInspectionByForce,
  } = useCompleteInspectionByForce();

  const handleCountAddByDirectInput = useCallback(
    (counterKey: string) => (count: number | undefined) => {
      if (!count) return;

      const target = skuCounting.counter.counterInfo[counterKey];

      skuCounting.counter.addCountByInput({ id: counterKey, count });

      setRowInfoToHighlight({ rowKey: target.itemId });
    },
    [setRowInfoToHighlight, skuCounting]
  );

  const resetLocalCount = useCallback(
    ({ counterKey }: { counterKey: string }) =>
      () => {
        resetCountByCounterKey(counterKey);
      },
    [resetCountByCounterKey]
  );

  /**
   * rowType에 맞는 row를 생성한다.
   */
  const createRow = useCallback(
    ({
      rowType,
      returningItem,
      currentCount,
      goalCount,
    }: {
      rowType: "basicRow" | "mainRow" | "subRow";
      returningItem: ReceivingItem;
      currentCount: number;
      // subRow의 경우 관리일자가 지정되기 전이므로 goalCount가 없다
      goalCount?: number;
    }): TableDataListItem<ReturningInspectionDetailTableItem> => {
      const counterKey = getCounterKeyForInspection({
        skuId: returningItem.sku.id,
        itemId: returningItem.id,
      });

      const skuId = getFormattedSingleSkuId(returningItem.sku.id);

      const currentUserIsAssignee = returningItem.inspectorId === currentUserId;
      const completedInspection = returningItem.isCompleteInspection;
      const skuInProgress =
        returningItem.id === skuCounting.skuInProgress?.itemId;

      const canOpenDirectInputModal =
        currentUserIsAssignee && !completedInspection && skuInProgress;

      const hasRightToReset = currentUserIsAssignee;

      const canReset =
        hasRightToReset &&
        (!!currentCount || returningItem.isCompleteInspection);

      const canCompleteInspectionByForce =
        !completedInspection && currentUserIsMainManager && !currentCount;

      const problemCount = returningItem.inspectProblems.reduce((acc, cur) => {
        return acc + cur.quantity;
      }, 0);

      const rowKey =
        rowType === "mainRow"
          ? `main-row-${returningItem.skuId}`
          : returningItem.id;

      const selection = (() => {
        if (rowType !== "subRow") return;

        const isSelectedItem = returningItem.id === groupedItemIdInProgress;

        const isDisabledItem = completedInspection;

        if (isDisabledItem) {
          return (
            <Icon
              type="checkStrokeCircleDisabled"
              size={1}
              color={COLOR.grayScale_300}
            />
          );
        }

        if (isSelectedItem) {
          return (
            <Icon
              type="radioSelected"
              size={1}
              color={COLOR.primaryBlue}
              onClick={() => setGroupedItemIdInProgress(0)}
            />
          );
        }

        return (
          <Icon
            type="radio"
            size={1}
            color={COLOR.grayScale_700}
            onClick={() => setGroupedItemIdInProgress(returningItem.id)}
          />
        );
      })();

      const SKUId = (
        <SKUInfo
          skuId={returningItem.sku.id}
          skuBarcode={returningItem.sku.barCode}
          handleSKUIdClick={fetchImageInfoBySKUId}
        />
      );

      const count = (() => {
        if (rowType === "mainRow") {
          // mainRow는 총계 카운트만 표시
          return getStyledSKUCount({
            currentCount,
            goalCount,
          });
        }

        if (rowType === "subRow") {
          return (
            <CountForScanning
              canOpenDirectInputModal={canOpenDirectInputModal}
              currentQty={currentCount}
              openDirectInputModal={() => {
                handleDirectInputModalOpen();
                setCountForScanning({
                  type: "returningInspection",
                  skuId: returningItem.sku.id,
                  currentQty: currentCount,
                  quantity: returningItem.quantity,
                  onCountAdd: handleCountAddByDirectInput(counterKey),
                  managementKind: returningItem.sku.managementKind,
                  managementDate: returningItem.managementDate,
                });
              }}
            />
          );
        }

        return (
          <CountForScanning
            // type="inspection"
            canOpenDirectInputModal={canOpenDirectInputModal}
            // skuId={receivingItem.sku.id}
            currentQty={currentCount}
            quantity={goalCount}
            // onCountAdd={handleCountAddByDirectInput(counterKey)}
            openDirectInputModal={() => {
              handleDirectInputModalOpen();
              setCountForScanning({
                type: "returningInspection",
                skuId: returningItem.sku.id,
                currentQty: currentCount,
                quantity: returningItem.quantity,
                onCountAdd: handleCountAddByDirectInput(counterKey),
                managementKind: returningItem.sku.managementKind,
                managementDate: returningItem.managementDate,
              });
            }}
          />
        );
      })();

      const managementDate = (() => {
        const usesManagementDate = checkUsesManagementDate({
          managementKind: returningItem.sku.managementKind,
          managementDate: returningItem.managementDate,
        });

        if (!usesManagementDate) return "-";

        if (rowType === "basicRow") {
          return (
            <Styled.basicRowOfManagementDateContainer>
              {returningItem.sku.managementKind && (
                <span>
                  {
                    INVENTORY_MANAGEMENT_KIND_MAP[
                      returningItem.sku.managementKind
                    ]
                  }
                </span>
              )}
              <span>
                {toFormattedDate(returningItem.managementDate, "YYYY-MM-DD")}
              </span>
            </Styled.basicRowOfManagementDateContainer>
          );
        }

        if (rowType === "mainRow") {
          return returningItem.sku.managementKind
            ? INVENTORY_MANAGEMENT_KIND_MAP[returningItem.sku.managementKind]
            : "";
        }

        if (rowType === "subRow") {
          return toFormattedDate(returningItem.managementDate, "YYYY-MM-DD");
        }

        return "";
      })();

      const problem =
        rowType === "mainRow" ? (
          ""
        ) : (
          <ProblemInput
            type="returning"
            itemId={returningItem.id}
            skuId={returningItem.sku.id}
            inspectionCount={currentCount}
            problemCount={problemCount}
            managementKind={returningItem.sku.managementKind}
            managementDate={returningItem.managementDate}
            disabled={completedInspection || !skuInProgress}
          />
        );

      const personInCharge =
        rowType === "mainRow" ? (
          ""
        ) : (
          <PersonInCharge
            worker={getWorkerById(returningItem.inspectorId)?.name ?? ""}
            isRemovable={currentUserIsMainManager}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "titleOnly",
                title: "SKU 담당자를 삭제하시겠습니까?",
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: removePersonInCharge({
                      returningId,
                      itemId: returningItem.id,
                      resetAfterRemoveInProgressPersonInCharge:
                        resetAfterRemoveInProgressPersonInCharge(counterKey),
                    }),
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
            // removePersonInCharge={removePersonInCharge({
            //   receivingId,
            //   itemId: v.id,
            //   resetAfterRemoveInProgressPersonInCharge:
            //     resetAfterRemoveInProgressPersonInCharge(counterKey),
            // })
          />
        );

      const status = (() => {
        if (rowType === "mainRow") {
          return "";
        }

        return completedInspection ? "Y" : "N";
      })();

      const returningGuide =
        rowType === "mainRow" ? (
          ""
        ) : (
          <Button
            label="확인"
            theme="secondary"
            size="small"
            handleClick={() =>
              setReturningGuideModal({
                condition: returningItem.sku.returningCondition,
                requests: returningRequests,
              })
            }
          />
          // <ReturningGuide
          //   condition={v.sku.returningCondition}
          //   requests={returningRequests}
          // />
        );

      const reset =
        rowType === "mainRow" ? (
          ""
        ) : (
          <ResetCounting
            skuId={returningItem.sku.id}
            canReset={canReset}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "content",
                title: (
                  <>
                    {skuId}(SKU ID)
                    {returningItem.managementDate && (
                      <>
                        <br />
                        {getManagementDateLabel(
                          returningItem.sku.managementKind,
                          returningItem.managementDate
                        )}
                      </>
                    )}
                  </>
                ),
                body: "카운트를 초기화하시겠습니까?",
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: resetInspectionOfItem({
                      returningId,
                      itemId: returningItem.id,
                      resetLocalCount: resetLocalCount({
                        counterKey,
                      }),
                    }),
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
            // reset={resetInspectionOfItem({
            //   receivingId,
            //   itemId: receivingItem.id,
            //   resetLocalCount: resetLocalCount({
            //     hasMultiLocation,
            //     inspectItems: receivingItem.inspectItems,
            //     skuId: receivingItem.sku.id,
            //   }),
            // })}
          />
        );

      const completeByForce =
        rowType === "mainRow" ? (
          ""
        ) : (
          <CompleteByForce
            skuId={returningItem.sku.id}
            canCompleteByForce={canCompleteInspectionByForce}
            openConfirmModal={() => {
              setConfirmModal({
                uiType: "titleOnly",
                title: `강제로 완료처리하시겠습니까? (${getLabelCombinedWithSKUIdAndManagementDate(
                  {
                    formattedSKUId: skuId,
                    managementKind: returningItem.sku.managementKind,
                    managementDate: returningItem.managementDate,
                  }
                )})`,
                actions: {
                  actionPositive: {
                    label: "예",
                    handleClick: completeInspectionByForce({
                      returningId,
                      itemId: returningItem.id,
                      setConfirmModal,
                    }),
                  },
                  actionNegative: {
                    label: "아니오",
                    handleClick: () => setConfirmModal(undefined),
                  },
                },
              });
            }}
            // completeByForce={completeInspectionByForce({
            //   receivingId: receivingId,
            //   skuId: receivingItem.sku.id,
            // })}
          />
        );

      const mappedReturning: TableDataListItem<ReturningInspectionDetailTableItem> =
        {
          rowKey,

          selection,
          SKUId,
          count,
          managementDate,
          problem,
          personInCharge,
          status,
          returningGuide,
          reset,
          completeByForce,
          // printBarcode: <PrintBarcode />,
        };

      return mappedReturning;
    },
    [
      completeInspectionByForce,
      currentUserId,
      currentUserIsMainManager,
      fetchImageInfoBySKUId,
      getWorkerById,
      handleCountAddByDirectInput,
      handleDirectInputModalOpen,
      groupedItemIdInProgress,
      removePersonInCharge,
      resetAfterRemoveInProgressPersonInCharge,
      resetInspectionOfItem,
      resetLocalCount,
      returningId,
      returningRequests,
      setConfirmModal,
      setCountForScanning,
      setGroupedItemIdInProgress,
      setReturningGuideModal,
      skuCounting.skuInProgress?.itemId,
    ]
  );

  const tableDataList = useMemo(() => {
    /** 불일치 상품(skuId 가 없는 상품) 필터링 해서 검수 상품을 보여줌. */
    const targetReturningItems = returningItems?.filter(
      checkForNormalItemAsInspection
    );

    const mapBySKU = convertItemListToMapBySKU(targetReturningItems);

    if (!mapBySKU) return [];

    return Object.values(mapBySKU).reduce<
      TableDataListItem<ReturningInspectionDetailTableItem>[]
    >((a, itemList) => {
      if (!itemList || !itemList?.length) return a;

      /**
       * sku를 구성하는 item이 2개 이상인 경우 subRow로 표시한다.
       **/
      const usesSubRow = itemList.length > 1;

      if (!usesSubRow) {
        return [
          ...a,
          ...itemList.map((returningItem) => {
            const currentCount = getCurrentSKUCount({
              skuId: returningItem.sku.id,
              itemId: returningItem.id,
              counterData: skuCounting.counter.counterInfo,
              returningItem,
            });

            return createRow({
              rowType: "basicRow",
              returningItem,
              currentCount,
              goalCount: returningItem.quantity,
            });
          }),
        ];
      }

      // 관리일자를 사용한다면 mainRow 하위의 subRow로 구분하여 표시한다
      const mainRow: TableDataListItem<ReturningInspectionDetailTableItem> =
        (() => {
          /**
           * mainRow는 item별 공통 정보를 만들므로 임의의 요소(첫 번째 요소)를 사용해서 생성할 수 있다.
           */
          const baseItem = itemList[0];

          // mainRow의 count는 subRow들의 총계를 반환
          const currentCount = itemList.reduce((acc, returningItem) => {
            const currentCount = getCurrentSKUCount({
              skuId: returningItem.sku.id,
              itemId: returningItem.id,
              counterData: skuCounting.counter.counterInfo,
              returningItem,
            });

            return acc + currentCount;
          }, 0);

          // mainRow의 count는 subRow들의 총계를 반환
          const goalCount = itemList.reduce((a, c) => a + c.quantity, 0);

          return {
            ...createRow({
              rowType: "mainRow",
              returningItem: baseItem,
              currentCount,
              goalCount,
            }),
          };
        })();

      mainRow.subRowInfo = (() => {
        return {
          // TODO: 입고 검수 UX가이드에 따라 initialAccordionStatus 추가
          subRowList: itemList.map((returningItem) => {
            const currentCount = getCurrentSKUCount({
              skuId: returningItem.sku.id,
              itemId: returningItem.id,
              counterData: skuCounting.counter.counterInfo,
              returningItem,
            });

            return createRow({
              rowType: "subRow",
              returningItem,
              currentCount,
              // 검수단계에서는 관리일자가 확정되기 전이므로 subRow의 goalCount가 없다.
            });
          }),

          initialAccordionStatus: "opened",
        };
      })();

      return [...a, mainRow];
    }, []);
  }, [returningItems, skuCounting.counter.counterInfo, createRow]);

  // function print() {
  //   WebToApp.print();
  // }

  return (
    <>
      <TableForCounting<ReturningInspectionDetailTableItem>
        isWindowed
        height={300}
        rowInfoToHighlight={rowInfoToHighlight}
        columnInfo={{
          selection: {
            label: "선택",
            fixedWidth: 32,
          },
          SKUId: {
            label: "SKU ID / 상품바코드",
            fixedWidth: 120,
          },
          count: {
            label: (
              <>
                검수수량/
                <br />
                요청수량(PCS)
              </>
            ),
            fixedWidth: 110,
          },
          managementDate: {
            label: "관리일자",
            fixedWidth: 78,
          },
          problem: {
            label: (
              <>
                비정상상품
                <br />
                (PCS)
              </>
            ),
            fixedWidth: 74,
          },
          personInCharge: {
            label: "담당자",
            fixedWidth: 110,
          },
          status: {
            label: (
              <>
                검품완료
                <br />
                여부
              </>
            ),
            fixedWidth: 58,
          },
          returningGuide: {
            label: (
              <>
                반품가이드
                <br />
                확인
              </>
            ),
            fixedWidth: 74,
          },
          reset: {
            label: "초기화",
            fixedWidth: 74,
          },
          completeByForce: {
            label: (
              <>
                강제처리
                <br />
                (카운트: 0)
              </>
            ),
            fixedWidth: 74,
          },
          // printBarcode: {
          //   label: "바코드 출력",
          //   fixedWidth: 110,
          // },
        }}
        dataList={tableDataList}
      />

      {CountForScanningModal}

      {SKUImageInfoModal}
      {ResponseHandlerOfGettingFileURLList}
      {ResponseHandlerOfGettingSKUInfo}

      {ResponseHandlerOfRemovingPersonInCharge}
      {ResponseHandlerOfResetInspectionOfItemExceptInspector}
      {ResponseHandlerOfCompletingInspectionByForce}
    </>
  );
}

export default SKUList;
