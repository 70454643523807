import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import InputRadio from "@sellernote/_sds-v1/src/components/input/InputRadio";

import InputForCounting from "./InputForCounting";
import ScanSku from "./ScanSku";
import useCountQuantityModal, { ModalType } from "./useCountQuantityModal";
import Styled from "./index.styles";

export default function CountQuantityModal({
  active,
  type,
  skuId,
  skuBarcode,
  availableQty,
  setQuantity,
  onClose,
}: {
  active: boolean;
  type: ModalType;
  skuId: string;
  skuBarcode: string | undefined;
  availableQty: number;
  setQuantity: (quantity: number) => void;
  onClose: () => void;
}) {
  const {
    countType,
    handleCountTypeChange,

    tempQuantity,
    setTempQuantity,

    validation,

    handleCountQuantityModalConfirm,
    handleCountQuantityModalClose,
  } = useCountQuantityModal({
    type,
    availableQty,
    setQuantity,
    onClose,
  });

  return (
    <>
      <Modal
        active={active}
        uiType="contentWithCustomBody"
        title="가용수량"
        body={
          <Styled.countQuantityModalBody>
            <InputRadio
              direction="row"
              optionList={[
                {
                  label: "스캔",
                  value: "scan",
                },
                {
                  label: "직접입력",
                  value: "input",
                },
              ]}
              selectedValue={countType}
              selectCallback={handleCountTypeChange}
            />

            {countType === "scan" ? (
              <ScanSku
                type={type}
                skuId={skuId}
                skuBarcode={skuBarcode}
                availableQty={availableQty}
                tempQuantity={tempQuantity}
                setTempQuantity={setTempQuantity}
              />
            ) : (
              <InputForCounting
                availableQty={availableQty}
                tempQuantity={tempQuantity}
                setTempQuantity={setTempQuantity}
                validation={validation}
              />
            )}

            <Button
              theme="primary"
              size="normal"
              label="확인"
              handleClick={handleCountQuantityModalConfirm}
              disabled={!validation.result}
            />
          </Styled.countQuantityModalBody>
        }
        onClose={handleCountQuantityModalClose}
      />
    </>
  );
}
