import styled from "styled-components";

const locationToConsolidate = styled.div`
  margin-bottom: 40px;
`;

const inputWrapper = styled.div`
  position: relative;
`;

export default {
  locationToConsolidate,
  inputWrapper,
};
