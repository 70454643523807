import { Dispatch, memo, SetStateAction } from "react";

import { BofulLocation } from "@sellernote/_shared/src/types/fulfillment/common";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

import SelectLocationModal from "../../SelectLocationModal";
import TempInput from "../../TempInput";
import useLocationToConsolidate from "./useLocationToConsolidate";
import Styled from "./index.styles";

function LocationToConsolidate({
  locationToConsolidate,
  setLocationToConsolidate,
}: {
  locationToConsolidate: BofulLocation | undefined;
  setLocationToConsolidate: Dispatch<SetStateAction<BofulLocation | undefined>>;
}) {
  const {
    isVisibleSelectLocationModal,
    handleLocationToMoveModalOpen,
    handleLocationToMoveModalClose,
  } = useLocationToConsolidate();

  return (
    <>
      <Styled.locationToConsolidate>
        <ListItem
          label="이동할 위치"
          type="withContentAlignRight"
          value={
            <Styled.inputWrapper onClick={handleLocationToMoveModalOpen}>
              <InputText
                placeholder="위치를 선택해주세요."
                width={12.625}
                borderType="outline"
                valueType="string"
                value={
                  locationToConsolidate?.barCode
                    ? `인천1센터 / ${locationToConsolidate?.barCode}`
                    : ""
                }
                icon="location"
                disabledToFocus
                visiblePostFix
              />
              <TempInput />
            </Styled.inputWrapper>
          }
        />
      </Styled.locationToConsolidate>

      <SelectLocationModal
        active={isVisibleSelectLocationModal}
        setSelectedLocation={setLocationToConsolidate}
        onClose={handleLocationToMoveModalClose}
        type="consolidation"
      />
    </>
  );
}

export default memo(LocationToConsolidate);
