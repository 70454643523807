import styled, { css } from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

const container = styled.div`
  .signature-modal-header {
    border-bottom: 1px solid ${COLOR.grayScale_200};
    margin-bottom: 8px;
  }

  .list-item.custom-value .value {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > .total {
      margin-right: 8px;
    }
  }

  .complete-button {
    margin-bottom: 28px;
  }
`;

const signatureContainer = styled.div<{ disabled: boolean }>`
  margin: 0 auto 24px;
  width: 300px;
  height: 120px;
  background-color: ${COLOR.grayScale_50};
  border-radius: 4px;
  ${({ disabled }) =>
    disabled
      ? css`
          border: 1px solid ${COLOR.grayScale_200};
        `
      : css`
          border: 1px solid ${COLOR.grayScale_400};
        `}

  .signatureCanvas {
    width: 100%;
    height: 100%;
  }
`;

export default {
  container,
  signatureContainer,
};
