import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.div<{ hasProblemItem: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${setFontStyle("Body7")};
  color: ${({ hasProblemItem }) =>
    hasProblemItem ? COLOR.pointWarning : COLOR.grayScale_700};
`;

export default {
  container,
};
