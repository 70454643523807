import styled from "styled-components";

const container = styled.span`
  display: inline-block;
  width: 100%;
  cursor: pointer;
`;

export default {
  container,
};
