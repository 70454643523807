import styled from "styled-components";

const container = styled.section`
  > .tab-filter {
    margin-bottom: 40px;
  }

  > .filter-scan {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    > .button {
      padding: 0 8px;

      .label {
        font-size: 12px;
        line-height: 24px;
        letter-spacing: -0.6px;
      }
    }
  }

  > .paging {
    margin-bottom: 64px;
  }
`;

export default {
  container,
};
