import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import useUploadFile from "@sellernote/_shared/src/hooks/common/useUploadFile";
import { FileDomainType } from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import { getActorKindByUserAuthority } from "@sellernote/_shared/src/utils/fulfillment/file";

import useCameraOrGallery from "hooks/common/useCameraOrGallery";
import { authSelectors } from "modules/auth";
import { returningSelectors } from "modules/returning";
import { useAppSelector } from "store";

import Styled from "./index.styles";

export default function usePicturePanelForConfirm({
  label,
  domainType,
  returningId,
}: {
  label: string;
  domainType: FileDomainType;
  returningId: number;
}) {
  const history = useHistory();

  const { currentUserAuthority, pictureListReturning } = useAppSelector(
    (state) => {
      return {
        currentUserAuthority: authSelectors.getCurrentUser()?.authority,
        pictureListReturning:
          returningSelectors.getReturningPictureDict(state)[domainType],
      };
    }
  );

  const [isModalVisible, setIsModalVisible] = useState(false);

  const hasPicture = !!pictureListReturning?.length;

  const [uploadFile, LoadingToUpload, FailureModal] = useUploadFile();

  const goToPictureListPage = useCallback(() => {
    history.push(`${history.location?.pathname}/${domainType}`);
  }, [history, domainType]);

  const [TriggerForCameraOrGallery] = useCameraOrGallery({
    triggerComponent: <Styled.takePictureOption>촬영</Styled.takePictureOption>,
    onFileLoaded: (fileInfoList) => {
      const file = fileInfoList[0];

      uploadFile({
        data: {
          fileName: file.fileName,
          fileBase64String: file.base64,
          name: file.fileName,
          domain: domainType,
          targetId: returningId,
          mimeType: file.fileMimeType,
          actorKind: getActorKindByUserAuthority(currentUserAuthority!),
        },
        onUploadSuccess: goToPictureListPage,
      });
    },
  });

  const showPicturePanel = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const PicturePanel = useMemo(() => {
    if (!isModalVisible) return null;

    return (
      <Modal
        active
        uiType="contentWithCustomBody"
        title={label}
        body={
          <Styled.takePictureOptionContainer>
            <Styled.takePictureOption
              disabled={!hasPicture}
              onClick={() => {
                if (hasPicture) goToPictureListPage();
              }}
            >
              사진보기
            </Styled.takePictureOption>

            {TriggerForCameraOrGallery}
          </Styled.takePictureOptionContainer>
        }
        onClose={() => {
          setIsModalVisible(false);
        }}
      />
    );
  }, [
    isModalVisible,
    label,
    hasPicture,
    TriggerForCameraOrGallery,
    goToPictureListPage,
  ]);

  const ResultHandlerOfPicturePanel = useMemo(() => {
    return (
      <>
        {LoadingToUpload}

        {FailureModal}
      </>
    );
  }, [LoadingToUpload, FailureModal]);

  return { showPicturePanel, PicturePanel, ResultHandlerOfPicturePanel };
}
