import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

import useResetStateWithTempKey from "../../useResetStateWithTempKey";
import CompleteConsolidation from "./CompleteConsolidation";
import ConsolidationTable from "./ConsolidationTable";
import LocationToConsolidate from "./LocationToConsolidate";
import SelectedSku from "./SelectedSku";
import SkuDetail from "./SkuDetail";
import useInventoryConsolidation from "./useInventoryConsolidation";

function InventoryMovementConsolidationWithKey() {
  const {
    inventoryDetailOfSelectedSku,
    setInventoryDetailOfSelectedSku,

    listOfInventoryToConsolidate,
    setListOfInventoryToConsolidate,

    locationToConsolidate,
    setLocationToConsolidate,

    selectedSku,
    setSelectedSku,
  } = useInventoryConsolidation();

  return (
    <>
      <SelectedSku
        inventoryDetailOfSelectedSku={inventoryDetailOfSelectedSku}
        setInventoryDetailOfSelectedSku={setInventoryDetailOfSelectedSku}
        selectedSku={selectedSku}
        setSelectedSku={setSelectedSku}
      />

      {!!inventoryDetailOfSelectedSku && selectedSku && (
        <>
          <SkuDetail
            skuDetail={inventoryDetailOfSelectedSku[0].sku}
            managementKind={selectedSku?.managementKind}
            managementDate={selectedSku?.managementDate}
          />

          <ConsolidationTable
            selectedSku={selectedSku}
            inventoryDetailOfSelectedSku={inventoryDetailOfSelectedSku}
            listOfInventoryToConsolidate={listOfInventoryToConsolidate}
            setListOfInventoryToConsolidate={setListOfInventoryToConsolidate}
          />

          <LocationToConsolidate
            locationToConsolidate={locationToConsolidate}
            setLocationToConsolidate={setLocationToConsolidate}
          />

          <CompleteConsolidation
            skuId={inventoryDetailOfSelectedSku[0].sku.id}
            locationIdToConsolidate={locationToConsolidate?.id}
            listOfInventoryToConsolidate={listOfInventoryToConsolidate}
          />
        </>
      )}
    </>
  );
}

function InventoryMovementConsolidation() {
  const tempKey = useResetStateWithTempKey();

  return (
    <Layout
      navigator={{
        title: "재고 병합",
      }}
    >
      <InventoryMovementConsolidationWithKey key={tempKey} />
    </Layout>
  );
}

export default withRequireAuth(InventoryMovementConsolidation);
