import { useCallback } from "react";
import { useDispatch } from "react-redux";

import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { UseCounterDataValue } from "@sellernote/_shared/src/utils/common/hook";

import useResetInputtingCounterInfo from "hooks/common/useResetInputtingCounterInfo";
import { InspectionCounterSKU } from "hooks/returning/useSKUCountingForInspection";
import { returningActions } from "modules/returning";

export default function useCompleteInspection({
  returningId,
  counterInProgress,
  resetAfterComplete,
}: {
  returningId: number;
  counterInProgress: UseCounterDataValue<InspectionCounterSKU> | undefined;
  resetAfterComplete: () => void;
}) {
  const resetInputtingCounterInfo = useResetInputtingCounterInfo();

  const dispatch = useDispatch();

  const {
    mutate: setItemInspectionDone,
    ResponseHandler: ResponseHandlerOfSetItemInspectionDone,
  } = RETURNING_QUERY.useSetItemInspectionDone();

  const canComplete = !!counterInProgress?.current;

  const complete = useCallback(() => {
    if (!counterInProgress || !counterInProgress.current) return;

    setItemInspectionDone(
      {
        pathParams: { returningId },
        inspectedItems: [
          {
            skuId: counterInProgress.skuId,
            itemId: counterInProgress.itemId,
            actualQty: counterInProgress.current,
          },
        ],
        actualQty: counterInProgress.current,
      },
      {
        onSuccess: () => {
          resetInputtingCounterInfo();

          dispatch(
            returningActions.GET_DETAIL({
              id: returningId,
              _postSuccessCallback: () => {
                resetAfterComplete();
              },
            })
          );
        },
      }
    );
  }, [
    counterInProgress,
    setItemInspectionDone,
    returningId,
    resetInputtingCounterInfo,
    dispatch,
    resetAfterComplete,
  ]);

  return {
    canComplete,
    complete,

    ResponseHandlerOfSetItemInspectionDone,
  };
}
