import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const inputMixQtyModalBody = styled.div`
  .unverified-label {
    margin-block-end: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    ${setFontStyle("SubHead3", "Bold")};
    color: ${COLOR.pointWarning};
  }

  .table {
    margin-block-end: 40px;

    table {
      width: 100%;
    }
  }
`;

const skuId = styled.div`
  text-align: left;
`;

export default {
  inputMixQtyModalBody,
  skuId,
};
