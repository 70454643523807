import React from "react";

import { ChipProps } from "@sellernote/_shared/src/headlessComponents/useChip";

import Styled from "./index.styles";

export default function Chip({
  shape,
  label,
  selectableInfo,
  className,
}: ChipProps) {
  return (
    <Styled.container
      shape={shape}
      className={`${className ? className : ""} chip ${
        selectableInfo?.selected ? "selected" : ""
      }`}
      onClick={selectableInfo && selectableInfo.handleClick}
      isSelectable={!!selectableInfo}
    >
      {label}
    </Styled.container>
  );
}
