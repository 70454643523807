import { memo } from "react";

import { ManagementKind } from "@sellernote/_shared/src/types/fulfillment/inventory";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import {
  getCombinedSkuManagementValueForPDAInventory,
  getCombinedSkuValueForPDAInventory,
} from "@sellernote/_shared/src/utils/fulfillment/inventory";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

import SkuDetailModal from "../../SkuDetailModal";
import useSkuDetail from "./useSkuDetail";
import Styled from "./index.styles";

function SkuDetail({
  skuId,
  skuBarcode,
  availableQty,
  managementKind,
  managementDate,
}: {
  skuId: number;
  skuBarcode: string | undefined;
  availableQty: number;
  managementKind: ManagementKind | undefined;
  managementDate: string | undefined;
}) {
  const {
    isVisibleSkuDetailModal,
    handleSkuDetailModalOpen,
    handleSkuDetailModalClose,

    skuDetail,

    ResponseHandlerOfGettingSkuDetail,
  } = useSkuDetail({
    skuId,
  });

  const hasManagementInfo = managementKind && managementDate;

  return (
    <>
      <ListItem
        label="상품정보"
        type="withButton"
        value={
          hasManagementInfo ? (
            <Styled.valueWithManagement>
              {getCombinedSkuValueForPDAInventory({ skuId, skuBarcode })}
              <br />
              {getCombinedSkuManagementValueForPDAInventory({
                managementKind,
                managementDate,
              })}
            </Styled.valueWithManagement>
          ) : (
            getCombinedSkuValueForPDAInventory({ skuId, skuBarcode })
          )
        }
        button={{
          label: "보기",
          handleClick: handleSkuDetailModalOpen,
        }}
      />

      <ListItem
        label="이동가능 수량"
        type="withContentAlignRight"
        value={toThousandUnitFormat(availableQty)}
      />

      <SkuDetailModal
        active={isVisibleSkuDetailModal}
        skuDetail={skuDetail?.sku}
        managementKind={managementKind}
        managementDate={managementDate}
        onClose={handleSkuDetailModalClose}
      />

      {ResponseHandlerOfGettingSkuDetail}
    </>
  );
}

export default memo(SkuDetail);
