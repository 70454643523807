import { useCallback, useState } from "react";

import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import SCAN_QUERY from "@sellernote/_shared/src/queries/fulfillment/SCAN_QUERY";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

import useScan from "hooks/common/useScan";

export interface CheckReturningTableItem {
  invoiceNo: string;
  status: "정상" | "오류";
}

export default function useCheckReturningInvoice() {
  const [checkReturningList, setCheckReturningList] = useState<
    TableDataListItem<CheckReturningTableItem>[]
  >([]);
  const [returningInvoiceNo, setReturningInvoiceNo] = useState("");

  const {
    mutate: interpretBarcode,
    ResponseHandler: ResponseHandlerOfCheckingReturningInvoice,
  } = SCAN_QUERY.useInterpretBarcode<"shipping">();

  const checkReturningInvoice = useCallback(
    (result: string) => {
      interpretBarcode(
        { type: "shipping", barCode: result },
        {
          onSuccess: ({ data: invoiceData }) => {
            setCheckReturningList([
              {
                rowKey: invoiceData.id,
                rowBackgroundColor:
                  invoiceData.wmsStatus === "return"
                    ? COLOR.pointWarning_20
                    : COLOR.pointSuccess_20,
                invoiceNo: invoiceData.invoiceNo,
                status: invoiceData.wmsStatus === "return" ? "오류" : "정상",
              },
            ]);

            if (invoiceData.wmsStatus === "return") {
              setReturningInvoiceNo(invoiceData.invoiceNo);
            }
          },
        }
      );
    },
    [interpretBarcode]
  );
  useScan(checkReturningInvoice);

  const initList = useCallback(() => {
    setCheckReturningList([]);
    setReturningInvoiceNo("");
  }, []);

  return {
    checkReturningList,
    returningInvoiceNo,
    initList,
    ResponseHandlerOfCheckingReturningInvoice,
  };
}
