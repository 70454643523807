export default function ScannedInfo({
  workingLocationBarcode,
}: {
  workingLocationBarcode: string | undefined;
}) {
  return (
    <div className="scanned-info">
      <strong>스캔된 위치: {workingLocationBarcode}</strong>
      <div className="outer-packaging-info">
        <span>외포장: </span>
        {/* TODO: 실제 외포장 추천데이터 -> 07/08 백엔드(학성): 어드민 나온 뒤 기준이 잡혀야 가능하다고 함 */}
        <span></span>
      </div>
    </div>
  );
}
