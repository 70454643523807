import React, { useCallback, useMemo } from "react";
import produce from "immer";
import { useRecoilValue } from "recoil";

import { INVENTORY_MANAGEMENT_KIND_MAP } from "@sellernote/_shared/src/constants/fulfillment/inventory";
import { SHIPPING_DELIVERY_TYPE } from "@sellernote/_shared/src/constants/fulfillment/shipping";
import {
  TableDataListItem,
  TableRowInfoToHighlight,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";
import SCAN_QUERY from "@sellernote/_shared/src/queries/fulfillment/SCAN_QUERY";
import SHIPPING_QUERY from "@sellernote/_shared/src/queries/fulfillment/SHIPPING_QUERY";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { FULFILLMENT_SHIPPING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/shipping";
import { ScanType } from "@sellernote/_shared/src/types/fulfillment/scan";
import { PickingSKUInfoForScanning } from "@sellernote/_shared/src/types/fulfillment/shipping";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { getLocationBarcodeById } from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import {
  getItemIdListByWorkingLocation,
  getPickingSKUId,
  sortListByLocationIdAndSKUId,
} from "@sellernote/_shared/src/utils/fulfillment/shipping";

import useConfirmModal from "hooks/common/useConfirmModal";
import useCountForScanning from "hooks/common/useCountForScanning";
import useSKUImageInfoModal from "hooks/sku/useSKUImageInfoModal";
import LocationToPicking from "pages/shipping/picking/LocationToPicking";
import ResetCount from "pages/shipping/picking/ResetCount";

import CountForScanning from "components/CountForScanning";
import SKUInfo from "components/SKUInfo";

import useChangePickingLocation from "./useChangePickingLocation";
import { CompletedList, WorkingLocation } from "./usePickingInvoice";
import usePickingMessageModal from "./usePickingMessageModal";

export interface MyPickingTableItem {
  locationToPicking: React.ReactNode;
  skuId: React.ReactNode;
  count: React.ReactNode;
  invoiceNo: string;
  deliveryType: string;
  resetCount: React.ReactNode;
}

export default function useMyPickingTable({
  selectedScanButtonType,
  handleScanButtonClick,
  workingLocation,
  setWorkingLocation,
  SKUInfoForScanning,
  setSKUInfoForScanning,
  setRowInfoToHighlight,
  completedList,
  isFirstSKUScan,
  resetPickingState,
}: {
  selectedScanButtonType: ScanType | undefined;
  handleScanButtonClick: (type: ScanType | undefined) => void;
  workingLocation: WorkingLocation | undefined;
  setWorkingLocation: React.Dispatch<
    React.SetStateAction<WorkingLocation | undefined>
  >;
  SKUInfoForScanning: PickingSKUInfoForScanning[];
  setSKUInfoForScanning: React.Dispatch<
    React.SetStateAction<PickingSKUInfoForScanning[]>
  >;
  setRowInfoToHighlight: React.Dispatch<
    React.SetStateAction<TableRowInfoToHighlight | undefined>
  >;
  completedList: React.MutableRefObject<CompletedList>;
  isFirstSKUScan: React.MutableRefObject<boolean>;
  resetPickingState: () => void;
}) {
  const locationListOfWarehouse = useRecoilValue(
    FULFILLMENT_COMMON_ATOMS.LOCATION_LIST_OF_WAREHOUSE
  );
  const { shippingId, invoiceNo, deliveryType, teamId, companyName, teamName } =
    useRecoilValue(FULFILLMENT_SHIPPING_ATOMS.SCANNED_PICKING_INVOICE);

  const {
    mutate: interpretBarcode,
    ResponseHandler: ResponseHandlerOfCheckingCancelingInvoice,
  } = SCAN_QUERY.useInterpretBarcode<"shipping">({
    isToCheckForCanceling: true,
  });

  const {
    mutate: startPickingScanning,
    ResponseHandler: ResponseHandlerOfStartingPickingScanning,
  } = SHIPPING_QUERY.useStartPickingScanning();

  const { ConfirmModal, setConfirmModal } = useConfirmModal();

  const {
    CountForScanningModal,
    handleDirectInputModalOpen,
    setCountForScanning,
  } = useCountForScanning();

  const {
    MessageModal,
    modalOpenHandler,
    ResponseHandlerOfSettingRestockByPickingQuantity,
  } = usePickingMessageModal();

  const {
    fetchImageInfoBySKUId,
    SKUImageInfoModal,
    ResponseHandlerOfGettingSKUInfo,
    ResponseHandlerOfGettingFileURLList,
  } = useSKUImageInfoModal();

  const {
    ChangePickingLocationModal,
    setSkuInfoToChangePickingLocation,
    ResponseHandlerOfGettingInventoryListBySkuId,
    ResponseHandlerOfChangingPickingLocation,
  } = useChangePickingLocation({ setSKUInfoForScanning });

  const itemIdListByWorkingLocation = useMemo(
    () =>
      getItemIdListByWorkingLocation({
        pickingList: SKUInfoForScanning,
        workingLocationId: workingLocation?.id ?? 0,
      }),
    [SKUInfoForScanning, workingLocation?.id]
  );

  const handleCountReset = useCallback(
    ({ itemIdToReset }: { itemIdToReset: number }) =>
      () => {
        resetCount();
        deleteCompletedList();

        function resetCount() {
          setSKUInfoForScanning(
            produce((draft) => {
              const SKUInfoByItemIdToReset = draft?.find(
                (v) => v.id === itemIdToReset
              );

              if (SKUInfoByItemIdToReset) {
                SKUInfoByItemIdToReset.currentQty = 0;
              }
            })
          );
        }

        function deleteCompletedList() {
          const locationIdByItemIdToReset =
            SKUInfoForScanning.find((v) => v.id === itemIdToReset)
              ?.locationId ?? 0;

          completedList.current.locationIdList.delete(
            locationIdByItemIdToReset
          );
          completedList.current.itemIdList.delete(itemIdToReset);
          completedList.current.itemIdListByWorkingLocation.delete(
            itemIdToReset
          );
        }
      },
    [setSKUInfoForScanning, SKUInfoForScanning, completedList]
  );

  const setCountInputResult = useCallback(
    ({ selectedItemId, count }: { selectedItemId: number; count: number }) => {
      if (!workingLocation) return;

      const selectedSKUId = getPickingSKUId({
        itemId: selectedItemId,
        pickingList: SKUInfoForScanning,
      });
      const selectedSKUBarcode = getFormattedSingleSkuId(selectedSKUId);

      setRowInfoToHighlight({ rowKey: selectedItemId });
      restoreCompletedItemIdListByWorkingLocation();
      countSKU();
      checkIsCompletedScanByScannedItemId();

      function restoreCompletedItemIdListByWorkingLocation() {
        // 다른 위치 작업을 하다가 기존에 완료된 위치의 상품을 초기화하해서 다시 돌아온 경우 작업 위치에서 완료된 상품 목록을 복원해준다.
        if (
          !isFirstSKUScan.current &&
          completedList.current.itemIdListByWorkingLocation.size === 0
        ) {
          itemIdListByWorkingLocation.forEach((itemId) => {
            if (completedList.current.itemIdList.has(itemId)) {
              completedList.current.itemIdListByWorkingLocation.add(itemId);
            }
          });
        }
      }

      function countSKU() {
        setSKUInfoForScanning(
          produce((draft) => {
            const selectedSKUInfo = draft.find((v) => v.id === selectedItemId);

            if (selectedSKUInfo) {
              // 등록 버튼 활성화가 되면 directInputForCount은 0보다 큰 number이다.
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              selectedSKUInfo.currentQty = count;
            }
          })
        );
      }

      // SKU 완료 확인 -> 취소 확인 -> 현재 작업 위치 완료 확인
      function checkIsCompletedScanByScannedItemId() {
        const scannedSKUInfo = SKUInfoForScanning.find(
          (v) => v.id === selectedItemId
        );
        const isCompletedScanByScannedSKUId =
          (scannedSKUInfo?.quantity ?? 0) === count;

        if (isCompletedScanByScannedSKUId) {
          completedList.current.itemIdListByWorkingLocation.add(selectedItemId);
          completedList.current.itemIdList.add(selectedItemId);

          checkIsCanceledInvoice();
        } else {
          completedList.current.itemIdListByWorkingLocation.delete(
            selectedItemId
          );
          completedList.current.itemIdList.delete(selectedItemId);
        }

        function checkIsCanceledInvoice() {
          interpretBarcode(
            { type: "shipping", barCode: invoiceNo },
            {
              onSuccess: ({ data: invoiceData }) => {
                if (invoiceData.shippingStatus === "cancel") {
                  prepareToMoveToCanceling();
                  return;
                }

                modalOpenHandler.openCompletedPickingBySKUIdMessage(
                  selectedSKUBarcode
                );

                checkIsCompletedScanByWorkingLocation();

                function prepareToMoveToCanceling() {
                  const restockItems = SKUInfoForScanning.map((v) => {
                    if (v.id === selectedItemId) {
                      return {
                        itemId: v.id,
                        restockQty: count,
                      };
                    }

                    return {
                      itemId: v.id,
                      restockQty: v.currentQty,
                    };
                  });

                  modalOpenHandler.openCanceledInvoiceInPickingMessage({
                    invoiceNo: invoiceData.invoiceNo,
                    shippingId: shippingId,
                    restockItems,
                  });
                }

                function checkIsCompletedScanByWorkingLocation() {
                  const isCompletedScanByWorkingLocation =
                    JSON.stringify([...itemIdListByWorkingLocation].sort()) ===
                    JSON.stringify(
                      [
                        ...completedList.current.itemIdListByWorkingLocation,
                      ].sort()
                    );

                  if (isCompletedScanByWorkingLocation) {
                    completedList.current.locationIdList.add(
                      workingLocation?.id ?? 0
                    );

                    completedList.current.itemIdListByWorkingLocation.clear();
                    setWorkingLocation(undefined);
                    handleScanButtonClick("location");
                  }
                }
              },
            }
          );
        }
      }
    },
    [
      SKUInfoForScanning,
      completedList,
      handleScanButtonClick,
      interpretBarcode,
      isFirstSKUScan,
      itemIdListByWorkingLocation,
      modalOpenHandler,
      invoiceNo,
      shippingId,
      setRowInfoToHighlight,
      setSKUInfoForScanning,
      setWorkingLocation,
      workingLocation,
    ]
  );

  const handleCountAddByDirectInput = useCallback(
    ({ selectedItemId }: { selectedItemId: number }) =>
      (count: number | undefined) => {
        if (!workingLocation || !count) return;

        isFirstSKUScan.current
          ? checkIsCanceledInvoice()
          : setCountInputResult({ selectedItemId, count });

        function checkIsCanceledInvoice() {
          interpretBarcode(
            { type: "shipping", barCode: invoiceNo },
            {
              onSuccess: ({ data: invoiceData }) => {
                // 본 운송 건은 취소되었습니다.
                if (invoiceData.shippingStatus === "cancel") {
                  modalOpenHandler.openCanceledPickingInvoice(
                    resetPickingState
                  );
                  return;
                }

                // 취소되지 않은 경우 피킹 스캔 시작을 알림
                startPickingScanning(
                  {},
                  {
                    onSuccess: () => {
                      isFirstSKUScan.current = false;
                      // 위에서 확인함
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      setCountInputResult({ selectedItemId, count: count! });
                    },
                  }
                );
              },
            }
          );
        }
      },
    [
      resetPickingState,
      interpretBarcode,
      isFirstSKUScan,
      modalOpenHandler,
      invoiceNo,
      setCountInputResult,
      startPickingScanning,
      workingLocation,
    ]
  );

  const myPickingTableList =
    useMemo((): TableDataListItem<MyPickingTableItem>[] => {
      if (!shippingId) {
        return [];
      }

      const sortedSKUInfo = sortListByLocationIdAndSKUId(SKUInfoForScanning);

      const isSelectedScanSKUButton = selectedScanButtonType === "sku";

      return sortedSKUInfo.map((skuInfo, i) => {
        const firstRow = i === 0;

        const locationBarcode = getLocationBarcodeById({
          locationList: locationListOfWarehouse,
          locationId: skuInfo.locationId,
        });

        const isItemIdInWorkingLocation = itemIdListByWorkingLocation.some(
          (itemId) => itemId === skuInfo.id
        );

        const canOpenDirectInputModal =
          isItemIdInWorkingLocation && isSelectedScanSKUButton;

        return {
          rowKey: skuInfo.id,

          locationToPicking: (
            <LocationToPicking
              locationBarcode={locationBarcode}
              setSkuInfoToChangePickingLocation={() =>
                setSkuInfoToChangePickingLocation({
                  shippingId,
                  teamId,
                  companyName,
                  teamName,
                  itemId: skuInfo.id,
                  skuId: skuInfo.skuId,
                  locationId: skuInfo.locationId,
                  productName: skuInfo.productName,
                  quantity: skuInfo.quantity,
                  managementKind: skuInfo.managementKind,
                  managementDate: skuInfo.managementDate,
                })
              }
              managementKind={skuInfo.managementKind}
              managementDate={skuInfo.managementDate}
            />
          ),

          skuId: (
            <SKUInfo
              skuId={skuInfo.skuId}
              skuBarcode={skuInfo.skuBarcode}
              handleSKUIdClick={fetchImageInfoBySKUId}
            />
          ),

          count: (
            <CountForScanning
              // type="picking"
              canOpenDirectInputModal={canOpenDirectInputModal}
              // skuId={skuInfo.skuId}
              currentQty={skuInfo.currentQty}
              quantity={skuInfo.quantity}
              // onCountAdd={handleCountAddByDirectInput({
              //   selectedItemId: skuInfo.id,
              // })}
              openDirectInputModal={() => {
                handleDirectInputModalOpen();
                setCountForScanning({
                  type: "picking",
                  skuId: skuInfo.skuId,
                  currentQty: skuInfo.currentQty,
                  quantity: skuInfo.quantity,
                  onCountAdd: handleCountAddByDirectInput({
                    selectedItemId: skuInfo.id,
                  }),
                  managementKind: skuInfo.managementKind,
                  managementDate: skuInfo.managementDate,
                });
              }}
            />
          ),

          invoiceNo: firstRow ? invoiceNo : "",

          deliveryType: (() => {
            if (firstRow && deliveryType) {
              return SHIPPING_DELIVERY_TYPE[deliveryType];
            }

            return "";
          })(),

          resetCount: (
            <ResetCount
              skuId={skuInfo.skuId}
              itemId={skuInfo.id}
              currentQty={skuInfo.currentQty}
              openConfirmModal={() => {
                setConfirmModal({
                  uiType: "content",
                  title: (
                    <>
                      {getFormattedSingleSkuId(skuInfo.skuId)}(SKU ID)
                      {skuInfo.managementKind && (
                        <>
                          <br />
                          {
                            INVENTORY_MANAGEMENT_KIND_MAP[
                              skuInfo.managementKind
                            ]
                          }{" "}
                          {toFormattedDate(
                            skuInfo.managementDate,
                            "YYYY-MM-DD"
                          )}
                        </>
                      )}
                    </>
                  ),
                  body: "카운트를 초기화하겠습니까?",
                  actions: {
                    actionPositive: {
                      label: "예",
                      handleClick: () => {
                        handleCountReset({
                          itemIdToReset: skuInfo.id,
                        })();
                        setConfirmModal(undefined);
                      },
                    },
                    actionNegative: {
                      label: "아니오",
                      handleClick: () => setConfirmModal(undefined),
                    },
                  },
                });
              }}
              // handleCountReset={handleCountReset}
            />
          ),
        };
      });
    }, [
      SKUInfoForScanning,
      companyName,
      deliveryType,
      fetchImageInfoBySKUId,
      handleCountAddByDirectInput,
      handleCountReset,
      handleDirectInputModalOpen,
      invoiceNo,
      itemIdListByWorkingLocation,
      locationListOfWarehouse,
      selectedScanButtonType,
      setConfirmModal,
      setCountForScanning,
      setSkuInfoToChangePickingLocation,
      shippingId,
      teamId,
      teamName,
    ]);

  return {
    ConfirmModal,
    CountForScanningModal,

    myPickingTableList,
    SKUImageInfoModal,
    ResponseHandlerOfGettingSKUInfo,
    ResponseHandlerOfGettingFileURLList,
    MessageModalOfDirectInput: MessageModal,

    ChangePickingLocationModal,

    ResponseHandlerOfCheckingCancelingInvoice,
    ResponseHandlerOfStartingPickingScanning,
    ResponseHandlerOfSettingRestockByPickingQuantity,
    ResponseHandlerOfGettingInventoryListBySkuId,
    ResponseHandlerOfChangingPickingLocation,
  };
}
