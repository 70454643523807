import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

const scanLocation = styled.div`
  margin-bottom: 40px;
`;

const locationBarcode = styled.div`
  margin-bottom: 24px;
  height: 88px;
  border: 1px solid ${COLOR.grayScale_400};
  border-radius: 4px;
  position: relative;

  > .barcode {
    width: 100%;
    height: 86px;
    border-radius: 4px;
  }
`;

const resetBarcode = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 86px;
  background-color: ${COLOR.bk_40};
  border: 1px solid ${COLOR.grayScale_400};
  border-radius: 4px;

  > .button {
    background-color: ${COLOR.white};
    min-width: 0;
    width: 92px;
  }
`;

export default {
  scanLocation,
  locationBarcode,
  resetBarcode,
};
