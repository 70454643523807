import { SetterOrUpdater } from "recoil";

/**
 * App -> Web 통신할 수 있도록 브릿지 함수를 global 함수로 등록한다.
 *
 * Web에서 global함수로 노출하면 앱에서 사용한다
 */
export function loadAppToWebBridge({
  setImageData,
  setScanResult,
  isScanning,
}: {
  setImageData: SetterOrUpdater<string | undefined>;
  setScanResult: SetterOrUpdater<string | undefined>;
  isScanning: boolean;
}) {
  if (typeof window === "undefined") return;

  // 함수들을 global scope로 선언
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).sendImageData = createSendImageDataFunction(setImageData);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).sendScanResult = createSendScanResultFunction({
    setScanResult,
    isScanning,
  });
}

/**
 * '촬영된 사진의 BASE64 엔코딩된 데이터 전달하는 함수'를 생성
 */
function createSendImageDataFunction(
  setImageData: SetterOrUpdater<string | undefined>
) {
  return function sendImageData(data: string) {
    setImageData(data);
  };
}

/**
 * '바코드/QA코드 촬영결과 텍스트 전달하는 함수'를 생성
 */
function createSendScanResultFunction({
  setScanResult,
  isScanning,
}: {
  setScanResult: SetterOrUpdater<string | undefined>;
  isScanning: boolean;
}) {
  return function sendScanResult(data: string) {
    isScanning && setScanResult(data);
  };
}
