import { useCallback, useState } from "react";

import SKU_QUERY from "@sellernote/_shared/src/queries/fulfillment/SKU_QUERY";

export default function useSkuDetail({ skuId }: { skuId: number }) {
  const [isVisibleSkuDetailModal, setIsVisibleSkuDetailModal] = useState(false);

  const {
    data: skuDetail,
    ResponseHandler: ResponseHandlerOfGettingSkuDetail,
  } = SKU_QUERY.useGetSingleSkuDetail({
    skuId,
    enabled: isVisibleSkuDetailModal,
  });

  const handleSkuDetailModalOpen = useCallback(() => {
    setIsVisibleSkuDetailModal(true);
  }, []);

  const handleSkuDetailModalClose = useCallback(() => {
    setIsVisibleSkuDetailModal(false);
  }, []);

  return {
    isVisibleSkuDetailModal,
    handleSkuDetailModalOpen,
    handleSkuDetailModalClose,

    skuDetail,

    ResponseHandlerOfGettingSkuDetail,
  };
}
