import { WarehousingStatusLabel } from "@sellernote/_shared/src/types/fulfillment/common";

import Styled from "./index.styles";

export default function ItemStatus({
  statusLabel,
  isUnverifiedSku = false,
  isTable = false,
}: {
  statusLabel: WarehousingStatusLabel | undefined;
  isUnverifiedSku: boolean | undefined;
  // Table Cell에서 사용되는 경우 '불일치 상품'을 표시하지 않기 위해 추가
  isTable?: boolean;
}) {
  if (!isUnverifiedSku || isTable) {
    return (
      <Styled.itemStatus statusLabel={statusLabel}>
        {statusLabel}
      </Styled.itemStatus>
    );
  }

  return (
    <span>
      <Styled.itemStatus statusLabel={statusLabel}>
        {statusLabel}
      </Styled.itemStatus>
      , <Styled.unverifiedSkuStatus>불일치 상품</Styled.unverifiedSkuStatus>
    </span>
  );
}
