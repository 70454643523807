import { selector } from "recoil";

import { PickingSKUInfoForScanning } from "../../../types/fulfillment/shipping";
import { sortPickingListByLocation } from "../../../utils/fulfillment/shipping";

import { FULFILLMENT_COMMON_ATOMS } from "../common";
import { FULFILLMENT_SHIPPING_ATOMS } from ".";

/** 피킹 테이블 초기값으로 사용(위치 변경 기능이 추가되어서 위치를 사용하는 경우에는 다른 곳에서 사용하면 안 됨) */
const PICKING_LIST = selector<PickingSKUInfoForScanning[]>({
  key: "fulfillment/shipping/selectors/PICKING_LIST",
  get: ({ get }) => {
    const locationList = get(
      FULFILLMENT_COMMON_ATOMS.LOCATION_LIST_OF_WAREHOUSE
    );

    const pickingList = (
      get(FULFILLMENT_SHIPPING_ATOMS.SCANNED_PICKING_INVOICE).items ?? []
    ).map((item) => ({
      id: item.id,
      locationId: item.locationId,
      locationBarcode:
        locationList.find((location) => location.id === item.locationId)
          ?.barCode ?? "",
      skuId: item.skuId,
      skuBarcode: item.sku.barCode,
      productName: item.sku.itemName,
      currentQty: 0,
      quantity: item.quantity,
      managementKind: item.sku.managementKind,
      managementDate: item.managementDate,
    }));

    return sortPickingListByLocation(pickingList);
  },
});

export default {
  PICKING_LIST,
};
