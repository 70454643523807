import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ADD_PARTIAL_WAREHOUSING_REQ } from "@sellernote/_shared/src/api-interfaces/boful-api/returning";
import useValidationErrorModal from "@sellernote/_shared/src/hooks/common/useValidationErrorModal";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import {
  checkConfirmedAsSingleLocationWarehousing,
  checkHasOnlyInitialWarehousing,
  getRemainedQtyForWarehousing,
} from "@sellernote/_shared/src/utils/fulfillment/receiving";

import BofulRequestResponseHandler from "containers/BofulRequestResponseHandler";
import { returningActions } from "modules/returning";
import { useAppSelector } from "store";

export default function useAddPartialWarehousing({
  returningId,
  returningItem,
}: {
  returningId: number;
  returningItem: ReceivingItem;
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { getDetailToCheckWarehousingCountReturningRes } = useAppSelector(
    (state) => {
      return {
        getDetailToCheckWarehousingCountReturningRes:
          state.returning.GET_DETAIL_TO_CHECK_WAREHOUSING_COUNT,
      };
    }
  );

  const {
    mutate: addPartialWarehousing,
    ResponseHandler: ResponseHandlerOfAddingPartialWarehousing,
  } = RETURNING_QUERY.useAddPartialWarehousing({
    type: "add",
    returningId,
  });

  const [setValidationError, ValidationErrorModal] = useValidationErrorModal();

  const addPartialWarehousingToItem = useCallback(
    ({
        itemId,
        remainedQty,
        quantity,
        isInit,
        callbackAfterAdd,
      }: ADD_PARTIAL_WAREHOUSING_REQ & {
        itemId: number;
        remainedQty: number;
        callbackAfterAdd: () => void;
      }) =>
      () => {
        dispatch(
          returningActions.GET_DETAIL_TO_CHECK_WAREHOUSING_COUNT({
            id: returningId,
            _postSuccessCallback: (res) => {
              const targetItem = res.returning.items.find(
                (v) => v.id === itemId
              );

              if (!targetItem) {
                setValidationError({
                  title: "이전 분할입고 내역을 확인하는 중 오류가 발생했습니다",
                  confirmCallback: () => {
                    dispatch(
                      returningActions.INIT_GET_DETAIL_TO_CHECK_WAREHOUSING_COUNT(
                        {}
                      )
                    );
                  },
                });
                return;
              }

              const remainedQtyLatest =
                getRemainedQtyForWarehousing(targetItem);
              if (remainedQty !== remainedQtyLatest) {
                // 잔여입고 수량이 바꼈으면 중단하고 입력을 다시 받도록 함
                setValidationError({
                  title: "다른 작업자에 의해 수량이 조정되었습니다.",
                  confirmCallback: () => {
                    dispatch(returningActions.GET_DETAIL({ id: returningId }));
                    dispatch(
                      returningActions.INIT_GET_DETAIL_TO_CHECK_WAREHOUSING_COUNT(
                        {}
                      )
                    );
                  },
                });
                return;
              }

              addPartialWarehousing(
                { itemId, quantity, isInit },
                {
                  onSuccess: ({ data }) => {
                    const targetItem = data.items.find((v) => v.id === itemId);
                    const confirmedAsSingleLocationWarehousing =
                      checkConfirmedAsSingleLocationWarehousing(targetItem);

                    dispatch(
                      returningActions.GET_DETAIL({
                        id: returningId,
                        _postSuccessCallback: () => {
                          // 전체수량으로 분할할 경우 일반입고로 취급하여 검수페이지로 돌아감
                          confirmedAsSingleLocationWarehousing
                            ? history.goBack()
                            : callbackAfterAdd();
                        },
                      })
                    );
                  },
                }
              );
            },
          })
        );
      },
    [addPartialWarehousing, dispatch, history, returningId, setValidationError]
  );

  const hasOnlyInitialWarehousing = useMemo(
    () => checkHasOnlyInitialWarehousing(returningItem),
    [returningItem]
  );

  const canAddPartialWarehousing = useMemo(() => {
    if (!returningItem.placeItems) return false;

    if (hasOnlyInitialWarehousing) {
      return true;
    }

    const partedQuantityTotal = returningItem.placeItems.reduce((a, c) => {
      return a + c.quantity;
    }, 0);
    const max = returningItem.actualQty ?? 0;
    if (partedQuantityTotal < max) return true;

    return false;
  }, [returningItem, hasOnlyInitialWarehousing]);

  const remainedQtyForWarehousing = useMemo((): number => {
    if (!returningItem.placeItems) return 0;

    if (hasOnlyInitialWarehousing) return returningItem.actualQty ?? 0;

    return getRemainedQtyForWarehousing(returningItem);
  }, [returningItem, hasOnlyInitialWarehousing]);

  const ResponseHandlerOfAddingPartialWarehousingToItem = useMemo(() => {
    return (
      <>
        {ValidationErrorModal}

        {ResponseHandlerOfAddingPartialWarehousing}

        <BofulRequestResponseHandler
          response={getDetailToCheckWarehousingCountReturningRes}
          initRequest={() => {
            dispatch(
              returningActions.INIT_GET_DETAIL_TO_CHECK_WAREHOUSING_COUNT({})
            );
          }}
          loading={{
            actionType:
              returningActions.GET_DETAIL_TO_CHECK_WAREHOUSING_COUNT.type,
          }}
          failureModalInfo={{
            customizeMessage: () => ({
              title: "이전 분할입고 내역 조회 중에 오류가 발생했습니다.",
            }),
          }}
        />
      </>
    );
  }, [
    ValidationErrorModal,
    ResponseHandlerOfAddingPartialWarehousing,
    getDetailToCheckWarehousingCountReturningRes,
    dispatch,
  ]);

  return {
    addPartialWarehousingToItem,
    canAddPartialWarehousing,
    remainedQtyForWarehousing,
    ResponseHandlerOfAddingPartialWarehousingToItem,
  };
}
