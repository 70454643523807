import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import {
  TableColumnInfo,
  TableDataListItem,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";
import CANCELING_QUERY from "@sellernote/_shared/src/queries/fulfillment/CANCELING_QUERY";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import {
  CancelingListItem,
  CancelingStatus,
  Canceller,
} from "@sellernote/_shared/src/types/fulfillment/canceling";
import { getStatusWhenCanceled } from "@sellernote/_shared/src/utils/fulfillment/canceling";
import {
  getTeamLabelForBofulWorker,
  getWorkerNameById,
} from "@sellernote/_shared/src/utils/fulfillment/common";

interface CancelingTableItemByWaiting {
  cancelingNo: number;
  combinedCanceller: React.ReactNode;
  shippingId: number;
  invoiceNo: string;
  statusWhenCanceled: "피킹 중" | "패킹 중" | "";
  workerWhenCanceled: string;
}

type CancelingTableItemByDone = Omit<
  CancelingTableItemByWaiting,
  "statusWhenCanceled" | "workerWhenCanceled"
> & {
  expectedQuantity: number;
  completedQuantity: number;
  restocker: string;
};

export type CancelingTableItemType =
  | CancelingTableItemByWaiting
  | CancelingTableItemByDone;

const getColumnInfoByCancelingStatus = (
  status: CancelingStatus
): TableColumnInfo<CancelingTableItemType> =>
  status === "waiting"
    ? {
        cancelingNo: {
          label: "취소번호",
        },
        combinedCanceller: {
          label: "취소주체",
          fixedWidth: 100,
        },
        shippingId: {
          label: "출고요청번호",
          fixedWidth: 100,
        },
        invoiceNo: {
          label: "송장번호(QR 넘버)",
          fixedWidth: 130,
        },
        statusWhenCanceled: {
          label: "취소시점",
        },
        workerWhenCanceled: {
          label: "취소시점 담당자",
          fixedWidth: 130,
        },
      }
    : {
        cancelingNo: {
          label: "취소번호",
        },
        combinedCanceller: {
          label: "취소주체",
          fixedWidth: 120,
        },
        shippingId: {
          label: "출고요청번호",
          fixedWidth: 100,
        },
        invoiceNo: {
          label: "송장번호(QR 넘버)",
          fixedWidth: 130,
        },
        expectedQuantity: {
          label: "재입고 예정수량",
          fixedWidth: 120,
        },
        completedQuantity: {
          label: "재입고 확정수량",
          fixedWidth: 120,
        },
        restocker: {
          label: "재입고 담당자",
          fixedWidth: 130,
        },
      };

/**
 * 취소관리 리스트 조회
 */
const PAGE_UNIT = 10;

export default function useCancelingList(
  currentPage: number,
  status: CancelingStatus,
  canceller?: Canceller
) {
  const history = useHistory();

  const workerList = useRecoilValue(FULFILLMENT_COMMON_ATOMS.WORKER_LIST);

  const {
    data: cancelingList,
    ResponseHandler: ResponseHandlerOfGettingCancelingList,
  } = CANCELING_QUERY.useGetCancelingList({
    page: currentPage,
    perPage: PAGE_UNIT,
    isComplete: status === "waiting" ? false : true,
    canceller,
  });

  const columnInfoByCancelingStatus = getColumnInfoByCancelingStatus(status);

  const getDataListByCancelingStatus = useCallback(
    (
      status: CancelingStatus,
      rawList?: CancelingListItem[]
    ): TableDataListItem<CancelingTableItemType>[] => {
      if (!rawList) {
        return [];
      }

      return status === "waiting"
        ? rawList.map((v, i) => {
            return {
              rowKey: v.id,
              handleRowClick: () => history.push(`/canceling/detail/${v.id}`),
              cancelingNo: v.id,
              combinedCanceller:
                v.canceller === "warehouseMaster"
                  ? "관리자"
                  : getTeamLabelForBofulWorker(v.shipping.team),
              shippingId: v.shipping.id,
              invoiceNo: v.shipping.invoiceNo,
              statusWhenCanceled: getStatusWhenCanceled(v.shipping.wmsStatus),
              workerWhenCanceled: getWorkerNameById(
                workerList,
                v.managerIdWhenCanceled
              ),
            };
          })
        : rawList.map((v, i) => {
            return {
              rowKey: v.id,
              cancelingNo: v.id,
              combinedCanceller:
                v.canceller === "warehouseMaster" ? (
                  "관리자"
                ) : (
                  <>
                    {v.shipping.team?.company} <br />
                    {v.shipping.team?.id}
                  </>
                ),
              shippingId: v.shipping.id,
              invoiceNo: v.shipping.invoiceNo,
              expectedQuantity: v.restockExpected ?? 0,
              completedQuantity: v.restockQty ?? 0,
              restocker: getWorkerNameById(workerList, v.restockerId),
            };
          });
    },
    [history, workerList]
  );
  const dataListByCancelingStatus = useMemo(
    () => getDataListByCancelingStatus(status, cancelingList?.list),
    [getDataListByCancelingStatus, status, cancelingList?.list]
  );

  return {
    cancelingTotal: cancelingList?.total ?? 0,
    cancelingAnotherTotal: cancelingList?.anotherTotal ?? 0,
    dataListByCancelingStatus,

    columnInfoByCancelingStatus,

    ResponseHandlerOfGettingCancelingList,
  };
}
