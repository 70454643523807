import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { loadAppToWebBridge } from "bridge/appToWeb";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Router from "Router";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  IS_READY_FOR_MSW_TOOL,
  IS_UNDER_LOCAL_DEVELOPMENT,
} from "@sellernote/_shared/src/constants";
import { OverlayStackProvider } from "@sellernote/_shared/src/context/overlayStack";
import {
  I18N_NAMESPACE_LIST_FOR_DESIGN_SYSTEM,
  initI18nInstanceForDesignSystem,
} from "@sellernote/_shared/src/i18n/i18nForDesignSystem";
import MSWTool from "@sellernote/_shared/src/mocks/MSWTool";
import { JotaiDevtools } from "@sellernote/_shared/src/services/jotai";
import { REACT_QUERY_CLIENT_CONFIG } from "@sellernote/_shared/src/services/query";
import { FULFILLMENT_BRIDGE_ATOMS } from "@sellernote/_shared/src/states/fulfillment/bridge";
import { printBuildInfo } from "@sellernote/_shared/src/utils/common/etc";
import GlobalStyle from "@sellernote/_sds-v1/src/styles/global";

/**
 * 루트 컴포넌트에 위치해야 하나, 리렌더링을 발생시키지 않기 위해서 별도의 컴포넌트로 분리함
 */
function AppToWebBridge() {
  const setImageData = useSetRecoilState(FULFILLMENT_BRIDGE_ATOMS.IMAGE_DATA);
  const setScanResult = useSetRecoilState(FULFILLMENT_BRIDGE_ATOMS.SCAN_RESULT);
  const isScanning = useRecoilValue(FULFILLMENT_BRIDGE_ATOMS.IS_SCANNING);

  useEffect(() => {
    loadAppToWebBridge({ setImageData, setScanResult, isScanning });
  }, [isScanning, setImageData, setScanResult]);

  return null;
}

const queryClient = new QueryClient(REACT_QUERY_CLIENT_CONFIG);

function App() {
  useEffect(() => {
    printBuildInfo();
  }, []);

  const { t } = useTranslation(I18N_NAMESPACE_LIST_FOR_DESIGN_SYSTEM);
  initI18nInstanceForDesignSystem({ Trans, t });

  return (
    <>
      <OverlayStackProvider>
        {IS_READY_FOR_MSW_TOOL && <MSWTool type="PDA" />}

        <QueryClientProvider client={queryClient}>
          {IS_UNDER_LOCAL_DEVELOPMENT && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}

          <JotaiDevtools>
            <Router>
              <GlobalStyle />

              <AppToWebBridge />
            </Router>
          </JotaiDevtools>
        </QueryClientProvider>
      </OverlayStackProvider>
    </>
  );
}

export default App;
