import { useEffect } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";

import { FULFILLMENT_INVENTORY_ATOMS } from "@sellernote/_shared/src/states/fulfillment/inventory";

export default function useResetStateWithTempKey() {
  const tempKey = useRecoilValue(FULFILLMENT_INVENTORY_ATOMS.TEMP_KEY);

  const resetTempKey = useResetRecoilState(
    FULFILLMENT_INVENTORY_ATOMS.TEMP_KEY
  );

  useEffect(() => {
    return () => {
      resetTempKey();
    };
  }, [resetTempKey]);

  return tempKey;
}
