import SvgIconWrapper from "./SvgIconWrapper";

export default function SubdirectoryIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.4204 34.2466C22.1682 33.9944 22.042 33.7001 22.042 33.3637C22.042 33.0274 22.1682 32.7331 22.4204 32.4809L28.3904 26.5109H9.47147C9.05105 26.5109 8.7007 26.3707 8.42042 26.0905C8.14014 25.8102 8 25.4598 8 25.0394V7.84422C8 7.50788 8.11912 7.2206 8.35736 6.98236C8.5956 6.74412 8.88288 6.625 9.21922 6.625C9.58358 6.625 9.87788 6.74412 10.1021 6.98236C10.3263 7.2206 10.4384 7.50788 10.4384 7.84422V24.0724H28.4324L22.3784 18.0184C22.1542 17.7942 22.049 17.5209 22.0631 17.1986C22.0771 16.8763 22.2102 16.589 22.4625 16.3367C22.6867 16.1125 22.967 16.0004 23.3033 16.0004C23.6396 16.0004 23.9339 16.1125 24.1862 16.3367L32.048 24.1986C32.1882 24.3387 32.3003 24.4999 32.3844 24.6821C32.4685 24.8642 32.5105 25.0534 32.5105 25.2496C32.5105 25.4178 32.4685 25.6 32.3844 25.7962C32.3003 25.9924 32.1882 26.1465 32.048 26.2586L24.1021 34.2887C23.8779 34.5129 23.6046 34.625 23.2823 34.625C22.96 34.625 22.6727 34.4989 22.4204 34.2466Z"
          fill="currentColor"
        />
      </svg>
    </SvgIconWrapper>
  );
}
