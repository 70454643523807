import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

const completeWarehousingConfirmModalBody = styled.div`
  > .counting {
    > .strong {
      color: ${COLOR.pointWarning};
    }
  }
`;

export default {
  completeWarehousingConfirmModalBody,
};
