import {
  INTERPRET_BARCODE_REQ,
  INTERPRET_BARCODE_RES,
} from "@sellernote/_shared/src//api-interfaces/boful-api/scan";
import { QueryResponseHandlerCustomMessage } from "@sellernote/_shared/src//components/QueryResponseHandler";
import { ScanType } from "@sellernote/_shared/src//types/fulfillment/scan";
import { useAppMutation } from "@sellernote/_shared/src/services/query";
import { BofulLocation } from "@sellernote/_shared/src/types/fulfillment/common";

import { COLOR } from "../../stylesToMoveToV1/constants";

function useInterpretBarcode<T extends ScanType>(options?: {
  type?: "directInput";
  isToCheckForCanceling?: boolean;
  isBarcodeModal?: boolean;
}) {
  const mutation = useAppMutation<
    INTERPRET_BARCODE_REQ,
    INTERPRET_BARCODE_RES<T>
  >({
    requestOptions: {
      method: "patch",
      path: `/scanning`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo): QueryResponseHandlerCustomMessage => {
        const getErrorMessage = ({ code }: { code: number | undefined }) =>
          ({
            400: {
              messageType: "titleOnly" as const,
              title: `올바른 송장(QR)을 ${
                options?.type === "directInput" ? "입력" : "스캔"
              }해주세요.`,
            },
            404: {
              messageType: "titleOnly" as const,
              title: `올바른 바코드를 ${
                options?.type === "directInput" ? "입력" : "스캔"
              }해주세요.`,
            },
          }[code ?? 0] ?? {
            title: "바코드를 검사하는 중에 오류가 발생했습니다.",
          });

        return options?.isToCheckForCanceling
          ? {
              title: "송장(QR)의 취소여부를 확인하는 중에 오류가 발생했습니다.",
            }
          : getErrorMessage({ code: failureInfo?.code });
      },
      barcodeValue: options?.isBarcodeModal ? "H_INT_BAR" : undefined,
    },
  });

  return { ...mutation };
}

function useInterpretWarehousingLocation({
  tempLocation,
}: {
  tempLocation: string;
}) {
  const mutation = useAppMutation<
    {
      barCode: string;
      skuId: number;
      /**
       * 관리일자를 사용하는 경우 보낸다
       */
      managementDate?: string;
    },
    BofulLocation
  >({
    requestOptions: {
      method: "patch",
      path: `/scanning/location`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      handleConfirmFailure: () => {
        tempLocation = "";
      },
      customizeMessage: (failureInfo): QueryResponseHandlerCustomMessage => {
        const getErrorMessage = ({ code }: { code: number | undefined }) =>
          ({
            400: {
              messageType: "titleOnly" as const,
              title: (
                <div style={{ color: COLOR.pointWarning }}>
                  해당 위치({tempLocation})에는 이미 입고된 상품이 있습니다.
                  <br />
                  다른 위치를 스캔해주세요.
                </div>
              ),
            },
            404: {
              messageType: "titleOnly" as const,
              title: `올바른 바코드를 스캔해주세요.`,
            },
          }[code ?? 0] ?? {
            messageType: "titleOnly" as const,
            title: "바코드를 스캔하는 중에 오류가 발생했습니다.",
          });

        return getErrorMessage({ code: failureInfo?.code });
      },
    },
  });

  return { ...mutation };
}

const SCAN_QUERY = {
  useInterpretBarcode,
  useInterpretWarehousingLocation,
};
export default SCAN_QUERY;
