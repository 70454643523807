import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";

import {
  CounterDataForInspection,
  getCounterKeyForInspection,
} from "hooks/returning/useSKUCountingForInspection";

function getCurrentSKUCount({
  skuId,
  itemId,
  counterData,
  returningItem,
}: {
  skuId: number;
  itemId: number;
  counterData: CounterDataForInspection;
  returningItem: ReceivingItem;
}) {
  if (!counterData) return 0;

  const counterKey = getCounterKeyForInspection({
    skuId,
    itemId,
  });

  const currentTempCount = counterData[counterKey]?.current;
  const currentCount = currentTempCount ?? returningItem.actualQty ?? 0;

  return currentCount;
}

export { getCurrentSKUCount };
