import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { TabFilterItem } from "@sellernote/_shared/src/headlessComponents/useTabFilter";
import {
  ShipmentDelayFilterType,
  ShipmentTodayAndOrderFilterType,
  ShippingShipmentListItem,
} from "@sellernote/_shared/src/types/fulfillment/shipping";

import withRequireAuth from "hocs/withRequireAuth";
import useShipmentClosingTruckDetail, {
  ShipmentTruckStatusType,
} from "hooks/shipping/useShipmentClosingTruckDetail";
import ShippingShipmentClosingDetail, {
  ConditionalFilterType,
} from "pages/shipping/shipment/closing/ShipmentClosingDetail";

import ShipmentClosingLayout from "../../../ShipmentClosingLayout";
import ConsigneeSignatureModal from "./ConSigneeSignatureModal";

function getFilterList<T extends ShipmentTruckStatusType>(
  status: T
): T extends "expected"
  ? TabFilterItem<ShipmentTodayAndOrderFilterType>[]
  : T extends "unprocessed"
  ? TabFilterItem<ShipmentDelayFilterType>[]
  : [];
function getFilterList(status: any): any {
  switch (status) {
    case "expected":
      return [
        {
          label: "전체",
          filter: "all",
        },
        {
          label: "금일 출하 요청 건",
          filter: "today",
        },
        {
          label: "이전 출하 건",
          filter: "overdue",
        },
      ];
    case "unprocessed":
      return [
        {
          label: "전체",
          filter: "all",
        },
        {
          label: "당일 지연",
          filter: "today",
        },
        {
          label: "1일 지연",
          filter: "oneday",
        },
        {
          label: "2일 이상 지연",
          filter: "twoday",
        },
      ];
    case "completed":
      return [];
  }
}

function ShippingShipmentClosingTruckCompanyDetail() {
  const { status } = useParams<{ status: ShipmentTruckStatusType }>();

  const [filterForList, setFilterForList] = useState(
    () => getFilterList(status)[0]?.filter
  );
  const [currentPage, setCurrentPage] = useState(0);
  const filterList = getFilterList(status);

  const [visibleSignatureModal, setVisibleSignatureModal] = useState(false);
  const [signatureModalData, setSignatureModalData] =
    useState<ShippingShipmentListItem>();

  const handleFilterClick = useCallback(
    <Status extends ShipmentTruckStatusType>(
      filter: ConditionalFilterType<Status>
    ) => {
      setCurrentPage(0);
      setFilterForList(filter);
    },
    []
  );

  const handleRowClickForSignature = useCallback(
    (data: ShippingShipmentListItem) => {
      setSignatureModalData(data);
      setVisibleSignatureModal(true);
    },
    []
  );

  const {
    titleValue,
    dataList,
    pageSize,

    ResponseHandlerOfGettingShipmentTruckToday,
    ResponseHandlerOfGettingShipmentTruckDone,
    ResponseHandlerOfGettingShipmentTruckDelay,
  } = useShipmentClosingTruckDetail({
    filterForList,
    currentPage,
    handleRowClickForSignature,
  });

  return (
    <ShipmentClosingLayout title="화물차량" selectType="운송사">
      <ShippingShipmentClosingDetail<"truck", typeof status>
        deliveryType="truck"
        titleInfo={{
          label: status,
          value: titleValue,
        }}
        filter={{
          filterList,
          selectedFilter: filterForList,
          setFilter: handleFilterClick,
        }}
        dataList={dataList}
        paging={{
          pageSize,
          currentPage,
          setCurrentPage,
        }}
      />

      {visibleSignatureModal && (
        <ConsigneeSignatureModal
          data={signatureModalData}
          handleModalClose={setVisibleSignatureModal}
        />
      )}

      {ResponseHandlerOfGettingShipmentTruckToday}
      {ResponseHandlerOfGettingShipmentTruckDone}
      {ResponseHandlerOfGettingShipmentTruckDelay}
    </ShipmentClosingLayout>
  );
}

export default withRequireAuth(ShippingShipmentClosingTruckCompanyDetail);
