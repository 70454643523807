import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { BofulMenu } from "@sellernote/_shared/src/types/fulfillment/common";

import Styled from "./index.styles";

function MobileMenu({ menu }: { menu: BofulMenu }) {
  const history = useHistory();
  const location = useLocation();

  const [isSubMenuListOpened, setIsSubMenuListOpened] = useState(false);

  const hasSubMenuList = menu.subMenuList;

  return (
    <>
      <Styled.mainMenu
        onClick={
          hasSubMenuList
            ? () => setIsSubMenuListOpened(!isSubMenuListOpened)
            : () =>
                history.push({
                  pathname: menu.path,
                  state: { from: location.pathname },
                })
        }
      >
        <div className="title">{menu.title}</div>

        {hasSubMenuList && (
          <Icon
            type={isSubMenuListOpened ? "remove" : "add"}
            size={1}
            color={COLOR.grayScale_800}
          />
        )}
      </Styled.mainMenu>

      {hasSubMenuList && isSubMenuListOpened && (
        <Styled.subMenuContainer>
          {menu.subMenuList?.map((subMenu, i) => {
            return (
              <div
                key={i}
                className="sub-menu"
                onClick={() =>
                  history.push({
                    pathname: subMenu.path,
                    state: { from: location.pathname },
                  })
                }
              >
                {subMenu.title}
              </div>
            );
          })}
        </Styled.subMenuContainer>
      )}
    </>
  );
}

export default MobileMenu;
