import { useCallback, useState } from "react";

export default function useQuantityAfterAdjustment() {
  const [isVisibleCountQuantityModal, setIsVisibleCountQuantityModal] =
    useState(false);

  const handleCountQuantityModalOpen = useCallback(() => {
    setIsVisibleCountQuantityModal(true);
  }, []);

  const handleCountQuantityModalClose = useCallback(() => {
    setIsVisibleCountQuantityModal(false);
  }, []);

  return {
    isVisibleCountQuantityModal,
    handleCountQuantityModalOpen,
    handleCountQuantityModalClose,
  };
}
