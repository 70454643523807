import { useCallback, useState } from "react";

export default function useLocationToComplete() {
  const [isVisibleSelectLocationModal, setIsVisibleSelectLocationModal] =
    useState(false);

  const handleLocationToMoveModalOpen = useCallback(() => {
    setIsVisibleSelectLocationModal(true);
  }, []);

  const handleLocationToMoveModalClose = useCallback(() => {
    setIsVisibleSelectLocationModal(false);
  }, []);

  return {
    isVisibleSelectLocationModal,
    handleLocationToMoveModalOpen,
    handleLocationToMoveModalClose,
  };
}
