import styled from "styled-components";

const container = styled.div`
  .variable-size-list {
    /* -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    } */
  }
`;

export default {
  container,
};
