import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

const container = styled.section``;

const detail = styled.div<{ isOpened: boolean }>`
  width: ${(props) => (props.isOpened ? "100vw" : "0px")};
  display: ${(props) => (props.isOpened ? "flex" : "none")};
  z-index: 999;

  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;

  > .aside {
    width: 58px;
    background-color: ${COLOR.bk_80};
    text-align: center;

    > .icon {
      margin-top: 16px;
    }
  }

  > .body {
    flex: 1;
    background-color: ${COLOR.white};
    position: relative;

    > .logout-wrapper {
      margin-top: 40px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    > .menu-container {
      margin-top: 10px;
    }

    > .app-build-info {
      > .built-at {
        position: absolute;
        font-size: 12px;
        margin-right: 20px;
        margin-bottom: 20px;
        color: ${COLOR.bk_60};
        bottom: 0;
        left: 15px;
      }

      > .hidden {
        display: none;
      }
    }
  }
`;

export default {
  container,
  detail,
};
