import { ComponentType, useMemo, useState } from "react";
import { useCallback } from "react";

import { ToastV2Props } from "../../headlessComponents/useToast";

type ValidationError = {
  message: string;
  reset?: () => void;
};

export default function useValidationErrorToast(
  Toast: ComponentType<ToastV2Props>
) {
  const [validationError, setValidationError] = useState<
    ValidationError | undefined
  >();

  const handleToastReset = useCallback(() => {
    setValidationError(undefined);
    validationError?.reset?.();
  }, [validationError]);

  const ValidationErrorToast = useMemo(() => {
    if (!validationError) return null;

    return (
      <Toast
        uiType="messageWithIcon"
        status="negative"
        message={validationError.message}
        reset={handleToastReset}
      />
    );
  }, [Toast, handleToastReset, validationError]);

  return [setValidationError, ValidationErrorToast] as const;
}
