import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { ReceivingWorker } from "@sellernote/_shared/src/types/fulfillment/receiving";

import { returningActions } from "modules/returning";

export default function useCandidateSelector({
  deActivate,
  existedWorkerList,
}: {
  deActivate: () => void;
  existedWorkerList?: ReceivingWorker[];
}) {
  const dispatch = useDispatch();

  const match = useRouteMatch<{ id: string }>();
  const {
    params: { id },
  } = match;

  const [selectedCandidate, setSelectedCandidate] =
    useState<InputSelectOption<number>>();

  const workerList = useRecoilValue(FULFILLMENT_COMMON_ATOMS.WORKER_LIST);

  const {
    mutate: addWorkerToInspection,
    ResponseHandler: ResponseHandlerOfAddingWorkerToInspection,
  } = RETURNING_QUERY.useAddWorkerToInspection({
    returningId: Number(id),
    workerId: selectedCandidate?.value ?? 0,
  });

  const candidateOptionList: InputSelectOption<number>[] = useMemo(
    () =>
      workerList
        .filter(
          (v) =>
            !existedWorkerList?.some(
              (existedWorker) => Number(existedWorker.id) === v.id
            )
        )
        .map((v) => ({
          value: v.id,
          label: v.name,
        })),
    [workerList, existedWorkerList]
  );

  const handleCandidateSelect = useCallback(() => {
    addWorkerToInspection(undefined, {
      onSuccess: () => {
        dispatch(
          returningActions.GET_DETAIL({
            id: Number(id),
            _postSuccessCallback: deActivate,
          })
        );
      },
    });
  }, [addWorkerToInspection, deActivate, dispatch, id]);

  return {
    candidateOptionList,
    selectedCandidate,
    setSelectedCandidate,

    handleCandidateSelect,

    ResponseHandlerOfAddingWorkerToInspection,
  };
}
