import styled from "styled-components";

const changeTotalPackingUnitModalBody = styled.div`
  > form.total-packing-unit {
    > .input-text {
      margin-bottom: 16px;
    }
  }

  > .list-item {
    margin-bottom: 24px;
  }

  > .actions {
    > .button:not(:last-child) {
      margin-right: 24px;
    }
  }
`;

export default {
  changeTotalPackingUnitModalBody,
};
