import React from "react";

import Styled from "./index.styles";

export default function TitleWithLine({
  title,
  desc,
  className,
}: {
  title: string;
  desc?: {
    body: React.ReactNode;
    /**
     * 설명이 title과 다른 라인으로 나뉘어지는지 여부
     */
    isSeparated?: boolean;
  };
  className?: string;
}) {
  return (
    <Styled.container
      className={`${className ? className : ""} title-with-line`}
      isBodySeparated={desc?.isSeparated}
    >
      <div className="title">{title}</div>

      {desc && <div className="desc">{desc.body}</div>}
    </Styled.container>
  );
}
