import { useCallback } from "react";
import { useRecoilValue } from "recoil";

import SCAN_QUERY from "@sellernote/_shared/src/queries/fulfillment/SCAN_QUERY";
import SHIPPING_QUERY from "@sellernote/_shared/src/queries/fulfillment/SHIPPING_QUERY";
import { FULFILLMENT_SHIPPING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/shipping";
import { PickingSKUInfoForScanning } from "@sellernote/_shared/src/types/fulfillment/shipping";

import usePickingMessageModal from "./usePickingMessageModal";

export default function useCompletePicking({
  SKUInfoForScanning,
  resetPickingState,
}: {
  SKUInfoForScanning: PickingSKUInfoForScanning[];
  resetPickingState: () => void;
}) {
  const { invoiceNo, shippingId } = useRecoilValue(
    FULFILLMENT_SHIPPING_ATOMS.SCANNED_PICKING_INVOICE
  );
  const isActiveCompleteButton = shippingId !== 0;

  const {
    mutate: interpretBarcode,
    ResponseHandler: ResponseHandlerOfCheckingCancelingInvoice,
  } = SCAN_QUERY.useInterpretBarcode<"shipping">({
    isToCheckForCanceling: true,
  });

  const {
    mutate: completePicking,
    ResponseHandler: ResponseHandlerOfCompletingPicking,
    ResponseHandlerOfStartingPacking,
    ResponseHandlerOfCompletingPacking,
  } = SHIPPING_QUERY.useCompletePicking({
    resetPickingState,
  });

  const {
    MessageModal,
    modalOpenHandler,
    ResponseHandlerOfSettingRestockByPickingQuantity,
  } = usePickingMessageModal();

  const handlePickingComplete = useCallback(() => {
    completePicking();
  }, [completePicking]);

  const checkIsCanceledInvoice = useCallback(() => {
    interpretBarcode(
      {
        type: "shipping",
        barCode: invoiceNo,
      },
      {
        onSuccess: ({ data: invoiceData }) => {
          if (invoiceData.shippingStatus === "cancel") {
            const restockItems = SKUInfoForScanning.map((v) => ({
              itemId: v.id,
              restockQty: v.currentQty,
            }));

            modalOpenHandler.openCanceledInvoiceInPickingMessage({
              invoiceNo: invoiceData.invoiceNo,
              shippingId,
              restockItems,
            });
            return;
          }

          handlePickingComplete();
        },
      }
    );
  }, [
    SKUInfoForScanning,
    handlePickingComplete,
    interpretBarcode,
    invoiceNo,
    modalOpenHandler,
    shippingId,
  ]);

  return {
    invoiceNo,
    isActiveCompleteButton,
    checkIsCanceledInvoice,
    CanceledInvoiceModal: MessageModal,

    ResponseHandlerOfCheckingCancelingInvoice,
    ResponseHandlerOfCompletingPicking,
    ResponseHandlerOfStartingPacking,
    ResponseHandlerOfCompletingPacking,
    ResponseHandlerOfSettingRestockByPickingQuantity,
  };
}
