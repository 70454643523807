import { useState } from "react";

import { getPageSize } from "@sellernote/_shared/src/utils/common/etc";
import Paging from "@sellernote/_sds-v1/src/components/Paging";
import TabFilter from "@sellernote/_sds-v1/src/components/TabFilter";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useFetchInspectionList, {
  FilterForListForInspection,
  TableItemForInspection,
} from "hooks/receiving/useFetchInspectionList";

import Styled from "./index.styles";

const PAGE_UNIT = 10;

function ReceivingInspection() {
  const [filter, setFilter] = useState<FilterForListForInspection>("mine");

  const [currentPage, setCurrentPage] = useState(0);

  const {
    tableDataListForInspection,
    totalCountOfListOfInspection,
    anotherTotalCountOfListOfInspection,
    ResponseHandlerOfGettingListForInspection,
  } = useFetchInspectionList({
    perPage: PAGE_UNIT,
    currentPage,
    filter,
  });

  return (
    <Layout navigator={{ title: "검수" }}>
      <Styled.container>
        <TabFilter<FilterForListForInspection>
          filterList={[
            {
              label: `My 검수 (${
                filter === "mine"
                  ? totalCountOfListOfInspection
                  : anotherTotalCountOfListOfInspection
              })`,
              filter: "mine",
            },
            {
              label: `검수완료 (${
                filter === "mine"
                  ? anotherTotalCountOfListOfInspection
                  : totalCountOfListOfInspection
              })`,
              filter: "inspected",
            },
          ]}
          selectedFilter={filter}
          setFilter={(filter) => {
            setFilter(filter);
            setCurrentPage(0);
          }}
        />

        <Paging
          pageSize={getPageSize(PAGE_UNIT, totalCountOfListOfInspection)}
          currentPage={currentPage}
          currentPageData={
            <Table<TableItemForInspection>
              columnInfo={{
                combinedTitle: {
                  label: "회사명 (팀명)",
                  fixedWidth: 120,
                },
                bidId: {
                  label: "의뢰번호",
                },
                SKU: {
                  label: "상품종류(SKU)",
                },
                PCS: {
                  label: (
                    <>
                      입고요청수량
                      <br />
                      (PCS)
                    </>
                  ),
                  fixedWidth: 110,
                },
                ...(filter === "inspected"
                  ? {
                      completedPCS: {
                        label: <>검수수량(PCS)</>,
                        fixedWidth: 110,
                      },
                      inspectionStatus: {
                        label: "상태",
                        fixedWidth: 110,
                      },
                    }
                  : {}),
              }}
              dataList={tableDataListForInspection}
            />
          }
          handleClickPage={setCurrentPage}
          isZeroBasedPage
        />
      </Styled.container>

      {ResponseHandlerOfGettingListForInspection}
    </Layout>
  );
}

export default withRequireAuth(ReceivingInspection);
