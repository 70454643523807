import React from "react";

import { TabFilterProps } from "@sellernote/_shared/src/headlessComponents/useTabFilter";
import Chip from "@sellernote/_sds-v1/src/components/Chip";

import Styled from "./index.styles";

export default function TabFilter<Filter extends string>({
  filterList,
  selectedFilter,
  setFilter,
  className,
}: TabFilterProps<Filter>) {
  return (
    <Styled.container className={`${className ? className : ""} tab-filter`}>
      {filterList.map((v, i) => {
        return (
          <Chip
            key={v.filter}
            shape="round"
            className="service-badge"
            label={v.label}
            selectableInfo={{
              selected: v.filter === selectedFilter,
              handleClick: () => setFilter(v.filter),
            }}
          />
        );
      })}
    </Styled.container>
  );
}
