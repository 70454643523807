import { ReceivingWorker } from "@sellernote/_shared/src/types/fulfillment/receiving";
import ChipsWithClear from "@sellernote/_sds-v1/src/components/ChipsWithClear";

import Styled from "../index.styles";
import usePersonInChargeList from "./usePersonInChargeList";

function PersonInChargeList({
  receivingManagerId,
  workerList,
}: {
  receivingManagerId?: number;
  workerList: ReceivingWorker[] | undefined;
}) {
  const {
    chipDataList,

    RemoveWorkerFromReceivingModal,

    ResponseHandlerOfRemovingWorkerFromInspection,
    ResponseHandlerOfGettingManagerReceivingDetailToCheck,
  } = usePersonInChargeList({
    receivingManagerId,
    workerList,
  });

  if (!workerList || !workerList.length) return null;

  return (
    <>
      <Styled.personInChargeList>
        <ChipsWithClear chipDataList={chipDataList} />
      </Styled.personInChargeList>

      {RemoveWorkerFromReceivingModal}

      {ResponseHandlerOfRemovingWorkerFromInspection}
      {ResponseHandlerOfGettingManagerReceivingDetailToCheck}
    </>
  );
}

export default PersonInChargeList;
