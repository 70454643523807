import { Dispatch, SetStateAction } from "react";

import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { ValidationResult } from "@sellernote/_shared/src/utils/common/validation";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";

import Styled from "./index.styles";

export default function InputForCounting({
  availableQty,
  tempQuantity,
  setTempQuantity,
  validation,
}: {
  availableQty: number;
  tempQuantity: number | undefined;
  setTempQuantity: Dispatch<SetStateAction<number | undefined>>;
  validation: ValidationResult;
}) {
  return (
    <Styled.inputForCounting>
      <InputText
        borderType="outline"
        valueType="int"
        label={
          <>
            가용수량{" "}
            <span className="available-qty">
              (현재: {toThousandUnitFormat(availableQty)})
            </span>
          </>
        }
        placeholder="입력해주세요."
        value={tempQuantity}
        setValue={setTempQuantity}
        errorMessage={validation.message}
      />
    </Styled.inputForCounting>
  );
}
