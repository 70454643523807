import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

const container = styled.div`
  .paging {
    margin-bottom: 64px;

    .table {
      tr.top-row:not(:first-child) {
        td {
          border-top: 1px solid ${COLOR.grayScale_200};
        }
      }
    }
  }
`;

export default {
  container,
};
