import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { SKUInfoForCancelingRestocking } from "@sellernote/_shared/src/types/fulfillment/canceling";

import useCompleteReportingCancelingProblem from "hooks/canceling/useCompleteReportingCancelingProblem";

import { ProblemList } from ".";

export default function CompleteButton({
  SKUInfoForScanning,
  problemList,
  moveToCancelingListPage,
  handleReportProblemModalClose,
}: {
  SKUInfoForScanning: SKUInfoForCancelingRestocking[];
  problemList: ProblemList;
  moveToCancelingListPage: () => void;
  handleReportProblemModalClose: () => void;
}) {
  const {
    handleReportProblemConfirm,
    isDisabledCompleteButton,
    ResponseHandlerOfReportingCancelingProblem,
    ResponseHandlerOfRestockingCancelingInvoice,
  } = useCompleteReportingCancelingProblem({
    SKUInfoForScanning,
    problemList,
    moveToCancelingListPage,
    handleReportProblemModalClose,
  });

  return (
    <>
      <Button
        theme="primary"
        size="normal"
        label="확인"
        handleClick={handleReportProblemConfirm}
        disabled={isDisabledCompleteButton}
      />

      {ResponseHandlerOfReportingCancelingProblem}
      {ResponseHandlerOfRestockingCancelingInvoice}
    </>
  );
}
