import { useCallback } from "react";

import {
  FileDomainType,
  FulfillmentAttachment,
} from "@sellernote/_shared/src/types/fulfillment/fulfillment";

import useSelectPhotoList from "hooks/common/useSelectPhotoList";
import useUploadFiles from "hooks/common/useUploadFiles";

/**
 * SelectPhotoList 의 파일들을 업로드 및 삭제할 수 있는 hook
 */
export default function useUploadSelectPhotoList({
  type,
  id,
  targetId,
  attachmentList,
  fileDomain,
}: {
  type: "receiving" | "returning";
  id: number;
  targetId: number;
  attachmentList: FulfillmentAttachment[];
  fileDomain: FileDomainType;
}) {
  const {
    SelectPhotoList,
    tempPhotoList,
    resetPhotoList,
    deletePhotoList,
    hasDeletedPhotoList,
    isValidDeletePhoto,
  } = useSelectPhotoList({
    type,
    id,
    attachmentList,
  });

  const [uploadFileList, LoadingToUpload, UploadFileListFailureModal] =
    useUploadFiles();

  const isChangedPhoto =
    (hasDeletedPhotoList && isValidDeletePhoto) || !!tempPhotoList.length;

  const uploadPhotoList = useCallback(
    ({ onUploadSuccess }: { onUploadSuccess?: () => void }) => {
      uploadFileList({
        apiType: "boful",
        data: tempPhotoList.map((photo) => ({
          name: photo.fileName,
          mimeType: photo.fileMimeType,
          targetId,
          domain: fileDomain,
          actorKind: "consignee",
        })),
        files: tempPhotoList.map((photo) => ({
          id: photo.id,
          fileInfo: photo.fileInfo,
        })),
        onUploadSuccess,
      });
    },
    [fileDomain, targetId, tempPhotoList, uploadFileList]
  );

  return {
    SelectPhotoList,
    tempPhotoList,
    resetPhotoList,
    deletePhotoList,
    LoadingToUpload,
    UploadFileListFailureModal,
    isChangedPhoto,
    uploadPhotoList,
  };
}
