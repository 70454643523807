import { Dispatch, memo, SetStateAction } from "react";

import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

import CountQuantityModal from "../../CountQuantityModal";
import TempInput from "../../TempInput";
import useQuantityAfterAdjustment from "./useQuantityAfterAdjustment";
import Styled from "./index.styles";

function QuantityAfterAdjustment({
  skuId,
  skuBarcode,
  availableQty,
  quantityToAdjust,
  setQuantityToAdjust,
}: {
  skuId: number;
  skuBarcode: string | undefined;
  availableQty: number;
  quantityToAdjust: number | undefined;
  setQuantityToAdjust: Dispatch<SetStateAction<number | undefined>>;
}) {
  const {
    isVisibleCountQuantityModal,
    handleCountQuantityModalOpen,
    handleCountQuantityModalClose,
  } = useQuantityAfterAdjustment();

  return (
    <>
      <Styled.quantityAfterAdjustment>
        <ListItem
          label="조정 후 수량"
          type="withContentAlignRight"
          value={
            <Styled.inputWrapper onClick={handleCountQuantityModalOpen}>
              <InputText
                placeholder="수량을 입력해주세요."
                width={12.625}
                borderType="outline"
                valueType="int"
                value={quantityToAdjust}
                icon="search"
                disabledToFocus
                visiblePostFix
              />
              <TempInput />
            </Styled.inputWrapper>
          }
        />
      </Styled.quantityAfterAdjustment>

      <CountQuantityModal
        active={isVisibleCountQuantityModal}
        type="adjustment"
        skuId={getFormattedSingleSkuId(skuId)}
        skuBarcode={skuBarcode}
        availableQty={availableQty}
        setQuantity={setQuantityToAdjust}
        onClose={handleCountQuantityModalClose}
      />
    </>
  );
}

export default memo(QuantityAfterAdjustment);
