import { Dispatch, SetStateAction } from "react";

import { InventoryDetailBySKUId } from "@sellernote/_shared/src/api-interfaces/boful-api/inventory";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import CountQuantityModal from "../../CountQuantityModal";
import { SkuToSelect } from "../../SelectSkuModal/useSelectSkuModal";
import { InventoryToConsolidate } from "../useInventoryConsolidation";
import useConsolidationTable from "./useConsolidationTable";
import Styled from "./index.styles";

function ConsolidationTable({
  selectedSku,
  inventoryDetailOfSelectedSku,
  listOfInventoryToConsolidate,
  setListOfInventoryToConsolidate,
}: {
  selectedSku: SkuToSelect;
  inventoryDetailOfSelectedSku: InventoryDetailBySKUId[];
  listOfInventoryToConsolidate: InventoryToConsolidate[];
  setListOfInventoryToConsolidate: Dispatch<
    SetStateAction<InventoryToConsolidate[]>
  >;
}) {
  const {
    tableDataList,

    isVisibleCountQuantityModal,
    availableQty,
    handleQuantityToConsolidateSet,
    handleCountQuantityModalClose,
  } = useConsolidationTable({
    selectedSku,
    inventoryDetailOfSelectedSku,
    listOfInventoryToConsolidate,
    setListOfInventoryToConsolidate,
  });

  return (
    <>
      <Styled.consolidationTable>
        <Table
          columnInfo={{
            location: {
              label: "보관위치",
              fixedWidth: 120,
            },
            availableQty: {
              label: "병합가능수량",
              fixedWidth: 100,
            },
            consolidationQty: {
              label: "병합할 수량",
              fixedWidth: 220,
            },
            reset: {
              label: "초기화",
              fixedWidth: 120,
            },
          }}
          dataList={tableDataList}
        />
      </Styled.consolidationTable>

      <CountQuantityModal
        active={isVisibleCountQuantityModal}
        type="consolidation"
        skuId={getFormattedSingleSkuId(inventoryDetailOfSelectedSku[0].sku.id)}
        skuBarcode={inventoryDetailOfSelectedSku[0].sku.barCode}
        availableQty={availableQty}
        setQuantity={handleQuantityToConsolidateSet}
        onClose={handleCountQuantityModalClose}
      />
    </>
  );
}

export default ConsolidationTable;
