import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const labelAndButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .label {
    ${setFontStyle("SubHead4")};

    > em {
      ${setFontStyle("Caption")};
      color: ${COLOR.pointWarning};
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    gap: 8px;
  }
`;

const allSelectAndDeleteContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .all-select {
    display: flex;
    align-items: center;
    gap: 4px;
    ${setFontStyle("SubHead3", "Bold")};
    color: ${COLOR.grayScale_700};
  }
`;

const photoList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  > li {
    width: 50%;
    flex: 0 0 calc(50% - 8px);
    display: flex;
    gap: 4px;
  }
`;

const thumbnail = styled.div<{ imageUrl: string }>`
  width: 100%;
  padding-top: 71.5%;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  background-position: center;
`;

const noPhotoList = styled.div`
  padding: 80px 0;
  background-color: ${COLOR.grayScale_50};
  color: ${COLOR.grayScale_500};
  ${setFontStyle("Body8")};
`;

export default {
  container,
  labelAndButtonContainer,
  allSelectAndDeleteContainer,
  photoList,
  thumbnail,
  noPhotoList,
};
