import { useEffect, useMemo, useState } from "react";

import INVENTORY_QUERY from "@sellernote/_shared/src/queries/fulfillment/INVENTORY_QUERY";
import { BofulLocation } from "@sellernote/_shared/src/types/fulfillment/common";
import { usePrevious } from "@sellernote/_shared/src/utils/common/hook";

import ItemToMove from "./ItemToMove";

export interface InventoryToMove {
  location: BofulLocation | undefined;
  quantity: number;
}

const initialList = [{ location: undefined, quantity: 0 }];

export default function useInventoryMovement() {
  const [existingLocation, setExistingLocation] = useState<BofulLocation>();
  const [totalAvailableQty, setTotalAvailableQty] = useState<number>(0);
  const [listOfInventoryToMove, setListOfInventoryToMove] =
    useState<InventoryToMove[]>(initialList);

  const {
    data: inventoryDetailByLocation,
    ResponseHandler: ResponseHandlerOfGettingInventoryDetailByLocation,
  } = INVENTORY_QUERY.useGetInventoryDetailByLocation({
    barCode: existingLocation?.barCode ?? "",
    enabled: !!existingLocation?.barCode,
    onSuccess: ({ availableQty }) => setTotalAvailableQty(availableQty),
  });

  const previousBarcode = usePrevious(existingLocation?.barCode);

  useEffect(() => {
    const isChangedExistingLocation =
      existingLocation && existingLocation.barCode !== previousBarcode;
    if (isChangedExistingLocation) {
      setListOfInventoryToMove(initialList);
    }
  }, [existingLocation, previousBarcode]);

  const AddedItemListToMove = useMemo(
    () =>
      listOfInventoryToMove.map((item, index) => {
        return (
          <ItemToMove
            key={item.location?.id ?? 0}
            skuId={inventoryDetailByLocation?.sku.id ?? 0}
            skuBarcode={inventoryDetailByLocation?.sku.barCode}
            existingLocationBarcode={existingLocation?.barCode}
            managementDate={inventoryDetailByLocation?.managementDate}
            totalAvailableQty={totalAvailableQty}
            setTotalAvailableQty={setTotalAvailableQty}
            listOfInventoryToMove={listOfInventoryToMove}
            setListOfInventoryToMove={setListOfInventoryToMove}
            indexOfItem={index}
          />
        );
      }),
    [
      existingLocation?.barCode,
      inventoryDetailByLocation?.managementDate,
      inventoryDetailByLocation?.sku.barCode,
      inventoryDetailByLocation?.sku.id,
      listOfInventoryToMove,
      totalAvailableQty,
    ]
  );

  return {
    existingLocation,
    setExistingLocation,

    listOfInventoryToMove,

    inventoryDetailByLocation,

    AddedItemListToMove,

    ResponseHandlerOfGettingInventoryDetailByLocation,
  };
}
