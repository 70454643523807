import { memo } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import ConfirmWrapper from "@sellernote/_sds-v1/src/components/ConfirmWrapper";

import { InventoryToConsolidate } from "../useInventoryConsolidation";
import useCompleteConsolidation from "./useCompleteConsolidation";
import Styled from "./index.styles";

function CompleteConsolidation({
  locationIdToConsolidate,
  skuId,
  listOfInventoryToConsolidate,
}: {
  locationIdToConsolidate: number | undefined;
  skuId: number;
  listOfInventoryToConsolidate: InventoryToConsolidate[];
}) {
  const {
    isValidToConsolidate,
    handleConsolidationComplete,

    ResponseHandlerOfCompletingInventoryConsolidation,
  } = useCompleteConsolidation({
    locationIdToConsolidate,
    skuId,
    listOfInventoryToConsolidate,
  });

  return (
    <>
      <Styled.completeConsolidation>
        <ConfirmWrapper
          confirmModalUiType="titleOnly"
          confirmMessageTitle="재고 병합을 진행 하시겠습니까?"
          customButtonLabel={{ positive: "진행", negative: "취소" }}
          disabled={!isValidToConsolidate}
        >
          <Button
            className="complete-button"
            theme="primary"
            size="block"
            label="병합완료"
            handleClick={handleConsolidationComplete}
            disabled={!isValidToConsolidate}
          />
        </ConfirmWrapper>
      </Styled.completeConsolidation>

      {ResponseHandlerOfCompletingInventoryConsolidation}
    </>
  );
}

export default memo(CompleteConsolidation);
