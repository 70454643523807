import { useCallback, useState } from "react";

import {
  CancelingStatus,
  Canceller,
} from "@sellernote/_shared/src/types/fulfillment/canceling";
import { getPageSize } from "@sellernote/_shared/src/utils/common/etc";
import InputRadio from "@sellernote/_sds-v1/src/components/input/InputRadio";
import Paging from "@sellernote/_sds-v1/src/components/Paging";
import TabFilter from "@sellernote/_sds-v1/src/components/TabFilter";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useCancelingList, {
  CancelingTableItemType,
} from "hooks/canceling/useCancelingList";
import useCheckCancelingInvoice from "hooks/canceling/useCheckCancelingInvoice";
import useScanCancelingInvoice from "hooks/canceling/useScanCancelingInvoice";

import Styled from "./index.styles";

const PAGE_UNIT = 10;

function CancelingMain() {
  const [filterForCancelingStatus, setFilterForCancelingStatus] =
    useState<CancelingStatus>("waiting");
  const [filterForCanceller, setFilterForCanceller] = useState<Canceller>();
  const [currentPage, setCurrentPage] = useState(0);

  const handleCancelingStatusFilterClick = useCallback((v: CancelingStatus) => {
    setCurrentPage(0);
    setFilterForCancelingStatus(v);
  }, []);

  const handleCancellerFilterClick = useCallback((v: Canceller | undefined) => {
    setCurrentPage(0);
    setFilterForCanceller(v);
  }, []);

  const { MessageModal, checkInvoice } = useCheckCancelingInvoice();

  const { ScanInvoiceButton, ResponseHandlerOfScanningInvoice } =
    useScanCancelingInvoice(checkInvoice);

  const {
    cancelingTotal,
    cancelingAnotherTotal,
    dataListByCancelingStatus,

    columnInfoByCancelingStatus,

    ResponseHandlerOfGettingCancelingList,
  } = useCancelingList(
    currentPage,
    filterForCancelingStatus,
    filterForCanceller
  );

  return (
    <Layout
      navigator={{
        title: "취소관리",
      }}
    >
      <Styled.container>
        <TabFilter<CancelingStatus>
          filterList={[
            {
              label: `주문취소(${
                filterForCancelingStatus === "waiting"
                  ? cancelingTotal
                  : cancelingAnotherTotal
              })`,
              filter: "waiting",
            },
            {
              label: `재입고마감(${
                filterForCancelingStatus === "done"
                  ? cancelingTotal
                  : cancelingAnotherTotal
              })`,
              filter: "done",
            },
          ]}
          selectedFilter={filterForCancelingStatus}
          setFilter={handleCancelingStatusFilterClick}
        />
        <div className="filter-scan">
          <InputRadio<Canceller | undefined>
            direction="row"
            optionList={[
              {
                label: "전체",
                value: undefined,
              },
              {
                label: "고객사",
                value: "user",
              },
              {
                label: "관리자",
                value: "warehouseMaster",
              },
            ]}
            selectedValue={filterForCanceller}
            selectCallback={handleCancellerFilterClick}
          />

          {filterForCancelingStatus === "waiting" && ScanInvoiceButton}
        </div>

        <Paging
          pageSize={getPageSize(PAGE_UNIT, cancelingTotal)}
          currentPage={currentPage}
          currentPageData={
            <Table<CancelingTableItemType>
              columnInfo={columnInfoByCancelingStatus}
              dataList={dataListByCancelingStatus}
            />
          }
          handleClickPage={setCurrentPage}
          isZeroBasedPage
        />
      </Styled.container>

      {MessageModal}

      {ResponseHandlerOfGettingCancelingList}
      {ResponseHandlerOfScanningInvoice}
    </Layout>
  );
}

export default withRequireAuth(CancelingMain);
