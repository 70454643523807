import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import RECEIVING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";

function CompleteConfirm({ receivingId }: { receivingId: number }) {
  const history = useHistory();

  const canCompleteConfirmation = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.CAN_COMPLETE_CONFIRMATION
  );

  const { mutate: setArrived, ResponseHandler: ResponseHandlerOfSetArrived } =
    RECEIVING_QUERY.useSetArrived({ receivingId });

  return (
    <>
      <Button
        theme="primary"
        size="block"
        label={"제품수령완료"}
        handleClick={() => {
          setArrived(undefined, {
            onSuccess: () => {
              history.replace("/receiving/confirm");
            },
          });
        }}
        disabled={!canCompleteConfirmation}
      />

      {ResponseHandlerOfSetArrived}
    </>
  );
}

export default CompleteConfirm;
