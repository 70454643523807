import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import { BofulLocation } from "@sellernote/_shared/src/types/fulfillment/common";

import { InventoryToMove } from "../useInventoryMovement";

export default function useItemToMove({
  totalAvailableQty,
  setTotalAvailableQty,
  listOfInventoryToMove,
  setListOfInventoryToMove,
  indexOfItem,
}: {
  totalAvailableQty: number;
  setTotalAvailableQty: Dispatch<SetStateAction<number>>;
  listOfInventoryToMove: InventoryToMove[];
  setListOfInventoryToMove: Dispatch<SetStateAction<InventoryToMove[]>>;
  indexOfItem: number;
}) {
  const [isVisibleSelectLocationModal, setIsVisibleSelectLocationModal] =
    useState(false);
  const [isVisibleCountQuantityModal, setIsVisibleCountQuantityModal] =
    useState(false);

  /**
   * AddedItemListToMove의 ItemToMove는 item.location.id를 key로 가지기 때문에
   * 새로운 위치가 추가되어 id값이 바뀌면 리렌더링 시 값이 초기화 된다.
   * 따라서 state는 리스트에 값을 추가하는 용도 이외에는 사용해서는 안 된다.
   * (listOfInventoryToMove에 저장된 값을 사용)
   */
  const [locationToMove, setLocationToMove] = useState<BofulLocation>();
  const [quantityToMove, setQuantityToMove] = useState<number>();

  const isActiveItem = listOfInventoryToMove.length - 1 === indexOfItem;
  const isAddedItem = indexOfItem > 0;

  const selectedLocationToMove = listOfInventoryToMove[indexOfItem].location
    ?.barCode
    ? `인천1센터 / ${listOfInventoryToMove[indexOfItem].location?.barCode}`
    : "";
  const countedQuantityToMove = listOfInventoryToMove[indexOfItem].quantity;

  const isValidToAddItem =
    !!selectedLocationToMove &&
    !!countedQuantityToMove &&
    totalAvailableQty > countedQuantityToMove;

  useEffect(() => {
    if (isActiveItem && locationToMove) {
      setListOfInventoryToMove((prevListOfInventoryToMove) => {
        const newListOfInventoryToMove = [...prevListOfInventoryToMove];

        return [
          ...newListOfInventoryToMove.slice(0, -1),
          {
            ...newListOfInventoryToMove[newListOfInventoryToMove.length - 1],
            location: locationToMove,
          },
        ];
      });
    }
  }, [isActiveItem, locationToMove, setListOfInventoryToMove]);

  useEffect(() => {
    if (isActiveItem && quantityToMove) {
      setListOfInventoryToMove((prevListOfInventoryToMove) => {
        const newListOfInventoryToMove = [...prevListOfInventoryToMove];

        return [
          ...newListOfInventoryToMove.slice(0, -1),
          {
            ...newListOfInventoryToMove[newListOfInventoryToMove.length - 1],
            quantity: quantityToMove,
          },
        ];
      });
    }
  }, [isActiveItem, quantityToMove, setListOfInventoryToMove]);

  const handleLocationToMoveModalOpen = useCallback(() => {
    if (!isActiveItem) {
      return;
    }

    setIsVisibleSelectLocationModal(true);
  }, [isActiveItem]);

  const handleLocationToMoveModalClose = useCallback(() => {
    setIsVisibleSelectLocationModal(false);
  }, []);

  const handleCountQuantityModalOpen = useCallback(() => {
    if (!isActiveItem) {
      return;
    }

    setIsVisibleCountQuantityModal(true);
  }, [isActiveItem]);

  const handleCountQuantityModalClose = useCallback(() => {
    setIsVisibleCountQuantityModal(false);
  }, []);

  const handleAllQuantitySelect = useCallback(() => {
    setQuantityToMove(totalAvailableQty);
  }, [totalAvailableQty]);

  const handleItemAdd = useCallback(() => {
    setTotalAvailableQty((prevTotalAvailableQty) => {
      return prevTotalAvailableQty - (quantityToMove ?? 0);
    });

    setListOfInventoryToMove((prevListOfInventoryToMove) => {
      const newListOfInventoryToMove = [...prevListOfInventoryToMove];

      return [
        ...newListOfInventoryToMove,
        {
          location: undefined,
          quantity: 0,
        },
      ];
    });
  }, [quantityToMove, setListOfInventoryToMove, setTotalAvailableQty]);

  // 아이템을 삭제하면 이전 아이템이 활성화(미확정) 상태로 변경되기 때문에 총수량을 되돌리기
  const handleItemRemove = useCallback(() => {
    setTotalAvailableQty((prevTotalAvailableQty) => {
      return (
        prevTotalAvailableQty + listOfInventoryToMove[indexOfItem - 1].quantity
      );
    });

    setListOfInventoryToMove((prevListOfInventoryToMove) => {
      const newListOfInventoryToMove = [...prevListOfInventoryToMove];

      return [...newListOfInventoryToMove.slice(0, -1)];
    });
  }, [
    indexOfItem,
    listOfInventoryToMove,
    setListOfInventoryToMove,
    setTotalAvailableQty,
  ]);

  return {
    isActiveItem,
    isAddedItem,
    handleItemRemove,

    handleLocationToMoveModalOpen,
    selectedLocationToMove,

    handleCountQuantityModalOpen,
    countedQuantityToMove,
    handleAllQuantitySelect,

    handleItemAdd,
    isValidToAddItem,

    isVisibleSelectLocationModal,
    setLocationToMove,
    handleLocationToMoveModalClose,

    isVisibleCountQuantityModal,
    setQuantityToMove,
    handleCountQuantityModalClose,
  };
}
