import { getI18nInstanceForDesignSystem } from "@sellernote/_shared/src/i18n/i18nForDesignSystem";

import Styled from "./index.styles";

/**
 * i18n을 사용하는 컴포넌트 sample.
 */
export default function SampleWithI18n({ className }: { className?: string }) {
  const { Trans, t } = getI18nInstanceForDesignSystem();

  return (
    <Styled.container
      className={`${className ? className : ""} sample-with-i18n`}
    >
      SampleWithI18n
      <p>{t("common:login")}</p>
      <p>
        <Trans i18nKey="common:confirm" />
      </p>
    </Styled.container>
  );
}
