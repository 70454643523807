import styled from "styled-components";

const quantityAfterAdjustment = styled.div`
  margin-bottom: 40px;
`;

const inputWrapper = styled.div`
  position: relative;
`;

export default {
  quantityAfterAdjustment,
  inputWrapper,
};
