import React, { useCallback } from "react";
import { useQueryClient } from "react-query";

import { SET_ITEM_INSPECTION_DONE_BY_FORCE_REQ_PATH_PARAMS } from "@sellernote/_shared/src/api-interfaces/boful-api/receiving";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";

import { ConfirmModal } from "hooks/common/useConfirmModal";
import useResetInputtingCounterInfo from "hooks/common/useResetInputtingCounterInfo";

export default function useCompleteInspectionByForce() {
  const resetInputtingCounterInfo = useResetInputtingCounterInfo();

  const queryClient = useQueryClient();

  const {
    mutate: setItemInspectionDoneByForce,
    ResponseHandler: ResponseHandlerOfCompletingInspectionByForce,
  } = RECEIVING_QUERY.useSetItemInspectionDoneByForce();

  const completeInspectionByForce = useCallback(
    ({
        receivingId,
        itemId,
        setConfirmModal,
      }: SET_ITEM_INSPECTION_DONE_BY_FORCE_REQ_PATH_PARAMS & {
        setConfirmModal: React.Dispatch<
          React.SetStateAction<ConfirmModal | undefined>
        >;
      }) =>
      () => {
        setItemInspectionDoneByForce(
          {
            pathParams: {
              receivingId,
              itemId,
            },
          },
          {
            onSuccess: () => {
              resetInputtingCounterInfo();

              setConfirmModal(undefined);

              queryClient.invalidateQueries(
                RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
                  receivingId,
                })
              );
            },
          }
        );
      },
    [queryClient, resetInputtingCounterInfo, setItemInspectionDoneByForce]
  );

  return {
    completeInspectionByForce,
    ResponseHandlerOfCompletingInspectionByForce,
  };
}
