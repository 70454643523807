import React from "react";
import { Link } from "react-router-dom";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

import Styled from "./index.styles";

/**
 * List Item과 같은 형태이나, 해당 아이템을 선택 시 상세정보 페이지로 이동한다.
 */
export default function ListItemWithMoreResult({
  label,
  value,
  path,
  className,
}: {
  label: React.ReactNode;
  value: React.ReactNode;
  path: string;
  className?: string;
}) {
  return (
    <Link to={path}>
      <Styled.container
        className={`${className ? className : ""} list-item-with-more-result`}
      >
        <div className="label">{label}</div>
        <div className="value-container">
          <div className="value">{value}</div>
          <Icon type="arrowRight" size={1} color={COLOR.grayScale_800} />
        </div>
      </Styled.container>
    </Link>
  );
}
