import styled, { css } from "styled-components";

import { tablet } from "@sellernote/_shared/src/styles/functions";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

interface ContainerProps {}
const container = styled.div``;

const dataContainer = styled.div``;

const pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  width: 90%;
  margin: 1.5rem auto;
  overflow: scroll;

  ${tablet(css`
    margin: 16px auto;
  `)};

  > .paging-arrow {
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }
  }
`;

const pageIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > .item {
    ${setFontStyle("Body8")};
    color: ${COLOR.grayScale_700};
    padding: 0.0625rem 1rem;
    cursor: pointer;
    position: relative;

    &:not(:last-child) {
      &::after {
        content: "";
        width: 0.0625rem;
        height: 0.875rem;
        background-color: ${COLOR.grayScale_300};
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
      }
    }

    &.selected {
      ${setFontStyle("SubHead4", "Bold")};
      color: ${COLOR.primaryBlue};
    }
  }
`;

const selectedCount = styled.div`
  margin-bottom: 16px;
  background-color: ${COLOR.grayScale_200};
  color: ${COLOR.grayScale_800};
  ${setFontStyle("Body6")};
  padding: 8px;
  width: 100%;
  height: 40px;
  text-align: center;

  > strong {
    ${setFontStyle("Body6", "Bold")};
  }
`;

export default {
  container,
  dataContainer,
  pagination,
  pageIndicator,
  selectedCount,
};
