import { selector } from "recoil";

import { CurrentManager } from "../../../types/fulfillment/auth";

import { FULFILLMENT_AUTH_ATOMS } from ".";

const CURRENT_MANAGER = selector<CurrentManager | undefined>({
  key: "fulfillment/auth/selectors/CURRENT_MANAGER",
  get: ({ get }) => {
    const loggedIn = get(FULFILLMENT_AUTH_ATOMS.LOGGED_IN);

    if (!loggedIn) return undefined;

    if (typeof window === "undefined") return undefined;

    const data = window.localStorage.getItem("currentUser");

    if (!data) return undefined;

    return JSON.parse(data);
  },
});

export default { CURRENT_MANAGER };
