import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import { BofulProblem } from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import { BOFUL_PROBLEM_OPTION_LIST } from "@sellernote/_shared/src/utils/fulfillment/constants";
import InputSelect from "@sellernote/_sds-v1/src/components/input/InputSelect";

export default function SKUProblem({
  selectedProblem,
  setProblem,
}: {
  selectedProblem?: InputSelectOption<BofulProblem>;
  setProblem: (problem: InputSelectOption<BofulProblem>) => void;
}) {
  return (
    <InputSelect<BofulProblem>
      placeholder="선택"
      uiType="outline"
      optionList={BOFUL_PROBLEM_OPTION_LIST}
      selectedOption={selectedProblem}
      handleSelect={setProblem}
      width="100%"
    />
  );
}
