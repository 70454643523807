/**
 * 주어진 HTML 요소가 오버플로우 상태인지 확인하는 함수입니다.
 *
 * @param element - 검사할 HTML 요소입니다. null일 수 있습니다.
 * @returns 주어진 요소가 오버플로우 상태이면 true를, 그렇지 않으면 false를 반환합니다.
 *
 * @remarks
 * 이 함수는 요소의 scrollHeight와 clientHeight, scrollWidth와 clientWidth를 비교하여 오버플로우 상태를 판단합니다.
 */
function isOverflownElement(element: HTMLElement | null) {
  if (!element) return false;

  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

export { isOverflownElement };
