import { createSelector } from "reselect";

import {
  FileDomainType,
  FulfillmentAttachment,
} from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { convertItemListToMapBySKU } from "@sellernote/_shared/src/utils/fulfillment/common";
import { checkForNormalItemAsInspection } from "@sellernote/_shared/src/utils/fulfillment/inspection";

import { authSelectors } from "modules/auth";
import { RootState } from "store";

const getSKUItems = createSelector(
  (state: RootState) => state.returning?.GET_DETAIL?.data?.returning.items,
  (SKUItems) => {
    return SKUItems || [];
  }
);

/**
 * 검수 작업 상황을 확인할 때에는 불일치 상품을 제외하고 확인한다.
 */
const getSkuItemListWithoutUnverifiedItem = createSelector(
  getSKUItems,
  (SKUItems) => {
    return SKUItems.filter(checkForNormalItemAsInspection);
  }
);

const getCurrentUserIsReturningManager = createSelector(
  (state: RootState) => authSelectors.getCurrentUser(),
  (state: RootState) => state.returning?.GET_DETAIL?.data,
  (currentUser, returnDetail) => {
    if (!currentUser || !returnDetail?.returning) {
      return false;
    }

    return currentUser.id === returnDetail.returning.managerId;
  }
);

const getCurrentUserIsPersonInCharge = createSelector(
  (state: RootState) => authSelectors.getCurrentUser(),
  (state: RootState) => state.returning?.GET_DETAIL?.data?.returning.workerList,
  (currentUser, workerList) => {
    if (!currentUser || !workerList) {
      return false;
    }

    return !!workerList.find((v) => +v.id === currentUser.id);
  }
);

const checkIsAllItemsCompleted = createSelector(
  getSkuItemListWithoutUnverifiedItem,
  (skuItemListWithoutUnverifiedItem) => {
    const mapBySku =
      convertItemListToMapBySKU(skuItemListWithoutUnverifiedItem) ?? [];

    return Object.values(mapBySku).every((items) => {
      /**
       * 관리일자가 지정되지 않은 경우에는 아이템이 하나만 존재하고,
       * 관리일자가 지정된 상품이면 해당 아이템 중 하나라도 검수가 완료된 경우 해당 상품은 완료된 것으로 간주하기 때문에
       * 검수가 완료된 상품이 하나라도 존재하는지 확인
       */
      const isComplete = items.some((item) => item.isCompleteInspection);

      return isComplete;
    });
  }
);

/**
 * 모든 SKU가 반품요청수량 전체에 대해서 검수완료되었는지 확인
 */
const checkIsCompletedInspectionWithMatchedCount = createSelector(
  getSkuItemListWithoutUnverifiedItem,
  checkIsAllItemsCompleted,
  (skuItemListWithoutUnverifiedItem, isAllItemsCompleted) => {
    const mapBySku =
      convertItemListToMapBySKU(skuItemListWithoutUnverifiedItem) ?? [];

    const isAllItemsMatched = Object.values<ReceivingItem[]>(mapBySku).every(
      (itemList) => {
        const quantity = itemList.reduce((acc, item) => acc + item.quantity, 0);

        const actualQty = itemList.reduce(
          (acc, item) => acc + (item.actualQty ?? 0),
          0
        );

        return quantity === actualQty;
      }
    );

    return isAllItemsCompleted && isAllItemsMatched;
  }
);

const getCanCloseInspection = createSelector(
  getCurrentUserIsReturningManager,
  checkIsAllItemsCompleted,
  (currentUserIsReceivingManager, isAllItemsCompleted) => {
    return currentUserIsReceivingManager && isAllItemsCompleted;
  }
);

const checkIsCompletedWarehousingWithMatchedCount = createSelector(
  getSKUItems,
  (SKUItems) => {
    if (!SKUItems?.length) {
      return false;
    }

    return SKUItems.every((item) => {
      if (!item.placeItems) {
        return false;
      }

      /**
       * 초과수량 입고가 가능하기 때문에 전체수량과 입고된 수량의 총합을 비교하는 것이 아니라,
       * 개별 아이템의 수량 일치 여부를 확인하여 수량 불일치인 경우 문제보고가 될 수 있도록 함
       */
      return item.placeItems.every((placeItem) => {
        const isMatched = placeItem.quantity === placeItem.placeQty;
        const isCompleted = placeItem.isCompletePlacing;

        return isMatched && isCompleted;
      });
    });
  }
);

const checkCanCloseWarehousing = createSelector(getSKUItems, (SKUItems) => {
  if (!SKUItems?.length) return false;

  return SKUItems.every((item) => {
    if (!item.placeItems) return true;

    return item.placeItems.every((pi) => pi.isCompletePlacing);
  });
});

const getReturningPictureDict = createSelector(
  (state: RootState) => state.returning.GET_DETAIL?.data?.attachment,
  (attachment) => {
    const dict: {
      [P in FileDomainType]?: FulfillmentAttachment[];
    } = {};

    if (!attachment?.length) return dict;

    attachment.forEach((v) => {
      if (dict[v.domain]) {
        dict[v.domain]?.push(v);
      } else {
        dict[v.domain] = [v];
      }
    });

    return dict;
  }
);

const getAllRequiredPicturesAreUploaded = createSelector(
  (state: RootState) => state.returning.GET_DETAIL?.data?.returning.delivery,
  (state: RootState) =>
    state.returning.GET_DETAIL?.data?.returning.isInvoiceMatched,
  getReturningPictureDict,
  (delivery, isInvoiceMatched, pictureDict) => {
    if (delivery === "truckRequest") {
      return (
        !!pictureDict["cargo"]?.length &&
        !!pictureDict["shippingReceipt"]?.length &&
        !!pictureDict["packing"]?.length
      );
    }

    if (delivery === "parcel") {
      const hasInvoiceChecked =
        !!isInvoiceMatched || !!pictureDict["invoice"]?.length;

      return hasInvoiceChecked && !!pictureDict["packing"]?.length;
    }

    return false;
  }
);

const getCanCompleteConfirmation = createSelector(
  getAllRequiredPicturesAreUploaded,
  (state: RootState) => !!getReturningPictureDict(state)["damages"]?.length,
  (state: RootState) =>
    authSelectors.getCurrentUser()?.authority === "whMaster" ||
    authSelectors.getCurrentUser()?.authority === "admin",
  (allRequiredPicturesAreUploaded, hasDamage, currentUserIsWHMasterLike) => {
    if (currentUserIsWHMasterLike) {
      // 데미지 유무와 상관없이 창고관리자는 완료처리가능함
      return allRequiredPicturesAreUploaded;
    }

    if (hasDamage) {
      return false;
    } else {
      return allRequiredPicturesAreUploaded;
    }
  }
);

export default {
  getSKUItems,
  getCurrentUserIsReturningManager,
  getCurrentUserIsPersonInCharge,
  checkIsCompletedInspectionWithMatchedCount,
  getCanCloseInspection,
  checkIsCompletedWarehousingWithMatchedCount,
  checkCanCloseWarehousing,
  getReturningPictureDict,
  getAllRequiredPicturesAreUploaded,
  getCanCompleteConfirmation,
};
