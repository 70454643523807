import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";

import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import useValidationErrorModal from "@sellernote/_shared/src/hooks/common/useValidationErrorModal";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { noop } from "@sellernote/_shared/src/utils/common/etc";
import {
  changeScanModeToLocationOnFullCount,
  checkIsGroupedItem,
  getManagementDateLabel,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";

import useScan from "hooks/common/useScan";
import useSelectDuplicateBarcode from "hooks/common/useSelectDuplicateBarcode";
import { ScanModeForReceivingWarehousing } from "pages/receiving/warehousing/:id/Warehousing";

import ItemStatus from "components/ItemStatus";

import {
  checkScannedSkuInCounterData,
  getCounterKeyFromScanResultByPlacingIdInProgress,
  getIncompleteSingleLocationCounterKeyFromScannedSkuBarcode,
  getIncompleteSingleLocationSkuListBySkuBarcode,
  getSingleLocationCounterKeyFromScanResult,
  SKUCountingForWarehousing,
  WarehousingCounterSKU,
} from "./useSKUCountingForWarehousing";

/**
 * 일반입고/분할입고를 구분
 */
type ScanTypeInfo =
  | {
      scanType: "single";
      itemList: ReceivingItem[];
      groupedItemIdInProgress: number;
      setGroupedItemIdInProgress: (val: number) => void;
      setSkuInProgress: (val: WarehousingCounterSKU | undefined) => void;
    }
  | { scanType: "multi"; placingId?: string };

export default function useScanWarehousingSKU({
  skuCounting,
  receivingId,
  setRowInfoToHighlight,
  setScanMode,
  ...scanTypeInfo
}: ScanTypeInfo & {
  skuCounting: SKUCountingForWarehousing;
  receivingId: number;
  setRowInfoToHighlight: (val: TableRowInfoToHighlight) => void;
  setScanMode: (v: ScanModeForReceivingWarehousing) => void;
}) {
  const [active, setActive] = useState(false);

  const currentManager = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );

  const queryClient = useQueryClient();

  const {
    mutate: assignPlacerToItem,
    ResponseHandler: ResponseHandlerOfAssigningPlacerToItem,
  } = RECEIVING_QUERY.useAssignPlacerToItem();

  const [setValidationError, ValidationErrorModal] = useValidationErrorModal();

  const { handleSelectionSkuModalOpen, SelectSkuModal } =
    useSelectDuplicateBarcode<"receivingWarehousing">(
      scanTypeInfo.scanType === "single"
        ? {
            type: "receivingWarehousing",
            itemList: scanTypeInfo.itemList,
            setGroupedItemIdInProgress: scanTypeInfo.setGroupedItemIdInProgress,
            setSkuInProgress: scanTypeInfo.setSkuInProgress,
          }
        : {
            type: "receivingWarehousing",
            itemList: [],
            setGroupedItemIdInProgress: noop,
            setSkuInProgress: noop,
          }
    );

  const setScanSKUActive = useCallback((val: boolean) => {
    setActive(val);
  }, []);

  const showSelectionErrorForScanSKU = useCallback(() => {
    setValidationError({
      title: `분할입고할 항목을 '선택'한 후 '상품스캔'을 진행해주세요.`,
    });
  }, [setValidationError]);

  const handleScanResult = useCallback(
    (scanResult: string) => {
      if (!active) return;

      if (!receivingId || !currentManager) return;

      let scannedCounterKey: string | undefined;

      if (scanTypeInfo.scanType === "multi") {
        if (!scanTypeInfo.placingId) {
          setValidationError({
            title: `분할입고할 항목을 '선택'한 후 '상품스캔'을 진행해주세요.`,
          });
          return;
        }

        // 분할입고의 경우 선택된 placingId를 기준으로 바코드를 찾는다.
        scannedCounterKey = getCounterKeyFromScanResultByPlacingIdInProgress({
          counterData: skuCounting,
          scanResult,
          placingIdInProgress: scanTypeInfo.placingId,
        });
      }

      if (scanTypeInfo.scanType === "single") {
        const isScannedSkuInCounterData = checkScannedSkuInCounterData({
          counterData: skuCounting,
          scanResult,
        });
        if (!isScannedSkuInCounterData) {
          setValidationError({
            title: "상품 바코드가 일치하는 상품이 없습니다.",
          });
          return;
        }

        const isGroupedItemSelected = Boolean(
          scanTypeInfo.groupedItemIdInProgress
        );

        /**
         * - 선택된 subRow가 있는 경우
         *   - 작업자의 작업 의도가 명확하기 때문에 해당 아이템을 기준으로 기존 로직이 동작
         * - 선택된 subRow가 없는 경우
         *   - 작업자의 의도를 알 수 없기 때문에 중복 바코드 선택 모달을 띄운다.
         */
        if (!isGroupedItemSelected) {
          const incompleteSingleLocationSkuListBySkuBarcode =
            getIncompleteSingleLocationSkuListBySkuBarcode({
              counterData: skuCounting,
              scanResult,
            });

          /**
           * 새로운 검수 작업을 시작하는데, 검수완료되지 않은 중복된 바코드가 있는 경우
           * 작업자가 작업할 아이템을 선택하도록 한다.
           */
          if (
            !skuCounting.skuInProgress &&
            incompleteSingleLocationSkuListBySkuBarcode.length > 1
          ) {
            // 아이템을 선택하는 경우 skuInProgress, groupedItemIdInProgress에 등록해서 이후 스캔부터는 해당 아이템이 스캔되도록 한다.
            handleSelectionSkuModalOpen(
              incompleteSingleLocationSkuListBySkuBarcode
            );
            return;
          }

          /**
           * 검수완료되지 않은 중복된 바코드가 2개 이상 있는 경우
           * 선택 모달에서 등록한 skuInProgress를 기준으로 counterKey를 찾는다.
           */
          if (incompleteSingleLocationSkuListBySkuBarcode.length > 1) {
            scannedCounterKey =
              getCounterKeyFromScanResultByPlacingIdInProgress({
                counterData: skuCounting,
                scanResult,
                placingIdInProgress: skuCounting.skuInProgress?.placingId,
              });
            /**
             * 일반적인 미완료된(검수완료되지 않은 중복 상품 바코드가 1개 남은) 경우
             */
          } else if (incompleteSingleLocationSkuListBySkuBarcode.length === 1) {
            scannedCounterKey =
              getIncompleteSingleLocationCounterKeyFromScannedSkuBarcode({
                counterData: skuCounting,
                scanResult,
              });
          }

          // subRow인 아이템인데 선택이 해제된 상태이므로 다시 선택하도록 유도한다.
          const isGroupedItem = checkIsGroupedItem(
            scanTypeInfo.itemList,
            skuCounting.skuInProgress?.skuId
          );
          if (isGroupedItem) {
            setValidationError({
              title: (
                <>
                  현재 작업 중인 상품에 하위 항목이 존재합니다. 스캔할 하위 항목
                  상품을 선택한 후 '상품스캔'을 진행해주세요.
                </>
              ),
            });
            return;
          }
        }

        // scannedCounterKey가 존재하는 경우 중복 바코드 관련 로직으로 스캔할 아이템(counterKey)가 결정된 상태이다.
        const hasTargetItemByDuplicateSkuList = Boolean(scannedCounterKey);

        scannedCounterKey = hasTargetItemByDuplicateSkuList
          ? scannedCounterKey
          : getSingleLocationCounterKeyFromScanResult({
              counterData: skuCounting,
              scanResult,
              itemIdInprogress: scanTypeInfo.groupedItemIdInProgress,
            });

        const target = scannedCounterKey
          ? skuCounting.counter.counterInfo[scannedCounterKey]
          : undefined;

        const isGroupedItem = checkIsGroupedItem(
          scanTypeInfo.itemList,
          target?.skuId
        );
        const isInProgress = !!skuCounting.skuInProgress;
        if (!isGroupedItemSelected && isGroupedItem && !isInProgress) {
          setValidationError({
            title: (
              <>
                현재 작업 중인 상품에 하위 항목이 존재합니다. 스캔할 하위 항목
                상품을 선택한 후 '상품스캔'을 진행해주세요.
              </>
            ),
          });
          return;
        }
      }

      if (!scannedCounterKey) {
        setValidationError({
          title: "상품 바코드가 일치하는 상품이 없습니다.",
        });
        return;
      }

      const target = skuCounting.counter.counterInfo[scannedCounterKey];

      if (target.isCompletePlacing) {
        setValidationError({
          title: `이미 완료한 SKU ID 입니다.`,
        });
        return;
      }

      const otherSkuIsInProgress =
        !!skuCounting.skuInProgress &&
        target.counterKey !== skuCounting.skuInProgress.counterKey;
      if (otherSkuIsInProgress) {
        const managementDateLabel = getManagementDateLabel(
          skuCounting.skuInProgress?.managementKind,
          skuCounting.skuInProgress?.managementDate
        );

        const title =
          scanTypeInfo.scanType === "single" ? (
            <>
              현재 작업중인 입고(SKU ID:{" "}
              {getFormattedSingleSkuId(skuCounting.skuInProgress?.skuId)} /
              상태:{" "}
              <ItemStatus
                statusLabel={skuCounting.skuInProgress?.statusLabel}
                isUnverifiedSku={skuCounting.skuInProgress?.isUnverifiedSku}
              />
              {managementDateLabel && ` / ${managementDateLabel}`})을 완료한
              후에 다른 입고를 진행할 수 있습니다.
            </>
          ) : (
            `현재 작업중인 분할 입고를 완료한 후에 다른 분할 입고를 진행할 수 있습니다.`
          );

        setValidationError({
          title,
        });
        return;
      }

      const isInitialScan = !target.placerId && !target.current;
      if (isInitialScan) {
        // 1) 작업자 지정 api 호출
        assignPlacerToItem(
          {
            pathParams: { receivingId, itemId: target.itemId },
            placingId: target.placingId,
          },
          {
            onSuccess: () => {
              // 2) local count ++
              // 위에서 존재여부 이미 검사함
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              skuCounting.counter.addCountById(scannedCounterKey!);
              skuCounting.setSkuInProgress(target);

              setRowInfoToHighlight({
                rowKey:
                  scanTypeInfo.scanType === "single"
                    ? target.itemId
                    : target.placingId,
              });

              // 3) 변경된 상세 데이터 불러오기
              queryClient.invalidateQueries(
                RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
                  receivingId,
                })
              );
            },
          }
        );
      } else {
        const isAssignedWorker = target.placerId === currentManager.id;
        if (!isAssignedWorker) {
          setValidationError({
            title: "이미 타 담당자가 해당 상품을 입고중입니다.",
          });
          return;
        }

        skuCounting.counter.addCountById(scannedCounterKey);
        if (target.counterKey !== skuCounting.skuInProgress?.counterKey) {
          skuCounting.setSkuInProgress(target);
        }
        setRowInfoToHighlight({
          rowKey:
            scanTypeInfo.scanType === "single"
              ? target.itemId
              : target.placingId,
        });
      }

      changeScanModeToLocationOnFullCount({
        setScanMode,
        count: (target.current ?? 0) + 1,
        max: target.max,
      });
    },
    [
      active,
      receivingId,
      currentManager,
      scanTypeInfo,
      skuCounting,
      setScanMode,
      setValidationError,
      handleSelectionSkuModalOpen,
      assignPlacerToItem,
      setRowInfoToHighlight,
      queryClient,
    ]
  );

  useScan(handleScanResult);

  const ResultHandlerOfScanSKU = useMemo(() => {
    return (
      <>
        {ValidationErrorModal}

        {ResponseHandlerOfAssigningPlacerToItem}

        {SelectSkuModal}
      </>
    );
  }, [
    ResponseHandlerOfAssigningPlacerToItem,
    SelectSkuModal,
    ValidationErrorModal,
  ]);

  return {
    setScanSKUActive,
    showSelectionErrorForScanSKU,
    ResultHandlerOfScanSKU,
  };
}
