import { useCallback, useMemo, useState } from "react";

import SKU_QUERY from "@sellernote/_shared/src/queries/fulfillment/SKU_QUERY";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";

import Layout from "containers/Layout";
import { createNewManagementDateForm } from "./utils";

import ItemContainer from "./ItemContainer";
import Submit from "./Submit";
import Styled from "./index.styles";

/**
 * 관리일자 입력 form 항목
 * - Record<관리일자, 수량>
 */
type ManagementDateForm = {
  /**
   * UI에서 unique key로 사용하기 위해 FE에서 만들어 넣는 id
   * <id를 FE에서 만드는 이유>
   * - managementDate값을 기준으로 unique가 되는 것이 맞지만, 새 입력 form으로 추가될때는 managementDate가 undefined로 상태이므로 key로 사용할 수 없다.
   * - 추가/삭제가 될 수 있는 요소라 index를 key로 사용할 수도 없다.
   */
  id: string;
  managementDate: string | undefined;
  quantity: number | undefined;
};

/**
 * itemId별로 ManagementDateForm 정보를 묶은 Map
 */
type MapForManagementDateFormList = Record<
  number,
  { formList: ManagementDateForm[]; goalCount: number }
>;

export type { ManagementDateForm, MapForManagementDateFormList };

export default function SelectManagementDate({
  returningId,
  formattedReturningId,
  itemList,
}: {
  returningId: number;
  formattedReturningId: string | number | undefined;
  itemList: ReceivingItem[] | undefined;
}) {
  const [mapForManagementDateFormList, setMapForManagementDateFormList] =
    useState<MapForManagementDateFormList>(
      itemList?.reduce((a: MapForManagementDateFormList, c) => {
        const goalCount = c.actualQty;

        /**
         * item id별로 초기화
         * value로 새 form을 추가해준다.
         */
        a[c.id] = {
          goalCount,
          formList: [createNewManagementDateForm()],
        };

        return a;
      }, {}) || {}
    );

  const skuList = useMemo(() => {
    return Array.from(
      itemList?.reduce((a, c) => {
        a.add(c.skuId);

        return a;
      }, new Set<number>()) || []
    );
  }, [itemList]);

  const {
    data: existedManagementDateListOfSku,
    ResponseHandler: ResponseHandlerOfGetManagementDateListOfSku,
  } = SKU_QUERY.useGetManagementDateList({
    enabled: !!skuList?.length,
    params: { skuIds: skuList },
  });

  /**
   * skuId 별로 묶은 existedManagementDateList
   */
  const mapForExistedManagementDateListBySku = useMemo(() => {
    return existedManagementDateListOfSku?.reduce(
      (a: Record<number, string[]>, c) => {
        a[c.skuId] = [...(a[c.skuId] || []), ...c.managementDates];

        return a;
      },
      {}
    );
  }, [existedManagementDateListOfSku]);

  /**
   * 관리일자 Form 추가
   */
  const addNewManagementDateForm = useCallback(
    ({ returningItemId }: { returningItemId: number }) => {
      setMapForManagementDateFormList((prev) => {
        return {
          ...prev,
          [returningItemId]: {
            ...prev[returningItemId],
            formList: [
              ...prev[returningItemId].formList,
              createNewManagementDateForm(),
            ],
          },
        };
      });
    },
    []
  );

  /**
   * 관리일자 Form 삭제
   */
  const removeManagementDateForm = useCallback(
    ({
      returningItemId,
      managementDateFormId,
    }: {
      returningItemId: number;
      /** 삭제대상 id */
      managementDateFormId: string;
    }) => {
      setMapForManagementDateFormList((prev) => {
        return {
          ...prev,
          [returningItemId]: {
            ...prev[returningItemId],
            formList: prev[returningItemId].formList.filter(
              (v) => v.id !== managementDateFormId
            ),
          },
        };
      });
    },
    []
  );

  /**
   * 관리일자 Form 업데이트
   */
  const updateManagementDateForm = useCallback(
    ({
      returningItemId,
      managementDateFormIndex,
      newManagementDateForm,
    }: {
      returningItemId: number;
      /** 업데이트대상 Index */
      managementDateFormIndex: number;
      newManagementDateForm: ManagementDateForm;
    }) => {
      setMapForManagementDateFormList((prev) => {
        const newForList = [...prev[returningItemId].formList];
        newForList[managementDateFormIndex] = newManagementDateForm;

        return {
          ...prev,
          [returningItemId]: {
            ...prev[returningItemId],
            formList: newForList,
          },
        };
      });
    },
    []
  );

  if (!itemList) return null;

  return (
    <Layout
      navigator={{
        title: `${formattedReturningId} / 불일치 상품 별 관리일자 선택`,
      }}
    >
      <Styled.container>
        <div className="header">
          해당 의뢰건에 관리일자가 설정된 불일치 상품이 있습니다. <br />
          해당 불일치 상품의 관리일자 DATE를 선택해주세요.
        </div>

        <div className="list">
          {itemList.map((v) => {
            return (
              <ItemContainer
                key={v.id}
                returningItem={v}
                existedManagementDateList={
                  mapForExistedManagementDateListBySku?.[v.skuId]
                }
                managementDateFormList={
                  mapForManagementDateFormList[v.id].formList
                }
                addNewManagementDateForm={addNewManagementDateForm}
                removeManagementDateForm={removeManagementDateForm}
                updateManagementDateForm={updateManagementDateForm}
              />
            );
          })}
        </div>

        <Submit
          returningId={returningId}
          mapForManagementDateFormList={mapForManagementDateFormList}
        />

        {ResponseHandlerOfGetManagementDateListOfSku}
      </Styled.container>
    </Layout>
  );
}
