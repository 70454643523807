import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function useResetInputtingCounterInfo() {
  const history = useHistory();

  const location = useLocation();

  return useCallback(() => {
    history.replace(location.pathname);
  }, [history, location.pathname]);
}
