import styled, { css } from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.div<{ isBodySeparated?: boolean }>`
  padding-bottom: 9px;
  border-bottom: 1px solid ${COLOR.primaryBlue_40};

  > .title {
    padding-left: 8px;
    color: ${COLOR.primaryBlue};
    ${setFontStyle("SubHead2", "Bold")};
  }

  > .desc {
    color: ${COLOR.grayScale_500};
    ${setFontStyle("SubHead3", "Bold")};

    ${(props) => {
      if (props.isBodySeparated) {
        return css`
          margin-top: 4px;
        `;
      } else {
        return css`
          margin-left: 4px;
        `;
      }
    }}
  }
`;

export default {
  container,
};
