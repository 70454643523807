import {
  TableColumnGroupInfo,
  TableColumnInfo,
  TableColumnInfoValue,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";

import Styled from "./index.styles";

export default function TableHead<T extends unknown>({
  columnInfo,
  columnGroupInfo,
  tableLeftEndRef,
  tableRightEndRef,
  isOverflowed,
}: {
  columnInfo: TableColumnInfo<T>;
  columnGroupInfo?: TableColumnGroupInfo[];
  tableLeftEndRef: React.MutableRefObject<HTMLTableCellElement>;
  tableRightEndRef: React.MutableRefObject<HTMLTableCellElement>;
  isOverflowed: boolean;
}) {
  return (
    <thead>
      {columnGroupInfo && (
        <Styled.TableTr>
          {columnGroupInfo.map((col, i) => (
            <Styled.TableThGroup
              key={`col${i}`}
              scope="colgroup"
              portion={(col as TableColumnInfoValue).portion}
              minWidth={(col as TableColumnInfoValue).minWidth}
              maxWidth={(col as TableColumnInfoValue).maxWidth}
              fixedWidth={col.fixedWidth}
              isOverflowed={isOverflowed}
              colSpan={col.colSpan}
            >
              {(col as TableColumnInfoValue).label}
            </Styled.TableThGroup>
          ))}
        </Styled.TableTr>
      )}
      <Styled.TableTr>
        <th ref={tableLeftEndRef} style={{ visibility: "hidden" }} />
        {Object.values(columnInfo).map((col, i) => (
          <Styled.TableTh
            key={`col${i}`}
            scope="col"
            portion={(col as TableColumnInfoValue).portion}
            minWidth={(col as TableColumnInfoValue).minWidth}
            maxWidth={(col as TableColumnInfoValue).maxWidth}
            fixedWidth={(col as TableColumnInfoValue).fixedWidth}
            isOverflowed={isOverflowed}
          >
            {(col as TableColumnInfoValue).label}
          </Styled.TableTh>
        ))}
        <th ref={tableRightEndRef} style={{ visibility: "hidden" }} />
      </Styled.TableTr>
    </thead>
  );
}
