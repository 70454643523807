import StepItem from "./StepItem";
import Styled from "./index.styles";

export default function ProcessStep({
  numberStepList,
  activeStepNumber,
  className,
}: {
  numberStepList?: number;
  activeStepNumber: number;
  className?: string;
}) {
  return (
    <Styled.container
      className={`${className ? className : ""} modal-process-step`}
    >
      <Styled.stepList>
        {Array.from({ length: numberStepList ?? 0 }, (_, index) => (
          <StepItem
            isDone={activeStepNumber > index + 1}
            key={`step-item-${index}`}
            number={index + 1}
            isActive={activeStepNumber === index + 1}
          />
        ))}
      </Styled.stepList>
    </Styled.container>
  );
}
