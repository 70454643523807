import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { ScanType } from "@sellernote/_shared/src/types/fulfillment/scan";

import useScanPickingInvoice from "hooks/shipping/useScanPickingInvoice";

export default function ScanInvoiceButton({
  selectedScanButtonType,
  handleScanButtonClick,
}: {
  selectedScanButtonType: ScanType | undefined;
  handleScanButtonClick: (type: ScanType | undefined) => void;
}) {
  const {
    isActiveInvoiceScanButton,
    ScanInvoiceMessageModal,
    ResponseHandlerOfScanningPickingInvoice,
  } = useScanPickingInvoice({
    selectedScanButtonType,
    handleScanButtonClick,
  });

  return (
    <>
      <Button
        className="scan-button"
        theme={selectedScanButtonType === "shipping" ? "scan" : "secondary"}
        size="small"
        label="송장(QR)스캔"
        handleClick={() => handleScanButtonClick("shipping")}
        disabled={!isActiveInvoiceScanButton}
      />

      {ScanInvoiceMessageModal}

      {ResponseHandlerOfScanningPickingInvoice}
    </>
  );
}
