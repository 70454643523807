import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useAtom } from "jotai";
import { RECEIVING_INSPECTION_ATOMS } from "jotaiStates/receiving/inspection";
import INSPECTION_QUERY from "queries/receiving/INSPECTION_QUERY";
import { useSetRecoilState } from "recoil";

import { RECEIVING_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { UnverifiedItemInfo } from "@sellernote/_shared/src/types/fulfillment/inspection";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";

import { useResetAtom } from "jotai/utils";

export default function useUnverifiedItem({
  receivingId,
  registeredUnverifiedList,
  onSuccessForCreateUnverifiedItem,
  onSuccessForCreateUnverifiedList,
  onSuccessForRemoveUnverifiedItem,
}: {
  receivingId: number;
  registeredUnverifiedList?: ReceivingItem[];
  onSuccessForCreateUnverifiedItem?: () => void;
  onSuccessForCreateUnverifiedList?: () => void;
  onSuccessForRemoveUnverifiedItem?: () => void;
}) {
  const history = useHistory();

  const queryClient = useQueryClient();

  const [unregisteredTempUnverifiedList, setUnregisteredTempUnverifiedList] =
    useAtom(RECEIVING_INSPECTION_ATOMS.SCANNED_UNREGISTERED_UNVERIFIED_LIST);

  const resetUnregisteredTempUnverifiedList = useResetAtom(
    RECEIVING_INSPECTION_ATOMS.SCANNED_UNREGISTERED_UNVERIFIED_LIST
  );

  const setCanNotLeavePage = useSetRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );

  const {
    mutate: updateUnverifiedItem,
    ResponseHandler: ResponseHandlerOfUpdateUnverifiedItem,
  } = INSPECTION_QUERY.useUpdateUnverifiedItemForReceiving({
    receivingId,
    onSuccess: () => {
      queryClient.invalidateQueries(
        RECEIVING_QUERY_KEY_GEN.getPDAReceivingDetail({
          id: receivingId,
        })
      );

      onSuccessForCreateUnverifiedItem?.();
    },
  });

  const {
    mutate: createUnverifiedList,
    ResponseHandler: ResponseHandlerOfCreateUnverifiedList,
  } = INSPECTION_QUERY.useCreateUnverifiedListForReceiving({
    receivingId,
    onSuccess: () => {
      queryClient.invalidateQueries(
        RECEIVING_QUERY_KEY_GEN.getPDAReceivingDetail({
          id: receivingId,
        })
      );

      onSuccessForCreateUnverifiedList?.();
    },
  });

  const {
    mutate: removeUnverifiedList,
    ResponseHandler: ResponseHandlerOfRemoveUnverifiedList,
  } = INSPECTION_QUERY.useRemoveUnverifiedList({
    receivingId,
    onSuccess: () => {
      queryClient.invalidateQueries(
        RECEIVING_QUERY_KEY_GEN.getPDAReceivingDetail({
          id: receivingId,
        })
      );

      onSuccessForRemoveUnverifiedItem?.();
    },
  });

  // 스캔 시, 불일치 상품의 barcode 를 temp 리스트에 추가. (입력버튼을 누르면 한번에 등록)
  const addTempUnverifiedItemToList = (newBarcode: string) => {
    const hasBarcode = unregisteredTempUnverifiedList.some(
      ({ barCode }) => barCode === newBarcode
    );

    // barcode 가 이미 리스트에 있으면 quantity 1 증가, 없으면 새로 추가.
    setUnregisteredTempUnverifiedList((prev) => {
      return hasBarcode
        ? prev.map((item) =>
            item.barCode === newBarcode
              ? { ...item, quantity: (item.quantity ?? 0) + 1 }
              : item
          )
        : [...prev, { barCode: newBarcode, quantity: 1 }];
    });
  };

  // 불일치 상품 단일 추가
  const handleUnverifiedItemUpdate = (
    unverifiedItemFormInfo: UnverifiedItemInfo
  ) => {
    updateUnverifiedItem(unverifiedItemFormInfo);
  };

  // 불일치 상품 리스트로 한번에 등록
  const handleUnverifiedListCreate = () => {
    // 불일치 리스트 페이지에서는 뒤로가기 확인없이 가능하도록 처리.
    setCanNotLeavePage(false);

    if (!unregisteredTempUnverifiedList.length)
      return history.push(`${history.location.pathname}/unverified-item`);

    createUnverifiedList(unregisteredTempUnverifiedList);
  };

  const handleUnverifiedListRemove = (itemIds: number[]) => {
    removeUnverifiedList({ itemIds });
  };

  const addScannedUnverifiedItem = (barcode: string) => {
    const registeredItem = registeredUnverifiedList?.find(
      ({ skuBarCode }) => skuBarCode === barcode
    );

    const newOrder = registeredUnverifiedList?.length
      ? registeredUnverifiedList.length + 1
      : 1;

    // 등록한 적 없는 상품이면 새로 등록.
    if (!registeredItem) {
      updateUnverifiedItem({
        tempId: Number(`` + receivingId + newOrder),
        barCode: barcode,
        quantity: 1,
      });

      return;
    }

    // 이미 등록한 상품이면 수량 증가. (수정 API 호출)
    updateUnverifiedItem({
      itemId: registeredItem.id,
      quantity: (registeredItem.actualQty ?? 0) + 1,
    });
  };

  const addScannedUnverifiedItemToInspection = (barcode: string) => {
    const registeredItem = registeredUnverifiedList?.find(
      ({ skuBarCode }) => skuBarCode === barcode
    );

    // 등록한 적 없는 상품이면 등록할 temp 리스트에 추가.
    if (!registeredItem) {
      addTempUnverifiedItemToList(barcode);
      return;
    }

    // 이미 등록한 상품이면 수량 증가. (수정 API 호출)
    updateUnverifiedItem({
      itemId: registeredItem.id,
      quantity: (registeredItem.actualQty ?? 0) + 1,
    });
  };

  return {
    unregisteredTempUnverifiedList,
    resetUnregisteredTempUnverifiedList,
    addScannedUnverifiedItem,
    addScannedUnverifiedItemToInspection,
    handleUnverifiedItemUpdate,
    ResponseHandlerOfUpdateUnverifiedItem,
    handleUnverifiedListCreate,
    ResponseHandlerOfCreateUnverifiedList,
    handleUnverifiedListRemove,
    ResponseHandlerOfRemoveUnverifiedList,
  };
}
