import { createSlice } from "@reduxjs/toolkit";
import { takeLatest } from "redux-saga/effects";

import { GET_DETAIL_RES_DATA } from "@sellernote/_shared/src/api-interfaces/boful-api/returning";
import { createReducerWithSagaBundleForBoful } from "@sellernote/_shared/src/services/request";
import { ApiResponseState } from "@sellernote/_shared/src/types/common/common";

import { loadingActions } from "modules/loading";

export interface ReturningState {
  GET_DETAIL?: ApiResponseState<GET_DETAIL_RES_DATA>;

  GET_DETAIL_TO_CHECK_WAREHOUSING_COUNT?: ApiResponseState<GET_DETAIL_RES_DATA>;
}

const initialState: ReturningState = {};

const SLICE_NAME = "returning";

const GET_DETAIL = createReducerWithSagaBundleForBoful<
  "GET_DETAIL",
  { id: number },
  GET_DETAIL_RES_DATA,
  ReturningState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "GET_DETAIL",
  getRequestOptions: (payload) => {
    return {
      method: "get",
      path: `/returning/list/pda/${payload.id}`,
    };
  },
  loadingActions,
});

/**
 * returning의 workerList정보를 조회. GET_DETAIL를 이용하면 불필요한 렌더링이 발생하여 별도로 만듦.
 * 최신의 분할입고 수량을 확인할때 상요
 */
const GET_DETAIL_TO_CHECK_WAREHOUSING_COUNT =
  createReducerWithSagaBundleForBoful<
    "GET_DETAIL_TO_CHECK_WAREHOUSING_COUNT",
    { id: number },
    GET_DETAIL_RES_DATA,
    ReturningState
  >({
    sliceName: SLICE_NAME,
    actionTypeKey: "GET_DETAIL_TO_CHECK_WAREHOUSING_COUNT",
    getRequestOptions: (payload) => {
      return {
        method: "get",
        path: `/returning/list/pda/${payload.id}`,
      };
    },
    loadingActions,
  });

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    ...GET_DETAIL.reducerBundle,

    ...GET_DETAIL_TO_CHECK_WAREHOUSING_COUNT.reducerBundle,
  },
});

function* saga() {
  yield takeLatest(slice.actions.GET_DETAIL, GET_DETAIL.saga);

  yield takeLatest(
    slice.actions.GET_DETAIL_TO_CHECK_WAREHOUSING_COUNT,
    GET_DETAIL_TO_CHECK_WAREHOUSING_COUNT.saga
  );
}

export default slice;

export { saga };
