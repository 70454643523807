import { INVENTORY_MANAGEMENT_KIND_MAP } from "@sellernote/_shared/src/constants/fulfillment/inventory";
import { ManagementKind } from "@sellernote/_shared/src/types/fulfillment/inventory";
import Calendar from "@sellernote/_sds-v2/src/components/calendar/Calendar";

import { ManagementDateForm } from "..";

export default function ManagementDateInput({
  managementKind,
  baseDate,
  formState,
  handelFormUpdate,
  selectedManagementDateList,
}: {
  managementKind: ManagementKind | undefined;
  baseDate: string;
  formState: ManagementDateForm;
  handelFormUpdate: (newManagementDateForm: ManagementDateForm) => void;
  selectedManagementDateList: string[];
}) {
  return (
    <Calendar
      size="default"
      type="datePicker"
      inputWidth={"200px"}
      labelInfo={{
        label: managementKind
          ? INVENTORY_MANAGEMENT_KIND_MAP[managementKind]
          : "",
      }}
      date={formState.managementDate || ""}
      setDate={(v) => {
        handelFormUpdate({
          ...formState,
          managementDate: v,
        });
      }}
      getOpenToDate={() => baseDate}
      disabledDateList={selectedManagementDateList}
      fixedHeight
    />
  );
}
