import { useCallback } from "react";
import { useRecoilValue } from "recoil";

import CANCELING_QUERY from "@sellernote/_shared/src/queries/fulfillment/CANCELING_QUERY";
import { FULFILLMENT_CANCELING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/canceling";
import { SKUInfoForCancelingRestocking } from "@sellernote/_shared/src/types/fulfillment/canceling";

/**
 * 취소 재입고 완료
 */
export default function useCompleteRestocking({
  SKUInfoForScanning,
  moveToCancelingListPage,
}: {
  SKUInfoForScanning: SKUInfoForCancelingRestocking[];
  moveToCancelingListPage: () => void;
}) {
  const { cancelingId } = useRecoilValue(
    FULFILLMENT_CANCELING_ATOMS.CANCELING_DETAIL
  );

  const {
    mutate: restockCancelingInvoice,
    ResponseHandler: ResponseHandlerOfRestockingCancelingInvoice,
  } = CANCELING_QUERY.useRestockCancelingInvoice({
    type: "complete",
    cancelingId,
    moveToCancelingListPage,
  });

  const handleRestockingComplete = useCallback(() => {
    restockCancelingInvoice({
      restockItems: SKUInfoForScanning.map((v) => ({
        itemId: v.id,
        restockQty: v.currentQty,
      })),
    });
  }, [SKUInfoForScanning, restockCancelingInvoice]);

  return {
    handleRestockingComplete,
    ResponseHandlerOfRestockingCancelingInvoice,
  };
}
