import SubdirectoryIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubdirectoryIcon";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import Styled from "./index.styles";

export default function SubRowIndicator({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Styled.subRowIndicator>
      <SubdirectoryIcon width={16} height={16} color={COLOR.grayScale_700} />

      <span>{children}</span>
    </Styled.subRowIndicator>
  );
}
