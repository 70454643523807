import { useCallback, useState } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import SCAN_QUERY from "@sellernote/_shared/src/queries/fulfillment/SCAN_QUERY";
import { ScanType } from "@sellernote/_shared/src/types/fulfillment/scan";
import { ShippingListItem } from "@sellernote/_shared/src/types/fulfillment/shipping";

import useScan from "hooks/common/useScan";

/**
 * 송장 스캔 후 _postSuccessCallback 실행
 */
export default function useScanCancelingInvoice(
  _postSuccessCallback: (invoiceData: ShippingListItem) => void
) {
  const [selectedScanButtonType, setSelectedScanButtonType] = useState<
    ScanType | undefined
  >("shipping");

  const {
    mutate: interpretBarcode,
    ResponseHandler: ResponseHandlerOfScanningInvoice,
  } = SCAN_QUERY.useInterpretBarcode<"shipping">();

  const scanInvoice = useCallback(
    (result: string) => {
      if (selectedScanButtonType !== "shipping") {
        return;
      }

      interpretBarcode(
        { type: selectedScanButtonType, barCode: result },
        { onSuccess: ({ data }) => _postSuccessCallback(data) }
      );
    },
    [_postSuccessCallback, interpretBarcode, selectedScanButtonType]
  );
  useScan(scanInvoice);

  const handleScanButtonClick = useCallback(
    (type: ScanType | undefined) =>
      selectedScanButtonType
        ? setSelectedScanButtonType(undefined)
        : setSelectedScanButtonType(type),
    [selectedScanButtonType]
  );

  const ScanInvoiceButton = (
    <Button
      theme={selectedScanButtonType === "shipping" ? "scan" : "tertiary"}
      size="small"
      label="송장(QR)스캔"
      handleClick={() => handleScanButtonClick("shipping")}
    />
  );

  return { ScanInvoiceButton, ResponseHandlerOfScanningInvoice };
}
