import { memo, useCallback, useState } from "react";

import { INVENTORY_MANAGEMENT_KIND_MAP } from "@sellernote/_shared/src/constants/fulfillment/inventory";
import { ManagementKind } from "@sellernote/_shared/src/types/fulfillment/inventory";
import { SKU } from "@sellernote/_shared/src/types/fulfillment/sku";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

import SkuDetailModal from "../../SkuDetailModal";
import Styled from "./index.styles";

function SkuDetail({
  skuDetail,
  managementKind,
  managementDate,
}: {
  skuDetail: SKU;
  managementKind: ManagementKind | undefined;
  managementDate: string | undefined;
}) {
  const [isVisibleSkuDetailModal, setIsVisibleSkuDetailModal] = useState(false);

  const handleSkuDetailModalOpen = useCallback(() => {
    setIsVisibleSkuDetailModal(true);
  }, []);

  const handleSkuDetailModalClose = useCallback(() => {
    setIsVisibleSkuDetailModal(false);
  }, []);

  const hasManagementInfo = managementKind && managementDate;

  return (
    <>
      <Styled.skuDetail>
        {hasManagementInfo && (
          <ListItem
            type="withContentAlignRight"
            label={INVENTORY_MANAGEMENT_KIND_MAP[managementKind]}
            value={toFormattedDate(managementDate, "YYYY-MM-DD")}
          />
        )}

        <ListItem
          label="상품명"
          type="withButton"
          value={skuDetail.itemName}
          button={{
            label: "보기",
            handleClick: handleSkuDetailModalOpen,
          }}
        />
      </Styled.skuDetail>

      <SkuDetailModal
        active={isVisibleSkuDetailModal}
        skuDetail={skuDetail}
        managementKind={managementKind}
        managementDate={managementDate}
        onClose={handleSkuDetailModalClose}
      />
    </>
  );
}

export default memo(SkuDetail);
