import { atom } from "recoil";

const LOGGED_IN = atom<undefined | boolean>({
  key: "fulfillment/auth/atoms/LOGGED_IN",
  default: undefined,
});

export default {
  LOGGED_IN,
};
