import styled from "styled-components";

const countQuantityModalBody = styled.div`
  > .input-radio {
    justify-content: center;
    margin-bottom: 24px;
  }
`;

export default {
  countQuantityModalBody,
};
