import SvgIconWrapper from "./SvgIconWrapper";

export default function CheckSolidIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_917_7428)">
          <path
            d="M35.0891 9.41094C35.968 10.2898 35.968 11.7102 35.0891 12.5891L17.0891 30.5891C16.2102 31.468 14.7898 31.468 13.9109 30.5891L4.90897 21.5891C4.03034 20.7102 4.03034 19.2898 4.90897 18.4109C5.78773 17.532 7.21227 17.532 8.09117 18.4109L15.4367 25.8148L31.9109 9.41094C32.7898 8.53062 34.2102 8.53062 35.0891 9.41094Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_917_7428">
            <rect
              width="31.5"
              height="36"
              fill="white"
              transform="translate(4.25 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}
