import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const searchAndScan = styled.div`
  display: flex;
  gap: 40px;

  > .input-text-with-search-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;

    > .input-text-with-search-error-message {
      ${setFontStyle("Caption")};
      color: ${COLOR.pointWarning};
    }
  }

  > .scan-button-wrapper {
    padding-block-start: 8px;
  }
`;

const dataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  > .title {
    ${setFontStyle("SubHead3", "Bold")};
    color: ${COLOR.grayScale_700};
  }
`;

const litItemRow = styled.div`
  display: flex;
  gap: 24px;

  // 2개의 리스트 아이템을 한 줄에 나열
  .custom-list-item {
    width: calc(50% - 12px);
  }
`;

export default {
  container,
  searchAndScan,
  dataContainer,
  litItemRow,
};
