import { useCallback, useState } from "react";

import SKU_QUERY from "@sellernote/_shared/src/queries/fulfillment/SKU_QUERY";
import { ManagementKind } from "@sellernote/_shared/src/types/fulfillment/inventory";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { WithButtonProps } from "@sellernote/_sds-v1/src/components/ListItem";

import { SkuToSelect } from "../../SelectSkuModal/useSelectSkuModal";

export default function useSkuDetail({
  skuId,
  skuBarcode,
  managementKind,
  managementDate,
  availableQty,
  isEmptyLocation,
  selectedSku,
}: {
  skuId: number | undefined;
  skuBarcode: string | undefined;
  managementKind: ManagementKind | undefined;
  managementDate: string | undefined;
  availableQty: number | undefined;
  isEmptyLocation: boolean;
  selectedSku: SkuToSelect | undefined;
}) {
  const [isVisibleSkuDetailModal, setIsVisibleSkuDetailModal] = useState(false);
  const [isVisibleSelectSkuModal, setIsVisibleSelectSkuModal] = useState(false);

  const skuIdToAdjust = isEmptyLocation ? selectedSku?.id : skuId;
  const skuBarcodeToAdjust = isEmptyLocation
    ? selectedSku?.barCode
    : skuBarcode;
  const skuManagementKindToAdjust = isEmptyLocation
    ? selectedSku?.managementKind
    : managementKind;
  const skuManagementDateToAdjust = isEmptyLocation
    ? selectedSku?.managementDate
    : managementDate;

  const {
    data: skuDetail,
    ResponseHandler: ResponseHandlerOfGettingSkuDetail,
  } = SKU_QUERY.useGetSingleSkuDetail({
    skuId: skuIdToAdjust,
    enabled: !!skuIdToAdjust && isVisibleSkuDetailModal,
  });

  const handleSkuDetailModalOpen = useCallback(() => {
    setIsVisibleSkuDetailModal(true);
  }, []);

  const handleSkuDetailModalClose = useCallback(() => {
    setIsVisibleSkuDetailModal(false);
  }, []);

  const handleSelectSkuModalOpen = useCallback(() => {
    setIsVisibleSelectSkuModal(true);
  }, []);

  const handleSelectSkuModalClose = useCallback(() => {
    setIsVisibleSelectSkuModal(false);
  }, []);

  const ButtonForSku: WithButtonProps["button"] =
    isEmptyLocation && !selectedSku
      ? {
          label: "입력",
          handleClick: handleSelectSkuModalOpen,
        }
      : {
          label: "보기",
          handleClick: handleSkuDetailModalOpen,
        };

  const getAdjustableQty = useCallback(() => {
    if (!isEmptyLocation) {
      return toThousandUnitFormat(availableQty);
    }

    if (!selectedSku) {
      return "-";
    }

    return 0;
  }, [availableQty, selectedSku, isEmptyLocation]);

  const isVisibleReSelectButton = isEmptyLocation && !!skuIdToAdjust;

  const hasManagementInfo = Boolean(
    skuManagementKindToAdjust && skuManagementDateToAdjust
  );

  return {
    skuIdToAdjust,
    skuBarcodeToAdjust,
    skuManagementKindToAdjust,
    skuManagementDateToAdjust,
    ButtonForSku,

    getAdjustableQty,

    isVisibleReSelectButton,

    isVisibleSkuDetailModal,
    handleSkuDetailModalClose,

    isVisibleSelectSkuModal,
    handleSelectSkuModalOpen,
    handleSelectSkuModalClose,

    skuDetail,

    ResponseHandlerOfGettingSkuDetail,

    hasManagementInfo,
  };
}
