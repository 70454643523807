import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const count = styled.div`
  > div:first-child {
    margin-bottom: 4px;
  }
`;

const directInputModalBody = styled.div`
  > strong.input-available-quantity {
    ${setFontStyle("Head6", "Bold")};
    color: ${COLOR.primaryBlue};
    margin-bottom: 8px;
  }

  > .input-text {
    margin-bottom: 32px;
  }

  > .button {
    margin-bottom: 24px;
  }
`;

export default {
  count,
  directInputModalBody,
};
