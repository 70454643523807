import { useCallback, useEffect, useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import {
  getManagementDateLabel,
  getWarehousingItemStatusLabel,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { checkForUnverifiedItem } from "@sellernote/_shared/src/utils/fulfillment/inspection";
import { checkConfirmedAsSingleLocationWarehousing } from "@sellernote/_shared/src/utils/fulfillment/receiving";
import { checkHasOnlyInitialWarehousing } from "@sellernote/_shared/src/utils/fulfillment/receiving";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useConfirmModal from "hooks/common/useConfirmModal";
import useGetReceivingDetail from "hooks/receiving/useGetReceivingDetail";
import useScanLocation from "hooks/receiving/useScanLocation";
import useScanWarehousingSKU from "hooks/receiving/useScanWarehousingSKU";
import useWarehousingCounting from "hooks/receiving/useSKUCountingForWarehousing";

import ItemStatus from "components/ItemStatus";

import { ScanModeForReceivingWarehousing } from "../../Warehousing";
import CompleteWarehousing from "../../Warehousing/CompleteWarehousing";
import AddPartialWarehousing from "./AddPartialWarehousing";
import PartedList from "./PartedList";
import Styled from "./index.styles";

function Guide({ skuId, actualQty }: { skuId: number; actualQty?: number }) {
  return (
    <Styled.guide>
      <div className="title">‣ 분할입고할 내용을 추가해주세요.</div>

      <div className="desc">
        [입고 정보]
        <br />
        ・SKU ID : {getFormattedSingleSkuId(skuId)}
        <br />
        ・총 입고수량 : {actualQty}
      </div>
    </Styled.guide>
  );
}

function TotalCount({ max, counted }: { max: number; counted: number }) {
  return (
    <Styled.totalCount>
      <div className="label">총 카운트 :</div>
      <div className="value">
        {counted} / {max}
      </div>
    </Styled.totalCount>
  );
}

function MultiLocation() {
  const match = useRouteMatch<{ id: string; itemId: string }>();
  const {
    params: { id, itemId },
  } = match;

  const [scanMode, setScanMode] =
    useState<ScanModeForReceivingWarehousing>("skuId");
  const [rowInfoToHighlight, setRowInfoToHighlight] = useState<
    TableRowInfoToHighlight | undefined
  >(undefined);
  const [placingIdInProgress, setPlacingIdInProgress] = useState("");

  const { ConfirmModal, setConfirmModal } = useConfirmModal();

  const skuItemsFilteredByActualQty = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.SKU_ITEMS_FILTERED_BY_ACTUAL_QTY
  );

  const { ResponseHandler: ResponseHandlerOfGettingManagerReceivingDetail } =
    useGetReceivingDetail({ receivingId: Number(id) });

  const skuCounting = useWarehousingCounting(skuItemsFilteredByActualQty);

  const {
    setScanSKUActive,
    showSelectionErrorForScanSKU,
    ResultHandlerOfScanSKU,
  } = useScanWarehousingSKU({
    scanType: "multi",
    placingId: placingIdInProgress,
    skuCounting,
    receivingId: Number(id),
    setRowInfoToHighlight,
    setScanMode,
  });

  const {
    scannedLocation,
    setScannedLocation,
    setScanLocationActive,
    showSelectionErrorForScanLocation,
    ResultHandlerOfScanLocation,
  } = useScanLocation({
    scanType: "multi",
    skuInprogress: skuCounting.skuInProgress,
    counterData: skuCounting.counter.counterInfo,
  });

  useEffect(() => {
    setScanSKUActive(scanMode === "skuId");
    setScanLocationActive(scanMode === "location");
  }, [scanMode, setScanLocationActive, setScanSKUActive]);

  const receivingItem = useMemo(() => {
    if (!skuItemsFilteredByActualQty) return;

    return skuItemsFilteredByActualQty.find((v) => v.id === Number(itemId));
  }, [skuItemsFilteredByActualQty, itemId]);

  const confirmedAsSingleLocationWarehousing = useMemo(() => {
    return checkConfirmedAsSingleLocationWarehousing(receivingItem);
  }, [receivingItem]);

  const totalPlacedQty = useMemo(() => {
    if (!receivingItem?.placeItems) return 0;

    return receivingItem.placeItems.reduce((a, c) => {
      return a + (c.placeQty ?? 0);
    }, 0);
  }, [receivingItem]);

  const hasOnlyInitialWarehousing = useMemo(() => {
    return checkHasOnlyInitialWarehousing(receivingItem);
  }, [receivingItem]);

  const receivingItemCompleted = useMemo(() => {
    const allWarehousingItemsAreSplit =
      receivingItem?.actualQty ===
      receivingItem?.placeItems.reduce((a, c) => {
        return a + (c.quantity ?? 0);
      }, 0);

    const allWarehousingItemsAreCompleted = receivingItem?.placeItems.every(
      (ii) => ii.isCompletePlacing
    );

    return allWarehousingItemsAreSplit && allWarehousingItemsAreCompleted;
  }, [receivingItem?.placeItems, receivingItem?.actualQty]);

  const itemStatusLabel = useMemo(() => {
    return getWarehousingItemStatusLabel(receivingItem?.processStatus);
  }, [receivingItem?.processStatus]);

  const isUnverifiedSku = checkForUnverifiedItem(receivingItem);

  const confirmMessageToLeave = useMemo(() => {
    //  분할입고를 시작했는데, 모든 분할입고를 완료하지 않았을 경우 confirm 메시지를 띄움.
    if (
      !hasOnlyInitialWarehousing &&
      !confirmedAsSingleLocationWarehousing &&
      !receivingItemCompleted
    ) {
      const managementDateLabel = getManagementDateLabel(
        receivingItem?.sku.managementKind,
        receivingItem?.managementDate
      );

      return {
        title: (
          <>
            {getFormattedSingleSkuId(receivingItem?.skuId)}(SKU ID)
            <br />
            상태:{" "}
            <ItemStatus
              statusLabel={itemStatusLabel}
              isUnverifiedSku={checkForUnverifiedItem(receivingItem)}
            />
            {managementDateLabel && (
              <>
                <br />
                {managementDateLabel}
              </>
            )}
          </>
        ),
        body: (
          <>
            입고가 완료되지 않았습니다.
            <br />
            분할입고를 그만하겠습니까?
          </>
        ),
      };
    }
  }, [
    hasOnlyInitialWarehousing,
    confirmedAsSingleLocationWarehousing,
    receivingItemCompleted,
    itemStatusLabel,
    receivingItem,
  ]);

  const handleScanSKUClick = useCallback(() => {
    if (!placingIdInProgress) {
      showSelectionErrorForScanSKU();
      return;
    }

    setScanMode("skuId");
  }, [placingIdInProgress, showSelectionErrorForScanSKU]);

  const handleScanLocationClick = useCallback(() => {
    if (!skuCounting.skuInProgress) {
      showSelectionErrorForScanLocation();
    }

    setScanMode("location");
  }, [showSelectionErrorForScanLocation, skuCounting.skuInProgress]);

  const resetCountByCounterKey = useCallback(
    (counterKey: string) => () => {
      setConfirmModal(undefined);

      skuCounting.counter.resetCountById(counterKey);
      skuCounting.setSkuInProgress(undefined);
      setRowInfoToHighlight(undefined);
    },
    [setConfirmModal, skuCounting]
  );

  const resetAfterComplete = useCallback(() => {
    setScanMode("skuId");
    setScannedLocation(undefined);
    setRowInfoToHighlight(undefined);
    skuCounting.setSkuInProgress(undefined);
    setPlacingIdInProgress("");
  }, [setScannedLocation, skuCounting]);

  if (!receivingItem) return null;

  const navigatorTitle = (() => {
    const managementDateLabel = getManagementDateLabel(
      receivingItem?.sku.managementKind,
      receivingItem?.managementDate
    );

    return (
      <>
        분할입고(ID: {getFormattedSingleSkuId(receivingItem.skuId)})
        <br />
        상태:{" "}
        <ItemStatus
          statusLabel={itemStatusLabel}
          isUnverifiedSku={isUnverifiedSku}
        />
        {receivingItem.sku.barCode ? (
          <>
            <br />({receivingItem.sku.barCode})
          </>
        ) : (
          ""
        )}
        {managementDateLabel && (
          <>
            <br />
            {managementDateLabel}
          </>
        )}
      </>
    );
  })();

  return (
    <Layout
      navigator={{
        title: navigatorTitle,
      }}
      confirmMessageToLeave={confirmMessageToLeave}
    >
      <Styled.container>
        {ConfirmModal}

        <Styled.headerForScan>
          <div className="left">{scannedLocation?.barCode}</div>

          <div className="right">
            <Button
              label="상품스캔"
              size="small"
              theme={scanMode === "skuId" ? "tertiary" : "secondary"}
              handleClick={handleScanSKUClick}
            />

            <Button
              label="위치스캔"
              size="small"
              theme={scanMode === "location" ? "tertiary" : "secondary"}
              handleClick={handleScanLocationClick}
            />
          </div>
        </Styled.headerForScan>

        {hasOnlyInitialWarehousing ? (
          <Guide
            skuId={receivingItem.skuId}
            actualQty={receivingItem.actualQty}
          />
        ) : (
          <PartedList
            setScanMode={setScanMode}
            receivingId={Number(id)}
            itemId={receivingItem.id}
            skuId={receivingItem.skuId}
            managementKind={receivingItem.sku.managementKind}
            managementDate={receivingItem.managementDate}
            placeItems={receivingItem.placeItems}
            selectedLocation={scannedLocation}
            rowInfoToHighlight={rowInfoToHighlight}
            setRowInfoToHighlight={setRowInfoToHighlight}
            placingIdInProgress={placingIdInProgress}
            setPlacingIdInProgress={setPlacingIdInProgress}
            skuCounting={skuCounting}
            counterData={skuCounting.counter.counterInfo}
            maxCount={receivingItem.actualQty ?? 0}
            addCountByInput={skuCounting.counter.addCountByInput}
            resetCountByCounterKey={resetCountByCounterKey}
            setConfirmModal={setConfirmModal}
            statusLabel={itemStatusLabel}
            isUnverifiedSku={isUnverifiedSku}
          />
        )}

        <TotalCount
          counted={totalPlacedQty}
          max={receivingItem.actualQty ?? 0}
        />

        <AddPartialWarehousing
          receivingId={Number(id)}
          receivingItem={receivingItem}
        />

        <CompleteWarehousing
          locationType="multi"
          receivingId={Number(id)}
          selectedLocation={scannedLocation}
          counterInProgress={
            skuCounting.skuInProgress
              ? skuCounting.counter.counterInfo[
                  skuCounting.skuInProgress.counterKey
                ]
              : undefined
          }
          resetAfterComplete={resetAfterComplete}
        />
      </Styled.container>

      {ResponseHandlerOfGettingManagerReceivingDetail}
      {ResultHandlerOfScanLocation}
      {ResultHandlerOfScanSKU}
    </Layout>
  );
}

export default withRequireAuth(MultiLocation);
