import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_CANCELING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/canceling";
import { SKUInfoForCancelingRestocking } from "@sellernote/_shared/src/types/fulfillment/canceling";
import { ManagementKind } from "@sellernote/_shared/src/types/fulfillment/inventory";
import { ScanType } from "@sellernote/_shared/src/types/fulfillment/scan";

export interface WorkingLocation {
  id: number;
  barcode: string;
  managementKind: ManagementKind | undefined;
  managementDate: string | undefined;
}

export interface CompletedList {
  locationIdList: Set<number>;
  itemIdListByWorkingLocation: Set<number>;
  itemIdList: Set<number>;
}

/**
 * 재입고 화면 관리, 스캔 버튼 관리, 완료/문제보고 후 초기화, interpretBarcode 핸들러
 */
export default function useRestockCanceling() {
  const [workingLocation, setWorkingLocation] = useState<WorkingLocation>();
  const [selectedScanButtonType, setSelectedScanButtonType] = useState<
    ScanType | undefined
  >("location");
  const [rowInfoToHighlight, setRowInfoToHighlight] =
    useState<TableRowInfoToHighlight>();

  const [SKUInfoForScanning, setSKUInfoForScanning] = useState<
    SKUInfoForCancelingRestocking[]
  >([]);
  const completedList = useRef<CompletedList>({
    locationIdList: new Set(),
    itemIdList: new Set(),
    itemIdListByWorkingLocation: new Set(),
  });

  const isFirstSKUScan = useRef(true);

  const history = useHistory();

  const restockingList = useRecoilValue(
    FULFILLMENT_CANCELING_SELECTORS.RESTOCKING_LIST
  );

  useEffect(() => {
    setSKUInfoForScanning(restockingList);
  }, [restockingList]);

  const handleScanButtonClick = useCallback(
    (type: ScanType | undefined) =>
      selectedScanButtonType === type
        ? setSelectedScanButtonType(undefined)
        : setSelectedScanButtonType(type),
    [selectedScanButtonType]
  );

  const allRestockingAreCompleted = SKUInfoForScanning.every(
    (v) => v.currentQty === v.quantity
  );

  const moveToCancelingListPage = useCallback(() => {
    history.push("/canceling");
  }, [history]);

  return {
    workingLocation,
    setWorkingLocation,

    rowInfoToHighlight,
    setRowInfoToHighlight,

    completedList,

    isFirstSKUScan,

    selectedScanButtonType,
    handleScanButtonClick,

    SKUInfoForScanning,
    setSKUInfoForScanning,

    allRestockingAreCompleted,

    moveToCancelingListPage,
  };
}
