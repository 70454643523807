import { useMemo } from "react";
import { useQueryClient } from "react-query";

import { UPDATE_MANAGEMENT_DATE_OF_UNVERIFIED_ITEM_REQ } from "@sellernote/_shared/src/api-interfaces/boful-api/receiving";
import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";

import { MapForManagementDateFormList } from ".";

export default function Submit({
  receivingId,
  mapForManagementDateFormList,
}: {
  receivingId: number;
  mapForManagementDateFormList: MapForManagementDateFormList;
}) {
  const queryClient = useQueryClient();

  const {
    mutate: updateManagementDate,
    ResponseHandler: ResponseHandlerOfUpdateManagementDate,
    isLoading: isLoadingOfUpdateManagementDate,
  } = RECEIVING_QUERY.useUpdateManagementDateOfUnverifiedItem({
    receivingId,
    onSuccess: () => {
      /**
       * 업데이트 성공시 detail정보를 다시 불러온다
       * 데이터가 정상적으로 처리된 경우, 새 detail정보에는 update할 불일치 상품내역이 없으므로 입고화면으로 넘어가게 된다.
       */
      queryClient.invalidateQueries(
        RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
          receivingId,
        })
      );
    },
  });

  const canSubmit = useMemo(() => {
    if (!mapForManagementDateFormList) return false;

    if (isLoadingOfUpdateManagementDate) return false;

    return Object.values(mapForManagementDateFormList).every((v) => {
      const managementDateFormList = v.formList;

      const isAllFormFilled = managementDateFormList.every(
        (managementDateForm) =>
          !!(managementDateForm.managementDate && managementDateForm.quantity)
      );
      // 모든 input이 입력되어야한다.
      if (!isAllFormFilled) return false;

      const currentCount = managementDateFormList.reduce((a, c) => {
        return a + (c.quantity ?? 0);
      }, 0);
      // item별 count가 맞아야한다
      if (v.goalCount !== currentCount) return false;

      return true;
    });
  }, [isLoadingOfUpdateManagementDate, mapForManagementDateFormList]);

  const handleSubmit = () => {
    const mismatchedItems = (() => {
      return Object.entries(mapForManagementDateFormList).reduce(
        (
          a: UPDATE_MANAGEMENT_DATE_OF_UNVERIFIED_ITEM_REQ["mismatchedItems"],
          [itemId, itemFormInfo]
        ) => {
          const subItemList: UPDATE_MANAGEMENT_DATE_OF_UNVERIFIED_ITEM_REQ["mismatchedItems"] =
            itemFormInfo.formList.map((v) => ({
              itemId: Number(itemId),
              // validation 통과후 submit가능하므로 밑의 값들은 모두 있는 상황이다
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              managementDate: v.managementDate!,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              quantity: v.quantity!,
            }));

          return [...a, ...subItemList];
        },
        []
      );
    })();

    updateManagementDate({ mismatchedItems });
  };

  return (
    <>
      <Button
        label="선택 완료"
        size="block"
        theme="primary"
        handleClick={handleSubmit}
        disabled={!canSubmit}
      />

      {ResponseHandlerOfUpdateManagementDate}
    </>
  );
}
