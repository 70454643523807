import newId from "@sellernote/_shared/src/utils/common/newId";

import { ManagementDateForm } from ".";

/**
 * 새로운 ManagementDate Form State를 생성
 * - 선택하기 전의 UI를 표시하는 용도이므로 세부 값은 undefined로 초기화 됨
 */
function createNewManagementDateForm(): ManagementDateForm {
  return {
    id: newId("management-date-"),
    managementDate: undefined,
    quantity: undefined,
  };
}

export { createNewManagementDateForm };
