import React from "react";

import Item from "./Item";
import Styled from "./index.styles";

export type AccordionType = "QnA" | "information";

export type AccordionItemStatus = "closed" | "opened" | "disabled";

export interface AccordionItem {
  status: AccordionItemStatus;
  title: React.ReactNode;
  desc: React.ReactNode;
}

export default function Accordion({
  type,
  list,
  className,
}: {
  type: AccordionType;
  list: AccordionItem[];
  className?: string;
}) {
  return (
    <Styled.container className={`${className ? className : ""} accordion`}>
      {list.map((v, i) => {
        return <Item key={i} type={type} data={v} />;
      })}
    </Styled.container>
  );
}
