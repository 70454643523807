import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { MENU_LIST } from "@sellernote/_shared/src/utils/fulfillment/constants";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

import MenuBofulList, { MenuListBofulItem } from "components/MenuListBoful";

const inventoryMovementMenuList = MENU_LIST.find(
  (menu) => menu.path === "/inventory"
)?.subMenuList?.find(
  (menu) => menu.path === "/inventory/movement"
)?.subMenuList;

function InventoryMovement() {
  const currentManagerAuthority = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  )?.authority;

  const history = useHistory();

  const targetMenuList =
    currentManagerAuthority === "admin"
      ? inventoryMovementMenuList
      : inventoryMovementMenuList?.filter((menu) => menu.title !== "재고 조정");

  const menuList: MenuListBofulItem[] = (targetMenuList ?? []).map(
    (menu, i) => {
      return {
        label: `${i + 1}. ${menu.title}`,
        handleClick: () => history.push(menu.path),
      };
    }
  );

  return (
    <Layout
      navigator={{
        title: "재고 이동",
      }}
    >
      <MenuBofulList menuList={menuList} />
    </Layout>
  );
}

export default withRequireAuth(InventoryMovement);
