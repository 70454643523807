import { useHistory } from "react-router-dom";

import { MENU_LIST } from "@sellernote/_shared/src/utils/fulfillment/constants";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

import MenuBofulList, { MenuListBofulItem } from "components/MenuListBoful";

function ShippingShipment() {
  const history = useHistory();

  const targetMenuList = MENU_LIST.find(
    (m) => m.path === "/shipping"
  )?.subMenuList?.find((m) => m.path === "/shipping/shipment")?.subMenuList;

  const menuList: MenuListBofulItem[] = (targetMenuList ?? []).map(
    (menu, i) => {
      return {
        label: `${i + 1}. ${menu.title}`,
        handleClick: () => history.push(menu.path),
      };
    }
  );

  return (
    <Layout
      navigator={{
        title: "출하",
      }}
    >
      <MenuBofulList menuList={menuList} />
    </Layout>
  );
}

export default withRequireAuth(ShippingShipment);
