import { useCallback, useMemo, useState } from "react";
import axios from "axios";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import FILE_QUERY from "@sellernote/_shared/src/queries/fulfillment/FILE_QUERY";
import { FILE_ITEM_TO_UPLOAD } from "@sellernote/_shared/src/types/common/file";
import { getFileFromBase64 } from "@sellernote/_shared/src/utils/fulfillment/file";

/**
 * 단일 파일 업로드(Boful)
 */
export default function useUploadFile() {
  const [isUploading, setIsUploading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const { mutate: getPresignedURL } = FILE_QUERY.useGetPresignedURL();
  const { mutate: bindFileAfterUpload } = FILE_QUERY.useBindFileAfterUpload();

  const FailureModal = useMemo(() => {
    if (!isFailed) return null;

    return (
      <Modal
        active
        uiType="content"
        title="업로드 중에 오류가 발생했습니다."
        body="고객센터에 문의해주세요"
        actionPositive={{
          label: "확인",
          handleClick: () => {
            setIsFailed(false);
          },
        }}
      />
    );
  }, [isFailed]);

  const uploadFile = useCallback(
    ({
      data,
      onUploadSuccess,
      onUploadFailure,
    }: {
      data: FILE_ITEM_TO_UPLOAD;
      // 구체적인 타입을 지정하기 어려워서 부득이하게 any로 지정
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onUploadSuccess?: (params: any) => void;
      onUploadFailure?: () => void;
    }) => {
      const onError = () => {
        onUploadFailure && onUploadFailure();

        setIsUploading(false);
        setIsFailed(true);
      };

      const uploadFileToBucket = (
        uploadInfo: { url: string; key: string },
        file: File
      ) => {
        axios
          .put(uploadInfo.url, file, {
            headers: { "Content-Type": file.type },
          })
          .then(() =>
            bindFileAfterUpload(
              [
                {
                  domain: data.domain,
                  key: uploadInfo.key,
                  targetId: data.targetId,
                },
              ],
              {
                onSuccess: () => {
                  onUploadSuccess && onUploadSuccess(uploadInfo.key);

                  setIsUploading(false);
                },

                onError,
              }
            )
          )
          .catch(onError);
      };

      setIsUploading(true);
      getPresignedURL(
        [
          {
            name: data.name,
            targetId: data.targetId,
            actorKind: data.actorKind,
            mimeType: data.mimeType,
            domain: data.domain,
          },
        ],
        {
          onSuccess: ({ data: successData }) => {
            const uploadInfo = successData.map((v) => ({
              url: v.url,
              key: v.key,
            }))[0];

            const file = getFileFromBase64(
              data.fileBase64String,
              data.fileName
            );

            uploadFileToBucket(uploadInfo, file);
          },

          onError,
        }
      );
    },
    [bindFileAfterUpload, getPresignedURL]
  );

  const LoadingToUpload = useMemo(() => {
    return <Loading active={isUploading} />;
  }, [isUploading]);

  return [uploadFile, LoadingToUpload, FailureModal] as const;
}
