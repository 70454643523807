import { ManagementKind } from "../../types/fulfillment/inventory";

const INVENTORY_MANAGEMENT_KIND_MAP: Record<ManagementKind, string> = {
  expirationDate: "유통기한",
  manufacturingDate: "제조일자",
};

const PRIORITY_RANK_MAP: Record<string, -2 | -1 | 0> = {
  /** 입고/반품 작업 중 */
  receivingOrReturnWorkingStatus: -2,
  /** 만료 */
  expiryStatus: -1,
  /** 작업 중 */
  workingStatus: 0,
};

export { INVENTORY_MANAGEMENT_KIND_MAP, PRIORITY_RANK_MAP };
