import React, { useCallback } from "react";
import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { APP_BUILD_INFO } from "@sellernote/_shared/src/constants";
import { FULFILLMENT_AUTH_ATOMS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { MENU_LIST } from "@sellernote/_shared/src/utils/fulfillment/constants";
import TextButton from "@sellernote/_sds-v1/src/components/button/TextButton";

import MobileMenu from "./MobileMenu";
import Styled from "./index.styles";

function LayoutMenu({
  setVisibleCannotLeaveModal,
}: {
  setVisibleCannotLeaveModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const setLoggedIn = useSetRecoilState(FULFILLMENT_AUTH_ATOMS.LOGGED_IN);
  const canNotLeavePage = useRecoilValue(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );

  const [isOpened, setIsOpened] = useState(false);

  const handleLogoutClick = useCallback(() => {
    window.localStorage.clear();
    setLoggedIn(false);
    window.location.href = "/";
  }, [setLoggedIn]);

  return (
    <>
      <Icon
        type="menu"
        size={1.5}
        color={COLOR.grayScale_800}
        onClick={
          canNotLeavePage
            ? () => setVisibleCannotLeaveModal(true)
            : () => {
                setIsOpened(!isOpened);
              }
        }
      />

      <Styled.detail isOpened={isOpened}>
        <div
          className="aside"
          onClick={() => {
            setIsOpened(false);
          }}
        >
          <Icon type="clear" size={1.5} color={COLOR.white} />
        </div>
        <div className="body">
          <div className="logout-wrapper">
            <TextButton
              theme="danger"
              label="로그아웃"
              size="14px"
              handleClick={handleLogoutClick}
              icon="logout"
              className="logout"
            />
          </div>

          {/* TODO: 개발용 새로고침 - 출시 후 지울것 */}
          <div
            className="reset-wrapper"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "12px",
              paddingTop: "10px",
            }}
          >
            <Icon
              type="reset"
              onClick={() => {
                location.reload();
              }}
            />
          </div>

          <div className="menu-container">
            {MENU_LIST.map((menu, i) => {
              return <MobileMenu key={i} menu={menu} />;
            })}
          </div>

          {APP_BUILD_INFO && (
            <div className="app-build-info">
              <div className="built-at">
                {`빌드: ${toFormattedDate(
                  APP_BUILD_INFO.builtAt,
                  "YYYY.MM.DD HH:mm:ss"
                )}`}
              </div>

              <div className="hidden">{APP_BUILD_INFO.gitBranch}</div>

              <div className="hidden">{APP_BUILD_INFO.gitCommitSha}</div>
            </div>
          )}
        </div>
      </Styled.detail>
    </>
  );
}

export default LayoutMenu;
