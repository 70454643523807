import { useEffect } from "react";
import { useSetRecoilState } from "recoil";

import RECEIVING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { FULFILLMENT_RECEIVING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/receiving";

export default function useGetReceivingDetail({
  receivingId,
  enabled,
}: {
  receivingId: number;
  enabled?: boolean;
}) {
  const setManagerReceivingDetail = useSetRecoilState(
    FULFILLMENT_RECEIVING_ATOMS.MANAGER_RECEIVING_DETAIL
  );

  const queryResult = RECEIVING_QUERY.useGetManagerReceivingDetail({
    receivingId,
    enabled,
    onSuccess: setManagerReceivingDetail,
    onError: () => setManagerReceivingDetail(undefined),
  });

  useEffect(() => {
    return () => setManagerReceivingDetail(undefined);
  }, [setManagerReceivingDetail]);

  return queryResult;
}
