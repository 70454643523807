import { Dispatch, SetStateAction } from "react";

import { GET_INVENTORY_DETAIL_BY_SKU_ID_RES } from "@sellernote/_shared/src/api-interfaces/boful-api/inventory";
import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import INVENTORY_QUERY from "@sellernote/_shared/src/queries/fulfillment/INVENTORY_QUERY";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";

import { SkuToSelect } from "../useSelectSkuModal";

const getManagementDateList = (
  data: GET_INVENTORY_DETAIL_BY_SKU_ID_RES | undefined
) => [
  ...new Set(
    (data ?? [])
      .filter(({ managementDate }) => Boolean(managementDate))
      .map(({ managementDate }) => managementDate) as string[]
  ),
];

export default function useSelectManagementDate({
  tempSku,
  setTempSku,
}: {
  tempSku: SkuToSelect | undefined;
  setTempSku: Dispatch<SetStateAction<SkuToSelect | undefined>>;
}) {
  const {
    data: inventoryDetailBySkuId,
    ResponseHandler: ResponseHandlerOfInventoryDetailBySkuId,
  } = INVENTORY_QUERY.useGetInventoryDetailBySkuId({
    skuId: tempSku?.id ?? 0,
    enabled: Boolean(tempSku?.managementKind),

    onSuccess: (data) => {
      const managementDateList = getManagementDateList(data);

      const hasOnlyOneOption = managementDateList.length === 1;
      if (hasOnlyOneOption) {
        handleManagementDateSelect({
          label: toFormattedDate(managementDateList[0], "YYYY-MM-DD"),
          value: managementDateList[0],
        });
      }
    },
  });

  const handleManagementDateSelect = ({ value }: InputSelectOption<string>) => {
    setTempSku((prev) => {
      if (!prev) {
        return prev;
      }

      return {
        ...prev,
        managementDate: value,
      };
    });
  };

  const optionList: InputSelectOption<string>[] = getManagementDateList(
    inventoryDetailBySkuId
  )
    .sort()
    .map((managementDate) => ({
      value: managementDate,
      label: toFormattedDate(managementDate, "YYYY-MM-DD"),
    }));

  return {
    optionList,

    handleManagementDateSelect,

    ResponseHandlerOfInventoryDetailBySkuId,
  };
}
