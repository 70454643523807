import Accordion from "@sellernote/_sds-v1/src/components/Accordion";
import ListWithMoreResult from "@sellernote/_sds-v1/src/components/ListWithMoreResult";

import withRequireAuth from "hocs/withRequireAuth";
import useShipmentClosingParcel from "hooks/shipping/useShipmentClosingParcel";

import ShipmentClosingLayout from "../../ShipmentClosingLayout";
import Styled from "./index.styles";

function ShippingShipmentClosingParcelCompany() {
  const {
    isAllCompany,

    TodayExpectedShipmentSummary,
    CompletedShipmentSummary,
    UnprocessedShipmentSummary,

    firstReadyShipment,
    firstDoneShipment,
    secondReadyShipment,
    secondDoneShipment,

    isOpenedSecondOrder,

    ResponseHandlerOfGettingShipmentParcelSummary,
  } = useShipmentClosingParcel();

  // * 출고 시기는 2회까지만 운영한다. 추후 추가될 수 있다.
  return (
    <ShipmentClosingLayout title="택배" selectType="택배사">
      <Styled.container>
        {TodayExpectedShipmentSummary}
        {isAllCompany ? (
          <>
            <>{CompletedShipmentSummary}</>
            <>{UnprocessedShipmentSummary}</>
          </>
        ) : (
          <>
            <Accordion
              type="information"
              list={[
                {
                  status: "opened",
                  title: "1차 출고 건",
                  desc: (
                    <>
                      <ListWithMoreResult title={firstReadyShipment.title} />
                      <ListWithMoreResult title={firstDoneShipment.title} />
                    </>
                  ),
                },
              ]}
            />
            <Accordion
              type="information"
              list={[
                {
                  status: isOpenedSecondOrder ? "opened" : "closed",
                  title: "2차 출고 건",
                  desc: (
                    <>
                      <ListWithMoreResult title={secondReadyShipment.title} />
                      <ListWithMoreResult title={secondDoneShipment.title} />
                    </>
                  ),
                },
              ]}
            />
          </>
        )}
      </Styled.container>

      {ResponseHandlerOfGettingShipmentParcelSummary}
    </ShipmentClosingLayout>
  );
}

export default withRequireAuth(ShippingShipmentClosingParcelCompany);
