import styled from "styled-components";

const completeAdjustment = styled.div`
  > .complete-button {
    margin-bottom: 24px;
  }
`;

export default {
  completeAdjustment,
};
