import { atom } from "recoil";

import { CancelingDetailInfo } from "../../../types/fulfillment/canceling";

const CANCELING_DETAIL = atom<{
  cancelingData: CancelingDetailInfo | undefined;
  cancelingId: number;
  cancelingInvoiceNo: string;
}>({
  key: "fulfillment/canceling/atoms/CANCELING_DETAIL",
  default: {
    cancelingData: undefined,
    cancelingId: 0,
    cancelingInvoiceNo: "",
  },
});

export default {
  CANCELING_DETAIL,
};
