import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 4px;
  flex-wrap: nowrap;

  .label {
    flex-shrink: 0;
    ${setFontStyle("SubHead3")};
    color: ${COLOR.grayScale_500};
  }

  .value-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 16px;

    > .value {
      margin-right: 4px;
      ${setFontStyle("Body7")};
      color: ${COLOR.grayScale_800};
    }
  }
`;

export default {
  container,
};
