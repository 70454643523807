import { GET_INVENTORY_DETAIL_BY_SKU_RES } from "@sellernote/_shared/src/api-interfaces/boful-api/inventory";
import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { INVENTORY_MANAGEMENT_KIND_MAP } from "@sellernote/_shared/src/constants/fulfillment/inventory";
import { LocationKindMap } from "@sellernote/_shared/src/constants/fulfillment/location";
import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import InputTextWithSearch from "@sellernote/_sds-v1/src/components/input/InputTextWithSearch";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import EmptyData from "../EmptyData.tsx";
import Styled from "../index.styles";

type SkuDataListItem = {
  totalQty: string;
  availableQty: string;
  inWorkingQty: string;
  faultyQty: string;
};

type LocationDataListItem = {
  barCode: string;
  name: string;
  totalQty: string;
  inWorkingQty: string;
  availableQty: string;
  managementDate?: string;
  kind: string;
};

export default function SearchSkuSection({
  isSearching,

  inputBarcode,
  searchLocation,
  resetSearch,
  isErrorMessageActive,

  isScanButtonActive,
  toggleScanButton,

  inventoryDetailBySku,
}: {
  isSearching: boolean;

  inputBarcode: string;
  searchLocation: (barcode: string) => void;
  resetSearch: () => void;
  isErrorMessageActive: boolean;

  isScanButtonActive: boolean;
  toggleScanButton: () => void;

  inventoryDetailBySku: GET_INVENTORY_DETAIL_BY_SKU_RES | undefined;
}) {
  const skuData = (inventoryDetailBySku ?? []).reduce(
    (acc, item) => {
      acc.totalQty += item.totalQty;
      acc.availableQty += item.availableQty;
      acc.inWorkingQty += item.inWorkingQty;
      acc.faultyQty += item.faultyQty;

      return acc;
    },
    { totalQty: 0, availableQty: 0, inWorkingQty: 0, faultyQty: 0 }
  );
  const skuDataList: TableDataListItem<SkuDataListItem>[] = [
    {
      rowKey: "skuData",
      totalQty: toThousandUnitFormat(skuData.totalQty),
      availableQty: toThousandUnitFormat(skuData.availableQty),
      inWorkingQty: toThousandUnitFormat(skuData.inWorkingQty),
      faultyQty: toThousandUnitFormat(skuData.faultyQty),
    },
  ];

  const locationDataList: TableDataListItem<LocationDataListItem>[] = (
    inventoryDetailBySku ?? []
  ).map((item) => ({
    rowKey: item.id,

    barCode: item.location?.barCode ?? "",
    name: item.location?.name ?? "",
    totalQty: toThousandUnitFormat(item.totalQty),
    availableQty: toThousandUnitFormat(item.availableQty),
    inWorkingQty: toThousandUnitFormat(item.inWorkingQty),
    ...(item.sku.managementKind && {
      managementDate: toFormattedDate(item.managementDate, "YYYY-MM-DD"),
    }),
    kind: item.location?.kind ? LocationKindMap[item.location.kind] : "",
  }));

  return (
    <>
      <Styled.searchAndScan>
        <div className="input-text-with-search-container">
          <InputTextWithSearch
            searchTerm={inputBarcode}
            handleSearch={searchLocation}
            resetSearch={resetSearch}
            placeholder="SKU ID, 상품 바코드 검색"
          />
          {isErrorMessageActive && (
            <div className="input-text-with-search-error-message">
              검색 결과가 없습니다.
            </div>
          )}
        </div>

        <div className="scan-button-wrapper">
          <Button
            theme={isScanButtonActive ? "scan" : "secondary"}
            size="small"
            label="상품스캔"
            handleClick={toggleScanButton}
          />
        </div>
      </Styled.searchAndScan>

      <Styled.dataContainer>
        <div className="title">보관 상품 정보</div>

        {inventoryDetailBySku ? (
          <>
            <div className="sku-data-list">
              <ListItem
                type="withContentAlignRight"
                label="SKU ID"
                value={getFormattedSingleSkuId(inventoryDetailBySku[0].sku.id)}
              />
              <ListItem
                type="withContentAlignRight"
                label="상품바코드"
                value={replaceEmptyToDash(inventoryDetailBySku[0].sku.barCode)}
              />
              <ListItem
                type="withContentAlignRight"
                label="판매자상품코드"
                value={replaceEmptyToDash(
                  inventoryDetailBySku[0].sku.productCode
                )}
              />
              <ListItem
                type="withContentAlignRight"
                label="옵션관리코드"
                value={replaceEmptyToDash(
                  inventoryDetailBySku[0].sku.managementCode
                )}
              />
              <ListItem
                type="withContentAlignRight"
                label="상품명"
                value={replaceEmptyToDash(inventoryDetailBySku[0].sku.itemName)}
              />
              <ListItem
                type="withContentAlignRight"
                label="유통기한/제조일자 여부"
                value={inventoryDetailBySku[0].managementDate ? "Y" : "N"}
              />
              <ListItem
                type="withContentAlignRight"
                label="회사명"
                value={replaceEmptyToDash(inventoryDetailBySku[0].team.company)}
              />
            </div>

            <Table<SkuDataListItem>
              columnInfo={{
                totalQty: { label: "총 수량", portion: 86 },
                availableQty: { label: "가용 수량", portion: 70 },
                inWorkingQty: { label: "작업 중 수량", portion: 70 },
                faultyQty: { label: "불량", portion: 30 },
              }}
              dataList={skuDataList}
            />
          </>
        ) : (
          <EmptyData isSearching={isSearching} type="sku" />
        )}
      </Styled.dataContainer>

      <Styled.dataContainer>
        <div className="title">로케이션 정보</div>

        {inventoryDetailBySku ? (
          <Table<LocationDataListItem>
            columnInfo={
              inventoryDetailBySku[0].managementDate
                ? {
                    barCode: { label: "위치", fixedWidth: 86 },
                    name: { label: "타입", fixedWidth: 90 },
                    totalQty: { label: "총 수량", fixedWidth: 86 },
                    availableQty: { label: "가용 수량", fixedWidth: 86 },
                    inWorkingQty: { label: "작업 중 수량", fixedWidth: 86 },
                    managementDate: {
                      label: inventoryDetailBySku[0].sku.managementKind
                        ? INVENTORY_MANAGEMENT_KIND_MAP[
                            inventoryDetailBySku[0].sku.managementKind
                          ]
                        : "",
                      fixedWidth: 90,
                    },
                    kind: { label: "구분", fixedWidth: 60 },
                  }
                : {
                    barCode: { label: "위치", fixedWidth: 86 },
                    name: { label: "타입", fixedWidth: 90 },
                    totalQty: { label: "총 수량", fixedWidth: 86 },
                    availableQty: { label: "가용 수량", fixedWidth: 86 },
                    inWorkingQty: { label: "작업 중 수량", fixedWidth: 86 },
                    kind: { label: "구분", fixedWidth: 60 },
                  }
            }
            dataList={locationDataList}
          />
        ) : (
          <EmptyData isSearching={isSearching} type="sku" />
        )}
      </Styled.dataContainer>
    </>
  );
}
