import { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";

import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { FulfillmentAttachment } from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import { ReceivingInvoiceNo } from "@sellernote/_shared/src/types/fulfillment/receiving";

export default function useResetInvoiceInfo({
  receivingId,
  invoiceNoList,
  attachments,
}: {
  receivingId: number;
  invoiceNoList: ReceivingInvoiceNo[];
  attachments: FulfillmentAttachment[];
}) {
  const queryClient = useQueryClient();

  const {
    mutate: resetInvoice,
    ResponseHandler: ResponseHandlerOfResetInvoiceInfo,
  } = RECEIVING_QUERY.useResetInvoiceInfo({
    receivingId,
  });

  const needToResetInvoiceInfo = useMemo(() => {
    const hasAttachment = attachments.some(
      ({ domain }) => domain === "invoice"
    );
    const hasMatchedInvoice = invoiceNoList.some(
      ({ isInvoiceMatched }) => isInvoiceMatched
    );

    const hasPreviousInfo = hasAttachment || hasMatchedInvoice;

    return hasPreviousInfo;
  }, [attachments, invoiceNoList]);

  const resetInvoiceInfo = useCallback(
    ({ callbackAfterReset }: { callbackAfterReset: () => void }) =>
      () => {
        resetInvoice(undefined, {
          onSuccess: () => {
            queryClient
              .invalidateQueries(
                RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
                  receivingId,
                })
              )
              .then(callbackAfterReset);
          },
        });
      },
    [queryClient, receivingId, resetInvoice]
  );

  return {
    needToResetInvoiceInfo,
    resetInvoiceInfo,
    ResponseHandlerOfResetInvoiceInfo,
  };
}
