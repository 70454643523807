import React from "react";

import { ListWithMoreResultProps } from "@sellernote/_shared/src/headlessComponents/useListWithMoreResult";
import ListItemWithMoreResult from "@sellernote/_sds-v1/src/components/ListItemWithMoreResult";

import ListItem from "../ListItem";
import Styled from "./index.styles";

export default function ListWithMoreResult({
  title,
  list,
  className,
}: ListWithMoreResultProps) {
  return (
    <Styled.container
      className={`${className ? className : ""} list-with-more-result`}
    >
      <ListItemWithMoreResult
        label={title.label}
        value={title.value}
        path={title.path}
      />

      {list &&
        list.map((item, i) => (
          <ListItem
            key={i}
            type="withContentAlignRight"
            label={item.label}
            value={item.value}
          />
        ))}
    </Styled.container>
  );
}
