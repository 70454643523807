import { ReactNode } from "react";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

import Styled from "./index.styles";

export default function UnverifiedSku({ children }: { children: ReactNode }) {
  return (
    <Styled.container>
      {children}

      <Styled.unverifiedSku>
        <Icon type="warning" size={1} color={COLOR.pointWarning} />
        <span>불일치 상품</span>
      </Styled.unverifiedSku>
    </Styled.container>
  );
}
