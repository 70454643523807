import styled, { css } from "styled-components";

import { hideScrollBar } from "@sellernote/_shared/src/styles/functions";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

const container = styled.div<{ isOpened: boolean }>`
  display: flex;

  ${(props) => {
    if (props.isOpened) {
      return css`
        flex-direction: column;
      `;
    }

    return css`
      flex-direction: row;
    `;
  }}

  > .chip-list {
    display: flex;
    padding: 0px 8px;
    flex: 1;
    ${hideScrollBar()};

    ${(props) => {
      if (props.isOpened) {
        return css`
          background-color: ${COLOR.grayScale_100};
          width: 100%;
          flex-wrap: wrap;
        `;
      }

      return css`
        overflow: scroll;
      `;
    }}
    > .chip-with-clear {
      margin: 8px;
      flex-shrink: 0;
    }
  }

  > .action {
    display: flex;
    align-items: center;
    margin-right: 6px;

    ${(props) => {
      if (props.isOpened) {
        return css`
          justify-content: flex-end;
        `;
      }

      return css``;
    }}
  }
`;

export default {
  container,
};
