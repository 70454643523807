import { GET_INVENTORY_DETAIL_BY_LOCATION_RES } from "@sellernote/_shared/src/api-interfaces/boful-api/inventory";
import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { INVENTORY_MANAGEMENT_KIND_MAP } from "@sellernote/_shared/src/constants/fulfillment/inventory";
import { LocationKindMap } from "@sellernote/_shared/src/constants/fulfillment/location";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import InputTextWithSearch from "@sellernote/_sds-v1/src/components/input/InputTextWithSearch";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

import EmptyData from "../EmptyData.tsx";
import Styled from "../index.styles";

export default function SearchLocationSection({
  inputBarcode,
  searchLocation,
  resetSearch,
  isSearching,
  isErrorMessageActive,

  isScanButtonActive,
  toggleScanButton,

  inventoryDetailByLocation,
}: {
  inputBarcode: string;
  searchLocation: (barcode: string) => void;
  resetSearch: () => void;
  isSearching: boolean;
  isErrorMessageActive: boolean;

  isScanButtonActive: boolean;
  toggleScanButton: () => void;

  inventoryDetailByLocation: GET_INVENTORY_DETAIL_BY_LOCATION_RES | undefined;
}) {
  return (
    <>
      <Styled.searchAndScan>
        <div className="input-text-with-search-container">
          <InputTextWithSearch
            searchTerm={inputBarcode}
            handleSearch={searchLocation}
            resetSearch={resetSearch}
            placeholder="LOC ID 검색"
          />
          {isErrorMessageActive && (
            <div className="input-text-with-search-error-message">
              검색 결과가 없습니다.
            </div>
          )}
        </div>

        <div className="scan-button-wrapper">
          <Button
            theme={isScanButtonActive ? "scan" : "secondary"}
            size="small"
            label="위치스캔"
            handleClick={toggleScanButton}
          />
        </div>
      </Styled.searchAndScan>

      <Styled.dataContainer>
        <div className="title">로케이션 정보</div>

        {inventoryDetailByLocation ? (
          <div className="location-data-list">
            <Styled.litItemRow>
              <ListItem
                className="custom-list-item"
                type="withContentAlignRight"
                label="위치"
                value={inventoryDetailByLocation.location.barCode}
              />
              <ListItem
                className="custom-list-item"
                type="withContentAlignRight"
                label="구분"
                value={LocationKindMap[inventoryDetailByLocation.location.kind]}
              />
            </Styled.litItemRow>

            <ListItem
              type="withContentAlignRight"
              label="타입"
              value={inventoryDetailByLocation.location.name}
            />
          </div>
        ) : (
          <EmptyData isSearching={isSearching} type="location" />
        )}
      </Styled.dataContainer>

      <Styled.dataContainer>
        <div className="title">보관 상품 정보</div>

        {inventoryDetailByLocation ? (
          <div className="sku-data-list">
            <ListItem
              type="withContentAlignRight"
              label="SKU ID"
              value={getFormattedSingleSkuId(inventoryDetailByLocation.sku.id)}
            />
            <ListItem
              type="withContentAlignRight"
              label="상품바코드"
              value={replaceEmptyToDash(inventoryDetailByLocation.sku.barCode)}
            />
            <ListItem
              type="withContentAlignRight"
              label="판매자상품코드"
              value={replaceEmptyToDash(
                inventoryDetailByLocation.sku.productCode
              )}
            />
            <ListItem
              type="withContentAlignRight"
              label="옵션관리코드"
              value={replaceEmptyToDash(
                inventoryDetailByLocation.sku.managementCode
              )}
            />
            <ListItem
              type="withContentAlignRight"
              label="상품명"
              value={replaceEmptyToDash(inventoryDetailByLocation.sku.itemName)}
            />
            <ListItem
              type="withContentAlignRight"
              label="수량(PCS)"
              value={toThousandUnitFormat(inventoryDetailByLocation.totalQty)}
            />
            {inventoryDetailByLocation.sku.managementKind && (
              <ListItem
                type="withContentAlignRight"
                label={
                  INVENTORY_MANAGEMENT_KIND_MAP[
                    inventoryDetailByLocation.sku.managementKind
                  ]
                }
                value={toFormattedDate(
                  inventoryDetailByLocation.managementDate,
                  "YYYY-MM-DD"
                )}
              />
            )}
            <ListItem
              type="withContentAlignRight"
              label="회사명"
              value={replaceEmptyToDash(inventoryDetailByLocation.team.company)}
            />
          </div>
        ) : (
          <EmptyData isSearching={isSearching} type="location" />
        )}
      </Styled.dataContainer>
    </>
  );
}
