import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

import useScan from "hooks/common/useScan";
import { returningActions } from "modules/returning";

import Styled from "./index.styles";

type InvoiceScanStatus = "standBy" | "matched" | "misMatched";

export default function useScanInvoicePanelForConfirm({
  returningId,
  invoiceNo,
  showPicturePanel,
}: {
  returningId: number;
  invoiceNo: string;
  showPicturePanel: () => void;
}) {
  const dispatch = useDispatch();

  const [visiblePanel, setVisiblePanel] = useState(false);

  const [scannedInvoice, setScannedInvoice] = useState("");

  const [invoiceScanStatus, setInvoiceScanStatus] =
    useState<InvoiceScanStatus>("standBy");

  useScan((scanResult) => {
    if (!visiblePanel) return;

    setScannedInvoice(scanResult);
  });

  useEffect(() => {
    if (!scannedInvoice || !invoiceNo) return;

    setInvoiceScanStatus(
      invoiceNo === scannedInvoice ? "matched" : "misMatched"
    );
  }, [scannedInvoice, invoiceNo]);

  const clear = useCallback(() => {
    setInvoiceScanStatus("standBy");
    setScannedInvoice("");
  }, []);

  useEffect(() => {
    return () => {
      clear();
    };
  }, [clear]);

  useEffect(() => {
    if (!visiblePanel) {
      clear();
    }
  }, [clear, visiblePanel]);

  const {
    mutate: setInvoiceScanDone,
    ResponseHandler: ResponseHandlerOfSetInvoiceScanDone,
  } = RETURNING_QUERY.useSetInvoiceScanDone({
    returningId,

    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();

        setVisiblePanel(false);

        dispatch(returningActions.GET_DETAIL({ id: returningId }));
      },
      customizeMessage: () => ({
        messageType: "titleOnly",
        title: "완료 처리되었습니다.",
      }),
    },
  });

  const showScanInvoicePanel = useCallback(() => {
    setVisiblePanel(true);
  }, []);

  const ValueWithScanStatus = useMemo(() => {
    switch (invoiceScanStatus) {
      case "standBy": {
        return (
          <Styled.invoiceValue>
            스캔중 <Icon type="loader" size={1} color={COLOR.primaryBlue} />
          </Styled.invoiceValue>
        );
      }
      case "matched": {
        return (
          <Styled.invoiceValue className="matched">
            {scannedInvoice}{" "}
            <Icon type="check" size={1} color={COLOR.primaryBlue} />
          </Styled.invoiceValue>
        );
      }
      case "misMatched": {
        return (
          <Styled.invoiceValue className="mis-matched">
            {scannedInvoice}{" "}
            <Icon type="clear" size={1} color={COLOR.pointWarning} />
          </Styled.invoiceValue>
        );
      }
      default: {
        return null;
      }
    }
  }, [invoiceScanStatus, scannedInvoice]);

  const ScanInvoicePanel = useMemo(() => {
    if (!visiblePanel) return null;

    return (
      <Modal
        active
        uiType="contentWithCustomBody"
        title={"송장스캔"}
        body={
          <Styled.modalBody>
            <div className="info">
              <div className="item">
                <div className="label">등록 운송장 번호</div>

                <Styled.invoiceValue>{invoiceNo}</Styled.invoiceValue>
              </div>

              <div className="item">
                <div className="label">스캔 운송장 번호</div>

                {ValueWithScanStatus}
              </div>
            </div>

            <div className="action">
              {invoiceScanStatus === "matched" && (
                <Button
                  className="btn-complete"
                  label={"송장스캔완료"}
                  size="normal"
                  theme="primary"
                  handleClick={() => setInvoiceScanDone()}
                />
              )}

              {invoiceScanStatus === "misMatched" && (
                <Button
                  className="btn-complete"
                  label={"사진촬영"}
                  size="normal"
                  theme="primary"
                  handleClick={() => {
                    showPicturePanel();
                    setVisiblePanel(false);
                  }}
                />
              )}
            </div>
          </Styled.modalBody>
        }
        onClose={() => {
          setVisiblePanel(false);
        }}
      />
    );
  }, [
    visiblePanel,
    invoiceNo,
    ValueWithScanStatus,
    invoiceScanStatus,
    setInvoiceScanDone,
    showPicturePanel,
  ]);

  return {
    showScanInvoicePanel,
    ScanInvoicePanel,
    ResponseHandlerOfSetInvoiceScanDone,
  };
}
