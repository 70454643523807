import { useCallback } from "react";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { MultiSelectInfo } from "@sellernote/_shared/src/hooks/common/useMultiSelect";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { ProblemInfo } from "@sellernote/_shared/src/types/fulfillment/inspection";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";
import TextButton from "@sellernote/_sds-v1/src/components/button/TextButton";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import { Form } from "./useProblemItemModal/useProblemItemForm";

export default function ProblemList({
  list,
  itemId,
  skuId,
  multiSelect,
  openModalAndUpdate,
}: {
  list?: ProblemInfo[];
  itemId: string;
  skuId: string;
  multiSelect: MultiSelectInfo;
  openModalAndUpdate: (formInfo: Form) => void;
}) {
  const {
    selectAll,
    unSelectAll,
    isAllSelected,
    selectionDict,
    select,
    unSelect,
  } = multiSelect;

  const handleCheck = useCallback(
    (id: number, isChecked: boolean) => {
      if (isChecked) {
        unSelect(id);
      } else {
        select(id);
      }
    },
    [select, unSelect]
  );

  // list 가 하나도 등록되어있지 않은 경우에 가장 자주쓰는 상품 데미지항목을 default 로 보여줌.
  const problemList = list?.length
    ? list
    : [
        {
          problemId: Number(`` + itemId + 0),
          problem: "상품데미지",
          skuId,
          quantity: 0,
          attachments: [],
        },
      ];

  const tableList = problemList.map((item, i) => {
    const isChecked = !!selectionDict[item.problemId];

    const hasAttachments = !!item.attachments?.length;

    return {
      rowKey: item.problemId,
      checkbox: isChecked ? (
        <Icon
          type="checkStrokeBoxChecked"
          size={1.5}
          color={COLOR.primaryBlue}
          onClick={() => handleCheck(item.problemId, isChecked)}
        />
      ) : (
        <Icon
          type="checkStrokeBox"
          size={1.5}
          color={COLOR.grayScale_700}
          onClick={() => handleCheck(item.problemId, isChecked)}
        />
      ),
      problem: item.problem,
      quantity: replaceEmptyToDash(toThousandUnitFormat(item.quantity), true),
      // attachment 유무로 입력/수정 구분.
      detail: (
        <TextButton
          theme={hasAttachments ? "gray" : "default"}
          size="14px"
          label={hasAttachments ? "수정" : "입력"}
          icon="arrowRight"
          handleClick={() => {
            openModalAndUpdate({
              problemId: item.problemId,
              problem: item.problem,
              quantity: item.quantity,
              attachments: item.attachments,
            });
          }}
        />
      ),
    };
  });

  return (
    <>
      <Table
        columnInfo={{
          checkbox: {
            label: isAllSelected ? (
              <Icon
                type="checkStrokeBoxChecked"
                size={1.5}
                color={COLOR.primaryBlue}
                onClick={() => unSelectAll()}
              />
            ) : (
              <Icon
                type="checkStrokeBox"
                size={1.5}
                color={COLOR.grayScale_700}
                onClick={() => selectAll()}
              />
            ),
            fixedWidth: 40,
          },
          problem: {
            label: "문제 명",
            fixedWidth: 104,
          },
          quantity: {
            label: "수량(PCS)",
            fixedWidth: 84,
          },
          detail: {
            label: "상세",
            fixedWidth: 100,
          },
        }}
        dataList={tableList}
        labelForNoData="문제 카테고리를 추가해주세요."
      />
    </>
  );
}
