import Layout from "containers/Layout";
import MenuListByMainPath from "containers/MenuListByMainPath";
import withRequireAuth from "hocs/withRequireAuth";

function ShippingMain() {
  return (
    <Layout
      navigator={{
        title: "출고관리",
      }}
    >
      <MenuListByMainPath mainPath="shipping" />
    </Layout>
  );
}

export default withRequireAuth(ShippingMain);
