import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";

import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import SHIPPING_QUERY from "@sellernote/_shared/src/queries/fulfillment/SHIPPING_QUERY";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { ShippingListItem } from "@sellernote/_shared/src/types/fulfillment/shipping";
import {
  ShipmentDelayFilterType,
  ShipmentStatusType,
  ShipmentTodayAndOrderFilterType,
  ShippingShipmentListItem,
  TruckCompanyType,
} from "@sellernote/_shared/src/types/fulfillment/shipping";
import {
  isBeforeToday,
  toFormattedDate,
} from "@sellernote/_shared/src/utils/common/date";
import { getPageSize } from "@sellernote/_shared/src/utils/common/etc";
import { shipmentStatus } from "@sellernote/_shared/src/utils/fulfillment/shipping";

export const truckCompany = new Map([["gogoVan", "고고밴"]]);

export type ShipmentTruckStatusType = Exclude<
  ShipmentStatusType,
  "ready" | "done"
>;

export type ShipmentTruckDetailTableItem = {
  // 도착희망시각
  dueTime: string;
  // 고객사
  customerCompany: React.ReactNode;
  // 박스 수
  boxQty: number;
  // 배차여부
  isTruckAppointed: string;
  // 출하여부
  isDone: string;
  // 상태
  status: string;
} & Pick<ShippingListItem, "dueDate" | "id" | "customerAddress">;

const PAGE_UNIT = 10;

export default function useShipmentClosingTruckDetail<
  T extends ShipmentTruckStatusType
>({
  filterForList,
  currentPage,
  handleRowClickForSignature,
}: {
  filterForList: T extends "expected"
    ? ShipmentTodayAndOrderFilterType
    : T extends "unprocessed"
    ? ShipmentDelayFilterType
    : undefined;
  currentPage: number;
  handleRowClickForSignature: (data: ShippingShipmentListItem) => void;
}) {
  const { company, status } = useParams<{
    company: TruckCompanyType;
    status: ShipmentTruckStatusType;
  }>();

  const {
    data: shipmentTruckToday,
    ResponseHandler: ResponseHandlerOfGettingShipmentTruckToday,
  } = SHIPPING_QUERY.useGetShipmentToday({
    type: "truck",
    status,
    params: {
      page: currentPage,
      perPage: PAGE_UNIT,
      filter: filterForList as ShipmentTodayAndOrderFilterType,
      truckCompany: company,
    },
  });

  const {
    data: shipmentTruckDone,
    ResponseHandler: ResponseHandlerOfGettingShipmentTruckDone,
  } = SHIPPING_QUERY.useGetShipmentDone({
    type: "truck",
    status,
    params: {
      page: currentPage,
      perPage: PAGE_UNIT,
      truckCompany: company,
    },
  });

  const {
    data: shipmentTruckDelay,
    ResponseHandler: ResponseHandlerOfGettingShipmentTruckDelay,
  } = SHIPPING_QUERY.useGetShipmentDelay({
    type: "truck",
    status,
    params: {
      page: currentPage,
      perPage: PAGE_UNIT,
      filter: filterForList as ShipmentDelayFilterType,
      truckCompany: company,
    },
  });

  const titleValue = useMemo(
    () =>
      ({
        expected: shipmentTruckToday?.total ?? 0,
        completed: shipmentTruckDone?.total ?? 0,
        unprocessed: shipmentTruckDelay?.total ?? 0,
      }[status] ?? 0),
    [
      shipmentTruckDelay?.total,
      shipmentTruckDone?.total,
      shipmentTruckToday?.total,
      status,
    ]
  );

  const getDataList = useCallback(
    (
      rawList?: ShippingShipmentListItem[]
    ): TableDataListItem<ShipmentTruckDetailTableItem>[] => {
      if (!rawList) {
        return [];
      }

      // 상태: 출하준비, 출하여부: X, 배차여부: O 행만 선택 가능
      return rawList.map((v) => {
        const isHandleRowClick =
          v.wmsStatus === "ready" && v.trucking?.isTruckAppointed;

        return {
          rowKey: v.id,
          handleRowClick: isHandleRowClick
            ? () => handleRowClickForSignature(v)
            : () => {},
          rowFontColor: isBeforeToday(v.dueDate)
            ? COLOR.pointWarning
            : undefined,
          dueDate: toFormattedDate(v.dueDate, `M/DD`),
          dueTime: toFormattedDate(v.dueDate, `HH:mm`),
          id: v.id,
          customerCompany: (
            <>
              {v.team?.company} <br />
              {v.team?.id}
            </>
          ),
          customerAddress: v.customerAddress,
          boxQty: v.trucking?.boxQty ?? 0,
          isTruckAppointed: v.trucking?.isTruckAppointed ? "○" : "✕",
          isDone: v.wmsStatus === "done" ? "○" : "✕",
          status: shipmentStatus.get(v.wmsStatus) ?? "",
        };
      });
    },
    [handleRowClickForSignature]
  );
  const dataList = useMemo(
    () =>
      ({
        expected: getDataList(shipmentTruckToday?.list),
        completed: getDataList(shipmentTruckDone?.list),
        unprocessed: getDataList(shipmentTruckDelay?.list),
      }[status] ?? []),
    [
      getDataList,
      shipmentTruckDelay?.list,
      shipmentTruckDone?.list,
      shipmentTruckToday?.list,
      status,
    ]
  );

  const pageSize = useMemo(
    () =>
      ({
        expected: getPageSize(PAGE_UNIT, shipmentTruckToday?.total),
        completed: getPageSize(PAGE_UNIT, shipmentTruckDone?.total),
        unprocessed: getPageSize(PAGE_UNIT, shipmentTruckDelay?.total),
      }[status] ?? 0),
    [
      shipmentTruckDelay?.total,
      shipmentTruckDone?.total,
      shipmentTruckToday?.total,
      status,
    ]
  );

  return {
    titleValue,
    dataList,
    pageSize,

    ResponseHandlerOfGettingShipmentTruckToday,
    ResponseHandlerOfGettingShipmentTruckDone,
    ResponseHandlerOfGettingShipmentTruckDelay,
  };
}
