import { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { MAX_UPLOAD_FILE_SIZE } from "@sellernote/_shared/src/constants/fulfillment/common";
import useClientSidePaging from "@sellernote/_shared/src/hooks/common/useClientSidePaging";
import useMultiSelect from "@sellernote/_shared/src/hooks/common/useMultiSelect";
import COMMON_QUERY from "@sellernote/_shared/src/queries/fulfillment/COMMON_QUERY";
import FILE_QUERY from "@sellernote/_shared/src/queries/fulfillment/FILE_QUERY";
import { RECEIVING_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { RETURNING_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { FileMimeType } from "@sellernote/_shared/src/types/common/common";
import { FulfillmentAttachment } from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import {
  checkFileExtension,
  checkFileSize,
} from "@sellernote/_shared/src/utils/common/file";
import TextButton from "@sellernote/_sds-v1/src/components/button/TextButton";
import Paging from "@sellernote/_sds-v1/src/components/Paging";

import { FileInfo } from "../useCameraOrGallery";
import FileUploadButton from "./FileUploadButton";
import Styled from "./index.styles";

const MAX_FILE_COUNT = 30;

const FILE_EXTENSION_LIST = ["jpg", "jpeg", "png"];

export type TempPhotoItem = {
  id: number;
  fileName: string;
  fileMimeType: FileMimeType;
  base64: string;
  fileInfo: File;
  type: "temp";
};

export type UploadedPhotoItem = FulfillmentAttachment & {
  url: string;
  type: "uploaded";
};

export default function useSelectPhotoList({
  type,
  id,
  attachmentList,
}: {
  type: "receiving" | "returning";
  id: number;
  attachmentList: FulfillmentAttachment[];
}) {
  const queryClient = useQueryClient();

  const {
    initSelectionDict,
    select,
    unSelect,
    selectAll,
    unSelectAll,
    isAllSelected,
    selectedIdList,
    selectionDict,
  } = useMultiSelect();

  const [tempPhotoList, setTempPhotoList] = useState<TempPhotoItem[]>([]);

  const [tempDeletedUploadedPhotoKeyList, setTempDeletedUploadedPhotoKeyList] =
    useState<string[]>([]);

  const [opensConfirmRemoveModal, setOpensConfirmRemoveModal] = useState(false);

  const [opensDeletePhotoErrorModal, setOpensDeletePhotoErrorModal] =
    useState(false);

  const [errorMessage, setErrorMessage] = useState<React.ReactNode>();

  const fileKeyList = attachmentList.map(({ key }) => key);

  const {
    data: uploadedPhotoUrlList,
    ResponseHandler: ResponseHandlerOfGetFileUrlList,
  } = COMMON_QUERY.useGetFileUrlList({
    key: fileKeyList,
    enabled: !!fileKeyList.length,
  });

  const { mutateAsync: mutateAsyncOfDeleteFile, isLoading } =
    FILE_QUERY.useDeleteFile();

  const uploadedPhotoList: UploadedPhotoItem[] = useMemo(() => {
    return (
      attachmentList.map((item) => ({
        ...item,
        url:
          uploadedPhotoUrlList?.find(({ key }) => key === item.key)?.url ?? "",
        type: "uploaded",
      })) ?? []
    );
  }, [attachmentList, uploadedPhotoUrlList]);

  // 사용자에게 보여지는 사진 리스트. (이미 업로드 한 사진 + 업로드 예정인 임시 사진)
  const visiblePhotoList: (TempPhotoItem | UploadedPhotoItem)[] =
    useMemo(() => {
      if (!uploadedPhotoList.length) return tempPhotoList;

      // 이미 업로드 한 사진에서 삭제 할 사진은 제외하고 보여진다.
      const filteredUploadedPhotoList = uploadedPhotoList.filter(
        ({ key }) => !tempDeletedUploadedPhotoKeyList.includes(key)
      );

      return [...filteredUploadedPhotoList, ...tempPhotoList];
    }, [tempDeletedUploadedPhotoKeyList, tempPhotoList, uploadedPhotoList]);

  const paging = useClientSidePaging<TempPhotoItem | UploadedPhotoItem>(
    10,
    visiblePhotoList
  );

  const hasVisiblePhotoList = !!visiblePhotoList.length;

  const hasTempPhotoList = !!tempPhotoList.length;

  const hasDeletedPhotoList = !!tempDeletedUploadedPhotoKeyList.length;

  const isValidDeletePhoto = hasDeletedPhotoList && hasVisiblePhotoList;

  const checkForPhotoError = useCallback(
    (fileList: FileInfo[]) => {
      const isAllowedFileExtension = fileList.some((file) =>
        checkFileExtension({
          allowedFileExtensionList: FILE_EXTENSION_LIST,
          fileName: file.fileInfo.name,
        })
      );

      const isAllowedFileSize = fileList.some((file) =>
        checkFileSize({
          allowedFileSize: MAX_UPLOAD_FILE_SIZE,
          fileSize: file.fileInfo.size,
        })
      );

      const isAllowedFileCount =
        fileList.length + tempPhotoList.length <= MAX_FILE_COUNT;

      if (!isAllowedFileExtension) {
        setErrorMessage(
          "업로드 가능한 파일 확장자(형식)은 JPG, JPEG, PNG 입니다. 확인 후 다시 업로드해 주세요."
        );

        return true;
      }

      if (!isAllowedFileSize) {
        setErrorMessage(
          <>
            업로드 가능한 파일 최대 크기는 “10MB”입니다. <br />
            확인 후 다시 업로드해 주세요.
          </>
        );

        return true;
      }

      if (!isAllowedFileCount) {
        setErrorMessage("최대 30장 업로드 가능합니다.");

        return true;
      }

      return false;
    },
    [tempPhotoList.length]
  );

  const handlePhotoAdd = useCallback(
    (fileInfoList: FileInfo[]) => {
      const hasError = checkForPhotoError(fileInfoList);

      if (hasError) return;

      const newTempPhotoList: TempPhotoItem[] = fileInfoList.map((file, i) => ({
        type: "temp",
        id:
          (hasTempPhotoList
            ? Math.max(...tempPhotoList.map(({ id }) => id))
            : 0) +
          i +
          1,
        ...file,
      }));

      setTempPhotoList((prev) => [...prev, ...newTempPhotoList]);
    },
    [checkForPhotoError, hasTempPhotoList, tempPhotoList]
  );

  // 이미 업로드 한 파일은 tempDeletedUploadedPhotoList에 저장했다가 수정버튼 누르면 일괄 삭제. (사용자에게는 제외하고 보여짐)
  // 업로드 하기 이전 파일은 tempPhotoList에서 제거.
  const handlePhotoRemove = useCallback(() => {
    const uploadedPhotoIdList = uploadedPhotoList.map(({ id }) => id);

    const hasUploadedPhotoList = selectedIdList.some((id) =>
      uploadedPhotoIdList.includes(id)
    );

    if (hasUploadedPhotoList) {
      const selectedUploadedPhotoKeyList = uploadedPhotoList
        .filter(({ id }) => selectedIdList.includes(id))
        .map(({ key }) => key);

      setTempDeletedUploadedPhotoKeyList((prev) => [
        ...prev,
        ...selectedUploadedPhotoKeyList,
      ]);
    }

    setTempPhotoList(
      tempPhotoList.filter(({ id }) => !selectedIdList.includes(id))
    );

    setOpensConfirmRemoveModal(false);

    // 전체 이미지를 삭제하는 경우 페이지를 0으로 초기화
    if (isAllSelected) {
      paging.setPage(0);
    }
  }, [isAllSelected, paging, selectedIdList, tempPhotoList, uploadedPhotoList]);

  const deletePhotoList = ({ onSuccess }: { onSuccess?: () => void }) => {
    const deletePromises = tempDeletedUploadedPhotoKeyList.map((key) =>
      mutateAsyncOfDeleteFile({ key })
    );

    Promise.all(deletePromises)
      .then(() => {
        onSuccess?.();
      })
      .catch((error) => {
        setOpensDeletePhotoErrorModal(true);
      });
  };

  const resetPhotoList = () => {
    setTempPhotoList([]);
    setTempDeletedUploadedPhotoKeyList([]);
  };

  // select box 상태 초기화. (페이지 단위)
  useEffect(() => {
    if (!paging.pageData.length) return;

    initSelectionDict(paging.pageData.map(({ id }) => id));
  }, [initSelectionDict, paging.pageData]);

  const LoadingOfDeletePhoto = useMemo(() => {
    return <Loading active={isLoading} />;
  }, [isLoading]);

  const SelectPhotoList = useMemo(() => {
    return (
      <Styled.container>
        {LoadingOfDeletePhoto}

        <Styled.labelAndButtonContainer>
          <span className="label">
            사진 <em>(*필수)</em>
          </span>

          <div className="button-container">
            <FileUploadButton
              label="컨디션 촬영"
              type="camera"
              onFileLoaded={handlePhotoAdd}
              isMultiple
            />

            <FileUploadButton
              label="파일 업로드"
              type="gallery"
              onFileLoaded={handlePhotoAdd}
              isMultiple
            />
          </div>
        </Styled.labelAndButtonContainer>

        {hasVisiblePhotoList && (
          <Styled.allSelectAndDeleteContainer>
            <div
              className="all-select"
              onClick={() => (isAllSelected ? unSelectAll() : selectAll())}
            >
              {isAllSelected ? (
                <Icon
                  type="checkStrokeBoxChecked"
                  size={1.5}
                  color={COLOR.primaryBlue}
                />
              ) : (
                <Icon
                  type={"checkStrokeBox"}
                  size={1.5}
                  color={COLOR.grayScale_700}
                />
              )}
              전체선택
            </div>

            <TextButton
              label="삭제"
              theme={selectedIdList.length ? "danger" : "gray"}
              size="12px"
              handleClick={() => setOpensConfirmRemoveModal(true)}
              disabled={!selectedIdList.length}
            />
          </Styled.allSelectAndDeleteContainer>
        )}

        {hasVisiblePhotoList ? (
          <Paging
            isZeroBasedPage
            currentPage={paging.page}
            pageSize={paging.pageSize}
            handleClickPage={paging.setPage}
            currentPageData={
              <Styled.photoList>
                {paging.pageData.map((item) => {
                  const isChecked = !!selectionDict[item.id];

                  return (
                    <li
                      key={item.id}
                      onClick={() =>
                        isChecked ? unSelect(item.id) : select(item.id)
                      }
                    >
                      {isChecked ? (
                        <Icon
                          type="checkStrokeBoxChecked"
                          size={1.5}
                          color={COLOR.primaryBlue}
                        />
                      ) : (
                        <Icon
                          type={"checkStrokeBox"}
                          size={1.5}
                          color={COLOR.grayScale_700}
                        />
                      )}

                      <Styled.thumbnail
                        imageUrl={item.type === "temp" ? item.base64 : item.url}
                      />
                    </li>
                  );
                })}
              </Styled.photoList>
            }
          />
        ) : (
          <Styled.noPhotoList>
            촬영 또는 파일 업로드를 통해
            <br />
            이미지를 올려주세요.
          </Styled.noPhotoList>
        )}

        <Modal
          uiType="titleOnly"
          active={opensConfirmRemoveModal}
          title={`해당 사진을 삭제하시겠습니까?`}
          actionPositive={{
            label: "확인",
            handleClick: handlePhotoRemove,
          }}
          actionNegative={{
            label: "취소",
            handleClick: () => setOpensConfirmRemoveModal(false),
          }}
        />

        <Modal
          uiType="titleOnly"
          active={opensDeletePhotoErrorModal}
          title={`파일 삭제 중에 오류가 발생했습니다.`}
          actionPositive={{
            label: "확인",
            handleClick: () => {
              setOpensDeletePhotoErrorModal(false);

              type === "receiving"
                ? queryClient.invalidateQueries(
                    RECEIVING_QUERY_KEY_GEN.getPDAReceivingDetail({ id })
                  )
                : queryClient.invalidateQueries(
                    RETURNING_QUERY_KEY_GEN.getPDAReturningDetail({
                      id,
                    })
                  );
            },
          }}
        />

        <Modal
          uiType="titleOnly"
          active={!!errorMessage}
          title={errorMessage}
          actionPositive={{
            label: "확인",
            handleClick: () => {
              setErrorMessage(undefined);
            },
          }}
        />

        {ResponseHandlerOfGetFileUrlList}
      </Styled.container>
    );
  }, [
    LoadingOfDeletePhoto,
    ResponseHandlerOfGetFileUrlList,
    errorMessage,
    handlePhotoAdd,
    handlePhotoRemove,
    hasVisiblePhotoList,
    id,
    isAllSelected,
    opensConfirmRemoveModal,
    opensDeletePhotoErrorModal,
    paging,
    queryClient,
    select,
    selectAll,
    selectedIdList.length,
    selectionDict,
    type,
    unSelect,
    unSelectAll,
  ]);

  return {
    SelectPhotoList,
    tempPhotoList,
    resetPhotoList,
    deletePhotoList,
    hasDeletedPhotoList,
    isValidDeletePhoto,
  };
}
