import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import {
  FileDomainType,
  FulfillmentAttachment,
} from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import { omitWithEllipsis } from "@sellernote/_shared/src/utils/common/string";
import { getTeamLabelForBofulWorker } from "@sellernote/_shared/src/utils/fulfillment/common";
import { getPictureTypeLabelByDomainType } from "@sellernote/_shared/src/utils/fulfillment/receiving";
import { getFormattedReturningId } from "@sellernote/_shared/src/utils/fulfillment/returning";
import ConfirmWrapper from "@sellernote/_sds-v1/src/components/ConfirmWrapper";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import usePicturePanelForConfirm from "hooks/returning/usePicturePanelForConfirm";
import useResetInvoiceInfo from "hooks/returning/useResetInvoiceInfo";
import useScanInvoicePanelForConfirm from "hooks/returning/useScanInvoicePanelForConfirm";
import { returningActions } from "modules/returning";
import { useAppSelector } from "store";

import Styled from "./index.styles";

function ItemToScan({
  order,
  domainType,
  invoiceNo,
  attachments,
  isInvoiceMatched,
  returningId,
}: {
  order: number;
  domainType: FileDomainType;
  invoiceNo: string;
  attachments: FulfillmentAttachment[];
  isInvoiceMatched: boolean;
  returningId: number;
}) {
  const label = useMemo(() => {
    return getPictureTypeLabelByDomainType(domainType);
  }, [domainType]);

  const { showPicturePanel, PicturePanel, ResultHandlerOfPicturePanel } =
    usePicturePanelForConfirm({ label, domainType, returningId });

  const {
    showScanInvoicePanel,
    ScanInvoicePanel,
    ResponseHandlerOfSetInvoiceScanDone,
  } = useScanInvoicePanelForConfirm({
    invoiceNo,
    showPicturePanel,
    returningId,
  });

  const {
    needToResetInvoiceInfo,
    ResponseHandlerOfResetInvoiceInfo,
    resetInvoiceInfo,
  } = useResetInvoiceInfo({ returningId, isInvoiceMatched, attachments });

  return (
    <>
      {needToResetInvoiceInfo ? (
        <ConfirmWrapper
          confirmMessageTitle={"송장스캔"}
          confirmMessageBody={
            <>
              이미 스캔한 정보가 있습니다.
              <br />
              스캔을 다시하면 기존 정보가 삭제됩니다.
              <br />
              다시 스캔하시겠습니까?
            </>
          }
          confirmModalUiType="content"
          className="reset-confirm"
        >
          <Button
            theme="secondary"
            size="block"
            label={`${order}. ${label}`}
            handleClick={() =>
              resetInvoiceInfo({
                returningId,
                callbackAfterReset: showScanInvoicePanel,
              })
            }
          />
        </ConfirmWrapper>
      ) : (
        <Button
          theme="secondary"
          size="block"
          label={`${order}. ${label}`}
          handleClick={showScanInvoicePanel}
        />
      )}

      {ScanInvoicePanel}

      {PicturePanel}

      {ResultHandlerOfPicturePanel}

      {ResponseHandlerOfSetInvoiceScanDone}

      {ResponseHandlerOfResetInvoiceInfo}
    </>
  );
}

function ItemToTakePicture({
  order,
  domainType,
  returningId,
}: {
  order: number;
  domainType: FileDomainType;
  returningId: number;
}) {
  const label = useMemo(() => {
    return getPictureTypeLabelByDomainType(domainType);
  }, [domainType]);

  const { showPicturePanel, PicturePanel, ResultHandlerOfPicturePanel } =
    usePicturePanelForConfirm({ label, domainType, returningId });

  return (
    <>
      <Button
        theme="secondary"
        size="block"
        label={`${order}. ${label}`}
        handleClick={showPicturePanel}
      />

      {PicturePanel}

      {ResultHandlerOfPicturePanel}
    </>
  );
}

function PictureType() {
  const match = useRouteMatch<{ id: string }>();
  const {
    params: { id },
  } = match;
  const dispatch = useDispatch();

  const { dataReturning } = useAppSelector((state) => {
    return {
      dataReturning: state.returning.GET_DETAIL?.data,
    };
  });

  useEffect(() => {
    dispatch(returningActions.GET_DETAIL({ id: +id }));

    return () => {
      dispatch(returningActions.INIT_GET_DETAIL({}));
    };
  }, [dispatch, id]);

  const PictureItemList = useMemo(() => {
    let order = 1;

    if (!dataReturning?.returning) return null;

    if (dataReturning?.returning.delivery === "truckRequest") {
      return (
        <>
          <ItemToTakePicture
            returningId={+id}
            order={order++}
            domainType={"cargo"}
          />
          <ItemToTakePicture
            returningId={+id}
            order={order++}
            domainType={"shippingReceipt"}
          />
          <ItemToTakePicture
            returningId={+id}
            order={order++}
            domainType={"packing"}
          />
          <ItemToTakePicture
            returningId={+id}
            order={order++}
            domainType={"damages"}
          />
        </>
      );
    } else {
      return (
        <>
          {dataReturning?.returning.invoiceNo && (
            <ItemToScan
              order={order++}
              domainType={"invoice"}
              invoiceNo={dataReturning?.returning.invoiceNo ?? ""}
              attachments={dataReturning?.attachment ?? []}
              isInvoiceMatched={!!dataReturning?.returning.isInvoiceMatched}
              returningId={+id}
            />
          )}

          <ItemToTakePicture
            returningId={+id}
            order={order++}
            domainType={"packing"}
          />
          <ItemToTakePicture
            returningId={+id}
            order={order++}
            domainType={"damages"}
          />
        </>
      );
    }
  }, [dataReturning?.returning, dataReturning?.attachment, id]);

  if (!dataReturning) return null;

  return (
    <>
      <Layout
        navigator={{
          title: `${getFormattedReturningId(
            dataReturning?.returning
          )} / ${getTeamLabelForBofulWorker({
            id: dataReturning?.returning.team?.id,
            name: omitWithEllipsis({
              src: dataReturning?.returning.team?.name,
              maxLength: 10,
              ellipsis: "...",
            }),
            company: omitWithEllipsis({
              src: dataReturning?.returning.team?.company,
              maxLength: 10,
              ellipsis: "...",
            }),
          })}`,
        }}
      >
        <Styled.container>
          <div className="title">촬영할 내용을 선택해주세요.</div>

          {PictureItemList}
        </Styled.container>
      </Layout>
    </>
  );
}

export default withRequireAuth(PictureType);
