import { BofulLocation } from "@sellernote/_shared/src/types/fulfillment/common";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";

import useSearchLocation from "./useSearchLocation";
import Styled from "./index.styles";

export default function SearchLocation({
  label,
  locationListByType,
  selectedLocation,
  onLocationSelect,
  onReset,
}: {
  label: string | undefined;
  locationListByType: BofulLocation[] | undefined;
  selectedLocation: BofulLocation | undefined;
  onLocationSelect: (v: BofulLocation) => void;
  onReset: () => void;
}) {
  const {
    selectedLocationBarcode,
    searchTerm,
    setSearchTerm,

    isVisibleOptionList,
    OptionList,
  } = useSearchLocation({
    locationListByType,
    selectedLocation,
    onLocationSelect,
    onReset,
  });

  return (
    <Styled.searchLocation>
      <InputText
        label={label}
        borderType="outline"
        valueType="string"
        placeholder="입력해주세요."
        value={searchTerm || selectedLocationBarcode}
        setValue={setSearchTerm}
        icon="location"
        clearCB={onReset}
      />

      {isVisibleOptionList && !!OptionList.length && (
        <Styled.optionListWrapper>
          <Styled.optionList>{OptionList}</Styled.optionList>
        </Styled.optionListWrapper>
      )}
    </Styled.searchLocation>
  );
}
