import { useEffect, useState } from "react";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { useDeviceDetect } from "@sellernote/_shared/src/utils/common/hook";

import { AccordionItem, AccordionType } from ".";
import Styled from "./index.styles";

export default function Item({
  type,
  data,
}: {
  type: AccordionType;
  data: AccordionItem;
}) {
  const { isMobile } = useDeviceDetect();

  const [status, setStatus] = useState(data.status);

  useEffect(() => {
    setStatus(data.status);
  }, [data.status]);

  function toggleStatus() {
    switch (status) {
      case "closed": {
        setStatus("opened");
        break;
      }
      case "opened": {
        setStatus("closed");
        break;
      }
    }
  }

  if (type === "information") {
    return (
      <Styled.accordionItemForInformationType status={status}>
        <div className="header" onClick={toggleStatus}>
          <div className="title">{data.title}</div>

          {status === "opened" && (
            <Icon type="arrowDown" color={COLOR.grayScale_600} size={1} />
          )}

          {status === "closed" && (
            <Icon type="arrowRight" color={COLOR.grayScale_600} size={1} />
          )}

          {status === "disabled" && (
            <Icon type="arrowRight" color={COLOR.grayScale_300} size={1} />
          )}
        </div>
        {status === "opened" && <div className="desc">{data.desc}</div>}
      </Styled.accordionItemForInformationType>
    );
  }

  if (type === "QnA") {
    return (
      <Styled.accordionItemForQnAType status={status}>
        <div className="header" onClick={toggleStatus}>
          {status === "closed" && (
            <Icon
              type="arrowRight"
              color={COLOR.primaryBlue}
              size={isMobile ? 1.5 : 2.5}
            />
          )}
          {status === "opened" && (
            <Icon
              type="arrowDown"
              color={COLOR.primaryBlue_60}
              size={isMobile ? 1.5 : 2.5}
            />
          )}
          <div className="title">{data.title}</div>
        </div>

        {status === "opened" && <div className="desc">{data.desc}</div>}
      </Styled.accordionItemForQnAType>
    );
  }

  return null;
}
