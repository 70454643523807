import { FC } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";

/**
 * 파트타이머는 접근할 수 없는 페이지 라우팅
 * 이전 페이지에서 from 값을 넣어주어야 함
 */
export default function PartTimerCannotAccessRoute({
  component,
  path,
  exact,
}: {
  component: FC;
  path: string;
  exact: boolean;
}) {
  const authorityOfCurrentUser = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  )?.authority;

  const PREVIOUS_PATHNAME = useLocation<{ from: string }>().state?.from;

  const hasAuthority = authorityOfCurrentUser !== "partTimer";

  return hasAuthority ? (
    <Route path={path} exact={exact} component={component} />
  ) : (
    <Redirect to={PREVIOUS_PATHNAME} />
  );
}
