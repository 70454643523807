import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { ManagementKind } from "@sellernote/_shared/src/types/fulfillment/inventory";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";

import Styled from "./index.styles";

type ProblemPropsByType =
  | {
      type: "receiving";
      hasMultiLocation: boolean;
      openInspectionCompleteToast: () => void;
      isCompletedInspection: boolean;
    }
  | {
      type: "returning";
    };

export default function ProblemInput({
  itemId,
  skuId,
  inspectionCount,
  problemCount,
  managementKind,
  managementDate,
  disabled,
  ...propsByType
}: {
  itemId: number;
  skuId: number;
  inspectionCount: number;
  problemCount?: number;
  managementKind: ManagementKind | undefined;
  managementDate: string | undefined;
  disabled: boolean;
} & ProblemPropsByType) {
  const history = useHistory();

  const setCanNotLeavePage = useSetRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );

  const hasProblemItem = !!problemCount;

  return (
    <Styled.container hasProblemItem={hasProblemItem}>
      <div>{replaceEmptyToDash(toThousandUnitFormat(problemCount), true)}</div>

      <Button
        label={hasProblemItem ? "수정" : "입력"}
        theme={hasProblemItem ? "secondary" : "dangerStroke"}
        size="small"
        handleClick={() => {
          if (propsByType.type === "receiving") {
            const {
              hasMultiLocation,
              openInspectionCompleteToast,
              isCompletedInspection,
            } = propsByType;
            if (hasMultiLocation && !isCompletedInspection) {
              openInspectionCompleteToast();
              return;
            }
          }

          // 비정상 리스트 페이지에서는 뒤로가기 확인없이 가능하도록 처리.
          setCanNotLeavePage(false);

          // 검수화면으로 되돌아왔을 때 해당 데이터를 사용하기 위해 query string에 저장
          history.push(
            `${history.location.pathname}?skuId=${skuId}&itemId=${itemId}&inspectionCount=${inspectionCount}`
          );

          history.push(
            `${
              history.location.pathname
            }/${skuId}/${itemId}/problem-item?inspectionCount=${inspectionCount}
            ${
              managementKind
                ? `&managementKind=${managementKind}&managementDate=${managementDate}`
                : ""
            }            
            `
          );
        }}
        disabled={disabled}
      />
    </Styled.container>
  );
}
