import Styled from "./index.styles";

export default function EmptyData({
  type,
  isSearching,
}: {
  type: "sku" | "location";
  isSearching: boolean;
}) {
  return (
    <Styled.emptyData>
      {isSearching
        ? `검색 결과가 없습니다.`
        : `${
            type === "sku" ? "SKU ID, 상품 바코드" : "LOC ID"
          }를 검색 또는 스캔해주세요.`}
    </Styled.emptyData>
  );
}
