import React, { ReactNode, useState } from "react";
import Slider, { CustomArrowProps } from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { IconType } from "@sellernote/_shared/src/componentsToMoveToV1/Icon/DATA";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { isNotEmptyObjectOrArray } from "@sellernote/_shared/src/utils/common/etc";
import { useCheckIsTablet } from "@sellernote/_shared/src/utils/common/hook";
import { getLastStringAfterSplit } from "@sellernote/_shared/src/utils/common/string";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

import Styled from "./index.styles";

interface ImageDetail {
  label: string;
  value: ReactNode;
}

interface CustomArrow extends CustomArrowProps {
  iconType: IconType;
}

const sliderSettings = {
  dots: true,
  nextArrow: <CustomArrowIcon iconType="arrowRight" />,
  prevArrow: <CustomArrowIcon iconType="arrowLeft" />,
};

function CustomArrowIcon({ className, onClick, iconType }: CustomArrow) {
  const { isTablet } = useCheckIsTablet();

  return (
    <div className={className} onClick={onClick}>
      <Icon
        type={iconType}
        size={isTablet ? 1.5 : 2.5}
        color={COLOR.primaryBlue}
      />
    </div>
  );
}

function ImageTitle({ imageTitle }: { imageTitle: string | undefined }) {
  if (!imageTitle) return null;

  return <div className="slider-title">{imageTitle}</div>;
}

function ImageDetails({
  imageDetails,
  hasImagePaths,
}: {
  imageDetails: ImageDetail[] | undefined;
  hasImagePaths: boolean;
}) {
  if (!imageDetails) return null;

  return (
    <Styled.imageDetailsContainer hasImagePaths={hasImagePaths}>
      {imageDetails.map((imageDetail) => (
        <ListItem
          key={imageDetail.label}
          type="withContentAlignRight"
          label={imageDetail.label}
          value={imageDetail.value}
        />
      ))}
    </Styled.imageDetailsContainer>
  );
}

function ImageSlider({
  hasImagePaths,
  imagePaths,
  initialSlideIndex,
  onIndexChange,
}: {
  hasImagePaths: boolean;
  imagePaths: string[];
  initialSlideIndex: number | undefined;
  onIndexChange?: (index: number) => void;
}) {
  if (!hasImagePaths) return null;

  return (
    <Slider
      {...sliderSettings}
      initialSlide={initialSlideIndex || 0}
      beforeChange={(_, index) => onIndexChange?.(index)}
    >
      {imagePaths.map((imagePath) => (
        <img
          key={imagePath}
          src={imagePath}
          alt={
            getLastStringAfterSplit({ str: imagePath, separator: "/" }).split(
              "."
            )[0]
          }
        />
      ))}
    </Slider>
  );
}

/**
 * 사용하는 어플리케이션에서 slick-carousel의 global css스타일이 import 되 있어야 사용할 수 있다.
 * next.js에서는 컴포넌트에서 global style을 import하는 것이 금지되어 있어 사용하는 어플리케이션에서 아래 2개 스타일을 import하는 식으로 사용 중 (개선 방법은 고민중이다)
 * import "slick-carousel/slick/slick-theme.css";
 * import "slick-carousel/slick/slick.css";
 */
export default function ImageSliderModal({
  active,
  title,
  imageTitle,
  imageDetails: imageDetailList = [],
  imagePaths: imagePathList = [],
  initialSlideIndex,
  onClose,
  className,
  imageKeyList = [],
  onDelete,
  onAllImageListDelete,
}: {
  active: boolean;
  title: React.ReactNode;
  imageTitle?: string;
  imageDetails?: ImageDetail[];
  imagePaths?: string[];
  initialSlideIndex?: number;
  onClose: () => void;
  className?: string;
  imageKeyList?: string[];
  onDelete?: (key: string) => void;
  onAllImageListDelete?: (keyList: string[]) => void;
}) {
  const hasImagePath = isNotEmptyObjectOrArray(imagePathList);
  const hasMultipleImage = imagePathList.length > 1;

  const [currentIndex, setCurrentIndex] = useState(initialSlideIndex ?? 0);

  return (
    <Modal
      className={`${className || ""} image-slider-modal`}
      uiType="contentWithCustomBody"
      active={active}
      title={title}
      body={
        <Styled.sliderContainer isNotEmptyImage={hasImagePath}>
          <ImageTitle imageTitle={imageTitle} />
          {hasMultipleImage && onAllImageListDelete && (
            <Styled.deleteAllButtonWrapper>
              <Button
                theme={"dangerStroke"}
                size={"small"}
                label={"전체 삭제"}
                handleClick={() => onAllImageListDelete?.(imageKeyList)}
              />
            </Styled.deleteAllButtonWrapper>
          )}
          <ImageDetails
            imageDetails={imageDetailList}
            hasImagePaths={hasImagePath}
          />
          <ImageSlider
            hasImagePaths={hasImagePath}
            imagePaths={imagePathList}
            initialSlideIndex={initialSlideIndex}
            onIndexChange={setCurrentIndex}
          />
          {onDelete && imageKeyList && (
            <Styled.deleteButtonWrapper hasMultipleImage={hasMultipleImage}>
              <Button
                theme="danger"
                size="normal"
                label="삭제"
                handleClick={() => {
                  onDelete(imageKeyList[currentIndex]);
                  setCurrentIndex(0);
                }}
              />
            </Styled.deleteButtonWrapper>
          )}
        </Styled.sliderContainer>
      }
      onClose={onClose}
    />
  );
}
