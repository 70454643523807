import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";

import { SkuToSelect } from "../useSelectSkuModal";
import Styled from "./index.styles";

export default function OptionList({
  list,
  onSkuSelect,
}: {
  list: SkuToSelect[];
  onSkuSelect: (sku: SkuToSelect) => () => void;
}) {
  return (
    <Styled.optionListWrapper>
      <Styled.optionList>
        {list.map(({ id, barCode, managementKind }) => (
          <Styled.option
            key={id}
            onClick={onSkuSelect({ id, barCode, managementKind })}
          >
            {getFormattedSingleSkuId(id)}
            {barCode && ` / ${barCode}`}
          </Styled.option>
        ))}
      </Styled.optionList>
    </Styled.optionListWrapper>
  );
}
