import { useCallback } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useRouteMatch } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { ReceivingWorker } from "@sellernote/_shared/src/types/fulfillment/receiving";

export default function useCandidateSelector({
  deActivate,
  existedWorkerList,
}: {
  deActivate: () => void;
  existedWorkerList: ReceivingWorker[] | undefined;
}) {
  const match = useRouteMatch<{ id: string }>();
  const {
    params: { id },
  } = match;

  const [selectedCandidate, setSelectedCandidate] =
    useState<InputSelectOption<number>>();

  const workerList = useRecoilValue(FULFILLMENT_COMMON_ATOMS.WORKER_LIST);

  const queyClient = useQueryClient();

  const {
    mutate: addWorkerToInspection,
    ResponseHandler: ResponseHandlerOfAddingWorkerToInspection,
  } = RECEIVING_QUERY.useAddWorkerToInspection({
    receivingId: Number(id),
    workerId: selectedCandidate?.value ?? 0,
  });

  const candidateOptionList: InputSelectOption<number>[] = useMemo(() => {
    return workerList
      .filter((v) => !existedWorkerList?.some((ew) => Number(ew.id) === v.id))
      .map((v) => ({
        value: v.id,
        label: v.name,
      }));
  }, [workerList, existedWorkerList]);

  const handleCandidateSelect = useCallback(() => {
    if (!selectedCandidate) return;

    addWorkerToInspection(undefined, {
      onSuccess: () => {
        deActivate();

        queyClient.invalidateQueries(
          RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
            receivingId: Number(id),
          })
        );
      },
    });
  }, [addWorkerToInspection, deActivate, id, queyClient, selectedCandidate]);

  return {
    candidateOptionList,
    selectedCandidate,
    setSelectedCandidate,

    handleCandidateSelect,

    ResponseHandlerOfAddingWorkerToInspection,
  };
}
