import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { noop } from "@sellernote/_shared/src/utils/common/etc";

import useCameraOrGallery, { FileInfo } from "hooks/common/useCameraOrGallery";

export default function FileUploadButton({
  label,
  type,
  onFileLoaded,
  isMultiple,
}: {
  label: string;
  type: "camera" | "gallery";
  onFileLoaded: (fileInfoList: FileInfo[]) => void;
  isMultiple?: boolean;
}) {
  const [TriggerForCameraOrGallery] = useCameraOrGallery({
    triggerComponent: (
      <Button
        label={label}
        size="small"
        theme={type === "camera" ? "secondary" : "tertiary"}
        handleClick={noop}
      />
    ),
    onFileLoaded,
    isGalleryView: type === "gallery",
    isMultiple,
  });

  return TriggerForCameraOrGallery;
}
