import InputText from "@sellernote/_sds-v1/src/components/input/InputText";

import { ManagementDateForm } from "..";

export default function QuantityInput({
  formState,
  handelFormUpdate,
}: {
  formState: ManagementDateForm;
  handelFormUpdate: (newManagementDateForm: ManagementDateForm) => void;
}) {
  return (
    <InputText
      borderType="outline"
      valueType="int"
      label={"수량"}
      placeholder="입력해주세요."
      value={formState.quantity}
      setValue={(v) =>
        handelFormUpdate({
          ...formState,
          quantity: v,
        })
      }
      width={6.875}
    />
  );
}
