import Layout from "containers/Layout";
import MenuListByMainPath from "containers/MenuListByMainPath";
import withRequireAuth from "hocs/withRequireAuth";

function ReturningMain() {
  return (
    <Layout
      navigator={{
        title: "반품관리",
      }}
    >
      <MenuListByMainPath mainPath="returning" />
    </Layout>
  );
}

export default withRequireAuth(ReturningMain);
