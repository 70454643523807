import React, { useRef } from "react";

import useTable, {
  RowToHighlightRef,
  TableProps,
  TablePropsWithRef,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { useWindowResize } from "@sellernote/_shared/src/utils/common/hook";

import TableBody from "./TableBody";
import TableHead from "./TableHead";
import Styled from "./index.styles";

const Table = <T extends unknown>(
  {
    columnGroupInfo,
    columnInfo,
    dataList,
    rowInfoToHighlight,
    rowMinHeight,
    isHorizontalScrollable,
    disabledRowHoverBgColor,
    labelForNoData,
    className,
    isLoadingTableData,
    ...propsByType
  }: TableProps<T>,
  ref: RowToHighlightRef
) => {
  const {
    tableRef,
    tableLeftEndRef,
    tableRightEndRef,
    tableHasArriveLeftEnd,
    tableHasArriveRightEnd,
    onScrollTable,
    isBlinkOnHighlightRow,
    isOverflowed,
    columnGroupSizes,
  } = useTable({
    columnGroupInfo,
    columnInfo,
    dataList,
    rowInfoToHighlight,
    rowMinHeight,
    isHorizontalScrollable,
    disabledRowHoverBgColor,
    className,
    ...propsByType,
  });

  const tableContainerRef = useRef<HTMLDivElement>(null);

  // windowSize 가 바뀔때 리렌더링되어 ref관련 사이즈를 재계산하기 위함
  useWindowResize();

  const tableWidth = (() => {
    if (!isHorizontalScrollable) return;

    const tableContainerWidth =
      tableContainerRef.current?.getClientRects()[0]?.width || 0;

    // 가로 스크롤이 가능한 테이블인 경우만 tableContainer만큼의 고정 tableWidth를 부여
    return `${tableContainerWidth}px`;
  })();

  return (
    <Styled.container
      className={`${className || ""} table`}
      isOverflowed={isOverflowed}
      tableWidth={tableWidth}
      columnGroupSizes={columnGroupSizes}
      ref={tableContainerRef}
    >
      {!tableHasArriveLeftEnd && <div className="curtain left" />}

      {!tableHasArriveRightEnd && <div className="curtain right" />}

      <table
        ref={tableRef}
        {...(isOverflowed
          ? {
              onScroll: () => {
                onScrollTable();
              },
            }
          : {})}
      >
        <TableHead
          columnInfo={columnInfo}
          columnGroupInfo={columnGroupInfo}
          tableLeftEndRef={tableLeftEndRef}
          tableRightEndRef={tableRightEndRef}
          isOverflowed={isOverflowed}
        />

        <TableBody
          // 이하 `Table` 컴포넌트의 props
          columnGroupInfo={columnGroupInfo}
          columnInfo={columnInfo}
          dataList={dataList}
          rowInfoToHighlight={rowInfoToHighlight}
          rowMinHeight={rowMinHeight}
          isHorizontalScrollable={isHorizontalScrollable}
          disabledRowHoverBgColor={disabledRowHoverBgColor}
          labelForNoData={labelForNoData}
          className={className}
          rowToHighlightRef={ref}
          {...propsByType}
          // 이하 `useTable` hook의 return 값
          tableRef={tableRef}
          isBlinkOnHighlightRow={isBlinkOnHighlightRow}
          isOverflowed={isOverflowed}
          isLoadingTableData={isLoadingTableData}
        />
      </table>
    </Styled.container>
  );
};

export default React.forwardRef(Table) as <T extends unknown>(
  props: TablePropsWithRef<T>
) => React.ReactElement;
