import withRequireAuth from "hocs/withRequireAuth";
import useShipmentClosingTruck from "hooks/shipping/useShipmentClosingTruck";

import ShipmentClosingLayout from "../../ShipmentClosingLayout";
import Styled from "./index.styles";

function ShippingShipmentClosingTruckCompany() {
  const {
    TodayExpectedShipmentSummary,
    CompletedShipmentSummary,
    UnprocessedShipmentSummary,

    ResponseHandlerOfGettingShipmentTruckSummary,
  } = useShipmentClosingTruck();

  return (
    <ShipmentClosingLayout title="화물차량" selectType="운송사">
      <Styled.container>
        {TodayExpectedShipmentSummary}
        {CompletedShipmentSummary}
        {UnprocessedShipmentSummary}
      </Styled.container>

      {ResponseHandlerOfGettingShipmentTruckSummary}
    </ShipmentClosingLayout>
  );
}

export default withRequireAuth(ShippingShipmentClosingTruckCompany);
