import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

interface ContainerProps {
  width?: number;
  height?: number;
  hasError: boolean;
}

const container = styled.div`
  width: ${(props: ContainerProps) =>
    props.width ? `${props.width}px` : "100%"};
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > .label {
    ${setFontStyle("SubHead4")};
    color: ${COLOR.grayScale_800};
    margin-bottom: 2px;
  }

  > textarea {
    resize: none;
    padding: 8px;
    border-radius: 2px;
    border: 1px solid
      ${({ hasError }) =>
        hasError ? COLOR.pointWarning_60 : COLOR.grayScale_300};
    height: ${(props: ContainerProps) =>
      props.height ? `${props.height}px` : "100%"};
    width: 100%;
    ${setFontStyle("Body7")};
    color: ${COLOR.grayScale_800};

    &::placeholder {
      ${setFontStyle("Body7")};
      color: ${COLOR.grayScale_300};
    }

    &::-ms-clear {
      display: none;
    }
  }

  .error-message {
    ${setFontStyle("Caption")};
    color: ${COLOR.pointWarning};
  }
`;

const postFix = styled.div`
  position: absolute;
  top: calc(50% - 8px);
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default {
  container,
  postFix,
};
