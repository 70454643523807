import styled from "styled-components";

const tempInput = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default {
  tempInput,
};
