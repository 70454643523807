import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { WarehousingStatusLabel } from "@sellernote/_shared/src/types/fulfillment/common";

const itemStatus = styled.span<{
  statusLabel: WarehousingStatusLabel | undefined;
}>`
  font-weight: 700;
  color: ${({ statusLabel }) =>
    statusLabel === "정상" ? COLOR.primaryBlue : COLOR.pointWarning};
  text-align: start;
`;

const unverifiedSkuStatus = styled.span`
  font-weight: 700;
  color: ${COLOR.pointWarning};
`;

export default {
  itemStatus,
  unverifiedSkuStatus,
};
