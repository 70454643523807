import { useRecoilValue } from "recoil";

import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";

import ReportReceivingProblem from "containers/ReportReceivingProblem";

import CloseAsNormal from "./CloseAsNormal";
import Styled from "./index.styles";

function CloseWarehousing({ receivingId }: { receivingId: number }) {
  const isCurrentManagerReceivingManager = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.IS_CURRENT_MANAGER_RECEIVING_MANAGER
  );
  const isCompletedWarehousingWithMatchedCount = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.IS_COMPLETED_WAREHOUSING_WITH_MATCHED_COUNT
  );

  const isDisabled = !isCurrentManagerReceivingManager;

  return (
    <Styled.closeWarehousing>
      {isCompletedWarehousingWithMatchedCount ? (
        <CloseAsNormal receivingId={receivingId} isDisabled={isDisabled} />
      ) : (
        <ReportReceivingProblem
          receivingId={receivingId}
          isDisabled={isDisabled}
        />
      )}

      {isDisabled && (
        <div className="close-warehousing-warning-message">
          메인담당자만 입고 마감이 가능합니다.
        </div>
      )}
    </Styled.closeWarehousing>
  );
}

export default CloseWarehousing;
