import { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import { ADD_PARTIAL_INSPECTION_REQ } from "@sellernote/_shared/src/api-interfaces/boful-api/receiving";
import useValidationErrorModal from "@sellernote/_shared/src/hooks/common/useValidationErrorModal";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import {
  checkConfirmedAsSingleLocationInspection,
  checkHasOnlyInitialInspection,
  getRemainedQtyForInspection,
} from "@sellernote/_shared/src/utils/fulfillment/receiving";

export default function useAddPartialInspection({
  receivingId,
  receivingItem,
}: {
  receivingItem: ReceivingItem;
  receivingId: number;
}) {
  const history = useHistory();

  const queryClient = useQueryClient();

  const {
    mutate: addPartialInspection,
    ResponseHandler: ResponseHandlerOfAddPartialInspection,
  } = RECEIVING_QUERY.useAddPartialInspection({
    type: "add",
    receivingId,
  });

  const {
    refetch: fetchManagerReceivingDetailToCheckCount,
    ResponseHandler: ResponseHandlerOfGettingManagerReceivingDetailToCheckCount,
  } = RECEIVING_QUERY.useGetManagerReceivingDetailToCheck({
    receivingId,
    enabled: false,
  });

  const [setValidationError, ValidationErrorModal] = useValidationErrorModal();

  const addPartialInspectionToItem = useCallback(
    ({
        itemId,
        remainedQty,
        quantity,
        isInit,
        callbackAfterAdd,
      }: ADD_PARTIAL_INSPECTION_REQ & {
        remainedQty: number;
        itemId: number;
        callbackAfterAdd: () => void;
      }) =>
      async () => {
        const { data } = await fetchManagerReceivingDetailToCheckCount({
          throwOnError: true,
        });

        if (!data) {
          return;
        }

        const targetItem = data.data.receiving.items.find(
          (v) => v.id === itemId
        );

        if (!targetItem) {
          setValidationError({
            title: "이전 분할검수 내역 조회 중에 오류가 발생했습니다",
          });
          return;
        }

        const remainedQtyLatest = getRemainedQtyForInspection(targetItem);
        if (remainedQty !== remainedQtyLatest) {
          // 잔여검수 수량이 바꼈으면 중단하고 입력을 다시 받도록 함
          setValidationError({
            title: "다른 작업자에 의해 수량이 조정되었습니다.",
            confirmCallback: () => {
              queryClient.invalidateQueries(
                RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
                  receivingId,
                })
              );
            },
          });
          return;
        }

        addPartialInspection(
          { itemId, quantity, isInit },
          {
            onSuccess: ({ data }) => {
              const targetItem = data.items.find((v) => v.id === itemId);
              const confirmedAsSingleLocationInspection =
                checkConfirmedAsSingleLocationInspection(targetItem);

              // 전체수량으로 분할할 경우 일반검수로 취급하여 검수페이지로 돌아감
              confirmedAsSingleLocationInspection
                ? history.goBack()
                : callbackAfterAdd();

              queryClient.invalidateQueries(
                RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
                  receivingId,
                })
              );
            },
          }
        );
      },
    [
      addPartialInspection,
      fetchManagerReceivingDetailToCheckCount,
      history,
      queryClient,
      receivingId,
      setValidationError,
    ]
  );

  const hasOnlyInitialInspection = useMemo(
    () => checkHasOnlyInitialInspection(receivingItem),
    [receivingItem]
  );

  const canAddPartialInspection = useMemo(() => {
    if (!receivingItem.inspectItems) return false;

    if (hasOnlyInitialInspection) {
      return true;
    }

    const partedQuantityTotal = receivingItem.inspectItems.reduce((a, c) => {
      return a + c.quantity;
    }, 0);
    const max = receivingItem.quantity ?? 0;
    if (partedQuantityTotal < max) return true;

    return false;
  }, [receivingItem, hasOnlyInitialInspection]);

  const remainedQtyForInspection = useMemo(() => {
    if (!receivingItem.inspectItems) return 0;

    if (hasOnlyInitialInspection) return receivingItem.quantity ?? 0;

    return getRemainedQtyForInspection(receivingItem);
  }, [receivingItem, hasOnlyInitialInspection]);

  const ResponseHandlerOfAddingPartialInspectionToItem = useMemo(() => {
    return (
      <>
        {ValidationErrorModal}

        {ResponseHandlerOfAddPartialInspection}

        {ResponseHandlerOfGettingManagerReceivingDetailToCheckCount}
      </>
    );
  }, [
    ValidationErrorModal,
    ResponseHandlerOfAddPartialInspection,
    ResponseHandlerOfGettingManagerReceivingDetailToCheckCount,
  ]);

  return {
    addPartialInspectionToItem,
    canAddPartialInspection,
    remainedQtyForInspection,
    ResponseHandlerOfAddingPartialInspectionToItem,
  };
}
