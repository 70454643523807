import { memo } from "react";

import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";

import Styled from "./index.styles";

function SkuIdForReportingProblem({
  skuId,
  skuBarcode,
}: {
  skuId: number;
  skuBarcode: string | undefined;
}) {
  return (
    <Styled.container>
      {getFormattedSingleSkuId(skuId)}
      {!!skuBarcode && (
        <>
          <br /> {`(${skuBarcode})`}
        </>
      )}
    </Styled.container>
  );
}

export default memo(SkuIdForReportingProblem);
