import INVENTORY_QUERY from "@sellernote/_shared/src/queries/fulfillment/INVENTORY_QUERY";

import useSearch from "./useSearch";

export default function useSearchLocationSection() {
  const {
    isSearching: isSearchingForLocation,

    inputBarcode: inputBarcodeForLocation,
    searchLocation: searchLocationForLocation,
    resetSearch: resetSearchForLocation,
    isErrorMessageActive: isErrorMessageActiveForLocation,
    showErrorMessage,
    resetErrorMessage,

    scannedBarcode,
    isScanButtonActive: isScanButtonActiveForLocation,
    toggleScanButton: toggleScanButtonForLocation,
    openScanErrorToast,
    ScanErrorToast: ScanErrorToastForLocation,
  } = useSearch({ type: "location" });

  const {
    data: inventoryDetailByLocation,
    ResponseHandler: ResponseHandlerOfInventoryDetailByLocation,
  } = INVENTORY_QUERY.useGetInventoryDetailByLocationForSearch({
    barCode: scannedBarcode || inputBarcodeForLocation,
    enabled: !!scannedBarcode || !!inputBarcodeForLocation,
    onSuccess: () => {
      if (inputBarcodeForLocation) {
        resetErrorMessage();
      }
    },
    onError: (err, hideFailureModal) => {
      if (err?.code === 404) {
        hideFailureModal();

        if (scannedBarcode) {
          openScanErrorToast();
        }

        if (inputBarcodeForLocation) {
          showErrorMessage();
        }
      }
    },
  });

  return {
    inputBarcodeForLocation,
    searchLocationForLocation,
    resetSearchForLocation,
    isSearchingForLocation,
    isErrorMessageActiveForLocation,

    isScanButtonActiveForLocation,
    toggleScanButtonForLocation,

    inventoryDetailByLocation,
    ResponseHandlerOfInventoryDetailByLocation,
    ScanErrorToastForLocation,
  };
}
