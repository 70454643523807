/* eslint-disable unused-imports/no-unused-imports */
import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.div`
  padding: 24px 16px 40px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const totalQuantity = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 4px;
  gap: 28px;
  border-bottom: 1px solid ${COLOR.grayScale_200};

  .label {
    ${setFontStyle("SubHead3")};
    color: ${COLOR.grayScale_500};
  }

  .value {
    flex: 1;
    ${setFontStyle("Body7")};
    color: ${COLOR.grayScale_800};
    text-align: right;
  }
`;

const problemListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0;

    .title {
      ${setFontStyle("SubHead3", "Bold")};
      color: ${COLOR.grayScale_700};
    }
  }

  .max-length-guide {
    ${setFontStyle("Caption")};
    color: ${COLOR.pointWarning};
    text-align: center;
  }
`;

const problemMemo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
`;

export default {
  container,
  totalQuantity,
  problemListContainer,
  problemMemo,
};
