import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import SHIPPING_QUERY from "@sellernote/_shared/src/queries/fulfillment/SHIPPING_QUERY";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { ShippingListItem } from "@sellernote/_shared/src/types/fulfillment/shipping";
import {
  ParcelCompanyType,
  ShipmentDelayFilterType,
  ShipmentParcelOrderType,
  ShipmentStatusType,
  ShipmentTodayAndOrderFilterType,
  ShippingShipmentListItem,
} from "@sellernote/_shared/src/types/fulfillment/shipping";
import {
  isBeforeToday,
  toFormattedDate,
} from "@sellernote/_shared/src/utils/common/date";
import { getPageSize } from "@sellernote/_shared/src/utils/common/etc";
import { shipmentStatus } from "@sellernote/_shared/src/utils/fulfillment/shipping";

const parcelCompany = new Map([["cj", "대한통운"]]);

export type ShipmentParcelDetailTableItem = {
  // 고객사
  customerCompany: React.ReactNode;
  // 출하여부
  isDone: string;
  // 상태
  status: string;
  parcelCompany: string;
} & Pick<ShippingListItem, "dueDate" | "id" | "invoiceNo">;

const getDataList = (
  rawList?: ShippingShipmentListItem[]
): TableDataListItem<ShipmentParcelDetailTableItem>[] => {
  if (!rawList) {
    return [];
  }

  return rawList.map((v) => ({
    rowKey: v.id,
    rowFontColor: isBeforeToday(v.dueDate) ? COLOR.pointWarning : undefined,
    dueDate: toFormattedDate(v.dueDate, `M/DD`),
    id: v.id,
    customerCompany: (
      <>
        {v.team?.company} <br />
        {v.team?.id}
      </>
    ),
    parcelCompany: parcelCompany.get(v.parcelCompany ?? "") ?? "",
    invoiceNo: v.invoiceNo,
    isDone: v.wmsStatus === "done" ? "○" : "✕",
    status: shipmentStatus.get(v.wmsStatus) ?? "",
  }));
};

const PAGE_UNIT = 10;

export default function useShipmentClosingParcelDetail<
  T extends ShipmentStatusType
>({
  filterForList,
  currentPage,
}: {
  filterForList: T extends "expected"
    ? ShipmentTodayAndOrderFilterType
    : T extends "unprocessed"
    ? ShipmentDelayFilterType
    : undefined;
  currentPage: number;
}) {
  const { company, status, order } = useParams<{
    company: ParcelCompanyType;
    status: ShipmentStatusType;
    order?: ShipmentParcelOrderType;
  }>();

  const {
    data: shipmentParcelToday,
    ResponseHandler: ResponseHandlerOfGettingShipmentParcelToday,
  } = SHIPPING_QUERY.useGetShipmentToday({
    type: "parcel",
    status,
    params: {
      page: currentPage,
      perPage: PAGE_UNIT,
      filter: filterForList as ShipmentTodayAndOrderFilterType,
      parcelCompany: company,
    },
  });

  const {
    data: shipmentParcelDone,
    ResponseHandler: ResponseHandlerOfGettingShipmentParcelDone,
  } = SHIPPING_QUERY.useGetShipmentDone({
    type: "parcel",
    status,
    params: {
      page: currentPage,
      perPage: PAGE_UNIT,
      parcelCompany: company,
    },
  });

  const {
    data: shipmentParcelDelay,
    ResponseHandler: ResponseHandlerOfGettingShipmentParcelDelay,
  } = SHIPPING_QUERY.useGetShipmentDelay({
    type: "parcel",
    status,
    params: {
      page: currentPage,
      perPage: PAGE_UNIT,
      filter: filterForList as ShipmentDelayFilterType,
      parcelCompany: company,
    },
  });

  const {
    data: shipmentParcelOrder,
    ResponseHandler: ResponseHandlerOfGettingShipmentParcelOrder,
  } = SHIPPING_QUERY.useGetShipmentParcelOrder({
    status,
    params: {
      page: currentPage,
      perPage: PAGE_UNIT,
      parcelCompany: company,
      parcelOrder: order ?? "firstOrder",
      isDone: status === "ready" ? false : true,
      filter: filterForList as ShipmentTodayAndOrderFilterType,
    },
  });

  const titleValue = useMemo(
    () =>
      ({
        expected: shipmentParcelToday?.total ?? 0,
        completed: shipmentParcelDone?.total ?? 0,
        unprocessed: shipmentParcelDelay?.total ?? 0,
        ready: shipmentParcelOrder?.total ?? 0,
        done: shipmentParcelOrder?.total ?? 0,
      }[status] ?? 0),
    [
      shipmentParcelDelay?.total,
      shipmentParcelDone?.total,
      shipmentParcelOrder?.total,
      shipmentParcelToday?.total,
      status,
    ]
  );

  const dataList = useMemo(
    () =>
      ({
        expected: getDataList(shipmentParcelToday?.list),
        completed: getDataList(shipmentParcelDone?.list),
        unprocessed: getDataList(shipmentParcelDelay?.list),
        ready: getDataList(shipmentParcelOrder?.list),
        done: getDataList(shipmentParcelOrder?.list),
      }[status] ?? []),
    [
      shipmentParcelDelay?.list,
      shipmentParcelDone?.list,
      shipmentParcelOrder?.list,
      shipmentParcelToday?.list,
      status,
    ]
  );

  const pageSize = useMemo(
    () =>
      ({
        expected: getPageSize(PAGE_UNIT, shipmentParcelToday?.total),
        completed: getPageSize(PAGE_UNIT, shipmentParcelDone?.total),
        unprocessed: getPageSize(PAGE_UNIT, shipmentParcelDelay?.total),
        ready: getPageSize(PAGE_UNIT, shipmentParcelOrder?.total),
        done: getPageSize(PAGE_UNIT, shipmentParcelOrder?.total),
      }[status] ?? 0),
    [
      shipmentParcelDelay?.total,
      shipmentParcelDone?.total,
      shipmentParcelOrder?.total,
      shipmentParcelToday?.total,
      status,
    ]
  );

  return {
    titleValue,
    dataList,
    pageSize,

    ResponseHandlerOfGettingShipmentParcelToday,
    ResponseHandlerOfGettingShipmentParcelDone,
    ResponseHandlerOfGettingShipmentParcelDelay,
    ResponseHandlerOfGettingShipmentParcelOrder,
  };
}
