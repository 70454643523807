import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";

import { InventoryDetailBySKUId } from "@sellernote/_shared/src/api-interfaces/boful-api/inventory";
import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";

import { SkuToSelect } from "../../SelectSkuModal/useSelectSkuModal";
import TempInput from "../../TempInput";
import { InventoryToConsolidate } from "../useInventoryConsolidation";
import Styled from "./index.styles";

export interface InventoryConsolidationTableItem {
  location: ReactNode;
  availableQty: number;
  consolidationQty: ReactNode;
  reset: ReactNode;
}

export default function useConsolidationTable({
  selectedSku,
  inventoryDetailOfSelectedSku,
  listOfInventoryToConsolidate,
  setListOfInventoryToConsolidate,
}: {
  selectedSku: SkuToSelect;
  inventoryDetailOfSelectedSku: InventoryDetailBySKUId[];
  listOfInventoryToConsolidate: InventoryToConsolidate[];
  setListOfInventoryToConsolidate: Dispatch<
    SetStateAction<InventoryToConsolidate[]>
  >;
}) {
  const [isVisibleCountQuantityModal, setIsVisibleCountQuantityModal] =
    useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState<number>();
  const [quantityToConsolidate, setQuantityToConsolidate] = useState<number>();

  const availableQty = useMemo(
    () =>
      listOfInventoryToConsolidate.find(
        (item) => item.locationId === selectedLocationId
      )?.availableQty ?? 0,
    [listOfInventoryToConsolidate, selectedLocationId]
  );

  const hasManagementInfo = Boolean(
    selectedSku?.managementKind && selectedSku?.managementDate
  );

  const handleCountQuantityModalOpen = useCallback(
    (locationId: number) => () => {
      setSelectedLocationId(locationId);
      setIsVisibleCountQuantityModal(true);
    },
    []
  );

  const handleCountQuantityModalClose = useCallback(() => {
    setSelectedLocationId(undefined);
    setIsVisibleCountQuantityModal(false);
  }, []);

  const handleQuantityToConsolidateSet = useCallback(
    (quantity: number) => {
      setListOfInventoryToConsolidate((prevListOfInventoryToConsolidate) =>
        prevListOfInventoryToConsolidate.map((item) =>
          item.locationId === selectedLocationId
            ? {
                ...item,
                consolidationQty: quantity,
              }
            : item
        )
      );
    },
    [selectedLocationId, setListOfInventoryToConsolidate]
  );

  const handleAllQuantitySelect = useCallback(
    ({
        locationId,
        availableQty,
      }: {
        locationId: number;
        availableQty: number;
      }) =>
      () => {
        setListOfInventoryToConsolidate((prevListOfInventoryToConsolidate) =>
          prevListOfInventoryToConsolidate.map((item) =>
            item.locationId === locationId
              ? {
                  ...item,
                  consolidationQty: availableQty,
                }
              : item
          )
        );
      },
    [setListOfInventoryToConsolidate]
  );

  const handleQuantityReset = useCallback(
    (locationId: number) => () => {
      setListOfInventoryToConsolidate((prevListOfInventoryToConsolidate) =>
        prevListOfInventoryToConsolidate.map((item) =>
          item.locationId === locationId
            ? {
                ...item,
                consolidationQty: 0,
              }
            : item
        )
      );
    },
    [setListOfInventoryToConsolidate]
  );

  const tableDataList: TableDataListItem<InventoryConsolidationTableItem>[] =
    useMemo(
      () =>
        (hasManagementInfo
          ? inventoryDetailOfSelectedSku.filter(
              ({ managementDate }) =>
                managementDate === selectedSku?.managementDate
            )
          : inventoryDetailOfSelectedSku
        ).map(({ id, locationId, availableQty, location }) => {
          const consolidationQty = listOfInventoryToConsolidate?.find(
            (item) => item.locationId === locationId
          )?.consolidationQty;

          return {
            rowKey: id,

            location: (
              <>
                인천1센터/
                <br />
                {location?.barCode}
              </>
            ),

            availableQty: availableQty,

            consolidationQty: (
              <Styled.consolidationQty>
                <Styled.inputWrapper
                  onClick={handleCountQuantityModalOpen(locationId)}
                >
                  <InputText
                    placeholder="수량 입력"
                    width={7.5}
                    borderType="outline"
                    valueType="int"
                    value={consolidationQty}
                    disabledToFocus
                    visiblePostFix
                  />
                  <TempInput />
                </Styled.inputWrapper>

                <Button
                  size="small"
                  theme="secondary"
                  label="전체병합"
                  handleClick={handleAllQuantitySelect({
                    locationId,
                    availableQty,
                  })}
                />
              </Styled.consolidationQty>
            ),

            reset: (
              <Button
                theme="tertiary"
                size="small"
                label="초기화"
                handleClick={handleQuantityReset(locationId)}
              />
            ),
          };
        }),
      [
        handleAllQuantitySelect,
        handleCountQuantityModalOpen,
        handleQuantityReset,
        hasManagementInfo,
        inventoryDetailOfSelectedSku,
        listOfInventoryToConsolidate,
        selectedSku?.managementDate,
      ]
    );

  return {
    tableDataList,

    isVisibleCountQuantityModal,
    availableQty,
    handleQuantityToConsolidateSet,
    handleCountQuantityModalClose,
  };
}
