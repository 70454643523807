import { useEffect, useState } from "react";

export default function useTableCurtain({
  activated,
  table,
  leftEnd,
  rightEnd,
}: {
  activated: boolean;
  table: HTMLTableElement;
  leftEnd: HTMLTableCellElement;
  rightEnd: HTMLTableCellElement;
}) {
  const [isLeftEnd, setIsLeftEnd] = useState(true);
  const [isRightEnd, setIsRightEnd] = useState(activated ? false : true);

  function onScrollTable() {
    if (!activated) return;

    if (checkIsEnd("left", table, leftEnd)) {
      setIsLeftEnd(true);
    } else {
      setIsLeftEnd(false);
    }

    if (checkIsEnd("right", table, rightEnd)) {
      setIsRightEnd(true);
    } else {
      setIsRightEnd(false);
    }
  }

  useEffect(() => {
    if (!activated) {
      setIsRightEnd(true);
    }
  }, [activated]);

  function checkIsEnd(
    direction: "left" | "right",
    container: HTMLTableElement,
    inner: HTMLTableCellElement
  ) {
    const containerRect = container.getClientRects()[0];
    const innerRect = inner.getClientRects()[0];

    if (!containerRect || !innerRect) {
      return true;
    }

    if (direction === "left") {
      return containerRect.x === innerRect.x;
    }

    if (direction === "right") {
      return containerRect.right === innerRect.right;
    }
  }

  return {
    tableHasArriveLeftEnd: isLeftEnd,
    tableHasArriveRightEnd: isRightEnd,
    onScrollTable,
  };
}
