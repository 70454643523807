import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const closeWarehousing = styled.div`
  > .close-warehousing-warning-message {
    margin-top: 8px;
    text-align: center;
    ${setFontStyle("Body8")}
    color: ${COLOR.pointWarning};
  }
`;

export default {
  closeWarehousing,
};
