import { useCallback, useState } from "react";

import { getPageSize } from "@sellernote/_shared/src/utils/common/etc";
import TabFilter from "@sellernote/_sds-v1/src/components/TabFilter";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useCompletedPickingList from "hooks/shipping/useCompletedPickingList";
import usePickingInvoice from "hooks/shipping/usePickingInvoice";

import CompletedPickingListTable from "./CompletedPickingListTable";
import CompletePickingButton from "./CompletePickingButton";
import InvoiceDirectInput from "./InvoiceDirectInput";
import MyPickingTable from "./MyPickingTable";
import ScanInvoiceButton from "./ScanInvoiceButton";
import ScanLocationButton from "./ScanLocationButton";
import ScannedInfo from "./ScannedInfo";
import ScanSKUButton from "./ScanSKUButton";
import Styled from "./index.styles";

export type FilterForIsMyPicking = "mine" | "completed";

const PAGE_UNIT = 10;

function ShippingPicking() {
  const [filterForIsMyPicking, setFilterForIsMyPicking] =
    useState<FilterForIsMyPicking>("mine");

  const {
    workingLocation,
    setWorkingLocation,

    rowInfoToHighlight,
    setRowInfoToHighlight,

    completedList,

    isFirstSKUScan,

    selectedScanButtonType,
    handleScanButtonClick,

    SKUInfoForScanning,
    setSKUInfoForScanning,

    allPickingAreCompleted,

    resetPickingState,
  } = usePickingInvoice();

  const {
    completedPickingListTotal,
    currentPage,
    setCurrentPage,
    completedPickingTableList,
    ResponseHandlerOfGettingCompletedPickingList,
  } = useCompletedPickingList({ filterForIsMyPicking });

  const handleIsCompleteFilterClick = useCallback(
    (v: FilterForIsMyPicking) => {
      setCurrentPage(0);
      setFilterForIsMyPicking(v);
    },
    [setCurrentPage]
  );

  return (
    <Layout
      navigator={{
        title: "피킹",
      }}
    >
      <Styled.container>
        <TabFilter<FilterForIsMyPicking>
          filterList={[
            {
              label: "My피킹",
              filter: "mine",
            },
            {
              label: `피킹완료 (${completedPickingListTotal})`,
              filter: "completed",
            },
          ]}
          selectedFilter={filterForIsMyPicking}
          setFilter={handleIsCompleteFilterClick}
        />

        {filterForIsMyPicking === "mine" && (
          <Styled.scanContainer>
            <ScannedInfo workingLocationBarcode={workingLocation?.barcode} />
            <Styled.scanButtonContainer>
              <InvoiceDirectInput onScanButtonSelect={handleScanButtonClick} />
              <ScanInvoiceButton
                selectedScanButtonType={selectedScanButtonType}
                handleScanButtonClick={handleScanButtonClick}
              />
              <ScanLocationButton
                selectedScanButtonType={selectedScanButtonType}
                handleScanButtonClick={handleScanButtonClick}
                workingLocation={workingLocation}
                setWorkingLocation={setWorkingLocation}
                SKUInfoForScanning={SKUInfoForScanning}
                completedList={completedList}
              />
              <ScanSKUButton
                selectedScanButtonType={selectedScanButtonType}
                handleScanButtonClick={handleScanButtonClick}
                workingLocation={workingLocation}
                setWorkingLocation={setWorkingLocation}
                setRowInfoToHighlight={setRowInfoToHighlight}
                SKUInfoForScanning={SKUInfoForScanning}
                setSKUInfoForScanning={setSKUInfoForScanning}
                completedList={completedList}
                isFirstSKUScan={isFirstSKUScan}
                resetPickingState={resetPickingState}
              />
            </Styled.scanButtonContainer>
          </Styled.scanContainer>
        )}

        {filterForIsMyPicking === "mine" ? (
          <>
            <MyPickingTable
              selectedScanButtonType={selectedScanButtonType}
              handleScanButtonClick={handleScanButtonClick}
              workingLocation={workingLocation}
              setWorkingLocation={setWorkingLocation}
              rowInfoToHighlight={rowInfoToHighlight}
              setRowInfoToHighlight={setRowInfoToHighlight}
              SKUInfoForScanning={SKUInfoForScanning}
              setSKUInfoForScanning={setSKUInfoForScanning}
              completedList={completedList}
              isFirstSKUScan={isFirstSKUScan}
              resetPickingState={resetPickingState}
            />
            {/* {allPickingAreCompleted ? ( */}
            <CompletePickingButton
              isAllPickingItemListCompletedWithMatchedQuantity={
                allPickingAreCompleted
              }
              SKUInfoForScanning={SKUInfoForScanning}
              resetPickingState={resetPickingState}
            />
            {/* 2024-06-13 관리일자 개발 중 창고에서 사용하지 않는다고 하여 주석 처리 https://sellernotehq.slack.com/archives/C068N3ASA1G/p1712803097067629 */}
            {/* ) : (
              <ReportProblemButton
                SKUInfoForScanning={SKUInfoForScanning}
                resetPickingState={resetPickingState}
              />
            )} */}
          </>
        ) : (
          <CompletedPickingListTable
            pageSize={getPageSize(PAGE_UNIT, completedPickingListTotal)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            list={completedPickingTableList}
          />
        )}
      </Styled.container>

      {ResponseHandlerOfGettingCompletedPickingList}
    </Layout>
  );
}

export default withRequireAuth(ShippingPicking);
