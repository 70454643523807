import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.section`
  > .btn-add {
    margin-top: 40px;
  }

  > .btn-complete {
    margin-top: 16px;
  }
`;

const guide = styled.div`
  padding: 16px;
  background-color: ${COLOR.bgColor_2};

  > .title {
    ${setFontStyle("Head6", "Bold")};
    color: ${COLOR.grayScale_700};
  }

  > .desc {
    margin-top: 16px;
    ${setFontStyle("Body7")};
    color: ${COLOR.grayScale_800};
  }
`;

const totalCount = styled.div`
  margin-top: 40px;
  padding: 8px;
  background-color: ${COLOR.bgColor_3};
  color: ${COLOR.bk};
  display: flex;
  justify-content: center;

  > .label {
    margin-right: 8px;
    ${setFontStyle("SubHead2")};
  }

  > .value {
    ${setFontStyle("Body6", "Bold")};
  }
`;

const addPartialModalBody = styled.div`
  > .remained {
    ${setFontStyle("Head6", "Bold")};
    color: ${COLOR.primaryBlue};
  }

  > .input-text {
    margin-top: 8px;
  }

  > .button {
    margin-top: 32px;
  }
`;

const resetConfirmModalTitle = styled.div`
  ${setFontStyle("Head6", "Bold")};
  color: ${COLOR.grayScale_700};

  > b {
    color: ${COLOR.primaryBlue};
  }
`;

const removeConfirmModalTitle = styled.div`
  ${setFontStyle("Head6", "Bold")};
  color: ${COLOR.grayScale_700};

  > b {
    color: ${COLOR.pointWarning};
  }
`;

const headerForScan = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  > .left {
    ${setFontStyle("SubHead2", "Bold")};
    color: ${COLOR.grayScale_800};
  }

  > .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > .button {
      &:first-child {
        margin-right: 16px;
      }
    }
  }
`;

export default {
  container,
  guide,
  headerForScan,
  totalCount,
  addPartialModalBody,
  resetConfirmModalTitle,
  removeConfirmModalTitle,
};
