import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { RETURNING_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { FulfillmentAttachment } from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import { handleFormChange } from "@sellernote/_shared/src/utils/common/etc";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";
import InputTextArea from "@sellernote/_sds-v1/src/components/input/InputTextArea";

import useUploadSelectPhotoList from "hooks/common/useUploadSelectPhotoList";
import useUnverifiedItem from "../../../hooks/useUnverifiedItem";

import useValid from "./useValid";
import Styled from "./index.styles";

export type Form = {
  order?: number; // 모달 상단에 표기하거나 사진 업로드 targetId 에 사용할 불일치 상품의 순번.
  itemId?: number;
  tempId?: number;
  quantity?: number;
  memo: string;
  attachments?: FulfillmentAttachment[];
};

export const initialFormState: Form = {
  tempId: undefined,
  quantity: undefined,
  memo: "",
};

export default function useUnverifiedItemForm({
  returningId,
  newOrder,
  onSuccessForDeletePhoto,
}: {
  returningId: number;
  newOrder: number;
  onSuccessForDeletePhoto: () => void;
}) {
  const queryClient = useQueryClient();

  const [form, setForm] = useState(initialFormState);

  const [prevForm, setPrevForm] = useState(initialFormState);

  const handleUnverifiedItemFormChange = handleFormChange(setForm);

  // 최초 불일치 상품 등록 시, itemId 가 없으므로,
  // 업로드 할 파일의 targetId 를 tempId 로 보내서 파일과 불일치 상품을 매칭시켜주는 역할.
  // 이미지 업로드 > 바인딩 > 등록 순으로 진행될 때, 최초에 임시로 사용하는 값.
  const targetId = Number(`` + returningId + newOrder);

  // 이미 등록한 불일치 상품에만 itemId 가 있다. (등록/수정인지 구별)
  const isRegistered = !!form.itemId;

  const {
    SelectPhotoList,
    uploadPhotoList,
    deletePhotoList,
    resetPhotoList,
    tempPhotoList,
    isChangedPhoto,
    LoadingToUpload,
    UploadFileListFailureModal,
  } = useUploadSelectPhotoList({
    type: "returning",
    id: returningId,
    targetId: isRegistered && form.itemId ? form.itemId : targetId,
    attachmentList: form.attachments ?? [],
    fileDomain: "unverifiedSku",
  });

  const {
    quantityErrorMessage,
    memoErrorMessage,
    isValidUpdateForm,
    isValidForm,
    isChangedForm,
  } = useValid({
    form,
    prevForm,
    tempPhotoList,
    isChangedPhoto,
  });

  const resetForm = () => {
    setForm(initialFormState);
    setPrevForm(initialFormState);
    resetPhotoList();
  };

  const { handleUnverifiedItemUpdate, ResponseHandlerOfUpdateUnverifiedItem } =
    useUnverifiedItem({
      returningId,
      onSuccessForCreateUnverifiedItem: () => {
        // 이미 업로드한 사진 중, 삭제한 사진이 있다면 수정 시, 일괄 삭제.
        deletePhotoList({
          onSuccess: () => {
            onSuccessForDeletePhoto();

            resetForm();

            queryClient.invalidateQueries(
              RETURNING_QUERY_KEY_GEN.getPDAReturningDetail({
                id: returningId,
              })
            );
          },
        });
      },
    });

  const updateForm = (formInfo: Form) => {
    setForm(formInfo);
    setPrevForm(formInfo);
  };

  const submitForm = useCallback(() => {
    // 개별 등록 시, 사진을 무조건 업로드해야하므로 수정(itemId 가 있는)케이스만 해당.
    if (!tempPhotoList.length) {
      return handleUnverifiedItemUpdate({
        itemId: form.itemId,
        quantity: Number(form.quantity),
        memo: form.memo,
      });
    }

    // tempId > 최초 등록 시, 불일치 상품과 사진을 매칭시켜주기 위해 임시로 사용하는 값. (수정시에는 사용하지 않음)
    // 이미지 업로드 시, upload > S3 > binding > update 순으로 진행될 때, binding > update 과정에서 이미지와 상품을 매칭하기 위해 임시로 쓰이는 id
    // 등록 이후에는 itemId 가 attachment 와 연동되므로 tempId 는 사용하지 않는다.
    uploadPhotoList({
      onUploadSuccess: () => {
        handleUnverifiedItemUpdate({
          itemId: isRegistered ? form.itemId : undefined,
          tempId: isRegistered ? undefined : targetId, // 수정 시에는 itemId 가 있으므로 tempId 는 보내지않는다.
          quantity: Number(form.quantity),
          memo: form.memo,
        });
      },
    });
  }, [
    form.itemId,
    form.memo,
    form.quantity,
    handleUnverifiedItemUpdate,
    isRegistered,
    targetId,
    tempPhotoList.length,
    uploadPhotoList,
  ]);

  const UnverifiedItemForm = useMemo(() => {
    return (
      <>
        <Styled.form>
          <InputText
            label="수량(PCS)"
            isRequired
            borderType="outline"
            valueType="int"
            value={form.quantity}
            setValue={handleUnverifiedItemFormChange("quantity")}
            placeholder="입력해주세요."
            usesNumberAsText
            errorMessage={quantityErrorMessage}
          />

          {SelectPhotoList}

          <InputTextArea
            label="비고"
            value={form.memo}
            setValue={handleUnverifiedItemFormChange("memo")}
            placeholder="입력해주세요."
            height={100}
            errorMessage={memoErrorMessage}
          />

          <div className="button-container">
            <Button
              label="저장"
              size="normal"
              theme="primary"
              handleClick={submitForm}
              disabled={isRegistered ? !isValidUpdateForm : !isValidForm}
            />
          </div>
        </Styled.form>

        {LoadingToUpload}
        {UploadFileListFailureModal}
        {ResponseHandlerOfUpdateUnverifiedItem}
      </>
    );
  }, [
    LoadingToUpload,
    ResponseHandlerOfUpdateUnverifiedItem,
    SelectPhotoList,
    UploadFileListFailureModal,
    form.memo,
    form.quantity,
    handleUnverifiedItemFormChange,
    isRegistered,
    isValidForm,
    isValidUpdateForm,
    memoErrorMessage,
    quantityErrorMessage,
    submitForm,
  ]);

  return {
    form,
    isChangedForm,
    resetForm,
    updateForm,
    UnverifiedItemForm,
  };
}
