import React, { useState } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import useCheckCancelingDetailInvoice from "hooks/canceling/useCheckCancelingDetailInvoice";
import useScanCancelingInvoice from "hooks/canceling/useScanCancelingInvoice";

import { CancelingDetailTableItem } from ".";
import Styled from "./index.styles";

export default function CheckInvoice({
  setRestocking,
  setPackingRestocker,
}: {
  setRestocking: React.Dispatch<React.SetStateAction<boolean>>;
  setPackingRestocker: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [cancelingInvoice, setCancelingInvoice] = useState<
    TableDataListItem<CancelingDetailTableItem>[]
  >([]);

  const { checkInvoice, isActiveRestock, ResponseHandlerOfAssigningRestocker } =
    useCheckCancelingDetailInvoice(
      cancelingInvoice,
      setCancelingInvoice,
      setPackingRestocker
    );

  const { ScanInvoiceButton, ResponseHandlerOfScanningInvoice } =
    useScanCancelingInvoice(checkInvoice);

  return (
    <>
      <Styled.checkInvoiceContainer>
        {ScanInvoiceButton}

        <Table<CancelingDetailTableItem>
          columnInfo={{
            invoiceNo: { label: "송장(QR)번호", fixedWidth: 200 },
            status: { label: "일치여부", fixedWidth: 120 },
          }}
          dataList={cancelingInvoice}
        />

        <Button
          theme="primary"
          size="block"
          label="재입고 시작"
          disabled={!isActiveRestock}
          handleClick={() => setRestocking(true)}
        />
      </Styled.checkInvoiceContainer>

      {ResponseHandlerOfScanningInvoice}
      {ResponseHandlerOfAssigningRestocker}
    </>
  );
}
