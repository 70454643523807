import { Dispatch, SetStateAction } from "react";

import useValidationErrorToast from "@sellernote/_shared/src/hooks/common/useValidationErrorToast";
import { BofulLocation } from "@sellernote/_shared/src/types/fulfillment/common";
import Toast from "@sellernote/_sds-v2/src/components/Toast";

import useScan from "hooks/common/useScan";

export default function useScanLocation({
  label,
  locationListByType,
  tempLocationBarcode,
  setTempLocation,
}: {
  label: string | undefined;
  locationListByType: BofulLocation[] | undefined;
  tempLocationBarcode: string | undefined;
  setTempLocation: Dispatch<SetStateAction<BofulLocation | undefined>>;
}) {
  const [setValidationError, ValidationErrorToast] =
    useValidationErrorToast(Toast);

  useScan((scanResult) => {
    if (tempLocationBarcode) {
      return;
    }

    const scannedLocation = locationListByType?.find(
      (location) => location.barCode === scanResult
    );
    if (!scannedLocation) {
      setValidationError({
        message: `${label}로 지정할 수 없는 바코드입니다.`,
      });
      return;
    }

    setTempLocation(scannedLocation);
  });

  return {
    ValidationErrorToast,
  };
}
