import { useHistory, useLocation } from "react-router-dom";

import SHIPPING_QUERY from "@sellernote/_shared/src/queries/fulfillment/SHIPPING_QUERY";
import { MENU_LIST } from "@sellernote/_shared/src/utils/fulfillment/constants";

import MenuBofulList, { MenuListBofulItem } from "components/MenuListBoful";

const getCountByTitle = ({
  isShipping,
  count,
}: {
  isShipping: boolean;
  count: number;
}) => {
  if (!isShipping) return "";

  if (typeof count !== "number" && !count) return "";

  return `(${count}건)`;
};

function MenuListByMainPath({ mainPath }: { mainPath: string }) {
  const history = useHistory();
  const location = useLocation();

  const targetMenuList = MENU_LIST.find(
    (m) => m.path === `/${mainPath}`
  )?.subMenuList;

  const isShipping = mainPath === "shipping";

  const {
    shippingCountByTitle,
    ResponseHandler: ResponseHandlerOfGettingOfShippingCount,
  } = SHIPPING_QUERY.useGetShippingCount({
    enabled: isShipping,
  });

  const menuList: MenuListBofulItem[] =
    targetMenuList?.map((menu, i) => {
      return {
        label: `${i + 1}. ${menu.title} ${getCountByTitle({
          isShipping,
          count: shippingCountByTitle[menu.title],
        })}`,
        handleClick: () =>
          history.push({
            pathname: menu.path,
            state: { from: location.pathname },
          }),
      };
    }) || [];

  return (
    <>
      <MenuBofulList menuList={menuList} />

      {ResponseHandlerOfGettingOfShippingCount}
    </>
  );
}

export default MenuListByMainPath;
