import { selector } from "recoil";

import { BofulWorker } from "../../../types/fulfillment/auth";

import { FULFILLMENT_COMMON_ATOMS } from ".";

const MANAGER_WORKER_LIST = selector<BofulWorker[]>({
  key: "fulfillment/common/selectors/MANAGER_WORKER_LIST",
  get: ({ get }) => {
    return get(FULFILLMENT_COMMON_ATOMS.WORKER_LIST).filter(
      (v) => v.authority === "manager"
    );
  },
});

export default { MANAGER_WORKER_LIST };
