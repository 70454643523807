import { useCallback } from "react";
import { useQueryClient } from "react-query";

import {
  RESET_PLACER_AND_WAREHOUSING_OF_ITEM_REQ_PATH_PARAMS,
  RESET_WAREHOUSING_OF_ITEM_EXPECT_PLACER_REQ,
} from "@sellernote/_shared/src/api-interfaces/boful-api/receiving";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";

export default function useResetWarehousingOfItem() {
  const queryClient = useQueryClient();

  const {
    mutate: resetWarehousingOfItemExceptPlacer,
    ResponseHandler: ResponseHandlerOfResetWarehousingOfItemExceptPlacer,
  } = RECEIVING_QUERY.useResetWarehousingOfItemExceptPlacer();

  const resetWarehousingOfItem = useCallback(
    ({
        receivingId,
        itemId,
        placingId,
        resetLocalCount,
      }: RESET_WAREHOUSING_OF_ITEM_EXPECT_PLACER_REQ &
        RESET_PLACER_AND_WAREHOUSING_OF_ITEM_REQ_PATH_PARAMS & {
          resetLocalCount: () => void;
        }) =>
      () => {
        if (!receivingId) return;

        resetWarehousingOfItemExceptPlacer(
          { pathParams: { receivingId, itemId }, placingId },
          {
            onSuccess: () => {
              resetLocalCount();

              queryClient.invalidateQueries(
                RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
                  receivingId,
                })
              );
            },
          }
        );
      },
    [queryClient, resetWarehousingOfItemExceptPlacer]
  );

  return {
    resetWarehousingOfItem,
    ResponseHandlerOfResetWarehousingOfItemExceptPlacer,
  };
}
