import styled from "styled-components";

const selectorBody = styled.div`
  > .input-select {
    text-align: left;
  }

  > .button {
    margin-top: 40px;
  }
`;

export default {
  selectorBody,
};
