import { useHistory } from "react-router-dom";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";

import { returningSelectors } from "modules/returning";
import { useAppSelector } from "store";

function CompleteConfirm({ returningId }: { returningId: number }) {
  const history = useHistory();

  const { canCompleteConfirmationReturning } = useAppSelector((state) => {
    return {
      canCompleteConfirmationReturning:
        returningSelectors.getCanCompleteConfirmation(state),
    };
  });

  const { mutate: setArrived, ResponseHandler: ResponseHandlerOfSetArrived } =
    RETURNING_QUERY.useSetArrived({ returningId });

  return (
    <>
      <Button
        theme="primary"
        size="block"
        label={"제품수령완료"}
        handleClick={() => {
          setArrived(undefined, {
            onSuccess: () => {
              history.replace("/returning/confirm");
            },
          });
        }}
        disabled={!canCompleteConfirmationReturning}
      />

      {ResponseHandlerOfSetArrived}
    </>
  );
}

export default CompleteConfirm;
