import { BofulWorker } from "@sellernote/_shared/src/types/fulfillment/auth";

function getManagerName({
  getWorkerById,
  placerIds,
}: {
  getWorkerById: (workerId?: number | undefined) => BofulWorker | undefined;
  placerIds: number[];
}) {
  if (!placerIds.length) return "";

  const isMultiWarehousing = placerIds.length > 1;

  const setOfPlacerIds = new Set(placerIds);

  const isMultiWorker = setOfPlacerIds.size > 1;

  const firstWorkerName = getWorkerById(placerIds[0])?.name ?? "";

  return isMultiWarehousing
    ? `[분할]${firstWorkerName}${
        isMultiWorker ? ` 외 ${setOfPlacerIds.size - 1}` : ""
      }`
    : firstWorkerName;
}

export { getManagerName };
