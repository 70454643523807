import { combineReducers } from "@reduxjs/toolkit";
import { all } from "redux-saga/effects";

import { loadingReducer } from "./loading";
import { returningReducer, returningSaga } from "./returning";

export const rootReducer = combineReducers({
  loading: loadingReducer,
  returning: returningReducer,
});

export function* rootSaga() {
  yield all([returningSaga()]);
}
