import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.section`
  figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 114px auto 0;

    img {
      margin-bottom: 24px;
    }

    figcaption {
      color: ${COLOR.primaryBlue};
      ${setFontStyle("Head6")}
    }
  }
`;

export default {
  container,
};
