import React, { useCallback, useEffect, useMemo, useState } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import SHIPPING_QUERY from "@sellernote/_shared/src/queries/fulfillment/SHIPPING_QUERY";
import { omitWithEllipsis } from "@sellernote/_shared/src/utils/common/string";
import { getStyledSKUCount } from "@sellernote/_shared/src/utils/fulfillment/sku";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import useScan from "hooks/common/useScan";
import ScanShipmentInvoiceModal from "pages/shipping/shipment/closing/truck/:company/:status/ConSigneeSignatureModal/ScanShipmentInvoiceModal";

interface QRListTableItem {
  QRNo: React.ReactNode;
  isScanned: string;
}

interface ScanQRList {
  completedList: Set<string>;
  scanList: string[];
}

export default function useScanShipmentInvoice({
  shippingId,
  visibleScanModal,
  setVisibleScanModal,
}: {
  shippingId: number;
  visibleScanModal: boolean;
  setVisibleScanModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [QRList, setQRList] = useState<ScanQRList>({
    completedList: new Set(),
    scanList: [],
  });
  const [scanMessageModalTitle, setMessageModalTitle] = useState<string>();
  const [isCompleted, setIsCompleted] = useState(false);

  const {
    data: shipmentTruckPackings,
    ResponseHandler: ResponseHandlerOfGettingShipmentTruckPackings,
  } = SHIPPING_QUERY.useGetShipmentTruckPackings({
    shippingId: shippingId ?? 0,
  });

  useEffect(() => {
    setQRList({
      completedList: new Set(),
      scanList: (shipmentTruckPackings?.packings || []).map((v) => v.invoiceNo),
    });
  }, [shipmentTruckPackings?.packings]);

  const isActiveCompleteButton =
    QRList.completedList.size !== 0 &&
    QRList.completedList.size === QRList.scanList.length;

  const countQR = useCallback(
    (result: string) => {
      // 모달이 활성화 되기 전에는 스캔 되더라도 스킵한다.
      if (!visibleScanModal) {
        return;
      }

      if (isActiveCompleteButton) {
        setMessageModalTitle(`QR번호 스캔이 모두 완료된 상태입니다.`);
        return;
      }

      if (!QRList.scanList.includes(result)) {
        setMessageModalTitle(`리스트에 없는 QR번호 입니다.`);
        return;
      }

      if (QRList.completedList.has(result)) {
        setMessageModalTitle(`이미 스캔이 완료된 QR번호 입니다.`);
        return;
      }

      setQRList((prev) => ({
        ...prev,
        completedList: new Set(prev.completedList).add(result),
      }));
    },
    [
      QRList.completedList,
      QRList.scanList,
      visibleScanModal,
      isActiveCompleteButton,
    ]
  );
  useScan(countQR);

  const ScanMessageModal = useMemo(
    () => (
      <Modal
        uiType="titleOnly"
        active={scanMessageModalTitle !== undefined}
        title={scanMessageModalTitle}
        actionPositive={{
          label: "확인",
          handleClick: () => setMessageModalTitle(undefined),
        }}
      />
    ),
    [scanMessageModalTitle]
  );

  const handleModalClose = useCallback(() => {
    setVisibleScanModal(false);
  }, [setVisibleScanModal]);

  const handleScanComplete = useCallback(() => {
    setVisibleScanModal(false);
    setIsCompleted(true);
  }, [setVisibleScanModal]);

  const scanDataList = useMemo((): TableDataListItem<QRListTableItem>[] => {
    const rawList = shipmentTruckPackings?.packings;

    if (!rawList) {
      return [];
    }

    return rawList.map((v) => ({
      rowKey: v.id,
      QRNo: omitWithEllipsis({
        src: v.invoiceNo,
        maxLength: 20,
        ellipsis: "...",
      }),
      isScanned: QRList.completedList.has(v.invoiceNo) ? "○" : "✕",
    }));
  }, [QRList, shipmentTruckPackings?.packings]);

  const scanShipmentInvoiceModalBody = useMemo(() => {
    const data = shipmentTruckPackings;

    return (
      <>
        <ListItem
          type="withContentAlignRight"
          label="출고요청번호"
          value={data?.id}
        />
        <ListItem
          type="withContentAlignRight"
          label="고객사명"
          value={`${data?.team?.company}(${data?.team?.id})`}
        />
        <ListItem
          type="withContentAlignRight"
          label="총 출하 건"
          value={getStyledSKUCount({
            currentCount: QRList.completedList.size,
            goalCount: QRList.scanList.length,
          })}
        />

        <Table<QRListTableItem>
          columnInfo={{
            QRNo: {
              label: "QR번호",
              fixedWidth: 194,
            },
            isScanned: {
              label: "스캔완료",
              fixedWidth: 96,
            },
          }}
          dataList={scanDataList}
        />

        {/* 스캔이 모두 완료 되어야 버튼이 활성화된다 */}
        <Button
          className="complete-button"
          theme="primary"
          label="스캔완료"
          size="normal"
          disabled={!isActiveCompleteButton}
          handleClick={handleScanComplete}
        />
      </>
    );
  }, [
    QRList.completedList.size,
    QRList.scanList.length,
    shipmentTruckPackings,
    handleScanComplete,
    isActiveCompleteButton,
    scanDataList,
  ]);

  const ScanModal = useMemo(
    () => (
      <ScanShipmentInvoiceModal
        active={visibleScanModal}
        body={scanShipmentInvoiceModalBody}
        handleModalClose={handleModalClose}
      />
    ),
    [handleModalClose, scanShipmentInvoiceModalBody, visibleScanModal]
  );

  return {
    isCompleted,

    ScanMessageModal,
    ScanModal,

    ResponseHandlerOfGettingShipmentTruckPackings,
  };
}
