import RequestResponseHandler, {
  RequestResponseHandlerLoading,
  RequestResponseHandlerProps,
} from "@sellernote/_shared/src/components/RequestResponseHandler";

import { useAppSelector } from "store";

/**
 * Boful에서 RequestResponseHandler를 사용할 때 반복해서 넣어줘야하는 속성들(combinedLoadingState, isBoful)을
 * 자동으로 넣어주는 Wrapper 컴포넌트
 */
export default function BofulRequestResponseHandler({
  response,
  initRequest,
  loading,
  successModalInfo,
  failureModalInfo,
  className,
}: {
  loading?: Pick<
    RequestResponseHandlerLoading,
    "actionType" | "actionTypeKey" | "label"
  >;
} & Omit<RequestResponseHandlerProps, "loading">) {
  const { combinedLoading } = useAppSelector((state) => {
    return {
      combinedLoading: state.loading,
    };
  });

  return (
    <RequestResponseHandler
      response={response}
      initRequest={initRequest}
      loading={
        loading
          ? {
              combinedLoadingState: combinedLoading,
              actionType: loading?.actionType,
              actionTypeKey: loading?.actionTypeKey,
              label: loading?.label,
            }
          : undefined
      }
      successModalInfo={successModalInfo}
      failureModalInfo={failureModalInfo}
      className={className}
    />
  );
}
