import { TypedUseSelectorHook, useSelector } from "react-redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import { IS_UNDER_LOCAL_DEVELOPMENT } from "@sellernote/_shared/src/constants";

import { rootReducer, rootSaga } from "./modules";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: {
        // Ignore these field paths in all actions
        ignoredActionPaths: [
          "payload._postSuccessActionCreator",
          "payload._postSuccessCallback",
          "payload._postFailureCallback",
        ],
      },
    }),
    sagaMiddleware,
  ],
  devTools: IS_UNDER_LOCAL_DEVELOPMENT,
});

sagaMiddleware.run(rootSaga);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ReturnType<typeof store.dispatch>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// export const useAppDispatch = () => useDispatch<AppDispatch>(); // guide에서는 쓰라고 하는데 왜 필요한지 모르겠음
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
