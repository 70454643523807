import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { FulfillmentAttachment } from "@sellernote/_shared/src/types/fulfillment/fulfillment";

import { returningActions } from "modules/returning";

export default function useResetInvoiceInfo({
  returningId,
  isInvoiceMatched,
  attachments,
}: {
  returningId: number;
  isInvoiceMatched: boolean;
  attachments: FulfillmentAttachment[];
}) {
  const dispatch = useDispatch();

  const {
    mutate: resetInvoice,
    ResponseHandler: ResponseHandlerOfResetInvoiceInfo,
  } = RETURNING_QUERY.useResetInvoiceInfo({ returningId });

  const needToResetInvoiceInfo = useMemo(() => {
    const hasAttachment = !!attachments.find((v) => v.domain === "invoice");

    const hasPreviousInfo = hasAttachment || isInvoiceMatched;

    return hasPreviousInfo;
  }, [isInvoiceMatched, attachments]);

  const resetInvoiceInfo = useCallback(
    ({
      returningId,
      callbackAfterReset,
    }: {
      returningId: number;
      callbackAfterReset: () => void;
    }) => {
      resetInvoice(undefined, {
        onSuccess: () => {
          dispatch(
            returningActions.GET_DETAIL({
              id: returningId,
              _postSuccessCallback: callbackAfterReset,
            })
          );
        },
      });
    },
    [dispatch, resetInvoice]
  );

  return {
    needToResetInvoiceInfo,
    resetInvoiceInfo,
    ResponseHandlerOfResetInvoiceInfo,
  };
}
