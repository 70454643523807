import { useMemo, useState } from "react";

/**
 * 기존 packages/_shared/src/utils/common/hook.ts > useClientSidePaging의 경우 페이지 초기화 관련 이슈가 있어서 새롭게 추가
 */
export default function useClientSidePaging<T>(
  perPage: number,
  dataSource: T[]
) {
  const [page, setPage] = useState(0);

  const pageData = useMemo(() => {
    const startIndex = page * perPage;
    const endIndex = startIndex + perPage;

    return dataSource.slice(startIndex, endIndex);
  }, [page, perPage, dataSource]);

  const totalCount = dataSource.length;

  const pageSize = Math.ceil(totalCount / perPage) || 0;

  return {
    page,
    setPage,
    pageData,
    totalCount,
    pageSize,
  };
}
