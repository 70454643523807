import { useState } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { PickingSKUInfoForScanning } from "@sellernote/_shared/src/types/fulfillment/shipping";

import useCompletePicking from "hooks/shipping/useCompletePicking";

export default function CompletePickingButton({
  isAllPickingItemListCompletedWithMatchedQuantity,
  SKUInfoForScanning,
  resetPickingState,
}: {
  isAllPickingItemListCompletedWithMatchedQuantity: boolean;
  SKUInfoForScanning: PickingSKUInfoForScanning[];
  resetPickingState: () => void;
}) {
  const [showsConfirmModal, setShowsConfirmModal] = useState(false);

  const handleConfirmModalOpen = () => {
    setShowsConfirmModal(true);
  };

  const handleConfirmModalClose = () => {
    setShowsConfirmModal(false);
  };

  const {
    invoiceNo,
    isActiveCompleteButton,
    checkIsCanceledInvoice,
    CanceledInvoiceModal,

    ResponseHandlerOfCheckingCancelingInvoice,
    ResponseHandlerOfCompletingPicking,
    ResponseHandlerOfStartingPacking,
    ResponseHandlerOfCompletingPacking,
    ResponseHandlerOfSettingRestockByPickingQuantity,
  } = useCompletePicking({
    SKUInfoForScanning,
    resetPickingState,
  });

  return (
    <>
      <Button
        theme="primary"
        size="block"
        label="피킹완료"
        handleClick={
          isAllPickingItemListCompletedWithMatchedQuantity
            ? checkIsCanceledInvoice
            : handleConfirmModalOpen
        }
        className="complete-picking-button"
        disabled={!isActiveCompleteButton}
      />

      <Modal
        active={showsConfirmModal}
        uiType="content"
        title={`${invoiceNo} (송장(QR)번호)`}
        body={
          <>
            작업수량과 주문수량이 불일치하여
            <br />
            피킹을 완료할 수 없습니다.
          </>
        }
        actionPositive={{
          label: "확인",
          handleClick: handleConfirmModalClose,
        }}
      />

      {CanceledInvoiceModal}

      {ResponseHandlerOfCheckingCancelingInvoice}
      {ResponseHandlerOfCompletingPicking}
      {ResponseHandlerOfStartingPacking}
      {ResponseHandlerOfCompletingPacking}
      {ResponseHandlerOfSettingRestockByPickingQuantity}
    </>
  );
}
