import { useMemo } from "react";

import { TempPhotoItem } from "hooks/common/useSelectPhotoList";

import { Form } from ".";

export default function useValid({
  form,
  prevForm,
  inspectionCount,
  problemTotalQuantity,
  tempPhotoList,
  isChangedPhoto,
}: {
  form: Form;
  prevForm: Form;
  inspectionCount: number;
  problemTotalQuantity: number;
  tempPhotoList: TempPhotoItem[];
  isChangedPhoto: boolean;
}) {
  const isValidIncludeUnverifiedName = !form.problem
    ?.replace(/\s+/g, "")
    .includes("불일치");

  const isValidProblemName =
    form.problem && form.problem.length <= 20 && isValidIncludeUnverifiedName;

  const hasQuantity = form.quantity && (form.quantity ?? 0) > 0;

  // 검수 수량 (이미 등록한 비정상수량은 제외) 만큼만 입력가능. (현재 입력하고 있는 item 의 수량은 제외)
  const canInputQuantity =
    inspectionCount - (problemTotalQuantity - (prevForm?.quantity ?? 0)) >=
    (form.quantity ?? 0);

  const isValidQuantity = hasQuantity && canInputQuantity;

  const isValidForm =
    isValidProblemName && !!isValidQuantity && !!tempPhotoList.length;

  const isChangedForm = useMemo(() => {
    const isChangedProblem = prevForm?.problem !== form.problem;

    const isChangedQuantity = prevForm?.quantity !== Number(form.quantity);

    return isChangedProblem || isChangedQuantity || isChangedPhoto;
  }, [
    form.problem,
    form.quantity,
    isChangedPhoto,
    prevForm?.problem,
    prevForm?.quantity,
  ]);

  // 수정 시, 문제명, 수량, 메모가 변경되었는지 확인.
  // 업로드 할 사진이 있는지 확인, 삭제했다면 남아있는 사진이나 업로드 할 사진이 하나라도 있는지 확인.
  const isValidUpdateForm = useMemo(() => {
    return isChangedForm && isValidProblemName && !!isValidQuantity;
  }, [isChangedForm, isValidProblemName, isValidQuantity]);

  const problemNameErrorMessage =
    (form.problem?.length > 20 && "20자 이하로 입력해주세요.") ||
    (!isValidIncludeUnverifiedName && "불일치 상품은 입력 불가능합니다.");

  const quantityErrorMessage =
    (form.quantity && !hasQuantity && "양의 정수만 입력 가능합니다") ||
    (!canInputQuantity && "검수 수량 내에서만 입력 가능합니다.");

  return {
    isValidUpdateForm,
    isValidForm,
    problemNameErrorMessage,
    quantityErrorMessage,
    isChangedForm,
  };
}
