import { useMemo } from "react";
import { useAtom } from "jotai";
import { RETURNING_INSPECTION_ATOMS } from "jotaiStates/returning/inspection";

import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";

export default function useUnverifiedList({
  items,
}: {
  items?: ReceivingItem[];
}) {
  const [scannedUnregisteredUnverifiedList] = useAtom(
    RETURNING_INSPECTION_ATOMS.SCANNED_UNREGISTERED_UNVERIFIED_LIST
  );

  const registeredUnverifiedList = useMemo(
    () => items?.filter(({ quantity }) => quantity === 0),
    [items]
  );

  const registeredUnverifiedCount = useMemo(
    () =>
      registeredUnverifiedList?.reduce(
        (acc, { actualQty }) => acc + (actualQty ?? 0),
        0
      ) ?? 0,
    [registeredUnverifiedList]
  );

  const scannedUnregisteredUnverifiedCount = useMemo(
    () =>
      scannedUnregisteredUnverifiedList.reduce(
        (acc, cur) => acc + (cur.quantity ?? 0),
        0
      ),
    [scannedUnregisteredUnverifiedList]
  );

  return {
    registeredUnverifiedList,
    registeredUnverifiedCount,
    scannedUnregisteredUnverifiedCount,
  };
}
