import { BofulWorker } from "@sellernote/_shared/src/types/fulfillment/auth";
import { ReceivingInspectItem } from "@sellernote/_shared/src/types/fulfillment/receiving";

import {
  CounterDataForInspection,
  getCounterKeyForMultiLocationInspection,
  getCounterKeyForSingleLocationInspection,
} from "hooks/receiving/useSKUCountingForInspection";

function getCanReset({
  skuId,
  counterData,
  hasRightToReset,
  hasMultiLocation,
  itemId,
}: {
  skuId: number;
  counterData: CounterDataForInspection;
  hasRightToReset: boolean;
  hasMultiLocation: boolean;
  itemId: number;
}) {
  if (hasMultiLocation) {
    return hasRightToReset;
  }

  const counterKeyAsSingleLocation = getCounterKeyForSingleLocationInspection({
    skuId,
    itemId,
  });
  const itemCounter = counterData[counterKeyAsSingleLocation];

  return (
    hasRightToReset &&
    (!!itemCounter?.current || !!itemCounter?.isCompleteInspecting)
  );
}

function getHasRightToReset({
  hasMultiLocation,
  currentManagerId,
  inspectItems,
}: {
  hasMultiLocation: boolean;
  currentManagerId: number | undefined;
  inspectItems: ReceivingInspectItem[];
}) {
  // 분할검수일때는 검수자에 해당하면 권한을 부여
  if (hasMultiLocation) {
    return inspectItems?.some((ii) => ii.inspectorId === currentManagerId);
  }

  return currentManagerId === inspectItems[0].inspectorId;
}

function getCurrentSKUCount({
  skuId,
  counterData,
  inspectItems,
  hasMultiLocation,
  itemId,
}: {
  skuId: number;
  counterData: CounterDataForInspection;
  inspectItems: ReceivingInspectItem[];
  hasMultiLocation: boolean;
  itemId: number;
}) {
  if (!counterData) return 0;

  return inspectItems.reduce((a, c) => {
    const counterKey = hasMultiLocation
      ? getCounterKeyForMultiLocationInspection({
          skuId,
          inspectingId: c.inspectingId,
        })
      : getCounterKeyForSingleLocationInspection({
          skuId,
          itemId,
        });

    const currentTempCount = counterData[counterKey]?.current;

    return a + (currentTempCount || c.actualQty || 0);
  }, 0);
}

function getPersonInCharge({
  inspecItems,
  getWorkerById,
}: {
  inspecItems: ReceivingInspectItem[];
  getWorkerById: (workerId?: number | undefined) => BofulWorker | undefined;
}) {
  if (!inspecItems.length) return "";

  const workerIds = inspecItems.reduce((a: number[], c) => {
    if (c.inspectorId) {
      a.push(c.inspectorId);
    }
    return a;
  }, []);

  const setOfWorkerIds = new Set(workerIds);

  const isMultiInspection = workerIds.length > 1;

  const firstWorkerName = getWorkerById(workerIds[0])?.name ?? "";

  const isMultiWorker = setOfWorkerIds.size > 1;

  return isMultiInspection
    ? `[분할]${firstWorkerName} ${
        isMultiWorker ? ` 외 ${setOfWorkerIds.size - 1}` : ""
      }`
    : firstWorkerName;
}

export {
  getCanReset,
  getHasRightToReset,
  getCurrentSKUCount,
  getPersonInCharge,
};
