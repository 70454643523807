import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import usePaging, {
  PagingProps,
} from "@sellernote/_shared/src/headlessComponents/usePaging";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

import Styled from "./index.styles";

/**
 * paging 영역만 공통으로 쓰고 page리스트 데이터(currentPageData)는 자유로운 커스터마이징이 가능하도록 함
 */
export default function Paging({
  pageSize,
  currentPage,
  currentPageData,
  handleClickPage,
  isZeroBasedPage,
  selectedCount,
  resetsSelectedCountOnPageChange = true, // 페이지 이동시 선택된 항목들 초기화 여부에 따라 다른 텍스트 표시를 위한 변수
  className,
}: PagingProps) {
  const {
    adjustedCurrentPage,
    isArrowVisible,
    canJumpToPreviousPageGroup,
    canJumpToNextPageGroup,
    currentPageGroupNum,
    visiblePageLength,
    firstPageOfVisiblePageGroup,
    MAX_VISIBLE_PAGE_COUNT,
  } = usePaging({
    pageSize,
    currentPage,
    isZeroBasedPage,
    selectedCount,
  });

  const renderSelectedCountText = (
    selectedCount: number,
    resetsSelectedCountOnPageChange: boolean
  ) => {
    if (selectedCount === 0) return <></>;

    if (resetsSelectedCountOnPageChange === true)
      return (
        <>
          이 페이지에 있는 상품 <strong>{selectedCount}</strong>개가
          선택되었습니다.
        </>
      );

    return (
      <>
        상품 <strong>{selectedCount}</strong>개가 선택되었습니다.
      </>
    );
  };

  return (
    <Styled.container className={`${className ? className : ""} paging`}>
      {selectedCount !== undefined && (
        <Styled.selectedCount>
          {renderSelectedCountText(
            selectedCount,
            resetsSelectedCountOnPageChange
          )}
        </Styled.selectedCount>
      )}

      <Styled.dataContainer>{currentPageData}</Styled.dataContainer>

      {pageSize > 1 && adjustedCurrentPage >= 1 && (
        <Styled.pagination>
          {isArrowVisible && (
            <Icon
              type="arrowLeft"
              size={1.125}
              color={
                canJumpToPreviousPageGroup
                  ? COLOR.primaryBlue
                  : COLOR.grayScale_300
              }
              className={`paging-arrow ${
                !canJumpToPreviousPageGroup ? "disabled" : ""
              }`}
              onClick={() => {
                const firstPageOfPreviousPageGroup = isZeroBasedPage
                  ? (currentPageGroupNum - 1) * MAX_VISIBLE_PAGE_COUNT - 1
                  : (currentPageGroupNum - 1) * MAX_VISIBLE_PAGE_COUNT;

                if (canJumpToPreviousPageGroup)
                  handleClickPage(firstPageOfPreviousPageGroup);
              }}
            />
          )}

          <Styled.pageIndicator>
            {[...Array(visiblePageLength)].map((_, i) => {
              const pageNum = firstPageOfVisiblePageGroup + i;

              /**
               * 다음페이지를 셋팅하는 함수로 전달되는 페이지는 0기반 페이징여부에 따라 처리가 필요함
               */
              const pageNumForAction = isZeroBasedPage ? pageNum - 1 : pageNum;

              const selected = adjustedCurrentPage === pageNum;

              return (
                <div
                  className={`item ${selected ? "selected" : ""}`}
                  key={i}
                  onClick={() => handleClickPage(pageNumForAction)}
                >
                  {pageNum}
                </div>
              );
            })}
          </Styled.pageIndicator>

          {isArrowVisible && (
            <Icon
              type="arrowRight"
              size={1.125}
              color={
                canJumpToNextPageGroup ? COLOR.primaryBlue : COLOR.grayScale_300
              }
              className={`paging-arrow ${
                !canJumpToNextPageGroup ? "disabled" : ""
              }`}
              onClick={() => {
                const firstPageOfNextPageGroup = isZeroBasedPage
                  ? currentPageGroupNum * MAX_VISIBLE_PAGE_COUNT
                  : currentPageGroupNum * MAX_VISIBLE_PAGE_COUNT + 1;

                if (canJumpToNextPageGroup)
                  handleClickPage(firstPageOfNextPageGroup);
              }}
            />
          )}
        </Styled.pagination>
      )}
    </Styled.container>
  );
}
