import React, { useCallback } from "react";
import { useRecoilValue } from "recoil";

import { FULFILLMENT_CANCELING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/canceling";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { SKUInfoForCancelingRestocking } from "@sellernote/_shared/src/types/fulfillment/canceling";
import { ScanType } from "@sellernote/_shared/src/types/fulfillment/scan";

import useScan from "hooks/common/useScan";

import useCancelingMessageModal from "./useCancelingMessageModal";
import { CompletedList, WorkingLocation } from "./useRestockCanceling";

/**
 * 취소 재입고 위치 스캔
 */
export default function useScanCancelingLocation({
  selectedScanButtonType,
  onScanButtonSelect,
  workingLocation,
  setWorkingLocation,
  SKUInfoForScanning,
  completedList,
}: {
  selectedScanButtonType: ScanType | undefined;
  onScanButtonSelect: (type: ScanType | undefined) => void;
  workingLocation: WorkingLocation | undefined;
  setWorkingLocation: React.Dispatch<
    React.SetStateAction<WorkingLocation | undefined>
  >;
  SKUInfoForScanning: SKUInfoForCancelingRestocking[];
  completedList: React.MutableRefObject<CompletedList>;
}) {
  const locationIdListInRestockingList = useRecoilValue(
    FULFILLMENT_CANCELING_SELECTORS.LOCATION_ID_LIST_IN_RESTOCKING_LIST
  );
  const locationListOfWarehouse = useRecoilValue(
    FULFILLMENT_COMMON_ATOMS.LOCATION_LIST_OF_WAREHOUSE
  );

  const { MessageModal, modalOpenHandler } = useCancelingMessageModal();

  const setLocationScanningResult = useCallback(
    (locationScanningResult: WorkingLocation) => {
      setWorkingLocation(locationScanningResult);
    },
    [setWorkingLocation]
  );

  const checkIsCorrectLocation = useCallback(
    ({ scannedBarcode }: { scannedBarcode: string }) => {
      const scannedLocationId =
        locationListOfWarehouse.find(
          (location) => location.barCode === scannedBarcode
        )?.id ?? 0;

      // 해당 위치(A1-1)은 상품을 재입고할 위치가 아닙니다.
      if (!locationIdListInRestockingList.includes(scannedLocationId)) {
        modalOpenHandler.openNoRestockingLocationMessage(scannedBarcode);
        return;
      }

      // 해당 위치(A1-1)에서의 재입고는 이미 완료되었습니다.
      if (completedList.current.locationIdList.has(scannedLocationId)) {
        modalOpenHandler.openCompletedLocationMessage(scannedBarcode);
        return;
      }

      // 현재 위치가 완료되기 전에 다른 위치를 스캔 한 경우
      // 올바른 SKU ID를 스캔해주세요.
      const SKUInfoByWorkingLocationId = SKUInfoForScanning.filter(
        (v) => v.locationId === workingLocation?.id
      );
      const isStartWorkingInWorkingLocation = SKUInfoByWorkingLocationId.some(
        (v) => v.currentQty > 0
      );
      const isAllCompletedWorkingLocation = SKUInfoByWorkingLocationId.every(
        (v) => v.currentQty === v.quantity
      );
      if (isStartWorkingInWorkingLocation && !isAllCompletedWorkingLocation) {
        modalOpenHandler.openCorrectSKUIdScanMessage();
        return;
      }

      const scannedSkuInfo = SKUInfoForScanning.find(
        (v) => v.locationId === scannedLocationId
      );

      setLocationScanningResult({
        id: scannedLocationId,
        barcode: scannedBarcode,
        managementKind: scannedSkuInfo?.managementKind,
        managementDate: scannedSkuInfo?.managementDate,
      });

      onScanButtonSelect("sku");
    },
    [
      SKUInfoForScanning,
      completedList,
      locationIdListInRestockingList,
      locationListOfWarehouse,
      modalOpenHandler,
      onScanButtonSelect,
      setLocationScanningResult,
      workingLocation?.id,
    ]
  );

  const scanLocation = useCallback(
    (scannedBarcode: string) => {
      if (selectedScanButtonType !== "location") {
        return;
      }

      checkIsCorrectLocation({ scannedBarcode });
    },
    [checkIsCorrectLocation, selectedScanButtonType]
  );
  useScan(scanLocation);

  return {
    ScanLocationMessageModal: MessageModal,
  };
}
