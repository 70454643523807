import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

import { ChipWithClearColorTheme } from ".";

const container = styled.div<{ colorTheme?: ChipWithClearColorTheme }>`
  padding: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${(props) =>
    props.colorTheme === "light" ? COLOR.white : COLOR.bgColor_2};

  > .label {
    margin-bottom: -3px;
    color: ${COLOR.grayScale_700};
    ${setFontStyle("SubHead3", "Bold")};

    margin-right: 1.33px;
  }
`;

export default {
  container,
};
