import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

import Styled from "./index.styles";

export interface CancelingDetailSummaryInfo {
  cancelingId: number;
  canceller: string;
  customer: string;
  shippingId: number;
  invoiceNo: string;
  statusWhenCanceled: string;
  workerWhenCanceled: string;
  restocker: string;
}

export default function Summary({
  cancelingDetailSummary,
  packingRestocker,
}: {
  cancelingDetailSummary: CancelingDetailSummaryInfo;
  packingRestocker: string | undefined;
}) {
  return (
    <Styled.detailSummaryContainer>
      <ListItem
        type="withContentAlignRight"
        label="취소번호"
        value={cancelingDetailSummary.cancelingId}
      />
      <ListItem
        type="withContentAlignRight"
        label="취소주체"
        value={cancelingDetailSummary.canceller}
      />
      <ListItem
        type="withContentAlignRight"
        label="회사명 (팀명)"
        value={cancelingDetailSummary.customer}
      />
      <ListItem
        type="withContentAlignRight"
        label="출고요청번호"
        value={cancelingDetailSummary.shippingId}
      />
      <ListItem
        type="withContentAlignRight"
        label="송장(QR)번호"
        value={cancelingDetailSummary.invoiceNo}
      />
      <ListItem
        type="withContentAlignRight"
        label="취소시점"
        value={cancelingDetailSummary.statusWhenCanceled}
      />
      <ListItem
        type="withContentAlignRight"
        label="취소시점 담당자"
        value={cancelingDetailSummary.workerWhenCanceled}
      />
      <ListItem
        type="withContentAlignRight"
        label="재입고 담당자"
        value={
          cancelingDetailSummary.restocker.length !== 0
            ? cancelingDetailSummary.restocker
            : packingRestocker
        }
      />
    </Styled.detailSummaryContainer>
  );
}
