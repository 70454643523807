import { useState } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";

import SKUListModal from "./SKUListModal";

/**
 * 반품 문제보고
 */
function ReportReturningProblem({
  returningId,
  isDisabled,
}: {
  returningId: number;
  isDisabled: boolean;
}) {
  const [isVisibleProblemList, setIsVisibleProblemList] = useState(false);

  return (
    <>
      <Button
        label="입고 마감"
        size="block"
        theme="primary"
        handleClick={() => setIsVisibleProblemList(true)}
        disabled={isDisabled}
      />

      {isVisibleProblemList && (
        <SKUListModal
          closeModal={() => setIsVisibleProblemList(false)}
          returningId={returningId}
        />
      )}
    </>
  );
}

export default ReportReturningProblem;
