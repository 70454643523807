import React from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { ScanType } from "@sellernote/_shared/src/types/fulfillment/scan";
import { PickingSKUInfoForScanning } from "@sellernote/_shared/src/types/fulfillment/shipping";

import {
  CompletedList,
  WorkingLocation,
} from "hooks/shipping/usePickingInvoice";
import useScanPickingLocation from "hooks/shipping/useScanPickingLocation";

export default function ScanLocationButton({
  selectedScanButtonType,
  handleScanButtonClick,
  workingLocation,
  setWorkingLocation,
  SKUInfoForScanning,
  completedList,
}: {
  selectedScanButtonType: ScanType | undefined;
  handleScanButtonClick: (type: ScanType | undefined) => void;
  workingLocation: WorkingLocation | undefined;
  setWorkingLocation: React.Dispatch<
    React.SetStateAction<WorkingLocation | undefined>
  >;
  SKUInfoForScanning: PickingSKUInfoForScanning[];
  completedList: React.MutableRefObject<CompletedList>;
}) {
  const { isActiveScanLocationButton, ScanLocationMessageModal } =
    useScanPickingLocation({
      selectedScanButtonType,
      workingLocation,
      setWorkingLocation,
      SKUInfoForScanning,
      completedList,
      onScanButtonSelect: handleScanButtonClick,
    });

  return (
    <>
      <Button
        className="scan-button"
        theme={selectedScanButtonType === "location" ? "scan" : "secondary"}
        size="small"
        label="위치스캔"
        handleClick={() => handleScanButtonClick("location")}
        disabled={!isActiveScanLocationButton}
      />

      {ScanLocationMessageModal}
    </>
  );
}
