import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { UseCounterDataValue } from "@sellernote/_shared/src/utils/common/hook";

import { InspectionCounterSKU } from "hooks/receiving/useSKUCountingForInspection";

import useCompleteInspection from "./useCompleteInspection";

function CompleteInspection({
  locationType,
  receivingId,
  counterInProgress,
  resetAfterComplete,
}: {
  locationType: "single" | "multi";
  receivingId: number;
  counterInProgress: UseCounterDataValue<InspectionCounterSKU> | undefined;
  resetAfterComplete: () => void;
}) {
  const {
    canComplete,
    handleInspectionComplete,

    ConfirmModal,

    ResponseHandlerOfSetItemInspectionDone,
  } = useCompleteInspection({
    locationType,
    receivingId,
    counterInProgress,
    resetAfterComplete,
  });

  return (
    <>
      <Button
        className="btn-complete"
        label={locationType === "single" ? "검수 완료" : "분할검수 완료"}
        size="block"
        theme="primary"
        handleClick={handleInspectionComplete}
        disabled={!canComplete}
      />

      {ConfirmModal}

      {ResponseHandlerOfSetItemInspectionDone}
    </>
  );
}

export default CompleteInspection;
