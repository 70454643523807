import { useCallback, useMemo, useState } from "react";
import axios from "axios";

import { GET_PRESIGNED_URL_RES } from "@sellernote/_shared/src/api-interfaces/boful-api/file";
import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import FILE_QUERY from "@sellernote/_shared/src/queries/fulfillment/FILE_QUERY";
import { FileMimeType } from "@sellernote/_shared/src/types/common/common";
import { FileTypes } from "@sellernote/_shared/src/types/common/upload";
import { ForwardingFileDomain } from "@sellernote/_shared/src/types/forwarding/bid";
import {
  FileActorKindType,
  FileDomainType,
} from "@sellernote/_shared/src/types/fulfillment/fulfillment";

interface HandleSuccessUpload {
  // 구체적인 타입을 지정하기 어려워 unknown으로 변경
  bindingSuccessData: unknown;
  bindingData: BindingAttachments[];
}

export interface ITEM_TO_GET_PRESIGNED_URL {
  name: string;
  domain: FileDomainType;
  targetId: number;
  actorKind: FileActorKindType;
  mimeType: FileMimeType;
}

interface BindingAttachments {
  key: string;
  domain: ForwardingFileDomain | FileDomainType;
  targetId: number | undefined;
}

type ApiType = "boful";

// 기존 useUploadFilesV2 에 boful 타입 추가 및
// 필요하지 않은 포워딩용 로직 삭제하여 boful pda 용으로 수정
export default function useUploadFiles() {
  const [isUploading, setIsUploading] = useState(false);

  const [isFailed, setIsFailed] = useState(false);

  const { mutate: getPresignedURL } = FILE_QUERY.useGetPresignedURL();

  const { mutate: bindFileAfterUpload } = FILE_QUERY.useBindFileAfterUpload();

  const FailureModal = useMemo(() => {
    if (!isFailed) return null;

    return (
      <Modal
        active
        uiType="content"
        title="업로드 중에 오류가 발생했습니다."
        body="고객센터에 문의해주세요"
        actionPositive={{
          label: "확인",
          handleClick: () => {
            setIsFailed(false);
          },
        }}
      />
    );
  }, [isFailed]);

  const handleGetShipdaPresignedURLSuccess = useCallback(
    async ({
      successData,
      files,
      data,
      getTargetId,
      onUploadSuccess,
      onError,
      bindFunc,
    }: {
      successData: GET_PRESIGNED_URL_RES;
      files: FileTypes[];
      data: ITEM_TO_GET_PRESIGNED_URL[];
      getTargetId: () => number | undefined;
      onUploadSuccess?: (values: HandleSuccessUpload) => void;
      onError: () => void;
      bindFunc?: (bindAttachment: BindingAttachments[]) => void | undefined;
    }) => {
      try {
        await Promise.all(
          successData.map(async (param, index) => {
            const { url } = param;
            const { fileInfo } = files[index];
            const { type } = files[index].fileInfo;
            const headers = { "Content-Type": type };
            await axios.put(url, fileInfo, { headers });
          })
        );

        const bindingData: BindingAttachments[] = successData.map(
          (v: { path: string; url: string; key: string }) => {
            return {
              key: v.key,
              domain: data[0].domain,
              targetId: getTargetId(),
            };
          }
        );

        if (bindFunc) {
          bindFunc(bindingData);

          return;
        }

        bindFileAfterUpload(bindingData, {
          onSuccess: ({ data: bindingSuccessData }) => {
            onUploadSuccess &&
              onUploadSuccess({ bindingSuccessData, bindingData });
            setIsUploading(false);
          },
          onError,
        });
      } catch (error) {
        onError();
      }
    },
    [bindFileAfterUpload]
  );

  const uploadFiles = useCallback(
    ({
      data,
      onUploadSuccess,
      onUploadFailure,
      files,
      apiType,
      bindFunc,
    }: {
      data: ITEM_TO_GET_PRESIGNED_URL[];
      onUploadSuccess?: (values: HandleSuccessUpload) => void;
      onUploadFailure?: () => void;
      files: FileTypes[];
      apiType: ApiType;
      bindFunc?: (bindAttachment: BindingAttachments[]) => void | undefined;
    }) => {
      const getTargetId = () => {
        return data[0].targetId;
      };

      setIsUploading(true);

      const handleError = () => {
        onUploadFailure && onUploadFailure();

        setIsFailed(true);
        setIsUploading(false);
      };

      getPresignedURL(data, {
        onSuccess: ({ data: successData }) => {
          handleGetShipdaPresignedURLSuccess({
            successData,
            files,
            data,
            getTargetId,
            onUploadSuccess,
            onError: handleError,
            bindFunc,
          });
        },

        onError: handleError,
      });
    },
    [getPresignedURL, handleGetShipdaPresignedURLSuccess]
  );

  const LoadingToUpload = useMemo(() => {
    return <Loading active={isUploading} />;
  }, [isUploading]);

  return [uploadFiles, LoadingToUpload, FailureModal, setIsUploading] as const;
}
