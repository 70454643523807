import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const restockInvoiceContainer = styled.div`
  display: flex;
  flex-direction: column;

  > .table-for-counting {
    margin-bottom: 40px;
  }

  > .button:last-child {
    margin-bottom: 80px;
  }
`;

const scanContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;

  > strong {
    color: ${COLOR.grayScale_800};
    ${setFontStyle("SubHead2", "Bold")};
  }
`;

const scanButtonsContainer = styled.div`
  display: flex;

  .scan-sku {
    margin-left: 16px;
  }
`;

const countContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const countWithDirectInputContainer = styled.div`
  > .count-values {
    margin-bottom: 16px;
    > .input-text {
      margin-right: 8px;
    }
  }
  > .count-buttons {
    display: flex;
    > .button:first-child {
      margin-right: 4px;
    }
  }
`;

export default {
  restockInvoiceContainer,
  scanContainer,
  scanButtonsContainer,
  countContainer,
  countWithDirectInputContainer,
};
