import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const returningGuideModalBody = styled.div`
  display: flex;
  flex-direction: column;

  > .sku-returning-condition {
    margin-bottom: 24px;
    text-align: left;

    > .label {
      margin-bottom: 4px;
      ${setFontStyle("Body6", "Bold")};
      color: ${COLOR.primaryBlue};
    }

    > .description {
      ${setFontStyle("Body6")};
      color: ${COLOR.grayScale_700};
    }
  }

  > .returning-requests {
    margin-bottom: 24px;

    > .label {
      margin-bottom: 8px;
      ${setFontStyle("Head6", "Bold")};
      color: ${COLOR.grayScale_800};
    }

    > .description {
      text-align: left;
      ${setFontStyle("Body6")};
      color: ${COLOR.grayScale_700};
    }
  }

  > .button {
    margin-top: 24px;
    align-self: center;
  }
`;

export default { returningGuideModalBody };
