import { useParams } from "react-router-dom";

import SHIPPING_QUERY from "@sellernote/_shared/src/queries/fulfillment/SHIPPING_QUERY";
import { TruckCompanyType } from "@sellernote/_shared/src/types/fulfillment/shipping";
import { useShipmentSummary } from "@sellernote/_shared/src/utils/fulfillment/shipping";
import ListWithMoreResult from "@sellernote/_sds-v1/src/components/ListWithMoreResult";

export default function useShipmentClosingTruck() {
  const { company } = useParams<{ company: TruckCompanyType }>();

  const {
    data: shipmentTruckSummary,
    ResponseHandler: ResponseHandlerOfGettingShipmentTruckSummary,
  } = SHIPPING_QUERY.useGetShipmentSummary({
    type: "truck",
    params: { truckCompany: company },
  });

  const {
    TodayExpectedShipmentSummary,
    CompletedShipmentSummary,
    UnprocessedShipmentSummary,
  } = useShipmentSummary<"truck">(shipmentTruckSummary, ListWithMoreResult);

  return {
    TodayExpectedShipmentSummary,
    CompletedShipmentSummary,
    UnprocessedShipmentSummary,

    ResponseHandlerOfGettingShipmentTruckSummary,
  };
}
