import { useCallback, useState } from "react";

import { IssueOptionList } from "@sellernote/_shared/src/types/fulfillment/shipping";
import InputRadio from "@sellernote/_sds-v1/src/components/input/InputRadio";
import Paging from "@sellernote/_sds-v1/src/components/Paging";
import TabFilter from "@sellernote/_sds-v1/src/components/TabFilter";
import Table from "@sellernote/_sds-v1/src/components/table/Table";
import { getPageSize } from "@sellernote//_shared/src/utils/common/etc";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useIssueAllShippingList from "hooks/shipping/useIssueAllShippingList";
import useMoveAllShippingList from "hooks/shipping/useMoveAllShippingList";
import useShippingList, {
  ShippingTableItem,
} from "hooks/shipping/useShippingList";

import Styled from "./index.styles";

export type FilterForIsMine = "mine" | "unSet";

const PAGE_UNIT = 10;

function ShippingConfirm() {
  const [filterForIsMine, setFilterForIsMine] =
    useState<FilterForIsMine>("mine");
  const [filterForIsIssue, setFilterForIsIssue] =
    useState<IssueOptionList>("all");
  const [currentPage, setCurrentPage] = useState(0);

  const handleIsMineFilterClick = useCallback((v: FilterForIsMine) => {
    setCurrentPage(0);
    setFilterForIsMine(v);
  }, []);

  const handleIsIssueFilterClick = useCallback((v: IssueOptionList) => {
    setCurrentPage(0);
    setFilterForIsIssue(v);
  }, []);

  const {
    shippingTotal,
    shippingAnotherTotal,
    shippingTableList,
    MoveToMyTaskModal,
    ResponseHandlerOfGettingShippingList,
    ResponseHandlerOfIssuingShippingInvoice,
    ResponseHandlerOfMovingToMyTask,
  } = useShippingList({
    filterForIsMine,
    filterForIsIssue,
    currentPage,
  });

  const {
    MoveAllShippingList,
    ResponseHandlerOfMovingAllShippingListToMyTask,
  } = useMoveAllShippingList();
  const { IssueAllShippingList, ResponseHandlerOfIssuingAllShippingInvoice } =
    useIssueAllShippingList();

  return (
    <Layout
      navigator={{
        title: "의뢰확인",
      }}
    >
      <Styled.container>
        <TabFilter<FilterForIsMine>
          filterList={[
            {
              label: `My의뢰 (${
                filterForIsMine === "mine"
                  ? shippingTotal
                  : shippingAnotherTotal
              })`,
              filter: "mine",
            },
            {
              label: `미배정의뢰 (${
                filterForIsMine === "mine"
                  ? shippingAnotherTotal
                  : shippingTotal
              })`,
              filter: "unSet",
            },
          ]}
          selectedFilter={filterForIsMine}
          setFilter={handleIsMineFilterClick}
        />

        <Styled.issueInvoiceContainer>
          {filterForIsMine === "mine" ? (
            <>
              <InputRadio<IssueOptionList>
                direction="row"
                optionList={[
                  {
                    label: "전체",
                    value: "all",
                  },
                  {
                    label: "미출력",
                    value: "unIssue",
                  },
                  {
                    label: "출력완료",
                    value: "issue",
                  },
                ]}
                selectedValue={filterForIsIssue}
                selectCallback={handleIsIssueFilterClick}
                className="input-radio-filter"
              />
              {IssueAllShippingList}
            </>
          ) : (
            <>{MoveAllShippingList}</>
          )}
        </Styled.issueInvoiceContainer>

        <Paging
          pageSize={getPageSize(PAGE_UNIT, shippingTotal)}
          currentPage={currentPage}
          currentPageData={
            <Table<ShippingTableItem>
              columnInfo={{
                customerCompany: {
                  label: "회사명 (팀명)",
                  fixedWidth: 120,
                },
                id: {
                  label: "출고요청번호",
                  fixedWidth: 100,
                },
                numberOfSKUType: {
                  label: "상품종류(SKU)",
                  fixedWidth: 120,
                },
                sumOfSKUQuantity: {
                  label: "출고요청수량(PCS)",
                  fixedWidth: 130,
                },
                deliveryType: {
                  label: "운송수단",
                },
                invoiceNo: {
                  label: "송장번호(QR 넘버)",
                  fixedWidth: 130,
                },
                dueDate: {
                  label: "발송 희망일",
                  fixedWidth: 100,
                },
                issue: {
                  label: "송장출력",
                },
                isIssue: {
                  label: "출력여부",
                },
              }}
              dataList={shippingTableList}
            />
          }
          handleClickPage={setCurrentPage}
          isZeroBasedPage
        />
      </Styled.container>

      {MoveToMyTaskModal}

      {ResponseHandlerOfGettingShippingList}
      {ResponseHandlerOfIssuingShippingInvoice}
      {ResponseHandlerOfIssuingAllShippingInvoice}
      {ResponseHandlerOfMovingToMyTask}
      {ResponseHandlerOfMovingAllShippingListToMyTask}
    </Layout>
  );
}

export default withRequireAuth(ShippingConfirm);
