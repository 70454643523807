import { UnverifiedItemInfo } from "@sellernote/_shared/src/types/fulfillment/inspection";

import { atomWithReset } from "jotai/utils";

const SCANNED_UNREGISTERED_UNVERIFIED_LIST = atomWithReset<
  UnverifiedItemInfo[]
>([]);

export default {
  SCANNED_UNREGISTERED_UNVERIFIED_LIST,
};
