import React from "react";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";

import Styled from "./index.styles";

export default function ShipmentScanModal({
  active,
  body,
  handleModalClose,
}: {
  active: boolean;
  body: React.ReactNode;
  handleModalClose: () => void;
}) {
  return (
    <Styled.container>
      <Modal
        uiType="contentWithCustomBody"
        title="스캔 시작"
        active={active}
        body={body}
        onClose={handleModalClose}
      />
    </Styled.container>
  );
}
