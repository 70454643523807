import { Dispatch, memo, SetStateAction, useCallback, useState } from "react";

import { InventoryDetailBySKUId } from "@sellernote/_shared/src/api-interfaces/boful-api/inventory";
import INVENTORY_QUERY from "@sellernote/_shared/src/queries/fulfillment/INVENTORY_QUERY";
import { getCombinedSkuValueForPDAInventory } from "@sellernote/_shared/src/utils/fulfillment/inventory";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

import SelectSkuModal from "../../SelectSkuModal";
import { SkuToSelect } from "../../SelectSkuModal/useSelectSkuModal";

function SelectedSku({
  inventoryDetailOfSelectedSku,
  setInventoryDetailOfSelectedSku,
  selectedSku,
  setSelectedSku,
}: {
  inventoryDetailOfSelectedSku: InventoryDetailBySKUId[] | undefined;
  setInventoryDetailOfSelectedSku: Dispatch<
    SetStateAction<InventoryDetailBySKUId[] | undefined>
  >;
  selectedSku: SkuToSelect | undefined;
  setSelectedSku: Dispatch<SetStateAction<SkuToSelect | undefined>>;
}) {
  const [isVisibleSelectSkuModal, setIsVisibleSelectSkuModal] = useState(false);

  const handleSelectSkuModalOpen = useCallback(() => {
    setIsVisibleSelectSkuModal(true);
  }, []);

  const handleSelectSkuModalClose = useCallback(() => {
    setIsVisibleSelectSkuModal(false);
  }, []);

  const { ResponseHandler: ResponseHandlerOfGettingInventoryDetailBySkuId } =
    INVENTORY_QUERY.useGetInventoryDetailBySkuId({
      skuId: selectedSku?.id ?? 0,
      enabled: !!selectedSku,
      failureModalInfo: {
        customizeMessage: () => ({
          title: "SKU 재고 정보 조회 중에 오류가 발생했습니다.",
        }),
      },
      onSuccess: setInventoryDetailOfSelectedSku,
    });

  return (
    <>
      <ListItem
        label="SKU ID / 상품바코드"
        type="withButton"
        value={getCombinedSkuValueForPDAInventory({
          skuId: inventoryDetailOfSelectedSku?.[0]?.sku.id,
          skuBarcode: inventoryDetailOfSelectedSku?.[0]?.sku.barCode,
        })}
        button={{
          label: `${inventoryDetailOfSelectedSku ? "재선택" : "선택"}`,
          handleClick: handleSelectSkuModalOpen,
        }}
      />

      <SelectSkuModal
        active={isVisibleSelectSkuModal}
        setSelectedSku={setSelectedSku}
        onClose={handleSelectSkuModalClose}
      />

      {ResponseHandlerOfGettingInventoryDetailBySkuId}
    </>
  );
}

export default memo(SelectedSku);
