import styled, { css } from "styled-components";

import { ModalUiTypeV2 } from "@sellernote/_shared/src/headlessComponents/useModal";
import { mobile } from "@sellernote/_shared/src/styles/functions";

import { COLOR, TEXT_COLOR } from "../../styles/colors";
import { setDimmedLayer, setElevationStyle } from "../../styles/elevation";
import { setFontStyle } from "../../styles/typography";

const container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 500;
  ${setDimmedLayer()};
`;

const modalContainer = styled.div<{
  uiType: ModalUiTypeV2;
  width?: string;
  height?: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : "480px")};
  min-height: 216px;

  ${({ height }) =>
    height
      ? css`
          max-height: ${height};
          height: 100%;
        `
      : css`
          height: auto;
        `}

  padding: 24px;
  border-radius: 8px;
  background-color: ${COLOR.wh};
  z-index: 500;
  ${setElevationStyle("level2")};

  ${mobile(css`
    width: calc(100% - 16px);
    max-height: calc(100% - 16px);
    padding: 16px;
  `)}

  ${({ uiType }) => {
    if (uiType !== "formInput") return;

    return css`
      padding: 0;
      border-radius: 16px;
      box-shadow: none;
      border: 1px solid ${COLOR.grayScale_400};

      ${mobile(css`
        padding: 0;
      `)}
    `;
  }}

  .head-icon {
    display: block;
    margin-bottom: 16px;
  }

  .clear-button {
    position: absolute;
    top: 26px;
    right: 24px;
    cursor: pointer;

    ${mobile(css`
      top: 17px;
      right: 16px;
    `)}
  }
`;

const title = styled.h2<{
  isFormInput?: boolean;
  hasDesc?: boolean;
}>`
  ${setFontStyle("SubHead1")};
  color: ${TEXT_COLOR.black_2};

  ${({ isFormInput, hasDesc }) => {
    if (isFormInput && hasDesc)
      return css`
        padding: 24px 24px 16px;

        ${mobile(css`
          padding: 16px 16px 8px;
        `)}
      `;

    if (isFormInput)
      return css`
        padding: 24px;

        ${mobile(css`
          padding: 16px;
        `)}
      `;
  }}
`;

const descContainer = styled.div<{ isFormInput?: boolean }>`
  color: ${TEXT_COLOR.black_2};

  ${({ isFormInput }) => {
    if (isFormInput)
      return css`
        margin-top: 8px;
        ${setFontStyle("Body2")};

        ${mobile(css`
          margin-top: 8px;
          ${setFontStyle("Body3")};
        `)}
      `;

    return css`
      min-height: 110px;
      ${setFontStyle("SubHead2")};
    `;
  }};
`;

const bodyContainer = styled.div<{
  isFormInput?: boolean;
}>`
  min-height: 112px;
  margin-top: 8px;
  padding-bottom: 32px;
  ${setFontStyle("Body2")};
  color: ${TEXT_COLOR.black_2};
  flex-grow: 1;

  ${({ isFormInput }) => {
    if (isFormInput)
      return css`
        margin-top: 0;
        padding: 0 24px 8px 24px;

        ${mobile(
          css`
            padding: 0 16px 8px 16px;
          `
        )}
      `;
  }}

  ${mobile(
    css`
      overflow-y: auto;
    `
  )}
`;

const actionContainer = styled.div<{
  uiType: ModalUiTypeV2;
}>`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;

  /** 버튼은 24px 고정으로, padding이 16px이 되는 모바일 환경에서 추가로 padding을 적용 */
  ${mobile(
    css`
      padding-right: 8px;
      padding-left: 8px;
    `
  )}

  .positive-negative-container {
    display: flex;
    justify-content: flex-end;

    > .button:last-child {
      margin-left: 24px;
    }
  }

  ${({ uiType }) => {
    if (uiType !== "formInput") return;

    return css`
      padding: 20px 24px;
      border-top: 1px solid ${COLOR.grayScale_200};

      ${mobile(css`
        padding: 20px 24px;
      `)}
    `;
  }}
`;

export default {
  container,
  modalContainer,
  title,
  descContainer,
  bodyContainer,
  actionContainer,
};
