import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { MENU_LIST } from "@sellernote/_shared/src/utils/fulfillment/constants";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import { useAppSelector } from "store";

import MenuBofulList, { MenuListBofulItem } from "components/MenuListBoful";

function Home() {
  // eslint-disable-next-line no-empty-pattern
  const {} = useAppSelector((state) => {
    return {};
  });

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const menuList: MenuListBofulItem[] = MENU_LIST.map((menu, i) => {
    return {
      label: `${i + 1}. ${menu.title}`,
      handleClick: () => history.push(menu.path),
    };
  });

  return (
    <Layout
      navigator={{
        title: "선택해주세요",
      }}
      isHiddenBackButton={true}
    >
      <MenuBofulList menuList={menuList} />
    </Layout>
  );
}

export default withRequireAuth(Home);
