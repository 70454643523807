import {
  ClassifiedGroupItem,
  GroupDataByType,
} from "../../types/fulfillment/common";
import {
  InspectionStatusReturning,
  ReturningDeliveringStatus,
  ReturningDelivery,
  ReturningListFilterStatus,
  ReturningPacking,
  ReturningProcessStatus,
  ReturningStatus,
  UserReturningDetailItem,
} from "../../types/fulfillment/returning";
import {
  ShippingDeliveryType,
  ShippingPacking,
} from "../../types/fulfillment/shipping";

import { replaceEmptyToDash } from "../common/string";
import {
  checkUsesManagementDate,
  getGroupDataByType,
  mergeItemsBySkuId,
} from "./common";
import {
  getFormattedGroupSkuId,
  getFormattedSingleSkuId,
  toParcelCompanyLabel,
} from "./fulfillment";

/**
 * 반품 진행현황 > ReturningDelivery를 DeliveryType으로 변환
 *
 * @param type - 반품 배송 타입
 * @returns 배송 타입에 따른 ShippingDeliveryType
 */
function toDeliveryTypeLabel(
  type?: ReturningDelivery
): ShippingDeliveryType | undefined {
  switch (type) {
    case "parcel":
      return "parcel";
    case "truck":
    case "truckRequest":
      return "truck";
  }
}

/**
 * 반품 진행현황 > 검수상태를 반환
 *
 * @param returningStatus - 반품 상태
 * @param inspectionStatus - 검수 상태
 * @returns 검수 상태에 따른 문자열
 */
function toInspectionStatusLabel(
  returningStatus: ReturningStatus,
  inspectionStatus: InspectionStatusReturning
) {
  switch (returningStatus) {
    case "waitingInspection":
      return "검수 대기 중";

    case "inspecting":
      return "검수 중";

    case "completeInspection":
    case "pendingPutAway":
    case "putAway":
    case "done":
      if (inspectionStatus === "hold") return "검수완료(이슈)";
      if (inspectionStatus === "normal") return "검수완료(정상)";
      if (inspectionStatus === "consent") return "검수완료(동의)";
      else return "검수완료(정상)";

    default:
      return "-";
  }
}

/**
 * 반품 진행현황 > 송장번호(차량번호)를 반환
 *
 * @param deliveryType - 배송 타입
 * @param packings - 포장 리스트
 * @param invoiceNo - 송장 번호
 * @param truckNo - 차량 번호
 * @param isFullList - 전체 리스트를 표시할지 여부
 * @param needsArray - 배열로 반환할지 여부
 * @returns 송장 번호 또는 차량 번호
 */
function getDeliveryNumberOfReturning({
  deliveryType,
  packings,
  invoiceNo,
  truckNo,
  isFullList,
  needsArray,
}: {
  deliveryType?: ShippingDeliveryType;
  packings?: ReturningPacking[] | ShippingPacking[];
  invoiceNo?: string;
  truckNo?: string;
  isFullList?: boolean;
  needsArray?: boolean;
}) {
  if (deliveryType === "parcel") {
    if (!packings || !packings.length) return "-";

    if (packings.length === 1) return invoiceNo ?? "-";

    if (isFullList && needsArray) {
      return packings.map(({ invoiceNo }) => invoiceNo);
    }

    if (isFullList && !needsArray) {
      return packings.map(({ invoiceNo }) => invoiceNo).join(", ");
    }

    return `${invoiceNo ?? ""} 외 ${packings.length - 1}건`;
  }

  if (deliveryType === "truck") {
    return truckNo ?? "-";
  }

  return "-";
}

const PARCEL_COMPANY_OPTION_LIST = [
  { label: "CJ대한통운", value: "cj" },
  { label: "한진택배", value: "hanjin" },
  { label: "우체국택배", value: "post" },
  { label: "대신택배", value: "daesin" },
  { label: "건영택배", value: "kunyoung" },
  { label: "천일택배", value: "chunil" },
];

const RETURNING_LIST_FILTER_STATUS_DICT: {
  [K in ReturningListFilterStatus]: string;
} = {
  notSent: "반품접수완료",
  delivering: "센터 배송 중",
  doneDelivery: "센터 도착",
  waitingInspection: "검수 대기 중",
  inspecting: "검수중",
  completeInspection: "검수완료",
  pendingPutAway: "입고 대기 중",
  putAway: "입고 중",
  done: "입고 완료",
  hold: "전담매니저 확인중",
  uncollected: "미집화",
  emptyInvoice: "반품송장 번호 입력",
};

const RETURNING_PROCESS_STATUS_DICT: {
  [K in ReturningProcessStatus]: string;
} = {
  restock: "재입고",
  disposal: "폐기",
  forward: "회송",
};

/**
 * 반품 송장 번호 표시 필요 여부를 확인
 *
 * @param invoiceNo - 송장 번호
 * @returns 송장 번호 표시 필요 여부
 */
function checkNeedToDisplayReturningInvoice(invoiceNo?: string) {
  // 운송장 입력 대기 중, 수거 준비 중, 값이 없는 상태에서는 반품송장번호에 출고송장번호가 임시로 들어가므로 보이지 않도록 처리.
  if (
    invoiceNo === "운송장 입력 대기 중" ||
    invoiceNo === "수거 준비 중" ||
    !invoiceNo
  ) {
    return false;
  }

  return true;
}

/**
 * items와 그룹상품의 정보를 조합하여 items와 동일한 형태로 변환하기 위한 함수
 * - items 중 productGroupId가 있으면 해당 그룹상품의 구성상품이다.
 * - list의 경우 productGroupIds, detail의 경우 groups에서 그룹상품 정보를 가져온다.
 *
 * @param items - 반품 아이템 리스트
 * @param groupDataByType - 그룹 데이터 타입에 따른 그룹 정보
 * @returns 그룹상품 리스트
 */
const getGroupReturningItems = ({
  items,
  groupDataByType,
}: {
  items: UserReturningDetailItem[] | undefined;
  groupDataByType: GroupDataByType;
}): ClassifiedGroupItem[] => {
  if (!items) {
    return [];
  }

  // items 중 productGroupId가 있는 것만 필터링하면 그룹상품의 구성상품 리스트를 구할 수 있다.
  const itemListWithProductGroupId = items.filter(
    (item) => item.productGroupId
  );

  if (groupDataByType.type === "list") {
    // 구성상품 리스틀 순회해서 그룹상품을 items와 동일한 형태로 변환한다.
    return itemListWithProductGroupId.reduce<ClassifiedGroupItem[]>(
      (acc, item) => {
        const existingItemIndex = acc.findIndex(
          (groupItem) => groupItem.skuId === item.productGroupId
        );
        if (existingItemIndex === -1) {
          const itemName =
            groupDataByType.productGroupIds?.find(
              (group) => group.productGroupId === item.productGroupId
            )?.productGroupName ?? "-";

          // productGroupId가 존재하는 아이템만 순회하기 때문에 반드시 존재함
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const skuId = item.productGroupId!;

          return [
            ...acc,

            {
              isGroup: true,

              // 같은 그룹상품이 여러 개 존재할 수 없으므로 productGroupId를 id로 사용
              id: skuId,
              skuId,
              // 반품요청수량
              quantity: item.quantity,
              sku: {
                id: skuId,
                itemName,
              },
            },
          ];
        }

        return acc.map((groupItem, index) => {
          // 이미 추가된 그룹상품은 구성상품의 수량을 더해주어서 반품요청수량을 구한다.
          if (index === existingItemIndex) {
            return {
              ...groupItem,
              quantity: groupItem.quantity + item.quantity,
            };
          }

          return groupItem;
        });
      },
      []
    );
  }

  if (groupDataByType.type === "detail") {
    // 구성상품 리스틀 순회해서 그룹상품을 items와 동일한 형태로 변환한다.
    return itemListWithProductGroupId.reduce<ClassifiedGroupItem[]>(
      (acc, item) => {
        const usesManagementDate = checkUsesManagementDate({
          managementKind: item.sku.managementKind,
          managementDate: item.managementDate,
        });

        const existingItemIndex = acc.findIndex(
          (groupItem) => groupItem.skuId === item.productGroupId
        );
        if (existingItemIndex === -1) {
          const groupItems = groupDataByType.groups?.find(
            (group) => group.id === item.productGroupId
          )?.groupItems;

          const filteredItemsByProductGroupId = items.filter(
            (groupItem) => groupItem.productGroupId === item.productGroupId
          );

          /**
           * 관리일자를 설정한 경우 skuId를 기준으로 하나의 행으로 표시하기 위해 합쳐준다.
           */
          const returningGroupItems = (() => {
            if (usesManagementDate) {
              return mergeItemsBySkuId(filteredItemsByProductGroupId);
            }

            return filteredItemsByProductGroupId;
          })();

          const groupItem = groupDataByType.groups?.find(
            (group) => group.id === item.productGroupId
          );

          // productGroupId가 존재하는 아이템만 순회하기 때문에 반드시 존재함
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const skuId = item.productGroupId!;

          return [
            ...acc,

            {
              isGroup: true,

              id: skuId,
              skuId,
              quantity: item.quantity,
              sku: {
                id: skuId,
                itemName: replaceEmptyToDash(groupItem?.groupName),
                productCode: groupItem?.productCode,
                managementCode: groupItem?.managementCode,

                // 아래 값은 사용하지는 않으나 타입을 맞추기 위해 기본값으로 추가함
                materialPackageType: "basic",
                returningCondition: "openNotUsed",
                attachment: [],
                packages: [],
              },
              // 구성상품정보 툴팁을 표시하기 위함
              groupItems,
              // 구성상품 정보(subRow)를 표시하기 위함
              returningGroupItems,
            },
          ];
        }

        return acc.map((groupItem, index) => {
          // 이미 추가된 그룹상품은 구성상품의 수량을 더해주어서 반품요청수량을 구한다.
          if (index === existingItemIndex) {
            return {
              ...groupItem,
              quantity: groupItem.quantity + item.quantity,
            };
          }

          return groupItem;
        });
      },
      []
    );
  }

  return [];
};

/**
 * 단일상품만 필터링
 *   - 단일상품에는 productGroupId가 없음
 *
 * @param items - 반품 아이템 리스트
 * @returns 단일상품 리스트
 */
const getSingleReturningItems = (
  items: UserReturningDetailItem[] | undefined
) => {
  if (!items) {
    return [];
  }

  const singleReturningItems = items.filter((item) => !item.productGroupId);

  // 관리일자 별로 나뉘어진 아이템을 SKU ID 기준으로 합쳐준다.
  return mergeItemsBySkuId(singleReturningItems);
};

/**
 * 반품 신청 당시의 초기 item인지 확인하는 함수
 *   - 아래 3가지 경우 화면에 표시하지 않으며, 공통적으로 반품요청수량이 0개이다.
 *     - 반품요청수량이 0개인 경우
 *     - 작업 중에 item이 추가 생성되는 아래의 2가지 경우
 *       - 불일치 상품
 *       - 비정상상품의 불량입고(입고 동의 시)
 *
 *   - 반품요청수량(quantity)가 0인 케이스가 하나 더 있다. (2024-06-07 추가)
 *    - 출고가 [3월 2개, 4월 2개, 5월 2개]로 나간 후 반품요청이 3개된 경우 [3월 2개, 4월 1개, 5월 0개]로 반품 아이템이 생성된다.
 *    - 해당 로직의 목적은 quantity 수량을 상품별로 묶기 위함이므로 해당 상품을 필터링 하더라도 현재(2024-06-07) 요구사항 기준으로는 문제가 없다.
 *
 * @param item - 반품 아이템
 * @returns 초기 반품 아이템 여부
 */
const checkInitialReturningItem = (item: UserReturningDetailItem) => {
  return item.quantity > 0;
};

/**
 * 화주웹 > 반품관리에서 returningItems를 [그룹상품, 단일상품] 형태로 분류하기 위한 함수
 *
 * @param items - 반품 아이템 리스트
 * @param paramsByType - 그룹 데이터 타입에 따른 그룹 정보
 * @returns 분류된 반품 아이템 리스트
 */
const classifyReturningItems = ({
  items,
  ...paramsByType
}: {
  items: UserReturningDetailItem[] | undefined;
} & GroupDataByType) => {
  if (!items) {
    return [];
  }

  const groupData = getGroupDataByType(paramsByType);

  // 작업하면서 item이 추가 생성되기 때문에 초기 items만 필터링
  const initialReturningItems = items.filter(checkInitialReturningItem);

  const hasGroupItems = groupData.length > 0;
  if (!hasGroupItems) {
    return mergeItemsBySkuId(initialReturningItems);
  }

  const groupItems = getGroupReturningItems({
    items: initialReturningItems,
    groupDataByType: paramsByType,
  });

  const singleItems = getSingleReturningItems(initialReturningItems);

  return [...groupItems, ...singleItems];
};

/**
 * 반품 SKU ID를 포맷팅하여 반환
 *
 * @param item - 반품 아이템
 * @returns 포맷팅된 SKU ID
 */
const getFormattedReturningSkuId = (
  item: UserReturningDetailItem | ClassifiedGroupItem
) => {
  if (!item) {
    return "-";
  }

  return item.isGroup
    ? getFormattedGroupSkuId(item.skuId)
    : getFormattedSingleSkuId(item.skuId);
};

/**
 * 그룹상품을 우선(1순위), 수량이 많은 상품을 우선(2순위)으로 대표상품으로 표기
 * https://www.notion.so/shipda/4ee5f64eaed248c4b5640f711fe9e570
 *
 * @param itemList - 반품 아이템 리스트
 * @returns 대표 상품
 */
const getRepresentativeProduct = (
  itemList: (UserReturningDetailItem | ClassifiedGroupItem)[]
) =>
  itemList.reduce((prev, cur) => {
    if ((!prev.isGroup && cur.isGroup) || prev.quantity < cur.quantity) {
      return cur;
    }

    return prev;
  });

/**
 * 반품 SKU ID 리스트를 반환
 *
 * @param items - 반품 아이템 리스트
 * @returns 포맷팅된 SKU ID 리스트
 */
const getReturningSkuIdList = (
  items: (UserReturningDetailItem | ClassifiedGroupItem)[]
) => {
  if (!items || !items.length) {
    return "-";
  }

  if (items.length === 1) {
    return getFormattedReturningSkuId(items[0]);
  }

  const representativeProduct = getRepresentativeProduct(items);

  return `${getFormattedReturningSkuId(representativeProduct)} 외 ${
    items.length - 1
  }건`;
};

/**
 * 수량이 가장 많은 상품이 대표상품. ㅇㅇ등으로 표기.
 *
 * @param items - 반품 아이템 리스트
 * @returns 대표 상품명
 */
function getReturningItemTitle(
  items?: (UserReturningDetailItem | ClassifiedGroupItem)[]
) {
  if (!items || !items.length) return "-";
  if (items.length === 1) return items[0].sku?.itemName;

  const hasGroupItem = items.some((item) => item.isGroup);

  const groupItemTitle = items.find((item) => item.isGroup)?.sku?.itemName;

  const maxQuantityItem = items.reduce((prev, cur) =>
    (prev.quantity ?? 0) > (cur.quantity ?? 0) ? prev : cur
  );

  return hasGroupItem
    ? `${groupItemTitle} 등`
    : `${maxQuantityItem.sku?.itemName} 등`;
}

/**
 * 반품관리 > 반품 진행내역 > 운송사
 * 현재는 택배만 반품 가능하기 때문에 택배만 표시
 *
 * @param packings - 포장 리스트
 * @returns 운송사 이름
 */
const getReturningDeliveryName = ({
  packings,
}: {
  packings: ReturningPacking[] | undefined;
}) => {
  if (!packings) {
    return "-";
  }

  const parcelCompanyList = [
    ...new Set(
      packings.map(({ parcelCompany }) => toParcelCompanyLabel(parcelCompany))
    ),
  ];

  return (
    {
      0: "-",
      1: parcelCompanyList[0],
    }[parcelCompanyList.length] ??
    `${parcelCompanyList[0]} 외 ${parcelCompanyList.length - 1}건`
  );
};

/**
 * 반품 타입을 반환
 *
 * @param isAlreadyApplied - 고객사가 이미 반품을 진행한 경우
 * @returns 반품 타입
 */
const getReturningType = (isAlreadyApplied: boolean) =>
  isAlreadyApplied ? "고객사 측 입고" : "쉽다 측 수거";

/**
 * 반품 배송 상태를 라벨로 변환하여 반환
 *
 * @param status - 반품 진행 상태
 * @returns 반품 배송 상태 라벨
 */
const toReturningDeliveringStatusLabel = (
  status: ReturningDeliveringStatus
) => {
  switch (status) {
    case "uncollected": {
      return "-";
    }
    case "notSent": {
      return "수거 준비 중";
    }
    case "moveToParcelCompany": {
      return "택배사 전달";
    }
    case "delivering": {
      return "센터 배송 중";
    }
    case "done": {
      return "센터 도착완료";
    }
    default: {
      return "";
    }
  }
};

/**
 * 반품 상태 라벨을 반환
 *
 * @param status - 반품 상태
 * @param inspectionStatus - 검수 상태
 * @param deliveringStatus - 배송 상태
 * @returns 반품 상태 라벨
 */
const getReturningStatusLabel = ({
  status,
  inspectionStatus,
  deliveringStatus,
}: {
  status: ReturningStatus;
  inspectionStatus: InspectionStatusReturning;
  deliveringStatus: ReturningDeliveringStatus;
}) => {
  switch (status) {
    case "beforeReturning": {
      if (deliveringStatus === "notSent") {
        return "반품접수완료";
      }
      return "도착 전";
    }
    case "waitingInspection": {
      return "검수 대기 중";
    }
    case "inspecting": {
      return "검수 중";
    }
    case "completeInspection": {
      if (inspectionStatus === "hold") {
        return "검수완료(이슈)";
      }
      return "";
    }
    case "putAway": {
      return "입고 중";
    }
    case "hold": {
      return "전담매니저 확인 중";
    }
    case "done": {
      return "입고완료";
    }
    default: {
      return "";
    }
  }
};

/**
 * 반품 ID를 포맷팅하여 반환
 *
 * @param returning - 반품 정보 객체
 * @returns 포맷팅된 반품 ID
 */
const getFormattedReturningId = (
  returning:
    | {
        id?: number;
      }
    | undefined
) => {
  if (!returning) {
    return "";
  }

  return `R${returning.id}`;
};

/**
 * PDA용 반품 송장 번호를 반환
 *
 * @param returningPackingList - 반품 포장 리스트
 * @returns PDA용 반품 송장 번호
 */
const getReturningInvoiceNoForPDA = (
  returningPackingList: ReturningPacking[] | undefined
) => {
  if (!returningPackingList || !returningPackingList.length) {
    return "-";
  }

  const invoiceNoList = returningPackingList.map(({ invoiceNo }) =>
    Number(invoiceNo)
  );

  if (invoiceNoList.length === 1) {
    return String(invoiceNoList[0]);
  }

  return `${Math.min(...invoiceNoList)} 외 ${invoiceNoList.length - 1}건`;
};

/**
 * 반품신청 시 0개로 신청된 item 여부를 확인하는 함수
 *  - 불일치 상품과 비정상상품의 불량입고는 검수 작업을 진행해야 생성되기 때문에 actualQty 수량이 존재한다.
 *
 * @param item - 반품 아이템
 * @returns 0개로 신청된 item 여부
 */
const checkZeroQuantityReturningItem = (item: {
  quantity: number;
  actualQty: number | undefined;
}) => item.quantity === 0 && item.actualQty === 0;

export {
  RETURNING_PROCESS_STATUS_DICT,
  RETURNING_LIST_FILTER_STATUS_DICT,
  PARCEL_COMPANY_OPTION_LIST,
  getDeliveryNumberOfReturning,
  toDeliveryTypeLabel,
  toInspectionStatusLabel,
  checkNeedToDisplayReturningInvoice,
  getSingleReturningItems,
  classifyReturningItems,
  getReturningDeliveryName,
  getReturningType,
  toReturningDeliveringStatusLabel,
  getReturningStatusLabel,
  getFormattedReturningId,
  getReturningSkuIdList,
  getReturningItemTitle,
  getReturningInvoiceNoForPDA,
  checkZeroQuantityReturningItem,
};
