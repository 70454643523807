import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import AUTH_QUERY from "@sellernote/_shared/src/queries/fulfillment/AUTH_QUERY";
import { FULFILLMENT_AUTH_ATOMS } from "@sellernote/_shared/src/states/fulfillment/auth";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";

import Layout from "containers/Layout";

import Styled from "./index.styles";

export default function Login() {
  // TODO: 로그인 유지 안되게 해야함

  const history = useHistory();

  const loggedIn = useRecoilValue(FULFILLMENT_AUTH_ATOMS.LOGGED_IN);

  const { mutate: login, ResponseHandler: ResponseHandlerOfLogin } =
    AUTH_QUERY.useWorkerLogin();

  useEffect(() => {
    if (loggedIn) {
      history.replace("/");
    }
  }, [history, loggedIn]);

  const [loginFormState, setLoginFormState] = useState<{
    id?: string;
    pdaNum?: number;
    password?: string;
  }>({
    id: "",
    pdaNum: undefined,
    password: "",
  });

  const isAllInputValid = useMemo(() => {
    return (
      loginFormState.id && loginFormState.pdaNum && loginFormState.password
    );
  }, [loginFormState]);

  const handleLoginClick = useCallback(() => {
    const { id, password, pdaNum } = loginFormState;

    if (!id || !password || !pdaNum) return;

    login({ accountId: id, password, deviceId: pdaNum });
  }, [login, loginFormState]);

  return (
    <Layout
      navigator={{
        title: "로그인",
      }}
      isHiddenBackButton={true}
    >
      <Styled.container>
        <form>
          <InputText
            borderType="outline"
            valueType="string"
            label="ID"
            placeholder="아이디를 입력해주세요."
            value={loginFormState.id}
            setValue={(v) => {
              setLoginFormState({
                ...loginFormState,
                id: v,
              });
            }}
            isValidated={!!loginFormState.id}
          />

          <InputText
            borderType="outline"
            valueType="int"
            label="PDA 번호"
            placeholder="PDA 번호를 입력해주세요."
            value={loginFormState.pdaNum}
            setValue={(v) => {
              setLoginFormState({
                ...loginFormState,
                pdaNum: v,
              });
            }}
            isValidated={!!loginFormState.pdaNum}
          />

          <InputText
            borderType="outline"
            valueType="password"
            label="비밀번호"
            placeholder="비밀번호를 입력해주세요."
            value={loginFormState.password}
            setValue={(v) => {
              setLoginFormState({
                ...loginFormState,
                password: v,
              });
            }}
            isValidated={!!loginFormState.password}
          />
        </form>

        <Button
          theme="primary"
          size="block"
          label={"완료"}
          handleClick={handleLoginClick}
          disabled={!isAllInputValid}
        />
      </Styled.container>

      {ResponseHandlerOfLogin}
    </Layout>
  );
}
