import { Dispatch, memo, SetStateAction } from "react";

import { BofulLocation } from "@sellernote/_shared/src/types/fulfillment/common";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

import SelectLocationModal from "../SelectLocationModal";
import useExistingLocation from "./useExistingLocation";

function ExistingLocation({
  location,
  setExistingLocation,
}: {
  location: string | undefined;
  setExistingLocation: Dispatch<SetStateAction<BofulLocation | undefined>>;
}) {
  const {
    isVisibleSelectLocationModal,
    handleExistingLocationModalOpen,
    handleExistingLocationModalClose,
  } = useExistingLocation();

  return (
    <>
      <ListItem
        label="기존 위치"
        type="withButton"
        value={location ? `인천1센터 / ${location}` : ""}
        button={{
          label: `${location ? "재선택" : "선택"}`,
          handleClick: handleExistingLocationModalOpen,
        }}
      />

      <SelectLocationModal
        type="all"
        active={isVisibleSelectLocationModal}
        setSelectedLocation={setExistingLocation}
        onClose={handleExistingLocationModalClose}
      />
    </>
  );
}

export default memo(ExistingLocation);
