import { useEffect, useState } from "react";

import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";

import { SkuToSelect } from "../useSelectSkuModal";
import { InputTypeProps, ScanTypeProps } from ".";

export default function useSelectSkuInput({
  selectedSku,
  onReset,
  ...propsByType
}: {
  selectedSku: SkuToSelect | undefined;
  onReset: () => void;
} & (ScanTypeProps | InputTypeProps)) {
  const [isVisibleOptionList, setIsVisibleOptionList] = useState(false);

  useEffect(() => {
    if (propsByType.type === "input") {
      const { searchTerm, onTempSearchTermRestore } = propsByType;

      if (!searchTerm) {
        setIsVisibleOptionList(false);
        return;
      }

      if (!selectedSku) {
        setIsVisibleOptionList(true);
        return;
      }

      // 선택 후 수정하는 경우
      if (
        !(
          searchTerm === getFormattedSingleSkuId(selectedSku.id) ||
          searchTerm === selectedSku.barCode
        )
      ) {
        onTempSearchTermRestore();
        return;
      }

      setIsVisibleOptionList(false);
    }
  }, [onReset, propsByType, selectedSku]);

  useEffect(() => {
    if (propsByType.type === "scan") {
      if (!selectedSku) {
        setIsVisibleOptionList(true);
        return;
      }

      setIsVisibleOptionList(false);
    }
  }, [propsByType, selectedSku]);

  return {
    selectedSkuValue: selectedSku?.id
      ? `${getFormattedSingleSkuId(selectedSku.id)}${
          selectedSku?.barCode ? ` / ${selectedSku.barCode}` : ""
        }`
      : "",

    isVisibleOptionList,
  };
}
