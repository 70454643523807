import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const selectManagementDate = styled.div`
  margin-bottom: 24px;

  p.description {
    margin-bottom: 8px;
    text-align: center;
    ${setFontStyle("Body7")};
    color: ${COLOR.grayScale_700};
  }

  .input-select {
    text-align: left;
  }
`;

export default {
  selectManagementDate,
};
