import { useCallback, useState } from "react";

import { validateCount } from "@sellernote/_shared/src/utils/common/validation";

type CountType = "scan" | "input";

export type ModalType = "movement" | "consolidation" | "adjustment";

export default function useCountQuantityModal({
  type,
  availableQty,
  setQuantity,
  onClose,
}: {
  type: ModalType;
  availableQty: number;
  setQuantity: (quantity: number) => void;
  onClose: () => void;
}) {
  const [countType, setCountType] = useState<CountType>("scan");
  const [tempQuantity, setTempQuantity] = useState<number>();

  const isAdjustmentPage = type === "adjustment";

  const handleCountTypeChange = useCallback(
    (v: CountType) => {
      setCountType(v);
      setTempQuantity(undefined);
    },
    [setTempQuantity]
  );

  const handleCountQuantityModalClose = useCallback(() => {
    setCountType("scan");
    setTempQuantity(undefined);
    onClose();
  }, [onClose]);

  const handleCountQuantityModalConfirm = useCallback(() => {
    if (!tempQuantity) {
      return;
    }

    setQuantity(tempQuantity);
    handleCountQuantityModalClose();
  }, [handleCountQuantityModalClose, setQuantity, tempQuantity]);

  const validation = validateCount({
    count: tempQuantity,
    // 재고 조정 시에는 수량 입력에 제한이 없음
    maxCount: isAdjustmentPage ? Number.MAX_SAFE_INTEGER : availableQty,
    message: { maxCount: `*가용수량 이하로 입력` },
  });

  return {
    countType,
    handleCountTypeChange,

    tempQuantity,
    setTempQuantity,

    validation,

    handleCountQuantityModalConfirm,
    handleCountQuantityModalClose,
  };
}
