import styled from "styled-components";

const selectLocationModalBody = styled.div`
  > .input-radio {
    justify-content: center;
    margin-bottom: 24px;
  }
`;

export default {
  selectLocationModalBody,
};
