import { Dispatch, memo, SetStateAction } from "react";

import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import TextButton from "@sellernote/_sds-v1/src/components/button/TextButton";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

import CountQuantityModal from "../../CountQuantityModal";
import SelectLocationModal from "../../SelectLocationModal";
import TempInput from "../../TempInput";
import { InventoryToMove } from "../useInventoryMovement";
import useItemToMove from "./useItemToMove";
import Styled from "./index.styles";

function TitleOfAddedItem({
  indexOfItem,
  handleItemRemove,
  isActiveItem,
}: {
  indexOfItem: number;
  handleItemRemove: () => void;
  isActiveItem: boolean;
}) {
  return (
    <Styled.tileOfAddedItem>
      추가{indexOfItem}
      <TextButton
        label="삭제"
        size="14px"
        theme="danger"
        icon="arrowRight"
        handleClick={handleItemRemove}
        disabled={!isActiveItem}
      />
    </Styled.tileOfAddedItem>
  );
}

function ItemToMove({
  skuId,
  skuBarcode,
  existingLocationBarcode,
  managementDate,
  totalAvailableQty,
  setTotalAvailableQty,
  listOfInventoryToMove,
  setListOfInventoryToMove,
  indexOfItem,
}: {
  skuId: number;
  skuBarcode: string | undefined;
  existingLocationBarcode: string | undefined;
  managementDate: string | undefined;
  totalAvailableQty: number;
  setTotalAvailableQty: Dispatch<SetStateAction<number>>;
  listOfInventoryToMove: InventoryToMove[];
  setListOfInventoryToMove: Dispatch<SetStateAction<InventoryToMove[]>>;
  indexOfItem: number;
}) {
  const {
    isActiveItem,
    isAddedItem,
    handleItemRemove,

    handleLocationToMoveModalOpen,
    selectedLocationToMove,

    handleCountQuantityModalOpen,
    countedQuantityToMove,
    handleAllQuantitySelect,

    handleItemAdd,
    isValidToAddItem,

    isVisibleSelectLocationModal,
    setLocationToMove,
    handleLocationToMoveModalClose,

    isVisibleCountQuantityModal,
    setQuantityToMove,
    handleCountQuantityModalClose,
  } = useItemToMove({
    totalAvailableQty,
    setTotalAvailableQty,
    listOfInventoryToMove,
    setListOfInventoryToMove,
    indexOfItem,
  });

  return (
    <>
      <Styled.itemToMove isAddedItem={isAddedItem}>
        {isAddedItem && (
          <TitleOfAddedItem
            indexOfItem={indexOfItem}
            handleItemRemove={handleItemRemove}
            isActiveItem={isActiveItem}
          />
        )}

        <ListItem
          label="이동할 위치"
          type="withContentAlignRight"
          value={
            <Styled.inputWrapper onClick={handleLocationToMoveModalOpen}>
              <InputText
                placeholder="위치를 선택해주세요."
                width={12.625}
                borderType="outline"
                valueType="string"
                value={selectedLocationToMove}
                icon="location"
                disabled={!isActiveItem}
                disabledToFocus
                visiblePostFix
              />
              <TempInput />
            </Styled.inputWrapper>
          }
        />

        <ListItem
          label="이동할 수량"
          type="withButton"
          value={
            <Styled.inputWrapper onClick={handleCountQuantityModalOpen}>
              <InputText
                placeholder="수량을 입력해주세요."
                width={9.125}
                borderType="outline"
                valueType="int"
                value={countedQuantityToMove}
                disabled={!isActiveItem}
                disabledToFocus
                visiblePostFix
              />
              <TempInput />
            </Styled.inputWrapper>
          }
          button={{
            label: "전수량",
            handleClick: handleAllQuantitySelect,
            disabled: !isActiveItem,
          }}
        />

        {isActiveItem && (
          <TextButton
            label="추가"
            theme="gray"
            icon="add"
            size="14px"
            handleClick={handleItemAdd}
            disabled={!isValidToAddItem}
          />
        )}
      </Styled.itemToMove>

      <SelectLocationModal
        active={isVisibleSelectLocationModal}
        setSelectedLocation={setLocationToMove}
        onClose={handleLocationToMoveModalClose}
        type="movement"
        skuId={skuId}
        existingLocationBarcode={existingLocationBarcode}
        managementDate={managementDate}
        listOfInventoryToMove={listOfInventoryToMove}
      />

      <CountQuantityModal
        active={isVisibleCountQuantityModal}
        type="movement"
        skuId={getFormattedSingleSkuId(skuId)}
        skuBarcode={skuBarcode}
        availableQty={totalAvailableQty}
        setQuantity={setQuantityToMove}
        onClose={handleCountQuantityModalClose}
      />
    </>
  );
}

export default memo(ItemToMove);
