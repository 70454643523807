import {
  BIND_FILE_AFTER_UPLOAD_REQ,
  BIND_FILE_AFTER_UPLOAD_RES,
  DELETE_FILE_REQ,
  DELETE_FILE_RES,
  GET_PRESIGNED_URL_REQ,
  GET_PRESIGNED_URL_RES,
} from "@sellernote/_shared/src/api-interfaces/boful-api/file";
import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "@sellernote/_shared/src/services/query";
import { FileInfo } from "@sellernote/_shared/src/types/common/file";

export const FILE_QUERY_KEY_GEN = {
  all: () => [{ scope: "fulfillment/FILE_QUERY" }] as const,
  getFileUrlListQuery: (params: { key: string[] }) =>
    [
      {
        ...FILE_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getFileUrlListQuery",
      },
    ] as const,
};

function useGetFileURLList() {
  const mutation = useAppMutation<{ key: string[] }, FileInfo[]>({
    requestOptions: {
      method: "patch",
      path: "/file/download",
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "파일 리스트 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useGetFileURLListQuery({
  key,
  onSuccess,
  enabled,
}: {
  key: string[];
  onSuccess?: (data: FileInfo[]) => void;
  enabled?: boolean;
}) {
  const mutation = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof FILE_QUERY_KEY_GEN.getFileUrlListQuery>,
    FileInfo[]
  >({
    queryKey: FILE_QUERY_KEY_GEN.getFileUrlListQuery({ key }),

    requestOptions: {
      method: "patch",
      path: "/file/download",
      apiType: "BofulDefault",
      data: {
        key,
      },
    },

    onSuccess,
    enabled,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "파일 리스트 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useGetPresignedURL() {
  const mutation = useAppMutation<GET_PRESIGNED_URL_REQ, GET_PRESIGNED_URL_RES>(
    {
      requestOptions: {
        method: "post",
        path: "/file/upload",
        apiType: "BofulDefault",
      },
    }
  );

  return { ...mutation };
}

function useBindFileAfterUpload() {
  const mutation = useAppMutation<
    BIND_FILE_AFTER_UPLOAD_REQ["data"],
    BIND_FILE_AFTER_UPLOAD_RES
  >({
    requestOptions: {
      method: "put",
      path: "/file/binding",
      apiType: "BofulDefault",
    },
  });

  return { ...mutation };
}

function useDeleteFile(args: { onSuccess?: () => void } | void) {
  const mutation = useAppMutation<DELETE_FILE_REQ, DELETE_FILE_RES>({
    requestOptions: {
      method: "delete",
      path: "/file",
      apiType: "BofulDefault",
    },
    onSuccess: args?.onSuccess,
    failureModalInfo: {
      customizeMessage: () => ({
        title: "파일 삭제 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

const FILE_QUERY = {
  useGetFileURLList,
  useGetFileURLListQuery,
  useGetPresignedURL,
  useBindFileAfterUpload,
  useDeleteFile,
};
export default FILE_QUERY;
