import SvgIconWrapper from "./SvgIconWrapper";

export default function CalendarOutlinedIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_917_7340)">
          <path
            d="M14.6875 6.5H24.8125V3.6875C24.8125 2.75586 25.5648 2 26.5 2C27.4352 2 28.1875 2.75586 28.1875 3.6875V6.5H31C33.482 6.5 35.5 8.51445 35.5 11V33.5C35.5 35.982 33.482 38 31 38H8.5C6.01445 38 4 35.982 4 33.5V11C4 8.51445 6.01445 6.5 8.5 6.5H11.3125V3.6875C11.3125 2.75586 12.0648 2 13 2C13.9352 2 14.6875 2.75586 14.6875 3.6875V6.5ZM7.375 33.5C7.375 34.1188 7.87844 34.625 8.5 34.625H31C31.6187 34.625 32.125 34.1188 32.125 33.5V15.5H7.375V33.5Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_917_7340">
            <rect
              width="31.5"
              height="36"
              fill="white"
              transform="translate(4 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}
