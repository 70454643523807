import React, { useCallback } from "react";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import RECEIVING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";

export interface ItemToAssign {
  id: number;
  summary: React.ReactNode;
}

function AssignToMe({
  item,
  resetItem,
  fetchList,
}: {
  item?: ItemToAssign;
  resetItem: () => void;
  fetchList: () => void;
}) {
  const {
    mutate: assignToMe,
    ResponseHandler: ResponseHandlerOfAssigningToMe,
  } = RECEIVING_QUERY.useAssignToMe({
    itemId: item?.id ?? 0,
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();

        resetItem();
        fetchList();
      },
    },
  });

  const handleSubmit = useCallback(() => {
    assignToMe();
  }, [assignToMe]);

  const handleCancel = useCallback(() => {
    resetItem();
  }, [resetItem]);

  if (!item) return null;
  return (
    <>
      <Modal
        className={`confirm-modal`}
        active={true}
        uiType="content"
        title={"해당 건을 My의뢰로 이동시키겠습니까?"}
        body={item.summary}
        actionPositive={{
          label: "예",
          handleClick: handleSubmit,
        }}
        actionNegative={{
          label: "아니오",
          handleClick: handleCancel,
        }}
      />

      {ResponseHandlerOfAssigningToMe}
    </>
  );
}

export default AssignToMe;
