import styled from "styled-components";

const completeMovement = styled.div`
  > .complete-button {
    margin: 32px 0 24px;
  }
`;

export default {
  completeMovement,
};
