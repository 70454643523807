import { useEffect, useRef } from "react";

import {
  TableColumnInfo,
  TableDataListItem,
  TableRowInfoToHighlight,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import Styled from "./index.styles";

interface TableForCountingBasicProps<T> {
  columnInfo: TableColumnInfo<T>;
  dataList?: TableDataListItem<T>[];
  className?: string;
  rowInfoToHighlight?: TableRowInfoToHighlight;
}

type TableForCountingProps<T> =
  | (TableForCountingBasicProps<T> & { isWindowed?: false })
  | (TableForCountingBasicProps<T> & { isWindowed: true; height: number });

/**
 * 카운팅할때마다(rowInfoToHighlight 상태가 바뀔때마다) 해당 행으로 스크롤이동하고 음영처리되며 깜박인다.
 */
export default function TableForCounting<T>({
  columnInfo,
  dataList,
  className,
  rowInfoToHighlight,
  ...propsByType
}: TableForCountingProps<T>) {
  /**
   * rowInfoToHighlight정보에 맞춰 highlight할 row의 dom 요소
   */
  const highlightedRowRef = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    handleHighlightChange();

    function handleHighlightChange() {
      if (highlightedRowRef?.current) {
        highlightedRowRef.current.scrollIntoView();
      }
    }
  }, [rowInfoToHighlight]);

  return (
    <Styled.container
      className={`${className ? className : ""} table-for-counting`}
    >
      {propsByType.isWindowed ? (
        <Table
          isWindowed
          height={propsByType.height}
          columnInfo={columnInfo}
          dataList={dataList}
          ref={highlightedRowRef}
          rowInfoToHighlight={rowInfoToHighlight}
        />
      ) : (
        <Table
          columnInfo={columnInfo}
          dataList={dataList}
          ref={highlightedRowRef}
          rowInfoToHighlight={rowInfoToHighlight}
        />
      )}
    </Styled.container>
  );
}
