import { useAtom } from "jotai";
import { RECEIVING_INSPECTION_ATOMS } from "jotaiStates/receiving/inspection";
import { useRecoilValue } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import {
  FULFILLMENT_RECEIVING_ATOMS,
  FULFILLMENT_RECEIVING_SELECTORS,
} from "@sellernote/_shared/src/states/fulfillment/receiving";
import { checkForUnverifiedItemAsInspection } from "@sellernote/_shared/src/utils/fulfillment/inspection";
import { getFormattedReceivingId } from "@sellernote/_shared/src/utils/fulfillment/receiving";
import ConfirmWrapper from "@sellernote/_sds-v1/src/components/ConfirmWrapper";

import useInputMixQty from "hooks/receiving/useInputMixQty";

function CloseInspection({ receivingId }: { receivingId: number }) {
  const isCompletedInspectionWithMatchedCount = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.IS_COMPLETED_INSPECTION_WITH_MATCHED_COUNT
  );
  const { id, bidId, team, items } =
    useRecoilValue(FULFILLMENT_RECEIVING_ATOMS.MANAGER_RECEIVING_DETAIL)
      ?.receiving || {};

  const [scannedUnregisteredUnverifiedList] = useAtom(
    RECEIVING_INSPECTION_ATOMS.SCANNED_UNREGISTERED_UNVERIFIED_LIST
  );

  const {
    handleCheckHasMixQtyModalOpen,

    ResultHandlerOfInputMixQty,
  } = useInputMixQty({ receivingId });

  const isCompletedUnverifiedListInput =
    !scannedUnregisteredUnverifiedList.length &&
    !!items
      ?.filter(checkForUnverifiedItemAsInspection)
      .every(({ attachment }) => !!attachment?.length);

  return (
    <>
      <ConfirmWrapper
        confirmMessageTitle={`${getFormattedReceivingId({ bidId, id })} / ${
          team?.company ?? "-"
        } (${team?.name ?? "-"})`}
        confirmModalUiType="content"
        confirmMessageBody={
          <>
            {isCompletedInspectionWithMatchedCount
              ? "입고요청수량과 검수수량이 일치합니다."
              : "입고요청수량과 검수수량이 일치하지 않습니다."}
            <br />
            검수를 마치시겠습니까?
          </>
        }
        disabled={!isCompletedUnverifiedListInput}
      >
        <Button
          label="검수 마감"
          size="block"
          theme="primary"
          handleClick={handleCheckHasMixQtyModalOpen}
          disabled={!isCompletedUnverifiedListInput}
        />
      </ConfirmWrapper>

      {ResultHandlerOfInputMixQty}
    </>
  );
}

export default CloseInspection;
