import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useRouteMatch } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import { ReceivingWorker } from "@sellernote/_shared/src/types/fulfillment/receiving";

export default function usePersonInChargeList({
  receivingManagerId,
  workerList,
}: {
  receivingManagerId: number | undefined;
  workerList: ReceivingWorker[] | undefined;
}) {
  const match = useRouteMatch<{ id: string }>();
  const {
    params: { id },
  } = match;

  const [personToConfirm, setPersonToConfirm] = useState<{
    id: string;
    name: string;
  }>();
  const [failedOnRemove, setFailedOnRemove] = useState(false);

  const isCurrentManagerReceivingManager = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.IS_CURRENT_MANAGER_RECEIVING_MANAGER
  );

  const queryClient = useQueryClient();

  const {
    mutate: removeWorkerFromInspection,
    ResponseHandler: ResponseHandlerOfRemovingWorkerFromInspection,
  } = RECEIVING_QUERY.useRemoveWorkerFromInspection({
    receivingId: Number(id),
    workerId: Number(personToConfirm?.id) ?? 0,
  });

  const {
    refetch: fetchManagerReceivingDetailToCheck,
    ResponseHandler: ResponseHandlerOfGettingManagerReceivingDetailToCheck,
  } = RECEIVING_QUERY.useGetManagerReceivingDetailToCheck({
    receivingId: Number(id),
    enabled: false,
  });

  const handleRemovePersonClick = useCallback((person: ReceivingWorker) => {
    setPersonToConfirm(person);
  }, []);

  const handlePersonRemove = useCallback(async () => {
    if (!personToConfirm) {
      return;
    }

    const workerIdToRemove = personToConfirm.id;

    // 1) 담당자를 삭제 하기전에, 이 receiving의 최신 담당자 정보를 확인
    const { data } = await fetchManagerReceivingDetailToCheck({
      throwOnError: true,
    });

    if (!data) {
      return;
    }

    const isTargetAssignedToActiveSKU = data.data.receiving.items.some((item) =>
      item.inspectItems.some((inspectItem) => {
        // 완료되지 않은 SKU에 할당되있는 담당자는 삭제할 수 없다.
        return (
          inspectItem.inspectorId === Number(workerIdToRemove) &&
          !inspectItem.isCompleteInspecting
        );
      })
    );

    if (isTargetAssignedToActiveSKU) {
      setFailedOnRemove(true);
      return;
    }

    // 2) 담당자 삭제
    removeWorkerFromInspection(undefined, {
      onSuccess: () => {
        setPersonToConfirm(undefined);

        // 3) 담당자 삭제가 반영된 최신 정보 불러오기
        queryClient.invalidateQueries(
          RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
            receivingId: Number(id),
          })
        );
      },
    });
  }, [
    fetchManagerReceivingDetailToCheck,
    id,
    personToConfirm,
    queryClient,
    removeWorkerFromInspection,
  ]);

  const chipDataList = useMemo(
    () =>
      (workerList ?? []).map((v) => {
        const isReceivingManager = Number(v.id) === receivingManagerId;
        const canRemove =
          isCurrentManagerReceivingManager && !isReceivingManager;

        return {
          id: v.id,
          label: v.name,
          handleClear: canRemove ? () => handleRemovePersonClick(v) : undefined,
        };
      }),
    [
      isCurrentManagerReceivingManager,
      handleRemovePersonClick,
      receivingManagerId,
      workerList,
    ]
  );

  const RemoveWorkerFromReceivingModal = useMemo(
    () => (
      <>
        <Modal
          uiType="titleOnly"
          active={!!personToConfirm}
          title={`정말로 ${personToConfirm?.name}(${personToConfirm?.id})을(를) 검수에서 제외시키겠습니까?`}
          actionPositive={{
            label: "확인",
            handleClick: handlePersonRemove,
          }}
          actionNegative={{
            label: "취소",
            handleClick: () => setPersonToConfirm(undefined),
          }}
        />

        <Modal
          uiType="titleOnly"
          active={failedOnRemove}
          title={`선택한 담당자(${personToConfirm?.name})는 스캔검수 중입니다. 제외시킬 수 없습니다. `}
          actionPositive={{
            label: "확인",
            handleClick: () => {
              setFailedOnRemove(false);
              setPersonToConfirm(undefined);
            },
          }}
        />
      </>
    ),
    [failedOnRemove, handlePersonRemove, personToConfirm]
  );

  return {
    chipDataList,

    RemoveWorkerFromReceivingModal,

    ResponseHandlerOfRemovingWorkerFromInspection,
    ResponseHandlerOfGettingManagerReceivingDetailToCheck,
  };
}
