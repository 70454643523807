import { useCallback, useEffect, useMemo, useState } from "react";

import { BofulLocation } from "@sellernote/_shared/src/types/fulfillment/common";

import Styled from "./index.styles";

export default function useSearchLocation({
  locationListByType,
  selectedLocation,
  onLocationSelect,
  onReset,
}: {
  locationListByType: BofulLocation[] | undefined;
  selectedLocation: BofulLocation | undefined;
  onLocationSelect: (v: BofulLocation) => void;
  onReset: () => void;
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [isVisibleOptionList, setIsVisibleOptionList] = useState(false);

  useEffect(() => {
    if (!searchTerm) return;

    if (!selectedLocation) return setIsVisibleOptionList(true);

    if (searchTerm !== selectedLocation.barCode) return onReset();

    setIsVisibleOptionList(false);
  }, [onReset, searchTerm, selectedLocation]);

  const handleLocationSelect = useCallback(
    (v: BofulLocation) => () => {
      setSearchTerm("");
      onLocationSelect(v);
    },
    [onLocationSelect]
  );

  const OptionList = useMemo(() => {
    if (!searchTerm || !locationListByType) {
      return [];
    }

    const filteredList = locationListByType.filter((location) =>
      location.barCode.toUpperCase().includes(searchTerm.toUpperCase())
    );

    return filteredList.map((location) => (
      <Styled.option
        key={location.barCode}
        onClick={handleLocationSelect(location)}
      >
        {/* TODO: 인천1센터로 고정시켜 둠 */}
        인천1센터 / {location.barCode}
      </Styled.option>
    ));
  }, [searchTerm, locationListByType, handleLocationSelect]);

  return {
    selectedLocationBarcode: selectedLocation?.barCode || "",
    searchTerm,
    setSearchTerm,

    isVisibleOptionList,
    OptionList,
  };
}
