import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

import useChangeTotalPackingUnit, {
  DataToChangeTotalPackingUnit,
} from "./useChangeTotalPackingUnit";
import Styled from "./index.styles";

export default function ChangeTotalPackingUnitModal({
  dataToChangeTotalPackingUnit,
  setDataToChangeTotalPackingUnit,
}: {
  dataToChangeTotalPackingUnit: DataToChangeTotalPackingUnit | undefined;
  setDataToChangeTotalPackingUnit: React.Dispatch<
    React.SetStateAction<DataToChangeTotalPackingUnit | undefined>
  >;
}) {
  const {
    packingUnitPallet,
    packingUnitBox,
    packingUnitOther,
    handleTotalPackingUnitFormChange,

    totalPackingUnit,

    handleModalClose,

    isValidatedForm,
    handleTotalPackingUnitChange,

    ResponseHandlerOfChangingTotalPackingUnit,
  } = useChangeTotalPackingUnit({
    dataToChangeTotalPackingUnit,
    setDataToChangeTotalPackingUnit,
  });

  return (
    <>
      <Modal
        uiType="contentWithCustomBody"
        title="총 포장 개수 수정"
        active
        body={
          <Styled.changeTotalPackingUnitModalBody>
            <form className="total-packing-unit">
              <InputText
                label="팔레트"
                borderType="outline"
                placeholder="입력해주세요."
                valueType="int"
                value={packingUnitPallet}
                setValue={handleTotalPackingUnitFormChange("packingUnitPallet")}
              />
              <InputText
                label="박스"
                borderType="outline"
                placeholder="입력해주세요."
                valueType="int"
                value={packingUnitBox}
                setValue={handleTotalPackingUnitFormChange("packingUnitBox")}
              />
              <InputText
                label="기타"
                borderType="outline"
                placeholder="입력해주세요."
                valueType="int"
                value={packingUnitOther}
                setValue={handleTotalPackingUnitFormChange("packingUnitOther")}
              />
            </form>

            <ListItem
              label="총합"
              type="withContentAlignRight"
              value={totalPackingUnit}
            />

            <div className="actions">
              <Button
                theme="secondary"
                size="normal"
                label="취소"
                handleClick={handleModalClose}
              />
              <Button
                theme="primary"
                size="normal"
                label="변경사항 저장"
                disabled={!isValidatedForm}
                handleClick={handleTotalPackingUnitChange}
              />
            </div>
          </Styled.changeTotalPackingUnitModalBody>
        }
        onClose={handleModalClose}
      />

      {ResponseHandlerOfChangingTotalPackingUnit}
    </>
  );
}
