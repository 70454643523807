import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

import Styled from "./index.styles";

function ShippingPacking() {
  return (
    <Layout
      navigator={{
        title: "패킹",
      }}
    >
      <Styled.container>
        <figure>
          <img
            src="/images/pda_shipping_packing.svg"
            alt="pda-shipping-packing"
          />
          <figcaption>패킹은 웹에서만 작업가능합니다.</figcaption>
        </figure>
      </Styled.container>
    </Layout>
  );
}

export default withRequireAuth(ShippingPacking);
