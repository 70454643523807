import styled, { css } from "styled-components";

import { SelectOptionSize } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import { showVerticalScrollBarAlways } from "@sellernote/_shared/src/styles/functions";

import { COLOR, TEXT_COLOR } from "../../../styles/colors";
import { setElevationStyle } from "../../../styles/elevation";
import { setFontStyle } from "../../../styles/typography";

import { optionListItemStyle } from "../index.styles";
import { LabelInfoPosition } from "../InputSearch";

const container = styled.div<{
  width?: string;
  disabled?: boolean;
  labelWidth?: string;
  size: SelectOptionSize;
}>`
  width: ${({ width }) => (width ? width : "100%")};
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${({ size }) => {
    if (size === "small") {
      return css`
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      `;
    }
  }}

  > label {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    margin-bottom: 4px;
    ${setFontStyle("Body3")};
    color: ${TEXT_COLOR.black_2};

    ${({ size }) => {
      if (size === "small") {
        return css`
          margin-bottom: 0;
          margin-right: 20px;
          width: max-content;
          color: ${TEXT_COLOR.black_3};
        `;
      }
    }};

    ${({ disabled, size }) => {
      if (disabled) {
        if (size === "small") {
          return css`
            color: ${TEXT_COLOR.black_3};
            cursor: not-allowed;
          `;
        }

        return css`
          color: ${TEXT_COLOR.black_disabled};
          cursor: not-allowed;
        `;
      }
    }};

    ${({ labelWidth }) => {
      if (labelWidth) {
        return css`
          width: ${labelWidth};
        `;
      }
    }};

    > .tooltip {
      margin-left: 2px;
    }
  }

  > .select-container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    > .error-message {
      position: absolute;
      left: 0px;
      bottom: -17px;
      color: ${COLOR.error_400};
      ${setFontStyle("Body5")};
    }
  }
`;

const inputWrapper = styled.div<{
  isActive: boolean;
  isVisiblePlaceholder: boolean;
  size: SelectOptionSize;
  disabled?: boolean;
}>`
  position: relative;
  width: 100%;
  height: 48px;
  padding: 11px;
  border: 1px solid
    ${({ isActive }) => (isActive ? COLOR.primary_800 : COLOR.grayScale_400)};
  border-radius: 2px;
  background-color: ${COLOR.wh};
  cursor: pointer;

  ${({ size, isActive }) => {
    if (size === "small") {
      return css`
        border-color: ${isActive ? COLOR.primary_800 : COLOR.grayScale_300};
        height: 40px;
        padding: 9px 11px;
      `;
    }
  }};

  ${({ disabled }) => {
    if (disabled) {
      return css`
        border: 1px solid ${COLOR.grayScale_300};
        background-color: ${COLOR.grayScale_100};
        cursor: not-allowed;
      `;
    }
  }};

  .selected-option-value {
    ${setFontStyle("Body2")};
    color: ${({ isVisiblePlaceholder }) =>
      isVisiblePlaceholder ? TEXT_COLOR.black_5 : TEXT_COLOR.black_2};
    display: inline-block;
    width: 90%;
    height: 26px;
    overflow: hidden;

    ${({ size }) => {
      if (size === "small") {
        return css`
          ${setFontStyle("Body4")};
          line-height: 22px;
        `;
      }
    }};

    ${({ disabled }) => {
      if (disabled) {
        return css`
          color: ${TEXT_COLOR.black_disabled};
        `;
      }
    }}
  }

  .icon {
    position: absolute;
    top: 16px;
    right: 8px;

    ${({ size }) => {
      if (size === "small") {
        return css`
          top: 11px;
        `;
      }
    }};
  }
`;

const optionList = styled.ul<{
  disabled?: boolean;
  size?: SelectOptionSize;
  labelPosition?: LabelInfoPosition;
  width?: string | number;
  optionListPosition: "top" | "bottom";
  searchResultOptionPosition: { width: string };
}>`
  background-color: ${COLOR.wh};
  z-index: 9;
  overflow-y: auto;
  max-height: 220px;
  ${setElevationStyle("level3")};

  ${showVerticalScrollBarAlways()}

  ${({ searchResultOptionPosition }) =>
    css`
      position: fixed;
      width: ${searchResultOptionPosition.width};
    `}

  li {
    ${optionListItemStyle}

    &.disabled {
      color: ${TEXT_COLOR.black_disabled};
      background-color: ${COLOR.grayScale_50};
      cursor: not-allowed;
    }
  }
`;

export default {
  container,
  inputWrapper,
  optionList,
};
