import styled, { css } from "styled-components";

import { COLOR } from "../../styles/colors";

import { DivideProps, HorizontalType, VerticalType } from ".";

type DivideStyleProps = Omit<DivideProps, "className">;

const container = styled.div<DivideStyleProps>`
  ${({ type, thickness }) => {
    if (type === "vertical") {
      return css`
        display: flex;
        width: ${thickness ? `${thickness}px` : "1px"};
        height: 100%;
      `;
    }
  }}

  > div {
    background-color: ${({ color }) => (color ? color : COLOR.grayScale_400)};
  }
`;

const divideHorizontalBlock = styled.div<
  Pick<HorizontalType, "width"> & Pick<DivideProps, "thickness">
>`
  width: ${({ width }) => width ?? "100%"};
  height: ${({ thickness }) => (thickness ? `${thickness}px` : "1px")};
`;

const divideVerticalBlock = styled.div<
  Pick<VerticalType, "height"> & Pick<DivideProps, "thickness">
>`
  width: ${({ thickness }) => (thickness ? `${thickness}px` : "1px")};
  height: ${({ height }) => height ?? "100%"};
`;

export default {
  container,
  divideHorizontalBlock,
  divideVerticalBlock,
};
