import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";

import {
  AxiosHttpMethod,
  ResponseFailureInfo,
  SendRequestOptions,
} from "../../types/common/common";

type ReducerWithSagaTriggerActionType<KEY extends string> = KEY;
type ReducerWithSagaTriggerActionReducer<REQUEST_PAYLOAD> = {
  prepare: (payload: REQUEST_PAYLOAD) => any;
  reducer: (state: any, action: PayloadAction<REQUEST_PAYLOAD>) => void;
};
/**
 * saga용 action을 시작하는 trigger 액션과 리듀서
 * (ex. GET_LIST)
 */
type ReducerWithSagaTrigger<KEY extends string, REQUEST_PAYLOAD> = {
  [P in ReducerWithSagaTriggerActionType<KEY>]: ReducerWithSagaTriggerActionReducer<REQUEST_PAYLOAD>;
};

type ReducerWithSagaInitActionType<KEY extends string> = `INIT_${KEY}`;
/**
 * saga용 state를 초기화하는 액션과 리듀서
 * (ex. INIT_GET_LIST)
 */
type ReducerWithSagaInit<KEY extends string> = {
  [P in ReducerWithSagaInitActionType<KEY>]: ReducerWithSagaTriggerActionReducer<{
    _key?: string | number;
    key?: string | number;
  }>;
};

type ReducerWithSagaResponseActionType<KEY extends string> =
  | `${KEY}_SUCCESS`
  | `${KEY}_FAILURE`;
type ReducerWithSagaResponseActionReducer = (state: any, action: any) => void;
/**
 * saga용 action의 결과에 대한 액션과 리듀서
 * (ex. GET_LIST_SUCCESS, GET_LIST_FAILURE)
 */
type ReducerWithSagaResponse<KEY extends string> = {
  [P in ReducerWithSagaResponseActionType<KEY>]: ReducerWithSagaResponseActionReducer;
};

/**
 * saga와 연결해서 사용되는 액션과 리듀서의 모음
 * (ex. GET_LIST, INIT_GET_LIST, GET_LIST_SUCCESS, GET_LIST_FAILURE)
 */
type ReducerWithSagaBundle<
  KEY extends string,
  REQUEST_PAYLOAD
> = ReducerWithSagaTrigger<KEY, REQUEST_PAYLOAD> &
  ReducerWithSagaInit<KEY> &
  ReducerWithSagaResponse<KEY>;

type StringLiteral<T> = T extends `${string & T}` ? T : never;

export interface CreateReducerWithSagaBundleProps<
  KEY,
  REQUEST_PAYLOAD,
  RESPONSE_PAYLOAD,
  SLICE_STATE
> {
  sendRequestFunction: any;
  loadingActions: any;
  actionTypeKey: StringLiteral<KEY>;
  sliceName: string;
  /**
   * state 하위의 특정 state를 사용하고 싶은 경우. 2depth이상인 경우는 '.'으로 구분
   * (ex. state.order이라는 state를 사용하고 싶을때: "order")
   * (ex. state.order.validation이라는 state를 사용하고 싶을때: "order.validation")
   *
   * *유의사항: custom 옵션(ex. customInitHandler)을 사용하는 경우 parentStateName 설정은 무시됨.
   */
  parentStateName?: string;
  getRequestOptions: (payload: REQUEST_PAYLOAD) => SendRequestOptions;
  /**
   * saga관련 작업 전에 처리하고 싶은 코드가 있을때 사용
   */
  customActionBeforeSaga?: ({
    state,
    action,
  }: {
    state: SLICE_STATE;
    action: PayloadAction<REQUEST_PAYLOAD> & {
      _key?: string | number;
      key?: string | number;
    };
  }) => void;
  /**
   * INIT 리듀서를 커스터마이징하고 싶을때 사용
   */
  customInitHandler?: ({
    state,
    actionTypeKey,
    action,
  }: {
    state: SLICE_STATE;
    actionTypeKey: StringLiteral<KEY>;
    action: { payload?: { _key?: string | number; key?: string | number } };
  }) => void;
  /**
   * 요청 후 SUCCESS 리듀서를 커스터마이징하고 싶을때 사용
   */
  customSuccessHandler?: ({
    state,
    actionTypeKey,
    action,
  }: {
    state: SLICE_STATE;
    actionTypeKey: StringLiteral<KEY>;
    action: {
      payload?: RESPONSE_PAYLOAD;
      _key?: string | number;
      key?: string | number;
    };
  }) => void;
  /**
   * 요청 후 FAILURE 리듀서를 커스터마이징하고 싶을때 사용
   */
  customFailureHandler?: ({
    state,
    actionTypeKey,
    action,
  }: {
    state: SLICE_STATE;
    actionTypeKey: StringLiteral<KEY>;
    action: {
      payload?: ResponseFailureInfo;
      _key?: string | number;
      key?: string | number;
    };
  }) => void;
}

/**
 * redux-saga을 통해 네트워크 요청을 하고 응답하는 액션과 리듀서 코드(리듀서 번들 객체)를 만들어준다.
 *
 * 'actionTypeKey'를 기준으로 다음 형태의 객체가 생성된다. (각 property 상세내용은 코드를 참조)
 * ```js
 * {
 *  {actionTypeKey}: ..,
 *  INIT_{actionTypeKey}: ..,
 *  {actionTypeKey}_SUCCESS: ..,
 *  {actionTypeKey}_FAILURE: ..,
 * }
 * ```
 *
 * REQUEST_PAYLOAD로 redux-saga를 실행시키는 액션에 담을 payload를 정의 할 수 있다.
 *
 */
export function createReducerWithSagaBundle<
  KEY extends string,
  REQUEST_PAYLOAD,
  RESPONSE_PAYLOAD,
  SLICE_STATE extends { [P in string]: any }
>({
  sendRequestFunction,
  loadingActions,
  sliceName,
  parentStateName,
  actionTypeKey,
  getRequestOptions,
  customActionBeforeSaga,
  customInitHandler,
  customSuccessHandler,
  customFailureHandler,
}: CreateReducerWithSagaBundleProps<
  KEY,
  REQUEST_PAYLOAD,
  RESPONSE_PAYLOAD,
  SLICE_STATE
>) {
  const reducerBundle = {
    [`${actionTypeKey}` as const]: {
      prepare: (payload: REQUEST_PAYLOAD) => {
        return {
          payload,
        };
      },
      reducer: (state: SLICE_STATE, action: PayloadAction<REQUEST_PAYLOAD>) => {
        if (customActionBeforeSaga) {
          customActionBeforeSaga({ state, action });
        }

        // handled by saga
      },
    },

    [`${actionTypeKey}_SUCCESS` as const]: (
      state: SLICE_STATE,
      action: { payload?: RESPONSE_PAYLOAD }
    ) => {
      if (customSuccessHandler) {
        customSuccessHandler({ state, action, actionTypeKey });
      } else {
        const targetState = parentStateName
          ? getTargetState(state, parentStateName)
          : state;

        targetState[actionTypeKey] = {
          data: action.payload,
          status: "SUCCESS",
        } as any;
      }
    },

    [`${actionTypeKey}_FAILURE` as const]: (
      state: SLICE_STATE,
      action: {
        payload?: ResponseFailureInfo;
      }
    ) => {
      if (customFailureHandler) {
        customFailureHandler({ state, action, actionTypeKey });
      } else {
        const targetState = parentStateName
          ? getTargetState(state, parentStateName)
          : state;

        targetState[actionTypeKey] = {
          status: "FAILURE",
          failureInfo: action.payload,
        } as any;
      }
    },

    [`INIT_${actionTypeKey}` as const]: {
      prepare: (_key?: number | string, key?: number | string) => {
        return {
          payload: {
            _key,
            key,
          },
        };
      },
      reducer: (
        state: SLICE_STATE,
        action: PayloadAction<{ _key?: number | string; key?: number | string }>
      ) => {
        if (customInitHandler) {
          customInitHandler({ state, actionTypeKey, action });
        } else {
          const targetState = parentStateName
            ? getTargetState(state, parentStateName)
            : state;

          targetState[actionTypeKey] = apiResponseInitialState as any;
        }
      },
    },
  } as ReducerWithSagaBundle<
    KEY,
    REQUEST_PAYLOAD & {
      /**
       * FE에서 사용할수 있는 key
       */
      _key?: string | number;
      /**
       * 요청 응답이 SUCCESS일때 dispatch할 Action생성 함수
       */
      _postSuccessActionCreator?: (
        successResponse: RESPONSE_PAYLOAD
      ) => PayloadAction<any> | undefined | void;
      /**
       * 요청 응답이 SUCCESS일때 실행할 함수 (postSuccessAction보다 먼저 실행 됨)
       */
      _postSuccessCallback?: (successResponse: RESPONSE_PAYLOAD) => void;
      /**
       * 요청 응답이 FAILURE일때 실행할 함수
       */
      _postFailureCallback?: (failureInfo: ResponseFailureInfo) => void;
    }
  >;

  const saga = createRequestSaga({
    type: `${sliceName}/${actionTypeKey}`,
    request: (payload: REQUEST_PAYLOAD) => {
      // FE에서 사용하기 위해 추가한 payload는 삭제
      const payloadForSend = { ...payload };
      delete (payloadForSend as any)._postSuccessActionCreator;
      delete (payloadForSend as any)._postSuccessCallback;
      delete (payloadForSend as any)._postFailureCallback;
      delete (payloadForSend as any)._key;

      return sendRequestFunction(getRequestOptions(payloadForSend));
    },
    loadingActions,
  });

  return { reducerBundle, saga };
}

export const apiResponseInitialState = {
  data: undefined,
  status: undefined,
  failureInfo: undefined,
};

/**
 * @param originState
 * @param parentIndicator '.'으로 구분된 state이름 (root state(originState) 하위의 state이름부터 쓰면 됨)
 * @returns
 */
export function getTargetState(originState: any, parentIndicator: string) {
  if (!parentIndicator) {
    return originState;
  }

  const indicatorList = parentIndicator.split(".");
  if (!indicatorList) {
    return originState;
  }

  let targetState = originState;
  indicatorList.forEach((v) => {
    if (v in targetState) {
      targetState = targetState[v];
    }
  });

  return targetState;
}

export interface SagaResult {
  type: string;
  payload: any;
  _key?: number | string;
  key?: number | string;
  note?: any; // request후 response에서 알아야할 데이터가 있을경우 사용
}

/**
 * key가 0일 때도 체크하기 위해서 사용
 * @param {*} key
 */
function hasKey(key?: number) {
  if (key || typeof key === "number") {
    return true;
  }

  return false;
}

export function createRequestSaga({
  type,
  request,
  loadingActions,
}: {
  type: string;
  request: any;
  loadingActions: any;
}) {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;

  return function* (action: any): any {
    // (Optional) 요청했을때의 _key를 응답에서도 사용해야할 경우 payload에 _key를 전달해 사용할 수 있다.
    // (_key뿐만아니라, legacy호환을 위해 key도 지원한다 - _key가 있으면 _key를 우선사용함)

    const _key = hasKey(action.payload?._key) ? action.payload._key : null;
    const key = hasKey(action.payload?.key) ? action.payload.key : null;

    yield put(loadingActions.START_LOADING(type, _key || key));

    try {
      const response = yield call(request, action.payload);
      const result: SagaResult = {
        type: SUCCESS,
        payload: response.data,
        note: action.note,
      };

      if (hasKey(_key)) {
        result._key = _key;
      }
      if (hasKey(key)) {
        result.key = key;
      }

      yield put(result);

      if (action.payload._postSuccessCallback) {
        yield call(action.payload._postSuccessCallback, response.data);
      }

      if (action.payload._postSuccessActionCreator) {
        const postSuccessAction = action.payload._postSuccessActionCreator(
          response.data
        );

        if (postSuccessAction) {
          yield put(postSuccessAction);
        }
      }
    } catch (e: any) {
      const result: SagaResult = {
        type: FAILURE,
        payload: e.response ? e.response.data : e.response,
        note: action.note,
      };

      if (hasKey(_key)) {
        result._key = _key;
      }
      if (hasKey(key)) {
        result.key = key;
      }
      yield put(result);

      if (action.payload._postFailureCallback) {
        yield call(action.payload._postFailureCallback, result.payload);
      }
    }

    yield put(loadingActions.FINISH_LOADING(type, _key || key));
  };
}

export function isValidAxiosHttpMethod(method?: string): boolean {
  if (!method) {
    return false;
  }

  const validMethods: AxiosHttpMethod[] = [
    "get",
    "put",
    "post",
    "delete",
    "patch",
  ];

  return validMethods.includes(method as AxiosHttpMethod);
}
