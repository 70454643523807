import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

const container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const unverifiedSku = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${COLOR.pointWarning};
`;

export default {
  container,
  unverifiedSku,
};
