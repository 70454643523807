import { useCallback, useState } from "react";
import { useRecoilValue } from "recoil";

import SHIPPING_QUERY from "@sellernote/_shared/src/queries/fulfillment/SHIPPING_QUERY";
import { FULFILLMENT_SHIPPING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/shipping";
import { ScanType } from "@sellernote/_shared/src/types/fulfillment/scan";

import usePickingMessageModal from "hooks/shipping/usePickingMessageModal";

export default function useInvoiceDirectInput({
  onScanButtonSelect,
}: {
  onScanButtonSelect: (type: ScanType | undefined) => void;
}) {
  const [
    isVisibleInvoiceDirectInputModal,
    setIsVisibleInvoiceDirectInputModal,
  ] = useState(false);
  const [invoiceNo, setInvoiceNo] = useState("");

  const isActiveInvoiceDirectInputButton =
    useRecoilValue(FULFILLMENT_SHIPPING_ATOMS.SCANNED_PICKING_INVOICE)
      .shippingId === 0;

  const { MessageModal, modalOpenHandler } = usePickingMessageModal();

  const {
    mutate: scanPickingInvoice,
    ResponseHandler: ResponseHandlerOfPickingInvoice,
  } = SHIPPING_QUERY.useScanPickingInvoice({
    type: "directInput",
    openCanceledPickingInvoice: modalOpenHandler.openCanceledPickingInvoice,
    onScanButtonSelect,
  });

  const handleDirectInputModalConfirm = useCallback(() => {
    scanPickingInvoice({ pathParams: { invoiceNo } });

    setIsVisibleInvoiceDirectInputModal(false);
    setInvoiceNo("");
  }, [invoiceNo, scanPickingInvoice]);

  const handleDirectInputModalOpen = useCallback(() => {
    setIsVisibleInvoiceDirectInputModal(true);
  }, []);

  const handleDirectInputModalClose = useCallback(() => {
    setIsVisibleInvoiceDirectInputModal(false);
    setInvoiceNo("");
  }, []);

  return {
    isActiveInvoiceDirectInputButton,
    isVisibleInvoiceDirectInputModal,
    invoiceNo,
    setInvoiceNo,
    handleDirectInputModalClose,
    handleDirectInputModalConfirm,
    handleDirectInputModalOpen,

    PickingInvoiceMessageModal: MessageModal,
    ResponseHandlerOfPickingInvoice,
  };
}
