import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const selectDuplicateBarcodeModalBody = styled.div`
  height: 478px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;

  .input-select {
    .label {
      text-align: left;
    }
  }

  .button {
    align-self: center;
  }
`;

const modalItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const modalItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const description = styled.p`
  ${setFontStyle("Body7")};
  color: ${COLOR.grayScale_700};
`;

export default {
  selectDuplicateBarcodeModalBody,
  modalItemContainer,
  modalItem,
  description,
};
