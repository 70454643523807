import styled from "styled-components";

const container = styled.section`
  > .tab-filter {
    margin-bottom: 40px;
  }

  .paging {
    margin-bottom: 80px;
  }
`;

const issueInvoiceContainer = styled.div`
  margin-bottom: 16px;
  display: flex;

  .confirm-wrapper {
    margin-left: auto;
  }
`;

export default {
  container,
  issueInvoiceContainer,
};
