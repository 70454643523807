import { ReceivingWorker } from "@sellernote/_shared/src/types/fulfillment/receiving";
import ChipsWithClear from "@sellernote/_sds-v1/src/components/ChipsWithClear";

import Styled from "../index.styles";
import usePersonInChargeList from "./usePersonInChargeList";

function PersonInChargeList({
  returningManagerId,
  workerList,
}: {
  returningManagerId: number | undefined;
  workerList: ReceivingWorker[] | undefined;
}) {
  const {
    chipDataList,

    RemoveWorkerFromInspectionModal,

    ResponseHandlerOfRemovingWorkerFromInspection,
  } = usePersonInChargeList({ returningManagerId, workerList });

  if (!workerList || !workerList.length) return null;

  return (
    <>
      <Styled.personInChargeList>
        <ChipsWithClear chipDataList={chipDataList} />
      </Styled.personInChargeList>

      {RemoveWorkerFromInspectionModal}

      {ResponseHandlerOfRemovingWorkerFromInspection}
    </>
  );
}

export default PersonInChargeList;
