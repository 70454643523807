import { BofulWorker } from "@sellernote/_shared/src/types/fulfillment/auth";
import { BofulLocation } from "@sellernote/_shared/src/types/fulfillment/common";
import { ReceivingPlaceItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { ScannedLocation } from "@sellernote/_shared/src/types/fulfillment/scan";

import {
  CounterDataForWarehousing,
  getCounterKeyByStatus,
} from "hooks/returning/useSKUCountingForWarehousing";

function getCanReset({
  skuId,
  itemId,
  counterData,
  hasRightToReset,
  hasMultiLocation,
  isGroupedItem,
}: {
  skuId: number;
  itemId: number;
  counterData: CounterDataForWarehousing;
  hasRightToReset: boolean;
  hasMultiLocation: boolean;
  isGroupedItem: boolean;
}): boolean {
  if (hasMultiLocation) {
    return hasRightToReset;
  }

  const counterKeyAsSingleLocation = getCounterKeyByStatus({
    isGroupedItem,
    skuId,
    itemId,
  });
  const itemCounter = counterData[counterKeyAsSingleLocation];
  return (
    hasRightToReset &&
    (!!itemCounter?.current || !!itemCounter?.isCompletePlacing)
  );
}

function getHasRightToReset({
  hasMultiLocation,
  currentUserIsMainManager,
  currentUserId,
  placeItems,
}: {
  hasMultiLocation: boolean;
  currentUserIsMainManager: boolean;
  currentUserId: number | undefined;
  placeItems: ReceivingPlaceItem[];
}) {
  // 분할입고일때는 메인매니저만 초기화 권한을 주면 됨.
  if (hasMultiLocation) {
    return currentUserIsMainManager;
  }

  return currentUserIsMainManager || currentUserId === placeItems[0].placerId;
}

function getCurrentSKUCount({
  skuId,
  itemId,
  counterData,
  placeItems,
  hasMultiLocation,
  isGroupedItem,
}: {
  skuId: number;
  itemId: number;
  counterData: CounterDataForWarehousing;
  placeItems: ReceivingPlaceItem[];
  hasMultiLocation: boolean;
  isGroupedItem: boolean;
}) {
  if (!counterData) return 0;

  return placeItems.reduce((a, c) => {
    const counterKey = getCounterKeyByStatus({
      hasMultiLocationWarehousing: hasMultiLocation,
      isGroupedItem,
      skuId,
      itemId,
      placingId: c.placingId,
    });

    const currentTempCount = counterData[counterKey]?.current;

    return a + (currentTempCount || c.placeQty || 0);
  }, 0);
}

function getPersonInCharge({
  placeItems,
  getWorkerById,
}: {
  placeItems: ReceivingPlaceItem[];
  getWorkerById: (workerId?: number | undefined) => BofulWorker | undefined;
}) {
  if (!placeItems.length) return "";

  const workerIds = placeItems.reduce((a: number[], c) => {
    if (c.placerId) {
      a.push(c.placerId);
    }
    return a;
  }, []);

  const setOfWorkerIds = new Set(workerIds);

  const firstWorkerName = getWorkerById(workerIds[0])?.name ?? "";

  const isMultiWorker = setOfWorkerIds.size > 1;

  return isMultiWorker
    ? `${firstWorkerName} 외 ${workerIds.length - 1}`
    : firstWorkerName;
}

function getLocationName({
  skuId,
  itemId,
  counterData,
  selectedLocation,
  hasMultiLocation,
  placeItems,
  locationListOfWarehouse,
  placingIdInProgress,
  isGroupedItem,
}: {
  skuId: number;
  itemId: number;
  counterData: CounterDataForWarehousing;
  hasMultiLocation: boolean;
  placeItems: ReceivingPlaceItem[];
  selectedLocation: ScannedLocation | undefined;
  locationListOfWarehouse: BofulLocation[];
  placingIdInProgress: string | undefined;
  isGroupedItem: boolean;
}) {
  if (hasMultiLocation) {
    return `분할입고 - ${placeItems.length}건`;
  }

  const itemAsSingleLocation = placeItems[0];

  const counterKey = getCounterKeyByStatus({
    isGroupedItem,
    skuId,
    itemId,
  });
  const counter = counterData[counterKey];

  // 현재 작업 중
  if (
    placingIdInProgress &&
    placingIdInProgress === itemAsSingleLocation.placingId &&
    counter?.current
  ) {
    return selectedLocation?.barCode ?? "";
  }

  // 완료된 위치
  if (itemAsSingleLocation.locationId) {
    return (
      locationListOfWarehouse.find(
        (v) => v.id === itemAsSingleLocation.locationId
      )?.barCode ?? ""
    );
  }

  return "";
}

export {
  getCanReset,
  getHasRightToReset,
  getCurrentSKUCount,
  getPersonInCharge,
  getLocationName,
};
