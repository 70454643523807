import { useCallback } from "react";
import { useQueryClient } from "react-query";

import {
  RESET_INSPECTOR_AND_INSPECTION_OF_ITEM_REQ,
  RESET_INSPECTOR_AND_INSPECTION_OF_ITEM_REQ_PATH_PARAMS,
} from "@sellernote/_shared/src/api-interfaces/boful-api/receiving";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";

import useResetInputtingCounterInfo from "hooks/common/useResetInputtingCounterInfo";

export default function useRemoveInspectionPersonInCharge() {
  const resetInputtingCounterInfo = useResetInputtingCounterInfo();

  const queryClient = useQueryClient();

  const {
    mutate: resetInspectorAndInspectionOfItem,
    ResponseHandler: ResponseHandlerOfRemovingPersonInCharge,
  } = RECEIVING_QUERY.useResetInspectorAndInspectionOfItem();

  const removePersonInCharge = useCallback(
    ({
        receivingId,
        itemId,
        inspectingId,
        resetAfterRemoveInProgressPersonInCharge,
      }: RESET_INSPECTOR_AND_INSPECTION_OF_ITEM_REQ &
        RESET_INSPECTOR_AND_INSPECTION_OF_ITEM_REQ_PATH_PARAMS & {
          resetAfterRemoveInProgressPersonInCharge: () => void;
        }) =>
      () => {
        if (!receivingId) return;

        resetInspectorAndInspectionOfItem(
          {
            pathParams: {
              receivingId,
              itemId,
            },
            inspectingId,
          },
          {
            onSuccess: () => {
              resetInputtingCounterInfo();

              resetAfterRemoveInProgressPersonInCharge();

              queryClient.invalidateQueries(
                RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
                  receivingId,
                })
              );
            },
          }
        );
      },
    [queryClient, resetInputtingCounterInfo, resetInspectorAndInspectionOfItem]
  );

  return { removePersonInCharge, ResponseHandlerOfRemovingPersonInCharge };
}
