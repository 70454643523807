import SvgIconWrapper from "./SvgIconWrapper";

export default function ChevronRightIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_917_7666)">
          <path
            d="M15.5 37.5C14.8603 37.5 14.2203 37.2559 13.7328 36.7676C12.7562 35.791 12.7562 34.209 13.7328 33.2324L26.9687 20L13.7328 6.76797C12.7562 5.7914 12.7562 4.20937 13.7328 3.23281C14.7094 2.25625 16.2914 2.25625 17.268 3.23281L32.268 18.2328C33.2445 19.2094 33.2445 20.7914 32.268 21.768L17.268 36.768C16.7812 37.2578 16.1406 37.5 15.5 37.5Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_917_7666">
            <rect
              width="25"
              height="40"
              fill="white"
              transform="translate(8)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}
