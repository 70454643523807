import { Dispatch, SetStateAction } from "react";

import Barcode from "@sellernote/_shared/src/componentsToMoveToV1/Barcode";
import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { BofulLocation } from "@sellernote/_shared/src/types/fulfillment/common";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";

import useScanLocation from "./useScanLocation";
import Styled from "./index.styles";

function ResetBarcode({
  onLocationBarcodeReset,
}: {
  onLocationBarcodeReset: () => void;
}) {
  return (
    <Styled.resetBarcode>
      <Button
        theme="secondary"
        label="재스캔"
        size="normal"
        icon={{
          position: "right",
          type: "reset",
        }}
        handleClick={onLocationBarcodeReset}
      />
    </Styled.resetBarcode>
  );
}

export default function ScanLocation({
  label,
  locationListByType,
  tempLocationBarcode,
  setTempLocation,
  onLocationBarcodeReset,
}: {
  label: string | undefined;
  locationListByType: BofulLocation[] | undefined;
  tempLocationBarcode: string | undefined;
  setTempLocation: Dispatch<SetStateAction<BofulLocation | undefined>>;
  onLocationBarcodeReset: () => void;
}) {
  const { ValidationErrorToast } = useScanLocation({
    label,
    locationListByType,
    tempLocationBarcode,
    setTempLocation,
  });

  return (
    <>
      <Styled.scanLocation>
        <Styled.locationBarcode>
          {!!tempLocationBarcode && (
            <>
              <Barcode
                value={tempLocationBarcode}
                options={{
                  displayValue: true,
                  margin: 0,
                }}
              />

              <ResetBarcode onLocationBarcodeReset={onLocationBarcodeReset} />
            </>
          )}
        </Styled.locationBarcode>

        <InputText
          borderType="outline"
          valueType="string"
          label={label}
          value={tempLocationBarcode}
          disabledToFocus
          disabled={!!tempLocationBarcode}
        />
      </Styled.scanLocation>

      {ValidationErrorToast}
    </>
  );
}
