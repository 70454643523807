import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";

import BofulRequestResponseHandler from "containers/BofulRequestResponseHandler";
import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import { returningActions } from "modules/returning";
import { useAppSelector } from "store";

import CompleteConfirm from "./CompleteConfirm";
import Info from "./Info";
import Styled from "./index.styles";

function ConfirmDetail() {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();

  const { getDetailReturningRes } = useAppSelector((state) => {
    return {
      getDetailReturningRes: state.returning.GET_DETAIL,
    };
  });

  const {
    params: { id },
  } = match;

  useEffect(() => {
    fetchData(Number(id));

    return clear;
  }, []);

  function fetchData(id: number) {
    dispatch(returningActions.GET_DETAIL({ id }));
  }

  function clear() {
    dispatch(returningActions.INIT_GET_DETAIL({}));
  }

  return (
    <Layout
      navigator={{
        title: "의뢰 확인 (ASN) 상세보기",
      }}
    >
      <Styled.container>
        <Info data={getDetailReturningRes?.data?.returning} />

        <div className="actions">
          <Button
            theme="secondary"
            size="block"
            label={"촬영"}
            handleClick={() =>
              history.push(`${history.location?.pathname}/picture-type`)
            }
          />

          <CompleteConfirm returningId={Number(id)} />
        </div>
      </Styled.container>

      <BofulRequestResponseHandler
        response={getDetailReturningRes}
        initRequest={() => {
          dispatch(returningActions.INIT_GET_DETAIL({}));
        }}
        loading={{
          actionType: returningActions.GET_DETAIL.type,
        }}
      />
    </Layout>
  );
}

export default withRequireAuth(ConfirmDetail);
