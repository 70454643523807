import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.section`
  > .header {
    text-align: center;
    ${setFontStyle("Body8")}
    color: ${COLOR.pointWarning};
  }

  > .list {
    margin-bottom: 40px;
  }
`;

export default {
  container,
};
