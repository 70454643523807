import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const emptyData = styled.div`
  padding: 80px 0;
  text-align: center;
  background-color: ${COLOR.grayScale_50};
  ${setFontStyle("Body8")};
  color: ${COLOR.grayScale_500};
`;

export default {
  emptyData,
};
