import { useCallback, useMemo, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { FULFILLMENT_RECEIVING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import {
  handleFormChange,
  setUndefinedToNull,
} from "@sellernote/_shared/src/utils/common/etc";
import { getTotalPackingUnit } from "@sellernote/_shared/src/utils/fulfillment/receiving";

export interface DataToChangeTotalPackingUnit {
  packingUnitPallet: number | undefined;
  packingUnitBox: number | undefined;
  packingUnitOther: number | undefined;
}

export default function useChangeTotalPackingUnit({
  dataToChangeTotalPackingUnit,
  setDataToChangeTotalPackingUnit,
}: {
  dataToChangeTotalPackingUnit: DataToChangeTotalPackingUnit | undefined;
  setDataToChangeTotalPackingUnit: React.Dispatch<
    React.SetStateAction<DataToChangeTotalPackingUnit | undefined>
  >;
}) {
  const { id } = useParams<{ id: string }>();

  const queryClient = useQueryClient();

  const { teamId } =
    useRecoilValue(FULFILLMENT_RECEIVING_ATOMS.MANAGER_RECEIVING_DETAIL)
      ?.receiving || {};

  const {
    mutate: changeTotalPackingUnit,
    ResponseHandler: ResponseHandlerOfChangingTotalPackingUnit,
  } = RECEIVING_QUERY.useChangeTotalPackingUnit({
    receivingId: Number(id),
  });

  const [
    { packingUnitBox, packingUnitPallet, packingUnitOther },
    setTotalPackingUnitForm,
  ] = useState<DataToChangeTotalPackingUnit>({
    packingUnitPallet: dataToChangeTotalPackingUnit?.packingUnitPallet,
    packingUnitBox: dataToChangeTotalPackingUnit?.packingUnitBox,
    packingUnitOther: dataToChangeTotalPackingUnit?.packingUnitOther,
  });
  const prevTotalPackingUnit = useRef({
    packingUnitPallet,
    packingUnitBox,
    packingUnitOther,
  });

  const handleTotalPackingUnitFormChange = handleFormChange(
    setTotalPackingUnitForm
  );

  const totalPackingUnit = useMemo(
    () =>
      getTotalPackingUnit({
        packingUnitPallet: packingUnitPallet,
        packingUnitBox: packingUnitBox,
        packingUnitOther: packingUnitOther,
      }),
    [packingUnitBox, packingUnitOther, packingUnitPallet]
  );

  const handleModalClose = useCallback(() => {
    setDataToChangeTotalPackingUnit(undefined);
  }, [setDataToChangeTotalPackingUnit]);

  const isValidatedForm = useMemo(
    () =>
      prevTotalPackingUnit.current.packingUnitBox !== packingUnitBox ||
      prevTotalPackingUnit.current.packingUnitPallet !== packingUnitPallet ||
      prevTotalPackingUnit.current.packingUnitOther !== packingUnitOther,
    [packingUnitBox, packingUnitOther, packingUnitPallet]
  );

  const handleTotalPackingUnitChange = useCallback(() => {
    if (!teamId) return;

    changeTotalPackingUnit(
      {
        packingUnitPallet: setUndefinedToNull(packingUnitPallet),
        packingUnitBox: setUndefinedToNull(packingUnitBox),
        packingUnitOther: setUndefinedToNull(packingUnitOther),
        teamId,
      },
      {
        onSuccess: () => {
          handleModalClose();

          queryClient.invalidateQueries(
            RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
              receivingId: Number(id),
            })
          );
        },
      }
    );
  }, [
    changeTotalPackingUnit,
    handleModalClose,
    id,
    packingUnitBox,
    packingUnitOther,
    packingUnitPallet,
    queryClient,
    teamId,
  ]);

  return {
    packingUnitPallet,
    packingUnitBox,
    packingUnitOther,
    handleTotalPackingUnitFormChange,

    totalPackingUnit,

    handleModalClose,

    isValidatedForm,
    handleTotalPackingUnitChange,

    ResponseHandlerOfChangingTotalPackingUnit,
  };
}
