import React, { useEffect, useRef, useState } from "react";
import { useMemo } from "react";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { isOverflownElement } from "@sellernote/_shared/src/utils/common/dom";

import TextButton from "../button/TextButton";
import ChipWithClear, { ChipWithClearProps } from "../ChipWithClear";
import Styled from "./index.styles";

export default function ChipsWithClear({
  chipDataList,
  className,
}: {
  chipDataList: (Pick<ChipWithClearProps, "label" | "handleClear"> & {
    id: string | number;
  })[];
  className?: string;
}) {
  const listRef = useRef<HTMLDivElement>(null);

  const canOpen = useMemo(() => {
    return isOverflownElement(listRef.current);
  }, [chipDataList]);

  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (!canOpen) {
      setIsOpened(false);
    }
  }, [canOpen]);

  return (
    <Styled.container
      isOpened={isOpened}
      className={`${className ? className : ""} chips-with-clear`}
    >
      <div className="chip-list" ref={listRef}>
        {chipDataList.map((v) => {
          return (
            <ChipWithClear
              key={v.id}
              label={v.label}
              handleClear={v.handleClear}
              colorTheme={isOpened ? "light" : "dark"}
            />
          );
        })}
      </div>

      {canOpen && (
        <div className="action">
          {isOpened ? (
            <TextButton
              handleClick={() => setIsOpened(false)}
              icon="arrowUp"
              label="닫기"
              size="14px"
              theme="withIcon"
            />
          ) : (
            <Icon
              type="arrowDown"
              color={COLOR.grayScale_400}
              size={1.875}
              onClick={() => setIsOpened(true)}
            />
          )}
        </div>
      )}
    </Styled.container>
  );
}
