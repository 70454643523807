import { useCallback, useMemo, useState } from "react";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";

import useUnverifiedItemForm from "./useUnverifiedItemForm";

export default function useUnverifiedItemModal({
  returningId,
  newOrder,
}: {
  returningId: number;
  newOrder: number;
}) {
  const [opensUnverifiedItemModal, setOpensUnverifiedItemModal] =
    useState(false);

  const { form, isChangedForm, resetForm, updateForm, UnverifiedItemForm } =
    useUnverifiedItemForm({
      returningId,
      newOrder,
      onSuccessForDeletePhoto: () => setOpensUnverifiedItemModal(false),
    });

  const handleModalOpen = () => {
    setOpensUnverifiedItemModal(true);
  };

  const handleModalClose = useCallback(() => {
    setOpensUnverifiedItemModal(false);

    resetForm();
  }, [resetForm]);

  const UnverifiedItemModal = useMemo(
    () => (
      <Modal
        uiType="contentWithCustomBody"
        active={opensUnverifiedItemModal}
        title={`불일치${form.order ?? newOrder}`}
        body={UnverifiedItemForm}
        onClose={handleModalClose}
        needConfirmBeforeCloseModal={isChangedForm}
        needConfirmBeforeCloseModalInfo={{
          title: "저장하지 않고 나가시겠습니까?",
          actionPositiveLabel: "네",
          actionNegativeLabel: "아니오",
        }}
      />
    ),
    [
      UnverifiedItemForm,
      form.order,
      handleModalClose,
      isChangedForm,
      newOrder,
      opensUnverifiedItemModal,
    ]
  );

  return {
    UnverifiedItemModal,
    handleModalOpen,
    handleModalClose,
    updateForm,
  };
}
