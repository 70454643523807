import React, { useCallback, useMemo, useState } from "react";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { INVENTORY_MANAGEMENT_KIND_MAP } from "@sellernote/_shared/src/constants/fulfillment/inventory";
import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { Overwrite } from "@sellernote/_shared/src/types/common/customUtilityTypes";
import { BofulProblem } from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import {
  checkUsesManagementDate,
  convertItemListToMapBySKU,
  getWarehousingItemStatusLabel,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { checkForUnverifiedItem } from "@sellernote/_shared/src/utils/fulfillment/inspection";
import { getStyledSKUCount } from "@sellernote/_shared/src/utils/fulfillment/sku";
import InputTextArea from "@sellernote/_sds-v1/src/components/input/InputTextArea";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import useGetWorkerByIdFactory from "hooks/common/useGetWorkerByIdFactory";
import { returningSelectors } from "modules/returning";
import { useAppSelector } from "store";
import { getManagerName } from "./utils";

import ItemStatus from "components/ItemStatus";
import SkuIdForReportingProblem from "components/SkuIdForReportingProblem";
import SubRowIndicator from "components/SubRowIndicator";
import UnverifiedSku from "components/UnverifiedSku";

import ButtonForReporting from "./ButtonForReporting";
import SKUProblem from "./SKUProblem";
import Styled from "./index.styles";

type ItemListToReportTableItem = {
  skuId?: React.ReactNode;
  counter?: React.ReactNode;
  manager?: string;
  problem?: React.ReactNode;
  managementDate?: React.ReactNode;
};

type BofulProblemDict = {
  [itemId in string]: BofulProblemDictValue;
};

type BofulProblemDictValue = {
  type: InputSelectOption<BofulProblem> | undefined;
  directInput?: string;
};

type ItemToReport = Overwrite<
  Partial<ReceivingItem>,
  { id: number; sku: { id: number } }
>;

export type { BofulProblemDict, BofulProblemDictValue };

export default function SKUList({
  closeModal,
  returningId,
}: {
  closeModal: () => void;
  returningId: number;
}) {
  const { SKUItems } = useAppSelector((state) => {
    return {
      SKUItems: returningSelectors.getSKUItems(state),
    };
  });

  const getWorkerById = useGetWorkerByIdFactory();

  /**
   * 리포트 대상인 item 리스트
   */
  const itemListToReport = useMemo(() => {
    return SKUItems.filter((v) => v.actualQty !== v.placeQty);
  }, [SKUItems]);

  const [problemDict, setProblemDict] = useState<BofulProblemDict>(
    itemListToReport.reduce((a, c) => {
      a[c.id] = { type: undefined };
      return a;
    }, {} as BofulProblemDict)
  );

  const createTextAreaRow = useCallback(
    (v: ItemToReport): TableDataListItem<ItemListToReportTableItem> => {
      return {
        rowKey: `${v.id}-${v.processStatus}-additional`,
        colSpan: {
          value: 4,
          content: (
            <InputTextArea
              value={problemDict[v.id].directInput}
              setValue={(value) => {
                const newDict = { ...problemDict };
                newDict[v.id] = {
                  ...newDict[v.id],
                  directInput: value,
                };

                setProblemDict(newDict);
              }}
              placeholder="직접입력"
              isAutoResize
              isValidated={!!problemDict[v.id].directInput}
            />
          ),
          hasFullWidth: true,
        },
      };
    },
    [problemDict]
  );

  const createRow = useCallback(
    ({
      rowType,
      v,
      hasTextArea,
      actualQty,
      placeQty,
    }: {
      rowType: "basicRow" | "mainRow" | "subRow";
      v: ItemToReport;
      hasTextArea: boolean;
      actualQty: number;
      placeQty: number | null;
    }): TableDataListItem<ItemListToReportTableItem> => {
      const isUnverifiedSku = checkForUnverifiedItem(v);
      const itemStatusLabel = getWarehousingItemStatusLabel(v.processStatus);

      const rowKey =
        rowType === "mainRow"
          ? `main-row-${v.skuId}`
          : `${v.id}-${v.processStatus}`;

      const skuId = (() => {
        if (isUnverifiedSku) {
          if (rowType === "subRow") {
            return (
              <SubRowIndicator>
                <UnverifiedSku>
                  <Styled.skuIdContainer>
                    <SkuIdForReportingProblem
                      skuId={v.sku.id}
                      skuBarcode={v.sku.barCode}
                    />

                    <ItemStatus
                      statusLabel={itemStatusLabel}
                      isUnverifiedSku={isUnverifiedSku}
                      isTable
                    />
                  </Styled.skuIdContainer>
                </UnverifiedSku>
              </SubRowIndicator>
            );
          }

          return (
            <UnverifiedSku>
              <Styled.skuIdContainer>
                <SkuIdForReportingProblem
                  skuId={v.sku.id}
                  skuBarcode={v.sku.barCode}
                />

                <ItemStatus
                  statusLabel={itemStatusLabel}
                  isUnverifiedSku={isUnverifiedSku}
                  isTable
                />
              </Styled.skuIdContainer>
            </UnverifiedSku>
          );
        }

        if (rowType === "subRow") {
          return (
            <SubRowIndicator>
              <Styled.skuIdContainer>
                <SkuIdForReportingProblem
                  skuId={v.sku.id}
                  skuBarcode={v.sku.barCode}
                />

                <ItemStatus
                  statusLabel={itemStatusLabel}
                  isUnverifiedSku={isUnverifiedSku}
                  isTable
                />
              </Styled.skuIdContainer>
            </SubRowIndicator>
          );
        }

        return (
          <Styled.skuIdContainer>
            <SkuIdForReportingProblem
              skuId={v.sku.id}
              skuBarcode={v.sku.barCode}
            />

            <ItemStatus
              statusLabel={itemStatusLabel}
              isUnverifiedSku={isUnverifiedSku}
              isTable
            />
          </Styled.skuIdContainer>
        );
      })();

      const managementDate = (() => {
        const usesManagementDate = checkUsesManagementDate({
          managementKind: v.sku.managementKind,
          managementDate: v.managementDate,
        });
        if (!usesManagementDate) return "-";

        if (rowType === "basicRow") {
          return (
            <Styled.basicRowOfManagementDateContainer>
              {v.sku.managementKind && (
                <span>
                  {INVENTORY_MANAGEMENT_KIND_MAP[v.sku.managementKind]}
                </span>
              )}
              <span>{toFormattedDate(v.managementDate, "YYYY-MM-DD")}</span>
            </Styled.basicRowOfManagementDateContainer>
          );
        }

        if (rowType === "mainRow") {
          return v.sku.managementKind
            ? INVENTORY_MANAGEMENT_KIND_MAP[v.sku.managementKind]
            : "";
        }

        if (rowType === "subRow") {
          return toFormattedDate(v.managementDate, "YYYY-MM-DD");
        }
      })();

      const counter = getStyledSKUCount({
        currentCount: placeQty ?? 0,
        goalCount: actualQty,
      });

      const manager = (() => {
        if (rowType === "mainRow") return "";

        return getManagerName({
          getWorkerById,
          placerIds: v.placeItems
            ? v.placeItems.reduce((a: number[], c) => {
                if (c.placerId) {
                  a.push(c.placerId);
                }
                return a;
              }, [])
            : [],
        });
      })();

      const problem = (() => {
        if (rowType === "mainRow") return "";

        return (
          <SKUProblem
            selectedProblem={problemDict[v.id]?.type}
            setProblem={(problem) => {
              const newDict = { ...problemDict };
              newDict[v.id] = {
                ...newDict[v.id],
                type: problem,
                directInput: undefined,
              };

              setProblemDict(newDict);
            }}
          />
        );
      })();

      return {
        rowKey,
        noBorderBottom: hasTextArea,

        skuId,
        managementDate,
        counter,
        manager,
        problem,
      };
    },
    [problemDict, getWorkerById]
  );

  const tableDataList = useMemo(() => {
    const mapBySKU = convertItemListToMapBySKU(itemListToReport);

    if (!mapBySKU) return [];

    return Object.values(mapBySKU).reduce<
      TableDataListItem<ItemListToReportTableItem>[]
    >((a, itemList) => {
      if (!itemList || !itemList?.length) return a;

      /**
       * sku를 구성하는 item이 2개 이상인 경우 subRow로 표시한다.
       **/
      const usesSubRow = itemList.length > 1;

      if (!usesSubRow) {
        return [
          ...a,
          ...itemList.reduce<TableDataListItem<ItemListToReportTableItem>[]>(
            (a, c) => {
              const hasTextArea =
                problemDict[c.id]?.type?.value === "directInput";

              return [
                ...a,
                createRow({
                  rowType: "basicRow",
                  v: c,
                  hasTextArea,
                  actualQty: c.actualQty,
                  placeQty: c.placeQty,
                }),
                ...(hasTextArea ? [createTextAreaRow(c)] : []),
              ];
            },
            []
          ),
        ];
      }

      const mainRow: TableDataListItem<ItemListToReportTableItem> = (() => {
        /**
         * mainRow는 item별 공통 정보를 만들므로 임의의 요소(첫번째요소)를 사용해서 생성할 수 있다.
         */
        const baseItem = itemList[0];

        const actualQty = itemList.reduce((a, c) => a + (c.actualQty ?? 0), 0);

        const placeQty = itemList.reduce((a, c) => a + (c.placeQty ?? 0), 0);

        return createRow({
          rowType: "mainRow",
          v: baseItem,
          hasTextArea: false,
          actualQty,
          placeQty,
        });
      })();

      mainRow.subRowInfo = (() => {
        return {
          subRowList: itemList.reduce<
            TableDataListItem<ItemListToReportTableItem>[]
          >((a, c) => {
            const hasTextArea =
              problemDict[c.id]?.type?.value === "directInput";

            return [
              ...a,
              createRow({
                rowType: "subRow",
                v: c,
                hasTextArea,
                actualQty: c.actualQty,
                placeQty: c.placeQty,
              }),
              ...(hasTextArea ? [createTextAreaRow(c)] : []),
            ];
          }, []),
        };
      })();

      return [...a, mainRow];
    }, []);
  }, [createRow, createTextAreaRow, itemListToReport, problemDict]);

  if (!itemListToReport.length) return null;

  return (
    <Modal
      active
      uiType="contentWithCustomBody"
      title={"문제가 발생한 상품(들)을 선택해주세요."}
      body={
        <Styled.modalBodyContainer>
          <Table<ItemListToReportTableItem>
            columnInfo={{
              skuId: {
                label: (
                  <>
                    SKU ID / 상품 바코드
                    <br />
                    상태
                  </>
                ),
                fixedWidth: 136,
              },
              managementDate: {
                label: "관리일자",
                fixedWidth: 100,
              },
              counter: {
                label: "카운트",
                fixedWidth: 100,
              },
              manager: {
                label: "담당자",
                fixedWidth: 150,
              },
              problem: {
                label: "발생문제 선택",
                fixedWidth: 200,
              },
            }}
            dataList={tableDataList}
          />

          <ButtonForReporting
            returningId={returningId}
            problemDict={problemDict}
          />
        </Styled.modalBodyContainer>
      }
      onClose={closeModal}
    />
  );
}
