import { useEffect, useState } from "react";

import INVENTORY_QUERY from "@sellernote/_shared/src/queries/fulfillment/INVENTORY_QUERY";
import { BofulLocation } from "@sellernote/_shared/src/types/fulfillment/common";
import { usePrevious } from "@sellernote/_shared/src/utils/common/hook";

import { SkuToSelect } from "../SelectSkuModal/useSelectSkuModal";

export default function useInventoryAdjustment() {
  const [existingLocation, setExistingLocation] = useState<BofulLocation>();
  const [isEmptyLocation, setIsEmptyLocation] = useState(false);
  const [selectedSku, setSelectedSku] = useState<SkuToSelect>(); // 완료 데이터 생성을 위해서 상위 컴포넌트에 관리
  const [quantityToAdjust, setQuantityToAdjust] = useState<number>();

  const {
    data: inventoryDetailByLocation,
    ResponseHandler: ResponseHandlerOfGettingInventoryDetailByLocation,
  } = INVENTORY_QUERY.useGetInventoryDetailByLocation({
    barCode: existingLocation?.barCode ?? "",
    type: "adjustment",
    enabled: !!existingLocation?.barCode,
    onError: (failureInfo) => {
      if (failureInfo?.code === 404) {
        setIsEmptyLocation(true);
      }
    },
  });
  const previousBarcode = usePrevious(existingLocation?.barCode);

  useEffect(() => {
    const isChangedExistingLocation =
      existingLocation && existingLocation.barCode !== previousBarcode;
    if (isChangedExistingLocation) {
      setIsEmptyLocation(false);
      setSelectedSku(undefined);
      setQuantityToAdjust(undefined);
    }
  }, [existingLocation, previousBarcode]);

  return {
    existingLocation,
    setExistingLocation,

    isEmptyLocation,

    selectedSku,
    setSelectedSku,

    quantityToAdjust,
    setQuantityToAdjust,

    inventoryDetailByLocation,

    ResponseHandlerOfGettingInventoryDetailByLocation,
  };
}
