import React from "react";

import Styled from "./index.styles";

export interface MenuListBofulItem {
  label: React.ReactNode;
  isActive?: boolean;
  handleClick?: () => void;
  className?: string;
}

// TODO: _shared로 옮기기
export default function MenuListBoful({
  menuList,
  className,
}: {
  menuList: MenuListBofulItem[];
  className?: string;
}) {
  return (
    <Styled.container
      className={`${className ? className : ""} menu-list-boful`}
    >
      {menuList.map((menu, i) => {
        return (
          <Styled.menuItem
            key={i}
            isActive={menu.isActive}
            onClick={() => menu.handleClick && menu.handleClick()}
          >
            {menu.label}
          </Styled.menuItem>
        );
      })}
    </Styled.container>
  );
}
