import styled from "styled-components";

const container = styled.section`
  margin-top: -10px;

  > form {
    min-height: 80vh;

    .input-text {
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }

  > .button {
    position: fixed;
    bottom: 0;
    left: 0;
  }
`;

export default {
  container,
};
