import { useRef, useState } from "react";
import DatePicker from "react-datepicker";

import {
  addDate,
  getAppTodayMidnight,
  getAppTomorrowMidnight,
  isHoliday,
  isSameDay,
} from "../utils/common/date";

type CalendarSize = "default" | "small";

type SelectedTime = {
  hour: number | null;
  minute: number | null;
};

type DatePickerType = {
  type: "datePicker";
};

type DateTimePickerType = {
  type: "dateTimePicker";
  selectableTime?: SelectableTime;
};

type SelectableTime = {
  startHour: number;
  endHour: number;
  disabledHourList?: number[];
};

type MinDateOption = "today" | "tomorrow" | Date;

/**
 * 단일, 범위 캘린더의 공통 로직을 모아놓은 커스텀 훅
 * TODO: 범위 캘린더는 아직 기능 미완성(커스텀 인풋, 시간선택) 추가시 로직도 수정될 수 있음
 */
function useCalendar({
  dateRangeOffset,
  getMinDate,
  getOpenToDate,
}: {
  dateRangeOffset?: number;
  getMinDate?: () => MinDateOption | undefined;
  getOpenToDate?: () => string | Date;
}) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [showsAlertModal, setShowsAlertModal] = useState(false);

  const isSunday = (date: Date) => date.getDay() === 0;

  const getDayClassName = (date: Date) => {
    if (isSunday(date)) {
      return "sunday";
    }

    if (isHoliday(date)) {
      return "holiday";
    }

    return "";
  };

  const geMinDateOption = (minDate: MinDateOption | undefined) => {
    if (minDate === "today") {
      return getAppTodayMidnight();
    }

    if (minDate === "tomorrow") {
      return getAppTomorrowMidnight();
    }

    return minDate;
  };

  const getMaxDate = (
    minDate: Date | undefined,
    dateRangeOffset: number | undefined
  ) => {
    if (!dateRangeOffset || !minDate) {
      return;
    }

    const maxDate = addDate({
      date: minDate,
      unit: "day",
      value: dateRangeOffset,
    });

    return maxDate;
  };

  const convertToUnicodeTokens = (format: string) => {
    // 참고) https://github.com/date-fns/date-fns/blob/main/docs/unicodeTokens.md
    return format.replace(/[YD]/g, (match) => match.toLowerCase());
  };

  const checkIsDisabledDate = (
    date: Date,
    disabledDateList: (string | Date)[]
  ) => !disabledDateList.some((disabledDate) => isSameDay(date, disabledDate));

  const minDateOption = isEditMode
    ? geMinDateOption(getMinDate?.())
    : undefined;

  const maxDateOption = isEditMode
    ? getMaxDate(minDateOption, dateRangeOffset)
    : undefined;

  const openToDate = isEditMode
    ? (() => {
        if (!getOpenToDate) return;

        const date = getOpenToDate();
        if (!date) return;

        if (typeof date === "string") {
          return new Date(date);
        }

        return date;
      })()
    : undefined;

  const datePickerRef = useRef<DatePicker>(null);

  const handleAlertModalOpen = () => {
    setShowsAlertModal(true);
  };

  const handleAlertModalClose = () => {
    setShowsAlertModal(false);
  };

  const handleCalendarClose = () => {
    setIsEditMode(false);
  };

  return {
    isEditMode,
    setIsEditMode,
    showsAlertModal,
    maxDateOption,
    openToDate,
    datePickerRef,
    checkIsDisabledDate,
    convertToUnicodeTokens,
    getDayClassName,
    minDateOption,
    handleAlertModalOpen,
    handleAlertModalClose,
    handleCalendarClose,
  };
}

export default useCalendar;

export type {
  CalendarSize,
  SelectedTime,
  SelectableTime,
  MinDateOption,
  DatePickerType,
  DateTimePickerType,
};
