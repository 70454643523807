import { memo } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";

function ResetCountingAsPartial({
  type,
  canReset,
  // reset,
  openConfirmModal,
}: {
  type: "inspection" | "warehousing";
  canReset: boolean;
  // reset: () => void;
  openConfirmModal: () => void;
}) {
  return (
    // <ConfirmWrapper
    //   confirmMessageTitle={
    //     <Styled.resetConfirmModalTitle>
    //       선택한 항목의 분할{type === "inspection" ? "검수" : "입고"}를{" "}
    //       <b>초기화</b>하겠습니까?
    //     </Styled.resetConfirmModalTitle>
    //   }
    //   confirmModalUiType="titleOnly"
    //   customButtonLabel={{ positive: "예", negative: "아니오" }}
    //   disabled={!canReset}
    // >
    <Button
      label="초기화"
      size="small"
      theme="tertiary"
      handleClick={openConfirmModal}
      // handleClick={reset}
      disabled={!canReset}
    />
    // </ConfirmWrapper>
  );
}

export default memo(ResetCountingAsPartial);
