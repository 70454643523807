import { useState } from "react";

import ReactWindowList from "@sellernote/_shared/src/components/ReactWindowList";
import {
  RowToHighlightRef,
  TableDataListItem,
  TableProps,
  TableSubRowAccordionStatus,
} from "@sellernote/_shared/src/headlessComponents/table/useTable";

import TableBodyRow from "./TableBodyRow";

export type InitialAccordionStatusMap = Record<
  string | number,
  TableSubRowAccordionStatus
>;

export default function RowsForIsWindowed<T>({
  dataList,
  columnInfo,
  disabledRowHoverBgColor,
  rowMinHeight,
  tableRef,
  rowInfoToHighlight,
  isBlinkOnHighlightRow,
  isOverflowed,
  rowToHighlightRef,
  height,
  scrollWidth,
  rowKeyToHighlight,
}: TableProps<T> & {
  rowToHighlightRef: RowToHighlightRef;
  tableRef: React.MutableRefObject<HTMLTableElement>;
  isBlinkOnHighlightRow: boolean;
  isOverflowed: boolean;
  height: number;
  scrollWidth: number;
  dataList: TableDataListItem<T>[] | undefined;
  rowKeyToHighlight?: string | number;
}) {
  const [accordionStatusMap, setAccordionStatusMap] =
    useState<InitialAccordionStatusMap>(() =>
      (dataList ?? []).reduce<InitialAccordionStatusMap>((acc, cur) => {
        if (cur.subRowInfo) {
          acc[cur.rowKey] = cur.subRowInfo.initialAccordionStatus;
        }

        return acc;
      }, {})
    );

  return (
    <ReactWindowList
      height={height}
      scrollWidth={scrollWidth}
      dataList={dataList}
      rowKeyToHighlight={rowKeyToHighlight}
      Row={({ data, index }) => (
        <TableBodyRow
          key={data[index].rowKey}
          rowMinHeight={rowMinHeight}
          rowInfoToHighlight={rowInfoToHighlight}
          rowToHighlightRef={rowToHighlightRef}
          isBlinkOnHighlightRow={isBlinkOnHighlightRow}
          disabledRowHoverBgColor={
            disabledRowHoverBgColor || data[index].disabledRowHoverBgColor
          }
          isOverflowed={isOverflowed}
          tableRef={tableRef}
          columnInfo={columnInfo}
          rowData={data[index]}
          accordionStatus={accordionStatusMap[data[index].rowKey]}
          setAccordionStatusMap={setAccordionStatusMap}
        />
      )}
    />
  );
}
