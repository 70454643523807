import { WarehousingStatusLabel } from "@sellernote/_shared/src/types/fulfillment/common";
import { UseCounterDataValue } from "@sellernote/_shared/src/utils/common/hook";

import { PageType, SkuInprogressValue } from "./types";

const removeDuplicateSku = <Type extends PageType>(
  items: SkuInprogressValue<Type>[]
) => {
  const seen = new Set();

  return items.reduce((acc, current) => {
    if (!seen.has(current.skuId)) {
      seen.add(current.skuId);
      acc.push(current);
    }

    return acc;
  }, [] as SkuInprogressValue<Type>[]);
};

/**
 * 정상/불량 상품이 혼재되어 있는지 여부(선택 UI 필요 여부)를 확인
 */
const checkNeedsToSelectStatusLabel = <Type extends PageType>(
  listBySelectedSkuId: UseCounterDataValue<SkuInprogressValue<Type>>[] = []
) => {
  const statusLabelList = listBySelectedSkuId.reduce<string[]>((acc, item) => {
    if ("statusLabel" in item) {
      acc.push(item.statusLabel);
      return acc;
    }

    return acc;
  }, []);

  return new Set(statusLabelList).size > 1;
};

/**
 * 불일치 상품이 아닌 상품/불일치 상품이 혼재되어 있는지 여부(선택 UI 필요 여부)를 확인
 */
const checkNeedsToSelectIsUnverifiedSku = <Type extends PageType>(
  listBySelectedSkuId: UseCounterDataValue<SkuInprogressValue<Type>>[] = []
) => {
  const isUnverifiedSkuList = listBySelectedSkuId.reduce<boolean[]>(
    (acc, item) => {
      if ("isUnverifiedSku" in item) {
        acc.push(item.isUnverifiedSku);
        return acc;
      }

      return acc;
    },
    []
  );

  return new Set(isUnverifiedSkuList).size > 1;
};

/**
 * 선택 UI가 필요한 경우(상태가 혼재된 경우)에는 기본값('정상')으로 설정하고, 아니면 선택된 아이템의 값으로 설정
 */
const setStatusLabel = <Type extends PageType>({
  needsToSelectStatusLabel,
  skuList,
  handleStatusLabelSelect,
}: {
  needsToSelectStatusLabel: boolean;
  skuList: UseCounterDataValue<SkuInprogressValue<Type>>[];
  handleStatusLabelSelect: (
    selectedStatusLabel: WarehousingStatusLabel
  ) => void;
}) => {
  if (!needsToSelectStatusLabel) {
    if (skuList[0] && "statusLabel" in skuList[0]) {
      handleStatusLabelSelect(skuList[0].statusLabel);
      return;
    }
  }

  handleStatusLabelSelect("정상");
};

/**
 * 선택 UI가 필요한 경우(불일치 여부가 혼재된 경우)에는 기본값('false')으로 설정하고, 아니면 선택된 아이템의 값으로 설정
 */
const setIsUnverifiedSku = <Type extends PageType>({
  needsToSelectIsUnverifiedSku,
  skuList,
  handleIsUnverifiedSkuSelect,
}: {
  needsToSelectIsUnverifiedSku: boolean;
  skuList: UseCounterDataValue<SkuInprogressValue<Type>>[];
  handleIsUnverifiedSkuSelect: (isUnverifiedSku: boolean) => void;
}) => {
  if (!needsToSelectIsUnverifiedSku) {
    if (skuList[0] && "isUnverifiedSku" in skuList[0]) {
      handleIsUnverifiedSkuSelect(skuList[0].isUnverifiedSku);
      return;
    }
  }

  handleIsUnverifiedSkuSelect(false);
};

export {
  removeDuplicateSku,
  checkNeedsToSelectStatusLabel,
  checkNeedsToSelectIsUnverifiedSku,
  setStatusLabel,
  setIsUnverifiedSku,
};
