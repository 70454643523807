import { memo, useMemo } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";

function RemovePartialItem({
  type,
  isNowCounting,
  // remove,
  openConfirmModal,
}: {
  type: "inspection" | "warehousing";
  isNowCounting: boolean;
  // remove: () => void;
  openConfirmModal: () => void;
}) {
  const DeleteButton = useMemo(
    () => (
      <Button
        label="삭제"
        size="small"
        theme="dangerStroke"
        handleClick={openConfirmModal}
        // handleClick={remove}
      />
    ),
    [openConfirmModal]
    // [remove]
  );

  return (
    <>
      {/* {isNowCounting ? (
        <AlertWrapper
          message={
            <>
              현재 작업 중인 항목은 삭제할 수 없습니다 <br /> (초기화 후 진행
              가능)
            </>
          }
        >
          {DeleteButton}
        </AlertWrapper>
      ) : (
        <ConfirmWrapper
          confirmMessageTitle={
            <Styled.removeConfirmModalTitle>
              선택한 항목의 분할{type === "inspection" ? "검수" : "입고"}를{" "}
              <b>삭제</b>하겠습니까?
            </Styled.removeConfirmModalTitle>
          }
          confirmModalUiType="titleOnly"
          customButtonLabel={{ positive: "예", negative: "아니오" }}
        > */}
      {DeleteButton}
      {/* </ConfirmWrapper>
      )} */}
    </>
  );
}

export default memo(RemovePartialItem);
