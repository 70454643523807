import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { getHighlightedLabel } from "@sellernote/_shared/src/utils/common/string";
import { getInspectionStatusString } from "@sellernote/_shared/src/utils/fulfillment/receiving";
import { getFormattedReturningId } from "@sellernote/_shared/src/utils/fulfillment/returning";

export type FilterForListForInspection = "mine" | "inspected";

export type TableItemForInspection = {
  combinedTitle: React.ReactNode;
  SKU: number;
  PCS: number;
  completedPCS?: React.ReactNode;
  inspectionStatus?: React.ReactNode;
  bidId: React.ReactNode;
};

export default function useFetchInspectionList({
  perPage,
  currentPage,
  filter,
}: {
  perPage: number;
  currentPage: number;
  filter: FilterForListForInspection;
}) {
  const history = useHistory();

  const currentManager = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );

  const {
    data: listForInspection,
    ResponseHandler: ResponseHandlerOfGettingListForInspection,
  } = RETURNING_QUERY.useGetManagerReturningListForInspection({
    page: currentPage,
    perPage,
    isComplete: filter === "inspected",
  });

  const handleRowClick = useCallback(
    (id: number) => {
      history.push(`${history.location.pathname}/${id}`);
    },
    [history]
  );

  const tableDataListForInspection =
    useMemo((): TableDataListItem<TableItemForInspection>[] => {
      const rowList = listForInspection?.list;

      if (!rowList) {
        return [];
      }

      return rowList.map((v, i) => {
        const currentManagerId = currentManager?.id;
        // 본인이 배정되지 않는 경우 진입할 수 없게 함
        const isCurrentManagerPersonInCharge = v.workerList?.some(
          (worker) => Number(worker.id) === currentManagerId
        );

        const mapped: TableDataListItem<TableItemForInspection> = {
          rowKey: v.id,

          handleRowClick:
            filter === "mine" && isCurrentManagerPersonInCharge
              ? () => handleRowClick(v.id)
              : undefined,

          combinedTitle: `${v.team?.company ?? "-"} (${v.team?.name ?? "-"} / ${
            v.team?.id
          })`,

          bidId: getFormattedReturningId(v),

          SKU: v.totalItem.sku,

          PCS: v.totalItem.quantity,
          ...(filter === "inspected"
            ? {
                completedPCS: getHighlightedLabel({
                  label: v.actualQty ?? 0,
                  isHighlighted: v.actualQty !== v.totalItem.quantity,
                }),
                inspectionStatus: getHighlightedLabel({
                  label: getInspectionStatusString(v.inspectionStatus),
                  isHighlighted: v.inspectionStatus !== "normal",
                }),
              }
            : {}),
        };

        return mapped;
      });
    }, [listForInspection?.list, currentManager?.id, filter, handleRowClick]);

  return {
    tableDataListForInspection,
    totalCountOfListOfInspection: listForInspection?.total ?? 0,
    anotherTotalCountOfListOfInspection: listForInspection?.anotherTotal ?? 0,
    ResponseHandlerOfGettingListForInspection,
  };
}
