import RECEIVING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import {
  checkForNormalItemAsInspection,
  checkForUnverifiedItemAsInspection,
} from "@sellernote/_shared/src/utils/fulfillment/inspection";

// 불일치 상품의 attachment 정보 -> items 의 attachment에서 domain이 "unverifiedSku"만 해당
const getUnverifiedListWithAttachments = (receivingItems?: ReceivingItem[]) => {
  if (!receivingItems) return;

  const unverifiedList = receivingItems.filter(
    checkForUnverifiedItemAsInspection
  );

  return unverifiedList.map((item) => ({
    ...item,
    attachment: item.attachment?.filter(
      ({ domain }) => domain === "unverifiedSku"
    ),
  }));
};

export default function useFetchListForUnverified({
  receivingId,
  onSuccessOfGetReceivingUnverifiedList,
}: {
  receivingId: number;
  onSuccessOfGetReceivingUnverifiedList: (data?: ReceivingItem[]) => void;
}) {
  const {
    data: receivingDetail,
    ResponseHandler: ResponseHandlerOfReceivingDetail,
  } = RECEIVING_QUERY.useGetPDAReceivingDetail({
    id: receivingId,
    onSuccess: (res) =>
      onSuccessOfGetReceivingUnverifiedList(
        getUnverifiedListWithAttachments(res.receiving.items)
      ),
    titleOfFailureModal: "불일치 상품 조회 중 오류가 발생했습니다.",
  });

  const unverifiedList = getUnverifiedListWithAttachments(
    receivingDetail?.receiving.items
  );

  // 불일치 상품을 제외한 리스트
  const normalItemList = receivingDetail?.receiving.items.filter(
    checkForNormalItemAsInspection
  );

  return {
    unverifiedList,
    ResponseHandlerOfUnverifiedList: ResponseHandlerOfReceivingDetail,
    normalItemList,
  };
}
