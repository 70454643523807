import React from "react";

import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import Paging from "@sellernote/_sds-v1/src/components/Paging";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import { CompletedPickingTableItem } from "hooks/shipping/useCompletedPickingList";

import Styled from "./index.styles";

export default function CompletedPickingListTable({
  pageSize,
  currentPage,
  setCurrentPage,
  list,
}: {
  pageSize: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  list: TableDataListItem<CompletedPickingTableItem>[];
}) {
  return (
    <Styled.container>
      <Paging
        pageSize={pageSize}
        currentPage={currentPage}
        currentPageData={
          <Table<CompletedPickingTableItem>
            className="picking-list-table"
            columnInfo={{
              invoiceNo: {
                label: "송장번호 (QR 넘버)",
                fixedWidth: 130,
              },
              locationToPicking: {
                label: "위치",
              },
              skuId: {
                label: "SKU ID",
                fixedWidth: 120,
              },
              count: {
                label: "카운트",
              },
              deliveryType: {
                label: "운송수단",
              },
              personInCharge: {
                label: "담당자",
              },
              endedAt: {
                label: "처리일시",
                fixedWidth: 100,
              },
            }}
            dataList={list}
          />
        }
        handleClickPage={setCurrentPage}
        isZeroBasedPage
      />
    </Styled.container>
  );
}
