import { atom } from "recoil";

const IMAGE_DATA = atom<undefined | string>({
  key: "bridge/atoms/IMAGE_DATA",
  default: undefined,
});

const SCAN_RESULT = atom<undefined | string>({
  key: "bridge/atoms/SCAN_RESULT",
  default: undefined,
});

/**
 * useScan을 등록한 컴포넌트에서 바코드를 스캔할 경우에만 스캔 결과를 저장하기 위해서 사용
 */
const IS_SCANNING = atom<boolean>({
  key: "bridge/atoms/IS_SCANNING",
  default: false,
});

export default {
  SCAN_RESULT,
  IMAGE_DATA,
  IS_SCANNING,
};
