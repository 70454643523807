import { VConsoleOptions } from "core/options.interface";
import vConsole from "vconsole";

export const initVConsole = () => {
  const config: VConsoleOptions = {
    onReady: () => {
      const button = document.querySelector(".vc-switch") as HTMLElement;
      const panel = document.querySelector(".vc-panel") as HTMLElement;

      button.style.zIndex = "99999";
      panel.style.zIndex = "99999";
    },
    theme: "dark",
  };

  new vConsole(config);
};
