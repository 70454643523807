import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";

import { getFormattedReceivingId } from "@sellernote/_shared/src/utils/fulfillment/receiving";

import withRequireAuth from "hocs/withRequireAuth";
import useGetReceivingDetail from "hooks/receiving/useGetReceivingDetail";

import SelectManagementDate from "./SelectManagementDate";
import Warehousing from "./Warehousing";

function ReceivingWarehousingDetail() {
  const match = useRouteMatch<{ id: string }>();
  const {
    params: { id },
  } = match;

  const {
    data: receivingDetail,
    refetch: refetchReceivingDetail,
    ResponseHandler: ResponseHandlerOfGettingManagerReceivingDetail,
  } = useGetReceivingDetail({ receivingId: Number(id) });

  /**
   * 관리일자를 사용하는 sku임에도 managementDate가 없는 itemList.
   * - 입고처리 전에 관리일자를 선택해줘야 한다.
   * - (ex 불일치 상품이 관리일자가 있는 sku로 매칭되는 경우, 해당 item이 어떤 관리일자인지 정해줘야한다)
   */
  const undecidedItemList = useMemo(() => {
    if (!receivingDetail?.receiving.items) return;

    return receivingDetail.receiving.items.filter((item) => {
      if (item.sku.managementKind && !item.managementDate) {
        return true;
      }

      return false;
    });
  }, [receivingDetail?.receiving.items]);

  const needsSelectManagementDate = (undecidedItemList || []).length > 0;

  return (
    <>
      {receivingDetail &&
        (needsSelectManagementDate ? (
          <SelectManagementDate
            receivingId={Number(id)}
            formattedReceivingId={getFormattedReceivingId(
              receivingDetail?.receiving
            )}
            itemList={undecidedItemList}
          />
        ) : (
          <Warehousing
            receivingId={Number(id)}
            receivingDetail={receivingDetail}
            refetchReceivingDetail={refetchReceivingDetail}
          />
        ))}

      {ResponseHandlerOfGettingManagerReceivingDetail}
    </>
  );
}

export default withRequireAuth(ReceivingWarehousingDetail);
