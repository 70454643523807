import React, { useCallback, useEffect } from "react";
import produce from "immer";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import CANCELING_QUERY from "@sellernote/_shared/src/queries/fulfillment/CANCELING_QUERY";
import { FULFILLMENT_CANCELING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/canceling";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { ShippingListItem } from "@sellernote/_shared/src/types/fulfillment/shipping";

import { CancelingDetailTableItem } from "pages/canceling/detail/:id";

/**
 * 상세 조회 (취소 송장 재확인)
 * 1) 테이블 데이터 셋팅
 * 2) 송장 스캔("○", "✕")
 * 3) "○"인 경우 재입고 Active
 * 4) 패킹 중 취소의 경우 페이지 이동을 막고, 담당자를 지정
 */
export default function useCheckCancelingDetailInvoice(
  cancelingInvoice: TableDataListItem<CancelingDetailTableItem>[],
  setCancelingInvoice: React.Dispatch<
    React.SetStateAction<TableDataListItem<CancelingDetailTableItem>[]>
  >,
  setPackingRestocker: React.Dispatch<React.SetStateAction<string>>
) {
  const setCanNotLeavePage = useSetRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );
  const { cancelingData, cancelingId, cancelingInvoiceNo } = useRecoilValue(
    FULFILLMENT_CANCELING_ATOMS.CANCELING_DETAIL
  );

  const statusWhenCanceled = cancelingData?.shipping.wmsStatus;

  useEffect(() => {
    if (cancelingInvoiceNo)
      setCancelingInvoice([
        { rowKey: 1, invoiceNo: cancelingInvoiceNo, status: "✕" },
      ]);
  }, [cancelingInvoiceNo, setCancelingInvoice]);

  const checkInvoice = useCallback(
    (invoiceData: ShippingListItem) => {
      setCancelingInvoice(
        produce((draft) => {
          draft[0].status =
            draft[0].invoiceNo === invoiceData.invoiceNo ? "○" : "✕";
        })
      );
    },
    [setCancelingInvoice]
  );

  const isActiveRestock = cancelingInvoice[0]?.status === "○";

  const {
    mutate: assignRestocker,
    ResponseHandler: ResponseHandlerOfAssigningRestocker,
  } = CANCELING_QUERY.useAssignRestocker({
    cancelingId,
    setPackingRestocker,
  });

  useEffect(() => {
    if (isActiveRestock && statusWhenCanceled === "packing") {
      // 피킹 화면에서 넘어온 경우에는 자동으로 화면 이동 제한이 걸려 있음
      setCanNotLeavePage(true);

      assignRestocker();
    }
  }, [
    statusWhenCanceled,
    cancelingId,
    isActiveRestock,
    setPackingRestocker,
    setCanNotLeavePage,
    assignRestocker,
  ]);

  return {
    checkInvoice,
    isActiveRestock,
    ResponseHandlerOfAssigningRestocker,
  };
}
