import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const mainMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  padding: 0 16px;

  > .title {
    ${setFontStyle("SubHead3", "Bold")};
    color: ${COLOR.grayScale_800};
  }
`;

const subMenuContainer = styled.div`
  > .sub-menu {
    ${setFontStyle("SubHead3")};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 42px;
    padding: 0 24px;
    color: ${COLOR.grayScale_800};
  }
`;

export default {
  mainMenu,
  subMenuContainer,
};
