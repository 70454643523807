import styled from "styled-components";

const selectSkuModalBody = styled.div`
  > .input-radio {
    justify-content: center;
    margin-bottom: 24px;
  }
`;

export default {
  selectSkuModalBody,
};
