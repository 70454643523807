import { useCallback, useMemo, useState } from "react";

import FILE_QUERY from "@sellernote/_shared/src/queries/fulfillment/FILE_QUERY";
import SKU_QUERY from "@sellernote/_shared/src/queries/fulfillment/SKU_QUERY";
import { FileInfo } from "@sellernote/_shared/src/types/common/file";
import { SKUDetail } from "@sellernote/_shared/src/types/fulfillment/sku";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import ImageSliderModal from "@sellernote/_sds-v1/src/components/ImageSliderModal";

export interface ImageDetails {
  companyName: string;
  teamName: string;
  itemName: string;
  /** 판매자상품코드 */
  productCode: string;
  /** 옵션관리코드 */
  managementCode: string;
  /** 상품 바코드 */
  skuBarcode: string | undefined;
}

export interface SKUInfoForImageSliderModal {
  skuId: string;
  imageDetails: ImageDetails;
  imagePaths?: string[];
}

const initialState: SKUInfoForImageSliderModal = {
  skuId: "",
  imageDetails: {
    companyName: "",
    teamName: "",
    itemName: "",
    productCode: "",
    managementCode: "",
    skuBarcode: "",
  },
};

const getImageDetailList = (imageDetails: ImageDetails) => [
  { label: "회사명", value: imageDetails.companyName },
  { label: "팀명", value: imageDetails.teamName },
  { label: "상품명", value: imageDetails.itemName },
  { label: "판매자상품코드", value: imageDetails.productCode },
  { label: "옵션관리코드", value: imageDetails.managementCode },
  { label: "상품 바코드", value: imageDetails.skuBarcode },
];

/**
 * 특정 SKU의 상품 이미지를 보여주는 모달과 관련된 로직을 관리
 */
export default function useSKUImageInfoModal() {
  const [visibleSKUInfoModal, setVisibleSKUInfoModal] = useState(false);
  const [SKUInfoForModal, setSKUInfoForModal] =
    useState<SKUInfoForImageSliderModal>(initialState);

  const [selectedSKUId, setSelectedSKUId] = useState<number>();

  const {
    mutate: getFileURLList,
    ResponseHandler: ResponseHandlerOfGettingFileURLList,
  } = FILE_QUERY.useGetFileURLList();

  // 3) 이미지 URL을 셋팅하고 SKU 정보 모달을 보여준다.
  const setSKUImageURLList = useCallback(({ data }: { data: FileInfo[] }) => {
    const imageURLList = data.map((v) => v.url);

    setSKUInfoForModal((prev) => ({ ...prev, imagePaths: imageURLList }));
    setVisibleSKUInfoModal(true);
  }, []);

  // 2) 모달에 표시할 정보를 셋팅하고, 이미지 URL을 요청한다.
  const setSKUInfo = useCallback(
    ({ sku, attachment }: SKUDetail) => {
      const skuId = getFormattedSingleSkuId(sku.id);
      const itemName = sku.itemName;
      const companyName = sku.team?.company ?? "";
      const teamName = sku.team?.name ?? "";
      const productCode = sku.productCode ?? "";
      const managementCode = sku.managementCode ?? "";
      const skuBarcode = sku.barCode ?? "";

      const attachmentKeys = attachment.map((v) => v.key);
      const hasAttachment = attachment.length > 0;

      // attachment 없는 경우에는 API 요청 시 에러가 발생 -> 바로 모달을 보여준다.
      if (hasAttachment) {
        setSKUInfoForModal({
          skuId,
          imageDetails: {
            companyName,
            teamName,
            itemName,
            productCode,
            managementCode,
            skuBarcode,
          },
        });

        getFileURLList(
          { key: attachmentKeys },
          { onSuccess: setSKUImageURLList }
        );
      } else {
        setSKUInfoForModal({
          skuId,
          imageDetails: {
            companyName,
            teamName,
            itemName,
            productCode,
            managementCode,
            skuBarcode,
          },
        });

        setVisibleSKUInfoModal(true);
      }
    },
    [getFileURLList, setSKUImageURLList]
  );

  const { ResponseHandler: ResponseHandlerOfGettingSKUInfo } =
    SKU_QUERY.useGetSingleSkuDetail({
      skuId: selectedSKUId,
      enabled: !!selectedSKUId,
      onSuccess: setSKUInfo,
    });

  // 1) SKU ID를 선택하면 SKU 정보를 요청한다.
  const fetchImageInfoBySKUId = useCallback((skuId: number) => {
    setSelectedSKUId(skuId);
  }, []);

  const handleImageSliderModalClose = useCallback(() => {
    setVisibleSKUInfoModal(false);
    setSelectedSKUId(undefined);
  }, []);

  const SKUImageInfoModal = useMemo(
    () => (
      <ImageSliderModal
        active={visibleSKUInfoModal}
        title={`SKU ID: ${SKUInfoForModal.skuId}`}
        imageDetails={getImageDetailList(SKUInfoForModal.imageDetails)}
        imagePaths={SKUInfoForModal?.imagePaths}
        onClose={handleImageSliderModalClose}
      />
    ),
    [SKUInfoForModal, handleImageSliderModalClose, visibleSKUInfoModal]
  );

  return {
    fetchImageInfoBySKUId,
    SKUImageInfoModal,
    ResponseHandlerOfGettingSKUInfo,
    ResponseHandlerOfGettingFileURLList,
  };
}
