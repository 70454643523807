import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const modalBody = styled.div`
  > .info {
    > .item {
      padding: 10px 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > .label {
        ${setFontStyle("SubHead3")};
        color: ${COLOR.grayScale_500};
      }
    }
  }
  > .action {
    margin-top: 26px;
    height: 40px;
  }
`;

const invoiceValue = styled.div`
  display: flex;
  align-items: center;
  ${setFontStyle("Body7")};
  color: ${COLOR.grayScale_800};

  &.matched {
    color: ${COLOR.primaryBlue};
  }

  &.mis-matched {
    color: ${COLOR.pointWarning};
  }

  > .icon {
    margin-left: 4px;
  }
`;

export default {
  modalBody,
  invoiceValue,
};
