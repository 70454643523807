import React from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { ScanType } from "@sellernote/_shared/src/types/fulfillment/scan";
import { PickingSKUInfoForScanning } from "@sellernote/_shared/src/types/fulfillment/shipping";

import {
  CompletedList,
  WorkingLocation,
} from "hooks/shipping/usePickingInvoice";
import useScanPickingSKU from "hooks/shipping/useScanPickingSKU";

export default function ScanSKUButton({
  selectedScanButtonType,
  handleScanButtonClick,
  workingLocation,
  setWorkingLocation,
  setRowInfoToHighlight,
  SKUInfoForScanning,
  setSKUInfoForScanning,
  completedList,
  isFirstSKUScan,
  resetPickingState,
}: {
  selectedScanButtonType: ScanType | undefined;
  handleScanButtonClick: (type: ScanType | undefined) => void;
  workingLocation: WorkingLocation | undefined;
  setWorkingLocation: React.Dispatch<
    React.SetStateAction<WorkingLocation | undefined>
  >;
  setRowInfoToHighlight: React.Dispatch<
    React.SetStateAction<TableRowInfoToHighlight | undefined>
  >;
  SKUInfoForScanning: PickingSKUInfoForScanning[];
  setSKUInfoForScanning: React.Dispatch<
    React.SetStateAction<PickingSKUInfoForScanning[]>
  >;
  completedList: React.MutableRefObject<CompletedList>;
  isFirstSKUScan: React.MutableRefObject<boolean>;
  resetPickingState: () => void;
}) {
  const {
    isActiveScanSKUButton,
    modalOpenHandler,
    ScanSKUMessageModal,

    ResponseHandlerOfCheckingCancelingInvoice,
    ResponseHandlerOfStartingPickingScanning,
    ResponseHandlerOfSettingRestockByPickingQuantity,
  } = useScanPickingSKU({
    selectedScanButtonType,
    handleScanButtonClick,
    workingLocation,
    setWorkingLocation,
    setRowInfoToHighlight,
    SKUInfoForScanning,
    setSKUInfoForScanning,
    completedList,
    isFirstSKUScan,
    resetPickingState,
  });

  return (
    <>
      <Button
        className="scan-button"
        theme={selectedScanButtonType === "sku" ? "scan" : "secondary"}
        size="small"
        label="상품스캔"
        handleClick={() =>
          // 위치를 먼저 스캔해주세요.
          !workingLocation
            ? modalOpenHandler.openLocationScanFirstMessage()
            : handleScanButtonClick("sku")
        }
        disabled={!isActiveScanSKUButton}
      />

      {ScanSKUMessageModal}

      {ResponseHandlerOfCheckingCancelingInvoice}
      {ResponseHandlerOfStartingPickingScanning}
      {ResponseHandlerOfSettingRestockByPickingQuantity}
    </>
  );
}
