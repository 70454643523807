import { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import SHIPPING_QUERY from "@sellernote/_shared/src/queries/fulfillment/SHIPPING_QUERY";
import { FULFILLMENT_SHIPPING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/shipping";
import ConfirmWrapper from "@sellernote/_sds-v1/src/components/ConfirmWrapper";

export default function useMoveAllShippingList() {
  const shippingList = useRecoilValue(FULFILLMENT_SHIPPING_ATOMS.SHIPPING_LIST);
  const hasShippingList = shippingList.length > 0;

  const {
    mutate: moveToMyTask,
    ResponseHandler: ResponseHandlerOfMovingAllShippingListToMyTask,
  } = SHIPPING_QUERY.useMoveToMyTask();

  const handleAllListMove = useCallback(() => {
    moveToMyTask({ ids: shippingList.map((v) => v.id) });
  }, [moveToMyTask, shippingList]);

  const MoveAllShippingList = useMemo(
    () => (
      <ConfirmWrapper
        confirmMessageTitle={
          <>
            미배정 의뢰들을 My의뢰로 <br /> 전체이동 하시겠습니까?
          </>
        }
        confirmModalUiType="titleOnly"
        disabled={!hasShippingList}
      >
        <Button
          label="전체이동"
          size="small"
          theme="secondary"
          handleClick={handleAllListMove}
          disabled={!hasShippingList}
        />
      </ConfirmWrapper>
    ),
    [handleAllListMove, hasShippingList]
  );

  return {
    MoveAllShippingList,
    ResponseHandlerOfMovingAllShippingListToMyTask,
  };
}
