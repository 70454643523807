import React, { useMemo } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { INVENTORY_MANAGEMENT_KIND_MAP } from "@sellernote/_shared/src/constants/fulfillment/inventory";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import {
  getAppTodayMidnight,
  isBeforeDate,
} from "@sellernote/_shared/src/utils/common/date";
import { getWarehousingItemStatusLabel } from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";

import ItemStatus from "components/ItemStatus";

import { ManagementDateForm } from "..";
import ManagementDateInput from "./ManagementDateInput";
import QuantityInput from "./QuantityInput";
import TotalCount from "./TotalCount";
import Styled from "./index.styles";

function ItemContainer({
  returningItem,
  existedManagementDateList,
  managementDateFormList,
  addNewManagementDateForm,
  removeManagementDateForm,
  updateManagementDateForm,
}: {
  returningItem: ReceivingItem;
  existedManagementDateList: string[] | undefined;
  managementDateFormList: ManagementDateForm[];
  addNewManagementDateForm: ({
    returningItemId,
  }: {
    returningItemId: number;
  }) => void;
  removeManagementDateForm: ({
    returningItemId,
    managementDateFormId,
  }: {
    returningItemId: number;
    managementDateFormId: string;
  }) => void;
  updateManagementDateForm: ({
    returningItemId,
    managementDateFormIndex,
    newManagementDateForm,
  }: {
    returningItemId: number;
    managementDateFormIndex: number;
    newManagementDateForm: ManagementDateForm;
  }) => void;
}) {
  const canRemoveManagementDateItem = managementDateFormList.length > 1;

  /** 검수수량이 2 PCS 이상일 경우, 항목 추가 가능 */
  const canAddManagementFormItem = returningItem.actualQty > 1;

  const headerTitle = (() => {
    if (!returningItem.sku.managementKind) {
      // 플로우상 managementKind가 반드시 있어야한다.
      throw new Error("Not found managementKind");
    }

    return (
      <>
        {getFormattedSingleSkuId(returningItem.skuId)} /{" "}
        {INVENTORY_MANAGEMENT_KIND_MAP[returningItem.sku.managementKind]} /{" "}
        <ItemStatus
          statusLabel={getWarehousingItemStatusLabel(
            returningItem.processStatus
          )}
          isUnverifiedSku={false} // 불일치 상품만 표시하는 화면이라 표시하지 않는다
        />
      </>
    );
  })();

  /**
   * DatePicker 오픈시 기준이 되는 날짜
   * - 캘린더 열면 처음 펼쳐지는 달력을 정하는데 사용
   */
  const baseDate = useMemo(() => {
    // 기존 managementDate중 가장 빠른 날짜를 캘린더 오픈 기준으로 삼는다.
    const earliestDate = existedManagementDateList?.reduce(
      (prevEarliestDate: string | undefined, c) => {
        if (!prevEarliestDate) return c;

        if (
          isBeforeDate({
            baseDate: c,
            targetDate: prevEarliestDate,
            unit: "day",
          })
        ) {
          return c;
        }

        return prevEarliestDate;
      },
      undefined
    );

    // 기존 날짜가 없으면 오늘을 반환
    return earliestDate ? earliestDate : getAppTodayMidnight().toISOString();
  }, [existedManagementDateList]);

  /**
   * 이미 선택된 ManagementDate List
   * - ManagementDate는 중복으로 선택되선 안 된다.
   */
  const selectedManagementDateList = useMemo(() => {
    return managementDateFormList.reduce((a: string[], c) => {
      if (c.managementDate) {
        return [...a, c.managementDate];
      }

      return a;
    }, []);
  }, [managementDateFormList]);

  return (
    <Styled.container>
      <div className="header">
        <div className="left">{headerTitle}</div>

        <div className="right">
          <TotalCount
            goalCount={returningItem.actualQty}
            managementDateFormList={managementDateFormList}
          />
        </div>
      </div>

      <div className="list">
        {managementDateFormList.map((v, i) => {
          const handelFormUpdate = (
            newManagementDateForm: ManagementDateForm
          ) =>
            updateManagementDateForm({
              returningItemId: returningItem.id,
              managementDateFormIndex: i,
              newManagementDateForm,
            });

          return (
            <Styled.managementDateForm key={v.id}>
              <div className="left">
                <ManagementDateInput
                  managementKind={returningItem.sku.managementKind}
                  formState={v}
                  baseDate={baseDate}
                  handelFormUpdate={handelFormUpdate}
                  selectedManagementDateList={selectedManagementDateList}
                />
                <QuantityInput
                  formState={v}
                  handelFormUpdate={handelFormUpdate}
                />
              </div>

              <div className="right">
                <Icon
                  type="clear"
                  size={1.5}
                  color={
                    canRemoveManagementDateItem
                      ? COLOR.grayScale_700
                      : COLOR.grayScale_300
                  }
                  onClick={() => {
                    if (!canRemoveManagementDateItem) return;

                    removeManagementDateForm({
                      returningItemId: returningItem.id,
                      managementDateFormId: v.id,
                    });
                  }}
                />
              </div>
            </Styled.managementDateForm>
          );
        })}
      </div>

      {canAddManagementFormItem && (
        <Button
          theme="secondary"
          size="small"
          width="100%"
          label="항목 추가"
          handleClick={() =>
            addNewManagementDateForm({ returningItemId: returningItem.id })
          }
        />
      )}
    </Styled.container>
  );
}

/**
 * 다른 itemId의 form변경에 영향받아 리렌더링되지 않게하기 위해 Memoization 처리
 */
export default React.memo(ItemContainer);
