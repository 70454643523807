import { useParams, useRouteMatch } from "react-router-dom";

import { ListWithMoreResultProps } from "@sellernote/_shared/src/headlessComponents/useListWithMoreResult";
import SHIPPING_QUERY from "@sellernote/_shared/src/queries/fulfillment/SHIPPING_QUERY";
import {
  ParcelCompanyType,
  ShipmentParcelOrderType,
  ShipmentParcelSummary,
} from "@sellernote/_shared/src/types/fulfillment/shipping";
import { useShipmentSummary } from "@sellernote/_shared/src/utils/fulfillment/shipping";
import ListWithMoreResult from "@sellernote/_sds-v1/src/components/ListWithMoreResult";

const getReadyShipment = ({
  url,
  order,
  data,
}: {
  url: string;
  order: ShipmentParcelOrderType;
  data: ShipmentParcelSummary | undefined;
}): ListWithMoreResultProps => ({
  title: {
    label: "출하 준비완료",
    value:
      order === "firstOrder"
        ? data?.firstReadyShipment
        : data?.secondReadyShipment,
    path: `${url}/ready/${order}`,
  },
});

const getDoneShipment = ({
  url,
  order,
  data,
}: {
  url: string;
  order: ShipmentParcelOrderType;
  data: ShipmentParcelSummary | undefined;
}): ListWithMoreResultProps => ({
  title: {
    label: "출하 마감",
    value:
      order === "firstOrder"
        ? `${data?.firstDoneShipment}/${data?.firstReadyShipment}`
        : `${data?.secondDoneShipment}/${data?.secondReadyShipment}`,
    path: `${url}/done/${order}`,
  },
});

export default function useShipmentClosingParcel() {
  const { url } = useRouteMatch();
  const { company } = useParams<{ company: ParcelCompanyType }>();

  const {
    data: shipmentParcelSummary,
    ResponseHandler: ResponseHandlerOfGettingShipmentParcelSummary,
  } = SHIPPING_QUERY.useGetShipmentSummary({
    type: "parcel",
    params: { parcelCompany: company },
  });

  const isAllCompany = company === "all";

  const {
    TodayExpectedShipmentSummary,
    CompletedShipmentSummary,
    UnprocessedShipmentSummary,
  } = useShipmentSummary<"parcel">(shipmentParcelSummary, ListWithMoreResult);

  const firstReadyShipment = getReadyShipment({
    url,
    order: "firstOrder",
    data: shipmentParcelSummary,
  });

  const firstDoneShipment = getDoneShipment({
    url,
    order: "firstOrder",
    data: shipmentParcelSummary,
  });

  const secondReadyShipment = getReadyShipment({
    url,
    order: "secondOrder",
    data: shipmentParcelSummary,
  });

  const secondDoneShipment = getDoneShipment({
    url,
    order: "secondOrder",
    data: shipmentParcelSummary,
  });

  const isOpenedSecondOrder = shipmentParcelSummary?.firstDoneShipment !== 0;

  return {
    isAllCompany,

    TodayExpectedShipmentSummary,
    CompletedShipmentSummary,
    UnprocessedShipmentSummary,

    firstReadyShipment,
    firstDoneShipment,

    secondReadyShipment,
    secondDoneShipment,

    isOpenedSecondOrder,

    ResponseHandlerOfGettingShipmentParcelSummary,
  };
}
