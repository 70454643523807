import ReportReturningProblem from "containers/ReportReturningProblem";
import { returningSelectors } from "modules/returning";
import { useAppSelector } from "store";

import CloseAsNormal from "./CloseAsNormal";
import Styled from "./index.styles";

function CloseWarehousing({ returningId }: { returningId: number }) {
  const {
    currentUserIsReturningManager,
    isCompletedWarehousingWithMatchedCount,
  } = useAppSelector((state) => {
    return {
      currentUserIsReturningManager:
        returningSelectors.getCurrentUserIsReturningManager(state),
      isCompletedWarehousingWithMatchedCount:
        returningSelectors.checkIsCompletedWarehousingWithMatchedCount(state),
    };
  });

  const isDisabled = !currentUserIsReturningManager;

  return (
    <Styled.closeWarehousing>
      {isCompletedWarehousingWithMatchedCount ? (
        <CloseAsNormal returningId={returningId} isDisabled={isDisabled} />
      ) : (
        <ReportReturningProblem
          returningId={returningId}
          isDisabled={isDisabled}
        />
      )}

      {isDisabled && (
        <div className="close-warehousing-warning-message">
          메인담당자만 입고 마감이 가능합니다.
        </div>
      )}
    </Styled.closeWarehousing>
  );
}

export default CloseWarehousing;
