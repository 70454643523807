import { memo } from "react";

import { INVENTORY_MANAGEMENT_KIND_MAP } from "@sellernote/_shared/src/constants/fulfillment/inventory";
import { ManagementKind } from "@sellernote/_shared/src/types/fulfillment/inventory";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import TextButton from "@sellernote/_sds-v1/src/components/button/TextButton";

import Styled from "./index.styles";

function LocationToPicking({
  locationBarcode,
  setSkuInfoToChangePickingLocation,
  managementKind,
  managementDate,
}: {
  locationBarcode: string;
  setSkuInfoToChangePickingLocation: () => void;
  managementKind: ManagementKind | undefined;
  managementDate: string | undefined;
}) {
  return (
    <Styled.locationToPicking>
      <div className="location-barcode">
        <div>{locationBarcode}</div>
        <TextButton
          label="변경"
          size="14px"
          theme="withIcon"
          icon="arrowRight"
          handleClick={setSkuInfoToChangePickingLocation}
        />
      </div>

      {managementKind ? (
        <div>
          {INVENTORY_MANAGEMENT_KIND_MAP[managementKind]}{" "}
          {toFormattedDate(managementDate, "YYYY-MM-DD")}
        </div>
      ) : (
        <div>-</div>
      )}
    </Styled.locationToPicking>
  );
}

export default memo(LocationToPicking);
