import { useCallback } from "react";
import { useQueryClient } from "react-query";

import { SET_ITEM_WAREHOUSING_DONE_BY_FORCE_REQ_PATH_PARAMS } from "@sellernote/_shared/src/api-interfaces/boful-api/receiving";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";

export default function useCompleteWarehousingByForce() {
  const queryClient = useQueryClient();

  const {
    mutate: setItemWarehousingDoneByForce,
    ResponseHandler: ResponseHandlerOfCompletingWarehousingByForce,
  } = RECEIVING_QUERY.useSetItemWarehousingDoneByForce();

  const completeWarehousingByForce = useCallback(
    ({
        receivingId,
        itemId,
        resetAfterCompleteWarehousingByForce,
      }: SET_ITEM_WAREHOUSING_DONE_BY_FORCE_REQ_PATH_PARAMS & {
        resetAfterCompleteWarehousingByForce: () => void;
      }) =>
      () => {
        setItemWarehousingDoneByForce(
          {
            pathParams: { receivingId, itemId },
          },
          {
            onSuccess: () => {
              resetAfterCompleteWarehousingByForce();

              queryClient.invalidateQueries(
                RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({
                  receivingId,
                })
              );
            },
          }
        );
      },
    [queryClient, setItemWarehousingDoneByForce]
  );

  return {
    completeWarehousingByForce,
    ResponseHandlerOfCompletingWarehousingByForce,
  };
}
