import INVENTORY_QUERY from "@sellernote/_shared/src/queries/fulfillment/INVENTORY_QUERY";

import useSearch from "./useSearch";

export default function useSearchSkuSection() {
  const {
    isSearching: isSearchingForSku,

    inputBarcode: inputBarcodeForSku,
    searchLocation: searchLocationForSku,
    resetSearch: resetSearchForSku,
    isErrorMessageActive: isErrorMessageActiveForSku,
    showErrorMessage,
    resetErrorMessage,

    scannedBarcode,
    isScanButtonActive: isScanButtonActiveForSku,
    toggleScanButton: toggleScanButtonForSku,
    openScanErrorToast,
    ScanErrorToast: ScanErrorToastForSku,
  } = useSearch({ type: "sku" });

  const {
    data: inventoryDetailBySku,
    ResponseHandler: ResponseHandlerOfInventoryDetailBySku,
  } = INVENTORY_QUERY.useGetInventoryDetailBySku({
    barcode: scannedBarcode || inputBarcodeForSku,
    enabled: !!scannedBarcode || !!inputBarcodeForSku,
    onSuccess: () => {
      if (inputBarcodeForSku) {
        resetErrorMessage();
      }
    },
    onError: (err, hideFailureModal) => {
      if (err?.code === 404) {
        hideFailureModal();

        if (scannedBarcode) {
          openScanErrorToast();
        }

        if (inputBarcodeForSku) {
          showErrorMessage();
        }
      }
    },
  });

  return {
    inputBarcodeForSku,
    searchLocationForSku,
    resetSearchForSku,
    isSearchingForSku,
    isErrorMessageActiveForSku,

    isScanButtonActiveForSku,
    toggleScanButtonForSku,

    inventoryDetailBySku,
    ResponseHandlerOfInventoryDetailBySku,
    ScanErrorToastForSku,
  };
}
