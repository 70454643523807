import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.section``;

const parcelHeaderContainer = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid ${COLOR.grayScale_200};
  margin-bottom: 24px;
`;

const date = styled.div`
  margin: 16px 0;
  ${setFontStyle("SubHead2", "Bold")};
  color: ${COLOR.grayScale_800};
`;

export default {
  container,
  parcelHeaderContainer,
  date,
};
