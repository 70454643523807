import { ReactNode, useCallback, useMemo, useState } from "react";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import {
  CancelingModalInfo,
  getCancelingModalInfo,
} from "@sellernote/_shared/src/utils/fulfillment/canceling";

/**
 * 취소관리 메시지 모달 관리
 */
export default function useCancelingMessageModal() {
  const [visibleModal, setVisibleModal] = useState(false);
  const [modalInfo, setModalInfo] = useState<CancelingModalInfo>();

  const MessageModal = useMemo(
    () => (
      <Modal
        active={visibleModal}
        uiType={modalInfo?.uiType || "titleOnly"}
        title={modalInfo?.title}
        body={modalInfo?.body}
        className={modalInfo?.className}
        actionPositive={
          modalInfo?.actionPositive || {
            label: "확인",
            handleClick: () => setVisibleModal(false),
          }
        }
        actionNegative={modalInfo?.actionNegative}
      />
    ),
    [visibleModal, modalInfo]
  );

  const openMessageModal = useCallback((type: string, data?: unknown) => {
    setVisibleModal(true);
    setModalInfo(getCancelingModalInfo(type, data));
  }, []);

  const openLocationScanFirstMessage = useCallback(() => {
    openMessageModal("locationScanFirst");
  }, [openMessageModal]);

  const openNoCancelingInvoiceNoMessage = useCallback(
    (invoiceNo: string) => {
      openMessageModal("noCancelingInvoiceNo", invoiceNo);
    },
    [openMessageModal]
  );

  const openEmptyRestockingList = useCallback(
    (invoiceNo: string) => {
      openMessageModal("emptyRestockingList", invoiceNo);
    },
    [openMessageModal]
  );

  const openNoMyCustomerMessage = useCallback(
    (invoiceNo: string) => {
      openMessageModal("noMyCustomer", invoiceNo);
    },
    [openMessageModal]
  );

  const openNoRestockingLocationMessage = useCallback(
    (locationName: string) => {
      openMessageModal("noRestockingLocation", locationName);
    },
    [openMessageModal]
  );

  const openCompletedLocationMessage = useCallback(
    (locationName: string) => {
      openMessageModal("completedLocation", locationName);
    },
    [openMessageModal]
  );

  const openCorrectSKUIdScanMessage = useCallback(() => {
    openMessageModal("correctSKUIdScan");
  }, [openMessageModal]);

  const openNoSKUInRestockingListMessage = useCallback(
    (barcode: string) => {
      openMessageModal("noSKUInRestockingList", barcode);
    },
    [openMessageModal]
  );

  const openNoSKUInWorkingLocationMessage = useCallback(
    (locationName: string) => {
      openMessageModal("noSKUInWorkingLocation", locationName);
    },
    [openMessageModal]
  );

  const openOverQuantityScanMessage = useCallback(
    (scannedData: ReactNode) => {
      openMessageModal("overQuantityScan", scannedData);
    },
    [openMessageModal]
  );

  const openCompletedRestockingBySKUIdMessage = useCallback(
    (scannedSKUId: string) => {
      openMessageModal("completedRestockingBySKUId", scannedSKUId);
    },
    [openMessageModal]
  );

  const openNoSKUInRestockListMessage = useCallback(
    (data: { scannedLocationName: string; skuId: number }) => {
      openMessageModal("noSKUInRestockList", data);
    },
    [openMessageModal]
  );

  return {
    MessageModal,
    modalOpenHandler: {
      openLocationScanFirstMessage,
      openNoCancelingInvoiceNoMessage,
      openEmptyRestockingList,
      openNoMyCustomerMessage,
      openNoRestockingLocationMessage,
      openCompletedLocationMessage,
      openCorrectSKUIdScanMessage,
      openNoSKUInRestockingListMessage,
      openNoSKUInWorkingLocationMessage,
      openOverQuantityScanMessage,
      openCompletedRestockingBySKUIdMessage,
      openNoSKUInRestockListMessage,
    },
    // 커스텀할 일이 있을 경우에 사용
    openMessageModal,
    setVisibleModal,
  };
}
