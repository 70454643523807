import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { WarehousingStatusLabel } from "@sellernote/_shared/src/types/fulfillment/common";
import { ManagementKind } from "@sellernote/_shared/src/types/fulfillment/inventory";
import { ReceivingItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import {
  useCounter,
  UseCounterData,
  UseCounterReturn,
} from "@sellernote/_shared/src/utils/common/hook";
import {
  checkIsGroupedItem,
  getLocationBarcodeById,
  getWarehousingItemStatusLabel,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { checkForUnverifiedItem } from "@sellernote/_shared/src/utils/fulfillment/inspection";
import { getHasMultiLocationWarehousing } from "@sellernote/_shared/src/utils/fulfillment/receiving";

/**
 * 아래의 경우 동일한 SKU ID가 존재할 수 있으며, 하나로 묶어서 표시한다. (groupedItem)
 * - 정상/불량으로 나뉜 경우
 * - 불일치상품이 화주웹 입고 동의 과정에서 기존 입고요청상품과 매칭된 경우
 */

type CounterType = "single" | "multi";

/**
 * groupedItem(입고 작업화면) -> itemId로 구분 (담당자 초기화로 리셋할 경우 placingId가 초기화되기 때문)
 * 분할입고(분할입고 작업화면)) -> placingId로 구분
 */
export interface WarehousingCounterSKU {
  counterType: CounterType;
  counterKey: string;
  itemId: number;
  skuId: number;
  /** 상품 바코드 */
  skuBarcode: string | undefined;
  itemName: string;
  locationBarcode: string;
  placerId?: number;
  placingId: string;
  isCompletePlacing: boolean;
  /**
   * 각 기능 동작 전 확인 모달에 '정상/불량' 상태를 표시하기 위한 상태값
   * groupedItem의 부모 행은 statusLabel이 존재하지 않음
   */
  statusLabel: WarehousingStatusLabel;
  /**
   * 각 기능 동작 전 확인 모달에 '불일치 상품' 여부를 표시하기 위한 상태값
   */
  isUnverifiedSku: boolean;
  managementKind?: ManagementKind;
  managementDate?: string;
}

type CounterForWarehousing = UseCounterReturn<WarehousingCounterSKU>;
export type CounterDataForWarehousing = UseCounterData<WarehousingCounterSKU>;

export interface SKUCountingForWarehousing {
  skuInProgress: WarehousingCounterSKU | undefined;
  setSkuInProgress: (val?: WarehousingCounterSKU) => void;
  totalCount: string;
  counter: CounterForWarehousing;
  reset: (items: ReceivingItem[]) => void;
}

/**
 * 입고 카운팅용(분할입고용) 키를 생성.
 * (분할입고를 가능하기 위해 skuId와 placingId를 조합)
 */
export function getCounterKeyForMultiLocationWarehousing(
  skuId: number,
  placingId: string
) {
  return `S${skuId}-${placingId}`;
}

/**
 * 입고 카운팅용(일반입고용) 키를 생성(skuId).
 */
export function getCounterKeyForSingleLocationWarehousing(skuId: number) {
  return `S${skuId}`;
}

/**
 * 입고 카운팅용(동일한 SKU ID가 존재하는 상품 - groupedItem) 키를 생성.
 */
export function getCounterKeyForGroupedItem(
  skuId: number,
  itemId: number | undefined
) {
  return `${getFormattedSingleSkuId(skuId)}-${itemId}`;
}

export function checkScannedSkuInCounterData({
  counterData,
  scanResult,
}: {
  counterData: SKUCountingForWarehousing;
  scanResult: string;
}) {
  return Object.values(counterData.counter.counterInfo).some(
    (counter) =>
      getFormattedSingleSkuId(counter.skuId) === scanResult ||
      counter.skuBarcode === scanResult
  );
}

export function getCounterKeyFromScanResultByPlacingIdInProgress({
  counterData,
  scanResult,
  placingIdInProgress,
}: {
  counterData: SKUCountingForWarehousing;
  scanResult: string;
  placingIdInProgress: string | undefined;
}) {
  return Object.values(counterData.counter.counterInfo).find(
    (counter) =>
      (getFormattedSingleSkuId(counter.skuId) === scanResult ||
        counter.skuBarcode === scanResult) &&
      counter.placingId === placingIdInProgress
  )?.counterKey;
}

export function getCounterKeyFromScanResultByItemIdInProgress({
  counterData,
  scanResult,
  itemIdInProgress,
}: {
  counterData: SKUCountingForWarehousing;
  scanResult: string;
  itemIdInProgress: number;
}) {
  return Object.values(counterData.counter.counterInfo).find(
    (counter) =>
      (getFormattedSingleSkuId(counter.skuId) === scanResult ||
        counter.skuBarcode === scanResult) &&
      counter.itemId === itemIdInProgress
  )?.counterKey;
}

export function getCounterKeyFromScanResult({
  counterData,
  scanResult,
}: {
  counterData: SKUCountingForWarehousing;
  scanResult: string;
}) {
  return Object.values(counterData.counter.counterInfo).find(
    (counter) =>
      getFormattedSingleSkuId(counter.skuId) === scanResult ||
      counter.skuBarcode === scanResult
  )?.counterKey;
}

export function getCounterKeyByStatus({
  hasMultiLocationWarehousing = false,
  isGroupedItem = false,
  skuId,
  itemId = 0,
  placingId = "",
}: {
  hasMultiLocationWarehousing?: boolean;
  isGroupedItem?: boolean;
  skuId: number;
  itemId?: number;
  placingId?: string;
}) {
  // 순서 유의 (multi -> grouped -> single)
  if (hasMultiLocationWarehousing) {
    return getCounterKeyForMultiLocationWarehousing(skuId, placingId);
  }

  if (isGroupedItem) {
    return getCounterKeyForGroupedItem(skuId, itemId);
  }

  return getCounterKeyForSingleLocationWarehousing(skuId);
}

export function getIncompleteSingleLocationSkuListBySkuBarcode({
  counterData,
  scanResult,
}: {
  counterData: SKUCountingForWarehousing;
  scanResult: string;
}) {
  return Object.values(counterData.counter.counterInfo).filter(
    (counter) =>
      counter.skuBarcode === scanResult &&
      !counter.isCompletePlacing &&
      counter.counterType === "single"
  );
}

export function getIncompleteSingleLocationCounterKeyFromScannedSkuBarcode({
  counterData,
  scanResult,
}: {
  counterData: SKUCountingForWarehousing;
  scanResult: string;
}) {
  return Object.values(counterData.counter.counterInfo).find(
    (counter) =>
      counter.skuBarcode === scanResult &&
      !counter.isCompletePlacing &&
      counter.counterType === "single"
  )?.counterKey;
}

export function getSingleLocationCounterKeyFromScanResult({
  counterData,
  scanResult,
  itemIdInprogress,
}: {
  counterData: SKUCountingForWarehousing;
  scanResult: string;
  itemIdInprogress?: number;
}) {
  return Object.values(counterData.counter.counterInfo).find((counter) => {
    const isSingleLocationItem = counter.counterType === "single";
    const isScannedItem =
      getFormattedSingleSkuId(counter.skuId) === scanResult ||
      counter.skuBarcode === scanResult;
    const isInprogressItem = itemIdInprogress === counter.itemId;

    // 라디오 버튼을 선택한 상태에는 선택된 상품만 스캔할 수 있다.
    return itemIdInprogress
      ? isSingleLocationItem && isScannedItem && isInprogressItem
      : isSingleLocationItem && isScannedItem;
  })?.counterKey;
}

export default function useSKUCountingForWarehousing(
  items: ReceivingItem[] | undefined
): SKUCountingForWarehousing {
  const setCanNotLeavePage = useSetRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );

  const locationListOfWarehouse = useRecoilValue(
    FULFILLMENT_COMMON_ATOMS.LOCATION_LIST_OF_WAREHOUSE
  );

  const counter = useCounter<WarehousingCounterSKU>();

  const [skuInProgress, setSkuInProgress] = useState<WarehousingCounterSKU>();

  const initCounter = useCallback(
    ({
      counter,
      receivingItemList,
      needReset,
    }: {
      counter: CounterForWarehousing;
      receivingItemList: ReceivingItem[] | undefined;
      needReset?: boolean; // 이전 local에서 카운팅했던 값을 무시할때 사용
    }) => {
      if (!receivingItemList) return;

      const newInfo: CounterDataForWarehousing = {};

      receivingItemList.forEach((v) => {
        const isGroupedItem = checkIsGroupedItem(receivingItemList, v.sku.id);
        const hasMultiLocationWarehousing = getHasMultiLocationWarehousing(
          v.actualQty,
          v.placeItems
        );

        v.placeItems.forEach((pi) => {
          if (!v.skuId || !pi.placingId) return;

          const counterKey = getCounterKeyByStatus({
            hasMultiLocationWarehousing,
            isGroupedItem,
            skuId: v.sku.id,
            itemId: v.id,
            placingId: pi.placingId,
          });

          const previousCountInfo =
            !needReset && counter.counterInfo
              ? counter.counterInfo[counterKey]
              : null;

          newInfo[counterKey] = {
            counterKey,
            itemId: v.id,
            skuId: v.skuId,
            skuBarcode: v.sku.barCode,
            itemName: v.sku.itemName,
            locationBarcode: getLocationBarcodeById({
              locationList: locationListOfWarehouse,
              locationId: pi.locationId,
            }),
            placerId: pi.placerId,
            placingId: pi.placingId,
            isCompletePlacing: pi.isCompletePlacing,
            counterType: hasMultiLocationWarehousing ? "multi" : "single",
            max: pi.quantity,
            current: previousCountInfo?.current || pi.placeQty,

            statusLabel: getWarehousingItemStatusLabel(v.processStatus),
            isUnverifiedSku: checkForUnverifiedItem(v),
            managementDate: v.managementDate,
            managementKind: v.sku?.managementKind,
          };
        });
      });

      counter.initCounterInfo(newInfo);
    },
    [locationListOfWarehouse]
  );

  useEffect(() => {
    initCounter({ counter, receivingItemList: items });
    // counter를 넣을 수 없음
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, initCounter]);

  useEffect(() => {
    if (skuInProgress) {
      setCanNotLeavePage(true);
    } else {
      setCanNotLeavePage(false);
    }
  }, [skuInProgress, setCanNotLeavePage]);

  useEffect(() => {
    if (skuInProgress) {
      const target = counter.counterInfo[skuInProgress.counterKey];
      setSkuInProgress(target);
    } else {
      setSkuInProgress(undefined);
    }
    // counter를 넣을 수 없음
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skuInProgress]);

  const getTotalCount = useCallback(
    (counter: CounterDataForWarehousing, list: ReceivingItem[] | undefined) => {
      if (!list) return "";

      let sum = 0;
      let total = 0;

      Object.values(counter).forEach((v) => {
        sum += v.current || 0;
      });

      list.forEach((v) => {
        total += v.actualQty || 0;
      });

      return `${sum} / ${total}`;
    },
    []
  );

  const totalCount = useMemo(
    () => getTotalCount(counter.counterInfo, items),
    [getTotalCount, counter.counterInfo, items]
  );

  const reset = useCallback(
    (items: ReceivingItem[]) => {
      setSkuInProgress(undefined);

      initCounter({
        counter,
        receivingItemList: items,
        needReset: true,
      });
    },
    [counter, initCounter]
  );

  return {
    skuInProgress,
    setSkuInProgress,
    counter,
    totalCount,
    reset,
  };
}
