import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import TabLine from "@sellernote/_shared/src/componentsToMoveToV1/TabLine";
import { FULFILLMENT_INVENTORY_ATOMS } from "@sellernote/_shared/src/states/fulfillment/inventory";
import Toast from "@sellernote/_sds-v2/src/components/Toast";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

import useResetStateWithTempKey from "../useResetStateWithTempKey";
import SearchLocationSection from "./SearchLocationSection";
import SearchSkuSection from "./SearchSkuSection";
import useSearchLocationSection from "./useSearchLocationSection";
import useSearchSkuSection from "./useSearchSkuSection";
import Styled from "./index.styles";

function InventoryInformationWithKey() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const history = useHistory();

  const isSkuTabActive = selectedTabIndex === 0;
  const isLocationTabActive = selectedTabIndex === 1;

  const handleTabSelect = (index: number) => {
    setSelectedTabIndex(index);
    history.replace(`?tab=${index === 0 ? "sku" : "location"}`);
  };

  const {
    isSearchingForLocation,

    inputBarcodeForLocation,
    searchLocationForLocation,
    resetSearchForLocation,
    isErrorMessageActiveForLocation,

    isScanButtonActiveForLocation,
    toggleScanButtonForLocation,

    inventoryDetailByLocation,
    ResponseHandlerOfInventoryDetailByLocation,
    ScanErrorToastForLocation,
  } = useSearchLocationSection();

  const {
    isSearchingForSku,

    inputBarcodeForSku,
    searchLocationForSku,
    resetSearchForSku,
    isErrorMessageActiveForSku,

    isScanButtonActiveForSku,
    toggleScanButtonForSku,

    inventoryDetailBySku,
    ResponseHandlerOfInventoryDetailBySku,
    ScanErrorToastForSku,
  } = useSearchSkuSection();

  return (
    <>
      <Styled.container>
        <TabLine
          tabList={[{ title: "상품 조회" }, { title: "로케이션 조회" }]}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={handleTabSelect}
        />

        {isLocationTabActive && (
          <SearchLocationSection
            isSearching={isSearchingForLocation}
            inputBarcode={inputBarcodeForLocation}
            searchLocation={searchLocationForLocation}
            resetSearch={resetSearchForLocation}
            isErrorMessageActive={isErrorMessageActiveForLocation}
            isScanButtonActive={isScanButtonActiveForLocation}
            toggleScanButton={toggleScanButtonForLocation}
            inventoryDetailByLocation={inventoryDetailByLocation}
          />
        )}

        {isSkuTabActive && (
          <SearchSkuSection
            isSearching={isSearchingForSku}
            inputBarcode={inputBarcodeForSku}
            searchLocation={searchLocationForSku}
            resetSearch={resetSearchForSku}
            isErrorMessageActive={isErrorMessageActiveForSku}
            isScanButtonActive={isScanButtonActiveForSku}
            toggleScanButton={toggleScanButtonForSku}
            inventoryDetailBySku={inventoryDetailBySku}
          />
        )}
      </Styled.container>

      {ResponseHandlerOfInventoryDetailByLocation}
      {ResponseHandlerOfInventoryDetailBySku}

      {ScanErrorToastForLocation}
      {ScanErrorToastForSku}
    </>
  );
}

function InventoryInformation() {
  const tempKey = useResetStateWithTempKey();

  const setTempKey = useSetRecoilState(FULFILLMENT_INVENTORY_ATOMS.TEMP_KEY);

  const handleRefresh = () => {
    setTempKey(tempKey + 1);
  };

  const [showsRefreshToast, setShowsRefreshToast] = useState(false);

  const openRefreshToast = () => {
    setShowsRefreshToast(true);
  };

  const closeRefreshToast = () => {
    setShowsRefreshToast(false);
  };

  useEffect(() => {
    if (tempKey > 0) {
      openRefreshToast();
    }
  }, [tempKey]);

  return (
    <Layout
      navigator={{
        title: "상품/로케이션 조회",
      }}
      refreshInfo={{ handleRefresh }}
    >
      <InventoryInformationWithKey key={tempKey} />

      {showsRefreshToast && (
        <Toast
          uiType="messageWithIcon"
          status="positive"
          message="새로고침 완료"
          reset={closeRefreshToast}
        />
      )}
    </Layout>
  );
}

export default withRequireAuth(InventoryInformation);
