import { useState } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { getI18nInstanceForDesignSystem } from "@sellernote/_shared/src/i18n/i18nForDesignSystem";
import { usePrevious } from "@sellernote/_shared/src/utils/common/hook";

import InputText from "../InputText";
import Styled from "./index.styles";

export default function InputTextWithSearch({
  handleSearch,
  searchTerm,
  resetSearch,
  placeholder,
  className,
}: {
  handleSearch: (val: string) => void;
  searchTerm: string | undefined;
  resetSearch: () => void;
  placeholder?: string;
  className?: string;
}) {
  const { t } = getI18nInstanceForDesignSystem();

  const [localSearchTerm, setLocalSearchTerm] = useState<string>(
    searchTerm || ""
  );

  const previousSearchTerm = usePrevious(searchTerm);
  const previousLocalSearchTerm = usePrevious(localSearchTerm);

  const isLocalSearchTermChanged = localSearchTerm !== previousLocalSearchTerm;

  const isSearchTermChanged = searchTerm !== previousSearchTerm;

  // searchTerm이 초기화 되는 경우 localSearchTerm에 반영
  if (
    isSearchTermChanged &&
    !isLocalSearchTermChanged &&
    localSearchTerm !== searchTerm
  ) {
    setLocalSearchTerm(searchTerm || "");
  }

  const isDisabled = !localSearchTerm;

  return (
    <Styled.container
      className={`${className ? className : ""} input-text-with-search`}
    >
      <InputText
        borderType="outline"
        valueType="string"
        placeholder={placeholder || t("common:placeholder.search")}
        icon="search"
        value={localSearchTerm}
        setValue={(v) => setLocalSearchTerm(v)}
        clearCB={resetSearch}
        handleEnter={() => {
          if (isDisabled) {
            return;
          }

          handleSearch(localSearchTerm);
        }}
      />

      <Button
        theme="primary"
        size="input"
        label={t("common:search")}
        handleClick={() => handleSearch(localSearchTerm)}
        disabled={isDisabled}
      />
    </Styled.container>
  );
}
