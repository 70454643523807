import { useCallback, useMemo } from "react";
import { useSetRecoilState } from "recoil";

import INVENTORY_QUERY from "@sellernote/_shared/src/queries/fulfillment/INVENTORY_QUERY";
import { FULFILLMENT_INVENTORY_ATOMS } from "@sellernote/_shared/src/states/fulfillment/inventory";

import { InventoryToConsolidate } from "../useInventoryConsolidation";

export default function useCompleteConsolidation({
  locationIdToConsolidate,
  skuId,
  listOfInventoryToConsolidate,
}: {
  locationIdToConsolidate: number | undefined;
  skuId: number;
  listOfInventoryToConsolidate: InventoryToConsolidate[];
}) {
  const setTempKey = useSetRecoilState(FULFILLMENT_INVENTORY_ATOMS.TEMP_KEY);

  const {
    mutate: completeInventoryConsolidation,
    ResponseHandler: ResponseHandlerOfCompletingInventoryConsolidation,
  } = INVENTORY_QUERY.useCompleteInventoryConsolidation();

  const isValidToConsolidate = useMemo(
    () =>
      listOfInventoryToConsolidate.filter((item) => item.consolidationQty > 0)
        .length > 1 && !!locationIdToConsolidate,
    [listOfInventoryToConsolidate, locationIdToConsolidate]
  );

  const handleConsolidationComplete = useCallback(() => {
    if (!locationIdToConsolidate) {
      return;
    }

    completeInventoryConsolidation(
      {
        skuId,
        inventoryFrom: listOfInventoryToConsolidate.map((item) => ({
          locationId: item.locationId ?? 0,
          quantity: item.consolidationQty,
        })),
        inventoryTo: locationIdToConsolidate,
      },

      {
        onSuccess: () => {
          setTempKey((prev) => prev + 1);
        },
      }
    );
  }, [
    completeInventoryConsolidation,
    listOfInventoryToConsolidate,
    locationIdToConsolidate,
    setTempKey,
    skuId,
  ]);

  return {
    isValidToConsolidate,
    handleConsolidationComplete,

    ResponseHandlerOfCompletingInventoryConsolidation,
  };
}
