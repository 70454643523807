import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

const container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-y: scroll;

  > .chip {
    white-space: nowrap;
    color: ${COLOR.primaryBlue_80};
    border: 1px solid ${COLOR.primaryBlue_80};
    background-color: transparent;

    &:hover {
      background-color: ${COLOR.primaryBlue_40};
      color: ${COLOR.grayScale_100};
    }

    &.selected {
      color: ${COLOR.white};
      background-color: ${COLOR.primaryBlue};
      border: 1px solid transparent;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

export default {
  container,
};
