import { useHistory, useRouteMatch } from "react-router-dom";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { omitWithEllipsis } from "@sellernote/_shared/src/utils/common/string";
import { getTeamLabelForBofulWorker } from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedReceivingId } from "@sellernote/_shared/src/utils/fulfillment/receiving";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useGetReceivingDetail from "hooks/receiving/useGetReceivingDetail";

import CompleteConfirm from "./CompleteConfirm";
import Info from "./Info";
import Styled from "./index.styles";

function ConfirmDetail() {
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();

  const {
    params: { id },
  } = match;

  const {
    data: receivingDetail,
    ResponseHandler: ResponseHandlerOfGettingManagerReceivingDetail,
  } = useGetReceivingDetail({
    receivingId: Number(id),
  });

  return (
    <Layout
      navigator={{
        title: `${getFormattedReceivingId(
          receivingDetail?.receiving
        )} / ${getTeamLabelForBofulWorker({
          id: receivingDetail?.receiving.team?.id,
          name: omitWithEllipsis({
            src: receivingDetail?.receiving.team?.name,
            maxLength: 10,
            ellipsis: "...",
          }),
          company: omitWithEllipsis({
            src: receivingDetail?.receiving.team?.company,
            maxLength: 10,
            ellipsis: "...",
          }),
        })}`,
      }}
    >
      <Styled.container>
        <Info data={receivingDetail?.receiving} />

        <div className="actions">
          <Button
            theme="secondary"
            size="block"
            label={"촬영"}
            handleClick={() =>
              history.push(`${history.location?.pathname}/picture-type`)
            }
          />

          <CompleteConfirm receivingId={Number(id)} />
        </div>
      </Styled.container>

      {ResponseHandlerOfGettingManagerReceivingDetail}
    </Layout>
  );
}

export default withRequireAuth(ConfirmDetail);
