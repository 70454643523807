import SvgIconWrapper from "./SvgIconWrapper";

export default function ExpandArrowLessFilledIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_917_7650)">
          <path
            d="M9.66022 20.6609L18.5969 11.6609C19.0969 11.218 19.6734 11 20.25 11C20.8266 11 21.4017 11.2197 21.8412 11.6592L30.7779 20.6592C31.4217 21.303 31.6146 22.27 31.2661 23.1117C30.9175 23.9533 30.157 24.5 29.1867 24.5H11.2507C10.3408 24.5 9.51959 23.9529 9.17084 23.1113C8.82209 22.2698 9.01639 21.3008 9.66022 20.6609Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_917_7650">
            <rect
              width="22.5"
              height="36"
              fill="white"
              transform="translate(9 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}
