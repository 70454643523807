import React, { useEffect, useState } from "react";
import { useRef } from "react";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";

import Styled from "./index.styles";

export type ConfirmModalTitleOnlyProps = {
  confirmModalUiType: "titleOnly" | "webNegativeTitleOnly";
};

export type ConfirmModalWithBodyProps = {
  confirmModalUiType: "content";
  confirmMessageBody: React.ReactNode;
};

export type ConfirmWrapperCommonProps = {
  confirmMessageTitle: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
  usePortal?: boolean;
  customButtonLabel?: {
    positive?: React.ReactNode;
    negative?: React.ReactNode;
  };
};

/**
 * TODO: _sds-v2의 ConfirmWrapper 유사코드 많음. 추후 개선 필요
 */
export default function ConfirmWrapper({
  confirmMessageTitle,
  children,
  disabled,
  customButtonLabel,
  usePortal,
  className,
  ...propsByType
}: ConfirmWrapperCommonProps &
  (ConfirmModalTitleOnlyProps | ConfirmModalWithBodyProps)) {
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);
  const targetRef = useRef<HTMLElement | null>(null);
  const isConfirmed = useRef<boolean>(false);

  useEffect(() => {
    return clear;
  }, []);

  function handleConfirm() {
    isConfirmed.current = true;

    if (targetRef.current) targetRef.current.click();

    clear();
  }

  function handleCancel() {
    clear();
  }

  function clear() {
    isConfirmed.current = false;
    setVisibleConfirmModal(false);
    targetRef.current = null;
  }

  const buttonLabelForPositive = customButtonLabel?.positive || "네";
  const buttonLabelForNegative = customButtonLabel?.negative || "아니오";

  return (
    <>
      <Styled.container
        className={`${className ? className : ""} confirm-wrapper`}
        onClickCapture={(e) => {
          if (disabled) return;

          targetRef.current = e.currentTarget?.childNodes
            ? (e.currentTarget?.childNodes[0] as HTMLElement)
            : null;

          if (!isConfirmed.current) {
            e.stopPropagation();
            setVisibleConfirmModal(true);
          }
        }}
      >
        {children}
      </Styled.container>

      {(propsByType.confirmModalUiType === "titleOnly" ||
        propsByType.confirmModalUiType === "webNegativeTitleOnly") && (
        <Modal
          usePortal={usePortal}
          className={`confirm-modal`}
          active={visibleConfirmModal}
          uiType={propsByType.confirmModalUiType}
          title={confirmMessageTitle}
          actionPositive={{
            label: buttonLabelForPositive,
            handleClick: handleConfirm,
          }}
          actionNegative={{
            label: buttonLabelForNegative,
            handleClick: handleCancel,
          }}
        />
      )}

      {propsByType.confirmModalUiType === "content" && (
        <Modal
          usePortal={usePortal}
          className={`confirm-modal`}
          active={visibleConfirmModal}
          uiType={propsByType.confirmModalUiType}
          title={confirmMessageTitle}
          body={propsByType.confirmMessageBody}
          actionPositive={{
            label: buttonLabelForPositive,
            handleClick: handleConfirm,
          }}
          actionNegative={{
            label: buttonLabelForNegative,
            handleClick: handleCancel,
          }}
        />
      )}
    </>
  );
}
