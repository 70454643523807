import { useCallback, useMemo, useState } from "react";

import FILE_QUERY from "@sellernote/_shared/src/queries/fulfillment/FILE_QUERY";
import {
  FileDomainType,
  FulfillmentAttachment,
} from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import {
  getPictureListByDomainType,
  getPictureTypeLabelByDomainType,
} from "@sellernote/_shared/src/utils/fulfillment/receiving";
import ImageSliderModal from "@sellernote/_sds-v1/src/components/ImageSliderModal";

import useGetReceivingDetail from "./useGetReceivingDetail";

export default function useImageSliderModal({
  receivingId,
  domainType,
}: {
  receivingId: number;
  domainType: FileDomainType;
}) {
  const [isVisibleImageSliderModal, setIsVisibleImageSliderModal] =
    useState(false);
  const [imageURLList, setImageURLList] = useState<string[]>([]);
  const [imageKeyList, setImageKeyList] = useState<string[]>([]);

  const {
    mutate: getFileURLList,
    ResponseHandler: ResponseHandlerOfGettingFileURLList,
  } = FILE_QUERY.useGetFileURLList();

  const imageListTitle = useMemo(
    () => getPictureTypeLabelByDomainType(domainType),
    [domainType]
  );

  const handleImageSliderModalClose = useCallback(() => {
    setIsVisibleImageSliderModal(false);
    setImageURLList([]);
  }, []);

  const handleImageSliderModalOpen = useCallback(() => {
    setIsVisibleImageSliderModal(true);
  }, []);

  const getImageURLList = useCallback(
    (res: { attachment: FulfillmentAttachment[] }) => {
      const pictureList = getPictureListByDomainType(
        res.attachment,
        domainType
      );

      if (pictureList.length === 0) return;

      getFileURLList(
        {
          key: pictureList.map((v) => v.key),
        },
        {
          onSuccess: ({ data }) => {
            setImageKeyList(data.map((v) => v.key));
            setImageURLList(data.map((v) => v.url));
            handleImageSliderModalOpen();
          },
        }
      );
    },
    [domainType, getFileURLList, handleImageSliderModalOpen]
  );

  const {
    refetch: fetchManagerReivingDetail,
    ResponseHandler: ResponseHandlerOfGettingManagerReceivingDetail,
  } = useGetReceivingDetail({
    receivingId,
    enabled: false,
  });

  const getImageSliderModalData = useCallback(async () => {
    const { data } = await fetchManagerReivingDetail({ throwOnError: true });

    if (!data) {
      return;
    }

    getImageURLList(data.data);
  }, [fetchManagerReivingDetail, getImageURLList]);

  const { mutate: deleteFile, ResponseHandler: ResponseHandlerOfDeleteFile } =
    FILE_QUERY.useDeleteFile({
      onSuccess: () => {
        handleImageSliderModalClose();
        getImageSliderModalData();
      },
    });

  const PictureImageSliderModal = useMemo(
    () => (
      <ImageSliderModal
        active={isVisibleImageSliderModal}
        title={"상세 이미지"}
        imageKeyList={imageKeyList}
        onDelete={(key) => deleteFile({ key })}
        onAllImageListDelete={(keyList) => {
          keyList.forEach((key) => deleteFile({ key }));
        }}
        imageTitle={imageListTitle}
        imagePaths={imageURLList}
        onClose={handleImageSliderModalClose}
      />
    ),
    [
      deleteFile,
      handleImageSliderModalClose,
      imageKeyList,
      imageListTitle,
      imageURLList,
      isVisibleImageSliderModal,
    ]
  );

  const ResultHandlerOfImageSliderModal = useMemo(
    () => (
      <>
        {ResponseHandlerOfGettingManagerReceivingDetail}

        {ResponseHandlerOfGettingFileURLList}

        {ResponseHandlerOfDeleteFile}
      </>
    ),
    [
      ResponseHandlerOfGettingFileURLList,
      ResponseHandlerOfGettingManagerReceivingDetail,
      ResponseHandlerOfDeleteFile,
    ]
  );

  return {
    showImageSliderModal: getImageSliderModalData,

    PictureImageSliderModal,
    ResultHandlerOfImageSliderModal,
  };
}
