import { memo } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";

function ResetCount({
  skuId,
  itemId,
  currentQtyByItemId,
  // handleCountReset,
  openConfirmModal,
}: {
  skuId: number;
  itemId: number;
  currentQtyByItemId: number | undefined;
  // handleCountReset: ({
  //   itemIdToReset,
  // }: {
  //   itemIdToReset: number;
  // }) => () => void;
  openConfirmModal: () => void;
}) {
  return (
    // <ConfirmWrapper
    //   confirmMessageTitle={`S${skuId}(SKU ID)`}
    //   confirmMessageBody="카운트를 초기화하겠습니까?"
    //   confirmModalUiType="content"
    //   disabled={!currentQtyByItemId}
    // >
    <Button
      theme="tertiary"
      label="초기화"
      size="small"
      handleClick={openConfirmModal}
      // handleClick={handleCountReset({ itemIdToReset: itemId })}
      disabled={!currentQtyByItemId}
    />
    // </ConfirmWrapper>
  );
}

export default memo(ResetCount);
