import { useMemo } from "react";

import { GET_DETAIL_RES_DATA_RETURNING } from "@sellernote/_shared/src/api-interfaces/boful-api/returning";
import {
  isTodayOrBeforeToday,
  toFormattedDate,
} from "@sellernote/_shared/src/utils/common/date";
import {
  getHighlightedLabel,
  omitWithEllipsis,
} from "@sellernote/_shared/src/utils/common/string";
import {
  getSKUItemTypeQuantity,
  getTeamLabelForBofulWorker,
  mergeItemsBySkuId,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import {
  getReceivingDeliveryTitle,
  getReceivingItemTitle,
  getTotalPCS,
  getTransportMethodTitle,
} from "@sellernote/_shared/src/utils/fulfillment/receiving";
import {
  getFormattedReturningId,
  getReturningInvoiceNoForPDA,
} from "@sellernote/_shared/src/utils/fulfillment/returning";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";
import TitleWithLine from "@sellernote/_sds-v1/src/components/TitleWithLine";

import Styled from "./index.styles";

function getInfoDetail(data?: GET_DETAIL_RES_DATA_RETURNING) {
  const result = [];
  let key = 0;

  result.push(
    <ListItem
      key={key++}
      type="withContentAlignRight"
      label={"입고방식"}
      value={getReceivingDeliveryTitle(data?.delivery)}
    />
  );

  result.push(
    <ListItem
      key={key++}
      type="withContentAlignRight"
      label={"상품명"}
      value={getReceivingItemTitle({ items: mergeItemsBySkuId(data?.items) })}
    />
  );

  result.push(
    <ListItem
      key={key++}
      type="withContentAlignRight"
      label={"상품종류(SKU)"}
      value={getSKUItemTypeQuantity(mergeItemsBySkuId(data?.items))}
    />
  );

  result.push(
    <ListItem
      key={key++}
      type="withContentAlignRight"
      label={"SKU ID"}
      value={mergeItemsBySkuId(data?.items).map((item) => (
        <div key={item.skuId}>{getFormattedSingleSkuId(item.skuId)}</div>
      ))}
    />
  );

  result.push(
    <ListItem
      key={key++}
      type="withContentAlignRight"
      label={"입고수량(PCS)"}
      value={getTotalPCS(mergeItemsBySkuId(data?.items))}
    />
  );

  result.push(
    <ListItem
      key={key++}
      type="withContentAlignRight"
      label={"화물디테일"}
      value={data?.bid?.invoiceCbm}
    />
  );

  result.push(
    <ListItem
      key={key++}
      type="withContentAlignRight"
      label={"포장개수"}
      value={data?.bid?.package}
    />
  );

  result.push(
    <ListItem
      key={key++}
      type="withContentAlignRight"
      label={"운송방식"}
      value={getTransportMethodTitle({
        freightType: data?.bid?.freightType,
        delivery: data?.delivery,
        invoiceNo: getReturningInvoiceNoForPDA(data?.returningPackings),
      })}
    />
  );

  result.push(
    <ListItem
      key={key++}
      type="withContentAlignRight"
      label={"센터도착일"}
      value={toFormattedDate(data?.expectedDate, "YYYY-MM-DD")}
    />
  );

  result.push(
    <ListItem
      key={key++}
      type="withContentAlignRight"
      label={"입고기한"}
      value={getHighlightedLabel({
        label: toFormattedDate(data?.dueDate, "YYYY-MM-DD"),
        isHighlighted: isTodayOrBeforeToday(data?.dueDate),
      })}
    />
  );

  return result;
}

export default function ConfirmDetailInfo({
  data,
}: {
  data?: GET_DETAIL_RES_DATA_RETURNING;
}) {
  const InfoDetail = useMemo(() => getInfoDetail(data), [data]);

  if (!data) return null;

  return (
    <Styled.info>
      <div className="kind">[반품]</div>

      <TitleWithLine
        title={`${getFormattedReturningId(data)} / ${getTeamLabelForBofulWorker(
          {
            id: data.team?.id,
            name: omitWithEllipsis({
              src: data.team?.name,
              maxLength: 10,
              ellipsis: "...",
            }),
            company: omitWithEllipsis({
              src: data.team?.company,
              maxLength: 10,
              ellipsis: "...",
            }),
          }
        )}`}
      />

      <div className="detail">{InfoDetail}</div>
    </Styled.info>
  );
}
