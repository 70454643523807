import { useCallback, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { INVENTORY_MANAGEMENT_KIND_MAP } from "@sellernote/_shared/src/constants/fulfillment/inventory";
import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import INVENTORY_QUERY from "@sellernote/_shared/src/queries/fulfillment/INVENTORY_QUERY";
import SHIPPING_QUERY from "@sellernote/_shared/src/queries/fulfillment/SHIPPING_QUERY";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { ManagementKind } from "@sellernote/_shared/src/types/fulfillment/inventory";
import { PickingSKUInfoForScanning } from "@sellernote/_shared/src/types/fulfillment/shipping";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { getPageSize } from "@sellernote/_shared/src/utils/common/etc";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { getLocationBarcodeById } from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { sortPickingListByLocation } from "@sellernote/_shared/src/utils/fulfillment/shipping";
import Paging from "@sellernote/_sds-v1/src/components/Paging";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import Styled from "./index.styles";

export interface SkuInfoToChangePickingLocation {
  shippingId: number;
  teamId: number;
  companyName: string;
  teamName: string;
  itemId: number;
  skuId: number;
  locationId: number;
  productName: string;
  quantity: number;
  managementKind: ManagementKind | undefined;
  managementDate: string | undefined;
}

interface SelectedData {
  inventoryId: number;
  locationId: number;
  managementDate: string | undefined;
}

interface ChangePickingLocationTableItem {
  selection: React.ReactNode;
  warehouseName: string;
  locationBarcode: string;
  availableQty: string;
  managementDate?: string;
}

const PAGE_UNIT = 5;

function SkuInfo({
  skuId,
  companyName,
  teamName,
  productName,
  managementKind,
  managementDate,
}: {
  skuId: string;
  companyName: string;
  teamName: string;
  productName: string;
  managementKind: ManagementKind | undefined;
  managementDate: string | undefined;
}) {
  return (
    <ul className="sku-info">
      <li>SKU ID : {skuId}</li>
      <li>회사명 : {companyName}</li>
      <li>팀명 : {teamName}</li>
      <li>상품명 : {productName}</li>
      {managementKind && (
        <li>
          {INVENTORY_MANAGEMENT_KIND_MAP[managementKind]} :{" "}
          {toFormattedDate(managementDate, "YYYY-MM-DD")}
        </li>
      )}
    </ul>
  );
}

export default function useChangePickingLocation({
  setSKUInfoForScanning,
}: {
  setSKUInfoForScanning: React.Dispatch<
    React.SetStateAction<PickingSKUInfoForScanning[]>
  >;
}) {
  const [skuInfoToChangePickingLocation, setSkuInfoToChangePickingLocation] =
    useState<SkuInfoToChangePickingLocation>();
  const [selectedData, setSelectedData] = useState<SelectedData>();
  const [currentPage, setCurrentPage] = useState(0);

  const locationListOfWarehouse = useRecoilValue(
    FULFILLMENT_COMMON_ATOMS.LOCATION_LIST_OF_WAREHOUSE
  );

  const {
    data: inventoryListBySkuId,
    ResponseHandler: ResponseHandlerOfGettingInventoryListBySkuId,
  } = INVENTORY_QUERY.useGetInventoryListBySkuId({
    skuId: skuInfoToChangePickingLocation?.skuId ?? 0,
    params: {
      perPage: PAGE_UNIT,
      page: currentPage,
      teamId: skuInfoToChangePickingLocation?.teamId ?? 0,
    },
    enabled: !!skuInfoToChangePickingLocation,
  });

  const {
    mutate: changePickingLocation,
    ResponseHandler: ResponseHandlerOfChangingPickingLocation,
  } = SHIPPING_QUERY.useChangePickingLocation();

  const isManagementKindSpecified =
    !!skuInfoToChangePickingLocation?.managementKind;

  const handleChangePickingLocationClose = useCallback(() => {
    setSkuInfoToChangePickingLocation(undefined);
    setSelectedData(undefined);
  }, []);

  const handlePickingLocationChange = useCallback(() => {
    changePickingLocation(
      {
        shippingId: skuInfoToChangePickingLocation?.shippingId ?? 0,
        afterInvenId: selectedData?.inventoryId ?? 0,
        skuId: skuInfoToChangePickingLocation?.skuId ?? 0,
        beforeLocationId: skuInfoToChangePickingLocation?.locationId ?? 0,
      },
      {
        onSuccess: () => {
          setSKUInfoForScanning((prevSKUInfoForScanning) => {
            return sortPickingListByLocation(
              prevSKUInfoForScanning
                .map((skuInfo) =>
                  skuInfo.id === skuInfoToChangePickingLocation?.itemId
                    ? {
                        ...skuInfo,
                        locationId: selectedData?.locationId ?? 0,
                        managementDate: selectedData?.managementDate,
                      }
                    : skuInfo
                )
                // skuId가 같은 상품이 여러 위치에 존재하다가, 위치 변경으로 인해 같은 skuId, locationId를 가지게 될 경우 하나로 합쳐서 보여준다.
                .reduce<PickingSKUInfoForScanning[]>((prevItems, item) => {
                  const indexOfDuplicateSKUId = prevItems.findIndex(
                    (prevItem) =>
                      prevItem.skuId === item.skuId &&
                      prevItem.locationId === item.locationId
                  );

                  if (indexOfDuplicateSKUId === -1) {
                    return [...prevItems, item];
                  } else {
                    const prevItem = prevItems[indexOfDuplicateSKUId];
                    const newItem = {
                      ...prevItem,
                      quantity: prevItem.quantity + item.quantity,
                    };

                    return [
                      ...prevItems.slice(0, indexOfDuplicateSKUId),
                      newItem,
                      ...prevItems.slice(indexOfDuplicateSKUId + 1),
                    ];
                  }
                }, [])
            );
          });

          handleChangePickingLocationClose();
        },
      }
    );
  }, [
    changePickingLocation,
    handleChangePickingLocationClose,
    selectedData?.inventoryId,
    selectedData?.locationId,
    selectedData?.managementDate,
    setSKUInfoForScanning,
    skuInfoToChangePickingLocation?.itemId,
    skuInfoToChangePickingLocation?.locationId,
    skuInfoToChangePickingLocation?.shippingId,
    skuInfoToChangePickingLocation?.skuId,
  ]);

  const tableDataList = useMemo(
    (): TableDataListItem<ChangePickingLocationTableItem>[] =>
      (inventoryListBySkuId?.list ?? [])
        .filter((item) => {
          const isExistingLocation =
            item.locationId === skuInfoToChangePickingLocation?.locationId;
          const hasEnoughQuantity =
            item.availableQty >=
            (skuInfoToChangePickingLocation?.quantity ?? 0);

          return !isExistingLocation && hasEnoughQuantity;
        })
        .map((item) => {
          const isSelected = item.id === selectedData?.inventoryId;
          const handleRowClick = () =>
            setSelectedData({
              inventoryId: item.id,
              locationId: item.locationId,
              managementDate: item.managementDate,
            });

          return {
            rowKey: item.id,
            handleRowClick,

            selection: isSelected ? (
              <Icon type="radioSelected" size={1} color={COLOR.primaryBlue} />
            ) : (
              <Icon
                type="radio"
                size={1}
                color={COLOR.grayScale_300}
                onClick={handleRowClick}
              />
            ),

            warehouseName: "쉽다1센터", // TODO: 창고 추가 전까지 픽스

            locationBarcode: getLocationBarcodeById({
              locationList: locationListOfWarehouse,
              locationId: item.locationId,
            }),

            ...(isManagementKindSpecified && {
              managementDate: toFormattedDate(
                item.managementDate,
                "YYYY-MM-DD"
              ),
            }),

            availableQty: `${toThousandUnitFormat(item.availableQty)}ea`,
          };
        }),
    [
      inventoryListBySkuId?.list,
      isManagementKindSpecified,
      locationListOfWarehouse,
      selectedData?.inventoryId,
      skuInfoToChangePickingLocation?.locationId,
      skuInfoToChangePickingLocation?.quantity,
    ]
  );

  const ChangePickingLocationModal = useMemo(
    () => (
      <Modal
        uiType="contentWithCustomBody"
        active={!!skuInfoToChangePickingLocation}
        title="피킹 위치를 선택하세요."
        body={
          <Styled.modalBody>
            <SkuInfo
              skuId={getFormattedSingleSkuId(
                skuInfoToChangePickingLocation?.skuId
              )}
              companyName={skuInfoToChangePickingLocation?.companyName ?? ""}
              teamName={skuInfoToChangePickingLocation?.teamName ?? ""}
              productName={skuInfoToChangePickingLocation?.productName ?? ""}
              managementKind={skuInfoToChangePickingLocation?.managementKind}
              managementDate={skuInfoToChangePickingLocation?.managementDate}
            />
            <Paging
              pageSize={getPageSize(PAGE_UNIT, inventoryListBySkuId?.total)}
              currentPage={currentPage}
              currentPageData={
                <Table<ChangePickingLocationTableItem>
                  columnInfo={{
                    selection: {
                      label: "",
                      portion: 16,
                    },
                    warehouseName: {
                      label: "센터명",
                      portion: 88,
                    },
                    locationBarcode: {
                      label: "위치",
                      portion: isManagementKindSpecified ? 70 : 96,
                    },
                    ...(skuInfoToChangePickingLocation?.managementKind && {
                      managementDate: {
                        label:
                          INVENTORY_MANAGEMENT_KIND_MAP[
                            skuInfoToChangePickingLocation.managementKind
                          ],
                        portion: 90,
                      },
                    }),
                    availableQty: {
                      label: "보관수량",
                      portion: isManagementKindSpecified ? 80 : 140,
                    },
                  }}
                  dataList={tableDataList}
                />
              }
              handleClickPage={setCurrentPage}
              isZeroBasedPage
            />

            <Button
              theme="primary"
              label="확인"
              size="normal"
              disabled={!selectedData}
              handleClick={handlePickingLocationChange}
            />
          </Styled.modalBody>
        }
        onClose={handleChangePickingLocationClose}
      />
    ),
    [
      currentPage,
      handleChangePickingLocationClose,
      handlePickingLocationChange,
      inventoryListBySkuId?.total,
      isManagementKindSpecified,
      selectedData,
      skuInfoToChangePickingLocation,
      tableDataList,
    ]
  );

  return {
    setSkuInfoToChangePickingLocation,

    ChangePickingLocationModal,

    ResponseHandlerOfGettingInventoryListBySkuId,
    ResponseHandlerOfChangingPickingLocation,
  };
}
