import Styled from "./index.styles";

export type VerticalType = {
  type: "vertical";
  height: string;
};

export type HorizontalType = {
  type: "horizontal";
  width: string;
};

export type DivideProps = {
  lineStyle: "line"; // dash 타입은 이후에 사용 시 추가.
  thickness: number;
  color?: string;
  className?: string;
} & (VerticalType | HorizontalType);

export default function Divide({
  lineStyle,
  thickness,
  color,
  className,
  ...propsByType
}: DivideProps) {
  return (
    <Styled.container
      lineStyle={lineStyle}
      type={propsByType.type}
      thickness={thickness}
      color={color}
      className={`${className ? className : ""} divide`}
    >
      {propsByType.type === "horizontal" && (
        <Styled.divideHorizontalBlock
          width={propsByType.width}
          thickness={thickness}
        />
      )}

      {propsByType.type === "vertical" && (
        <Styled.divideVerticalBlock
          height={propsByType.height}
          thickness={thickness}
        />
      )}
    </Styled.container>
  );
}
