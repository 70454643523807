import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const scanStatus = styled.div`
  padding: 8px;
  margin: 0 -16px 24px;
  background-color: ${COLOR.secondaryDeepBlue_10};
  ${setFontStyle("SubHead3", "Bold")};
  color: ${COLOR.grayScale_700};
  text-align: center;
`;

export default {
  scanStatus,
};
