import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.section`
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  > .table {
    margin-top: 32px;
  }

  > .total-count {
    margin-top: 4px;
    padding: 8px;
    border-radius: 4px;
    background-color: ${COLOR.bgColor_3};
    text-align: center;

    > .label {
      ${setFontStyle("SubHead2")};
      margin-right: 8px;
    }

    > .value {
      ${setFontStyle("Body6", "Bold")};
    }
  }

  > .footer {
    margin-top: 4px;

    > .button {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
`;

const unverifiedItemNotification = styled.div<{
  hasUnverifiedItem: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin: 8px 0;
  background-color: ${({ hasUnverifiedItem }) =>
    hasUnverifiedItem ? COLOR.pointWarning_20 : COLOR.grayScale_100};
  ${setFontStyle("SubHead3", "Bold")};
  color: ${COLOR.pointWarning};

  > .guide {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const personInChargeList = styled.div`
  > .chips-with-clear {
    margin: 24px -15px 0;
    width: 100vw;
  }
`;

const completeInspectionConfirmModalBody = styled.div`
  > .counting {
    > .strong {
      color: ${COLOR.pointWarning};
    }
  }
`;

export default {
  container,
  unverifiedItemNotification,
  personInChargeList,
  completeInspectionConfirmModalBody,
};
