import { CurrentManager } from "@sellernote/_shared/src/types/fulfillment/auth";

/**
 *
 * state.auth.login.user로 접근해도 되지만, 리프레시 했을때는 사라지므로 안정적이지 않아,
 * 로그인 성고시 localStorage에 저장하고 사용할 수 있도록 함.
 */
const getCurrentUser = (): CurrentManager | undefined => {
  if (typeof window === "undefined") return undefined;

  const data = window.localStorage.getItem("currentUser");

  if (!data) return undefined;

  return JSON.parse(data);
};

export default { getCurrentUser };
