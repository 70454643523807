import { useState } from "react";
import { useRouteMatch } from "react-router-dom";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import useMultiSelect from "@sellernote/_shared/src/hooks/common/useMultiSelect";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";
import TextButton from "@sellernote/_sds-v1/src/components/button/TextButton";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useScanInspectionUnverifiedItem from "hooks/common/useScanInspectionUnverifiedItem";
import useFetchListForUnverified from "hooks/returning/useFetchListForUnverified";
import useUnverifiedItem from "../hooks/useUnverifiedItem";

import UnverifiedList from "./UnverifiedList";
import useUnverifiedItemModal from "./useUnverifiedItemModal";
import Styled from "./index.styles";

function UnverifiedItem() {
  const match = useRouteMatch<{ id: string }>();
  const {
    params: { id },
  } = match;

  const multiSelect = useMultiSelect();

  const [opensConfirmRemoveModal, setOpensConfirmRemoveModal] = useState(false);

  const { unverifiedList, ResponseHandlerOfUnverifiedList, normalItemList } =
    useFetchListForUnverified({
      returningId: Number(id),
      onSuccessOfGetReturningUnverifiedList: (unverifiedList) => {
        if (!unverifiedList) return;

        multiSelect.initSelectionDict(unverifiedList.map(({ id }) => id));
      },
    });

  const {
    handleUnverifiedListRemove,
    ResponseHandlerOfRemoveUnverifiedList,
    addScannedUnverifiedItem,
    ResponseHandlerOfUpdateUnverifiedItem,
  } = useUnverifiedItem({
    returningId: Number(id),
    registeredUnverifiedList: unverifiedList,
    onSuccessForRemoveUnverifiedItem: () => {
      setOpensConfirmRemoveModal(false);
    },
  });

  const { ResultHandlerOfScanUnverifiedItem } = useScanInspectionUnverifiedItem(
    {
      type: "returning",
      id: Number(id),
      normalItemList,
      addScannedUnverifiedItem,
    }
  );

  const { UnverifiedItemModal, handleModalOpen, updateForm } =
    useUnverifiedItemModal({
      returningId: Number(id),
      newOrder: unverifiedList?.length ? unverifiedList.length + 1 : 1,
    });

  const unverifiedListTotalQuantity = unverifiedList?.reduce(
    (acc, { actualQty }) => acc + (actualQty ?? 0),
    0
  );

  return (
    <Layout navigator={{ title: "불일치 상품 입력" }} isWithoutDefaultPadding>
      <Styled.container>
        <Styled.totalQuantity>
          <span className="label">불일치 수량(PCS)</span>

          <span className="value">
            {replaceEmptyToDash(
              toThousandUnitFormat(unverifiedListTotalQuantity),
              true
            )}
          </span>
        </Styled.totalQuantity>

        <Styled.unverifiedListContainer>
          <div className="header">
            <span className="title">불일치 상품</span>

            <TextButton
              label="삭제"
              theme="danger"
              size="12px"
              handleClick={() => setOpensConfirmRemoveModal(true)}
              disabled={!multiSelect.selectedIdList.length}
            />
          </div>

          <UnverifiedList
            list={unverifiedList}
            multiSelect={multiSelect}
            openModalAndUpdate={(formInfo) => {
              updateForm(formInfo);

              handleModalOpen();
            }}
          />

          <Button
            label="불일치 상품 추가"
            size="small"
            theme="secondary"
            handleClick={() => handleModalOpen()}
          />
        </Styled.unverifiedListContainer>

        <Modal
          uiType="titleOnly"
          active={opensConfirmRemoveModal}
          title={`해당 입력란을 삭제하시겠습니까?`}
          actionPositive={{
            label: "네",
            handleClick: () =>
              handleUnverifiedListRemove(multiSelect.selectedIdList),
          }}
          actionNegative={{
            label: "아니오",
            handleClick: () => setOpensConfirmRemoveModal(false),
          }}
        />
      </Styled.container>

      {UnverifiedItemModal}

      {ResponseHandlerOfUnverifiedList}

      {ResponseHandlerOfRemoveUnverifiedList}

      {ResultHandlerOfScanUnverifiedItem}

      {ResponseHandlerOfUpdateUnverifiedItem}
    </Layout>
  );
}

export default withRequireAuth(UnverifiedItem);
