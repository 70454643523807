import styled from "styled-components";

const form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;

  > .button-container {
    padding: 16px 0;
  }
`;

export default {
  form,
};
