import styled from "styled-components";

const reportProblemContainer = styled.div`
  .report-problem-table {
    margin-bottom: 16px;

    > table {
      width: 100%;
      height: 390px;
    }

    .textarea-upper-row {
      > td {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
`;

export default {
  reportProblemContainer,
};
