import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

const detailSummaryContainer = styled.div`
  margin: 8px 0 16px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${COLOR.grayScale_200};
`;

const checkInvoiceContainer = styled.section`
  display: flex;
  flex-direction: column;

  > .button:first-child {
    align-self: flex-end;
    margin-bottom: 16px;
    padding: 0 8px;

    .label {
      font-size: 12px;
      line-height: 24px;
      letter-spacing: -0.6px;
    }
  }

  > .table {
    margin-bottom: 40px;
  }

  > .button:last-child {
    margin-bottom: 64px;
  }
`;

export default {
  detailSummaryContainer,
  checkInvoiceContainer,
};
