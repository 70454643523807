import { useState } from "react";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useCancelingDetail from "hooks/canceling/useCancelingDetail";

import CheckInvoice from "./CheckInvoice";
import RestockInvoice from "./RestockInvoice";
import Summary from "./Summary";

export interface CancelingDetailTableItem {
  invoiceNo: string;
  status: "○" | "✕" | "";
}

function CancelingDetail() {
  const [restocking, setRestocking] = useState(false);

  const {
    packingRestocker,
    setPackingRestocker,
    cancelingDetailSummary,
    currentUserIsNotManagerInPicking,
    isRestockingInvoice,
    ResponseHandlerOfGettingCancelingDetail,
  } = useCancelingDetail();

  return (
    <Layout
      navigator={{
        title: "취소관리",
      }}
    >
      <Summary
        cancelingDetailSummary={cancelingDetailSummary}
        packingRestocker={packingRestocker}
      />

      {/* 취소시점이 피킹 중 - 매니저 본인 담당이 아닌 경우, 재입고 작업 중인 경우 단순 열람만 가능 */}
      {currentUserIsNotManagerInPicking ||
      isRestockingInvoice ? null : !restocking ? (
        <CheckInvoice
          setRestocking={setRestocking}
          setPackingRestocker={setPackingRestocker}
        />
      ) : (
        <RestockInvoice />
      )}

      {ResponseHandlerOfGettingCancelingDetail}
    </Layout>
  );
}

export default withRequireAuth(CancelingDetail);
