import { memo } from "react";

import ChipWithClear from "@sellernote/_sds-v1/src/components/ChipWithClear";

function PersonInCharge({
  worker,
  isRemovable,
  // removePersonInCharge,
  openConfirmModal,
}: {
  worker: string | undefined;
  isRemovable: boolean;
  // removePersonInCharge: () => void;
  openConfirmModal: () => void;
}) {
  if (!worker) return null;

  return (
    // <ConfirmWrapper
    //   confirmMessageTitle="SKU 담당자를 삭제하시겠습니까?"
    //   confirmModalUiType="titleOnly"
    //   disabled={!isRemovable}
    //   usePortal
    // >
    <ChipWithClear
      label={worker}
      handleClear={isRemovable ? openConfirmModal : undefined}
      // handleClear={isRemovable ? removePersonInCharge : undefined}
      colorTheme={"dark"}
    />
    // </ConfirmWrapper>
  );
}

export default memo(PersonInCharge);
