import { useCallback, useMemo, useState } from "react";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";

import useProblemItemForm from "./useProblemItemForm";

export default function useProblemItemModal({
  returningId,
  skuId,
  itemId,
  newProblemId,
  inspectionCount,
  problemTotalQuantity,
}: {
  returningId: number;
  skuId: number;
  itemId: number;
  newProblemId: number;
  inspectionCount: number;
  problemTotalQuantity: number;
}) {
  const [opensProblemItemModal, setOpensProblemItemModal] = useState(false);

  const { resetForm, updateFormInfo, isChangedForm, ProblemItemForm } =
    useProblemItemForm({
      returningId,
      skuId,
      itemId,
      newProblemId,
      inspectionCount,
      problemTotalQuantity,
      onSuccessForDeletePhoto: () => setOpensProblemItemModal(false),
    });

  const handleModalClose = useCallback(() => {
    setOpensProblemItemModal(false);

    resetForm();
  }, [resetForm]);

  const handleModalOpen = () => {
    setOpensProblemItemModal(true);
  };

  const ProblemItemModal = useMemo(
    () => (
      <Modal
        uiType="contentWithCustomBody"
        active={opensProblemItemModal}
        title={`문제 보고`}
        body={ProblemItemForm}
        onClose={handleModalClose}
        needConfirmBeforeCloseModal={isChangedForm}
        needConfirmBeforeCloseModalInfo={{
          title: "저장하지 않고 나가시겠습니까?",
          actionPositiveLabel: "네",
          actionNegativeLabel: "아니오",
        }}
      />
    ),
    [ProblemItemForm, handleModalClose, isChangedForm, opensProblemItemModal]
  );

  return {
    ProblemItemModal,
    handleModalOpen,
    handleModalClose,
    updateFormInfo,
  };
}
