import { Dispatch, SetStateAction } from "react";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import InputRadio from "@sellernote/_sds-v1/src/components/input/InputRadio";

import InputForSku from "./InputForSku";
import ScanSku from "./ScanSku";
import useSelectSkuModal, { SkuToSelect } from "./useSelectSkuModal";
import Styled from "./index.styles";

export default function SelectSkuModal({
  active,
  setSelectedSku,
  onClose,
}: {
  active: boolean;
  onClose: () => void;
  setSelectedSku: Dispatch<SetStateAction<SkuToSelect | undefined>>;
}) {
  const {
    selectType,
    handleSelectTypeChange,

    tempSku,
    setTempSku,

    handleSelectSkuModalClose,
  } = useSelectSkuModal({
    onClose,
  });

  return (
    <>
      <Modal
        active={active}
        uiType="contentWithCustomBody"
        title="SKU ID / 상품바코드"
        body={
          <Styled.selectSkuModalBody>
            <InputRadio
              direction="row"
              optionList={[
                {
                  label: "스캔",
                  value: "scan",
                },
                {
                  label: "직접입력",
                  value: "input",
                },
              ]}
              selectedValue={selectType}
              selectCallback={handleSelectTypeChange}
            />

            {selectType === "scan" ? (
              <ScanSku
                tempSku={tempSku}
                setTempSku={setTempSku}
                setSelectedSku={setSelectedSku}
                onSelectSkuModalClose={handleSelectSkuModalClose}
              />
            ) : (
              <InputForSku
                tempSku={tempSku}
                setTempSku={setTempSku}
                setSelectedSku={setSelectedSku}
                onSelectSkuModalClose={handleSelectSkuModalClose}
              />
            )}
          </Styled.selectSkuModalBody>
        }
        onClose={handleSelectSkuModalClose}
      />
    </>
  );
}
