import { Dispatch, SetStateAction } from "react";

import { noop } from "@sellernote/_shared/src/utils/common/etc";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";

import { SkuToSelect } from "../useSelectSkuModal";
import OptionList from "./OptionList";
import useSelectSkuInput from "./useSelectSkuInput";
import Styled from "./index.styles";

export interface ScanTypeProps {
  type: "scan";
}

export interface InputTypeProps {
  type: "input";
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  onTempSearchTermRestore: () => void;
}

export default function SelectSkuInput({
  selectedSku,
  optionList,
  onSkuSelect,
  onReset,
  disabledToFocus,
  disabled,
  placeholder,
  ...propsByType
}: {
  selectedSku: SkuToSelect | undefined;
  optionList: SkuToSelect[] | undefined;
  onSkuSelect: (sku: SkuToSelect) => () => void;
  onReset: () => void;
  disabledToFocus?: boolean;
  disabled?: boolean;
  placeholder?: string;
} & (ScanTypeProps | InputTypeProps)) {
  const { selectedSkuValue, isVisibleOptionList } = useSelectSkuInput({
    selectedSku,
    onReset,
    ...propsByType,
  });

  return (
    <Styled.selectSkuInput>
      <InputText
        label="SKU ID / 상품바코드"
        borderType="outline"
        valueType="string"
        placeholder={placeholder}
        value={
          (propsByType.type === "input" && propsByType.searchTerm) ||
          selectedSkuValue
        }
        setValue={
          propsByType.type === "input" ? propsByType.setSearchTerm : noop
        }
        clearCB={onReset}
        disabledToFocus={disabledToFocus}
        visiblePostFix={propsByType.type === "scan"}
        disabled={disabled}
      />

      {isVisibleOptionList && !!optionList?.length && (
        <OptionList list={optionList} onSkuSelect={onSkuSelect} />
      )}
    </Styled.selectSkuInput>
  );
}
