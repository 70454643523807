import { useAtom } from "jotai";
import { RETURNING_INSPECTION_ATOMS } from "jotaiStates/returning/inspection";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { checkForUnverifiedItemAsInspection } from "@sellernote/_shared/src/utils/fulfillment/inspection";
import ConfirmWrapper from "@sellernote/_sds-v1/src/components/ConfirmWrapper";

import { returningSelectors } from "modules/returning";
import { useAppSelector } from "store";

import useCloseAsNormal from "./useCloseAsNormal";

function CloseInspection({ returningId }: { returningId: number }) {
  const { returningItems, isCompletedInspectionWithMatchedCount } =
    useAppSelector((state) => {
      return {
        returningItems: state.returning.GET_DETAIL?.data?.returning.items,
        isCompletedInspectionWithMatchedCount:
          returningSelectors.checkIsCompletedInspectionWithMatchedCount(state),
      };
    });

  const {
    confirmMessageTitle,
    handleSubmit,
    ResponseHandlerOfSetInspectionDone,
  } = useCloseAsNormal({ returningId });

  const [scannedUnregisteredUnverifiedList] = useAtom(
    RETURNING_INSPECTION_ATOMS.SCANNED_UNREGISTERED_UNVERIFIED_LIST
  );

  const isCompletedUnverifiedListInput =
    !scannedUnregisteredUnverifiedList.length &&
    !!returningItems
      ?.filter(checkForUnverifiedItemAsInspection)
      .every(({ attachment }) => !!attachment?.length);

  return (
    <>
      <ConfirmWrapper
        confirmMessageTitle={confirmMessageTitle}
        confirmModalUiType="content"
        confirmMessageBody={
          <>
            {isCompletedInspectionWithMatchedCount
              ? "입고요청수량과 검수수량이 일치합니다."
              : "입고요청수량과 검수수량이 일치하지 않습니다."}
            <br />
            검수를 마치시겠습니까?
          </>
        }
        disabled={!isCompletedUnverifiedListInput}
      >
        <Button
          label="검수마감"
          size="block"
          theme="primary"
          handleClick={handleSubmit}
          disabled={!isCompletedUnverifiedListInput}
        />
      </ConfirmWrapper>

      {ResponseHandlerOfSetInspectionDone}
    </>
  );
}

export default CloseInspection;
