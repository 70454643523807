import { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import RECEIVING_QUERY, {
  RECEIVING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { ReceivingInvoiceNo } from "@sellernote/_shared/src/types/fulfillment/receiving";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

import useConfirmModal from "hooks/common/useConfirmModal";
import { usePubSub } from "hooks/common/usePubSub";
import useScan from "hooks/common/useScan";

import Styled from "./index.styles";

interface InvoiceListToScan {
  invoiceNo: string;
  isCompleted: boolean;
}

const initInvoiceListToScan = (invoiceNoList: ReceivingInvoiceNo[]) =>
  invoiceNoList.map<InvoiceListToScan>((invoiceNo) => ({
    invoiceNo: invoiceNo.invoiceNo,
    isCompleted: false,
  }));

export default function useScanInvoicePanelForConfirm({
  receivingId,
  invoiceNoList,
  showPicturePanel,
}: {
  receivingId: number;
  invoiceNoList: ReceivingInvoiceNo[];
  showPicturePanel: () => void;
}) {
  const [isVisibleScanInvoicePanel, setIsVisibleScanInvoicePanel] =
    useState(false);
  const [invoiceListToScan, setInvoiceListToScan] = useState<
    InvoiceListToScan[]
  >(() => initInvoiceListToScan(invoiceNoList));
  const [scannedInvoice, setScannedInvoice] = useState("");
  const [isMisMatched, setIsMisMatched] = useState(false);

  const queryClient = useQueryClient();

  const {
    mutate: setInvoiceScanDone,
    ResponseHandler: ResponseHandlerOfSetInvoiceScanDone,
  } = RECEIVING_QUERY.useSetInvoiceScanDone({
    receivingId,
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();

        handleScanInvoicePanelClose();
        setConfirmModal(undefined);

        queryClient.invalidateQueries(
          RECEIVING_QUERY_KEY_GEN.getManagerReceivingDetail({ receivingId })
        );
      },
      customizeMessage: () => ({
        messageType: "titleOnly",
        title: "완료 처리되었습니다.",
      }),
    },
  });

  const { ConfirmModal, setConfirmModal } = useConfirmModal();

  const { subscribe, publish } = usePubSub();

  useScan((scanResult) => {
    if (!isVisibleScanInvoicePanel) return;

    setScannedInvoice(scanResult);

    setInvoiceListToScan((prevInvoiceListToScan) =>
      prevInvoiceListToScan.map((invoice) =>
        invoice.invoiceNo === scanResult
          ? { ...invoice, isCompleted: true }
          : invoice
      )
    );

    setIsMisMatched(
      !invoiceListToScan.some(({ invoiceNo }) => invoiceNo === scanResult)
    );

    publish("useScanInvoicePanelForConfirm.handleScanResult", {
      scannedInvoiceNo: scanResult,
    });
  });

  const handlePicturePanelClose = useCallback(() => {
    setIsMisMatched(false);
  }, []);

  useEffect(() => {
    subscribe(
      "usePicturePanelForConfirm.handlePicturePanelClose",
      handlePicturePanelClose
    );
  }, [handlePicturePanelClose, subscribe]);

  const showScanInvoicePanel = useCallback(() => {
    setIsVisibleScanInvoicePanel(true);
  }, []);

  const handleScanInvoicePanelClose = useCallback(() => {
    setIsVisibleScanInvoicePanel(false);
    setInvoiceListToScan(initInvoiceListToScan(invoiceNoList));
    setScannedInvoice("");
    setIsMisMatched(false);
  }, [invoiceNoList]);

  const handleScanInvoiceComplete = useCallback(() => {
    const completedList = invoiceListToScan
      .filter(({ isCompleted }) => isCompleted)
      .map(({ invoiceNo }) => invoiceNo);

    setInvoiceScanDone(
      { invoices: completedList },
      {
        onSuccess: handleScanInvoicePanelClose,
      }
    );
  }, [handleScanInvoicePanelClose, invoiceListToScan, setInvoiceScanDone]);

  const checkInvoiceScanDone = useCallback(() => {
    const hasIncompleteInvoice = invoiceListToScan.some(
      (invoice) => !invoice.isCompleted
    );

    hasIncompleteInvoice
      ? setConfirmModal({
          uiType: "titleOnly",
          title: (
            <>
              스캔이 완료되지 않은 송장이 있습니다. <br />
              스캔을 완료하시겠습니까?
            </>
          ),
          actions: {
            actionPositive: {
              label: "스캔완료",
              handleClick: handleScanInvoiceComplete,
            },
            actionNegative: {
              label: "아니오",
              handleClick: () => setConfirmModal(undefined),
            },
          },
        })
      : handleScanInvoiceComplete();
  }, [handleScanInvoiceComplete, invoiceListToScan, setConfirmModal]);

  const ListByScanStatus = useMemo(() => {
    if (isMisMatched) {
      return (
        <ListItem
          type="withContentAlignRight"
          label="스캔 운송장 번호"
          value={
            <span className="mis-matched">{scannedInvoice} (등록안됨)</span>
          }
        />
      );
    }

    return (
      <>
        {invoiceListToScan.map(({ invoiceNo, isCompleted }, i) => {
          const InvoiceNoValue = (
            <>
              {invoiceNo}{" "}
              <span className={isCompleted ? "complete" : "scanning"}>
                {isCompleted ? "(완료)" : "(스캔 중)"}
              </span>
            </>
          );

          if (i === 0) {
            return (
              <ListItem
                key={invoiceNo}
                type="withContentAlignRight"
                label="등록 운송장 번호"
                value={InvoiceNoValue}
              />
            );
          }

          return (
            <ListItem
              key={invoiceNo}
              type="withContentAlignRight"
              label=""
              value={InvoiceNoValue}
            />
          );
        })}
      </>
    );
  }, [invoiceListToScan, isMisMatched, scannedInvoice]);

  const ScanInvoicePanel = useMemo(() => {
    if (!isVisibleScanInvoicePanel) return null;

    return (
      <>
        <Modal
          active
          uiType="contentWithCustomBody"
          title={isMisMatched ? "등록된 송장번호가 아닙니다." : "송장스캔"}
          body={
            <Styled.modalBody>
              {ListByScanStatus}

              {isMisMatched ? (
                <Button
                  className="btn-complete"
                  label={"사진촬영"}
                  size="normal"
                  theme="primary"
                  handleClick={showPicturePanel}
                />
              ) : (
                <Button
                  className="btn-complete"
                  label={"송장스캔완료"}
                  size="normal"
                  theme="primary"
                  handleClick={checkInvoiceScanDone}
                />
              )}
            </Styled.modalBody>
          }
          onClose={handleScanInvoicePanelClose}
        />

        {ConfirmModal}
      </>
    );
  }, [
    isVisibleScanInvoicePanel,
    isMisMatched,
    ListByScanStatus,
    showPicturePanel,
    checkInvoiceScanDone,
    handleScanInvoicePanelClose,
    ConfirmModal,
  ]);

  return {
    showScanInvoicePanel,
    ScanInvoicePanel,
    ResponseHandlerOfSetInvoiceScanDone,
  };
}
