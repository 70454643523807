import { useCallback, useState } from "react";

export default function useExistingLocation() {
  const [isVisibleSelectLocationModal, setIsVisibleSelectLocationModal] =
    useState(false);

  const handleExistingLocationModalOpen = useCallback(() => {
    setIsVisibleSelectLocationModal(true);
  }, []);

  const handleExistingLocationModalClose = useCallback(() => {
    setIsVisibleSelectLocationModal(false);
  }, []);

  return {
    isVisibleSelectLocationModal,
    handleExistingLocationModalOpen,
    handleExistingLocationModalClose,
  };
}
