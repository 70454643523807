import { Dispatch, memo, SetStateAction } from "react";

import { ManagementKind } from "@sellernote/_shared/src/types/fulfillment/inventory";
import {
  getCombinedSkuManagementValueForPDAInventory,
  getCombinedSkuValueForPDAInventory,
} from "@sellernote/_shared/src/utils/fulfillment/inventory";
import TextButton from "@sellernote/_sds-v1/src/components/button/TextButton";
import ListItem from "@sellernote/_sds-v1/src/components/ListItem";

import SelectSkuModal from "../../SelectSkuModal";
import { SkuToSelect } from "../../SelectSkuModal/useSelectSkuModal";
import SkuDetailModal from "../../SkuDetailModal";
import useSkuDetail from "./useSkuDetail";
import Styled from "./index.styles";

function SkuDetail({
  skuId,
  skuBarcode,
  managementKind,
  managementDate,
  availableQty,
  isEmptyLocation,
  selectedSku,
  setSelectedSku,
}: {
  skuId: number | undefined;
  skuBarcode: string | undefined;
  managementKind: ManagementKind | undefined;
  managementDate: string | undefined;
  availableQty: number | undefined;
  isEmptyLocation: boolean;
  selectedSku: SkuToSelect | undefined;
  setSelectedSku: Dispatch<SetStateAction<SkuToSelect | undefined>>;
}) {
  const {
    skuIdToAdjust,
    skuBarcodeToAdjust,
    skuManagementKindToAdjust,
    skuManagementDateToAdjust,
    ButtonForSku,

    getAdjustableQty,

    isVisibleReSelectButton,

    isVisibleSkuDetailModal,
    handleSkuDetailModalClose,

    isVisibleSelectSkuModal,
    handleSelectSkuModalOpen,
    handleSelectSkuModalClose,

    skuDetail,

    ResponseHandlerOfGettingSkuDetail,

    hasManagementInfo,
  } = useSkuDetail({
    skuId,
    skuBarcode,
    managementKind,
    managementDate,
    availableQty,
    isEmptyLocation,
    selectedSku,
  });

  return (
    <>
      <Styled.skuDetail>
        <ListItem
          label="상품정보"
          type="withButton"
          value={
            hasManagementInfo ? (
              <Styled.valueWithManagement>
                {getCombinedSkuValueForPDAInventory({
                  skuId: skuIdToAdjust,
                  skuBarcode: skuBarcodeToAdjust,
                })}
                <br />
                {getCombinedSkuManagementValueForPDAInventory({
                  managementKind: skuManagementKindToAdjust,
                  managementDate: skuManagementDateToAdjust,
                })}
              </Styled.valueWithManagement>
            ) : (
              getCombinedSkuValueForPDAInventory({
                skuId: skuIdToAdjust,
                skuBarcode: skuBarcodeToAdjust,
              })
            )
          }
          button={ButtonForSku}
        />

        {isVisibleReSelectButton && (
          <TextButton
            theme="gray"
            icon="arrowRight"
            label="SKU ID 재선택"
            size="14px"
            handleClick={handleSelectSkuModalOpen}
          />
        )}

        <ListItem
          label="가용수량"
          type="withContentAlignRight"
          value={getAdjustableQty()}
        />
      </Styled.skuDetail>

      <SkuDetailModal
        active={isVisibleSkuDetailModal}
        skuDetail={skuDetail?.sku}
        managementKind={skuManagementKindToAdjust}
        managementDate={skuManagementDateToAdjust}
        onClose={handleSkuDetailModalClose}
      />

      <SelectSkuModal
        active={isVisibleSelectSkuModal}
        setSelectedSku={setSelectedSku}
        onClose={handleSelectSkuModalClose}
      />

      {ResponseHandlerOfGettingSkuDetail}
    </>
  );
}

export default memo(SkuDetail);
