import React, { useMemo, useState } from "react";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { ModalFooterActions } from "@sellernote/_shared/src/headlessComponents/useModal";

export interface ConfirmModal {
  uiType: "titleOnly" | "content" | "contentWithCustomBody";
  title: React.ReactNode;
  body?: React.ReactNode;
  actions: ModalFooterActions;
}

export default function useConfirmModal() {
  const [confirmModal, setConfirmModal] = useState<ConfirmModal>();

  const ConfirmModal = useMemo(
    () => (
      // ConfirmWrapper/AlertWrapper 대신에 하나의 모달을 사용(가상화된 table 내에 렌더링 시 레이아웃이 깨짐)
      <Modal
        active={!!confirmModal}
        uiType={confirmModal?.uiType ?? "titleOnly"}
        title={confirmModal?.title}
        body={confirmModal?.body}
        actionPositive={confirmModal?.actions.actionPositive}
        actionNegative={confirmModal?.actions.actionNegative}
      />
    ),
    [confirmModal]
  );

  return {
    ConfirmModal,
    setConfirmModal,
  };
}
