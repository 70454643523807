import { useQueryClient } from "react-query";
import INSPECTION_QUERY from "queries/receiving/INSPECTION_QUERY";

import { REMOVE_PROBLEM_ITEMS_REQ } from "@sellernote/_shared/src/api-interfaces/boful-api/receiving";
import { RECEIVING_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";

export default function useProblemItem({
  receivingId,
  onSuccessForCreateProblemItem,
  onSuccessForRemoveProblemList,
}: {
  receivingId: number;
  onSuccessForCreateProblemItem?: () => void;
  onSuccessForRemoveProblemList?: () => void;
}) {
  const queryClient = useQueryClient();

  const {
    mutate: createProblemItem,
    ResponseHandler: ResponseHandlerOfCreateProblemItem,
  } = INSPECTION_QUERY.useCreateProblemItemForReceiving({
    receivingId,
    onSuccess: () => {
      queryClient.invalidateQueries(
        RECEIVING_QUERY_KEY_GEN.getPDAReceivingDetail({
          id: receivingId,
        })
      );

      onSuccessForCreateProblemItem?.();
    },
  });

  const {
    mutate: removeProblemList,
    ResponseHandler: ResponseHandlerOfRemoveProblemList,
  } = INSPECTION_QUERY.useRemoveProblemList({
    receivingId,
    onSuccess: () => {
      queryClient.invalidateQueries(
        RECEIVING_QUERY_KEY_GEN.getPDAReceivingDetail({
          id: receivingId,
        })
      );

      onSuccessForRemoveProblemList?.();
    },
  });

  const handleProblemListRemove = ({
    itemId,
    problemIds,
  }: REMOVE_PROBLEM_ITEMS_REQ) => {
    removeProblemList({ itemId, problemIds });
  };

  return {
    createProblemItem,
    ResponseHandlerOfCreateProblemItem,
    handleProblemListRemove,
    ResponseHandlerOfRemoveProblemList,
  };
}
