import { TFunction } from "react-i18next";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";

import SeverErrorImage from "./server_error.png";
import Styled from "./index.styles";

export default function ServerError({
  t,
  goBackToMainPage,
}: {
  t?: TFunction;
  goBackToMainPage: () => void;
}) {
  const { isMobile } = useCheckIsMobile();

  return (
    <Styled.wrapper>
      <Styled.container>
        <Styled.img>
          <img src={SeverErrorImage} alt="server-error-page" />
        </Styled.img>

        <Styled.textGroup>
          <div className="text-bold">SERVER ERROR</div>

          <div className="text-normal">
            {t ? t("errors:serverErrorDesc") : "시스템 오류가 발생했습니다."}
          </div>
        </Styled.textGroup>
      </Styled.container>

      <Styled.backAction>
        <Button
          theme="primary"
          size={isMobile ? "normal" : "xLarge"}
          label={t ? t("errors:goBack") : "< 돌아가기"}
          handleClick={goBackToMainPage}
        />
      </Styled.backAction>
    </Styled.wrapper>
  );
}
