import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

function InventoryInspection() {
  return (
    <Layout
      navigator={{
        title: "재고 조사",
      }}
    >
      <div>재고 조사</div>
    </Layout>
  );
}

export default withRequireAuth(InventoryInspection);
