import React, { useCallback, useMemo } from "react";
import produce from "immer";
import { useRecoilValue } from "recoil";

import { INVENTORY_MANAGEMENT_KIND_MAP } from "@sellernote/_shared/src/constants/fulfillment/inventory";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_CANCELING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/canceling";
import { SKUInfoForCancelingRestocking } from "@sellernote/_shared/src/types/fulfillment/canceling";
import { ScanType } from "@sellernote/_shared/src/types/fulfillment/scan";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import {
  getItemIdListByWorkingLocation,
  getRestockingItemId,
} from "@sellernote/_shared/src/utils/fulfillment/canceling";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";

import useScan from "hooks/common/useScan";

import useCancelingMessageModal from "./useCancelingMessageModal";
import { CompletedList, WorkingLocation } from "./useRestockCanceling";

/**
 * 취소 재입고 SKU 스캔
 */
export default function useScanCancelingSKU({
  selectedScanButtonType,
  handleScanButtonClick,
  workingLocation,
  setWorkingLocation,
  setRowInfoToHighlight,
  SKUInfoForScanning,
  setSKUInfoForScanning,
  completedList,
  isFirstSKUScan,
}: {
  selectedScanButtonType?: ScanType;
  handleScanButtonClick: (type: ScanType | undefined) => void;
  workingLocation?: WorkingLocation;
  setWorkingLocation: React.Dispatch<
    React.SetStateAction<WorkingLocation | undefined>
  >;
  setRowInfoToHighlight: React.Dispatch<
    React.SetStateAction<TableRowInfoToHighlight | undefined>
  >;
  SKUInfoForScanning: SKUInfoForCancelingRestocking[];
  setSKUInfoForScanning: React.Dispatch<
    React.SetStateAction<SKUInfoForCancelingRestocking[]>
  >;
  completedList: React.MutableRefObject<CompletedList>;
  isFirstSKUScan: React.MutableRefObject<boolean>;
}) {
  const restockingList = useRecoilValue(
    FULFILLMENT_CANCELING_SELECTORS.RESTOCKING_LIST
  );

  const { MessageModal, modalOpenHandler } = useCancelingMessageModal();

  const setSKUScanningResult = useCallback(
    ({
      scannedSKUId,
      scannedItemId,
      itemIdListByWorkingLocation,
    }: {
      scannedSKUId: string;
      scannedItemId: number;
      itemIdListByWorkingLocation: number[];
    }) => {
      setRowInfoToHighlight({ rowKey: scannedItemId });
      restoreCompletedItemIdListByWorkingLocation();
      countSKU();
      checkIsCompletedScanByScannedItemId();

      function restoreCompletedItemIdListByWorkingLocation() {
        // 다른 위치 작업을 하다가 기존에 완료된 위치의 상품을 초기화하해서 다시 돌아온 경우 작업 위치에서 완료된 상품 목록을 복원해준다.
        if (
          !isFirstSKUScan.current &&
          completedList.current.itemIdListByWorkingLocation.size === 0
        ) {
          itemIdListByWorkingLocation.forEach((itemId) => {
            if (completedList.current.itemIdList.has(itemId)) {
              completedList.current.itemIdListByWorkingLocation.add(itemId);
            }
          });
        }
      }

      function countSKU() {
        setSKUInfoForScanning(
          produce((draft) => {
            const SKUInfoByScannedSKUId = draft.find(
              (v) => v.id === scannedItemId
            );

            if (SKUInfoByScannedSKUId) {
              SKUInfoByScannedSKUId.currentQty += 1;
            }
          })
        );
      }

      function checkIsCompletedScanByScannedItemId() {
        const scannedSKUInfo = SKUInfoForScanning.find(
          (v) => v.id === scannedItemId
        );
        const isCompletedScanByScannedItemId =
          (scannedSKUInfo?.currentQty ?? 0) + 1 === scannedSKUInfo?.quantity;

        if (isCompletedScanByScannedItemId) {
          completedList.current.itemIdListByWorkingLocation.add(scannedItemId);
          completedList.current.itemIdList.add(scannedItemId);

          modalOpenHandler.openCompletedRestockingBySKUIdMessage(scannedSKUId);

          checkIsCompletedScanByWorkingLocation();
        }

        function checkIsCompletedScanByWorkingLocation() {
          const isCompletedScanByWorkingLocation =
            JSON.stringify([...itemIdListByWorkingLocation].sort()) ===
            JSON.stringify(
              [...completedList.current.itemIdListByWorkingLocation].sort()
            );

          if (isCompletedScanByWorkingLocation) {
            completedList.current.locationIdList.add(workingLocation?.id ?? 0);

            completedList.current.itemIdListByWorkingLocation.clear();
            setWorkingLocation(undefined);
            handleScanButtonClick("location");
          }
        }
      }
    },
    [
      SKUInfoForScanning,
      completedList,
      handleScanButtonClick,
      isFirstSKUScan,
      modalOpenHandler,
      setRowInfoToHighlight,
      setSKUInfoForScanning,
      setWorkingLocation,
      workingLocation,
    ]
  );

  const itemIdListByWorkingLocation = useMemo(
    () =>
      getItemIdListByWorkingLocation({
        restockingList,
        workingLocationId: workingLocation?.id ?? 0,
      }),
    [restockingList, workingLocation?.id]
  );
  const checkIsCorrectSKUBarcode = useCallback(
    ({ scannedBarcode }: { scannedBarcode: string }) => {
      if (!workingLocation) {
        return;
      }

      const scannedItemId = getRestockingItemId({
        scannedBarcode,
        locationId: workingLocation.id,
        restockingList,
      });

      const scannedSkuInfo = SKUInfoForScanning.find(
        (v) => v.id === scannedItemId
      );

      const scannedSKUId = getFormattedSingleSkuId(scannedSkuInfo?.skuId);

      // 재입고 대상이 아닌 상품 스캔 - 송장에 아예 없는 상품
      // 해당 SKU ID(1111)는 재입고 목록에 없습니다.
      if (
        !SKUInfoForScanning.some(
          (v) =>
            getFormattedSingleSkuId(v.skuId) === scannedBarcode ||
            v.skuBarcode === scannedBarcode
        )
      ) {
        modalOpenHandler.openNoSKUInRestockingListMessage(scannedBarcode);
        return;
      }

      // 현재 스캔 중인 위치의 상품이 아닌 상품을 스캔한 경우
      // 해당 위치(A1-1)에 없는 SKU ID입니다.
      if (!itemIdListByWorkingLocation.some((v) => v === scannedItemId)) {
        modalOpenHandler.openNoSKUInWorkingLocationMessage(
          `${workingLocation.barcode}${
            workingLocation.managementKind
              ? ` / ${
                  INVENTORY_MANAGEMENT_KIND_MAP[workingLocation.managementKind]
                }: ${toFormattedDate(
                  workingLocation.managementDate,
                  "YYYY-MM-DD"
                )}`
              : ""
          }`
        );
        return;
      }

      // 이미 풀카운트인 상품을 또 스캔한 경우
      if (completedList.current.itemIdList.has(scannedItemId)) {
        modalOpenHandler.openOverQuantityScanMessage(
          <>
            {scannedSKUId}(SKU ID)
            {scannedSkuInfo?.managementKind && (
              <>
                <br />
                {
                  INVENTORY_MANAGEMENT_KIND_MAP[scannedSkuInfo.managementKind]
                }{" "}
                {toFormattedDate(scannedSkuInfo.managementDate, "YYYY-MM-DD")}
              </>
            )}
          </>
        );
        return;
      }

      setSKUScanningResult({
        scannedSKUId,
        scannedItemId,
        itemIdListByWorkingLocation,
      });
    },
    [
      SKUInfoForScanning,
      completedList,
      itemIdListByWorkingLocation,
      modalOpenHandler,
      restockingList,
      setSKUScanningResult,
      workingLocation,
    ]
  );

  const scanSKU = useCallback(
    (scannedBarcode: string) => {
      if (selectedScanButtonType !== "sku") {
        return;
      }

      checkIsCorrectSKUBarcode({ scannedBarcode });
    },
    [checkIsCorrectSKUBarcode, selectedScanButtonType]
  );

  useScan(scanSKU);

  return { ScanSKUMessageModal: MessageModal, modalOpenHandler };
}
