import { LocationKind } from "../../types/fulfillment/location";

const LocationKindMap: Record<LocationKind, string> = {
  receiving: "입고",
  shipping: "출고",
  cancel: "취소",
  discard: "폐기",
  putAway: "보관",
};

export { LocationKindMap };
