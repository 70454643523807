import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.div`
  margin-top: 32px;

  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .left {
      ${setFontStyle("Head6", "Bold")}
      color: ${COLOR.grayScale_800};
    }

    > .right {
    }
  }

  > .list {
  }

  > .button {
    margin-top: 16px;
  }
`;

const managementDateForm = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > .left {
    display: flex;
    align-items: center;

    > .calendar {
      margin-right: 8px;
    }

    > .input-text {
    }
  }

  > .right {
  }
`;

const totalCount = styled.div<{ isValidCount: boolean }>`
  ${setFontStyle("Head6", "Bold")}
  color: ${COLOR.grayScale_800};

  > .current-count {
    color: ${({ isValidCount }) =>
      isValidCount ? COLOR.pointSuccess : COLOR.pointWarning};
  }
`;

export default {
  container,
  managementDateForm,
  totalCount,
};
