import { COLOR } from "../../../styles/colors";

import CheckSolidIcon from "../../svgIcons/CheckSolidIcon";
import Styled from "./index.styles";

export interface StepItemProps {
  number: number;
  isActive: boolean;
  isDone: boolean;
}

export default function StepItem({ number, isActive, isDone }: StepItemProps) {
  return (
    <Styled.stepItemContainer isActive={isActive}>
      <Styled.stepPoint isActive={isActive}>
        {isDone ? (
          <CheckSolidIcon color={COLOR.wh} width={16} height={16} />
        ) : (
          <span className="step-number">{number}</span>
        )}
      </Styled.stepPoint>
    </Styled.stepItemContainer>
  );
}
