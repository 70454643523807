import { Dispatch, SetStateAction } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { BofulLocation } from "@sellernote/_shared/src/types/fulfillment/common";
import InputRadio from "@sellernote/_sds-v1/src/components/input/InputRadio";

import ScanLocation from "./ScanLocation";
import SearchLocation from "./SearchLocation";
import useSelectLocationModal, {
  ModalTypeInfo,
} from "./useSelectLocationModal";
import Styled from "./index.styles";

export default function SelectLocationModal({
  active,
  setSelectedLocation,
  onClose,
  ...modalTypeInfo
}: {
  active: boolean;
  setSelectedLocation: Dispatch<SetStateAction<BofulLocation | undefined>>;
  onClose: () => void;
} & ModalTypeInfo) {
  const {
    label,

    locationType,
    handleLocationTypeChange,

    tempLocation,
    setTempLocation,
    handleTempLocationReset,

    handleSelectLocationModalConfirm,
    handleSelectLocationModalClose,

    locationListByType,
    ResponseHandlerOfGettingLocationListOfWarehouse,
  } = useSelectLocationModal({
    setSelectedLocation,
    onClose,
    ...modalTypeInfo,
  });

  return (
    <>
      <Modal
        active={active}
        uiType="contentWithCustomBody"
        title={label}
        body={
          <Styled.selectLocationModalBody>
            <InputRadio
              direction="row"
              optionList={[
                {
                  label: "스캔",
                  value: "scan",
                },
                {
                  label: "직접입력",
                  value: "input",
                },
              ]}
              selectedValue={locationType}
              selectCallback={handleLocationTypeChange}
            />

            {locationType === "scan" ? (
              <ScanLocation
                label={label}
                locationListByType={locationListByType}
                tempLocationBarcode={tempLocation?.barCode}
                setTempLocation={setTempLocation}
                onLocationBarcodeReset={handleTempLocationReset}
              />
            ) : (
              <SearchLocation
                label={label}
                locationListByType={locationListByType}
                selectedLocation={tempLocation}
                onLocationSelect={setTempLocation}
                onReset={handleTempLocationReset}
              />
            )}

            <Button
              theme="primary"
              size="normal"
              label="확인"
              handleClick={handleSelectLocationModalConfirm}
              disabled={!tempLocation}
            />
          </Styled.selectLocationModalBody>
        }
        onClose={handleSelectLocationModalClose}
      />

      {ResponseHandlerOfGettingLocationListOfWarehouse}
    </>
  );
}
