import { useState } from "react";

import { getPageSize } from "@sellernote/_shared/src/utils/common/etc";
import Paging from "@sellernote/_sds-v1/src/components/Paging";
import TabFilter from "@sellernote/_sds-v1/src/components/TabFilter";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useFetchWarehousingList, {
  FilterForListForWarehousing,
  TableItemForWarehousing,
} from "hooks/returning/useFetchWarehousingList";

import Styled from "./index.styles";

const PAGE_UNIT = 10;

function ReturningWarehousing() {
  const [filter, setFilter] = useState<FilterForListForWarehousing>("unDone");

  const [currentPage, setCurrentPage] = useState(0);

  const {
    tableDataListForWarehousing,
    totalCountOfListOfWarehousing,
    anotherTotalCountOfListOfWarehousing,
    ResponseHandlerOfGettingWarehousingList,
  } = useFetchWarehousingList({
    perPage: PAGE_UNIT,
    currentPage,
    filter,
  });

  return (
    <Layout navigator={{ title: "반품입고" }}>
      <Styled.container>
        <TabFilter<FilterForListForWarehousing>
          filterList={[
            {
              label: `My 입고 (${
                filter === "unDone"
                  ? totalCountOfListOfWarehousing
                  : anotherTotalCountOfListOfWarehousing
              })`,
              filter: "unDone",
            },
            {
              label: `입고완료 (${
                filter === "unDone"
                  ? anotherTotalCountOfListOfWarehousing
                  : totalCountOfListOfWarehousing
              })`,
              filter: "done",
            },
          ]}
          selectedFilter={filter}
          setFilter={(filter) => {
            setFilter(filter);
            setCurrentPage(0);
          }}
        />

        <Paging
          pageSize={getPageSize(PAGE_UNIT, totalCountOfListOfWarehousing)}
          currentPage={currentPage}
          currentPageData={
            <Table<TableItemForWarehousing>
              columnInfo={{
                combinedTitle: {
                  label: "회사명 (팀명)",
                  fixedWidth: 120,
                },
                bidId: {
                  label: "의뢰번호",
                },
                SKU: {
                  label: "상품종류(SKU)",
                  fixedWidth: 110,
                },
                ...(filter === "unDone"
                  ? {
                      PCS: {
                        label: "입고요청수량(PCS)",
                        fixedWidth: 140,
                      },
                    }
                  : {}),
                ...(filter === "done"
                  ? {
                      actualQty: {
                        label: "검수수량(PCS)",
                        fixedWidth: 120,
                      },
                      placeQty: {
                        label: "입고수량(PCS)",
                        fixedWidth: 120,
                      },
                      status: {
                        label: "상태",
                        fixedWidth: 120,
                      },
                    }
                  : {}),
              }}
              dataList={tableDataListForWarehousing}
            />
          }
          handleClickPage={setCurrentPage}
          isZeroBasedPage
        />
      </Styled.container>

      {ResponseHandlerOfGettingWarehousingList}
    </Layout>
  );
}

export default withRequireAuth(ReturningWarehousing);
