import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";

import { SHIPPING_DELIVERY_TYPE } from "@sellernote/_shared/src/constants/fulfillment/shipping";
import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import SHIPPING_QUERY, {
  SHIPPING_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/fulfillment/SHIPPING_QUERY";
import { FULFILLMENT_COMMON_ATOMS } from "@sellernote/_shared/src/states/fulfillment/common";
import { CompletedPickingListItem } from "@sellernote/_shared/src/types/fulfillment/shipping";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import {
  getLocationBarcodeById,
  getWorkerNameByAuthority,
} from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { sortListByLocationIdAndSKUId } from "@sellernote/_shared/src/utils/fulfillment/shipping";
import { getStyledSKUCount } from "@sellernote/_shared/src/utils/fulfillment/sku";

import { FilterForIsMyPicking } from "pages/shipping/picking";

import { MyPickingTableItem } from "./useMyPickingTable";

export type CompletedPickingTableItem = Omit<
  MyPickingTableItem,
  "resetCount"
> & {
  personInCharge: string;
  endedAt: React.ReactNode;
};

const PAGE_UNIT = 10;

export default function useCompletedPickingList({
  filterForIsMyPicking,
}: {
  filterForIsMyPicking: FilterForIsMyPicking;
}) {
  const [currentPage, setCurrentPage] = useState(0);

  const locationListOfWarehouse = useRecoilValue(
    FULFILLMENT_COMMON_ATOMS.LOCATION_LIST_OF_WAREHOUSE
  );

  const queryClient = useQueryClient();

  const {
    data: completedPickingList,
    ResponseHandler: ResponseHandlerOfGettingCompletedPickingList,
  } = SHIPPING_QUERY.useGetCompletedPickingList({
    params: { page: currentPage, perPage: PAGE_UNIT },
    enabled: filterForIsMyPicking === "completed",
  });

  useEffect(() => {
    if (filterForIsMyPicking === "completed")
      queryClient.invalidateQueries(
        SHIPPING_QUERY_KEY_GEN.completedPickingList()
      );
  }, [filterForIsMyPicking, queryClient]);

  const getCompletedPickingTableRow = useCallback(
    (
      rowData: CompletedPickingListItem
    ): TableDataListItem<CompletedPickingTableItem>[] => {
      const sortedList = sortListByLocationIdAndSKUId(rowData.items);

      return sortedList.map((v, i) => {
        const firstRow = i === 0;

        return {
          rowClassName: firstRow ? "top-row" : undefined,

          rowKey: v.id,

          invoiceNo: firstRow ? rowData.invoiceNo : "",

          locationToPicking: getLocationBarcodeById({
            locationList: locationListOfWarehouse,
            locationId: v.locationId,
          }),

          skuId: getFormattedSingleSkuId(v.skuId),

          count: getStyledSKUCount({
            currentCount: v.quantity,
            goalCount: v.quantity,
          }),

          deliveryType: firstRow
            ? SHIPPING_DELIVERY_TYPE[rowData.deliveryType]
            : "",

          personInCharge: firstRow
            ? getWorkerNameByAuthority(rowData.picker)
            : "",

          endedAt: firstRow ? (
            <span>
              {toFormattedDate(rowData.endedPickingAt, "YYYYMMDD")}
              <br />
              {toFormattedDate(rowData.endedPickingAt, "HH:mm")}
            </span>
          ) : (
            ""
          ),
        };
      });
    },
    [locationListOfWarehouse]
  );

  const completedPickingTableList = useMemo(() => {
    const rawList = completedPickingList?.list;

    if (!rawList) {
      return [];
    }

    return rawList.flatMap((v) => getCompletedPickingTableRow(v));
  }, [completedPickingList?.list, getCompletedPickingTableRow]);

  return {
    completedPickingListTotal: completedPickingList?.total ?? 0,
    currentPage,
    setCurrentPage,
    completedPickingTableList,
    ResponseHandlerOfGettingCompletedPickingList,
  };
}
