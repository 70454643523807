import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

const modalBody = styled.div`
  > .list-item {
    .scanning,
    .mis-matched {
      color: ${COLOR.pointWarning};
    }

    .complete {
      color: ${COLOR.pointSuccess};
    }
  }

  > .button {
    margin-top: 40px;
  }
`;

export default {
  modalBody,
};
