import { Dispatch, SetStateAction } from "react";

import Barcode from "@sellernote/_shared/src/componentsToMoveToV1/Barcode";
import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";

import { ModalType } from "../useCountQuantityModal";
import useScanSku from "./useScanSku";
import Styled from "./index.styles";

function ResetBarcode({
  onTempQuantityReset,
}: {
  onTempQuantityReset: () => void;
}) {
  return (
    <Styled.resetBarcode>
      <Button
        theme="secondary"
        label="재스캔"
        size="normal"
        icon={{
          position: "right",
          type: "reset",
        }}
        handleClick={onTempQuantityReset}
      />
    </Styled.resetBarcode>
  );
}

export default function ScanSku({
  type,
  skuId,
  skuBarcode,
  availableQty,
  tempQuantity,
  setTempQuantity,
}: {
  type: ModalType;
  skuId: string;
  skuBarcode: string | undefined;
  availableQty: number;
  tempQuantity: number | undefined;
  setTempQuantity: Dispatch<SetStateAction<number | undefined>>;
}) {
  const { scanResult, handleTempQuantityReset, ValidationErrorToast } =
    useScanSku({
      type,
      skuId,
      skuBarcode,
      availableQty,
      tempQuantity,
      setTempQuantity,
    });

  return (
    <>
      <Styled.scanSku>
        <Styled.skuBarcode>
          {!!tempQuantity && !!scanResult && (
            <>
              <Barcode
                value={scanResult}
                options={{
                  displayValue: true,
                  margin: 0,
                }}
              />

              <ResetBarcode onTempQuantityReset={handleTempQuantityReset} />
            </>
          )}
        </Styled.skuBarcode>

        <InputText
          borderType="outline"
          valueType="int"
          label={
            <>
              가용수량{" "}
              <span className="available-qty">
                (현재: {toThousandUnitFormat(availableQty)})
              </span>
            </>
          }
          value={tempQuantity}
          disabledToFocus
          disabled={!!tempQuantity}
        />
      </Styled.scanSku>

      {ValidationErrorToast}
    </>
  );
}
