import { css, FlattenSimpleInterpolation } from "styled-components";

import Portal from "@sellernote/_shared/src/components/Portal";
import useToast, {
  ToastV2Props,
} from "@sellernote/_shared/src/headlessComponents/useToast";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";

import { COLOR } from "../../styles/colors";

import TextButton from "../button/TextButton";
import CheckBoxCircleSelectedFilledIcon from "../svgIcons/CheckBoxCircleSelectedFilledIcon";
import InfoFilledIcon from "../svgIcons/InfoFilledIcon";
import XMarkDefaultIcon from "../svgIcons/XMarkDefaultIcon";
import Styled from "./index.styles";

/**
 * <참고>
 * - 원래는 Snackbar라는 이름을 쓰고 있었으나, v1에서는 Toast고 v2에서는 Snackbar로 쓰고있어 괜한 네이밍 혼란을 만든다는 이슈가 있어
 * - `24.03.07`부터 `Toast`로 통일해서 사용중임. (상세 논의 내용: https://sellernotehq.slack.com/archives/C02L08CHM1Q/p1709776139330179)
 * - 관련하여 이 컴포넌트를 사용하는 로직등에 여전히 `Snackbar`라는 네이밍이 퍼져있으나, 이것들을 한 번에 수정하기가 범위가 너무 커서 컴포넌트 및 내부 코드 네이밍만 수정했음
 * - 새로 네이밍할때는 `Toast`로만 사용하고, 이 컴포넌트 바깥에 퍼진 `Snackbar`라는 네이밍은 점진적으로 수정될 예정임.
 */
export default function Toast({
  status,
  message,
  reset,
  className,
  ...propsByType
}: ToastV2Props) {
  const showsClearIcon =
    propsByType.uiType === "clearIcon" ||
    propsByType.uiType === "clearIconWithButton";

  // SDS V1(Toast)의 Headless Component를 같이 사용
  const { isVisibleToast, setIsVisibleToast } = useToast({
    reset,
    disablesAutoClose: showsClearIcon,
  });

  const { isMobile } = useCheckIsMobile();

  if (!isVisibleToast) return null;

  const portalSubContainerStyle: FlattenSimpleInterpolation = (() => {
    const commonStyle = css`
      // V1 모달이 99997이므로 99998로 설정
      z-index: 99998;
      position: fixed;
      transform: translateX(-50%);
      left: 50%;
      display: flex;
    `;

    return isMobile
      ? css`
          ${commonStyle}
          /* 모바일일때는 아래에서 위로 정렬한다 */
          flex-direction: column-reverse;
          top: auto;
          bottom: 72px;
        `
      : css`
          ${commonStyle}
          flex-direction: column;
          top: 72px;
        `;
  })();

  return (
    <Portal
      selector="#app-portal"
      /**
       * Toast가 여러 개 일때 정렬이되어야하므로, portal 하위의 sub container를 사용
       */
      subContainerInfo={{
        id: "sds-v2-toast-list-container",
        style: portalSubContainerStyle,
      }}
    >
      <Styled.toastContainer
        className={`${className ? className : ""} toast`}
        status={status}
      >
        {propsByType.uiType !== "onlyMessage" && (
          <Styled.iconContainer>
            {(status === "positive" || status === "informative") && (
              <CheckBoxCircleSelectedFilledIcon
                width={16}
                height={16}
                color={COLOR.wh}
              />
            )}
            {status === "negative" && (
              <InfoFilledIcon width={16} height={16} color={COLOR.wh} />
            )}
          </Styled.iconContainer>
        )}

        <Styled.messageContainer status={status}>
          {message}
        </Styled.messageContainer>

        <Styled.buttonContainer showsClearIcon={showsClearIcon}>
          {(propsByType.uiType === "button" ||
            propsByType.uiType === "clearIconWithButton") && (
            <TextButton
              theme={status === "neutral" ? "primary" : "white"}
              fontSize={14}
              label={propsByType.buttonInfo?.label || "버튼"}
              handleClick={() => {
                propsByType.buttonInfo?.onClick &&
                  propsByType.buttonInfo.onClick();
              }}
              className="toast-text-button"
            />
          )}

          {showsClearIcon && (
            <XMarkDefaultIcon
              width={16}
              height={16}
              color={status === "neutral" ? COLOR.grayScale_500 : COLOR.wh}
              onClick={() => {
                setIsVisibleToast(false);

                reset();
              }}
              className="toast-clear-icon"
            />
          )}
        </Styled.buttonContainer>
      </Styled.toastContainer>
    </Portal>
  );
}
