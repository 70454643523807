import { COLOR } from "../../stylesToMoveToV1/constants";

/**
 * 현재 수량과 목표 수량을 받아 스타일링된 SKU 수량을 반환합니다.
 *
 * @param currentCount - 현재 수량 (선택적)
 * @param goalCount - 목표 수량 (선택적)
 * @returns 스타일링된 SKU 수량을 포함하는 JSX 엘리먼트
 */
function getStyledSKUCount({
  currentCount,
  goalCount,
}: {
  currentCount?: number;
  goalCount?: number;
}) {
  if (!goalCount) return;

  const isCompleted = currentCount === goalCount;

  return (
    <div>
      {/* 스캔해야할 수량은 검은색, 스캔한 수량은 스캔해야할 수량과 다르면 빨강, 같으면 녹색 */}
      <span
        style={{ color: isCompleted ? COLOR.pointSuccess : COLOR.pointWarning }}
      >
        {currentCount || 0}
      </span>
      {" / "}
      <span>{goalCount}</span>
    </div>
  );
}

/**
 * SKUId에서 접두어 S(s)를 뺀 결과를 반환합니다.
 *
 * @param SKUId - 접두어를 제거할 SKU ID
 * @returns 접두어가 제거된 SKU ID
 */
function removeSKUIdPrefix(SKUId: string | number | undefined) {
  if (!SKUId) return SKUId;

  if (typeof SKUId === "number") return SKUId;

  return SKUId.replace(/^s/gi, "");
}

/**
 * 그룹상품의 SKU ID에서 접두어 G를 뺀 결과를 반환합니다.
 *
 * @param skuId - 접두어를 제거할 그룹상품 SKU ID
 * @returns 접두어가 제거된 그룹 SKU ID
 */
function removeGroupSkuIdPrefix(skuId: string | number | undefined) {
  if (!skuId) return skuId;

  if (typeof skuId === "number") return skuId;

  return skuId.replace(/^g/gi, "");
}

/**
 * 그룹상품의 상품명에서 접두어 [그룹]을 뺀 결과를 반환합니다. (그룹상품은 상품 생성 시 상품명 앞에 [그룹]을 붙임)
 *
 * @param groupItemName - 접두어를 제거할 그룹상품의 상품명
 * @returns 접두어가 제거된 그룹 상품명
 */
function removeGroupItemNamePrefix(groupItemName: string | undefined) {
  if (!groupItemName) return "";

  return groupItemName.replace("[그룹] ", "");
}

/**
 * 단일상품 등록 시 인보이스 금액이 소수점 둘째 자리까지 입력되었는지 확인합니다.
 *
 * @param value - 확인할 값
 * @returns 값이 유효한지 여부
 */
const checkSkuValue = (value: string | number | undefined) => {
  if (!value) {
    return true;
  }

  const regex = /^(\d{1,3}(,\d{3})*|\d+)(\.\d{0,2})?$/;

  return regex.test(value.toString());
};

export {
  getStyledSKUCount,
  removeSKUIdPrefix,
  removeGroupSkuIdPrefix,
  removeGroupItemNamePrefix,
  checkSkuValue,
};
