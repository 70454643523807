import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { TabFilterItem } from "@sellernote/_shared/src/headlessComponents/useTabFilter";
import {
  ShipmentDelayFilterType,
  ShipmentStatusType,
  ShipmentTodayAndOrderFilterType,
} from "@sellernote/_shared/src/types/fulfillment/shipping";

import withRequireAuth from "hocs/withRequireAuth";
import useShipmentClosingParcelDetail from "hooks/shipping/useShipmentClosingParcelDetail";
import ShippingShipmentClosingDetail, {
  ConditionalFilterType,
} from "pages/shipping/shipment/closing/ShipmentClosingDetail";

import ShipmentClosingLayout from "../../../ShipmentClosingLayout";

function getFilterList<T extends ShipmentStatusType>(
  status: T
): T extends "expected"
  ? TabFilterItem<ShipmentTodayAndOrderFilterType>[]
  : T extends "unprocessed"
  ? TabFilterItem<ShipmentDelayFilterType>[]
  : [];
function getFilterList(status: any): any {
  switch (status) {
    case "expected":
      return [
        {
          label: "전체",
          filter: "all",
        },
        {
          label: "금일 출하 요청 건",
          filter: "today",
        },
        {
          label: "이전 출하 건",
          filter: "overdue",
        },
      ];
    case "unprocessed":
      return [
        {
          label: "전체",
          filter: "all",
        },
        {
          label: "당일 지연",
          filter: "today",
        },
        {
          label: "1일 지연",
          filter: "oneday",
        },
        {
          label: "2일 이상 지연",
          filter: "twoday",
        },
      ];
    case "ready":
    case "done":
      return [
        {
          label: "전체",
          filter: "all",
        },
        {
          label: "금일 출하 요청 건",
          filter: "today",
        },
        {
          label: "이전 출하 요청 건",
          filter: "overdue",
        },
      ];
    case "completed":
      return [];
  }
}

function ShippingShipmentClosingParcelCompanyDetail() {
  const { status } = useParams<{
    status: ShipmentStatusType;
  }>();

  const [filterForList, setFilterForList] = useState(
    () => getFilterList(status)[0]?.filter
  );
  const [currentPage, setCurrentPage] = useState(0);
  const filterList = getFilterList(status);

  const handleFilterClick = useCallback(
    <Status extends ShipmentStatusType>(
      filter: ConditionalFilterType<Status>
    ) => {
      setCurrentPage(0);
      setFilterForList(filter);
    },
    []
  );

  const {
    titleValue,
    dataList,
    pageSize,

    ResponseHandlerOfGettingShipmentParcelToday,
    ResponseHandlerOfGettingShipmentParcelDone,
    ResponseHandlerOfGettingShipmentParcelDelay,
    ResponseHandlerOfGettingShipmentParcelOrder,
  } = useShipmentClosingParcelDetail({ filterForList, currentPage });

  return (
    <ShipmentClosingLayout title="택배" selectType="택배사">
      <ShippingShipmentClosingDetail<"parcel", typeof status>
        deliveryType="parcel"
        titleInfo={{
          label: status,
          value: titleValue,
        }}
        dataList={dataList}
        filter={{
          selectedFilter: filterForList,
          filterList,
          setFilter: handleFilterClick,
        }}
        paging={{
          pageSize,
          currentPage,
          setCurrentPage,
        }}
      />

      {ResponseHandlerOfGettingShipmentParcelToday}
      {ResponseHandlerOfGettingShipmentParcelDone}
      {ResponseHandlerOfGettingShipmentParcelDelay}
      {ResponseHandlerOfGettingShipmentParcelOrder}
    </ShipmentClosingLayout>
  );
}

export default withRequireAuth(ShippingShipmentClosingParcelCompanyDetail);
