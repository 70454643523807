import {
  ModalCommonPropsV1,
  ModalContentProps,
  ModalTitleOnlyProps,
} from "@sellernote/_shared/src/headlessComponents/useModal";
import { WmsStatus } from "@sellernote/_shared/src/types/fulfillment/fulfillment";

import { SKUInfoForCancelingRestocking } from "../../types/fulfillment/canceling";

import { getFormattedSingleSkuId } from "./fulfillment";

export type CancelingModalInfo = Pick<
  ModalCommonPropsV1 & (ModalTitleOnlyProps | ModalContentProps),
  "uiType" | "className" | "title" | "actionPositive" | "actionNegative"
> & { body?: React.ReactNode };

/**
 * 주어진 타입에 따라 취소 모달 정보를 반환합니다.
 *
 * @param type - 모달 타입을 나타내는 문자열
 * @param data - 모달에 표시할 추가 데이터 (선택 사항)
 * @returns CancelingModalInfo 객체 또는 undefined
 */
export function getCancelingModalInfo(type: string, data?: unknown) {
  const CancelingModalInfo = new Map<string, CancelingModalInfo>([
    [
      "noCancelingInvoiceNo",
      {
        uiType: "content",
        title: `${data}`,
        body: `주문취소된 송장(QR)번호가 아닙니다.`,
      },
    ],
    [
      "emptyRestockingList",
      {
        uiType: "content",
        title: `${data}`,
        body: `재입고할 내역이 없는 송장(QR)번호 입니다.`,
      },
    ],
    [
      "noMyCustomer",
      {
        uiType: "content",
        title: `${data}`,
        body: `본인 담당 고객이 아닌 송장(QR)번호 입니다.`,
      },
    ],

    [
      "locationScanFirst",
      {
        uiType: "titleOnly",
        title: `위치를 먼저 스캔해주세요.`,
      },
    ],
    [
      "noRestockingLocation",
      {
        uiType: "titleOnly",
        title: (
          <div>
            해당 위치({data})은 <br />
            상품을 재입고할 위치가 아닙니다.
          </div>
        ),
      },
    ],
    [
      "completedLocation",
      {
        uiType: "titleOnly",
        title: `해당 위치(${data})에서의 재입고는 이미 완료되었습니다.`,
      },
    ],
    [
      "correctSKUIdScan",
      {
        uiType: "titleOnly",
        title: `올바른 상품을 스캔해주세요.`,
      },
    ],
    [
      "noSKUInRestockingList",
      {
        uiType: "titleOnly",
        title: `${data}는 재입고 목록에 없습니다.`,
        className: `title-warning-modal`,
      },
    ],
    [
      "noSKUInWorkingLocation",
      {
        uiType: "titleOnly",
        title: `해당 위치(${data})에 없는 SKU ID입니다.`,
        className: `title-warning-modal`,
      },
    ],
    [
      "overQuantityScan",
      {
        uiType: "content",
        title: (
          <div>
            {data}
            <br />
            최대 스캔 가능 수량을 초과하였습니다.
          </div>
        ),
        body: `다시 확인해주세요.`,
      },
    ],
    [
      "completedRestockingBySKUId",
      {
        uiType: "content",
        title: `${data}(SKU ID)`,
        body: `재입고가 완료되었습니다.`,
      },
    ],
  ]);

  return CancelingModalInfo.get(type);
}

/**
 * 취소된 상태에 따른 WMS 상태를 반환합니다.
 *
 * @param wmsStatus - WMS 상태를 나타내는 문자열
 * @returns WMS 상태에 따른 한글 문자열 또는 빈 문자열
 */
export function getStatusWhenCanceled(wmsStatus?: WmsStatus) {
  if (!wmsStatus) return "";

  return wmsStatus === "picking"
    ? "피킹 중"
    : wmsStatus === "packing"
    ? "패킹 중"
    : "";
}

/**
 * 현재 작업 중인 위치에 해당하는 아이템 ID 목록을 반환
 *
 * @param restockingList - 재입고 목록 배열
 * @param workingLocationId - 현재 작업 중인 위치 ID
 * @returns 현재 작업 중인 위치에 해당하는 아이템 ID 목록
 */
export function getItemIdListByWorkingLocation({
  restockingList,
  workingLocationId,
}: {
  restockingList: SKUInfoForCancelingRestocking[];
  workingLocationId: number;
}) {
  return restockingList
    .filter((v) => v.locationId === workingLocationId)
    .map((v) => v.id);
}

/**
 * 스캔된 바코드와 위치 ID에 따른 재입고 아이템 ID를 반환합니다.
 *
 * @param scannedBarcode - 스캔된 바코드 문자열
 * @param locationId - 위치 ID
 * @param restockingList - 재입고 목록 배열
 * @returns 재입고 아이템 ID 또는 0
 */
export function getRestockingItemId({
  scannedBarcode,
  locationId,
  restockingList,
}: {
  scannedBarcode: string;
  locationId: number;
  restockingList: SKUInfoForCancelingRestocking[];
}) {
  return (
    restockingList.find(
      (item) =>
        (getFormattedSingleSkuId(item.skuId) === scannedBarcode ||
          item.skuBarcode === scannedBarcode) &&
        item.locationId === locationId
    )?.id ?? 0
  );
}

/**
 * 아이템 ID에 따른 재입고 SKU ID를 반환합니다.
 *
 * @param itemId - 아이템 ID
 * @param restockingList - 재입고 목록 배열
 * @returns 재입고 SKU ID 또는 0
 */
export function getRestockingSKUId({
  itemId,
  restockingList,
}: {
  itemId: number;
  restockingList: SKUInfoForCancelingRestocking[];
}) {
  return restockingList.find((item) => item.id === itemId)?.skuId ?? 0;
}
