import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { RETURNING_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { FulfillmentAttachment } from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import { handleFormChange } from "@sellernote/_shared/src/utils/common/etc";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";

import useUploadSelectPhotoList from "hooks/common/useUploadSelectPhotoList";
import useProblemItem from "../../../hooks/useProblemItem";

import useValid from "./useValid";
import Styled from "./index.styles";

export type Form = {
  problemId?: number;
  /** 문제 명 */
  problem: string;
  quantity?: number;
  attachments?: FulfillmentAttachment[];
};

export const initialFormState: Form = {
  problemId: undefined,
  problem: "",
  quantity: undefined,
};

export default function useProblemItemForm({
  returningId,
  skuId,
  itemId,
  newProblemId,
  inspectionCount,
  problemTotalQuantity,
  onSuccessForDeletePhoto,
}: {
  returningId: number;
  skuId: number;
  itemId: number;
  newProblemId: number;
  inspectionCount: number;
  problemTotalQuantity: number;
  onSuccessForDeletePhoto: () => void;
}) {
  const queryClient = useQueryClient();

  const [form, setForm] = useState(initialFormState);

  const [prevForm, setPrevForm] = useState(initialFormState);

  // 이미 등록한 비정상 상품에만 problemId 가 있다. (등록/수정인지 구별)
  const isRegistered = !!form.problemId;

  const {
    SelectPhotoList,
    uploadPhotoList,
    deletePhotoList,
    resetPhotoList,
    tempPhotoList,
    isChangedPhoto,
    LoadingToUpload,
    UploadFileListFailureModal,
  } = useUploadSelectPhotoList({
    type: "returning",
    id: returningId,
    targetId: isRegistered && form.problemId ? form.problemId : newProblemId,
    attachmentList: form.attachments ?? [],
    fileDomain: "abnormalSku",
  });

  const resetForm = () => {
    setForm(initialFormState);
    setPrevForm(initialFormState);
    resetPhotoList();
  };

  const { createProblemItem, ResponseHandlerOfCreateProblemItem } =
    useProblemItem({
      returningId,
      onSuccessForCreateProblemItem: () => {
        // 이미 업로드한 사진 중, 삭제한 사진이 있다면 수정 시, 일괄 삭제.
        deletePhotoList({
          onSuccess: () => {
            onSuccessForDeletePhoto();

            resetForm();

            queryClient.invalidateQueries(
              RETURNING_QUERY_KEY_GEN.getPDAReturningDetail({
                id: returningId,
              })
            );
          },
        });
      },
    });

  const handleProblemItemFormChange = handleFormChange(setForm);

  const {
    isValidUpdateForm,
    isValidForm,
    problemNameErrorMessage,
    quantityErrorMessage,
    isChangedForm,
  } = useValid({
    form,
    prevForm,
    inspectionCount,
    problemTotalQuantity,
    tempPhotoList,
    isChangedPhoto,
  });

  // newProblemId > 프론트에서 생성해서 최초 등록하는 ID, 등록 이후에는 data의 ID 로 지정됨.
  // 이미지 업로드 시, upload > S3 > binding > create 순으로 진행될 때, binding > create 과정에서 이미지와 상품을 매칭하기 위해 사용.
  // 최초 등록 이후에는 (수정 시에는) data 에 등록 되어있는 problemId 를 가져와서 쓴다.
  const submitForm = useCallback(() => {
    if (!form.quantity) return;

    if (!tempPhotoList.length) {
      return createProblemItem({
        problemId: form.problemId ?? newProblemId,
        skuId,
        quantity: form.quantity,
        problem: form.problem,
        itemId,
      });
    }

    uploadPhotoList({
      onUploadSuccess: () => {
        if (!form.quantity) return;

        createProblemItem({
          problemId: form.problemId ?? newProblemId,
          skuId,
          quantity: form.quantity,
          problem: form.problem,
          itemId,
        });
      },
    });
  }, [
    createProblemItem,
    form.problem,
    form.problemId,
    form.quantity,
    itemId,
    newProblemId,
    skuId,
    tempPhotoList.length,
    uploadPhotoList,
  ]);

  const updateFormInfo = (formInfo: Form) => {
    setForm(formInfo);
    setPrevForm(formInfo);
  };

  const ProblemItemForm = useMemo(() => {
    return (
      <>
        <Styled.form>
          <InputText
            label="문제 명"
            isRequired
            borderType="outline"
            valueType="string"
            value={form.problem}
            setValue={handleProblemItemFormChange("problem")}
            placeholder="입력해주세요."
            errorMessage={problemNameErrorMessage}
          />

          <InputText
            label="수량(PCS)"
            isRequired
            borderType="outline"
            valueType="int"
            value={form.quantity}
            setValue={handleProblemItemFormChange("quantity")}
            placeholder="입력해주세요."
            usesNumberAsText
            errorMessage={quantityErrorMessage}
          />

          {SelectPhotoList}

          <div className="button-container">
            <Button
              label="저장"
              size="normal"
              theme="primary"
              handleClick={submitForm}
              disabled={isRegistered ? !isValidUpdateForm : !isValidForm}
            />
          </div>
        </Styled.form>

        {LoadingToUpload}
        {UploadFileListFailureModal}

        {ResponseHandlerOfCreateProblemItem}
      </>
    );
  }, [
    LoadingToUpload,
    ResponseHandlerOfCreateProblemItem,
    SelectPhotoList,
    UploadFileListFailureModal,
    form.problem,
    form.quantity,
    handleProblemItemFormChange,
    isRegistered,
    isValidForm,
    isValidUpdateForm,
    problemNameErrorMessage,
    quantityErrorMessage,
    submitForm,
  ]);

  return {
    resetForm,
    updateFormInfo,
    isChangedForm,
    ProblemItemForm,
  };
}
