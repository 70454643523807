import { useCallback, useMemo } from "react";
import { useSetRecoilState } from "recoil";

import INVENTORY_QUERY from "@sellernote/_shared/src/queries/fulfillment/INVENTORY_QUERY";
import { FULFILLMENT_INVENTORY_ATOMS } from "@sellernote/_shared/src/states/fulfillment/inventory";

import { InventoryToMove } from "../useInventoryMovement";

export default function useCompleteMovement({
  existingLocationId,
  skuId,
  listOfInventoryToMove,
}: {
  existingLocationId: number;
  skuId: number;
  listOfInventoryToMove: InventoryToMove[];
}) {
  const setTempKey = useSetRecoilState(FULFILLMENT_INVENTORY_ATOMS.TEMP_KEY);

  const {
    mutate: completeInventoryMovement,
    ResponseHandler: ResponseHandlerOfCompletingInventoryMovement,
  } = INVENTORY_QUERY.useCompleteInventoryMovement();

  const isValidToMove = useMemo(
    () =>
      listOfInventoryToMove.every((item) => !!item.location && !!item.quantity),
    [listOfInventoryToMove]
  );

  const handleMovementComplete = useCallback(() => {
    completeInventoryMovement(
      {
        skuId,
        inventoryFrom: existingLocationId,
        inventoryTo: listOfInventoryToMove.map((item) => ({
          locationId: item.location?.id ?? 0,
          quantity: item.quantity,
        })),
      },

      {
        onSuccess: () => {
          setTempKey((prev) => prev + 1);
        },
      }
    );
  }, [
    completeInventoryMovement,
    existingLocationId,
    listOfInventoryToMove,
    setTempKey,
    skuId,
  ]);

  return {
    isValidToMove,
    handleMovementComplete,

    ResponseHandlerOfCompletingInventoryMovement,
  };
}
