import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { ScannedLocation } from "@sellernote/_shared/src/types/fulfillment/scan";
import { UseCounterDataValue } from "@sellernote/_shared/src/utils/common/hook";

import { WarehousingCounterSKU } from "hooks/receiving/useSKUCountingForWarehousing";

import useCompleteWarehousing from "./useCompleteWarehousing";

function CompleteWarehousing({
  locationType,
  receivingId,
  counterInProgress,
  selectedLocation,
  resetAfterComplete,
}: {
  locationType: "single" | "multi";
  receivingId: number;
  counterInProgress: UseCounterDataValue<WarehousingCounterSKU> | undefined;
  selectedLocation: ScannedLocation | undefined;
  resetAfterComplete: () => void;
}) {
  const {
    canComplete,
    handleWarehousingComplete,

    ConfirmModal,

    ResponseHandlerOfSetItemWarehousingDone,
  } = useCompleteWarehousing({
    locationType,
    receivingId,
    counterInProgress,
    selectedLocation,
    resetAfterComplete,
  });

  return (
    <>
      <Button
        className="btn-complete"
        label={locationType === "single" ? "입고 완료" : "분할입고 완료"}
        size="block"
        theme="primary"
        handleClick={handleWarehousingComplete}
        disabled={!canComplete}
      />

      {ConfirmModal}

      {ResponseHandlerOfSetItemWarehousingDone}
    </>
  );
}

export default CompleteWarehousing;
