import { useCallback } from "react";
import { useRecoilValue } from "recoil";

import SHIPPING_QUERY from "@sellernote/_shared/src/queries/fulfillment/SHIPPING_QUERY";
import { FULFILLMENT_SHIPPING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/shipping";
import { ScanType } from "@sellernote/_shared/src/types/fulfillment/scan";

import useScan from "hooks/common/useScan";

import usePickingMessageModal from "./usePickingMessageModal";

export default function useScanPickingInvoice({
  selectedScanButtonType,
  handleScanButtonClick,
}: {
  selectedScanButtonType: ScanType | undefined;
  handleScanButtonClick: (type: ScanType | undefined) => void;
}) {
  const { MessageModal, modalOpenHandler } = usePickingMessageModal();

  const isActiveInvoiceScanButton =
    useRecoilValue(FULFILLMENT_SHIPPING_ATOMS.SCANNED_PICKING_INVOICE)
      .shippingId === 0;

  const {
    mutate: scanPickingInvoice,
    ResponseHandler: ResponseHandlerOfScanningPickingInvoice,
  } = SHIPPING_QUERY.useScanPickingInvoice({
    type: "scan",
    openCanceledPickingInvoice: modalOpenHandler.openCanceledPickingInvoice,
    onScanButtonSelect: handleScanButtonClick,
  });

  const scanInvoice = useCallback(
    (scannedBarcode: string) => {
      if (selectedScanButtonType !== "shipping") {
        return;
      }

      scanPickingInvoice({ pathParams: { invoiceNo: scannedBarcode } });
    },
    [scanPickingInvoice, selectedScanButtonType]
  );
  useScan(scanInvoice);

  return {
    isActiveInvoiceScanButton,
    ScanInvoiceMessageModal: MessageModal,
    ResponseHandlerOfScanningPickingInvoice,
  };
}
