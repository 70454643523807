import { useState } from "react";
import { useLocation } from "react-router-dom";

import Toast from "@sellernote/_sds-v2/src/components/Toast";

import useScan from "hooks/common/useScan";

export default function useSearch({ type }: { type: "sku" | "location" }) {
  const [inputBarcode, setInputBarcode] = useState("");
  const [isErrorMessageActive, setIsErrorMessageActive] = useState(false);
  const [scannedBarcode, setScannedBarcode] = useState("");
  const [isScanButtonActive, setIsScanButtonActive] = useState(true);
  const [showsScanErrorToast, setShowScanErrorToast] = useState(false);

  const location = useLocation();
  const tab = new URLSearchParams(location.search).get("tab") || "sku";

  const isSearching = inputBarcode.length > 0;

  const isScanning = scannedBarcode.length > 0;

  const toggleScanButton = () => {
    setIsScanButtonActive((prev) => !prev);
  };

  const searchLocation = (barcode: string) => {
    // scannedBarcode를 우선값으로 사용하기 때문에 검색 시에는 scannedBarcode를 초기화해줘야 함
    setScannedBarcode("");

    setInputBarcode(barcode);
  };

  const resetSearch = () => {
    setInputBarcode("");
  };

  const showErrorMessage = () => {
    setIsErrorMessageActive(true);
  };

  const resetErrorMessage = () => {
    setIsErrorMessageActive(false);
  };

  const openScanErrorToast = () => {
    setShowScanErrorToast(true);
  };

  const closeScanErrorToast = () => {
    setShowScanErrorToast(false);
  };

  const scanBarcode = (barcode: string) => {
    // 현새 선택된 탭이 아닌 스캔은 무시
    if (tab !== type) {
      return;
    }

    if (!isScanButtonActive) {
      return;
    }

    setScannedBarcode(barcode);

    // 디자인 요구사항) 상품스캔 시 검색input은 default 상태입니다.
    setInputBarcode("");
    resetErrorMessage();
  };

  const ScanErrorToast = showsScanErrorToast && (
    <Toast
      uiType="onlyMessage"
      status="neutral"
      message="스캔한 바코드의 검색 결과가 없습니다."
      reset={closeScanErrorToast}
    />
  );

  useScan(scanBarcode);

  return {
    isSearching: isSearching || isScanning,

    inputBarcode,
    searchLocation,
    resetSearch,
    isErrorMessageActive,
    showErrorMessage,
    resetErrorMessage,

    scannedBarcode,
    isScanButtonActive,
    toggleScanButton,
    openScanErrorToast,
    ScanErrorToast,
  };
}
