import React, { useState } from "react";

import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import ConfirmWrapper from "@sellernote/_sds-v1/src/components/ConfirmWrapper";
import Toast from "@sellernote/_sds-v2/src/components/Toast";

export interface LayoutRefreshInfo {
  handleRefresh: (showSuccessMessage: () => void) => void;
  confirm?: {
    message: React.ReactNode;
  };
}

export default function Refresh({
  refreshInfo,
}: {
  refreshInfo: LayoutRefreshInfo;
}) {
  const [visibleToast, setVisibleToast] = useState(false);

  const Trigger = refreshInfo.confirm ? (
    <ConfirmWrapper
      confirmMessageTitle={refreshInfo.confirm.message}
      confirmModalUiType="titleOnly"
      customButtonLabel={{ positive: "확인", negative: "취소" }}
      className="confirm-refresh"
    >
      <Icon
        type="reset"
        size={2}
        color={COLOR.grayScale_700}
        onClick={handleClickRefresh}
      />
    </ConfirmWrapper>
  ) : (
    <Icon
      type="reset"
      size={2}
      color={COLOR.grayScale_700}
      onClick={handleClickRefresh}
    />
  );

  function handleClickRefresh() {
    refreshInfo.handleRefresh(() => setVisibleToast(true));
  }

  return (
    <>
      {Trigger}

      {visibleToast && (
        <Toast
          uiType="messageWithIcon"
          message={"새로고침 완료"}
          status="positive"
          reset={() => setVisibleToast(false)}
        />
      )}
    </>
  );
}
