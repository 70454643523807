import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.div`
  margin-top: 24px;
`;

const menuItem = styled.div<{ isActive?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${setFontStyle("SubHead2", "Bold")};

  color: ${COLOR.grayScale_800};
  padding: 16px 0 14px;
  background-color: ${(props) =>
    props.isActive ? COLOR.primaryBlue_10 : COLOR.white};
  border: 1px solid ${COLOR.grayScale_400};
  border-radius: 4px;

  &:focus,
  &:active {
    background-color: ${COLOR.primaryBlue_10};
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export default {
  container,
  menuItem,
};
