import SvgIconWrapper from "./SvgIconWrapper";

export default function ChevronLeftIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.5 37.5C24.8603 37.5 24.2203 37.2559 23.7328 36.7676L8.73281 21.7676C7.75625 20.791 7.75625 19.209 8.73281 18.2324L23.7328 3.23242C24.7094 2.25586 26.2914 2.25586 27.268 3.23242C28.2445 4.20898 28.2445 5.79102 27.268 6.76758L14.0352 20L27.2695 33.2344C28.2461 34.2109 28.2461 35.793 27.2695 36.7695C26.7812 37.2578 26.1406 37.5 25.5 37.5Z"
          fill="currentColor"
        />
      </svg>
    </SvgIconWrapper>
  );
}
