import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import RECEIVING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RECEIVING_QUERY";
import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { ReceivingListItem } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { isToday } from "@sellernote/_shared/src/utils/common/date";
import { getHighlightedLabel } from "@sellernote/_shared/src/utils/common/string";
import { getFormattedReceivingId } from "@sellernote/_shared/src/utils/fulfillment/receiving";
import { getStatusTitle } from "@sellernote/_shared/src/utils/fulfillment/receiving";

export type FilterForListForWarehousing = "unDone" | "done";

export type TableItemForWarehousing = {
  combinedTitle: React.ReactNode;
  placeQty?: React.ReactNode;
  SKU: number;
  PCS?: number;
  status?: React.ReactNode;
  bidId: React.ReactNode;
} & Pick<ReceivingListItem, "actualQty">;

export default function useFetchWarehousingList({
  perPage,
  currentPage,
  filter,
}: {
  perPage: number;
  currentPage: number;
  filter: FilterForListForWarehousing;
}) {
  const history = useHistory();

  const currentManagerAuthority = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  )?.authority;

  const {
    data: warehousingList,
    ResponseHandler: ResponseHandlerOfGettingWarehousingList,
  } = RECEIVING_QUERY.useGetManagerReceivingList({
    page: currentPage,
    perPage: perPage,
    mode: filter === "unDone" ? "putAway" : "done",
    isMine: true,
  });

  const handleUnDoneItemClick = useCallback(
    (id: number) => {
      history.push(`${history.location.pathname}/${id}`);
    },
    [history]
  );

  const tableDataListForWarehousing =
    useMemo((): TableDataListItem<TableItemForWarehousing>[] => {
      const rowList = warehousingList?.list;

      if (!rowList) {
        return [];
      }

      return rowList.map((v, i) => {
        // 권한이 매니저 이상인 사용자만 입고 상세에 접근할 수 있음
        const hasRightToWarehousing =
          currentManagerAuthority === "admin" ||
          currentManagerAuthority === "manager" ||
          currentManagerAuthority === "whMaster";

        const mapped: TableDataListItem<TableItemForWarehousing> = {
          rowKey: v.id,

          handleRowClick:
            filter === "unDone" && hasRightToWarehousing
              ? () => handleUnDoneItemClick(v.id)
              : undefined,

          rowFontColor: isToday(v.dueDate) ? COLOR.pointWarning : undefined,

          combinedTitle: `${v.team?.company ?? "-"} (${v.team?.name ?? "-"} / ${
            v.team?.id
          })`,

          bidId: getFormattedReceivingId(v),

          SKU: new Set(
            v.items
              .filter((v) => (v.actualQty ?? 0) > 0)
              .map(({ skuId }) => skuId)
          ).size,

          ...(filter === "unDone"
            ? {
                PCS: v.actualQty,
              }
            : {}),
          ...(filter === "done"
            ? {
                actualQty: v.actualQty,
                placeQty: getHighlightedLabel({
                  label: v.placeQty || 0,
                  isHighlighted: v.placeQty !== v.actualQty,
                }),
                status: getHighlightedLabel({
                  label: getStatusTitle(v.status),
                  isHighlighted: v.status !== "done",
                }),
              }
            : {}),
        };

        return mapped;
      });
    }, [
      warehousingList?.list,
      currentManagerAuthority,
      filter,
      handleUnDoneItemClick,
    ]);

  return {
    tableDataListForWarehousing,
    totalCountOfListOfWarehousing: warehousingList?.total ?? 0,
    anotherTotalCountOfListOfWarehousing: warehousingList?.anotherTotal ?? 0,
    ResponseHandlerOfGettingWarehousingList,
  };
}
