import styled from "styled-components";

const subRowIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    flex-shrink: 0;
  }
`;

export default {
  subRowIndicator,
};
