import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import Table from "@sellernote/_sds-v1/src/components/table/Table";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useCheckReturningInvoice, {
  CheckReturningTableItem,
} from "hooks/shipping/useCheckReturningInvoice";

import Styled from "./index.styles";

function ShippingShipmentPreparation() {
  const {
    checkReturningList,
    returningInvoiceNo,
    initList,
    ResponseHandlerOfCheckingReturningInvoice,
  } = useCheckReturningInvoice();

  return (
    <Layout
      navigator={{
        title: "출하준비",
      }}
    >
      <Styled.scanStatus>
        송장(QR)번호 스캔이 가능한 상태입니다.
      </Styled.scanStatus>

      <Table<CheckReturningTableItem>
        columnInfo={{
          invoiceNo: { label: "송장(QR)번호", fixedWidth: 200 },
          status: { label: "상태", fixedWidth: 120 },
        }}
        dataList={checkReturningList}
      />

      <Modal
        uiType="content"
        title={`${returningInvoiceNo} (송장/QR 번호)`}
        body={
          <div>
            반품신청이 된 의뢰입니다. <br />
            다른 상품들과 구분하고 반품을 진행해주세요.
          </div>
        }
        active={returningInvoiceNo !== ""}
        actionPositive={{
          label: "확인",
          handleClick: initList,
        }}
      />

      {ResponseHandlerOfCheckingReturningInvoice}
    </Layout>
  );
}

export default withRequireAuth(ShippingShipmentPreparation);
