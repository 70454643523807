import styled from "styled-components";

const container = styled.div`
  .modal {
    .body {
      .table {
        margin-bottom: 24px;

        table {
          width: 100%;
        }
      }

      .complete-button {
        margin-bottom: 28px;
      }
    }
  }
`;

export default {
  container,
};
