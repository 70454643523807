import styled from "styled-components";

const container = styled.section`
  > .tab-filter {
    margin-bottom: 40px;
  }
`;

export default {
  container,
};
