import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";
import { ReceivingWorker } from "@sellernote/_shared/src/types/fulfillment/receiving";

import { returningActions, returningSelectors } from "modules/returning";
import { useAppSelector } from "store";

export default function usePersonInChargeList({
  returningManagerId,
  workerList,
}: {
  returningManagerId: number | undefined;
  workerList: ReceivingWorker[] | undefined;
}) {
  const dispatch = useDispatch();
  const { currentUserIsReturningManager } = useAppSelector((state) => {
    return {
      currentUserIsReturningManager:
        returningSelectors.getCurrentUserIsReturningManager(state),
    };
  });
  const match = useRouteMatch<{ id: string }>();
  const {
    params: { id },
  } = match;

  const [personToConfirm, setPersonToConfirm] = useState<{
    id: string;
    name: string;
  }>();
  const [isFailedToRemove, setIsFailedToRemove] = useState(false);

  const {
    mutate: removeWorkerFromInspection,
    ResponseHandler: ResponseHandlerOfRemovingWorkerFromInspection,
  } = RETURNING_QUERY.useRemoveWorkerFromInspection({
    returningId: Number(id),
    workerId: Number(personToConfirm?.id) ?? 0,
  });

  const handlePersonToRemoveSelect = useCallback((person: ReceivingWorker) => {
    setPersonToConfirm(person);
  }, []);

  const handlePersonRemove = useCallback(() => {
    if (!personToConfirm) {
      return;
    }

    const workerIdToRemove = personToConfirm.id;

    dispatch(
      // 1) 담당자를 삭제 하기전에, 이 returning의 최신 담당자 정보를 확인
      returningActions.GET_DETAIL({
        id: Number(id),
        _postSuccessCallback: (res) => {
          const isTargetAssignedToActiveSKU = res.returning.items.some((v) => {
            // 완료되지 않은 SKU에 할당되있는 담당자는 삭제할 수 없다
            return (
              v.inspectorId === Number(workerIdToRemove) &&
              !v.isCompleteInspection
            );
          });

          if (isTargetAssignedToActiveSKU) {
            setIsFailedToRemove(true);
            return;
          }

          // 2) 담당자 삭제
          removeWorkerFromInspection(undefined, {
            onSuccess: () =>
              // 3) 담당자 삭제가 반영된 최신 정보 불러오기
              {
                dispatch(
                  returningActions.GET_DETAIL({
                    id: Number(id),
                    _postSuccessCallback: () => {
                      setPersonToConfirm(undefined);
                    },
                  })
                );
              },
          });
        },
      })
    );
  }, [dispatch, id, personToConfirm, removeWorkerFromInspection]);

  const chipDataList = useMemo(
    () =>
      (workerList ?? []).map((v) => {
        const isReturningManager = Number(v.id) === returningManagerId;
        const canRemove = currentUserIsReturningManager && !isReturningManager;

        return {
          id: v.id,
          label: v.name,
          handleClear: canRemove
            ? () => handlePersonToRemoveSelect(v)
            : undefined,
        };
      }),
    [
      currentUserIsReturningManager,
      handlePersonToRemoveSelect,
      returningManagerId,
      workerList,
    ]
  );

  const RemoveWorkerFromInspectionModal = useMemo(
    () => (
      <>
        <Modal
          uiType="titleOnly"
          active={!!personToConfirm}
          title={`정말로 ${personToConfirm?.name}(${personToConfirm?.id})을(를) 검수에서 제외시키겠습니까?`}
          actionPositive={{
            label: "확인",
            handleClick: handlePersonRemove,
          }}
          actionNegative={{
            label: "취소",
            handleClick: () => setPersonToConfirm(undefined),
          }}
        />

        <Modal
          uiType="titleOnly"
          active={isFailedToRemove}
          title={`선택한 담당자(${personToConfirm?.name})는 스캔검수 중입니다. 제외시킬 수 없습니다. `}
          actionPositive={{
            label: "확인",
            handleClick: () => {
              setIsFailedToRemove(false);
              setPersonToConfirm(undefined);
            },
          }}
        />
      </>
    ),
    [isFailedToRemove, handlePersonRemove, personToConfirm]
  );

  return {
    chipDataList,

    RemoveWorkerFromInspectionModal,

    ResponseHandlerOfRemovingWorkerFromInspection,
  };
}
