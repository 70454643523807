import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

import {
  GET_CANCELING_DETAIL_REQ,
  GET_CANCELING_REQ,
  GET_CANCELING_RES,
  REPORT_CANCELING_PROBLEM_REQ,
  REPORT_CANCELING_PROBLEM_RES,
  RESTOCK_CANCELING_INVOICE_REQ,
  SET_RESTOCK_BY_PICKING_QUANTITY_REQ,
  SET_RESTOCK_BY_PICKING_QUANTITY_RES,
} from "@sellernote/_shared/src/api-interfaces/boful-api/canceling";
import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "@sellernote/_shared/src/services/query";

import {
  CancelingDetailInfo,
  CancelingItem,
  RestockingItem,
} from "../../types/fulfillment/canceling";
import { getWorkerNameById } from "../../utils/fulfillment/common";

import { FULFILLMENT_CANCELING_ATOMS } from "../../states/fulfillment/canceling";
import { FULFILLMENT_COMMON_ATOMS } from "../../states/fulfillment/common";

export const CANCELING_QUERY_KEY_GEN = {
  all: () => [{ scope: "fulfillment/CANCELING_QUERY" }] as const,
  cancelingList: () =>
    [
      { ...CANCELING_QUERY_KEY_GEN.all()[0], subScope: "cancelingList" },
    ] as const,
  getCancelingList: (params: GET_CANCELING_REQ) =>
    [
      {
        ...CANCELING_QUERY_KEY_GEN.cancelingList()[0],
        ...params,
      },
    ] as const,
  cancelingDetail: () =>
    [
      { ...CANCELING_QUERY_KEY_GEN.all()[0], subScope: "cancelingDetail" },
    ] as const,
  getCancelingDetail: (params: GET_CANCELING_DETAIL_REQ) =>
    [
      {
        ...CANCELING_QUERY_KEY_GEN.cancelingDetail()[0],
        ...params,
      },
    ] as const,
};

function useGetCancelingList(params: GET_CANCELING_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof CANCELING_QUERY_KEY_GEN.getCancelingList>,
    GET_CANCELING_RES
  >({
    queryKey: CANCELING_QUERY_KEY_GEN.getCancelingList(params),
    requestOptions: {
      method: "get",
      path: `/canceling`,
      apiType: "BofulDefault",
      params,
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "취소관리 리스트 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useGetCancelingDetail(params: GET_CANCELING_DETAIL_REQ) {
  const setCancelingDetail = useSetRecoilState(
    FULFILLMENT_CANCELING_ATOMS.CANCELING_DETAIL
  );

  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof CANCELING_QUERY_KEY_GEN.getCancelingDetail>,
    CancelingDetailInfo
  >({
    queryKey: CANCELING_QUERY_KEY_GEN.getCancelingDetail(params),
    requestOptions: {
      method: "get",
      path: `/canceling/detail`,
      apiType: "BofulDefault",
      params,
    },

    onSuccess: (data) =>
      setCancelingDetail({
        cancelingData: data,
        cancelingId: data.id,
        cancelingInvoiceNo: data.shipping.invoiceNo,
      }),

    keepPreviousData: true,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "취소 정보 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useSetRestockByPickingQuantity() {
  const mutation = useAppMutation<
    SET_RESTOCK_BY_PICKING_QUANTITY_REQ,
    SET_RESTOCK_BY_PICKING_QUANTITY_RES
  >({
    requestOptions: {
      method: "patch",
      path: `/canceling/set/restock/{shippingId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "재입고 수량을 반영하는 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useAssignRestocker({
  cancelingId,
  setPackingRestocker,
}: {
  cancelingId: number;
  setPackingRestocker: React.Dispatch<React.SetStateAction<string>>;
}) {
  const workerList = useRecoilValue(FULFILLMENT_COMMON_ATOMS.WORKER_LIST);

  const mutation = useAppMutation<unknown, CancelingItem>({
    requestOptions: {
      method: "patch",
      path: `/canceling/restocker/${cancelingId}`,
      apiType: "BofulDefault",
    },

    onSuccess: ({ restockerId }) => {
      setPackingRestocker(getWorkerNameById(workerList, restockerId));
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "재입고 담당자를 지정하는 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useRestockCancelingInvoice({
  cancelingId,
  moveToCancelingListPage,
  ...argsByType
}: {
  cancelingId: number;
  moveToCancelingListPage: () => void;
} & (
  | {
      type: "complete";
    }
  | {
      // 문제보고 시에도 재입고 마감 처리를 해야함
      type: "report";
      handleReportProblemModalClose: () => void;
    }
)) {
  const setCanNotLeavePage = useSetRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );
  const { cancelingInvoiceNo } = useRecoilValue(
    FULFILLMENT_CANCELING_ATOMS.CANCELING_DETAIL
  );
  const resetCancelingDetail = useResetRecoilState(
    FULFILLMENT_CANCELING_ATOMS.CANCELING_DETAIL
  );

  const isComplete = argsByType.type === "complete";

  const handleRestockingSuccess = () => {
    setCanNotLeavePage(false);
    moveToCancelingListPage();
    resetCancelingDetail();
  };

  const mutation = useAppMutation<RESTOCK_CANCELING_INVOICE_REQ, CancelingItem>(
    {
      requestOptions: {
        method: "patch",
        path: `/canceling/restocking/${cancelingId}`,
        apiType: "BofulDefault",
      },

      successModalInfo: isComplete
        ? {
            handleConfirmSuccess: (initQuery) => {
              initQuery();

              handleRestockingSuccess();
            },
            customizeMessage: () => ({
              messageType: "titleAndBody",
              title: `${cancelingInvoiceNo} (송장(QR)번호)`,
              body: `재입고가 완료되었습니다.`,
            }),
          }
        : {
            handleConfirmSuccess: (initQuery) => {
              initQuery();

              handleRestockingSuccess();
              argsByType.type === "report" &&
                argsByType.handleReportProblemModalClose();
            },
            customizeMessage: () => ({
              messageType: "titleOnly",
              title: `문제보고가 처리되었습니다.`,
            }),
          },

      failureModalInfo: {
        customizeMessage: () => ({
          title: `${
            isComplete ? "재입고 마감" : "문제보고"
          } 중에 오류가 발생했습니다.
          `,
        }),
      },
    }
  );

  return { ...mutation };
}

function useReportCancelingProblem({
  cancelingId,
  restockItems,
  moveToCancelingListPage,
  handleReportProblemModalClose,
}: {
  cancelingId: number;
  restockItems: RestockingItem[];
  moveToCancelingListPage: () => void;
  handleReportProblemModalClose: () => void;
}) {
  const {
    mutate: restockCancelingInvoice,
    ResponseHandler: ResponseHandlerOfRestockingCancelingInvoice,
  } = CANCELING_QUERY.useRestockCancelingInvoice({
    type: "report",
    cancelingId,
    moveToCancelingListPage,
    handleReportProblemModalClose,
  });

  const mutation = useAppMutation<
    REPORT_CANCELING_PROBLEM_REQ,
    REPORT_CANCELING_PROBLEM_RES
  >({
    requestOptions: {
      method: "patch",
      path: `/canceling/problem/${cancelingId}`,
      apiType: "BofulDefault",
    },

    onSuccess: () =>
      restockCancelingInvoice({
        restockItems,
      }),

    failureModalInfo: {
      customizeMessage: () => ({
        title: "문제보고 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation, ResponseHandlerOfRestockingCancelingInvoice };
}

const CANCELING_QUERY = {
  useGetCancelingList,
  useGetCancelingDetail,

  useSetRestockByPickingQuantity,
  useAssignRestocker,

  useRestockCancelingInvoice,
  useReportCancelingProblem,
};
export default CANCELING_QUERY;
