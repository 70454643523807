import { Dispatch, SetStateAction } from "react";

import Barcode from "@sellernote/_shared/src/componentsToMoveToV1/Barcode";
import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";

import SelectManagementDate from "../SelectManagementDate";
import SelectSkuInput from "../SelectSkuInput";
import { SkuToSelect } from "../useSelectSkuModal";
import useScanSku from "./useScanSku";
import Styled from "./index.styles";

function ResetBarcode({ onTempSkuReset }: { onTempSkuReset: () => void }) {
  return (
    <Styled.resetBarcode>
      <Button
        theme="secondary"
        label="재스캔"
        size="normal"
        icon={{
          position: "right",
          type: "reset",
        }}
        handleClick={onTempSkuReset}
      />
    </Styled.resetBarcode>
  );
}

export default function ScanSku({
  tempSku,
  setTempSku,
  setSelectedSku,
  onSelectSkuModalClose,
}: {
  tempSku: SkuToSelect | undefined;
  setTempSku: Dispatch<SetStateAction<SkuToSelect | undefined>>;
  setSelectedSku: Dispatch<SetStateAction<SkuToSelect | undefined>>;
  onSelectSkuModalClose: () => void;
}) {
  const {
    scanResult,

    duplicateSkuList,
    handleSkuSelect,
    handleTempSkuReset,

    handleSelectSkuModalConfirm,

    ValidationErrorToast,

    isValid,
  } = useScanSku({
    tempSku,
    setTempSku,
    setSelectedSku,
    onSelectSkuModalClose,
  });

  return (
    <>
      <Styled.scanSku>
        <Styled.skuBarcode>
          {!!tempSku && !!scanResult && (
            <>
              <Barcode
                value={scanResult}
                options={{
                  displayValue: true,
                  margin: 0,
                }}
              />

              <ResetBarcode onTempSkuReset={handleTempSkuReset} />
            </>
          )}
        </Styled.skuBarcode>

        <SelectSkuInput
          type="scan"
          selectedSku={tempSku}
          optionList={duplicateSkuList}
          onSkuSelect={handleSkuSelect}
          onReset={handleTempSkuReset}
          disabledToFocus
          disabled={!!tempSku}
        />
      </Styled.scanSku>

      {tempSku?.managementKind && (
        <SelectManagementDate
          tempSku={tempSku}
          setTempSku={setTempSku}
          managementKind={tempSku.managementKind}
        />
      )}

      <Button
        theme="primary"
        size="normal"
        label="확인"
        disabled={!isValid}
        handleClick={handleSelectSkuModalConfirm}
      />

      {ValidationErrorToast}
    </>
  );
}
