import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.div`
  margin-top: 16px;

  > .title {
    ${setFontStyle("SubHead2", "Bold")};
    color: ${COLOR.primaryBlue};
    padding: 0 8px 8px;
    border-bottom: 1px solid ${COLOR.primaryBlue_40};
  }
`;

const thumbnailList = styled.div`
  margin: -8px;
  padding-top: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  align-content: flex-start;
`;

const thumbnail = styled.div<{ imageUrl: string }>`
  width: 45%;
  height: 100px;
  margin: 8px;
  border: 1px solid ${COLOR.grayScale_400};
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  background-position: center;
`;

export default {
  container,
  thumbnailList,
  thumbnail,
};
