import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import {
  FileDomainType,
  FulfillmentAttachment,
} from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import { ReceivingInvoiceNo } from "@sellernote/_shared/src/types/fulfillment/receiving";
import { omitWithEllipsis } from "@sellernote/_shared/src/utils/common/string";
import { getTeamLabelForBofulWorker } from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedReceivingId } from "@sellernote/_shared/src/utils/fulfillment/receiving";
import { getPictureTypeLabelByDomainType } from "@sellernote/_shared/src/utils/fulfillment/receiving";
import ConfirmWrapper from "@sellernote/_sds-v1/src/components/ConfirmWrapper";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useGetReceivingDetail from "hooks/receiving/useGetReceivingDetail";
import usePicturePanelForConfirm from "hooks/receiving/usePicturePanelForConfirm";
import useResetInvoiceInfo from "hooks/receiving/useResetInvoiceInfo";
import useScanInvoicePanelForConfirm from "hooks/receiving/useScanInvoicePanelForConfirm";

import Styled from "./index.styles";

function ItemToScan({
  order,
  domainType,
  invoiceNoList,
  attachments,
  receivingId,
}: {
  order: number;
  domainType: FileDomainType;
  invoiceNoList: ReceivingInvoiceNo[];
  attachments: FulfillmentAttachment[];
  receivingId: number;
}) {
  const label = useMemo(
    () => getPictureTypeLabelByDomainType(domainType),
    [domainType]
  );

  const { showPicturePanel, PicturePanel, ResultHandlerOfPicturePanel } =
    usePicturePanelForConfirm({ label, domainType, receivingId });

  const {
    showScanInvoicePanel,
    ScanInvoicePanel,
    ResponseHandlerOfSetInvoiceScanDone,
  } = useScanInvoicePanelForConfirm({
    receivingId,
    invoiceNoList,
    showPicturePanel,
  });

  const {
    needToResetInvoiceInfo,
    ResponseHandlerOfResetInvoiceInfo,
    resetInvoiceInfo,
  } = useResetInvoiceInfo({ receivingId, invoiceNoList, attachments });

  return (
    <>
      {needToResetInvoiceInfo ? (
        <ConfirmWrapper
          confirmMessageTitle={"송장스캔"}
          confirmMessageBody={
            <>
              이미 스캔한 정보가 있습니다.
              <br />
              스캔을 다시하면 기존 정보가 삭제됩니다.
              <br />
              다시 스캔하시겠습니까?
            </>
          }
          confirmModalUiType="content"
          className="reset-confirm"
        >
          <Button
            theme="secondary"
            size="block"
            label={`${order}. ${label}`}
            handleClick={resetInvoiceInfo({
              callbackAfterReset: showScanInvoicePanel,
            })}
          />
        </ConfirmWrapper>
      ) : (
        <Button
          theme="secondary"
          size="block"
          label={`${order}. ${label}`}
          handleClick={showScanInvoicePanel}
        />
      )}

      {ScanInvoicePanel}

      {PicturePanel}

      {ResultHandlerOfPicturePanel}

      {ResponseHandlerOfSetInvoiceScanDone}

      {ResponseHandlerOfResetInvoiceInfo}
    </>
  );
}

function ItemToTakePicture({
  order,
  domainType,
  receivingId,
}: {
  order: number;
  domainType: FileDomainType;
  receivingId: number;
}) {
  const label = useMemo(() => {
    return getPictureTypeLabelByDomainType(domainType);
  }, [domainType]);

  const { showPicturePanel, PicturePanel, ResultHandlerOfPicturePanel } =
    usePicturePanelForConfirm({ label, domainType, receivingId });

  return (
    <>
      <Button
        theme="secondary"
        size="block"
        label={`${order}. ${label}`}
        handleClick={showPicturePanel}
      />

      {PicturePanel}

      {ResultHandlerOfPicturePanel}
    </>
  );
}

function PictureType() {
  const match = useRouteMatch<{ id: string }>();
  const {
    params: { id },
  } = match;

  const {
    data: managerReceivingDetail,
    ResponseHandler: ResponseHandlerOfGettingManagerReceivingDetail,
  } = useGetReceivingDetail({
    receivingId: Number(id),
  });

  const PictureItemList = useMemo(() => {
    let order = 1;

    if (!managerReceivingDetail?.receiving) return null;

    const { receivingKind, delivery, invoiceNo } =
      managerReceivingDetail.receiving;

    if (
      receivingKind === "import" ||
      (receivingKind === "domestic" && delivery === "truck")
    ) {
      return (
        <>
          <ItemToTakePicture
            receivingId={Number(id)}
            order={order++}
            domainType={"packing"}
          />
          <ItemToTakePicture
            receivingId={Number(id)}
            order={order++}
            domainType={"damages"}
          />
        </>
      );
    } else {
      return (
        <>
          {invoiceNo && (
            <ItemToScan
              order={order++}
              domainType={"invoice"}
              invoiceNoList={invoiceNo ?? []}
              attachments={managerReceivingDetail.attachment ?? []}
              receivingId={Number(id)}
            />
          )}

          <ItemToTakePicture
            receivingId={Number(id)}
            order={order++}
            domainType={"packing"}
          />
          <ItemToTakePicture
            receivingId={Number(id)}
            order={order++}
            domainType={"damages"}
          />
        </>
      );
    }
  }, [managerReceivingDetail, id]);

  return (
    <>
      <Layout
        navigator={{
          title: `${getFormattedReceivingId(
            managerReceivingDetail?.receiving
          )} / ${getTeamLabelForBofulWorker({
            id: managerReceivingDetail?.receiving.team?.id,
            name: omitWithEllipsis({
              src: managerReceivingDetail?.receiving.team?.name,
              maxLength: 10,
              ellipsis: "...",
            }),
            company: omitWithEllipsis({
              src: managerReceivingDetail?.receiving.team?.company,
              maxLength: 10,
              ellipsis: "...",
            }),
          })}`,
        }}
      >
        <Styled.container>
          <div className="title">촬영할 내용을 선택해주세요.</div>

          {PictureItemList}
        </Styled.container>

        {ResponseHandlerOfGettingManagerReceivingDetail}
      </Layout>
    </>
  );
}

export default withRequireAuth(PictureType);
