// 어플리케이션의 Redux Store와 연결된 hook을 정의 (그 외의 공용 hook은 _shared에 정의)

import React, { useState } from "react";
import { useRef } from "react";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { FileMimeType } from "@sellernote/_shared/src/types/common/common";

export type FileInfo = {
  fileName: string;
  fileMimeType: FileMimeType;
  base64: string;
  fileInfo: File;
};

/**
 * 앱의 '사진 촬영 or 사진 선택' 기능을 호출할 트리거가 될 React Element를 전달해주면,
 * 해당 Element를 Trigger로 만든 결과와 촬영/선택된 이미지 결과 데이터(base64), 이밎 결과 데이터 reset함수를 반환한다.
 */
export default function useCameraOrGallery({
  displayStyle = "inline",
  triggerComponent,
  onFileLoaded,
  isGalleryView,
  isMultiple,
}: {
  displayStyle?: "inline" | "block";
  triggerComponent: React.ReactElement;
  onFileLoaded: (fileInfoList: FileInfo[]) => void;
  /** 사진을 촬영할 것인지/갤러리뷰에서 선택할 것인지 (촬영은 가능한 기기에서만 동작하고, 불가능한 기기에서는 갤러리뷰로 보여짐)*/
  isGalleryView?: boolean;
  /** 파일 다중 선택 가능 여부 */
  isMultiple?: boolean;
}) {
  const [opensErrorModal, setOpensErrorModal] = useState(false);

  const inputFileRef = useRef<HTMLInputElement>(null);

  const TriggerForCameraOrGallery = (
    <div
      className="trigger-wrapper"
      style={{
        display: displayStyle === "block" ? "flex" : "inline-flex",
      }}
      onClick={() => {
        inputFileRef.current?.click();
      }}
    >
      {triggerComponent}

      <input
        style={{ display: "none" }}
        ref={inputFileRef}
        type="file"
        name="file-upload"
        id="fileUpload"
        accept="image/*"
        capture={isGalleryView ? undefined : "environment"}
        multiple={isMultiple}
        onChange={(e) => {
          e.preventDefault();

          if (!e.target.files) return;

          const fileList = Array.from(e.target.files);

          const fileInfoPromises = fileList.map((file) => {
            const reader = new FileReader();

            return new Promise<FileInfo>((resolve) => {
              reader.onload = () => {
                const fileInfo = {
                  fileName: file.name,
                  fileMimeType: file.type as FileMimeType,
                  base64: reader.result as string,
                  fileInfo: file,
                };

                resolve(fileInfo);
              };

              reader.readAsDataURL(file);
            });
          });

          Promise.all(fileInfoPromises)
            .then((fileInfoList) => {
              onFileLoaded(fileInfoList as FileInfo[]);
            })
            .catch(() => {
              setOpensErrorModal(true);
            });
        }}
      />

      <Modal
        uiType="titleOnly"
        active={opensErrorModal}
        title={`파일에 오류가 있습니다. 다시 선택해주세요.`}
        actionPositive={{
          label: "확인",
          handleClick: () => {
            setOpensErrorModal(false);
          },
        }}
      />
    </div>
  );

  return [TriggerForCameraOrGallery] as const;
}
