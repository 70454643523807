import styled from "styled-components";

import { hideScrollBar } from "@sellernote/_shared/src/styles/functions";

const container = styled.div`
  .list-item {
    margin-bottom: 16px;
  }

  .tab-filter {
    margin-bottom: 24px;

    ${hideScrollBar()};
  }

  .paging {
    margin-bottom: 64px;
  }
`;

export default {
  container,
};
