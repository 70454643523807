import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { SET_ITEM_WAREHOUSING_DONE_BY_FORCE_REQ_PATH_PARAMS } from "@sellernote/_shared/src/api-interfaces/boful-api/returning";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";

import { returningActions } from "modules/returning";

export default function useCompleteWarehousingByForce() {
  const dispatch = useDispatch();

  const {
    mutate: setItemWarehousingDoneByForce,
    ResponseHandler: ResponseHandlerOfCompletingWarehousingByForce,
  } = RETURNING_QUERY.useSetItemWarehousingDoneByForce();

  const completeWarehousingByForce = useCallback(
    ({
        returningId,
        itemId,
        resetAfterCompleteWarehousingByForce,
      }: SET_ITEM_WAREHOUSING_DONE_BY_FORCE_REQ_PATH_PARAMS & {
        resetAfterCompleteWarehousingByForce: () => void;
      }) =>
      () => {
        setItemWarehousingDoneByForce(
          {
            pathParams: { returningId, itemId },
          },
          {
            onSuccess: () => {
              dispatch(
                returningActions.GET_DETAIL({
                  id: returningId,
                  _postSuccessCallback: resetAfterCompleteWarehousingByForce,
                })
              );
            },
          }
        );
      },
    [dispatch, setItemWarehousingDoneByForce]
  );

  return {
    completeWarehousingByForce,
    ResponseHandlerOfCompletingWarehousingByForce,
  };
}
