import { BofulAuthority } from "@sellernote/_shared/src/types/fulfillment/auth";

/**
 * 사용자의 권한에 따라 적절한 actor kind를 반환합니다.
 *
 * @param authority - 사용자의 권한 (BofulAuthority 타입)
 * @returns 주어진 권한에 해당하는 actor kind 문자열
 */
export function getActorKindByUserAuthority(authority: BofulAuthority) {
  switch (authority) {
    case "admin": {
      return "warehouseAdmin";
    }
    case "manager": {
      return "warehouseManager";
    }
    case "whMaster": {
      return "warehouseAdmin";
    }
    case "partTimer": {
      return "warehouseWorker";
    }
  }
}

/**
 * base64 문자열을 파일 객체로 변환합니다.
 *
 * @param imageURL - base64 형식의 이미지 URL 문자열
 * @param fileName - 생성될 파일의 이름
 * @returns 변환된 File 객체
 *
 * @remarks
 * 이 함수는 브라우저 환경에서만 동작합니다. Node.js 환경에서는 사용할 수 없습니다.
 */
export function getFileFromBase64(imageURL: string, fileName: string) {
  const arr = imageURL.split(",");
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
}
