import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";

import useResetStateWithTempKey from "../../useResetStateWithTempKey";
import ExistingLocation from "../ExistingLocation";
import CompleteAdjustment from "./CompleteAdjustment";
import QuantityAfterAdjustment from "./QuantityAfterAdjustment";
import SkuDetail from "./SkuDetail";
import useInventoryAdjustment from "./useInventoryAdjustment";

function InventoryMovementAdjustmentWithKey() {
  const {
    existingLocation,
    setExistingLocation,

    isEmptyLocation,

    selectedSku,
    setSelectedSku,

    quantityToAdjust,
    setQuantityToAdjust,

    inventoryDetailByLocation,

    ResponseHandlerOfGettingInventoryDetailByLocation,
  } = useInventoryAdjustment();
  return (
    <>
      <ExistingLocation
        location={existingLocation?.barCode}
        setExistingLocation={setExistingLocation}
      />

      {!!existingLocation && (
        <>
          <SkuDetail
            skuId={inventoryDetailByLocation?.skuId}
            skuBarcode={inventoryDetailByLocation?.sku?.barCode}
            managementKind={inventoryDetailByLocation?.sku?.managementKind}
            managementDate={inventoryDetailByLocation?.managementDate}
            availableQty={inventoryDetailByLocation?.availableQty}
            isEmptyLocation={isEmptyLocation}
            selectedSku={selectedSku}
            setSelectedSku={setSelectedSku}
          />

          {!isEmptyLocation && !!inventoryDetailByLocation ? (
            <QuantityAfterAdjustment
              skuId={inventoryDetailByLocation.sku.id}
              skuBarcode={inventoryDetailByLocation.sku.barCode}
              availableQty={inventoryDetailByLocation.availableQty}
              quantityToAdjust={quantityToAdjust}
              setQuantityToAdjust={setQuantityToAdjust}
            />
          ) : (
            // 전산재고는 없지만 실재고를 반영하고자 하는 경우에는 SKU ID를 선택한 후에 조정 후 수량을 입력 가능하도록 한다.
            selectedSku && (
              <QuantityAfterAdjustment
                skuId={selectedSku.id}
                skuBarcode={selectedSku.barCode}
                availableQty={0}
                quantityToAdjust={quantityToAdjust}
                setQuantityToAdjust={setQuantityToAdjust}
              />
            )
          )}

          <CompleteAdjustment
            isEmptyLocation={isEmptyLocation}
            locationId={existingLocation.id}
            skuId={inventoryDetailByLocation?.skuId ?? selectedSku?.id}
            quantity={quantityToAdjust}
            managementDate={
              inventoryDetailByLocation?.managementDate ??
              selectedSku?.managementDate
            }
          />
        </>
      )}

      {ResponseHandlerOfGettingInventoryDetailByLocation}
    </>
  );
}

function InventoryMovementAdjustment() {
  const tempKey = useResetStateWithTempKey();

  return (
    <Layout
      navigator={{
        title: "재고 조정",
      }}
    >
      <InventoryMovementAdjustmentWithKey key={tempKey} />
    </Layout>
  );
}

export default withRequireAuth(InventoryMovementAdjustment);
