import { useState } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { SKUInfoForCancelingRestocking } from "@sellernote/_shared/src/types/fulfillment/canceling";

import useReportCancelingProblem from "hooks/canceling/useReportCancelingProblem";

import ReportProblemModal from "./ReportProblemModal";

export default function ReportProblemButton({
  SKUInfoForScanning,
  moveToCancelingListPage,
}: {
  SKUInfoForScanning: SKUInfoForCancelingRestocking[];
  moveToCancelingListPage: () => void;
}) {
  const [visibleReportProblemModal, setVisibleReportProblemModal] =
    useState(false);

  const { reportingList } = useReportCancelingProblem({ SKUInfoForScanning });

  return (
    <>
      <Button
        theme="primary"
        size="block"
        label="재입고 마감"
        handleClick={() => setVisibleReportProblemModal(true)}
      />

      {visibleReportProblemModal && (
        <ReportProblemModal
          SKUInfoForScanning={SKUInfoForScanning}
          reportingList={reportingList}
          moveToCancelingListPage={moveToCancelingListPage}
          handleReportProblemModalClose={() =>
            setVisibleReportProblemModal(false)
          }
        />
      )}
    </>
  );
}
