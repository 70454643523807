import { useCallback, useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_RECEIVING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/receiving";
import { getManagementDateLabel } from "@sellernote/_shared/src/utils/fulfillment/common";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import {
  checkConfirmedAsSingleLocationInspection,
  checkHasOnlyInitialInspection,
} from "@sellernote/_shared/src/utils/fulfillment/receiving";

import Layout from "containers/Layout";
import withRequireAuth from "hocs/withRequireAuth";
import useConfirmModal from "hooks/common/useConfirmModal";
import useGetReceivingDetail from "hooks/receiving/useGetReceivingDetail";
import useScanInspectionSKU from "hooks/receiving/useScanInspectionSKU";
import useSKUCountingForInspection from "hooks/receiving/useSKUCountingForInspection";

import AddPersonInCharge from "../../AddPersonInCharge";
import CompleteInspection from "../../CompleteInspection";
import PersonInChargeList from "../../PersonInChargeList";
import AddPartialInspection from "./AddPartialInspection";
import PartedList from "./PartedList";
import Styled from "./index.styles";

function Guide({ skuId, quantity }: { skuId: number; quantity?: number }) {
  return (
    <Styled.guide>
      <div className="title">‣ 분할검수할 내용을 추가해주세요.</div>

      <div className="desc">
        [검수 정보]
        <br />
        ・SKU ID : {getFormattedSingleSkuId(skuId)}
        <br />
        ・총 수량 : {quantity}
      </div>
    </Styled.guide>
  );
}

function TotalCount({ counted, max }: { counted: number; max: number }) {
  return (
    <Styled.totalCount>
      <div className="label">총 카운트 :</div>
      <div className="value">
        {counted} / {max}
      </div>
    </Styled.totalCount>
  );
}

function MultiLocation() {
  const match = useRouteMatch<{ id: string; itemId: string }>();
  const {
    params: { id, itemId },
  } = match;

  const [rowInfoToHighlight, setRowInfoToHighlight] = useState<
    TableRowInfoToHighlight | undefined
  >(undefined);
  const [inspectingIdInProgress, setInspectingIdInProgress] = useState("");

  const isCurrentManagerReceivingManager = useRecoilValue(
    FULFILLMENT_RECEIVING_SELECTORS.IS_CURRENT_MANAGER_RECEIVING_MANAGER
  );
  const skuItems = useRecoilValue(FULFILLMENT_RECEIVING_SELECTORS.SKU_ITEMS);

  const {
    data: receivingDetail,
    ResponseHandler: ResponseHandlerOfGettingManagerReceivingDetail,
  } = useGetReceivingDetail({ receivingId: Number(id) });

  const { ConfirmModal, setConfirmModal } = useConfirmModal();

  const skuCounting = useSKUCountingForInspection({ items: skuItems });

  const { ResultHandlerOfScanSKU } = useScanInspectionSKU({
    scanType: "multi",
    itemId: Number(itemId),
    inspectingId: inspectingIdInProgress,
    skuCounting,
    receivingId: Number(id),
    setRowInfoToHighlight,
    startInspectionAt: receivingDetail?.receiving.startInspectionAt,
  });

  const receivingItem = useMemo(() => {
    if (!skuItems) return;

    return skuItems.find((v) => v.id === Number(itemId));
  }, [skuItems, itemId]);

  const confirmedAsSingleLocationInspection = useMemo(() => {
    return checkConfirmedAsSingleLocationInspection(receivingItem);
  }, [receivingItem]);

  const totalInspectedQty = useMemo(() => {
    if (!receivingItem?.inspectItems) return 0;

    return receivingItem.inspectItems.reduce((a, c) => {
      return a + (c.actualQty ?? 0);
    }, 0);
  }, [receivingItem]);

  const hasOnlyInitialInspection = useMemo(() => {
    return checkHasOnlyInitialInspection(receivingItem);
  }, [receivingItem]);

  const receivingItemCompleted = useMemo(() => {
    const allInspectionItemsAreSplit =
      receivingItem?.quantity ===
      receivingItem?.inspectItems.reduce((a, c) => {
        return a + (c.quantity ?? 0);
      }, 0);

    const allInspectionItemsAreCompleted = receivingItem?.inspectItems.every(
      (ii) => ii.isCompleteInspecting
    );

    return allInspectionItemsAreSplit && allInspectionItemsAreCompleted;
  }, [receivingItem?.inspectItems, receivingItem?.quantity]);

  const confirmMessageToLeave = useMemo(() => {
    //  분할검수를 시작했는데, 모든 분할검수를 완료하지 않았을 경우 confirm 메시지를 띄움.
    if (
      !hasOnlyInitialInspection &&
      !confirmedAsSingleLocationInspection &&
      !receivingItemCompleted
    ) {
      return {
        title: (
          <>
            {getFormattedSingleSkuId(receivingItem?.skuId)}(SKU ID)
            {receivingItem?.managementDate && (
              <>
                <br />
                {getManagementDateLabel(
                  receivingItem.sku.managementKind,
                  receivingItem.managementDate
                )}
              </>
            )}
          </>
        ),
        body: (
          <>
            검수가 완료되지 않았습니다.
            <br />
            분할검수를 그만하겠습니까?
          </>
        ),
      };
    }
  }, [
    hasOnlyInitialInspection,
    confirmedAsSingleLocationInspection,
    receivingItemCompleted,
    receivingItem?.skuId,
    receivingItem?.managementDate,
    receivingItem?.sku.managementKind,
  ]);

  const resetCountByCounterKey = useCallback(
    (counterKey: string) => () => {
      setConfirmModal(undefined);

      skuCounting.counter.resetCountById(counterKey);
      skuCounting.setSkuInProgress(undefined);
      setRowInfoToHighlight(undefined);
    },
    [setConfirmModal, skuCounting]
  );

  const resetAfterComplete = useCallback(() => {
    skuCounting.setSkuInProgress(undefined);
    setRowInfoToHighlight(undefined);
    setInspectingIdInProgress("");
  }, [skuCounting]);

  if (!receivingItem) return null;

  return (
    <Layout
      navigator={{
        title: (
          <>
            분할검수(ID:{getFormattedSingleSkuId(receivingItem.skuId)})
            {receivingItem.managementDate && (
              <>
                <br />
                {getManagementDateLabel(
                  receivingItem.sku.managementKind,
                  receivingItem.managementDate
                )}
              </>
            )}
            <br />
            {receivingItem.sku.barCode ? `(${receivingItem.sku.barCode})` : ""}
          </>
        ),
      }}
      confirmMessageToLeave={confirmMessageToLeave}
    >
      <Styled.container>
        {ConfirmModal}

        <Styled.header hasOnlyInitialInspection={hasOnlyInitialInspection}>
          <AddPersonInCharge
            isManager={isCurrentManagerReceivingManager}
            workerList={receivingDetail?.receiving.workerList}
          />

          <Button
            label="상품스캔"
            size="small"
            theme="tertiary"
            handleClick={() => {}}
          />
        </Styled.header>

        {hasOnlyInitialInspection ? (
          <Guide
            skuId={receivingItem.skuId}
            quantity={receivingItem.quantity}
          />
        ) : (
          <>
            <PersonInChargeList
              receivingManagerId={receivingDetail?.receiving.managerId}
              workerList={receivingDetail?.receiving.workerList}
            />
            <PartedList
              receivingId={Number(id)}
              itemId={receivingItem.id}
              skuId={receivingItem.skuId}
              inspectItems={receivingItem.inspectItems}
              rowInfoToHighlight={rowInfoToHighlight}
              setRowInfoToHighlight={setRowInfoToHighlight}
              inspectingIdInProgress={inspectingIdInProgress}
              setInspectingIdInProgress={setInspectingIdInProgress}
              skuCounting={skuCounting}
              counterData={skuCounting.counter.counterInfo}
              maxCount={receivingItem.quantity}
              addCountByInput={skuCounting.counter.addCountByInput}
              resetCountByCounterKey={resetCountByCounterKey}
              setConfirmModal={setConfirmModal}
              managementDate={receivingItem.managementDate}
              managementKind={receivingItem.sku.managementKind}
            />
          </>
        )}

        <TotalCount counted={totalInspectedQty} max={receivingItem.quantity} />

        <AddPartialInspection
          receivingId={Number(id)}
          receivingItem={receivingItem}
        />

        <CompleteInspection
          locationType="multi"
          receivingId={Number(id)}
          counterInProgress={
            skuCounting.skuInProgress
              ? skuCounting.counter.counterInfo[
                  skuCounting.skuInProgress.counterKey
                ]
              : undefined
          }
          resetAfterComplete={resetAfterComplete}
        />
      </Styled.container>

      {ResponseHandlerOfGettingManagerReceivingDetail}
      {ResultHandlerOfScanSKU}
    </Layout>
  );
}

export default withRequireAuth(MultiLocation);
