import SvgIconWrapper from "./SvgIconWrapper";

export default function ExpandArrowMoreFilledIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_917_7652)">
          <path
            d="M30.8391 19.3391L21.9024 28.3391C21.4031 28.782 20.8266 29 20.25 29C19.6735 29 19.0983 28.7803 18.6588 28.3408L9.72213 19.3408C9.0158 18.6992 8.82312 17.7289 9.17145 16.8922C9.51977 16.0555 10.3409 15.5 11.25 15.5H29.1867C30.0966 15.5 30.9178 16.0471 31.2666 16.8887C31.6153 17.7302 31.486 18.6992 30.8391 19.3391Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_917_7652">
            <rect
              width="22.5"
              height="36"
              fill="white"
              transform="translate(9 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}
