import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";

import { getFormattedReturningId } from "@sellernote/_shared/src/utils/fulfillment/returning";

import withRequireAuth from "hocs/withRequireAuth";
import useFetchDetailForWarehousing from "hooks/returning/useFetchDetailForWarehousing";

import SelectManagementDate from "./SelectManagementDate";
import Warehousing from "./Warehousing";

function ReturningWarehousingDetail() {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const { returningDetailData, SKUItems, ResponseHandlerOfDetail } =
    useFetchDetailForWarehousing({
      returningId: Number(id),
    });

  /**
   * 관리일자를 사용하는 sku임에도 managementDate가 없는 itemList.
   * - 입고처리 전에 관리일자를 선택해줘야 한다.
   * - (ex 불일치 상품이 관리일자가 있는 sku로 매칭되는 경우, 해당 item이 어떤 관리일자인지 정해줘야한다)
   */
  const undecidedItemList = useMemo(() => {
    if (!SKUItems) return;

    return SKUItems.filter((item) => {
      if (item.sku.managementKind && !item.managementDate) {
        return true;
      }

      return false;
    });
  }, [SKUItems]);

  const needsSelectManagementDate = (undecidedItemList || []).length > 0;

  return (
    <>
      {returningDetailData &&
        (needsSelectManagementDate ? (
          <SelectManagementDate
            returningId={Number(id)}
            formattedReturningId={getFormattedReturningId(
              returningDetailData?.returning
            )}
            itemList={undecidedItemList}
          />
        ) : (
          <Warehousing returningId={Number(id)} />
        ))}

      {ResponseHandlerOfDetail}
    </>
  );
}

export default withRequireAuth(ReturningWarehousingDetail);
