import React, { ReactNode } from "react";
import { useRouteMatch } from "react-router-dom";

import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import {
  ListWithMoreResultComponent,
  ListWithMoreResultProps,
} from "@sellernote/_shared/src/headlessComponents/useListWithMoreResult";
import {
  ModalCommonPropsV1,
  ModalContentProps,
  ModalFooterActions,
  ModalTitleOnlyProps,
} from "@sellernote/_shared/src/headlessComponents/useModal";
import {
  PickingSKUInfoForScanning,
  ShipmentParcelSummary,
  ShipmentSummary,
  ShippingDeliveringStatus,
  ShippingDeliveryType,
  ShippingItem,
  ShippingOrderItem,
  ShippingStatus,
  ShippingTruckType,
} from "@sellernote/_shared/src/types/fulfillment/shipping";

import {
  ClassifiedGroupItem,
  CommonTruckType,
  GroupDataByType,
} from "../../types/fulfillment/common";
import {
  FulfillmentParcelCompany,
  FulfillmentTrackingLevel,
  FulfillmentTruckCompany,
} from "../../types/fulfillment/fulfillment";
import { ProductGroup } from "../../types/fulfillment/sku";

import {
  getDetailSkuIdList,
  getFilteredItemsByQuantity,
  getGroupDataByType,
  getGroupItems,
  getGroupItemsV2,
  getGroupItemsWithTotalQuantity,
  getGroupItemsWithTotalQuantityV2,
  getItemsWithoutGroupItemsQuantity,
  mergeItemsBySkuId,
} from "./common";
import {
  getFormattedGroupSkuId,
  getFormattedSingleSkuId,
  toParcelCompanyLabel,
  toTruckTypeLabel,
} from "./fulfillment";

/**
 * 현재 작업 중인 위치에 해당하는 아이템 ID 목록을 반환
 *
 * @param pickingList - 피킹 목록
 * @param workingLocationId - 현재 작업 중인 위치 ID
 * @returns 현재 작업 중인 위치에 해당하는 아이템 ID 목록
 */
export function getItemIdListByWorkingLocation({
  pickingList,
  workingLocationId,
}: {
  pickingList: PickingSKUInfoForScanning[];
  workingLocationId: number;
}) {
  return pickingList
    .filter((v) => v.locationId === workingLocationId)
    .map((v) => v.id);
}

export type PickingModalInfo = Pick<
  ModalCommonPropsV1 & (ModalTitleOnlyProps | ModalContentProps),
  "uiType" | "className" | "title" | "actionPositive" | "actionNegative"
> & { body?: React.ReactNode };

/**
 * 피킹 모달 정보를 반환
 *
 * @param type - 모달 타입
 * @param data - 모달에 표시할 데이터
 * @param action - 모달의 액션 정보
 * @returns 피킹 모달 정보
 */
export function getPickingModalInfo(
  type: string,
  data?: ReactNode,
  action?: Pick<ModalFooterActions, "actionPositive" | "actionNegative">
) {
  const pickingModalInfo = new Map<string, PickingModalInfo>([
    [
      "locationScanFirst",
      {
        uiType: "titleOnly",
        title: `위치를 먼저 스캔해주세요.`,
      },
    ],
    [
      "noLocationInInvoice",
      {
        uiType: "titleOnly",
        title: `해당 위치(${data})에 피킹할 목록이 없습니다.`,
        className: `title-warning-modal`,
      },
    ],
    [
      "noSKUInPickingList",
      {
        uiType: "titleOnly",
        title: `${data}은(는) 피킹목록에 없습니다.`,
        className: `title-warning-modal`,
      },
    ],
    [
      "noSKUInCurrentLocation",
      {
        uiType: "titleOnly",
        title: `해당 위치(${data})에 없는 상품입니다.`,
        className: `title-warning-modal`,
      },
    ],

    [
      "correctInvoicedScan",
      {
        uiType: "titleOnly",
        title: `올바른 송장(QR)번호를 스캔해주세요.`,
      },
    ],
    [
      "correctLocationScan",
      {
        uiType: "titleOnly",
        title: `올바른 위치를 스캔해주세요.`,
      },
    ],
    [
      "correctSKUIdScan",
      {
        uiType: "titleOnly",
        title: `올바른 상품을 스캔해주세요.`,
      },
    ],
    [
      "overQuantityScan",
      {
        uiType: "content",
        title: (
          <>
            {data}
            <br />
            최대 스캔 가능 수량을 초과하였습니다.
          </>
        ),
        body: `다시 확인해주세요.`,
      },
    ],
    [
      "completedLocation",
      {
        uiType: "titleOnly",
        title: `해당 위치(${data})에서의 피킹은 이미 완료되었습니다.`,
      },
    ],
    [
      "completedPickingBySKUId",
      {
        uiType: "content",
        title: `${data}(SKU ID)`,
        body: `피킹이 완료되었습니다.`,
      },
    ],
    [
      "completedPickingInvoice",
      {
        uiType: "content",
        title: `${data}(송장번호)`,
        body: `피킹이 완료되었습니다.`,
      },
    ],

    [
      "canceledPickingInvoice",
      {
        uiType: "titleOnly",
        title: `본 운송 건은 취소되었습니다.`,
        actionPositive: action?.actionPositive,
        className: `title-warning-modal`,
      },
    ],

    [
      "canceledInvoiceInPicking",
      {
        uiType: "content",
        title: (
          <>
            고객사의 요청에 의해
            <br />
            <strong className="warning-message">주문이 취소</strong>되었습니다.
          </>
        ),
        body: "재입고를 진행해주세요.",
        actionPositive: action?.actionPositive,
      },
    ],
  ]);

  return pickingModalInfo.get(type);
}

/**
 * @deprecated 해당 함숙를 사용하는 페이지는 더 이상 사용하지 않습니다.
 *
 * 출하 요약 정보를 반환하는 훅
 *
 * @param data - 출하 요약 데이터
 * @param ListWithMoreResult - 리스트 컴포넌트
 * @returns 출하 요약 정보 컴포넌트
 */
export function useShipmentSummary<T extends ShippingDeliveryType>(
  data: T extends "parcel"
    ? ShipmentParcelSummary | undefined
    : ShipmentSummary | undefined,
  ListWithMoreResult: ListWithMoreResultComponent
) {
  const { url } = useRouteMatch();

  /**
   * 당일 출하 예정 건 요약 데이터를 반환
   *
   * @param url - 현재 URL
   * @param data - 출하 요약 데이터
   * @returns 당일 출하 예정 건 요약 데이터
   */
  const getTodayExpectedShipmentSummaryData = ({
    url,
    data,
  }: {
    url: string;
    data: T extends "parcel"
      ? ShipmentParcelSummary | undefined
      : ShipmentSummary | undefined;
  }): ListWithMoreResultProps => ({
    title: {
      label: "당일 출하 예정 건",
      value: (data?.todayRequest || 0) + (data?.overdueRequest || 0),
      path: `${url}/expected`,
    },
    list: [
      {
        label: "금일 출하 요청 건",
        value: data?.todayRequest || 0,
      },
      {
        label: "이전 출하 요청 건",
        value: data?.overdueRequest || 0,
      },
    ],
  });

  /**
   * 현재 마감 건 요약 데이터를 반환
   *
   * @param url - 현재 URL
   * @param data - 출하 요약 데이터
   * @returns 현재 마감 건 요약 데이터
   */
  const getCompletedShipmentSummaryData = ({
    url,
    data,
  }: {
    url: string;
    data: T extends "parcel"
      ? ShipmentParcelSummary | undefined
      : ShipmentSummary | undefined;
  }): ListWithMoreResultProps => ({
    title: {
      label: "현재 마감 건",
      value: `${data?.todayDone || 0} / ${
        (data?.todayRequest || 0) + (data?.overdueRequest || 0)
      }`,
      path: `${url}/completed`,
    },
  });

  /**
   * 현재 미처리 건 요약 데이터를 반환
   *
   * @param url - 현재 URL
   * @param data - 출하 요약 데이터
   * @returns 현재 미처리 건 요약 데이터
   */
  const getUnprocessedShipmentSummaryData = ({
    url,
    data,
  }: {
    url: string;
    data: T extends "parcel"
      ? ShipmentParcelSummary | undefined
      : ShipmentSummary | undefined;
  }): ListWithMoreResultProps => ({
    title: {
      label: "현재 미처리 건",
      value:
        (data?.todayDelay || 0) +
        (data?.onedayDelay || 0) +
        (data?.twodayDelay || 0),
      path: `${url}/unprocessed`,
    },
    list: [
      {
        label: "당일 지연",
        value: data?.todayDelay || 0,
      },
      {
        label: "1일 지연",
        value: data?.onedayDelay || 0,
      },
      {
        label: "2일 이상 지연",
        value: data?.twodayDelay || 0,
      },
    ],
  });

  const todayExpectedShipmentSummaryData = getTodayExpectedShipmentSummaryData({
    url,
    data,
  });
  const completedShipmentSummaryData = getCompletedShipmentSummaryData({
    url,
    data,
  });
  const unprocessedShipmentSummaryData = getUnprocessedShipmentSummaryData({
    url,
    data,
  });

  const TodayExpectedShipmentSummary = (
    <ListWithMoreResult
      title={todayExpectedShipmentSummaryData.title}
      list={todayExpectedShipmentSummaryData.list}
    />
  );

  const CompletedShipmentSummary = (
    <ListWithMoreResult title={completedShipmentSummaryData.title} />
  );

  const UnprocessedShipmentSummary = (
    <ListWithMoreResult
      title={unprocessedShipmentSummaryData.title}
      list={unprocessedShipmentSummaryData.list}
    />
  );

  return {
    TodayExpectedShipmentSummary,
    CompletedShipmentSummary,
    UnprocessedShipmentSummary,
  };
}

export const shipmentStatus = new Map([
  ["waiting", "송장출력전"],
  ["picking", "피킹 중"],
  ["packing", "패킹 중"],
  ["ready", "출하준비"],
  ["done", "출하완료"],
]);

/**
 * 아이템을 locationId 및 skuId 기준으로 정렬합니다.
 *
 * @param list - 정렬할 리스트
 * @returns 정렬된 리스트
 */
export function sortListByLocationIdAndSKUId<
  T extends ShippingItem | PickingSKUInfoForScanning
>(list?: readonly T[]): T[] {
  return [...(list || [])].sort(
    (a, b) => a.locationId - b.locationId || a.skuId - b.skuId
  );
}

/**
 * 배송 단계 레벨에 따른 라벨 반환
 * '수거 준비 중': 1(배송준비중)
 * '택배사 전달': 2(집화완료),
 * '배송 중': 3(배송중), 4(지점 도착), 5(배송출발)
 * '배송 완료': 6(배송완료)
 * 0(오류): CJ에서 택배 업무를 수행하지 못하는 경우입니다.
 *
 * @param level - 배송 단계 레벨
 * @returns 배송 단계 라벨
 */
export function toShippingLevelLabel(level: FulfillmentTrackingLevel) {
  switch (level) {
    case 1: {
      return "수거 준비 중";
    }
    case 2: {
      return "택배사 전달";
    }
    case 3:
    case 4:
    case 5: {
      return "배송 중";
    }
    case 6: {
      return "배송 완료";
    }
    default: {
      return "";
    }
  }
}

/**
 * 배송 상태에 따른 라벨 반환
 *
 * @param status - 배송 상태
 * @returns 배송 상태 라벨
 */
export function toShippingDeliveringStatusLabel(
  status: ShippingDeliveringStatus
) {
  switch (status) {
    case "notSent": {
      return "배송 전";
    }
    case "delivering": {
      return "배송 중";
    }
    case "done": {
      return "배송 완료";
    }
    default: {
      return "";
    }
  }
}

export const TRUCK_COMPANY_OPTION_LIST: InputSelectOption<FulfillmentTruckCompany>[] =
  [
    { label: "가나물류", value: "gana" },
    { label: "고고밴", value: "gogoVan" },
    { label: "Sandy", value: "sandy" },
    { label: "해피로지스틱스", value: "happyLogistics" },
  ];

export const TRUCK_TYPE_OPTION_LIST: InputSelectOption<ShippingTruckType>[] = [
  { label: "오토바이", value: "motorcycle" },
  { label: "다마스", value: "damas" },
  { label: "라보", value: "labo" },
  { label: "벤", value: "van" },
  { label: "1톤 트럭", value: "1ton" },
  { label: "1톤 윙바디 트럭", value: "1ton_wingbody" },
  { label: "1.2톤&1.4톤 트럭", value: "ton1_2_and_ton1_4" },
  { label: "2.5톤&3.5톤 트럭", value: "ton2_5_and_ton3_5" },
  { label: "2.5톤&3.5톤 윙바디 트럭", value: "ton2_5_and_ton3_5_wingbody" },
  { label: "5톤 트럭", value: "5ton" },
  { label: "5톤 축차", value: "5ton_rotor" },
  { label: "5톤 윙바디 트럭", value: "5ton_wingbody" },
  { label: "7.5톤 트럭", value: "ton7_5" },
  { label: "11톤 트럭", value: "11ton" },
  { label: "14톤 트럭", value: "14ton" },
  { label: "25톤 트럭", value: "25ton" },
];

/**
 * 스캔된 바코드와 위치 ID를 통해 피킹 아이템 ID를 반환
 *
 * @param scannedBarcode - 스캔된 바코드
 * @param locationId - 위치 ID
 * @param pickingList - 피킹 목록
 * @returns 피킹 아이템 ID
 */
export function getPickingItemId({
  scannedBarcode,
  locationId,
  pickingList,
}: {
  scannedBarcode: string;
  locationId: number;
  pickingList: PickingSKUInfoForScanning[];
}) {
  return (
    pickingList.find(
      (item) =>
        (getFormattedSingleSkuId(item.skuId) === scannedBarcode ||
          item.skuBarcode === scannedBarcode) &&
        item.locationId === locationId
    )?.id ?? 0
  );
}

/**
 * 아이템 ID를 통해 피킹 SKU ID를 반환
 *
 * @param itemId - 아이템 ID
 * @param pickingList - 피킹 목록
 * @returns 피킹 SKU ID
 */
export function getPickingSKUId({
  itemId,
  pickingList,
}: {
  itemId: number;
  pickingList: PickingSKUInfoForScanning[];
}) {
  return pickingList.find((item) => item.id === itemId)?.skuId ?? 0;
}

/**
 * items와 그룹상품의 정보를 통해 items에서 단일상품만 필터링
 * - needsToMerge
 *   - location 별로 아이템이 나뉘어져 있는 경우 SKU ID를 기준으로 아이템을 합치는 작업이 필요
 *   - 다음의 두 개의 API에서는 location이 다르더라도 SKU ID별로 이미 묶여 있음
 *     - [GET] /shipping/admin/new/:id, [GET] /shipper/:id
 *
 * @param items - 아이템 목록
 * @param needsToMerge - SKU ID 기준으로 아이템을 합칠 필요 여부
 * @param groupDataByType - 그룹상품의 데이터 타입
 * @returns 단일상품 목록
 */
const getSingleShippingItems = ({
  items,
  needsToMerge = false,
  ...groupDataByType
}: {
  items: ShippingItem[] | undefined;
  needsToMerge?: boolean;
} & GroupDataByType) => {
  const groupItemsWithTotalQuantity =
    getGroupItemsWithTotalQuantity(groupDataByType);
  const detailSkuIdList = getDetailSkuIdList(groupItemsWithTotalQuantity);

  const mergedItems = needsToMerge ? mergeItemsBySkuId(items) : items;

  // items에서 그룹상품의 수량을 빼고 quantity가 남는 item은 단일상품
  return getFilteredItemsByQuantity(
    getItemsWithoutGroupItemsQuantity({
      items: mergedItems,
      detailSkuIdList,
      groupItemsWithTotalQuantity,
    })
  );
};

/**
 * TODO: V2는 백엔드 개선으로 인해 변경된 API만 적용됨
 * TODO: 반품까지 백엔드 개선이 완료될 경우 V2 문구를 삭제하고 기존 함수, 컴포넌트를 삭제
 * items와 그룹상품의 정보를 통해 items에서 단일상품만 필터링
 * - needsToMerge
 *   - location 별로 아이템이 나뉘어져 있는 경우 SKU ID를 기준으로 아이템을 합치는 작업이 필요
 *   - 다음의 두 개의 API에서는 location이 다르더라도 SKU ID별로 이미 묶여 있음
 *     - [GET] /shipping/admin/new/:id, [GET] /shipper/:id
 *
 * @param items - 아이템 목록
 * @param groupList - 그룹상품 목록
 * @param needsToMerge - SKU ID 기준으로 아이템을 합칠 필요 여부
 * @returns 단일상품 목록
 */
const getSingleShippingItemsV2 = ({
  items,
  groupList,
  needsToMerge = false,
}: {
  items: ShippingItem[] | undefined;
  groupList: ProductGroup[] | undefined;
  needsToMerge?: boolean;
}) => {
  const groupItemsWithTotalQuantity =
    getGroupItemsWithTotalQuantityV2(groupList);
  const detailSkuIdList = getDetailSkuIdList(groupItemsWithTotalQuantity);

  const mergedItems = needsToMerge ? mergeItemsBySkuId(items) : items;

  // items에서 그룹상품의 수량을 빼고 quantity가 남는 item은 단일상품
  return getFilteredItemsByQuantity(
    getItemsWithoutGroupItemsQuantity({
      items: mergedItems,
      detailSkuIdList,
      groupItemsWithTotalQuantity,
    })
  );
};

/**
 * 출고관리에서 items를 [그룹상품, 단일상품] 형태로 분류하기 위한 함수
 *
 * @param items - 아이템 목록
 * @param needsToMerge - SKU ID 기준으로 아이템을 합칠 필요 여부
 * @param groupDataByType - 그룹상품의 데이터 타입
 * @returns 분류된 아이템 목록
 */
const classifyShippingItems = ({
  items,
  needsToMerge = false,
  ...groupDataByType
}: {
  items: ShippingItem[] | undefined;
  needsToMerge?: boolean;
} & GroupDataByType) => {
  const groupData = getGroupDataByType(groupDataByType);

  const hasGroupItems = groupData.length > 0;
  if (!hasGroupItems) {
    return needsToMerge ? mergeItemsBySkuId(items) : items ?? [];
  }

  const groupItems = getGroupItems(groupDataByType);
  const singleItems = getSingleShippingItems({
    items,
    needsToMerge,
    ...groupDataByType,
  });

  return [...groupItems, ...singleItems];
};

/**
 * TODO: V2는 백엔드 개선으로 인해 변경된 API만 적용됨
 * TODO: 반품까지 백엔드 개선이 완료될 경우 V2 문구를 삭제하고 기존 함수, 컴포넌트를 삭제
 * 출고관리에서 items를 [그룹상품, 단일상품] 형태로 분류하기 위한 함수
 *
 * @param items - 출고 아이템 목록
 * @param groupList - 그룹상품 목록
 * @param needsToMerge - SKU ID 기준으로 아이템을 합칠 필요 여부
 * @returns 분류된 아이템 목록
 */
const classifyShippingItemsV2 = ({
  items,
  groupList,
  // /shipper/:id API에서는 SKU 기준으로 이미 묶어서 반환하고 있기 때문에 별도의 묶음처리가 필요 없음
  needsToMerge = false,
}: {
  items: ShippingItem[] | undefined;
  groupList: ProductGroup[] | undefined;
  needsToMerge?: boolean;
}) => {
  const hasGroupItems = (groupList ?? []).length > 0;
  if (!hasGroupItems) {
    return needsToMerge ? mergeItemsBySkuId(items) : items ?? [];
  }

  const groupItems = getGroupItemsV2(groupList);
  const singleItems = getSingleShippingItemsV2({
    items,
    groupList,
    needsToMerge,
  });

  return [...groupItems, ...singleItems];
};

/**
 * orders에서 상품명을 추출하여 반환
 * 수량이 가장 많은 상품이 대표상품. ㅇㅇ등으로 표기.
 *
 * @param orders - 주문 목록
 * @returns 대표상품 이름
 */
function getItemTitleForOrders(orders?: ShippingOrderItem[]) {
  if (!orders || !orders.length) return "-";
  if (orders.length === 1) return orders[0].itemName;

  const maxQuantityItem = orders.reduce((prev, cur) =>
    (prev.quantity ?? 0) > (cur.quantity ?? 0) ? prev : cur
  );

  return `${maxQuantityItem.itemName} 등`;
}

/**
 * orders에서 포맷팅된 SKU ID를 반환
 *
 * @param item - 주문 아이템
 * @returns 포맷팅된 SKU ID
 */
const getFormattedSkuIdForOrders = (item: {
  skuId?: number;
  productGroupId?: number;
}) => {
  if (!item) {
    return "";
  }

  if (item.productGroupId) {
    return getFormattedGroupSkuId(item.productGroupId);
  }

  return getFormattedSingleSkuId(item.skuId);
};

/**
 * TODO: V2는 백엔드 개선으로 인해 변경된 API만 적용됨
 * TODO: 반품까지 백엔드 개선이 완료될 경우 V2 문구를 삭제하고 기존 함수, 컴포넌트를 삭제
 * 출고 아이템의 출고 수량을 반환
 *
 * @param shippingItem - 출고 아이템 목록
 * @param groupList - 그룹상품 목록
 * @returns 출고 수량
 */
const getShippingItemShippingQuantityV2 = ({
  shippingItem,
  groupList,
}: {
  shippingItem: ShippingItem | ClassifiedGroupItem | undefined;
  groupList: ProductGroup[] | undefined;
}) => {
  if (!shippingItem) {
    return 0;
  }

  const isGroupSkuItem = shippingItem.isGroup;
  if (isGroupSkuItem) {
    const targetGroupSkuItem = (groupList ?? []).find(
      (groupData) => groupData.id === shippingItem.skuId
    );

    return (
      shippingItem.quantity *
      (targetGroupSkuItem?.groupItems ?? []).reduce(
        (sum, groupItem) => sum + groupItem.qty,
        0
      )
    );
  }

  return shippingItem.quantity;
};

/**
 * 피킹 목록을 위치 바코드 기준으로 정렬
 *
 * @param items - 피킹 목록
 * @returns 정렬된 피킹 목록
 */
const sortPickingListByLocation = (items: PickingSKUInfoForScanning[]) =>
  [...items].sort((a, b) =>
    a.locationBarcode.localeCompare(b.locationBarcode, undefined, {
      numeric: true,
      sensitivity: "base",
    })
  );

/**
 * https://www.notion.so/shipda/4ee5f64eaed248c4b5640f711fe9e570
 * 그룹상품을 우선(1순위), 수량이 많은 상품을 우선(2순위)으로 대표상품으로 표기
 *
 * @param orders - 주문 목록
 * @returns 대표상품
 */
const getRepresentativeProduct = (orders: ShippingOrderItem[]) =>
  orders.reduce((prev, cur) => {
    if (prev.skuType === "group" && cur.skuType === "single") {
      return prev;
    }

    if (prev.skuType === "single" && cur.skuType === "group") {
      return cur;
    }

    if (prev.quantity > cur.quantity) {
      return prev;
    }

    if (prev.quantity < cur.quantity) {
      return cur;
    }

    return prev;
  });

/**
 * 2개 이상인 경우 대표상품을 기준으로 표기
 *
 * @param orders - 주문 목록
 * @returns 대표상품 이름
 */
const getShipperItemTitle = (orders: ShippingOrderItem[]) => {
  if (!orders || !orders.length) {
    return "-";
  }

  if (orders.length === 1) {
    return orders[0].itemName;
  }

  const representativeProduct = getRepresentativeProduct(orders);

  return `${representativeProduct.itemName} 등`;
};

/**
 * 주문 아이템의 SKU ID를 포맷팅하여 반환
 *
 * @param item - 주문 아이템
 * @returns 포맷팅된 SKU ID
 */
const getFormattedShipperSkuId = (item: ShippingOrderItem) => {
  if (!item) {
    return "-";
  }

  return item.skuType === "single"
    ? getFormattedSingleSkuId(item.skuId)
    : getFormattedGroupSkuId(item.productGroupId);
};

/**
 * 주문 목록에서 SKU ID 목록을 반환
 *
 * @param orders - 주문 목록
 * @returns SKU ID 목록
 */
const getShipperSkuIdList = (orders: ShippingOrderItem[]) => {
  if (!orders || !orders.length) {
    return "-";
  }

  if (orders.length === 1) {
    return getFormattedShipperSkuId(orders[0]);
  }

  const representativeProduct = getRepresentativeProduct(orders);

  return `${getFormattedShipperSkuId(representativeProduct)} 외 ${
    orders.length - 1
  }건`;
};

/**
 * 배송 타입과 운송사 타입에 따른 택배사 또는 화물차종 이름을 반환
 *
 * @param deliveryType - 배송 타입
 * @param carrierType - 운송사 타입
 * @returns 택배사 또는 화물차종 이름
 */
const getShipperDeliveryName = (
  deliveryType: ShippingDeliveryType,
  carrierType: FulfillmentParcelCompany | ShippingTruckType | "-"
) => {
  if (
    deliveryType === "parcel" ||
    deliveryType === "freight" ||
    deliveryType === "airExpress"
  ) {
    return toParcelCompanyLabel(carrierType as FulfillmentParcelCompany);
  }

  if (deliveryType === "truck") {
    return toTruckTypeLabel(carrierType as CommonTruckType);
  }

  return carrierType;
};

/**
 * 주문 목록에서 SKU ID 목록을 반환
 *
 * @param orders - 주문 목록
 * @returns SKU ID 목록
 */
const getSkuIdListForOrders = (orders?: ShippingOrderItem[]) => {
  if (!orders || !orders.length) {
    return "-";
  }

  const skuIdList = orders.map(({ skuId, productGroupId }) =>
    getFormattedSkuIdForOrders({ skuId, productGroupId })
  );

  return skuIdList.join(", ");
};

/**
 * 출고 상태에 따른 배송 진행 상태를 반환
 *
 * @param shippingStatus - 출고 상태
 * @returns 배송 진행 상태
 */
const getShippingDeliveringStatusByShippingStatus = (
  shippingStatus: ShippingStatus
) => {
  switch (shippingStatus) {
    case "waiting":
    case "inProgress":
    case "close":
    case "cancel":
      return "notSent";
    case "delivering":
      return "delivering";
    case "done":
    case "return":
      return "done";
    default:
      return "notSent";
  }
};

/**
 * 배송 타입에 따른 발송 희망일 라벨을 반환
 *
 * @param deliveryType - 배송 타입
 * @returns 발송 희망일 라벨
 */
const getDueDateLabelByDeliveryType = (
  deliveryType: ShippingDeliveryType | undefined
) => {
  if (deliveryType === "truck") {
    return "발송희망일시";
  }

  if (deliveryType === "selfCollect") {
    return "방문예정일시";
  }

  return "발송희망일";
};

export {
  getSingleShippingItems,
  getSingleShippingItemsV2,
  classifyShippingItems,
  classifyShippingItemsV2,
  getItemTitleForOrders,
  getFormattedSkuIdForOrders,
  getShippingItemShippingQuantityV2,
  sortPickingListByLocation,
  getShipperItemTitle,
  getFormattedShipperSkuId,
  getShipperSkuIdList,
  getShipperDeliveryName,
  getSkuIdListForOrders,
  getRepresentativeProduct,
  getShippingDeliveringStatusByShippingStatus,
  getDueDateLabelByDeliveryType,
};
