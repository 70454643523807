import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { SKUInfoForCancelingRestocking } from "@sellernote/_shared/src/types/fulfillment/canceling";

import useCompleteRestocking from "hooks/canceling/useCompleteRestocking";

export default function CompleteRestockingButton({
  SKUInfoForScanning,
  moveToCancelingListPage,
}: {
  SKUInfoForScanning: SKUInfoForCancelingRestocking[];
  moveToCancelingListPage: () => void;
}) {
  const {
    handleRestockingComplete,
    ResponseHandlerOfRestockingCancelingInvoice,
  } = useCompleteRestocking({
    SKUInfoForScanning,
    moveToCancelingListPage,
  });

  return (
    <>
      <Button
        theme="primary"
        size="block"
        label="재입고 마감"
        handleClick={handleRestockingComplete}
      />

      {ResponseHandlerOfRestockingCancelingInvoice}
    </>
  );
}
