import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  RESET_PLACER_AND_WAREHOUSING_OF_ITEM_REQ_PATH_PARAMS,
  RESET_WAREHOUSING_OF_ITEM_EXPECT_PLACER_REQ,
} from "@sellernote/_shared/src/api-interfaces/boful-api/returning";
import RETURNING_QUERY from "@sellernote/_shared/src/queries/fulfillment/RETURNING_QUERY";

import { returningActions } from "modules/returning";

export default function useResetWarehousingOfItem() {
  const dispatch = useDispatch();

  const {
    mutate: resetWarehousingOfItemExceptPlacer,
    ResponseHandler: ResponseHandlerOfResetWarehousingOfItemExceptPlacer,
  } = RETURNING_QUERY.useResetWarehousingOfItemExceptPlacer();

  const resetWarehousingOfItem = useCallback(
    ({
        returningId,
        itemId,
        placingId,
        resetLocalCount,
      }: RESET_WAREHOUSING_OF_ITEM_EXPECT_PLACER_REQ &
        RESET_PLACER_AND_WAREHOUSING_OF_ITEM_REQ_PATH_PARAMS & {
          resetLocalCount: () => void;
        }) =>
      () => {
        if (!returningId) return;

        resetWarehousingOfItemExceptPlacer(
          {
            pathParams: { returningId, itemId },
            placingId,
          },
          {
            onSuccess: () => {
              dispatch(
                returningActions.GET_DETAIL({
                  id: returningId,
                  _postSuccessCallback: resetLocalCount,
                })
              );
            },
          }
        );
      },
    [dispatch, resetWarehousingOfItemExceptPlacer]
  );

  return {
    resetWarehousingOfItem,
    ResponseHandlerOfResetWarehousingOfItemExceptPlacer,
  };
}
