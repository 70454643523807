import React, { useMemo, useState } from "react";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";

type ValidationError = {
  title: React.ReactNode;
  body?: React.ReactNode;
  confirmCallback?: () => void;
};

export default function useValidationErrorModal() {
  const [validationError, setValidationError] = useState<
    ValidationError | undefined
  >();

  const ValidationErrorModal = useMemo(() => {
    if (!validationError) return null;

    if (validationError?.body) {
      return (
        <Modal
          active={true}
          uiType="content"
          title={validationError.title}
          body={validationError.body}
          actionPositive={{
            label: "확인",
            handleClick: handleConfirm,
          }}
        />
      );
    } else {
      return (
        <Modal
          active={true}
          uiType="titleOnly"
          title={validationError.title}
          actionPositive={{
            label: "확인",
            handleClick: handleConfirm,
          }}
        />
      );
    }

    function handleConfirm() {
      setValidationError(undefined);

      if (validationError?.confirmCallback) {
        validationError.confirmCallback();
      }
    }
  }, [validationError]);

  return [setValidationError, ValidationErrorModal] as const;
}
