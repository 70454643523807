import { Dispatch, SetStateAction } from "react";

import { INVENTORY_MANAGEMENT_KIND_MAP } from "@sellernote/_shared/src/constants/fulfillment/inventory";
import { ManagementKind } from "@sellernote/_shared/src/types/fulfillment/inventory";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import InputSelect from "@sellernote/_sds-v1/src/components/input/InputSelect";

import { SkuToSelect } from "../useSelectSkuModal";
import useSelectManagementDate from "./useSelectManagementDate";
import Styled from "./index.styles";

export default function SelectManagementDate({
  tempSku,
  setTempSku,
  managementKind,
}: {
  tempSku: SkuToSelect | undefined;
  setTempSku: Dispatch<SetStateAction<SkuToSelect | undefined>>;
  managementKind: ManagementKind;
}) {
  const {
    optionList,

    handleManagementDateSelect,

    ResponseHandlerOfInventoryDetailBySkuId,
  } = useSelectManagementDate({
    tempSku,
    setTempSku,
  });

  return (
    <>
      <Styled.selectManagementDate>
        <p className="description">
          관리일자가 지정된 상품입니다.
          <br />
          일자를 선택해주세요.
        </p>

        <InputSelect
          uiType="outline"
          label={`${INVENTORY_MANAGEMENT_KIND_MAP[managementKind]} 선택`}
          optionList={optionList}
          selectedOption={
            tempSku?.managementDate
              ? {
                  value: tempSku.managementDate,
                  label: toFormattedDate(tempSku?.managementDate, "YYYY-MM-DD"),
                }
              : undefined
          }
          handleSelect={handleManagementDateSelect}
        />
      </Styled.selectManagementDate>

      {ResponseHandlerOfInventoryDetailBySkuId}
    </>
  );
}
