import styled from "styled-components";

const modalBodyContainer = styled.div`
  > .table > table {
    width: 100%;
    height: 390px;
  }
`;

const modalButtonContainer = styled.div`
  padding-top: 16px;
`;

const skuIdContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const basicRowOfManagementDateContainer = styled.div`
  text-align: left;
  display: flex;
  flex-flow: column;
  gap: 4px;
`;

export default {
  modalBodyContainer,
  modalButtonContainer,
  skuIdContainer,
  basicRowOfManagementDateContainer,
};
