import styled, { css } from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.section`
  > .title {
    ${setFontStyle("SubHead2", "Bold")};
    margin-bottom: 16px;
  }

  > .reset-confirm,
  > .button {
    margin-bottom: 16px;
  }
`;

const takePictureOptionContainer = styled.div`
  > div {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const takePictureOption = styled.div<{ disabled?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 140px;
  height: 100px;
  ${setFontStyle("SubHead2", "Bold")};

  ${(props) => {
    if (props.disabled) {
      return css`
        border: 1px solid ${COLOR.grayScale_400};
        color: ${COLOR.grayScale_400};
        background-color: ${COLOR.grayScale_200};
      `;
    }

    return css`
      border: 1px solid ${COLOR.primaryBlue};
      color: ${COLOR.primaryBlue};
      background-color: ${COLOR.white};

      &:focus,
      &:active,
      &:hover {
        background-color: ${COLOR.primaryBlue_10};
      }
    `;
  }}
`;

export default {
  container,
  takePictureOptionContainer,
  takePictureOption,
};
