import styled, { css } from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

import { InputRadioDirection } from ".";

const container = styled.div<{
  direction: InputRadioDirection;
  isFlexWrap?: boolean;
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  flex-wrap: ${({ isFlexWrap }) => (isFlexWrap ? "wrap" : "nowrap")};
`;

interface OptionProps {
  color: string;
  selected: boolean;
  disabled?: boolean;
  direction: InputRadioDirection;
  hasDescription: boolean;
  hasStroke: boolean;
}

const option = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: ${(props: OptionProps) => props.color};
  cursor: ${(props: OptionProps) =>
    props.disabled ? "not-allowed" : "pointer"};
  background-color: ${(props: OptionProps) =>
    props.hasDescription && props.selected
      ? COLOR.primaryBlue_10
      : "transparent"};

  ${(props: OptionProps) => {
    if (props.hasDescription) {
      return css`
        border: ${props.selected
          ? `1px solid ${COLOR.primaryBlue_10}`
          : `1px solid ${COLOR.primaryBlue_40}`};
        border-radius: 5px;
        padding: 0.5rem 0.5rem;
      `;
    }
  }}

  > .title {
    display: flex;
    justify-content: center;
    align-items: center;

    > .icon {
      margin-right: 0.25rem;
    }

    > .label {
      ${setFontStyle("SubHead3", "Bold")};
    }
  }

  > .description {
    ${setFontStyle("Body8")};
    margin-top: 0.25rem;
    color: ${COLOR.grayScale_400};
  }

  > .icon-clear {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  ${({ hasStroke }) => {
    if (!hasStroke) {
      return css`
        border: 0;
        padding: 0;
        background-color: transparent;

        > .description {
          margin-top: 0;
          color: ${COLOR.grayScale_600};
        }
      `;
    }
  }}

  ${(props: OptionProps) => {
    switch (props.direction) {
      case "row": {
        return css`
          &:not(:last-child) {
            margin-right: 1rem;
          }
        `;
      }

      case "column": {
        return css`
          &:not(:last-child) {
            margin-bottom: 0.5rem;
          }
        `;
      }
    }
  }}
`;

export default {
  container,
  option,
};
