import { memo } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import ConfirmWrapper from "@sellernote/_sds-v1/src/components/ConfirmWrapper";

import { InventoryToMove } from "../useInventoryMovement";
import useCompleteMovement from "./useCompleteMovement";
import Styled from "./index.styles";

function CompleteMovement({
  existingLocationId,
  skuId,
  listOfInventoryToMove,
}: {
  existingLocationId: number;
  skuId: number;
  listOfInventoryToMove: InventoryToMove[];
}) {
  const {
    isValidToMove,
    handleMovementComplete,

    ResponseHandlerOfCompletingInventoryMovement,
  } = useCompleteMovement({
    existingLocationId,
    skuId,
    listOfInventoryToMove,
  });

  return (
    <>
      <Styled.completeMovement>
        <ConfirmWrapper
          confirmModalUiType="titleOnly"
          confirmMessageTitle="재고 이동 / 분할을 진행 하시겠습니까?"
          customButtonLabel={{ positive: "진행", negative: "취소" }}
          disabled={!isValidToMove}
        >
          <Button
            className="complete-button"
            theme="primary"
            size="block"
            label="이동완료"
            handleClick={handleMovementComplete}
            disabled={!isValidToMove}
          />
        </ConfirmWrapper>
      </Styled.completeMovement>

      {ResponseHandlerOfCompletingInventoryMovement}
    </>
  );
}

export default memo(CompleteMovement);
