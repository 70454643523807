import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  LoadingState,
  LoadingWithKey,
} from "@sellernote/_shared/src/types/common/loading";

const initialState: LoadingState = {};

const SLICE_NAME = "loading";

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    START_LOADING: {
      reducer: (
        state,
        action: PayloadAction<{ requestType: string; key?: number }>
      ) => {
        if (hasKey(action.payload.key)) {
          if (!state[action.payload.requestType]) {
            state[action.payload.requestType] = {};
          }
          (state[action.payload.requestType] as LoadingWithKey)[
            action.payload.key!
          ] = true;
        } else {
          state[action.payload.requestType] = true;
        }
      },
      prepare: (requestType: string, key?: number) => {
        return { payload: { requestType, key } };
      },
    },

    FINISH_LOADING: {
      reducer: (
        state,
        action: PayloadAction<{ requestType: string; key?: number }>
      ) => {
        if (hasKey(action.payload.key)) {
          if (!state[action.payload.requestType]) {
            state[action.payload.requestType] = {};
          }
          (state[action.payload.requestType] as LoadingWithKey)[
            action.payload.key!
          ] = false;
        } else {
          state[action.payload.requestType] = false;
        }
      },
      prepare: (requestType: string, key?: number) => {
        return { payload: { requestType, key } };
      },
    },

    INIT_GET_FILE_URL_LIST_LOADING: (state) => {
      state["file/GET_FILE_URL_LIST"] = false;
    },
  },
});

export default slice;

function hasKey(key?: number) {
  return Boolean(key || typeof key === "number");
}
