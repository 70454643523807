import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const removeConfirmModalTitle = styled.div`
  ${setFontStyle("Head6", "Bold")};
  color: ${COLOR.grayScale_700};

  > b {
    color: ${COLOR.pointWarning};
  }
`;

export default {
  removeConfirmModalTitle,
};
