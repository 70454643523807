import { useState } from "react";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";
import { SKUInfoForCancelingRestocking } from "@sellernote/_shared/src/types/fulfillment/canceling";
import { BofulProblem } from "@sellernote/_shared/src/types/fulfillment/fulfillment";

import { ReportingListItem } from "hooks/canceling/useReportCancelingProblem";

import CompleteButton from "./CompleteButton";
import ProblemListTable from "./ProblemListTable";
import Styled from "./index.styles";

export type ProblemList = {
  [itemId: number]: ProblemListItemType;
};

export interface ProblemListItemType {
  problemKind: InputSelectOption<BofulProblem> | undefined;
  problemDirectInput?: string;
}

export default function ReportProblemModal({
  SKUInfoForScanning,
  reportingList,
  moveToCancelingListPage,
  handleReportProblemModalClose,
}: {
  SKUInfoForScanning: SKUInfoForCancelingRestocking[];
  reportingList: ReportingListItem[];
  moveToCancelingListPage: () => void;
  handleReportProblemModalClose: () => void;
}) {
  const [problemList, setProblemList] = useState<ProblemList>(
    reportingList.reduce<ProblemList>((list, reportingItem) => {
      list[reportingItem.id] = { problemKind: undefined };

      return list;
    }, {})
  );

  return (
    <Styled.reportProblemContainer>
      <Modal
        className="report-modal"
        uiType="contentWithCustomBody"
        active={true}
        title="문제가 발생한 상품(들)을 선택해주세요."
        body={
          <>
            <ProblemListTable
              reportingList={reportingList}
              problemList={problemList}
              setProblemList={setProblemList}
            />
            <CompleteButton
              SKUInfoForScanning={SKUInfoForScanning}
              problemList={problemList}
              moveToCancelingListPage={moveToCancelingListPage}
              handleReportProblemModalClose={handleReportProblemModalClose}
            />
          </>
        }
        onClose={handleReportProblemModalClose}
      />
    </Styled.reportProblemContainer>
  );
}
