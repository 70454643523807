import { useCallback, useMemo, useState } from "react";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { INVENTORY_MANAGEMENT_KIND_MAP } from "@sellernote/_shared/src/constants/fulfillment/inventory";
import { WarehousingStatusLabel } from "@sellernote/_shared/src/types/fulfillment/common";
import { ManagementKind } from "@sellernote/_shared/src/types/fulfillment/inventory";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { validateCount } from "@sellernote/_shared/src/utils/common/validation";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import InputText from "@sellernote/_sds-v1/src/components/input/InputText";

import StyledForCount from "components/CountForScanning/index.styles";
import ItemStatus from "components/ItemStatus";

export interface CountForScanning {
  type:
    | "receivingInspection"
    | "receivingWarehousing"
    | "picking"
    | "restocking"
    | "returningInspection"
    | "returningWarehousing"
    | "";
  skuId: number;
  // TODO: currentQty는 입력한 수량만큼 등록되도록 기능이 변경됨에 따라 필요 없어져서 추후 삭제가 필요해보임
  currentQty: number | undefined;
  quantity: number;
  onCountAdd: (count: number | undefined) => void;
  /** 입고(warehousing)작업 시 정상/불량 상품을 구분하기 위한 상태값 */
  statusLabel?: WarehousingStatusLabel;
  /** 직접 입력 시, 최소 입력 값 */
  minCount?: number;
  /** 입고(warehousing)작업 시 불일치 상품 여부를 구분하기 위한 상태값 */
  isUnverifiedSku?: boolean;
  /** 관리일자 타입 */
  managementKind?: ManagementKind;
  /** 관리일자 */
  managementDate?: string;
}

export default function useCountForScanning() {
  const [isVisibleDirectInputModal, setIsVisibleDirectInputModal] =
    useState(false);
  const [directInputForCount, setDirectInputForCount] = useState<number>();
  const [countForScanning, setCountForScanning] = useState<CountForScanning>();

  const canInputOverCountType =
    countForScanning?.type === "receivingInspection" ||
    countForScanning?.type === "receivingWarehousing" ||
    countForScanning?.type === "returningInspection" ||
    countForScanning?.type === "returningWarehousing";

  const maxCount = canInputOverCountType
    ? Number.MAX_SAFE_INTEGER
    : countForScanning?.quantity ?? 0;

  const handleDirectInputModalOpen = useCallback(() => {
    setIsVisibleDirectInputModal(true);
  }, []);

  const handleDirectInputModalClose = useCallback(() => {
    setDirectInputForCount(undefined);

    setIsVisibleDirectInputModal(false);
  }, []);

  const handleCountAdd = useCallback(() => {
    countForScanning?.onCountAdd(directInputForCount ?? 0);

    setDirectInputForCount(undefined);
    setIsVisibleDirectInputModal(false);
  }, [countForScanning, directInputForCount]);

  const CountForScanningModal = useMemo(
    () => (
      <Modal
        active={isVisibleDirectInputModal}
        uiType="contentWithCustomBody"
        title={
          <>
            {getFormattedSingleSkuId(countForScanning?.skuId)}(SKU ID)
            {countForScanning?.statusLabel ? (
              <>
                <br />
                상태:{" "}
                <ItemStatus
                  statusLabel={countForScanning.statusLabel}
                  isUnverifiedSku={countForScanning.isUnverifiedSku}
                />
              </>
            ) : (
              ""
            )}
            {countForScanning?.managementKind ? (
              <>
                <br />
                {INVENTORY_MANAGEMENT_KIND_MAP[countForScanning.managementKind]}
                :{" "}
                {toFormattedDate(countForScanning.managementDate, "YYYY-MM-DD")}
              </>
            ) : (
              ""
            )}
          </>
        }
        body={
          <StyledForCount.directInputModalBody>
            {!canInputOverCountType && (
              <strong className="input-available-quantity">
                입력 가능 수량: {countForScanning?.quantity ?? 0}
              </strong>
            )}

            <InputText
              borderType="outline"
              valueType="int"
              label={`${
                {
                  receivingInspection: "검수",
                  returningInspection: "검수",
                  receivingWarehousing: "입고",
                  returningWarehousing: "입고",
                  picking: "피킹",
                  restocking: "재입고",
                  "": "",
                }[countForScanning?.type ?? ""]
              } 수량`}
              placeholder="입력해주세요."
              value={directInputForCount}
              setValue={setDirectInputForCount}
              errorMessage={
                validateCount({
                  count: directInputForCount,
                  maxCount,
                  minCount: countForScanning?.minCount,
                  message: {
                    minCount:
                      "입력한 비정상 상품 수량보다 낮은 수량으로 입력 불가능합니다.",
                  },
                }).message
              }
            />

            <Button
              theme="primary"
              size="normal"
              label="확인"
              disabled={
                !validateCount({
                  count: directInputForCount,
                  minCount: countForScanning?.minCount,
                  maxCount,
                }).result
              }
              handleClick={handleCountAdd}
            />
          </StyledForCount.directInputModalBody>
        }
        onClose={handleDirectInputModalClose}
      />
    ),
    [
      isVisibleDirectInputModal,
      countForScanning?.skuId,
      countForScanning?.statusLabel,
      countForScanning?.isUnverifiedSku,
      countForScanning?.managementKind,
      countForScanning?.managementDate,
      countForScanning?.quantity,
      countForScanning?.type,
      countForScanning?.minCount,
      canInputOverCountType,
      directInputForCount,
      maxCount,
      handleCountAdd,
      handleDirectInputModalClose,
    ]
  );

  return {
    CountForScanningModal,
    handleDirectInputModalOpen,
    setCountForScanning,
  };
}
