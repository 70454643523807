import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

import { AccordionItemStatus } from ".";

interface ContainerProps {}
const container = styled.div``;

interface AccordionItemForInformationTypeProps {
  status: AccordionItemStatus;
}
const accordionItemForInformationType = styled.div`
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: ${COLOR.primaryBlue_10};
    border-bottom: 1px solid ${COLOR.primaryBlue};
    cursor: pointer;

    ${(props: AccordionItemForInformationTypeProps) => {
      if (props.status === "disabled") {
        return css`
          cursor: not-allowed;
          background-color: ${COLOR.grayScale_50};
          border-bottom: 1px solid ${COLOR.grayScale_300};
        `;
      }

      if (props.status === "closed") {
        return css`
          background-color: ${COLOR.grayScale_50};
          border-bottom: 1px solid ${COLOR.primaryBlue_40};
        `;
      }
    }}

    > .title {
      ${setFontStyle("SubHead2", "Bold")};
      color: ${COLOR.grayScale_600};

      ${(props: AccordionItemForInformationTypeProps) => {
        if (props.status === "disabled") {
          return css`
            color: ${COLOR.grayScale_300};
          `;
        }
      }}
    }
  }
`;

interface AccordionItemForQnATypeProps {
  status: AccordionItemStatus;
}
const accordionItemForQnAType = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  > .header {
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: flex-start;
    cursor: pointer;

    > .icon {
      margin-right: 1rem;

      ${mobile(css`
        margin-right: 0.5rem;
      `)}
    }

    > .title {
      ${setFontStyle("Head4", "Bold")};
      color: ${(props: AccordionItemForQnATypeProps) =>
        props.status === "opened" ? COLOR.grayScale_500 : COLOR.grayScale_700};

      ${mobile(css`
        ${setFontStyle("SubHead2", "Bold")};
      `)}
    }
  }

  > .desc {
    margin-top: 0.25rem;
    padding-left: 3.5rem;
    ${setFontStyle("Body5")};
    color: ${COLOR.grayScale_800};

    ${mobile(css`
      margin-top: 0.5rem;
      padding-left: 1rem;
      ${setFontStyle("Body6")};
    `)}
  }
`;

export default {
  container,
  accordionItemForInformationType,
  accordionItemForQnAType,
};
