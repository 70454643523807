import { useAppQueryWithQueryKeyFactory } from "@sellernote/_shared/src/services/query";

import { BofulWorker } from "../../types/fulfillment/auth";
import { BofulLocation } from "../../types/fulfillment/common";

import {
  GET_FILE_URL_LIST_REQ,
  GET_FILE_URL_LIST_RES,
} from "../../api-interfaces/boful-api/file";
import { GET_LOCATION_LIST_OF_WAREHOUSE_REQ } from "../../api-interfaces/boful-api/location";

export const COMMON_QUERY_KEY_GEN = {
  all: () => [{ scope: "fulfillment/COMMON_QUERY" }] as const,
  getFileUrlList: (params: GET_FILE_URL_LIST_REQ) =>
    [
      {
        ...COMMON_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "fileUrlList",
      },
    ] as const,
  getWorkerList: () =>
    [
      {
        ...COMMON_QUERY_KEY_GEN.all()[0],
        entity: "workerList",
      },
    ] as const,
  getLocationListOfWarehouse: (params: GET_LOCATION_LIST_OF_WAREHOUSE_REQ) =>
    [
      {
        ...COMMON_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "locationListOfWarehouse",
      },
    ] as const,
};

function useGetWorkerList(params?: {
  enabled?: boolean;
  onSuccess?: ((data: BofulWorker[]) => void) | undefined;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof COMMON_QUERY_KEY_GEN.getWorkerList>,
    BofulWorker[]
  >({
    queryKey: COMMON_QUERY_KEY_GEN.getWorkerList(),
    requestOptions: {
      method: "get",
      path: "/warehouseManager/list",
    },

    enabled: params?.enabled,

    onSuccess: params?.onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "작업자 리스트 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useGetLocationListOfWarehouse({
  enabled,
  onSuccess,
  refetchOnMount,
  ...params
}: GET_LOCATION_LIST_OF_WAREHOUSE_REQ & {
  enabled?: boolean;
  onSuccess?: ((data: BofulLocation[]) => void) | undefined;
  refetchOnMount?: boolean | "always";
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof COMMON_QUERY_KEY_GEN.getLocationListOfWarehouse>,
    BofulLocation[]
  >({
    queryKey: COMMON_QUERY_KEY_GEN.getLocationListOfWarehouse(params),
    requestOptions: {
      method: "get",
      path: `/location/list`,
      params,
    },

    enabled,

    onSuccess,

    refetchOnMount,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "위치 리스트 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

function useGetFileUrlList({
  key,
  enabled,
  onSuccess,
}: GET_FILE_URL_LIST_REQ & {
  enabled?: boolean;
  onSuccess?: (res: GET_FILE_URL_LIST_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof COMMON_QUERY_KEY_GEN.getFileUrlList>,
    GET_FILE_URL_LIST_RES
  >({
    queryKey: COMMON_QUERY_KEY_GEN.getFileUrlList({ key }),
    enabled,
    requestOptions: {
      method: "patch",
      path: "/file/download",
      apiType: "BofulDefault",
      data: { key },
    },
    onSuccess,
  });

  return { ...queryResult };
}

const COMMON_QUERY = {
  useGetWorkerList,
  useGetFileUrlList,
  useGetLocationListOfWarehouse,
};
export default COMMON_QUERY;
